import { StateService } from "@uirouter/angular";
import { NgFor, NgIf } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { take } from "rxjs";
import { UiFormControlComponent } from "@webapp/ui/form/components/form-control/form-control.component";
import { UiColDirective } from "@webapp/ui/grid/col.directive";
import { UiInputTextAreaComponent } from "@webapp/ui/input/components/input-text-area/input-text-area.component";
import { StrategicBetVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategicBetsService } from "../../services/bet/strategic-bets.service";
import { StrategiesTrackingService } from "../../services/utility/strategies-tracking.service";
import { ActionableHeaderComponent } from "../actionable-header/actionable-header.component";
import { AlertMessageComponent } from "../evaluate-bet-page/components/alert-message/alert-message.component";

type PredefinedQuestions = "What market should I go into?" | "Should I launch a new product?";

@Component({
  selector: "detailed-strategy-page",
  templateUrl: "./detailed-strategy-page.component.html",
  styleUrls: ["./detailed-strategy-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    UiButtonComponent,
    UiTooltipModule,
    UiIconModule,
    ActionableHeaderComponent,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    UiColDirective,
    UiFormControlComponent,
    UiInputTextAreaComponent,
    AlertMessageComponent,
  ],
})
export class DetailedStrategyPageComponent implements AfterViewInit {
  @ViewChild("askInput")
  public askInput: UiInputTextAreaComponent;
  public askForm: FormGroup<{ question: FormControl<string> }>;
  public predefinedQuestions: PredefinedQuestions[] = ["What market should I go into?", "Should I launch a new product?"];
  public limitReached = false;

  constructor(
    private strategicBetsService: StrategicBetsService,
    private trackingService: StrategiesTrackingService,
    private cdr: ChangeDetectorRef,
    private state: StateService,
    formBuilder: FormBuilder
  ) {
    this.askForm = formBuilder.group({ question: "" });
  }

  public ngAfterViewInit(): void {
    this.askInput.textareaRef.nativeElement.focus();
  }

  public createBet(options?: { question: PredefinedQuestions; isPredefined: boolean }): void {
    const betQuestion = options?.question || this.askForm.get("question").value;
    if (!betQuestion || betQuestion.length > 1000) {
      this.askInput.textareaRef.nativeElement.focus();
      return;
    }
    this.strategicBetsService
      .createBet$({ betName: betQuestion })
      .pipe(take(1))
      .subscribe({
        next: (bet: StrategicBetVM) => {
          this.trackingService.trackStrategyFlowInitiated(bet.contextId, "Decision Map", bet.name, options?.question || "", betQuestion);
          this.openBet(bet);
        },
        error: (error) => {
          if (error.status === 403) {
            this.limitReached = true;
            this.cdr.markForCheck();
          }
        },
      });
  }

  public openBet(bet: StrategicBetVM): void {
    this.state.go("gtmhub.decision", { betId: bet.id });
  }

  public closePage(): void {
    this.state.go("gtmhub.decisions");
  }

  public hideLimitReachedError(): void {
    this.limitReached = false;
    this.cdr.markForCheck();
  }

  public goToBilling(): void {
    this.state.go("gtmhub.editionPlanChange");
  }
}
