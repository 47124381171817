import { StateService } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule, UiIconSize, UiThemeType } from "@quantive/ui-kit/icon";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { HasAllPermissionsDirective } from "@webapp/permissions/directives/has-all-permissions.directive";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { DisplayModes, TopNavBarButtonsConfig } from "@webapp/top-nav-bar/models/top-nav-bar-buttons.models";
import { actionHandlerGenerator } from "@webapp/top-nav-bar/utils/action-handler";
import { UiButton, UiIconAndButton } from "@webapp/top-nav-bar/utils/ui-button.builder";

type TopNavBarButton = UiButton & { onClick(): void };

@Component({
  selector: "top-nav-bar-buttons",
  templateUrl: "./top-nav-bar-buttons.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiButtonModule, UiIconModule, DropdownModule, LocalizationModule, HasAllPermissionsDirective],
})
export class TopNavBarButtonsComponent implements OnChanges {
  @Input() public isRelaxedMode: boolean;

  @Input() public buttonsConfig: TopNavBarButtonsConfig["buttonsConfig"];
  @Input() public dropdownConfig: TopNavBarButtonsConfig["dropdownConfig"];

  public buttons: TopNavBarButton[] = [];
  public dropdown: TopNavBarButtonsConfig["dropdownConfig"];

  constructor(
    private stateService: StateService,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {}

  public get dropdownKey(): string {
    if (!this.dropdown.keysByVisibilityMode) {
      return this.dropdown.key;
    } else {
      return this.isRelaxedMode ? this.dropdown.keysByVisibilityMode.relaxed : this.dropdown.keysByVisibilityMode.compact;
    }
  }

  public ngOnChanges(): void {
    const hasActions = this.buttonsConfig || this.dropdownConfig;
    if (!hasActions) return;

    if (this.isRelaxedMode) {
      this.buildRelaxedActions();
    } else {
      this.buildCompactActions();
    }

    this.cdr.markForCheck();
  }

  public dropdownToggled(value: boolean): void {
    this.dropdown.isExpanded = value;
  }

  public getIconType(button: TopNavBarButton): string {
    return (button as UiIconAndButton).icon?.iconType;
  }

  public getIconSize(button: TopNavBarButton): UiIconSize {
    return (button as UiIconAndButton).icon?.iconSize;
  }

  public getIconTheme(button: TopNavBarButton): UiThemeType {
    return (button as UiIconAndButton).icon?.iconTheme;
  }

  public shouldDisplayIcon(button: TopNavBarButton): boolean {
    return button.uiType === "iconAndButton" && !!button.icon;
  }

  private buildRelaxedActions(): void {
    this.buttons = [];

    for (const button of this.buttonsConfig?.uiButtons || []) {
      const buttonInRelaxedMode = this.buildButtonBasedOnMode(button, "relaxed");
      this.buttons.push(buttonInRelaxedMode);
    }

    this.buildDropdownButton();
  }

  private buildCompactActions(): void {
    this.buttons = [];

    if (!this.buttonsConfig?.dropdownKey || !this.buttonsConfig?.shouldMergeButtonsInCompactMode) {
      for (const button of this.buttonsConfig?.uiButtons || []) {
        const buttonInCompactMode = this.buildButtonBasedOnMode(button, "compact");
        this.buttons.push(buttonInCompactMode);
      }
    }

    this.buildDropdownButton();
  }

  private buildDropdownButton(): void {
    this.dropdown = null;
    const dropdownKey = this.checkDropdownKey();

    if (!dropdownKey) return;

    const menuItems = this.dropdownConfig ? [...this.dropdownConfig.menuItems] : [];
    const lastMenuItem = menuItems[menuItems.length - 1] || <DropdownMenuItem>{};

    /**
     * When we merge the buttons and we already have some dropdown items, we need to add a separator
     * between the buttons and the dropdown items but we already build the dropdown items and we can't
     * add a separator using the DropdownMenuItemBuilder. So we add a separator here.
     */
    lastMenuItem.endOfSection = this.buttonsConfig?.shouldMergeButtonsInCompactMode && !this.isRelaxedMode;

    if (!this.isRelaxedMode && this.buttonsConfig?.shouldMergeButtonsInCompactMode) {
      const menuItemBuilder = new DropdownMenuItemBuilder();

      for (const uiButton of [...this.buttonsConfig.uiButtons].reverse()) {
        const builder = menuItemBuilder.setKey(uiButton.key).setAction(uiButton.action);

        if (uiButton.track) {
          builder.setTrack(uiButton.track).setTrackMetaData(uiButton.trackMetaData);
        }

        if (uiButton.uiType === "iconAndButton") {
          builder.setUiType({ uiType: "uiIcon", iconType: uiButton.icon?.iconType, iconTheme: uiButton.icon?.iconTheme });
        }

        const menuItem = builder.setDataTestId(uiButton.dataTestId).build();

        menuItems.push(menuItem);
      }
    }

    this.dropdown = {
      key: dropdownKey,
      menuItems: menuItems,
      isVisible$: this.dropdownConfig?.isVisible$,
      keysByVisibilityMode: this.dropdownConfig?.keysByVisibilityMode,
      disabled: !!this.dropdownConfig?.disabled,
    };
  }

  private checkDropdownKey(): string {
    if (!this.isRelaxedMode && this.buttonsConfig?.dropdownKey) return this.buttonsConfig.dropdownKey;

    return this.dropdownConfig?.key;
  }

  private buildButtonBasedOnMode(uiButton: UiButton, mode: DisplayModes): TopNavBarButton {
    let key: string = null;
    if (uiButton.keyVisibilityMode === "always" || uiButton.keyVisibilityMode === mode) {
      key = uiButton.keysByVisibilityMode ? uiButton.keysByVisibilityMode[mode] : uiButton.key;
    }

    let icon: UiIconAndButton["icon"] = null;
    if (uiButton.uiType === "iconAndButton") {
      const shouldDisplayIconInMode = uiButton.iconVisibilityMode === "always" || uiButton.iconVisibilityMode === mode;

      if (shouldDisplayIconInMode) {
        icon = uiButton.icon;
      }
    }

    return {
      ...uiButton,
      key: key,
      ...{ icon },
      onClick: () => this.invokeAction(uiButton),
    };
  }

  private invokeAction(uiButton: UiButton): void {
    if (uiButton.track) {
      this.analyticsService.track(uiButton.track, uiButton.trackMetaData);
    }
    actionHandlerGenerator(uiButton.action, { stateService: this.stateService, window: window })();
  }
}
