import { Injectable } from "@angular/core";
import { Observable, map, shareReplay } from "rxjs";
import { cacheOrGet } from "@webapp/core/state-management/state-management.utils";
import { UserSettingsApiService } from "./user-settings-api.service";
import { UserSettingsCache } from "./user-settings-cache";
import { UserSettings } from "./user-settings.model";

@Injectable()
export class UserSettingsRepository {
  private apiGetObservable$: Observable<UserSettings>;

  constructor(
    private apiService: UserSettingsApiService,
    private cache: UserSettingsCache
  ) {}

  public getUserSettings$(): Observable<UserSettings> {
    if (!this.apiGetObservable$) {
      this.apiGetObservable$ = this.apiService.getUserSettings$().pipe(shareReplay(1));
    }

    return cacheOrGet(this.cache, this.apiGetObservable$);
  }

  public getUserSettingsV2$(): Observable<UserSettings> {
    return this.apiService.getUserSettingsV2$().pipe(map((res) => res.settings || {}));
  }

  public updateUserSettings$(userSettings: UserSettings): Observable<void> {
    return this.apiService.updateUserSettings$(userSettings).pipe(
      map((newSettings) => {
        this.cache.set(newSettings);
      })
    );
  }

  public updateUserSettingsV2$(userSettings: UserSettings): Observable<UserSettings> {
    return this.apiService.updateUserSettingsV2$(userSettings);
  }
}
