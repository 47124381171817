import { Injectable, NgZone } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, combineLatest, filter, map, noop, switchMap, take } from "rxjs";
import { IFavoritesItemsGroupedResponse } from "@gtmhub/favorites/models";
import { IUXCustomizationItemResponse, UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ICollection } from "@webapp/core/core.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { INavItem, NavItemsType } from "@webapp/navigation/models/nav-items-list.models";
import { NavItemsUXCTypesMap, SubNavItemsModel, navItemsUXCTypesMapBuilder } from "@webapp/navigation/utils/nav-items.util";
import { FavoritesFacade } from "./favorites/favorites.facade.service";
import { IFavoritesModel } from "./favorites/favorites.models";
import NavigationItemsChangeListener, { IItemsChangeListenerDelegate } from "./navigation-items-update-listener.service";
import { RecentsFacade } from "./recents/recents.facade.service";
import { SuggestedNavItemFacade } from "./suggested/suggested-nav-item.facade";

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export default class NavigationItemsMediator implements IItemsChangeListenerDelegate {
  public constructor(
    private suggestedNavItemFacade: SuggestedNavItemFacade,
    private favoritesFacade: FavoritesFacade,
    private recentsFacade: RecentsFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
    private editionFeatureService: EditionFeatureService,
    private navigationitemsChangeListener: NavigationItemsChangeListener,
    private ngZone: NgZone
  ) {
    this.navigationitemsChangeListener.setDelegate(this);
    this.navigationitemsChangeListener.registerListeners();
    this.initializeNavItemsUXCTypesMap();
  }

  public getSuggestedItems$(entityNavType: NavItemsType): Observable<ICollection<INavItem>> {
    switch (entityNavType) {
      case "sessions":
        return this.suggestedNavItemFacade.getSuggestedSessions$();
      case "whiteboards":
        return this.suggestedNavItemFacade.getSuggestedWhiteboards$();
      case "kpis":
        return this.suggestedNavItemFacade.getSuggestedKpiGroups$();
      case "insightboards":
        return this.suggestedNavItemFacade.getSuggestedDashboards$();
      case "lists":
        return this.suggestedNavItemFacade.getSuggestedLists$();
      case "people":
        return this.suggestedNavItemFacade.getSuggestedTeams$();
    }
  }

  public getFavoritesItems$(entityNavType: NavItemsType): Observable<ICollection<INavItem>> {
    return this.favoritesFacade.getFavoritesOfType(entityNavType);
  }

  public getRecentItems$(entityNavType: NavItemsType): Observable<ICollection<INavItem>> {
    return this.recentsFacade.getRecentsOfType(entityNavType);
  }

  public loadRecents(navItemsType: NavItemsType): void {
    this.navItemsUXCTypesMap$
      .pipe(
        untilDestroyed(this),
        filter((x) => x !== null)
      )
      .subscribe((navItemsUXCTypesMap) => {
        this.recentsFacade.getAllOfType(navItemsUXCTypesMap[navItemsType], navItemsType);
      });
  }

  public loadFavorites(navItemsTypes: NavItemsType | NavItemsType[]): Observable<IFavoritesItemsGroupedResponse> {
    return this.navItemsUXCTypesMap$.pipe(
      untilDestroyed(this),
      filter((x) => {
        return x !== null;
      }),
      switchMap((navItemsUXCTypesMap: NavItemsUXCTypesMap): Observable<IFavoritesItemsGroupedResponse> => {
        const targets = Array.isArray(navItemsTypes) ? navItemsTypes : [navItemsTypes];

        return this.favoritesFacade.loadFavorites(targets, navItemsUXCTypesMap).pipe(take(1));
      })
    );
  }

  public isLoading$(): Observable<boolean> {
    return combineLatest([this.suggestedNavItemFacade.isLoaded$(), this.favoritesFacade.getState().isLoading$(HttpActions.getAll)]).pipe(
      map(([suggested, favorites]) => {
        return !suggested || favorites;
      })
    );
  }

  public unmarkItemAsFavorite(id: string, type: UXCustomizationType, navItem: INavItem, navItemsType: NavItemsType): void {
    this.ngZone.run(() => {
      this.favoritesFacade.unmarkItemAsFavorite(id, navItem, navItemsType).subscribe(() => {
        noop();
      });
    });
  }

  public unmarkItemAsFavorite$(id: string, type: UXCustomizationType, navItem: INavItem, navItemsType: NavItemsType): Observable<IFavoritesModel> {
    return this.ngZone.run(() => {
      return this.favoritesFacade.unmarkItemAsFavorite(id, navItem, navItemsType);
    });
  }

  public removeFromSuggested(id: string, uxcType: UXCustomizationType): void {
    this.suggestedNavItemFacade.blacklistItem(id, uxcType).subscribe(() => {
      this.suggestedNavItemFacade.deleteItem(uxcType, id);
    });
  }

  public markItemAsFavorite(id: string, uxcType: UXCustomizationType, navItem: INavItem, navItemsType: NavItemsType): void {
    this.ngZone.run(() => {
      this.favoritesFacade.markItemAsFavorite(uxcType, id, navItem, navItemsType).subscribe((response: { id: string }) => {
        this.suggestedNavItemFacade.isLoaded$().subscribe((isLoaded: boolean) => {
          if (isLoaded) {
            this.suggestedNavItemFacade.deleteItem(uxcType, id);
          }
        });
        this.favoritesFacade.reorderFavoriteItemSequentially(response.id, 1, uxcType);
      });
    });
  }

  public markItemAsFavorite$(
    id: string,
    uxcType: UXCustomizationType,
    navItem: INavItem,
    navItemsType: NavItemsType
  ): Observable<IUXCustomizationItemResponse<UXCustomizationType>> {
    return this.ngZone.run(() => {
      return this.favoritesFacade.markItemAsFavorite(uxcType, id, navItem, navItemsType);
    });
  }

  public addItemToViewHistory(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    this.recentsFacade.addItemToViewHistory(uxcType, item).subscribe(() => {
      this.suggestedNavItemFacade.isLoaded$().subscribe((isLoaded: boolean) => {
        if (isLoaded) {
          this.suggestedNavItemFacade.deleteItem(uxcType, item.id);
        }
      });
    });
  }

  public reorder(favoriteId: string, newOrder: number): void {
    this.favoritesFacade.reorderFavoriteItemSequentially(favoriteId, newOrder);
  }

  public updateNavItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    this.recentsFacade.updateItem(uxcType, item);
    this.favoritesFacade.updateItem(uxcType, item);
    this.suggestedNavItemFacade.updateItem(uxcType, item);
  }

  public deleteNavItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    this.recentsFacade.deleteItem(uxcType, item);
    this.favoritesFacade.deleteItem(uxcType, item);
    this.suggestedNavItemFacade.deleteItem(uxcType, item.id);
  }

  public enrichRecents(favoritesCollection: ICollection<INavItem>, entityNavType: NavItemsType): void {
    this.recentsFacade.enrichEntitiesWithFavorites(favoritesCollection, entityNavType);
  }

  private navItemsUXCTypesMap$ = new BehaviorSubject<NavItemsUXCTypesMap>(null);

  private initializeNavItemsUXCTypesMap(): void {
    combineLatest([this.shouldDisplayOkrViews$(), this.shouldDisplayKpiViews$()])
      .pipe(take(1))
      .subscribe(([shouldDisplayOkrViews, shouldDisplayKpiViews]) => {
        const navItemsUXCTypesMap = navItemsUXCTypesMapBuilder({ shouldDisplayOkrViews, shouldDisplayKpiViews });
        this.navItemsUXCTypesMap$.next(navItemsUXCTypesMap);
      });
  }

  private shouldDisplayOkrViews$(): Observable<boolean> {
    return combineLatest([this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.OkrViews), this.editionFeatureService.hasFeature$("okrs.okr-views")]).pipe(
      map(([isOkrViewsFeatureEnabled, isOkrViewsFeatureIncludedInEdition]) => {
        return isOkrViewsFeatureEnabled && isOkrViewsFeatureIncludedInEdition;
      })
    );
  }

  private shouldDisplayKpiViews$(): Observable<boolean> {
    return this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.KpiRevampBetaFirstIteration);
  }
}
