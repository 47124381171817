import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { getUnknownFrameworkLabel } from "@webapp/strategy/services/utility/strategy-conversation.utils";
import { ChatQuestionVM, ConversationChatEntryVM, ConversationVM, ResultBlockType, UserDTO } from "../../models/chat.vm-models";

const PREDEFINED_FRAMEWORKS_PROMPTS = [
  {
    label: "PEST",
    action: ResultBlockType.PEST,
    question: "Generate a PEST analysis",
  },
  {
    label: "SWOT",
    action: ResultBlockType.SWOT,
    question: "Generate a SWOT analysis",
  },
  {
    label: "Porter's Five Forces",
    action: ResultBlockType.Porter,
    question: "Generate a Porter's Five Forces analysis",
  },
  {
    label: "Balanced Scorecard",
    action: ResultBlockType.BalancedScorecard,
    question: "Generate a Balanced Scorecard",
  },
  {
    label: "Blue Ocean ",
    action: ResultBlockType.BlueOcean,
    question: "Generate a Blue Ocean ",
  },
  {
    label: "Ansoff Matrix",
    action: ResultBlockType.Ansoff,
    question: "Generate an Ansoff Matrix",
  },
  {
    label: "BCG Matrix",
    action: ResultBlockType.BCG,
    question: "Analyze using BCG Matrix",
  },
  {
    label: "Scenario Planning",
    action: ResultBlockType.ScenarioPlanning,
    question: "Analyze using Scenario Planning",
  },
  {
    label: "Gap Analysis",
    action: ResultBlockType.GapAnalysis,
    question: "Analyze using Gap Analysis",
  },
  {
    label: "Value Chain Analysis",
    action: ResultBlockType.ValueChainAnalysis,
    question: "Analyze using Value Chain Analysis",
  },
  {
    label: "Benchmarking",
    action: ResultBlockType.Benchmarking,
    question: "Analyze using Benchmarking",
  },
  {
    label: "McKinsey 7S Model",
    action: ResultBlockType.McKinsey7SModel,
    question: "Analyze using McKinsey 7S Model",
  },
  {
    label: "Value Proposition Canvas",
    action: ResultBlockType.ValuePropositionCanvas,
    question: "Analyze using Value Proposition Canvas",
  },
  {
    label: "Growth Share Matrix",
    action: ResultBlockType.GrowthShareMatrix,
    question: "Analyze using Growth Share Matrix",
  },
  {
    label: "Decision Trees",
    action: ResultBlockType.DecisionTrees,
    question: "Analyze using Decision Trees",
  },
  {
    label: "Root Cause Analysis",
    action: ResultBlockType.RootCauseAnalysis,
    question: "Analyze using Root Cause Analysis",
  },
  {
    label: "Fishbone Diagram",
    action: ResultBlockType.FishboneDiagram,
    question: "Analyze using Fishbone Diagram",
  },
  {
    label: "Pareto Analysis",
    action: ResultBlockType.ParetoAnalysis,
    question: "Analyze using Pareto Analysis",
  },
  {
    label: "Six Sigma",
    action: ResultBlockType.SixSigma,
    question: "Analyze using Six Sigma",
  },
  {
    label: "Lean Thinking",
    action: ResultBlockType.LeanThinking,
    question: "Analyze using Lean Thinking",
  },
  {
    label: "The 4Ps of Marketing",
    action: ResultBlockType.The4PsOfMarketing,
    question: "Analyze using The 4Ps of Marketing",
  },
  {
    label: "The 5Ps Model",
    action: ResultBlockType.The5PsModel,
    question: "Analyze using The 5Ps Model",
  },
  {
    label: "Scenario Analysis",
    action: ResultBlockType.ScenarioAnalysis,
    question: "Analyze using Scenario Analysis",
  },
  {
    label: "Company Culture Analysis",
    action: ResultBlockType.CompanyCultureAnalysis,
    question: "Analyze using Company Culture Analysis",
  },
  {
    label: "Game Theory",
    action: ResultBlockType.GameTheory,
    question: "Analyze using Game Theory",
  },
  {
    label: "Feasibility Study",
    action: ResultBlockType.FeasibilityStudy,
    question: "Analyze using Feasibility Study",
  },
  {
    label: "Risk Reward Analysis",
    action: ResultBlockType.RiskRewardAnalysis,
    question: "Analyze using Risk Reward Analysis",
  },
];

@Component({
  selector: "ui-ai-chat",
  templateUrl: "./ui-ai-chat.component.html",
  styleUrls: ["./ui-ai-chat.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAiChatComponent implements OnChanges {
  @Input() public usersMap: Record<string, UserDTO> | undefined;
  @Input() public conversation: ConversationVM | undefined;
  @Input() public suggestedFrameworks: string[] | null = null;
  @Output() public readonly refreshContent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly retryErroredMessage: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly provideFeedback: EventEmitter<{
    message: ConversationChatEntryVM;
    type: "positive" | "negative";
  }> = new EventEmitter<{
    message: ConversationChatEntryVM;
    type: "positive" | "negative";
  }>();
  @Output()
  public readonly askQuestion: EventEmitter<ChatQuestionVM> = new EventEmitter<ChatQuestionVM>();
  @Output()
  public readonly addContent: EventEmitter<ConversationChatEntryVM> = new EventEmitter<ConversationChatEntryVM>();

  public validSuggestedFrameworks: string[] = [];

  public executeSuggestedFramework(framework: string): void {
    const prompt = PREDEFINED_FRAMEWORKS_PROMPTS.find((prompt) => prompt.action === framework);
    if (prompt) {
      this.askQuestion.emit({
        type: prompt.action,
        question: prompt.question,
      });
    } else {
      this.askQuestion.emit({
        type: ResultBlockType.Text,
        question: `Analyze using ${getUnknownFrameworkLabel(framework)}`,
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.suggestedFrameworks) {
      if (changes.suggestedFrameworks.currentValue) {
        this.validSuggestedFrameworks = changes.suggestedFrameworks.currentValue as string[];
      } else {
        this.validSuggestedFrameworks = [];
      }
    }
  }
}
