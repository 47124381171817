import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { DecisionDisplayItem } from "../../models/strategy.vm-models";

@Component({
  selector: "bet-context-status",
  templateUrl: "./bet-context-status.component.html",
  styleUrls: ["./bet-context-status.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiTooltipModule, UiIconModule, CommonModule],
})
export class BetContextStatusComponent {
  @Input({ required: true }) public item: DecisionDisplayItem;

  public get uiType(): string {
    if (this.item.status === "ERROR") {
      return "caution";
    }
    if (this.item.status === "SUCCESS") {
      return "check";
    }
    if (this.item.status === "LOADING") {
      return "loading";
    }
  }

  public get statusClass(): string {
    if (this.item.status === "ERROR") {
      return "error";
    }
    if (this.item.status === "SUCCESS") {
      return "success";
    }
    if (this.item.status === "LOADING") {
      return "loading";
    }
  }
}
