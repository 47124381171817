import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DocumentIngestionVM } from "../../services/ingestion/ingestion.models";

@Injectable({ providedIn: "root" })
export class UploadStateService {
  private uploadedDocumentsSubject = new Subject<DocumentIngestionVM[]>();
  private missedUpdates = new Array<{ documentSourceKey: string; document: Partial<DocumentIngestionVM> }>();
  private currentDocuments: DocumentIngestionVM[] = [];

  public readonly uploadedDocuments$: Observable<DocumentIngestionVM[]> = this.uploadedDocumentsSubject.asObservable();

  public reset(): void {
    this.currentDocuments = [];
    this.uploadedDocumentsSubject.next([]);
  }

  public getCurrentDocuments(): DocumentIngestionVM[] {
    return this.currentDocuments;
  }

  public addDocument(document: DocumentIngestionVM): void {
    if (this.currentDocuments.some((d) => d.sourceKey === document.sourceKey)) return;

    this.currentDocuments = [...this.currentDocuments, document];
    this.uploadedDocumentsSubject.next(this.currentDocuments);
  }

  public addDocuments(documents: DocumentIngestionVM[]): void {
    const newDocuments = documents.filter((doc) => !this.currentDocuments.some((d) => d.sourceKey === doc.sourceKey));

    if (newDocuments.length === 0) return;

    this.currentDocuments = [...this.currentDocuments, ...newDocuments];
    this.uploadedDocumentsSubject.next(this.currentDocuments);
  }

  public updateDocument(documentSourceKey: string, document: Partial<DocumentIngestionVM>): void {
    if (!this.currentDocuments.some((d) => d.sourceKey === documentSourceKey)) {
      this.missedUpdates.push({ documentSourceKey, document });
      return;
    }

    let updatedDocuments = this.currentDocuments.map((d) => (d.sourceKey === documentSourceKey ? { ...d, ...document } : d));

    if (document.sourceKey) {
      const newMissedUpdates = new Array<{ documentSourceKey: string; document: Partial<DocumentIngestionVM> }>();

      for (const update of this.missedUpdates) {
        if (update.documentSourceKey === document.sourceKey) {
          updatedDocuments = updatedDocuments.map((d) => (d.sourceKey === update.documentSourceKey ? { ...d, ...update.document } : d));
        } else {
          newMissedUpdates.push(update);
        }
      }
      this.missedUpdates = newMissedUpdates;
    }

    this.currentDocuments = updatedDocuments;
    this.uploadedDocumentsSubject.next(this.currentDocuments);
  }

  public removeDocument(documentSourceKey: string): void {
    this.currentDocuments = this.currentDocuments.filter((d) => d.sourceKey !== documentSourceKey);
    this.uploadedDocumentsSubject.next(this.currentDocuments);
  }
}
