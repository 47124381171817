<article>
  <header [ngClass]="{ 'export-page-break': content.exportPagebreak }">
    {{ content.title === "Title" ? content.description : content.title }}
  </header>
  <actionable-text-block
    *ngIf="content.title !== 'Title' && !content.loading"
    [content]="content.description"
    [contextMenuAvailable]="false"
    [enablePlaceholder]="true"
    (contentChanged)="contentChanged.emit($event)"></actionable-text-block>
  <ui-skeleton-line *ngIf="content.title !== 'Title' && content.loading" [style]="{ height: '20px', width: '100%' }" [uiActive]="true" />
  <ui-skeleton-line *ngIf="content.title !== 'Title' && content.loading" [style]="{ height: '20px', width: '100%' }" [uiActive]="true" />
</article>
