import { HttpErrorResponse } from "@angular/common/http";
import { Observable, catchError, pipe, throwError } from "rxjs";

export function catchHttpError<T, O extends Observable<unknown>>(
  ...args: Parameters<typeof catchError<T, O | Observable<never>>>
): ReturnType<typeof catchError<T, O | Observable<never>>> {
  const selector = args[0];

  return pipe(
    catchError<T, O | Observable<never>>((unknownError: unknown, caught: Observable<T>) => {
      if (unknownError instanceof HttpErrorResponse) {
        return selector(unknownError, caught);
      }

      return throwError(() => unknownError);
    })
  );
}
