import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiAvatarModule } from "@quantive/ui-kit/avatar";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { UiToggleModule } from "@quantive/ui-kit/toggle";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { AlertMessageComponent } from "../alert-message/alert-message.component";
import { IntervalBasedLoadingCardComponent } from "../interval-based-loading-card/interval-based-loading-card.component";
import { TextReferencesComponent } from "../text-references/text-references.component";
import { AiChatAnsoffBlockComponent } from "./components/ai-chat-ansoff-block/ai-chat-ansoff-block.component";
import { AiChatBalancedScorecardBlockComponent } from "./components/ai-chat-balanced-scorecard-block/ai-chat-balanced-scorecard-block.component";
import { AiChatBlueOceanBlockComponent } from "./components/ai-chat-blue-ocean-block/ai-chat-blue-ocean-block.component";
import { AiChatGoalsBlockComponent } from "./components/ai-chat-goals-block/ai-chat-goals-block.component";
import { AiChatKpisBlockComponent } from "./components/ai-chat-kpis-block/ai-chat-kpis-block.component";
import { AiChatPestBlockComponent } from "./components/ai-chat-pest-block/ai-chat-pest-block.component";
import { AiChatPorterBlockComponent } from "./components/ai-chat-porter-block/ai-chat-porter-block.component";
import { AiChatSwotBlockComponent } from "./components/ai-chat-swot-block/ai-chat-swot-block.component";
import { ChatAskQuestionComponent } from "./components/chat-ask-question/chat-ask-question.component";
import { ChatBlockActionsComponent } from "./components/chat-block-actions/chat-block-actions.component";
import { ChatBlockAiComponent } from "./components/chat-block-ai/chat-block-ai.component";
import { ChatBlockHumanComponent } from "./components/chat-block-human/chat-block-human.component";
import { ChatBlockComponent } from "./components/chat-block/chat-block.component";
import { ChatContentEmptyComponent } from "./components/chat-content-empty/chat-content-empty.component";
import { ChatContentComponent } from "./components/chat-content/chat-content.component";
import { ChatLoadingBlockComponent } from "./components/chat-loading-block/chat-loading-block.component";
import { GenericStructuredChatBlockComponent } from "./components/generic-structured-chat-block/generic-structured-chat-block.component";
import { UiAiChatComponent } from "./components/ui-ai-chat/ui-ai-chat.component";

@NgModule({
  imports: [
    UiAvatarModule,
    CommonModule,
    FormsModule,
    UiButtonModule,
    UiIconModule,
    UiSkeletonModule,
    UiToggleModule,
    TextReferencesComponent,
    AlertMessageComponent,
    IntervalBasedLoadingCardComponent,
    FeatureTogglesModule,
    UiDropdownModule,
  ],
  declarations: [
    UiAiChatComponent,
    ChatContentComponent,
    ChatAskQuestionComponent,
    ChatBlockComponent,
    ChatBlockHumanComponent,
    ChatBlockAiComponent,
    ChatBlockActionsComponent,
    AiChatAnsoffBlockComponent,
    AiChatBalancedScorecardBlockComponent,
    AiChatBlueOceanBlockComponent,
    AiChatPestBlockComponent,
    AiChatPorterBlockComponent,
    AiChatSwotBlockComponent,
    AiChatGoalsBlockComponent,
    AiChatKpisBlockComponent,
    GenericStructuredChatBlockComponent,
    ChatContentEmptyComponent,
    ChatLoadingBlockComponent,
  ],
  exports: [UiAiChatComponent],
})
export class UiAiChatModule {}
