import sanitizeHtml from "sanitize-html";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeam } from "@gtmhub/teams";
import { Assignee, UserAssignee } from "@webapp/assignees/models/assignee.models";
import { assigneeFromRedux } from "@webapp/assignees/utils/assignee.utils";
import { SearchOperatorsEnum } from "@webapp/search/models/search-api.models";
import { Search, SearchDTO } from "@webapp/search/models/search.models";
import { marked } from "@webapp/shared/libs/marked";
import { GH_BLOT_ATTRIBUTES } from "@webapp/shared/rich-text-editor/blots/blot-attributes";
import { GH_BLOT_CLASSES } from "@webapp/shared/rich-text-editor/blots/blot-classes";
import { OKRLinkItem, OKRLinkItemType } from "./rich-text-editor.models";

export const searchItemToUserAssignee = (user: Search<"users">): UserAssignee => ({
  id: user.id,
  type: "user",
  name: `${user.fields.firstName} ${user.fields.lastName}`,
  email: user.fields.auth0Cache.email,
  picture: user.fields.picture || user.fields.auth0Cache.picture,
});

export const splitTitleFromBulletList = (text: string): string => {
  const regex = new RegExp(/\n[*_~a-zA-Z]+/g);
  const matches = text.match(regex);

  if (matches) {
    let replaced = text;

    matches.forEach((m) => {
      replaced = replaced.replace(m, "\n\n" + m);
    });
    return replaced;
  }

  return text;
};

export const stripMarkdownNewLinesAfterLineN = (text: string, n?: number): string => {
  if (!n) {
    return text;
  }

  return text.split("\n").slice(0, n).join("\n");
};

export const convertMarkdownToRichText = (text: string): string => {
  let trimmedTextWithReplacedNewLines = text.trim().replace(/^\n/gm, "\n<br>\n\n");
  trimmedTextWithReplacedNewLines = splitTitleFromBulletList(trimmedTextWithReplacedNewLines);

  const html = marked
    .parse(trimmedTextWithReplacedNewLines, {
      breaks: true,
    })
    .trim();

  return sanitizeRichTextHtml(html);
};

export const isPreventBlurClass = (el: Element): boolean => {
  const classesToExclude = ["search-gif", "post-button", "input-text-field-box", "ql-editor"];

  return classesToExclude.some((classToExclude) => el?.classList?.contains?.(classToExclude));
};

/**
 * Sanitizes the HTML but allows specific classes and attributes related to the Rich text Editor
 *
 * @param html
 */
export const sanitizeRichTextHtml = (html: string): string => {
  return sanitizeHtml(html, {
    allowedClasses: { span: GH_BLOT_CLASSES },
    allowedAttributes: {
      a: ["href"],
      span: GH_BLOT_ATTRIBUTES,
      strong: ["style"],
    },
    allowedStyles: {
      strong: {
        "background-color": [/#([A-Fa-f0-9]{6})/],
      },
    },
  });
};

/**
 * Uses the input string and parses the HTML, then return the `textContent`
 * This way escaped characters are converted back, e.g. `&amp;` -> `&`, `&lt;` -> `<`, but all HTML is stripped, e.g. script elements
 *
 * @param input
 */
export const stripHtmlElements = (input: string): string => {
  // DOMParser does NOT execute JS
  const doc = new DOMParser().parseFromString(input, "text/html");

  return doc.documentElement.textContent;
};

// Format is @[NAME:EMAIL:ID]
const mentionGroup = "([^:[\\]]+?)";
export const MENTION_PATTERN = `@\\[${mentionGroup}:${mentionGroup}:${mentionGroup}\\]`;
export const OKR_LINK_SELECTOR_PATTERN = `#\\[${mentionGroup}:${mentionGroup}:${mentionGroup}\\]`;
export const GOAL_ICON_PATH = "https://cdn.quantivestatic.com/dist/img/icons/emails-v3/objective.png";
export const METRIC_ICON_PATH = "https://cdn.quantivestatic.com/dist/img/icons/emails-v3/key-result.png";
export const KPI_ICON_PATH = "https://cdn.quantivestatic.com/dist/img/icons/emails-v3/kpi.png";
export const UNKNOWN_AVATAR_ICON_PATH = "/dist/img/icons/unknown.svg";

export const searchItemToOKRLinkItem = (item: Search<"goals"> | Search<"metrics"> | Search<"kpis">): OKRLinkItem => ({
  id: item.id,
  type: item.collectionName,
  title: encodeURIComponent(item.fields.name),
  owners: getAssigneesByIdsFromRedux(item.fields.ownerIds),
  ...(item.collectionName === "goals" && {
    sessionTitle: item.fields.session?.title,
  }),
  ...(item.collectionName === "metrics" && {
    sessionTitle: item.fields.session?.title,
  }),
});

export const getAssigneesByIdsFromRedux = (ids: string[]): Assignee[] => {
  const state: IAssigneesStoreState = reduxStoreContainer.reduxStore.getState();
  return ids.reduce((assignees, id) => {
    return [...assignees, assigneeFromRedux(state, id)];
  }, []);
};

export const okrMappingRecord: Record<string, string> = {
  goals: "Objective",
  metrics: "Key Result",
  kpis: "KPI",
};

export const okrIconMappingRecord: Record<string, string> = {
  goals: GOAL_ICON_PATH,
  metrics: METRIC_ICON_PATH,
  kpis: KPI_ICON_PATH,
};

export const generateOKRIconByType = (type: OKRLinkItemType, className?: string): HTMLImageElement => {
  const icon = document.createElement("img");

  if (className) {
    icon.className = className;
  }

  icon.src = okrIconMappingRecord[type];
  icon.alt = okrMappingRecord[type];

  return icon;
};

export const getTeamIdsByUserId = (teams: ITeam[], userId: string): string[] => {
  return teams.reduce((teamIds, team) => {
    if (team.members?.includes(userId) || team?.manager === userId) {
      return [...teamIds, team.id];
    }

    return teamIds;
  }, []);
};

export const convertResponseItemsToOKRLinkItems = (responseItems: (Search<"goals"> | Search<"metrics"> | Search<"kpis">)[]): OKRLinkItem[] => {
  return responseItems.reduce((items, item) => {
    return [...items, searchItemToOKRLinkItem(item)];
  }, []);
};

export const prioritizeAndGroupItems = (items: OKRLinkItem[], personal: string[], team: string[], otherTeams: string[]): OKRLinkItem[] => {
  const personalItems = items.filter((item) => item.owners.some((owner) => personal.includes(owner.id)));
  const teamItems = items.filter((item) => item.owners.some((owner) => team.includes(owner.id)));
  const otherTeamItems = items.filter((item) => item.owners.some((owner) => otherTeams.includes(owner.id)));

  return [...groupOKRLinkItems(personalItems), ...groupOKRLinkItems(teamItems), ...groupOKRLinkItems(otherTeamItems)];
};

export const groupOKRLinkItems = (items: OKRLinkItem[]): OKRLinkItem[] => {
  const goalsGroup = items.filter((item) => item.type === "goals");
  goalsGroup.sort(okrLinkSortPredicate);

  const metricsGroup = items.filter((item) => item.type === "metrics");
  metricsGroup.sort(okrLinkSortPredicate);

  const kpisGroup = items.filter((item) => item.type === "kpis");
  kpisGroup.sort(okrLinkSortPredicate);

  return [...goalsGroup, ...metricsGroup, ...kpisGroup];
};

const okrLinkSortPredicate = (a: OKRLinkItem, b: OKRLinkItem): number => {
  if (a.title > b.title) {
    return 1;
  }

  if (a.title < b.title) {
    return -1;
  }

  return 0;
};

export const getUniqueItems = (data: OKRLinkItem[], limit: number): OKRLinkItem[] => {
  const uniqueIds = new Set();
  const uniqueItems = [];

  for (const item of data) {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      uniqueItems.push(item);
    }
    if (uniqueItems.length == limit) {
      break;
    }
  }

  return uniqueItems;
};

export const buildOKRLinkSelectorInitialRequest = (params: { ownerIds: string[]; sessionStatuses: string[]; accessGoals: boolean; accessKPIs: boolean }): SearchDTO => {
  const searchRequests = [];

  if (params.accessGoals) {
    searchRequests.push({
      collectionName: "goals",
      searchConditions: [
        {
          fieldName: "ownerIds",
          fieldCondition: {
            operator: "in",
            value: params.ownerIds,
          },
        },
        {
          fieldName: "session.status",
          fieldCondition: {
            operator: "in",
            value: params.sessionStatuses,
          },
        },
      ],
      responseFields: ["name", "ownerIds", "session.title"],
    });

    searchRequests.push({
      collectionName: "metrics",
      searchConditions: [
        {
          fieldName: "ownerIds",
          fieldCondition: {
            operator: "in",
            value: params.ownerIds,
          },
        },
        {
          fieldName: "session.status",
          fieldCondition: {
            operator: "in",
            value: params.sessionStatuses,
          },
        },
      ],
      responseFields: ["name", "ownerIds", "session.title"],
    });
  }

  if (params.accessKPIs) {
    searchRequests.push({
      collectionName: "kpis",
      searchConditions: [
        {
          fieldName: "ownerIds",
          fieldCondition: {
            operator: "in",
            value: params.ownerIds,
          },
        },
      ],
      responseFields: ["name", "ownerIds"],
    });
  }

  return {
    searchRequests,
  };
};

export const buildOKRLinkSelectorSearchBody = (searchTerm: string, params: { accessGoals: boolean; accessKPIs: boolean }): SearchDTO => {
  const searchRequests = [];

  if (params.accessGoals) {
    searchRequests.push({
      collectionName: "goals",
      ...(searchTerm && {
        searchFields: [{ name: "name" }, { name: "description" }, { name: "assignees.name" }],
        responseFields: ["name", "ownerIds", "session.title"],
      }),
    });

    searchRequests.push({
      collectionName: "metrics",
      ...(searchTerm && {
        searchFields: [{ name: "name" }, { name: "description" }, { name: "assignees.name" }],
        responseFields: ["name", "ownerIds", "session.title"],
      }),
    });
  }

  if (params.accessKPIs) {
    searchRequests.push({
      collectionName: "kpis",
      ...(searchTerm && {
        searchFields: [{ name: "name" }, { name: "description" }, { name: "assignee.name" }],
        responseFields: ["name", "ownerIds"],
      }),
    });
  }

  return {
    searchRequests,
    ...(searchTerm && {
      searchTerm,
      operator: SearchOperatorsEnum.matchPrefix,
    }),
  };
};

export const isNameRestricted = (title: string): boolean => {
  const restrictedNames = ["Restricted Objective", "Restricted Key Result", "Restricted KPI", "Deleted Objective", "Deleted Key Result", "Deleted KPI"];

  return restrictedNames.includes(title);
};
