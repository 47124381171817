import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { restLayerFieldsToQueryString } from "@webapp/core/http/utils/query-string.utils";
import { QueryParams } from "../models/query-params.model";
import { RequestConfig } from "../models/request-config.model";
import { RestLayerFields, RestLayerRequest } from "../models/rest-layer-request.models";
import { HttpEncoderService } from "./http-encoder.service";

enum ApiVersionEnum {
  v1 = "v1",
  v2 = "v2",
}

const removeLeadingSlash = (path?: string): string => (path ? (path[0] === "/" ? path.substr(1) : path) : "");

@Injectable({
  providedIn: "root",
})
export abstract class PlainBaseApiService {
  private baseUrl: string;
  private webSocketsApi: string;
  private webSocketsStrategyApi: string;

  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService
  ) {
    this.baseUrl = apiEndpointService.getApiBaseUrl();

    const currentDCWebSocketApiHostname = apiEndpointService.getWsBaseUrl();

    if (apiEndpointService.envName === "local") {
      this.webSocketsApi = `${currentDCWebSocketApiHostname}/api`;
    } else {
      this.webSocketsApi = `${currentDCWebSocketApiHostname}/results/api`;
    }
    this.webSocketsStrategyApi = `${currentDCWebSocketApiHostname}/strategy/api`;
  }

  protected getApiEndpointV1 = (path: string): string => {
    return `${this.baseUrl}/api/${ApiVersionEnum.v1}/${path}`;
  };

  protected getApiEndpointV1WithAdditionalBase = (additionalBase: string, path: string): string => {
    return `${this.baseUrl}/${additionalBase}/api/${ApiVersionEnum.v1}/${path}`;
  };

  protected getApiEndpointV2WithAdditionalBase = (additionalBase: string, path: string): string => {
    return `${this.baseUrl}/${additionalBase}/api/${ApiVersionEnum.v2}/${path}`;
  };

  protected getApiEndpointV2 = (path: string): string => {
    return `${this.baseUrl}/api/${ApiVersionEnum.v2}/${path}`;
  };

  protected getCustomApiEndpoint = (path: string): string => {
    return `${this.baseUrl}/${path}`;
  };

  protected getWebSocketV2Endpoint = (path?: string): string => {
    return `wss://${this.webSocketsApi}/v2/sockets/${removeLeadingSlash(path)}`;
  };

  protected getWebSocketV1Endpoint = (path?: string): string => {
    return `wss://${this.webSocketsApi}/v1/${removeLeadingSlash(path)}`;
  };

  protected getWebSocketStrategyV1Endpoint = (path?: string): string => {
    return `wss://${this.webSocketsStrategyApi}/v1/${removeLeadingSlash(path)}`;
  };

  protected getWebSocketStrategyV2Endpoint = (path?: string): string => {
    return `wss://${this.webSocketsStrategyApi}/v2/${removeLeadingSlash(path)}`;
  };

  protected get$<ResponseType>(
    url: string,
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        }
  ): Observable<ResponseType> {
    return this.httpClient.get<ResponseType>(url, { params });
  }

  protected getAll$<ResponseType>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters?: RestLayerRequest<any>,
    config: RequestConfig = new RequestConfig()
  ): Observable<ResponseType> {
    return this.httpClient.get<ResponseType>(url, {
      params: this.encodeQueryParams<ResponseType>({
        queryParams: config.queryParams,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        filters,
      }),
      headers: config.headers,
      context: config.context,
    });
  }

  protected post$<ResponseType>(url: string, payload: Record<string, unknown> | unknown, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    return this.httpClient.post<ResponseType>(url, payload, {
      params: this.encodeQueryParams<ResponseType>({
        queryParams: config.queryParams,
      }),
    });
  }

  protected patch$<ResponseType>(url: string, payload: Record<string, unknown>): Observable<ResponseType> {
    return this.httpClient.patch<ResponseType>(url, payload);
  }

  protected put$<ResponseType>(url: string, payload: Record<string, unknown>): Observable<ResponseType> {
    return this.httpClient.put<ResponseType>(url, payload);
  }

  protected delete$<ResponseType>(url: string): Observable<ResponseType> {
    return this.httpClient.delete<ResponseType>(url);
  }

  private encodeQueryParams<ResponseType>(params: { queryParams: QueryParams; filters?: RestLayerRequest<ResponseType> }): HttpParams {
    const queryParams = { ...params.queryParams };

    if (params.filters) {
      for (const [key, value] of Object.entries(params.filters)) {
        if (typeof value === "undefined") {
          continue;
        }

        if (key === "filter") {
          queryParams[key] = `${JSON.stringify(value)}`;
          continue;
        }

        if (key === "sort") {
          queryParams[key] = `${value.join(",")}`;
          continue;
        }

        if (key === "fields") {
          queryParams[key] = restLayerFieldsToQueryString(value as RestLayerFields<ResponseType>);
          continue;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        queryParams[key] = value.toFixed(0);
      }
    }

    return new HttpParams({
      fromObject: { ...queryParams },
      encoder: new HttpEncoderService(),
    });
  }
}
