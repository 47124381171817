<section class="whiteboard-generation">
  <article class="suggesting-whiteboard" *ngIf="!generatedWhiteboardId">
    <figure>
      <ui-loading-indicator [uiSpinning]="true" uiSize="large"></ui-loading-indicator>
    </figure>
    <section class="information-text">
      <h2>Generating Objectives...</h2>
      <h5>Drafting Objectives in a newly created Whiteboard.</h5>
    </section>
  </article>
  <article class="whiteboard-ready" *ngIf="generatedWhiteboardId">
    <figure>
      <i class="fs-48px" ui-icon uiTheme="outline" uiType="whiteboards"></i>
    </figure>
    <section class="information-text">
      <h2>Your Whiteboard with Objectives is ready</h2>
      <h5>It contains a summary of your analysis and a top-level action plan with suggested objectives to achieve.</h5>
    </section>
    <button class="view-okrs-button" (click)="navigateToWhiteboard.emit()" type="submit" ui-button uiShape="round" uiSize="large" uiType="primary">
      View Objectives
      <i class="fs-16px" ui-icon uiTheme="outline" uiType="external-link"></i>
    </button>
  </article>
  <section class="actions">
    <button (click)="stopOKRGeneration.emit()" ui-button uiShape="round" uiType="link">
      <i class="fs-16px" *ngIf="generatedWhiteboardId" ui-icon uiTheme="outline" uiType="back"></i>
      {{ generatedWhiteboardId ? backButtonCaption : "Cancel" }}
    </button>
  </section>
</section>
