import { UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiAvatarModule } from "@quantive/ui-kit/avatar";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiMenuItemComponent } from "@quantive/ui-kit/menu";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { UiTabModule } from "@quantive/ui-kit/tab";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { TokenProviderProvider } from "@webapp/core/auth/token-provider.provider";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { PeopleSelectorModule } from "@webapp/shared/components/people-selector/people-selector.module";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { ErrorViewModule } from "@webapp/shared/error-view/error-view.module";
import { FileSizePipe } from "@webapp/shared/pipes/file-size.pipe";
import { PercentagePipe } from "@webapp/shared/pipes/percentage.pipe";
import { AccessProvidersModule } from "@webapp/shared/services/access/access-providers.module";
import { TraceElementDirective } from "@webapp/tracing/apm/trace-element.directive";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiDrawerModule } from "@webapp/ui/drawer/drawer.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { UiScrollableContentDirective } from "@webapp/ui/utils/directives/scrollable-content.directive";
import { ActionableHeaderComponent } from "./components/actionable-header/actionable-header.component";
import { ActionableTextBlockComponent } from "./components/actionable-text-block/actionable-text-block.component";
import { AsyncStatusComponent } from "./components/async-status/async-status.component";
import { BetContextStatusComponent } from "./components/bet-context-status/bet-context-status.component";
import { ConfirmDeleteStrategyItemComponent } from "./components/confirm-delete-strategy-item/confirm-delete-strategy-item.component";
import { ContextDrawerComponent } from "./components/context-drawer/context-drawer.component";
import { ContextFormComponent } from "./components/context-form/context-form.component";
import { CurrentUserAssigneeAvatarComponent } from "./components/current-user-assignee-avatar/current-user-assignee-avatar";
import { DecisionNewV2Component } from "./components/decision-new-v2/decision-new-v2.component";
import { DecisionV2Component } from "./components/decision-v2/decision-v2.component";
import { DecisionTypeBoxComponent } from "./components/decisions-page/decision-type-box/decision-type-box.component";
import { DecisionsPageComponent } from "./components/decisions-page/decisions-page.component";
import { DetailedStrategyPageComponent } from "./components/detailed-strategy-page/detailed-strategy-page.component";
import { DropdownMenuWithTriggerComponent } from "./components/dropdown-menu-with-trigger/dropdown-menu-with-trigger.component";
import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { UiAiChatModule } from "./components/evaluate-bet-page/components/ai-chat/ai-chat.module";
import { AlertMessageComponent } from "./components/evaluate-bet-page/components/alert-message/alert-message.component";
import { EvaluateBetContextComponent } from "./components/evaluate-bet-page/components/evaluate-bet-context/evaluate-bet-context.component";
import { EvaluateBetErrorsComponent } from "./components/evaluate-bet-page/components/evaluate-bet-errors/evaluate-bet-errors.component";
import { AreaFormComponent } from "./components/evaluate-bet-page/components/evaluate-bet-strategic-map/components/area-form/area-form.component";
import { HypothesisCardComponent } from "./components/evaluate-bet-page/components/evaluate-bet-strategic-map/components/hypothesis-card/hypothesis-card.component";
import { HypothesisFormComponent } from "./components/evaluate-bet-page/components/evaluate-bet-strategic-map/components/hypothesis-form/hypothesis-form.component";
import { EvaluateBetStrategicMapComponent } from "./components/evaluate-bet-page/components/evaluate-bet-strategic-map/evaluate-bet-strategic-map.component";
import { TextReferencesComponent } from "./components/evaluate-bet-page/components/text-references/text-references.component";
import { WhiteboardGenerationComponent } from "./components/evaluate-bet-page/components/whiteboard-generation/whiteboard-generation.component";
import { EvaluateBetPageComponent } from "./components/evaluate-bet-page/evaluate-bet-page.component";
import { GenerateStrategyPageComponent } from "./components/generate-strategy-page/generate-strategy-page.component";
import { GeneratedObjectivesPageComponent } from "./components/generated-objectives-page/generated-objectives-page.component";
import { PageWrapperComponent } from "./components/page-wrapper/page-wrapper.component";
import { AnsoffResultBlockComponent } from "./components/result-block/components/ansoff-result-block/ansoff-result-block.component";
import { BalancedScorecardResultBlockComponent } from "./components/result-block/components/balanced-scorecard-result-block/balanced-scorecard-result-block.component";
import { BlueOceanResultBlockComponent } from "./components/result-block/components/blue-ocean-result-block/blue-ocean-result-block.component";
import { ResultsBoxLabelComponent } from "./components/result-block/components/generic-results-box/components/results-box-label/results-box-label.component";
import { ResultsBoxLabelsListComponent } from "./components/result-block/components/generic-results-box/components/results-box-labels-list/results-box-labels-list.component";
import { PestResultBlockComponent } from "./components/result-block/components/pest-result-block/pest-result-block.component";
import { PorterResultBlockComponent } from "./components/result-block/components/porter-result-block/porter-result-block.component";
import { SwotResultBlockComponent } from "./components/result-block/components/swot-result-block/swot-result-block.component";
import { TitleDescriptionResultBlockComponent } from "./components/result-block/components/title-description-result-block/title-description-result-block.component";
import { ResultBlockComponent } from "./components/result-block/result-block.component";
import { ResultsHeaderComponent } from "./components/results-header/results-header.component";
import { ResultsSectionComponent } from "./components/results-section/results-section.component";
import { MessageBoxComponent } from "./components/shared/message-box/message-box.component";
import { StrategyAiCardLoadingListComponent } from "./components/shared/strategy-ai-card-loading-list/strategy-ai-card-loading-list.component";
import { StrategyAiCardComponent } from "./components/shared/strategy-ai-card/strategy-ai-card.component";
import { StrategyAiDropdownComponent } from "./components/shared/strategy-ai-dropdown/strategy-ai-dropdown.component";
import { StrategyAiHeaderComponent } from "./components/shared/strategy-ai-header/strategy-ai-header.component";
import { StrategyAiListComponent } from "./components/shared/strategy-ai-list/strategy-ai-list.component";
import { StrategyIntoActionPageComponent } from "./components/strategy-into-action-page/strategy-into-action-page.component";
import { AccountsProvidersModule } from "./services/accounts/accounts-providers.module";
import { CurrentAccountDTORepository } from "./services/accounts/services/current-account-dto.repository";
import { AsyncTasksProvidersModule } from "./services/async-tasks/async-tasks-providers.module";
import { StrategicBetsApiFactory } from "./services/bet/api-utils/strategic-bets-api-factory.service";
import { StrategicBetsApiV2Service } from "./services/bet/api-utils/v2/strategic-bets-api-v2.service";
import { StrategicBetsUrlBuilderV2Service } from "./services/bet/api-utils/v2/strategic-bets-url-builder-v2.service";
import { BetTasksStateService } from "./services/bet/bet-tasks-state.service";
import { BetMediatorService } from "./services/bet/bet.mediator";
import { BetLockService } from "./services/bet/bets-lock.service";
import { GenerateGoalsForBetMediator } from "./services/bet/generate-goals-for-bet/generate-goals-for-bet.mediator";
import { StrategicBetsService } from "./services/bet/strategic-bets.service";
import { StrategyConversationChatApiFactory } from "./services/chat/api-utils/strategy-conversation-chat-api-factory.service";
import { StrategyConversationChatApiV2Service } from "./services/chat/api-utils/v2/strategy-conversation-chat-api-v2.service";
import { StrategyConversationChatUrlBuilderV2Service } from "./services/chat/api-utils/v2/strategy-conversation-chat-url-builder-v2.service";
import { PerplexityUsageStateService } from "./services/chat/perplexity-usage-state.service";
import { StrategyConversationChatService } from "./services/chat/strategy-conversation-chat.service";
import { StrategyConfigApiFactory } from "./services/config/api-utils/strategy-config-api-factory.service";
import { StrategyConfigApiV2Service } from "./services/config/api-utils/v2/strategy-config-api-v2.service";
import { StrategyConfigUrlBuilderV2Service } from "./services/config/api-utils/v2/strategy-config-url-builder-v2.service";
import { StrategyConfigService } from "./services/config/strategy-config.service";
import { StrategyConversationContextApiFactory } from "./services/context/api-utils/strategy-conversation-context-api-factory.service";
import { StrategyConversationContextApiV2Service } from "./services/context/api-utils/v2/strategy-conversation-context-api-v2.service";
import { StrategyConversationContextUrlBuilderV2Service } from "./services/context/api-utils/v2/strategy-conversation-context-url-builder-v2.service";
import { StrategyConversationContextService } from "./services/context/strategy-conversation-context.service";
import { StrategyConversationApiFactory } from "./services/conversation/api-utils/strategy-conversation-api-factory.service";
import { StrategyConversationApiV2Service } from "./services/conversation/api-utils/v2/strategy-conversation-api-v2.service";
import { StrategyConversationUrlBuilderV2Service } from "./services/conversation/api-utils/v2/strategy-conversation-url-builder-v2.service";
import { StrategyConversationTasksStateService } from "./services/conversation/strategy-conversation-task-state.service";
import { StrategyConversationMediatorService } from "./services/conversation/strategy-conversation.mediator";
import { StrategyConversationService } from "./services/conversation/strategy-conversation.service";
import { DocumentsApiFactory } from "./services/documents/api-utils/documents-api-factory.service";
import { DocumentsApiV2Service } from "./services/documents/api-utils/v2/documents-api-v2.service";
import { DocumentsUrlBuilderV2Service } from "./services/documents/api-utils/v2/documents-url-builder-v2.service";
import { DocumentsMediatorService } from "./services/documents/documents-mediator.service";
import { DocumentsService } from "./services/documents/documents.service";
import { StrategyConversationReportService } from "./services/report/strategy-conversation-report.service";
import { UserSettingsProvidersModule } from "./services/user-settings/user-settings-providers.module";
import { ActionableTextContextService } from "./services/utility/actionable-text-context.service";
import { StrategiesTrackingService } from "./services/utility/strategies-tracking.service";
import { StrategyConversationExportService } from "./services/utility/strategy-conversation-export.service";
import { StrategyBetSocketApiService } from "./services/web-sockets/services/strategy-bet-socket-api.service";
import { StrategyBetSocketService } from "./services/web-sockets/services/strategy-bet-socket.service";
import { StrategySocketsApiV2Service } from "./services/web-sockets/services/strategy-sockets-api-v2.service";
import { StrategySocketsService } from "./services/web-sockets/services/strategy-sockets.service";
import { StrategyRoutingModule } from "./strategy-routing.module";

const socketProviders = [StrategySocketsService, StrategySocketsApiV2Service, StrategyBetSocketService];

export const strategicBetsProviders = [StrategicBetsUrlBuilderV2Service, StrategicBetsApiV2Service, StrategicBetsApiFactory, StrategicBetsService];

const strategyConversationProviders = [
  StrategyConversationUrlBuilderV2Service,
  StrategyConversationApiV2Service,
  StrategyConversationApiFactory,
  StrategyConversationService,
];

const strategyConversationChatProviders = [
  StrategyConversationChatUrlBuilderV2Service,
  StrategyConversationChatApiV2Service,
  StrategyConversationChatApiFactory,
  StrategyConversationChatService,
];

const strategyConversationContextProviders = [
  StrategyConversationContextUrlBuilderV2Service,
  StrategyConversationContextApiV2Service,
  StrategyConversationContextApiFactory,
  StrategyConversationContextService,
];

const strategyConversationReportProviders = [StrategyConversationReportService, StrategyConversationExportService];

const strategyConfigProviders = [StrategyConfigUrlBuilderV2Service, StrategyConfigApiV2Service, StrategyConfigApiFactory, StrategyConfigService];

const documentsProviders = [DocumentsUrlBuilderV2Service, DocumentsApiV2Service, DocumentsApiFactory, DocumentsService, DocumentsMediatorService];

@NgModule({
  imports: [
    CommonModule,
    StrategyRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UiButtonModule,
    UiIconModule,
    UiGridModule,
    UiFormModule,
    UiInputModule,
    UiTabModule,
    UiLoadingIndicatorModule,
    UiSkeletonModule,
    UiDropdownModule,
    UiDrawerModule,
    UiAvatarModule,
    UiAiChatModule,
    UiTooltipModule,
    UiAssigneeModule,
    NzUploadModule,
    PageWrapperComponent,
    DropdownModule,
    ErrorViewModule,
    TextReferencesComponent,
    ActionableHeaderComponent,
    MessageBoxComponent,
    DropdownMenuWithTriggerComponent,
    AlertMessageComponent,
    ErrorPageComponent,
    UserSettingsProvidersModule,
    FileSizePipe,
    PercentagePipe,
    AsyncStatusComponent,
    WhiteboardGenerationComponent,
    PeopleSelectorModule,
    CurrentUserAssigneeAvatarComponent,
    FeatureTogglesModule,
    StrategyAiHeaderComponent,
    StrategyAiCardComponent,
    StrategyAiCardLoadingListComponent,
    StrategyAiListComponent,
    StrategyAiDropdownComponent,
    UiScrollableContentDirective,
    BetContextStatusComponent,
    TraceElementDirective,
    GenerateStrategyPageComponent,
    ResultsSectionComponent,
    ResultBlockComponent,
    ResultsHeaderComponent,
    ContextDrawerComponent,
    ActionableTextBlockComponent,
    SwotResultBlockComponent,
    TitleDescriptionResultBlockComponent,
    AnsoffResultBlockComponent,
    PestResultBlockComponent,
    PorterResultBlockComponent,
    BlueOceanResultBlockComponent,
    BalancedScorecardResultBlockComponent,
    ResultsBoxLabelsListComponent,
    ResultsBoxLabelComponent,
    DetailedStrategyPageComponent,
    EvaluateBetPageComponent,
    DecisionsPageComponent,
    GeneratedObjectivesPageComponent,
    StrategyIntoActionPageComponent,
    DecisionTypeBoxComponent,
    ContextFormComponent,
    EvaluateBetContextComponent,
    EvaluateBetErrorsComponent,
    EvaluateBetStrategicMapComponent,
    HypothesisCardComponent,
    HypothesisFormComponent,
    UiMenuItemComponent,
    AccessProvidersModule,
    AreaFormComponent,
    AsyncTasksProvidersModule,
    ConfirmDeleteStrategyItemComponent,
    AccountsProvidersModule,
    UIRouterModule,

    DecisionNewV2Component,
    DecisionV2Component,
  ],
  providers: [
    ...socketProviders,
    StrategiesTrackingService,
    ...strategicBetsProviders,
    ...documentsProviders,
    ...strategyConfigProviders,
    ...strategyConversationProviders,
    ...strategyConversationChatProviders,
    ...strategyConversationContextProviders,
    ...strategyConversationReportProviders,
    ActionableTextContextService,
    BetLockService,
    BetMediatorService,
    GenerateGoalsForBetMediator,
    BetTasksStateService,
    StrategyConversationMediatorService,
    StrategyConversationTasksStateService,
    StrategyBetSocketApiService,
    PerplexityUsageStateService,
    CurrentAccountDTORepository,
    TokenProviderProvider,
  ],
})
export class StrategyModule {}
