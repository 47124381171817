import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "@webapp/shared/libs/dayjs";

/**
 * @description
 * the pipe returns the input in this format, today, yesterday, raw date for input > 2 days
 *  Today, 14:04 PM
    Yesterday, 14:04 PM
    1 November 2023, 14:04 PM
 */
@Pipe({
  name: "customDaysAgoPipe",
  standalone: true,
})
export class CustomDaysAgoPipe implements PipeTransform {
  public transform(value: string): string {
    const dateToCheck = dayjs(value);
    const now = dayjs();

    if (dateToCheck.isSame(now, "day")) {
      return "Today, " + dateToCheck.format("hh:mm A");
    } else if (dateToCheck.isSame(now.subtract(1, "day"), "day")) {
      return "Yesterday, " + dateToCheck.format("hh:mm A");
    } else {
      // For dates older than 30 days, show the full date
      return dateToCheck.format("D MMMM YYYY, hh:mm A");
    }
  }
}
