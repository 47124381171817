import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import { AsyncTasksApiFactory } from "./api-utils/async-tasks-api-factory.service";

@Injectable()
export class AsyncTasksService {
  constructor(private asyncTasksApiFactory: AsyncTasksApiFactory) {}

  public getCreateStrategyMapTasks$(betId: string): Observable<AsyncTaskVM[]> {
    return this.asyncTasksApiFactory.getApi().pipe(
      switchMap((api) =>
        api.getAsyncTasks$({
          itemId: betId,
          name: "create_strategic_map",
          accountId: storage.get("accountId"),
        })
      )
    );
  }

  public getTasksForDocuments$(documentIds: string[]): Observable<AsyncTaskVM[]> {
    const taskNames: AsyncTaskVM["name"][] = ["embed_document", "update_document_summary"];

    return this.asyncTasksApiFactory.getApi().pipe(
      switchMap((api) =>
        api.getAsyncTasks$({
          name: {
            $in: taskNames,
          },
          accountId: storage.get("accountId"),
          itemId: { $in: documentIds },
        })
      )
    );
  }

  public getAllTasksForDocuments$(): Observable<AsyncTaskVM[]> {
    const taskNames: AsyncTaskVM["name"][] = ["embed_document", "update_document_summary"];

    return this.asyncTasksApiFactory.getApi().pipe(
      switchMap((api) =>
        api.getAsyncTasks$({
          name: {
            $in: taskNames,
          },
          accountId: storage.get("accountId"),
        })
      )
    );
  }

  public getTasksForAccount$(taskNames: AsyncTaskVM["name"][]): Observable<AsyncTaskVM[]> {
    return this.asyncTasksApiFactory.getApi().pipe(
      switchMap((api) =>
        api.getAsyncTasks$({
          accountId: storage.get("accountId"),
          name: { $in: taskNames },
        })
      )
    );
  }

  public getTasksForAccountAndItemId$(itemId: string, taskNames: AsyncTaskVM["name"][]): Observable<AsyncTaskVM[]> {
    return this.asyncTasksApiFactory.getApi().pipe(
      switchMap((api) =>
        api.getAsyncTasks$({
          accountId: storage.get("accountId"),
          name: { $in: taskNames },
          itemId: itemId,
        })
      )
    );
  }

  public getTasksForAccountAndItemIds$(itemIds: string[], taskNames: AsyncTaskVM["name"][]): Observable<AsyncTaskVM[]> {
    return this.asyncTasksApiFactory.getApi().pipe(
      switchMap((api) =>
        api.getAsyncTasks$({
          accountId: storage.get("accountId"),
          name: { $in: taskNames },
          itemId: { $in: itemIds },
        })
      )
    );
  }

  public getTasksForBetIds$(betIds: string[], taskNames: AsyncTaskVM["name"][]): Observable<AsyncTaskVM[]> {
    return this.asyncTasksApiFactory.getApi().pipe(
      switchMap((api) =>
        api.getAsyncTasks$({
          accountId: storage.get("accountId"),
          name: { $in: taskNames },
          betId: { $in: betIds },
        })
      )
    );
  }
}
