<generic-results-box
  class="result-box"
  [data]="balancedScoreCard.financialPerspectives"
  (contentChanged)="changeContent($event, 'financialPerspectives')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Financial Perspective"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="balancedScoreCard.customerPerspectives"
  (contentChanged)="changeContent($event, 'customerPerspectives')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Customer Perspective"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="balancedScoreCard.internalProcessPerspectives"
  (contentChanged)="changeContent($event, 'internalProcessPerspectives')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Internal Process Perspective"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="balancedScoreCard.learningGrowthPerspectives"
  (contentChanged)="changeContent($event, 'learningGrowthPerspectives')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Learning and Growth Perspective"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="balancedScoreCard.alignmentAndSynergies"
  (contentChanged)="changeContent($event, 'alignmentAndSynergies')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Alignment and Synergies"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="balancedScoreCard.riskAndAdaptations"
  (contentChanged)="changeContent($event, 'riskAndAdaptations')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Risk and Adaptations"></generic-results-box>
