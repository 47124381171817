<header
  class="sp-py-48"
  description="Generate Objectives and Key Results from the strategic documents you have available."
  headerTitle="Turn strategy into Objectives"
  strategy-ai-header
  uiType="strategyai-develop">
  <button *ngIf="!limitReached" (click)="goToTurnStrategyIntoActions()" ui-button uiShape="round" uiSize="large" uiType="primary">Generate Objectives</button>
  <button *ngIf="limitReached" (click)="goToBilling()" ui-button uiShape="round" uiSize="large" uiType="primary">
    <i ui-icon uiSize="xs" uiTheme="outline" uiType="upgrade"></i>
    <span>Manage account</span>
  </button>
  <alert-message *ngIf="limitReached" (dismissAlert)="hideLimitReachedError()" dismissType="x-button" message="Your account type does not allow any more Decision entries." type="warning">
  </alert-message>
</header>

<article>
  <div class="inner-shadow" ui-scrollable-content>
    <div class="content">
      <ui-loading-indicator [uiIndicator]="loadingIndicator" [uiSpinning]="loading">
        <ng-container *ngIf="!hasError && contextGroups.length">
          <section>
            <ng-template #template let-context>
              <ng-template #name>
                <span [attr.id]="context.id">
                  {{ context.title || "Context " + context.id }}
                </span>
              </ng-template>
              <ng-template #dropdown>
                <strategy-ai-dropdown>
                  <ul class="dropdown-menu">
                    <li class="destructive" (click)="deleteContext(context.id)" role="presentation">
                      <i class="fs-16px sp-m-2" ui-icon uiType="trash-bin"></i>
                      <span>Delete Objective generation</span>
                    </li>
                  </ul>
                </strategy-ai-dropdown>
              </ng-template>

              <strategy-ai-card
                [created]="context.dateCreated"
                [dropdown]="dropdown"
                [name]="name"
                [ownerIds]="toCollaboratorId(context.collaborators)"
                [route]="['/strategies/strategy-into-action', context.id]">
              </strategy-ai-card>

              <bet-context-status [item]="context"> </bet-context-status>
            </ng-template>

            <strategy-ai-list [groups]="contextGroups" [templateRef]="template" [updatedItemId]="updatedContextId"> </strategy-ai-list>
          </section>
        </ng-container>

        <div class="no-bets" *ngIf="!hasError && !contextGroups.length">Past Objective generations will appear here.</div>

        <message-box *ngIf="hasError" [description]="deletionErrorText" (buttonAction)="fetchContexts()" buttonIcon="refresh" buttonLabel="Try again" icon="caution" size="medium" />

        <ng-template #loadingIndicator>
          <strategy-ai-card-loading-list></strategy-ai-card-loading-list>
        </ng-template>
      </ui-loading-indicator>
    </div>
  </div>
</article>
<ng-template #deletionError>
  <p class="deletion-error sp-mb-0">
    Unable to delete Objective generation. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
<ng-template #deletionErrorText>
  <p class="deletion-error sp-mb-0">
    Unable to load past Objective generations. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
