import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  ChatPorterForcesDataVM,
  StructuredChatBlockDisplayData,
  StructuredChatDataSection,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";

@Component({
  selector: "ui-ai-chat-porter-block",
  templateUrl: "./ai-chat-porter-block.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatPorterBlockComponent implements OnInit {
  public porterSections: StructuredChatBlockDisplayData | undefined;
  @Input() public porter: ChatPorterForcesDataVM | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const bargainingPowerOfBuyersSection: StructuredChatDataSection = this.transformSwotAspectToSection(
      this.porter?.buyersBargainingPowers,
      "Bargaining Power of Buyers"
    );
    const bargainingPowerOfSuppliersSection: StructuredChatDataSection = this.transformSwotAspectToSection(
      this.porter?.supplierBargainingPowers,
      "Bargaining Power of Suppliers"
    );
    const threatOfSubstitutesSection: StructuredChatDataSection = this.transformSwotAspectToSection(
      this.porter?.substituteThreats,
      "Threat of Substitute Products or Services"
    );
    const threatOfNewEntrantsSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.porter?.newEntrantThreats, "Threat of New Entrants");
    const rivalryAmongExistingCompetitorsSection: StructuredChatDataSection = this.transformSwotAspectToSection(
      this.porter?.competitionRivalry,
      "Rivalry Among Existing Competitors"
    );
    this.porterSections = [
      threatOfNewEntrantsSection,
      bargainingPowerOfSuppliersSection,
      bargainingPowerOfBuyersSection,
      threatOfSubstitutesSection,
      rivalryAmongExistingCompetitorsSection,
    ];
    this.cdr.markForCheck();
  }

  private transformSwotAspectToSection(swotAspect: StructuredDataAspectVM[] | undefined, title: string): StructuredChatDataSection {
    return {
      title,
      entries: swotAspect || [],
    };
  }
}
