import { StateService, TransitionService, UIRouterGlobals } from "@uirouter/angular";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { take } from "rxjs";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { fromTransitionHook } from "@webapp/core/routing/rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { NavigationEvents } from "@webapp/navigation/components/navigation/navigation.events";
import { INavItem, NavItemsSectionConfig } from "@webapp/navigation/models/nav-items-list.models";
import { checkOverflow } from "@webapp/ui/tooltip/tooltip.utils";
import { ItemState, getItemsScreenMap } from "../../config";

@Component({
  selector: "nav-list-item",
  templateUrl: "./nav-list-item.component.html",
  styleUrls: ["./nav-list-item.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListItemComponent implements OnInit, AfterViewInit {
  @Input()
  public config: NavItemsSectionConfig;
  @Input()
  public item: INavItem & { decisionId?: string };
  @Input()
  public itemIsFavorite: boolean;
  @Input()
  public canMoveUp: boolean;
  @Input()
  public canMoveDown: boolean;

  @Output()
  public readonly markItemAsFavorite = new EventEmitter<INavItem>();
  @Output()
  public readonly unmarkItemAsFavorite = new EventEmitter<INavItem>();
  @Output()
  public readonly removeFromSuggested = new EventEmitter<INavItem>();
  @Output()
  public readonly moveUp = new EventEmitter<INavItem>();
  @Output()
  public readonly moveDown = new EventEmitter<INavItem>();

  public isMenuOpened: boolean;
  public isActive: boolean;
  public isTitleOverflowing = false;

  private isOkrGridEnabled: boolean = false;

  constructor(
    private stateService: StateService,
    private routerGlobals: UIRouterGlobals,
    private transitionService: TransitionService,
    private analyticsService: AnalyticsService,
    private broadcastService: BroadcastService,
    private changeDetector: ChangeDetectorRef,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public ngOnInit(): void {
    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.OkrGrid)
      .pipe(take(1))
      .subscribe((isOkrGridEnabled) => (this.isOkrGridEnabled = isOkrGridEnabled));

    this.setIsActive();
    fromTransitionHook(this.transitionService, "onSuccess", {}).subscribe(() => {
      this.setIsActive();
    });
  }

  public ngAfterViewInit(): void {
    this.isTitleOverflowing = checkOverflow(this.item.id);

    if (this.isTitleOverflowing) {
      this.changeDetector.detectChanges();
    }
  }

  private setIsActive(): void {
    this.isActive = false;
    const itemStates = this.getStateAndScreenKey(this.item);

    if (itemStates.some((state) => state.paramsMap)) {
      const itemState = itemStates.find((state) => state.name === this.routerGlobals.current.name);
      const paramValue = this.routerGlobals.params[itemState?.paramsMap.id];

      if (this.item.decisionId) {
        this.isActive = itemState && paramValue === this.item.decisionId;
      } else {
        this.isActive = itemState && paramValue === this.item.id;
      }
    }

    this.changeDetector.markForCheck();
  }

  private getStateAndScreenKey(item: INavItem): ItemState[] {
    // in the case where we have a heterogenic container of navitems, we need to find the proper subType and use its state definition to navigate
    const itemScreen = getItemsScreenMap({ [FeatureFlag.OkrGrid]: this.isOkrGridEnabled })[this.config.itemsType];

    if (!itemScreen.itemState && itemScreen.subTypes) {
      const subType = itemScreen.subTypes[item.uxcType];

      if (subType.activeStates) {
        return subType.activeStates;
      }
      return [subType.itemState];
    }
    return [itemScreen.itemState];
  }

  private convertToSiblingIfNeeded(stateName: string): string {
    const currentState = this.routerGlobals.current.name;
    if (stateName[0] === "." && currentState.endsWith(stateName)) {
      return `^${stateName}`;
    }
    return stateName;
  }

  public handleItemEnterPress(item: INavItem, event: KeyboardEvent, itemElement: HTMLElement): void {
    if (!this.isMenuOpened && event.target === itemElement) {
      this.view(item);
    }
  }

  public view(item: INavItem & { decisionId?: string }): void {
    // add debounce if the user clicks the navigation several times
    const itemStates = this.getStateAndScreenKey(item);

    if (itemStates.length && !this.config.broadcastConfig.navItemActions.viewNavItem) {
      const itemState = itemStates[0];
      const params = itemState.paramsMap ? { [itemState.paramsMap.id]: item.id } : {};
      if (this.config.itemsGroupType === "suggested") {
        this.analyticsService.track("New Experience Suggested Click", { id: item.id, type: item.uxcType });
      }
      const stateName = this.convertToSiblingIfNeeded(itemState.name);

      if (item.uxcType === "whiteboard" && item.decisionId) {
        this.handleDecisionRedirect(item);
      } else {
        window.setTimeoutOutsideAngular(() => {
          this.stateService.go(stateName, params);
        });
      }
    }

    this.broadcastService.emit(NavigationEvents.SUB_NAVIGATION_ITEM_CLICKED, this.item);
  }

  public onMenuToggled(value: boolean): void {
    this.isMenuOpened = value;
  }

  private handleDecisionRedirect(item: INavItem & { decisionId?: string }): void {
    // This should be removed shortly (2-3 months) when we merge decisions with whiteboards
    this.stateService.go("gtmhub.decisionV2", {
      decisionId: item.decisionId,
    });
  }
}
