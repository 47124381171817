import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";

@Component({
  selector: "action-card",
  standalone: true,
  templateUrl: "./action-card.component.html",
  styleUrls: ["action-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiButtonModule],
})
export class ActionCardComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public buttonLabel: string;
  @Input() public image: string;
  @Output() public readonly actionButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  public buttonClicked(): void {
    this.actionButtonClicked.emit();
  }
}
