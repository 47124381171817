import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ICollection } from "@webapp/core/core.models";
import { CreateSuggestedGoal, SuggestedGoal, UpdateSuggestedGoal } from "../models/suggested-goals.models";
import { SuggestedGoalsApiService } from "./suggested-goals-api.service";

@Injectable()
export class SuggestedGoalsRepository {
  constructor(private suggestedGoalsApiService: SuggestedGoalsApiService) {}

  public getSuggestedGoals$(): Observable<ICollection<SuggestedGoal>> {
    return this.suggestedGoalsApiService.getAll$();
  }

  public getSuggestedGoal$(id: string): Observable<SuggestedGoal> {
    return this.suggestedGoalsApiService.get$(id);
  }

  public createSuggestedGoal$(suggestedGoal: CreateSuggestedGoal): Observable<SuggestedGoal> {
    return this.suggestedGoalsApiService.post$(suggestedGoal);
  }

  public updateSuggestedGoal$(id: string, suggestedGoal: UpdateSuggestedGoal): Observable<SuggestedGoal> {
    return this.suggestedGoalsApiService.put$(id, suggestedGoal);
  }

  public deleteSuggestedGoal$(id: string): Observable<void> {
    return this.suggestedGoalsApiService.delete$(id);
  }
}
