import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({
  selector: "whiteboard-upgrade",
  standalone: true,
})
export class WhiteboardUpgradeDirective extends UpgradeComponent {
  @Input() public whiteboardId: string;
  @Input() public isAIWhiteboard: boolean;

  constructor(elementRef: ElementRef, injector: Injector) {
    super("whiteboard", elementRef, injector);
  }
}
