import { UiButtonShape, UiButtonSize, UiButtonType } from "@quantive/ui-kit/button";
import { UiIconSize, UiThemeType } from "@quantive/ui-kit/icon";
import { Observable } from "rxjs";
import { BaseElementBuilder } from "@webapp/shared/builders/base-element-builder";
import { IBaseElementBuilder } from "@webapp/shared/models/base-element-builder.models";
import { ItemAction } from "../models/action-handler.models";
import { DisplayModes, ResponsiveModes } from "../models/top-nav-bar-buttons.models";

interface BaseUiButton extends IBaseElementBuilder {
  key: string;
  size: UiButtonSize;
  type: UiButtonType;
  shape: UiButtonShape;
  action: ItemAction;
  keyVisibilityMode: DisplayModes;
  keysByVisibilityMode?: Record<ResponsiveModes, string>; // if ommited the default key from BaseUIButton is used in both modes
  dataTestId?: string;
  ariaLabel?: string;
  isVisible$?: Observable<boolean>;
  track?: string;
  trackMetaData?: { [key: string]: string };
}

interface UiButtonOnly extends BaseUiButton {
  uiType: "buttonOnly";
}

export interface UiIconAndButton extends BaseUiButton {
  uiType: "iconAndButton";
  icon: {
    iconType: string;
    iconTheme: UiThemeType;
    iconSize?: UiIconSize;
  };
  iconVisibilityMode: DisplayModes;
}

export type UiButton = UiIconAndButton | UiButtonOnly;

export class UiButtonBuilder extends BaseElementBuilder<UiButton> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  public setKeyVisibilityMode(keyVisibilityMode: DisplayModes): UiButtonBuilder {
    this.elem.keyVisibilityMode = keyVisibilityMode;
    return this;
  }

  public setKeysByVisibilityMode(keysByVisibilityMode: Record<ResponsiveModes, string>): UiButtonBuilder {
    this.elem.keysByVisibilityMode = keysByVisibilityMode;
    return this;
  }

  public setType(
    option:
      | {
          uiType: "iconAndButton";
          buttonType: UiButtonType;
          buttonShape: UiButtonShape;
          buttonSize: UiButtonSize;
          iconType: string;
          iconTheme: UiThemeType;
          iconVisibilityMode: DisplayModes;
        }
      | {
          uiType: "buttonOnly";
          buttonType: UiButtonType;
          buttonShape: UiButtonShape;
          buttonSize: UiButtonSize;
        }
  ): UiButtonBuilder {
    this.elem.uiType = option.uiType;
    this.elem.type = option.buttonType;
    this.elem.shape = option.buttonShape;
    this.elem.size = option.buttonSize;

    if (option.uiType === "iconAndButton") {
      (this.elem as UiIconAndButton).icon = {
        iconType: option.iconType,
        iconTheme: option.iconTheme,
      };
      (this.elem as UiIconAndButton).iconVisibilityMode = option.iconVisibilityMode;
    }
    return this;
  }

  public setDataTestId(dataTestId: string): UiButtonBuilder {
    this.elem.dataTestId = dataTestId;
    return this;
  }

  public setAriaLabel(ariaLabel: string): UiButtonBuilder {
    this.elem.ariaLabel = ariaLabel;
    return this;
  }

  public setTrack(trackName: string): this {
    this.elem.track = trackName;
    return this;
  }

  public setTrackMetaData(metaData: { [key: string]: string }): UiButtonBuilder {
    this.elem.trackMetaData = metaData;
    return this;
  }
}
