import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable } from "rxjs";
import { StrategicMapVM } from "../../models/bets/strategic-bets.vm-models";
import { AsyncOperationPreviewState } from "../../models/strategy.vm-models";
import { AsyncTasksService } from "../async-tasks/async-tasks.service";
import { getLatestHypothesisTask } from "../utility/async-tasks.utils";
import { getTaskPreviewStatePeriodString } from "../utility/strategy-conversation.utils";

@UntilDestroy()
@Injectable()
export class StrategyConversationTasksStateService {
  constructor(private asyncTasksService: AsyncTasksService) {}

  private tasksSubject$: BehaviorSubject<Record<string, AsyncOperationPreviewState>> = new BehaviorSubject<Record<string, AsyncOperationPreviewState>>(null);

  private setTasks(conversationId: string, tasks: AsyncOperationPreviewState): void {
    this.tasksSubject$.next({
      ...this.tasksSubject$.value,
      [conversationId]: tasks,
    });
  }

  public setTaskSeenStatus(conversationId: string, options: { seen: boolean }): void {
    const task = this.tasksSubject$.value[conversationId];
    this.setTasks(conversationId, { ...task, seen: options.seen });
  }

  public get conversationTasks$(): Observable<Record<string, AsyncOperationPreviewState>> {
    return this.tasksSubject$.asObservable();
  }

  public setStatusAsLoaded(conversationId: string, doneDate: string, options: { seen: boolean } = { seen: false }): void {
    this.setTasks(conversationId, {
      state: "LOADED",
      tooltip: `Response was ready ${getTaskPreviewStatePeriodString(doneDate)}`,
      seen: options.seen,
    });
  }

  public setStatusAsFailed(conversationId: string, doneDate: string, options: { seen: boolean } = { seen: false }): void {
    this.setTasks(conversationId, {
      state: "FAILED",
      tooltip: `Unable to respond ${getTaskPreviewStatePeriodString(doneDate)}`,
      seen: options.seen,
    });
  }

  public setStatusAsLoading(conversationId: string, options: { seen: boolean } = { seen: false }): void {
    this.setTasks(conversationId, {
      state: "LOADING",
      tooltip: "Response is in progress...",
      seen: options.seen,
    });
  }

  public loadTaskState$(betId: string, strategicMap: StrategicMapVM): void {
    this.asyncTasksService
      .getTasksForBetIds$([betId], ["ask_question", "regenerate_chat_answer"])
      .pipe(untilDestroyed(this))
      .subscribe((tasks) => {
        strategicMap.areas.forEach((area) => {
          area.hypotheses.forEach((hypothesis) => {
            const latestTask = getLatestHypothesisTask(hypothesis, tasks);

            if (!latestTask) {
              return this.setStatusAsLoaded(hypothesis.conversationId, new Date().toISOString(), { seen: hypothesis.isSeen });
            }

            if (["STARTED", "PENDING"].includes(latestTask.status)) {
              this.setStatusAsLoading(hypothesis.conversationId);
            }

            if (!hypothesis.isSeen) {
              if (latestTask.status === "FAILURE") {
                this.setStatusAsFailed(hypothesis.conversationId, latestTask.dateDone);
              } else if (latestTask.status === "SUCCESS") {
                this.setStatusAsLoaded(hypothesis.conversationId, latestTask.dateDone, { seen: hypothesis.isSeen });
              }
            }
          });
        });
      });
  }
}
