import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { ISocketsApiService, SocketEndpointParams } from "../models/sockets-api.interface";

@Injectable()
export class StrategyBetSocketApiService extends PlainBaseApiService implements ISocketsApiService {
  private strategyBetId: string | null = null;

  set betId(value: string) {
    this.strategyBetId = value;
  }

  constructor(httpClient: HttpClient, apiEndpointService: ApiEndpointService) {
    super(httpClient, apiEndpointService);
  }

  public getSocketEndpoint = ({ token, accountId }: SocketEndpointParams): string => {
    return this.getWebSocketStrategyV2Endpoint(`ws/${this.strategyBetId}/subscribe?authorization=${token}&accountId=${accountId}`);
  };
}
