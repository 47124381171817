import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { Intercom } from "@gtmhub/shared/intercom";

@Component({
  standalone: true,
  selector: "alert-message",
  templateUrl: "./alert-message.component.html",
  styleUrls: ["./alert-message.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiButtonModule, UiIconModule],
})
export class AlertMessageComponent {
  @Input() public message: string;
  @Input() public projectContent: boolean;
  @Input() public buttonLabel: string;
  @Input() public attachContactSupport?: boolean;
  @Input() public type: "error" | "info" | "warning";
  @Input() public dismissType: "x-button" | "text-button" = "text-button";
  @Output() public readonly dismissAlert: EventEmitter<void> = new EventEmitter<void>();

  public onDismissAlertClick(): void {
    this.dismissAlert.emit();
  }

  public showIntercom(): void {
    Intercom("show");
  }
}
