import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class ContributorLockResponseStrategy implements QuantiveResultsSocketStrategy<"contributorLock"> {
  public messageType: QuantiveResultsSocketStrategy<"contributorLock">["messageType"] = "contributorLock";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"contributorLock">> {
    return pipe(filter(({ data }) => data.type === "contributor_lock"));
  }
}
