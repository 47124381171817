import { Injectable } from "@angular/core";
import { Observable, delayWhen, first, from, map, mergeMap, take } from "rxjs";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import { SettingsVisibilityService } from "./settings-visibility.service";

@Injectable()
export class SettingsSubNavStateService {
  public showSystemOptions$ = this.settingsVisibility.systemOptions.anyOption$;
  public showUserOptions$ = this.settingsVisibility.userOptions.anyOption$;
  public showIntegrationOptions$ = this.settingsVisibility.integrationsOptions.anyOption$;
  public showAccountOptions$ = this.settingsVisibility.accountOptions.anyOption$;
  public showMigrationOptions$ = this.settingsVisibility.systemOptions.vivagoals$;

  public systemOptions: SubNavOption[] = [
    {
      label: "methodology",
      icon: "map",
      state: "gtmhub.configuration.methodology",
      showOptionCondition$: this.settingsVisibility.systemOptions.methodology$,
    },
    {
      label: "tag_management",
      icon: "tag",
      state: "gtmhub.configuration.tags",
      showOptionCondition$: this.settingsVisibility.systemOptions.tagManagement$,
    },
    {
      label: "language",
      icon: "public",
      state: "gtmhub.configuration.language",
      showOptionCondition$: this.settingsVisibility.systemOptions.language$,
    },
    {
      label: "account_notifications",
      icon: "notifications",
      state: "gtmhub.configuration.accountNotificationSettings",
      showOptionCondition$: this.settingsVisibility.systemOptions.accountNotifications$,
    },
    {
      label: "okr_update_reminders_title",
      icon: "update-reminder",
      state: "gtmhub.configuration.okrUpdateReminders",
      showOptionCondition$: this.settingsVisibility.systemOptions.remindersAndReport$,
    },
    {
      label: "aggregated_report",
      icon: "email",
      state: "gtmhub.configuration.aggregatedReport",
      showOptionCondition$: this.settingsVisibility.systemOptions.remindersAndReport$,
    },
    {
      label: "features",
      icon: "wizard",
      state: "gtmhub.configuration.features",
      showOptionCondition$: this.settingsVisibility.systemOptions.features$,
    },
    {
      label: "custom_fields",
      icon: "drag-drop",
      state: "gtmhub.configuration.customFields",
      showOptionCondition$: this.settingsVisibility.systemOptions.customFields$,
    },
    {
      label: "insightboard_parameters",
      icon: "custom-field",
      state: "gtmhub.configuration.parameters",
      showOptionCondition$: this.settingsVisibility.systemOptions.insightboardParameters$,
    },
    {
      label: "automation",
      icon: "automation",
      state: "gtmhub.automationList",
      showOptionCondition$: this.settingsVisibility.systemOptions.automation$,
    },
    {
      label: "home_widgets",
      icon: "grid-view",
      state: "gtmhub.configuration.homeWidgets",
      showOptionCondition$: this.settingsVisibility.systemOptions.homeWidgets$,
    },
  ];
  public usersOptions: SubNavOption[] = [
    {
      label: "users_cap",
      icon: "users-group",
      state: "gtmhub.usersList",
      showOptionCondition$: this.settingsVisibility.userOptions.users$,
    },
    {
      label: "single_sign_on",
      icon: "security",
      state: "gtmhub.configuration.singleSignOn",
      showOptionCondition$: this.settingsVisibility.userOptions.singleSignOn$,
    },
    {
      label: "scim_provisioning",
      icon: "scim",
      state: "gtmhub.configuration.scim",
      showOptionCondition$: this.settingsVisibility.userOptions.scimProvisioning$,
    },
    {
      label: "group_to_role_mapping",
      icon: "role-mapping",
      state: "gtmhub.configuration.groupRoleMapping",
      showOptionCondition$: this.settingsVisibility.userOptions.groupToRoleMapping$,
    },
    {
      label: "manage_roles",
      icon: "live-demo",
      state: "gtmhub.roles",
      showOptionCondition$: this.settingsVisibility.userOptions.manageRoles$,
    },
  ];
  public integrationsOptions: SubNavOption[] = [
    {
      label: "data_sources",
      icon: "sql",
      state: "gtmhub.dataflow",
      showOptionCondition$: this.settingsVisibility.integrationsOptions.dataSources$,
    },
    {
      label: "installed_apps",
      icon: "integrations",
      state: "gtmhub.configuration.pluginList",
      showOptionCondition$: this.settingsVisibility.integrationsOptions.installedApps$,
    },
    {
      label: "api_tokens",
      icon: "key",
      state: "gtmhub.configuration.apiTokens",
      showOptionCondition$: this.settingsVisibility.integrationsOptions.apiTokens$,
    },
    {
      label: "Slack",
      icon: "slack",
      state: "gtmhub.configuration.slack",
      showOptionCondition$: this.settingsVisibility.integrationsOptions.slack$,
    },
    {
      label: "Jira",
      icon: "jira",
      state: "gtmhub.configuration.jira",
      showOptionCondition$: this.settingsVisibility.integrationsOptions.jira$,
    },
    {
      label: "workday",
      icon: "workday",
      state: "gtmhub.configuration.workday",
      showOptionCondition$: this.settingsVisibility.integrationsOptions.workday$,
    },
  ];
  public migrationOptions: SubNavOption[] = [
    {
      label: "Viva Goals",
      icon: "vivagoals",
      state: "gtmhub.configuration.vivagoalsconfig",
      showOptionCondition$: this.settingsVisibility.systemOptions.vivagoals$,
    },
  ];
  public accountOptions: SubNavOption[] = [
    {
      label: "account_and_domain",
      icon: "public",
      state: "gtmhub.configuration.accountNameDomainChange",
      showOptionCondition$: this.settingsVisibility.accountOptions.accountAndDomain$,
    },
    {
      label: "billing",
      icon: "credit-card",
      state: "gtmhub.configuration.billing",
      showOptionCondition$: this.settingsVisibility.accountOptions.billing$,
    },
    {
      label: "activity_history",
      icon: "billing-history",
      state: "gtmhub.configuration.activityHistory",
      showOptionCondition$: this.settingsVisibility.accountOptions.activityHistory$,
    },
  ];

  constructor(private settingsVisibility: SettingsVisibilityService) {
    this.usersOptions.splice(1, 0, this.getBadges());
  }

  public firstVisibleState$(): Observable<string> {
    const settingsOptions: Observable<SubNavOption> = from([...this.systemOptions, ...this.usersOptions, ...this.integrationsOptions, ...this.accountOptions]);

    return settingsOptions.pipe(
      delayWhen(() => this.settingsVisibility.showSettings$),
      map((s) =>
        s.showOptionCondition$.pipe(
          take(1),
          map((v) => {
            if (v) {
              return s;
            } else {
              return null;
            }
          })
        )
      ),
      mergeMap((v) => v),
      first((v) => !!v),
      map((s) => s.state)
    );
  }

  private getBadges(): SubNavOption {
    return {
      label: "badges",
      icon: "award",
      state: "gtmhub.badges",
      showOptionCondition$: this.settingsVisibility.userOptions.badges$,
    };
  }
}
