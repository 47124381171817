import { NgClass, NgFor, NgIf, NgTemplateOutlet, PercentPipe } from "@angular/common";
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { NzUploadComponent, NzUploadFile } from "ng-zorro-antd/upload";
import { Subscription, combineLatest } from "rxjs";
import { Intercom } from "@gtmhub/shared/intercom";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { FileSizePipe } from "@webapp/shared/pipes/file-size.pipe";
import { PercentagePipe } from "@webapp/shared/pipes/percentage.pipe";
import { UiFormControlComponent } from "@webapp/ui/form/components/form-control/form-control.component";
import { UiFormItemComponent } from "@webapp/ui/form/components/form-item/form-item.component";
import { UiColDirective } from "@webapp/ui/grid/col.directive";
import { UiRowDirective } from "@webapp/ui/grid/row.directive";
import { UiInputTextAreaComponent } from "@webapp/ui/input/components/input-text-area/input-text-area.component";
import { UiLoadingIndicatorComponent } from "@webapp/ui/loading-indicator/loading-indicator.component";
import { StrategyContextDocument, StrategyContextForm } from "../../models/strategy.vm-models";
import { nzUploadFile2StrategyContextDocument } from "../../services/documents/api-utils/document-mapper.utils";
import { DocumentsMediatorService } from "../../services/documents/documents-mediator.service";
import { AsyncStatusComponent } from "../async-status/async-status.component";

@UntilDestroy()
@Component({
  selector: "context-form",
  templateUrl: "./context-form.component.html",
  styleUrls: ["./context-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiLoadingIndicatorComponent,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    UiRowDirective,
    UiFormItemComponent,
    NzUploadComponent,
    UiIconModule,
    NgFor,
    UiTooltipModule,
    UiButtonComponent,
    AsyncStatusComponent,
    UiColDirective,
    UiFormControlComponent,
    UiInputTextAreaComponent,
    PercentPipe,
    FileSizePipe,
    PercentagePipe,
    NgTemplateOutlet,
  ],
})
export class ContextFormComponent implements OnInit, AfterViewChecked {
  @ViewChildren("contextDocument")
  public overflowDocumentElements: QueryList<ElementRef>;

  @Input() public form: StrategyContextForm;
  @Input() public loadingContext: boolean;
  @Input() public contextId: string;

  @Input() public documentsAreBeingProcessed: boolean;
  @Input() public showErrors: boolean;
  @Input() public filesRequired: boolean;
  @Input() public additionalContextDescription: string;
  @Output()
  public readonly documentsAreBeingProcessedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly formSubmitted = new EventEmitter<StrategyContextForm>();
  @Output() public readonly processingStatusUpdated = new EventEmitter<boolean>();
  @Output() public readonly notesChanged = new EventEmitter<string>();

  public uploadedDocumentsCount = 0;
  public totalDocumentsCount = 0;
  public overflowDocumentsMap: Record<string, ElementRef> = {};
  public documents: StrategyContextDocument[] = [];
  public fileAcceptExtensionsList: string = ".txt, .pdf";
  public fileAcceptExtensionsDescription: string = "10 files max, 50MB each. Accepts PDF, TXT.";
  private acceptedMimeTypes: string[] = ["application/pdf", "text/plain"];

  private overflowDocumentsChangesSubscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private documentsMediator: DocumentsMediatorService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public ngOnInit(): void {
    this.documentsMediator
      .getDocumentsForContext$(this.contextId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (documentList) => {
          this.documents = documentList;
          this.updateDocumentsCount(documentList);
          this.updateProcessingState(documentList);
          this.cdr.markForCheck();
        },
      });

    combineLatest({
      pptxEnabled: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.StrategyPPTXEnabled),
      xlsEnabled: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.StrategyXLSXEnabled),
    }).subscribe({
      next: ({ pptxEnabled, xlsEnabled }) => {
        this.acceptedMimeTypes = ["application/pdf", "text/plain"];
        const acceptExtensionsList = [".txt", ".pdf"];
        const acceptedExtensionDescriptions = ["PDF", "TXT"];
        if (pptxEnabled) {
          acceptExtensionsList.push(".pptx");
          acceptedExtensionDescriptions.push("PPTX");
          this.acceptedMimeTypes.push("application/vnd.openxmlformats-officedocument.presentationml.presentation");
        }
        if (xlsEnabled) {
          acceptExtensionsList.push(".xlsx");
          acceptedExtensionDescriptions.push("XLSX");
          this.acceptedMimeTypes.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        }
        this.fileAcceptExtensionsList = acceptExtensionsList.join(", ");
        this.fileAcceptExtensionsDescription = `10 files max, 50MB each. Accepts ${acceptedExtensionDescriptions.join(", ")}.`;
        this.cdr.markForCheck();
      },
    });

    this.documentsMediator.markContextAsSeen(this.contextId);
  }

  public getIconTypeForDocument(document: StrategyContextDocument): "pdf-file" | "presentation" | "spreadsheet" | "text-file" {
    if (document.name.endsWith("pdf")) return "pdf-file";
    if (document.name.endsWith("pptx")) return "presentation";
    if (document.name.endsWith("xlsx")) return "spreadsheet";
    return "text-file";
  }

  public ngAfterViewChecked(): void {
    if (this.overflowDocumentElements) {
      if (this.overflowDocumentsChangesSubscription) {
        this.overflowDocumentsChangesSubscription.unsubscribe();
      }
      this.overflowDocumentsChangesSubscription = this.overflowDocumentElements.changes.pipe(untilDestroyed(this)).subscribe(() => {
        this.populateOverflowMap();
      });
      this.populateOverflowMap();
    }
  }

  public retryDocumentUpload = ($event: MouseEvent, document: StrategyContextDocument): void => {
    $event.preventDefault();
    $event.stopPropagation();
    this.documentsMediator.handleFileSelected(this.contextId, document);
  };

  public retryDocumentAnalysis = ($event: MouseEvent, document: StrategyContextDocument): void => {
    $event.preventDefault();
    $event.stopPropagation();
    this.analyseDocument(document);
  };

  public beforeUpload = (file: NzUploadFile): boolean => {
    if (this.totalDocumentsCount >= 10) return false;
    if (!this.acceptedMimeTypes.includes(file.type)) return false;
    const document = nzUploadFile2StrategyContextDocument(file);
    this.documentsMediator.handleFileSelected(this.contextId, document);
    return false;
  };

  public removeDocument = ($event: MouseEvent, document: StrategyContextDocument): void => {
    $event.preventDefault();
    $event.stopPropagation();
    this.documentsMediator.handleDocumentRemoved(this.contextId, document);
  };

  public removeUploadError = ($event: MouseEvent, document: StrategyContextDocument): void => {
    $event.preventDefault();
    $event.stopPropagation();

    this.documentsMediator.removeUploadError(document);

    this.cdr.markForCheck();
  };

  public removeDeleteError = ($event: MouseEvent, document: StrategyContextDocument): void => {
    $event.preventDefault();
    $event.stopPropagation();

    document.deletionState = null;

    this.cdr.markForCheck();
  };

  public submitForm(): void {
    if (this.form.invalid) {
      this.showErrors = true;
      this.cdr.markForCheck();
      return;
    }
    this.formSubmitted.emit(this.form);
  }

  public showIntercom(): void {
    Intercom("show");
  }

  public notesValueChanged(newContent: string): void {
    this.notesChanged.emit(newContent);
  }

  private updateDocumentsCount(documentList: StrategyContextDocument[]): void {
    this.uploadedDocumentsCount = documentList.filter((document) => document.state === "SUMMARIZED").length;
    this.totalDocumentsCount = documentList.length;
  }

  private analyseDocument(document: StrategyContextDocument): void {
    this.documentsMediator.analyseFile(document);
  }

  private updateProcessingState(documentList: StrategyContextDocument[]): void {
    const documentsAreBeingUploaded = documentList.some((document) => document.state === "UPLOADING");
    const documentsAreBeingDeleted = documentList.some((document) => document.deletionState === "DELETING");
    this.documentsAreBeingProcessed = documentsAreBeingUploaded || documentsAreBeingDeleted;
    this.documentsAreBeingProcessedChange.emit(this.documentsAreBeingProcessed);
    this.documentsMediator.markContextAsSeen(this.contextId);
    this.cdr.markForCheck();
  }

  private populateOverflowMap(): void {
    this.overflowDocumentElements.forEach((element) => {
      this.overflowDocumentsMap[element.nativeElement.id] = element;
    });
    this.cdr.markForCheck();
  }
}
