import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ChatGoalsDataVM } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";

@Component({
  selector: "ui-ai-chat-goals-block",
  templateUrl: "./ai-chat-goals-block.component.html",
  styleUrls: ["./ai-chat-goals-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatGoalsBlockComponent {
  @Input() public goalsBlockContent: ChatGoalsDataVM;
}
