import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { CHAT_AI_LOADING_ID, CHAT_TEMP_HUMAN_ID } from "@webapp/strategy/models/strategy.constants";
import { ChatQuestionVM, ConversationChatEntryVM, ConversationVM, ResultBlockType, UserDTO } from "../../models/chat.vm-models";

@Component({
  selector: "ui-chat-content",
  templateUrl: "./chat-content.component.html",
  styleUrls: ["./chat-content.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContentComponent implements OnInit, OnChanges {
  @Input() public conversation: ConversationVM | undefined;
  @Input() public usersMap: Record<string, UserDTO> | undefined;
  @Input() public suggestedFrameworks: string[];
  @ViewChild("conversationBottom")
  public conversationBottomRef: ElementRef<HTMLDivElement> | undefined;
  @Output() public readonly refreshContent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly retryErroredMessage: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly provideFeedback: EventEmitter<{
    message: ConversationChatEntryVM;
    type: "positive" | "negative";
  }> = new EventEmitter<{
    message: ConversationChatEntryVM;
    type: "positive" | "negative";
  }>();
  @Output()
  public readonly askQuestion: EventEmitter<ChatQuestionVM> = new EventEmitter<ChatQuestionVM>();
  @Output()
  public readonly addContent: EventEmitter<ConversationChatEntryVM> = new EventEmitter<ConversationChatEntryVM>();
  @Output()
  public readonly executeFramework: EventEmitter<string> = new EventEmitter<string>();

  public suggestedFrameworkMessage: ConversationChatEntryVM;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.scrollToLastMessage();
    this.suggestedFrameworkMessage = {
      id: "mocked_id",
      type: "mock",
      blockType: ResultBlockType.SuggestedFrameworks,
      blockContent: this.suggestedFrameworks.length > 0 ? this.suggestedFrameworks : ["swot", "pest"],
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes["conversation"] ||
      changes["conversation"].currentValue?.chat?.length ===
        changes["conversation"].previousValue?.chat?.filter((chatEntry: ConversationChatEntryVM) => {
          return chatEntry.id !== CHAT_TEMP_HUMAN_ID && chatEntry.id !== CHAT_AI_LOADING_ID;
        }).length
    ) {
      return;
    }

    this.scrollToLastMessage();
  }

  private scrollToLastMessage(): void {
    try {
      setTimeout(() => {
        if (!this.conversationBottomRef) return;
        this.conversationBottomRef.nativeElement.scrollIntoView({
          behavior: "instant",
          block: "end",
        });
        this.cdr.markForCheck();
      }, 0);
    } catch (e) {
      // no messages found
    }
  }

  public provideFeedbackForMessage(message: ConversationChatEntryVM, $event: "positive" | "negative"): void {
    this.provideFeedback.emit({
      message,
      type: $event,
    });
  }

  public askQuestionFromEmptyChatPanel(question: ChatQuestionVM): void {
    this.askQuestion.emit(question);
    this.cdr.markForCheck();
  }
}
