import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { AsyncOperationPreviewState } from "../../models/strategy.vm-models";

@Component({
  standalone: true,
  selector: "async-status",
  templateUrl: "./async-status.component.html",
  styleUrls: ["./async-status.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiTooltipModule, UiIconModule, UiLoadingIndicatorModule, CommonModule],
})
export class AsyncStatusComponent {
  @Input() public asyncPreviewState: AsyncOperationPreviewState;
  @Input() public imageOnly?: boolean;
  @Input() public size?: string;
}
