export const CHAT_TEMP_HUMAN_ID = "temp-human-id";
export const CHAT_AI_LOADING_ID = "ai-loading-id";

export const REPORT_DUMMY_BLOCK_ID = "dummy-block-id";
export const REPORT_NEWLY_CREATED_BLOCK_ID = "new-block-id";
export const SUMMARY_CONVERSATION_MOCK_ID = "summary-mock";

export const VALIDATION_STATUS_COMPLETED = "Completed";
export const VALIDATION_STATUS_NOT_STARTED = "Not Started";

export const TRIAL_END_DATE = "2024-07-31";
export const TRIAL_START_DATE = "2024-06-01";
export const STRATEGIES_ADDON_ID = "strategies-add-on-monthly";

export const WHITEBOARD_GENERATION_ERROR = "WHITEBOARD_GENERATION_ERROR";
export const STRATEGIC_MAP_GENERATION_ERROR = "STRATEGIC_MAP_GENERATION_ERROR";
export const BET_LOADING_ERROR = "BET_LOADING_ERROR";

export const BET_NO_SUMMARY_ERROR_MESSAGE = "strategy bet does not have associated summary";
export const MOCK_REPORT_ID = "summary-mock-report-id";

export const NON_SUMMARIZABLE_FILE_EXTENSIONS = [".xlsx", ".xls"];
export const STRATEGY_BET_PAGE_SIZE = 20;

export const CONFIRM_DELETE_STRATEGY_ITEM_WARNING_MESSAGES_MAP = {
  objectiveGeneration: "All associated files will be permanently removed. This action cannot be undone.",
  bet: "All associated analyses and files will be permanently removed. This action cannot be undone.",
  area: "All associated analyses will be permanently removed. This action cannot be undone.",
  hypothesis: "All associated analyses will be permanently removed. This action cannot be undone.",
} as const;
