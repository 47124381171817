import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  ChatBalancedScoreCardDataVM,
  StructuredChatBlockDisplayData,
  StructuredChatDataSection,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";

@Component({
  selector: "ui-ai-chat-balanced-scorecard-block",
  templateUrl: "./ai-chat-balanced-scorecard-block.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatBalancedScorecardBlockComponent implements OnInit {
  public balancedScorecardSections: StructuredChatBlockDisplayData | undefined;
  @Input() public balancedScorecard: ChatBalancedScoreCardDataVM | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const financialSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.balancedScorecard?.financialPerspectives, "Financial Perspective");
    const customerSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.balancedScorecard?.customerPerspectives, "Customer Perspective");
    const internalSection: StructuredChatDataSection = this.transformSwotAspectToSection(
      this.balancedScorecard?.internalProcessPerspectives,
      "Internal Process Perspective"
    );
    const learningSection: StructuredChatDataSection = this.transformSwotAspectToSection(
      this.balancedScorecard?.learningGrowthPerspectives,
      "Learning and Growth Perspective"
    );
    const alignmentSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.balancedScorecard?.alignmentAndSynergies, "Alignment and Synergies");
    const riskAndAdaptationsSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.balancedScorecard?.riskAndAdaptations, "Risk and Adaptations");
    this.balancedScorecardSections = [financialSection, customerSection, internalSection, learningSection, alignmentSection, riskAndAdaptationsSection];
    this.cdr.markForCheck();
  }

  private transformSwotAspectToSection(swotAspect: StructuredDataAspectVM[] | undefined, title: string): StructuredChatDataSection {
    return {
      title,
      entries: swotAspect || [],
    };
  }
}
