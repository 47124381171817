import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";

@Component({
  selector: "strategy-ai-dropdown",
  templateUrl: "./strategy-ai-dropdown.component.html",
  styleUrls: ["./strategy-ai-dropdown.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiButtonModule, UiIconModule, UiDropdownModule],
})
export class StrategyAiDropdownComponent {}
