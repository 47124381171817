import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class AskQuestionResponseStrategy implements QuantiveResultsSocketStrategy<"askQuestion"> {
  public messageType: QuantiveResultsSocketStrategy<"askQuestion">["messageType"] = "askQuestion";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"askQuestion">> {
    return pipe(
      filter(({ data }) => {
        return data.item === "conversation" && data.taskName === "ask_question" && data.userId === userId.get();
      })
    );
  }
}
