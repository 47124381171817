<ui-loading-indicator class="strategy-container" [uiSpinning]="initialLoadingIsOngoing" [uiTipPosition]="'bottom'" uiTip="Loading analysis...">
  @if (!initialLoadingIsOngoing) {
    <page-wrapper class="strategy" *ngIf="!isInErrorState && !areaNotAvailableForUser">
      <results-header
        class="results-header"
        [areaTitle]="area.title"
        [hypothesisId]="hypothesisId"
        [hypothesisTitle]="hypothesis.title"
        [verified]="verified"
        (closePage)="onClosePage()"
        (deleteHypothesis)="onHypothesisDelete()"
        (showCollectiveContext)="showCollectiveContext()"
        (toggleChat)="toggleChat()" />

      <section class="strategy-page-container">
        <section class="results">
          <ng-content></ng-content>

          <div class="results-content-container" id="content-for-export">
            <section [class.alert-results-section]="showAlert" [class.no-alert-results-section]="!showAlert">
              @if (showAlert) {
                <section class="alert skipped-pdf-content">
                  <alert-message
                    (dismissAlert)="hideAlertInternal()"
                    buttonLabel="Okay, got it"
                    message="Use the proposed analysis methods to gather insights. Quantive Assistant will help you elaborate and research on the hypothesis."
                    type="info"></alert-message>
                </section>
              }

              <results-section
                class="results-content"
                [conversation]="reportConversation$ | async"
                (deleteResult)="deleteResultBlock($event)"
                (editContent)="editResultSectionContent($event)"
                (executeFollowupAction)="executeFollowupAction($event)"
                (insertTextBlockAtIndex)="insertTextBlockAtIndex($event)"
                (moveDown)="moveResultBlockDown($event)"
                (moveUp)="moveResultBlockUp($event)"></results-section>
            </section>
          </div>
        </section>
        <section class="chat" *ngIf="chatVisible">
          <ui-ai-chat
            class="chat-content"
            [conversation]="aiChatConversation$ | async"
            [suggestedFrameworks]="hypothesis.suggestedFrameworks"
            [usersMap]="usersMap"
            (addContent)="addContent($event)"
            (askQuestion)="askQuestion($event)"
            (provideFeedback)="provideFeedbackForMessage($event)"
            (refreshContent)="regenerateLastChatEntry()"
            (retryErroredMessage)="retryErroredMessage()"></ui-ai-chat>
        </section>
      </section>
    </page-wrapper>

    <section class="error-state" *ngIf="isInErrorState && !areaNotAvailableForUser">
      <message-box [description]="unableToLoadAnalysis" (buttonAction)="onRetryClick()" buttonIcon="refresh" buttonLabel="Try again" icon="caution" size="medium" />
    </section>

    <article class="area-not-available" *ngIf="areaNotAvailableForUser">
      <error-page
        [errorMessage]="areaNotAvailableForCollaborator"
        [showButtonIcon]="false"
        (back)="onClosePage()"
        (retry)="onClosePage()"
        backButtonCaption="Close"
        errorButtonCaption="View decision map"
        errorType="error"></error-page>
    </article>
  }
</ui-loading-indicator>

<ng-template #unableToLoadAnalysis>
  <p class="deletion-error sp-mb-0">
    Unable to load analysis. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>

<ng-template #areaNotAvailableForCollaborator>
  <h3>Page not found</h3>
  <p>The area {{ area.title }} has been deleted.</p>
</ng-template>

<ng-template #hypothesisDeletionError>
  <p class="deletion-error sp-mb-0">
    Unable to delete hypothesis. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
  <error-details *ngIf="uiError.transactionId" [transactionId]="uiError.transactionId"></error-details>
</ng-template>
