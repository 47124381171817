export enum ResultBlockType {
  Text = "free_text",
  SWOT = "swot",
  PEST = "pest",
  Porter = "porter",
  BalancedScorecard = "balanced_scorecard",
  BlueOcean = "blue_ocean",
  Ansoff = "ansoff",
  BCG = "bcg_matrix",
  ScenarioPlanning = "scenario_planning",
  GapAnalysis = "gap",
  ValueChainAnalysis = "value_chain",
  Benchmarking = "benchmarking",
  McKinsey7SModel = "mckinsey_7s_model",
  ValuePropositionCanvas = "value_proposition_canvas",
  GrowthShareMatrix = "growth_share_matrix",
  DecisionTrees = "decision_trees",
  RootCauseAnalysis = "root_cause",
  FishboneDiagram = "fishbone_diagram",
  ParetoAnalysis = "pareto",
  SixSigma = "six_sigma",
  LeanThinking = "lean_thinking",
  The4PsOfMarketing = "the_4ps_of_marketing",
  The5PsModel = "the_5ps_model",
  ScenarioAnalysis = "scenario",
  CompanyCultureAnalysis = "company_culture",
  GameTheory = "game_theory",
  FeasibilityStudy = "feasibility_study",
  RiskRewardAnalysis = "risk_reward",
  GenerateGoals = "generate_goals",
  GenerateKPIs = "generate_kpis",
  Goals = "goals",
  KPIs = "kpis",
  Loading = "loading",
  Error = "error",
  QuickAnalysis = "quick_analysis",
  DecisionMap = "decision_map",
  SuggestedFrameworks = "suggested_frameworks",
}

export type FollowupTypes = "elaborate" | "swot_opportunities_ansoff" | "swot_opportunities_pest" | "swot_threats_porter" | "swot_threats_pest" | "research";

export interface StructuredDataAspectVM {
  id: number;
  text: string;
  references: string[];
}

export interface ConversationChatEntryVM {
  id: string;
  type: "human" | "ai" | "mock";
  blockType: ResultBlockType;
  blockContent: BlockContent;
  erroredBlockType?: ResultBlockType | FollowupTypes;
  followupActionDetails?: { type: FollowupTypes; isSuggestion: boolean };
  feedback?: { score: "good" | "bad" };
  inserting?: boolean;
  insertingError?: boolean;
  startedAt?: string;
  createdBy?: string;
}

export interface ChatSwotDataVM {
  strengths: StructuredDataAspectVM[];
  weaknesses: StructuredDataAspectVM[];
  opportunity: StructuredDataAspectVM[];
  threats: StructuredDataAspectVM[];
}

export interface ChatPestDataVM {
  politicalFactors: StructuredDataAspectVM[];
  economicFactors: StructuredDataAspectVM[];
  socioculturalFactors: StructuredDataAspectVM[];
  technologicalFactors: StructuredDataAspectVM[];
  environmentalFactors: StructuredDataAspectVM[];
  legalFactors: StructuredDataAspectVM[];
}

export interface ChatPorterForcesDataVM {
  newEntrantThreats: StructuredDataAspectVM[];
  substituteThreats: StructuredDataAspectVM[];
  buyersBargainingPowers: StructuredDataAspectVM[];
  supplierBargainingPowers: StructuredDataAspectVM[];
  competitionRivalry: StructuredDataAspectVM[];
  conclusion: StructuredDataAspectVM[];
}

export interface ChatBalancedScoreCardDataVM {
  alignmentAndSynergies: StructuredDataAspectVM[];
  customerPerspectives: StructuredDataAspectVM[];
  financialPerspectives: StructuredDataAspectVM[];
  internalProcessPerspectives: StructuredDataAspectVM[];
  learningGrowthPerspectives: StructuredDataAspectVM[];
  riskAndAdaptations: StructuredDataAspectVM[];
}

export interface ChatAnsoffDataVM {
  diversification: StructuredDataAspectVM[];
  marketDevelopment: StructuredDataAspectVM[];
  marketPenetration: StructuredDataAspectVM[];
  productDevelopment: StructuredDataAspectVM[];
}

export interface ChatBlueOceanDataVM {
  errcGridAnalysis: StructuredDataAspectVM[];
  fourActionsFramework: StructuredDataAspectVM[];
  leadershipAndPeopleDevelopment: StructuredDataAspectVM[];
  marketBoundaryReconstruction: StructuredDataAspectVM[];
  sixPathsFramework: StructuredDataAspectVM[];
  strategicSequence: StructuredDataAspectVM[];
  strategyCanvas: StructuredDataAspectVM[];
  threeNoncustomerTiers: StructuredDataAspectVM[];
  valueInnovation: StructuredDataAspectVM[];
}

export interface ChatGoalsDataVM {
  goals: ChatGoalDataVM[];
  text?: string;
}

interface ChatGoalDataVM {
  goal: string;
  keyResults: string[];
  reasoning: string;
  references?: string[];
}

export interface ChatKPIsDataVM {
  kpis: ChatKPIDataVM[];
  text?: string;
}

interface ChatKPIDataVM {
  kpi: string;
  reasoning: string;
  references: string[];
}

export interface ChatTextResultVM {
  text: string;
  partial?: boolean;
  references?: string[];
}

type SuggestedFrameworks = string[];

export type BlockContent =
  | ChatSwotDataVM
  | ChatAnsoffDataVM
  | ChatPorterForcesDataVM
  | ChatPestDataVM
  | ChatBalancedScoreCardDataVM
  | ChatBlueOceanDataVM
  | ChatGoalsDataVM
  | ChatKPIsDataVM
  | ChatTextResultVM
  | SuggestedFrameworks
  | LoadingDataVM
  | null;

export interface OrderedResultEntryVM {
  id: string;
  blockType: ResultBlockType;
  blockContent: BlockContent;
  isCreatedInThisSession: boolean;
  shapeId?: string;
}

export interface ConversationVM {
  id: string;
  accountId: string;
  chat: ConversationChatEntryVM[];
  results: OrderedResultEntryVM[];
  reportId: string;
  currentlyAnsweringQuestion?: boolean;
  suggestedFrameworks?: string[];
}

export interface ChatQuestionVM {
  question: string;
  type: ResultBlockType | FollowupTypes;
}

export interface StructuredChatDataSectionTextEntry {
  text: string;
  references: string[];
}

export interface LoadingDataVM {
  framework: string;
  isSuggested: boolean;
}

export interface StructuredChatDataSection {
  title: string;
  entries: StructuredChatDataSectionTextEntry[];
}

export type StructuredChatBlockDisplayData = StructuredChatDataSection[];

type Language = "english" | "spanish" | "german" | "chinese" | "bulgarian" | "french" | "portuguese" | "italian" | "arabic";

export interface UserDTO {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  picture?: string;
  language?: Language;
}
