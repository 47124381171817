import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { IStrategyConversationContextApi } from "./strategy-conversation-context-api.interface";
import { StrategyConversationContextApiV2Service } from "./v2/strategy-conversation-context-api-v2.service";

@Injectable()
export class StrategyConversationContextApiFactory {
  constructor(private injector: Injector) {}

  public getApi(): Observable<IStrategyConversationContextApi> {
    return of(this.injector.get(StrategyConversationContextApiV2Service));
  }
}
