import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, take } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { OrderedStrategyResultEntryVM, StrategyResultBlockContentVM, StrategyResultBlockTypeVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategyConversationChatEntryVM } from "../../models/chat/strategy-conversation-chat.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { StrategyReportFollowupActionDefinition } from "../../models/strategy.vm-models";
import { StrategyConversationChatService } from "../chat/strategy-conversation-chat.service";
import { StrategyConversationService } from "../conversation/strategy-conversation.service";
import { StrategiesTrackingService } from "../utility/strategies-tracking.service";
import { getFollowupQuestion } from "../utility/strategy-conversation.utils";
import { ResultPersistenceBuilder } from "./result-persistence-builder";
import {
  buildConversationByInsertingBlockAtIndex,
  buildConversationMoveResultBlockDown,
  buildConversationMoveResultBlockUp,
  buildConversationWithDeletedBlock,
  buildInitialReportConversation,
  changeConversationBasedOnNewResults,
  changeConversationByAddingMissingReportIds,
  changeConversationByFillingInDummyObject,
} from "./strategy-conversation-report-factory.utils";

// This service will hold the view model that is used in the report section.
// The data may differ from the one that is persisted, but for now we do not care about collaborative editing -
// whatever changes come in last, will be applied. We're going to support two separate stateful services to ensure
// a smooth editing experience. Report service will hold what's currently displayed, Conversation service will hold

type ReportEntries = OrderedStrategyResultEntryVM[];

@Injectable()
export class StrategyConversationReportService {
  private conversations$: Record<string, BehaviorSubject<StrategyConversationVM | null>> = {};

  private updateRequestIsOngoing = false;

  private updateQueue: {
    updateObservableGenerator(): Observable<ReportEntries>;
    updateSubject: Subject<ReportEntries>;
  }[] = [];

  constructor(
    private strategyConversationService: StrategyConversationService,
    private strategiesTrackingService: StrategiesTrackingService,
    private strategyConversationChatService: StrategyConversationChatService
  ) {}

  public getConversationForReport$(conversationId: string): Observable<StrategyConversationVM | null> {
    this.createConversationBSIfNotExists(conversationId);
    this.strategyConversationService
      .getConversation$(conversationId)
      .pipe(
        filter((conversation) => conversation !== null),
        take(1)
      )
      .subscribe((conversation) => this.conversations$[conversationId].next(buildInitialReportConversation(conversation)));
    return this.conversations$[conversationId].asObservable();
  }

  public executeFollowupAction(conversation: StrategyConversationVM, followupAction: StrategyReportFollowupActionDefinition): void {
    this.strategyConversationChatService.askQuestion(
      conversation.id,
      {
        question: followupAction.question,
        type: followupAction.type,
      },
      followupAction.resultEntry,
      undefined,
      getFollowupQuestion(followupAction)
    );
  }

  public editResultSectionContent(conversation: StrategyConversationVM, updatedResultBlock: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    return this.executeReportUpdateConsecutively(() => this.executeEditResultSectionContent(conversation, updatedResultBlock));
  }

  public deleteResultBlock(conversation: StrategyConversationVM, resultBlockToBeDeleted: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    return this.executeReportUpdateConsecutively(() => this.executeDeleteResultBlock(conversation, resultBlockToBeDeleted));
  }

  public moveResultBlockDown(conversation: StrategyConversationVM, resultBlock: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    return this.executeReportUpdateConsecutively(() => this.executeMoveResultBlockDown(conversation, resultBlock));
  }

  public moveResultBlockUp(conversation: StrategyConversationVM, resultBlock: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    return this.executeReportUpdateConsecutively(() => this.executeMoveResultBlockUp(conversation, resultBlock));
  }

  public addResultFromChat(conversation: StrategyConversationVM, message: StrategyConversationChatEntryVM): Observable<ReportEntries> {
    return this.insertBlock(conversation, message.blockContent as unknown as StrategyResultBlockContentVM, message.blockType, {
      replacesDummyObject: false,
      index: this.conversations$[conversation.id].value.results.length,
      isCreatedInThisSession: true,
      suggestionId: message.followupActionDetails?.isSuggestion ? message.id : null,
      shapeId: message.shapeId,
    });
  }

  public addResultFromADummyBlock(conversation: StrategyConversationVM, message: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    return this.insertBlock(conversation, message.blockContent, message.blockType, {
      replacesDummyObject: true,
      index: -1,
      isCreatedInThisSession: false,
      shapeId: message.shapeId,
    });
  }

  public insertEmptyTextBlockAtIndex(conversation: StrategyConversationVM, index: number): Observable<ReportEntries> {
    return this.insertBlock(
      conversation,
      {
        text: "",
        references: [],
      },
      StrategyResultBlockTypeVM.Text,
      {
        replacesDummyObject: false,
        index,
        isCreatedInThisSession: false,
      }
    );
  }

  public reportIsBeingEdited(): boolean {
    return this.updateQueue.length > 0 || this.updateRequestIsOngoing;
  }

  // when we're inserting, we can do one of two things:
  // 1. add a block at an index (either an empty one or one from the chat)
  // 2. start typing in the dummy box
  private insertBlock(
    conversation: StrategyConversationVM,
    content: StrategyResultBlockContentVM,
    blockType = StrategyResultBlockTypeVM.Text,
    options: {
      index: number;
      replacesDummyObject: boolean;
      isCreatedInThisSession: boolean;
      suggestionId?: string;
      shapeId?: string;
    }
  ): Observable<ReportEntries> {
    return this.executeReportUpdateConsecutively(() => this.executeInsertBlock(conversation, content, blockType, options));
  }

  // when we're inserting, we can do one of two things:
  // 1. add a block at an index (either an empty one or one from the chat)
  // 2. start typing in the dummy box
  private executeInsertBlock(
    conversation: StrategyConversationVM,
    content: StrategyResultBlockContentVM,
    blockType = StrategyResultBlockTypeVM.Text,
    options: {
      index: number;
      replacesDummyObject: boolean;
      isCreatedInThisSession: boolean;
      suggestionId?: string;
      shapeId?: string;
    }
  ): Observable<ReportEntries> {
    const resultPersistenceBuilder = new ResultPersistenceBuilder(this.conversations$[conversation.id].value);

    if (options.replacesDummyObject) {
      resultPersistenceBuilder.fillDummyObject(content, blockType, options.shapeId);
    } else {
      resultPersistenceBuilder.insertBlockAtIndex(options.index, content, blockType, options.suggestionId, options.shapeId);
    }

    return this.strategyConversationService.updateConversationReport$(conversation.id, resultPersistenceBuilder.build()).pipe(
      tap((updatedResults: ReportEntries) => {
        if (options.replacesDummyObject) {
          // we add a new temp block with an id of NEWLY_CREATED_BLOCK_ID and a new dummy block at the bottom
          // this SHOULD NOT be destructive, because we're expecting that the user is typing at the moment of creation
          const newConversation = changeConversationByFillingInDummyObject(this.conversations$[conversation.id].value, content, blockType);
          this.conversations$[conversation.id].next(newConversation);
        } else {
          // we add a new temp block with an id of NEWLY_CREATED_BLOCK_ID, the dummy block remains unchanged
          // this can be destructive for the conversation object, as the user is not typing at the moment of insertion
          const newConversation = buildConversationByInsertingBlockAtIndex(this.conversations$[conversation.id].value, content, blockType, options.index, options);
          this.conversations$[conversation.id].next(newConversation);
        }
        // we need to fill in the id of the newly created block
        // this SHOULD NOT be destructive as we can't be sure that the user hasn't started typing
        const updatedConversation = changeConversationByAddingMissingReportIds(this.conversations$[conversation.id].value, updatedResults);
        this.conversations$[conversation.id].next(updatedConversation);
      })
    );
  }

  private executeEditResultSectionContent(conversation: StrategyConversationVM, updatedResultBlock: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    const resultPersistenceBuilder = new ResultPersistenceBuilder(this.conversations$[conversation.id].value);
    return this.strategyConversationService.updateConversationReport$(conversation.id, resultPersistenceBuilder.updateBlock(updatedResultBlock).build()).pipe(
      tap((updatedResults: ReportEntries) => {
        const updatedConversation = changeConversationBasedOnNewResults(this.conversations$[conversation.id].value, updatedResultBlock, updatedResults);
        this.conversations$[conversation.id].next(updatedConversation);
      })
    );
  }

  private executeDeleteResultBlock(conversation: StrategyConversationVM, resultBlockToBeDeleted: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    const resultPersistenceBuilder = new ResultPersistenceBuilder(this.conversations$[conversation.id].value);
    const updateObservable = this.strategyConversationService
      .updateConversationReport$(conversation.id, resultPersistenceBuilder.deleteBlock(resultBlockToBeDeleted).build())
      .pipe(
        tap(() => {
          this.strategiesTrackingService.trackConversationEdited(conversation, resultBlockToBeDeleted, "delete");
        })
      );
    this.conversations$[conversation.id].next(buildConversationWithDeletedBlock(this.conversations$[conversation.id].value, resultBlockToBeDeleted));
    return updateObservable;
  }

  private executeMoveResultBlockUp(conversation: StrategyConversationVM, resultBlock: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    const resultPersistenceBuilder = new ResultPersistenceBuilder(this.conversations$[conversation.id].value);
    return this.strategyConversationService.updateConversationReport$(conversation.id, resultPersistenceBuilder.moveBlockUp(resultBlock).build()).pipe(
      tap(() => {
        this.conversations$[conversation.id].next(buildConversationMoveResultBlockUp(this.conversations$[conversation.id].value, resultBlock));
        this.strategiesTrackingService.trackConversationEdited(conversation, resultBlock, "move_up");
      })
    );
  }

  private executeMoveResultBlockDown(conversation: StrategyConversationVM, resultBlock: OrderedStrategyResultEntryVM): Observable<ReportEntries> {
    const resultPersistenceBuilder = new ResultPersistenceBuilder(this.conversations$[conversation.id].value);
    return this.strategyConversationService.updateConversationReport$(conversation.id, resultPersistenceBuilder.moveBlockDown(resultBlock).build()).pipe(
      tap(() => {
        this.conversations$[conversation.id].next(buildConversationMoveResultBlockDown(this.conversations$[conversation.id].value, resultBlock));
        this.strategiesTrackingService.trackConversationEdited(conversation, resultBlock, "move_down");
      })
    );
  }

  private executeReportUpdateTask(observableGenerator: () => Observable<ReportEntries>, subject: Subject<ReportEntries>): void {
    this.updateRequestIsOngoing = true;
    observableGenerator()
      .pipe(take(1))
      .subscribe({
        next: (result: ReportEntries) => {
          this.updateRequestIsOngoing = false;
          subject.next(result);
          subject.complete();
          this.processUpdateQueue();
        },
        error: () => {
          this.updateRequestIsOngoing = false;
          subject.next(null);
          subject.complete();
          this.processUpdateQueue();
        },
      });
  }

  private processUpdateQueue(): void {
    if (this.updateQueue.length === 0) return;
    const { updateObservableGenerator, updateSubject } = this.updateQueue.shift();
    this.executeReportUpdateTask(updateObservableGenerator, updateSubject);
  }

  private executeReportUpdateConsecutively(observableGenerator: () => Observable<ReportEntries>): Observable<ReportEntries> {
    const reportUpdateSubject = new Subject<ReportEntries>();
    if (this.updateRequestIsOngoing) {
      this.updateQueue.push({
        updateObservableGenerator: observableGenerator,
        updateSubject: reportUpdateSubject,
      });
    } else {
      this.executeReportUpdateTask(observableGenerator, reportUpdateSubject);
    }
    return reportUpdateSubject.asObservable();
  }

  private createConversationBSIfNotExists(conversationId: string): void {
    if (!this.conversations$[conversationId]) {
      this.conversations$[conversationId] = new BehaviorSubject<StrategyConversationVM | null>(null);
    }
  }
}
