<nav>
  <button (click)="closePage()" ui-button ui-tooltip uiShape="round" uiTooltipTitle="Close" uiType="default">
    <i ui-icon uiTheme="outline" uiType="close-big"></i>
  </button>
</nav>
<header class="detailed-strategy-page-header" [descriptionBlunt]="true" actionable-header headerTitle="What can I help you decide on?">
  <section class="ask-container">
    <section class="place-bet-container">
      <section class="predefined-questions">
        <button class="smart-shortcut" *ngFor="let question of predefinedQuestions" (click)="createBet({ question, isPredefined: true })" ui-button uiShape="round" uiSize="large" uiType="primary">
          {{ question }}
        </button>
      </section>
      <form class="ask-form" [formGroup]="askForm" (ngSubmit)="createBet()">
        <ui-form-control class="input-container">
          <ui-input-text-area
            #askInput
            [maxCharacterCount]="1000"
            [showCharacterCount]="true"
            [showMaxCharacterCount]="true"
            [visibleRowCount]="4"
            formControlName="question"
            textAreaClassName="ask-textarea"></ui-input-text-area>
        </ui-form-control>
        <alert-message *ngIf="limitReached" (dismissAlert)="hideLimitReachedError()" dismissType="x-button" message="Your account type does not allow any more Decision entries." type="warning">
        </alert-message>
        <section class="actions">
          <button *ngIf="!limitReached" type="submit" ui-button uiShape="round" uiSize="large" uiType="primary">
            <span>Submit</span>
          </button>
          <button *ngIf="limitReached" (click)="goToBilling()" ui-button uiShape="round" uiSize="large" uiType="primary">
            <i ui-icon uiSize="xs" uiTheme="outline" uiType="upgrade"></i>
            <span>Manage account</span>
          </button>
        </section>
      </form>
    </section>
  </section>
</header>
