import { ISharedOkrsAccountSetting } from "@gtmhub/accounts/accounts.service";
import { Language, MethodologyType } from "@gtmhub/localization";
import { EditionFeature } from "@webapp/accounts/models/edition.models";
import { AssignPermissions, IdpRoleMappingsProvisioningSetupType } from "@webapp/configuration/models/configuration.model";

export interface IAccount {
  id: string;
  name: string;
  isActive: boolean;
  domain: string;
  settings: string;
  edition?: IEdition;
  subscriptionId?: string;
  subscriptions: ILicense[];
  dateCreated: string;
  trialEnds: string;
  ownerId: string;
  type: AccountType;
  notifications: IAccountNotification;
  confidenceSettings: IConfidenceSettings;
  language: Language;
  csManaged?: boolean;
  billingSystem: string;
  trackingProperties: ITrackingProperties;
  saasSubscriptionId?: string;
  subscriptionProvider?: string;
  methodologySummary?: string;
  companyDescription?: {
    sector?: string;
    industry?: string;
    summary?: string;
  };
  accountMarketingInfo?: {
    companySize: string;
    country: string;
  };
  useQuantiveAuth0Domain?: boolean;
  methodologyType?: MethodologyType;
  campaign?: string;
}

export interface IEdition {
  id?: string;
  name?: string;
  features?: EditionFeature[];
  trialDuration?: number;
  planId?: string;
  activityHistoryLimit?: number;
  activityHistoryLimitUnit?: "day" | "month";
  activityHistoryRecordLimit?: number;
}
export enum AccountType {
  TrialAccount,
  ClientAccount,
  InternalAccount,
  DemoAccount,
  PartnerAccount,
  FreeAccount,
}

export type ScimTeamNamingConventionType = "manager" | "department" | "none";

export interface IScimSettings {
  teamNamingConvention: ScimTeamNamingConventionType;
  autoRenameTeams: boolean;
  deactivateEmptyTeams: boolean;
  rolesProvisioningSetupType?: IdpRoleMappingsProvisioningSetupType;
}

export interface IAccountSettings {
  aggregation: boolean;
  coloring: {
    defaultColor: string;
    ranges: { [key: number]: string }[];
    strategy: string;
  };
  confidenceScale: string;
  confidenceSettings: {
    confidenceType: ConfidenceType;
    confidenceMapping: IConfidenceMappingSettings[];
  };
  branding: string;
  limitObjectives: number;
  limitKRs: number;
  assignObjectivePermissions: AssignPermissions;
  methodology: string;
  assignTaskPermissions: AssignPermissions;
  assignKRPermissions: AssignPermissions;
  scim: IScimSettings;
  sharedOkrs: ISharedOkrsAccountSetting;
  methodologyPerSessionEnabled: boolean;
  limitKrsProgress: boolean;
}

interface IConfidenceMappingSettings {
  name: string;
  color: string;
  range: {
    to: number;
    from: number;
  };
}

export interface IConfidenceRange {
  from?: number;
  to: number;
}

export interface IConfidenceMapping {
  color: string;
  name: string;
  range: IConfidenceRange;
}

type ConfidenceType = "text" | "numeric" | "emoji";

export interface IConfidenceSettings {
  confidenceMapping: IConfidenceMapping[];
  confidenceType: ConfidenceType;
}

export interface IAccountNotification {
  cadence: string;
  cadenceCheckIn: string;
  cadenceReports: string;
  checkinDeadline: IAccountNotificationSetting;
  reportOptions: ReportOptions;
  reportsDeadline: IAccountNotificationSetting;
  retrospectiveMeeting: IAccountNotificationSetting;
}

interface ReportOptions {
  okrUpdate: OkrUpdateRemindersPayload;
  aggregatedReport: AggregatedReportPayload;
}

interface RemindersAndReportBasePayload {
  excludeUserIds: string[];
  excludeTeamIds: string[];
  timezone: string;
}

interface OkrUpdateRemindersPayload extends RemindersAndReportBasePayload {
  sendToTeamMembers: boolean;
}

interface AggregatedReportPayload extends RemindersAndReportBasePayload {
  includeObjectivesWithoutKrs: boolean;
}

interface IAccountNotificationSetting {
  dayOfTheWeek: string;
  periodStart: string;
  remindAt: IRemindAt;
  lastReminded: string;
  disableConstraint: boolean;
  reportChannels: IReportChannels;
}

interface IReportChannels {
  email: boolean;
  slack: boolean;
  msTeams: boolean;
  mobile: boolean;
}

export interface ILicense {
  allowCancellation: boolean;
  isConverted?: boolean;
  usersAllowed: number;
  usersCount: number;
  type: string;
  maxUserLimit?: number;
}

interface ITrackingProperties {
  shouldTrack: boolean;
}

interface IRemindAt {
  hour: number;
  minute: number;
}

const gtmhubAccountId = "573dbb12ed915d0005cc2c46";

export const isGtmhubAccountId = (accountId: string) => accountId === gtmhubAccountId;

export const isScimSettingKey = (k: string): k is keyof IScimSettings =>
  k === "teamNamingConvention" || k === "autoRenameTeams" || k === "deactivateEmptyTeams" || k === "rolesProvisioningSetupType";

export const isScimTeamNamingConventionType = (type: unknown): type is ScimTeamNamingConventionType => type === "manager" || type === "department" || type === "none";
