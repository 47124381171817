import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UiButtonComponent, UiButtonShape, UiButtonSize, UiButtonType } from "@quantive/ui-kit/button";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { IButtonSelectOptions } from "./button-select.models";

@Component({
  selector: "button-select",
  templateUrl: "./button-select.component.html",
  styleUrls: ["./button-select.component.less"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSelectComponent implements OnChanges {
  /** Aria label of the select. */
  @Input() public a11yLabel = "";
  @Input() public model: string;
  @Input() public uiId?: string;
  @Input() public selected: string;
  @Input() public options: IButtonSelectOptions[] = [];
  @Input() public hasOptionCheckIcon?: boolean;
  @Input() public dropdownMatchSelectWidth = true;
  @Input() public disabled = false;
  @Input() public iconButton = false;
  @Input() public withSpecialType = true;
  @Input() public iconButtonConfig: { uiTheme: UiThemeType; uiType: string };
  @Input() public buttonConfig: { size?: UiButtonSize; shape?: UiButtonShape; type?: UiButtonType };
  @Input() public buttonTabindex = 0;
  @Input() public dropdownStyle: { [key: string]: string } | null = null;

  /**
   * Controls whether the additional paddings and margins will be rendered around the button itself.
   * When used in forms, those spacings align the button to the same start position as other select elements.
   */
  @Input() public inline = false;
  @Output() public readonly modelChange = new EventEmitter();

  @ViewChild(UiButtonComponent) private buttonComponent: UiButtonComponent;

  public enrichedSelected: IButtonSelectOptions;
  public isDropDownOpen: boolean;

  public constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ("selected" in changes) {
      const selected = changes["selected"].currentValue;
      this.enrichedSelected = this.options.find((option) => option.value === selected);
    }
  }

  public handleChange(value): void {
    this.modelChange.emit(value);
    this.focusButtonElement();
  }

  public openDropDown(): void {
    // overrides the internal select key bindings config, enter keydown doesn't work without a timeout
    window.setTimeoutOutsideAngular(() => {
      this.isDropDownOpen = true;
      this.changeDetector.detectChanges();
    });
  }

  public focusButtonElement(): void {
    this.buttonComponent.thisElementRef.nativeElement.focus();
  }
}
