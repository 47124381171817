import { IntegerRange } from "../integer-range";

type AccessRightsDTOv2 = "read" | "update" | "delete" | "create" | "modifyPermissions";

interface GrantDefinitionDTOv2 {
  general: AccessRightsDTOv2[];
}

interface PermissionDefinitionDTOv2 {
  grant: GrantDefinitionDTOv2;
  principalId: string;
  principalKind: "user" | "account";
}

export interface AccessDefinitionDTOv2 {
  permissions: PermissionDefinitionDTOv2[];
}

type StrategicBetProgressStepDTOv2 = IntegerRange<1, 4>;
type StrategicBetProgressSubStepDTOv2 = IntegerRange<0, 2>;

export interface StrategicBetDTOv2 {
  id: string;
  name: string;
  description: string;
  accountId: string;
  createdBy: string;
  contextId: string;
  strategicMap: StrategicMapDTOv2;
  createdAt: string;
  progressStep: StrategicBetProgressStepDTOv2;
  progressSubStep: StrategicBetProgressSubStepDTOv2;
  access: AccessDefinitionDTOv2;
  state: string;
  onePagerContent: string;
  whiteboardId: string;
  conversationId: string;
}

export interface StrategicMapDTOv2 {
  id: string;
  areas: StrategicMapAreaDTOv2[];
}

export interface StrategicMapAreaDTOv2 {
  id: string;
  title: string;
  status: string;
  hypotheses: StrategicMapHypothesisDTOv2[];
}

export interface StrategicMapHypothesisDTOv2 {
  conversationId: string;
  description: string;
  id: string;
  status: string;
  suggestedFrameworks: string[];
  title: string;
  statement: string;
  state: string;
}

export interface OrderedStrategyResultEntryDTOv2 {
  id: string;
  blockType: StrategyResultBlockTypeDTOv2;
  blockContent: StrategyResultBlockContentDTOv2;
  suggestionId?: string;
  shapeId?: string;
}

export enum StrategyResultBlockTypeDTOv2 {
  Text = "free_text",
  SWOT = "swot",
  PEST = "pest",
  Porter = "porter",
  BalancedScorecard = "balanced_scorecard",
  BlueOcean = "blue_ocean",
  Goals = "goals",
  KPIs = "kpis",
  Ansoff = "ansoff",
  SummaryText = "summary_text",
}

export type StrategyResultBlockContentDTOv2 =
  | SwotDataDTOv2
  | AnsoffDataDTOv2
  | PorterForcesDTOv2
  | PestDataDTOv2
  | BalancedScoreCardDTOv2
  | BlueOceanDataDTOv2
  | GoalsDataDTOv2
  | KPIsDataDTOv2
  | TextStrategyResultDTOv2
  | TitleAndDescriptionDataDTOv2;

export interface StructuredDataAspectDTOv2 {
  text: string;
  references: string[];
}

export interface SwotDataDTOv2 {
  strengths: StructuredDataAspectDTOv2[];
  weaknesses: StructuredDataAspectDTOv2[];
  opportunity: StructuredDataAspectDTOv2[];
  threats: StructuredDataAspectDTOv2[];
}

interface AnsoffDataDTOv2 {
  diversification: StructuredDataAspectDTOv2[];
  marketDevelopment: StructuredDataAspectDTOv2[];
  marketPenetration: StructuredDataAspectDTOv2[];
  productDevelopment: StructuredDataAspectDTOv2[];
}

interface PorterForcesDTOv2 {
  newEntrantThreats: StructuredDataAspectDTOv2[];
  substituteThreats: StructuredDataAspectDTOv2[];
  buyersBargainingPowers: StructuredDataAspectDTOv2[];
  supplierBargainingPowers: StructuredDataAspectDTOv2[];
  competitionRivalry: StructuredDataAspectDTOv2[];
  conclusion: StructuredDataAspectDTOv2[];
}

interface PestDataDTOv2 {
  politicalFactors: StructuredDataAspectDTOv2[];
  economicFactors: StructuredDataAspectDTOv2[];
  socioculturalFactors: StructuredDataAspectDTOv2[];
  technologicalFactors: StructuredDataAspectDTOv2[];
  environmentalFactors: StructuredDataAspectDTOv2[];
  legalFactors: StructuredDataAspectDTOv2[];
}

interface BalancedScoreCardDTOv2 {
  alignmentAndSynergies: StructuredDataAspectDTOv2[];
  customerPerspectives: StructuredDataAspectDTOv2[];
  financialPerspectives: StructuredDataAspectDTOv2[];
  internalProcessPerspectives: StructuredDataAspectDTOv2[];
  learningGrowthPerspectives: StructuredDataAspectDTOv2[];
  riskAndAdaptations: StructuredDataAspectDTOv2[];
}

interface BlueOceanDataDTOv2 {
  errcGridAnalysis: StructuredDataAspectDTOv2[];
  fourActionsFramework: StructuredDataAspectDTOv2[];
  leadershipAndPeopleDevelopment: StructuredDataAspectDTOv2[];
  marketBoundaryReconstruction: StructuredDataAspectDTOv2[];
  sixPathsFramework: StructuredDataAspectDTOv2[];
  strategicSequence: StructuredDataAspectDTOv2[];
  strategyCanvas: StructuredDataAspectDTOv2[];
  threeNoncustomerTiers: StructuredDataAspectDTOv2[];
  valueInnovation: StructuredDataAspectDTOv2[];
}

interface GoalsDataDTOv2 {
  goals: GoalDTOv2[];
}

interface GoalDTOv2 {
  goal: string;
  keyResults: string[];
}

interface KPIsDataDTOv2 {
  kpis: KPIDTOv2[];
}

interface KPIDTOv2 {
  kpi: string;
}

interface TextStrategyResultDTOv2 {
  text: string;
}

interface TitleAndDescriptionDataDTOv2 {
  title: string;
  description: string;
}

export interface AsyncResponseDTOv2 {
  taskId: string;
}

export interface GenerateOKRsResponseDTOv2 {
  whiteboardId: string;
}
