<ui-loading-indicator [uiIndicator]="loadingTemplate" [uiSpinning]="loading" [uiTip]="'loading' | localize">
  <div *ngIf="sections.length" [ngClass]="['assignee-active-okrs-main-wrap', 'view-mode-' + viewMode, 'grouped-by-' + groupBy]">
    <ui-collapse>
      <ui-collapse-panel
        *ngFor="let group of sections"
        [class.summary-expanded]="sectionsState[group.id]?.summary"
        [collapsePanelActive]="sectionsState[group.id]?.panel"
        [collapsePanelExpandedIcon]="expandedIcon"
        [collapsePanelHeader]="panelHeaderTemplate"
        [defaultState]="sectionsState[group.id]"
        [stateAssigneeId]="assigneeId"
        [stateSessionId]="group.id"
        [uiOnlyContentBorder]="true"
        [uiSize]="'small'"
        (stateChange)="stateChanged(group.id, $event)"
        assignee-active-okrs-list-state>
        <ng-template #expandedIcon>
          <i class="collapse-arrow" [uiRotate]="sectionsState[group.id]?.panel ? 90 : 0" ui-icon uiType="chevron-right-small"></i>
        </ng-template>
        <ng-template #panelHeaderTemplate>
          <div class="panel-header" [class.panel-header-active]="sectionsState[group.id]?.panel" [ghTrackMeta]="getTrackingMetadata(group.id)" ghTrack="Button Clicked">
            <h4 [attr.aria-label]="getSectionHeaderAriaLabel(group)" [uiTooltipTitle]="group.isRestricted ? restrictedSessionTitle : group.title" tooltipIfOverflow="hover" ui-tooltip>
              {{ group.isRestricted ? restrictedSessionTitle : group.title }}
              <span class="deactivated-label" *ngIf="group?.isActive === false && group.type === 'user'">({{ "deactivated" | localize }})</span>
            </h4>
            <ui-badge
              class="sp-mr-8"
              [a11yLabel]="group.items.length + ' OKRs.'"
              [uiCount]="group.items.length"
              [uiStyle]="{ backgroundColor: 'transparent', color: '#0D232F', fontWeight: '700', border: '1px solid #7485A2', fontSize: '10px', display: 'flex', alignItems: 'center' }"
              uiSize="small"
              uiStandalone />
            <div
              class="columns"
              [ngClass]="{
                'session-grouping': groupBy === 'session',
                'ownership-grouping': groupBy === 'ownership',
              }">
              <p class="column session-col" *ngIf="groupBy === 'ownership'">{{ "session" | localize }}</p>
              <p class="column confidence-col">{{ "confidence" | localize }}</p>
              <p class="column owner-col">{{ "owner_cap" | localize }}</p>
              <p class="column progress-col">{{ "progress_cap" | localize }}</p>
            </div>
          </div>
        </ng-template>
        <div class="panel-content">
          <div class="panel-content-okrs-grid" [class.reserved-session-report-space]="groupBy === 'session'">
            <div class="session-okrs-wrap" *ngFor="let objective of group.items">
              <div class="okr-item-row goal-row">
                <h6 class="item-name-container">
                  <item-name-with-icon
                    class="title-nowrap"
                    [a11yLabelExtra]="getItemWithIconExtraLabel(objective)"
                    [isDisabled]="objective.isRestricted || objective.obfuscated"
                    [name]="getItemName(objective)"
                    [obfuscated]="objective.obfuscated && !objective.isRestricted"
                    [showTooltipWhenTextHidden]="true"
                    (nameClick)="openGoal(objective.id)"
                    icon="goal"></item-name-with-icon>
                </h6>

                <span class="session-name-container" *ngIf="groupBy === 'ownership'" [uiTooltipTitle]="objective.sessionName" (nameClick)="openGoal(objective.id)" tooltipIfOverflow="hover" ui-tooltip>
                  {{ objective.sessionName }}
                </span>

                <!-- deliborately left empty element - OKRs don't specify confidence (just KRs do), but this element keeps the structure table-like -->
                <span class="confidence-label-container"></span>

                <ng-container *ngIf="!objective.isRestricted">
                  <ui-assignee-avatar-group [uiMax]="2" uiSize="xs">
                    <ui-assignee-avatar *ngFor="let assignee of objective.ownerIds" [fromAssigneeId]="assignee" />
                  </ui-assignee-avatar-group>

                  <metric-progress-bar
                    [delta]="objective.attainmentDelta"
                    [progress]="objective.attainment"
                    [uiHideBar]="progressBarState[group.id]?.hideBar"
                    [uiHideDelta]="progressBarState[group.id]?.hideDelta"></metric-progress-bar>
                </ng-container>
              </div>

              <div class="okr-item-row metric-row" *ngFor="let metric of objective.metrics">
                <h6 class="item-name-container">
                  <item-name-with-icon
                    class="title-nowrap"
                    [a11yLabelExtra]="getMetricNameLabel(metric)"
                    [isDisabled]="objective.isRestricted && metric.isRestricted"
                    [name]="objective.isRestricted && metric.isRestricted ? restrictedMetricTitle : metric.name"
                    [showTooltipWhenTextHidden]="true"
                    (nameClick)="openMetric(metric.id)"
                    icon="metric"></item-name-with-icon>
                </h6>

                <!-- deliborately left empty element - KRs don't specify parent session (just OKRs do), but this element keeps the structure table-like -->
                <span class="session-name-container" *ngIf="groupBy === 'ownership'"></span>
                <ng-container *ngIf="objective.isRestricted === undefined || (objective.isRestricted && !metric.isRestricted)">
                  <span class="confidence-label-container">
                    <confidence-label *ngIf="metric.confidence" [confidenceValue]="metric.confidence.value" [isPreview]="true" />
                  </span>

                  <ui-assignee-avatar-group [uiMax]="2" uiSize="xs">
                    <ui-assignee-avatar *ngFor="let assignee of metric.ownerIds" [fromAssigneeId]="assignee" />
                  </ui-assignee-avatar-group>

                  <metric-progress-bar
                    [delta]="metric.attainmentDelta"
                    [progress]="metric.attainment"
                    [uiHideBar]="progressBarState[group.id]?.hideBar"
                    [uiHideDelta]="progressBarState[group.id]?.hideDelta"></metric-progress-bar>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container *ngIf="showReport && groupBy === 'session'">
            <session-summary-report
              [contextTeamId]="assigneeType === 'team' ? assigneeId : ''"
              [expanded]="sectionsState[group.id]?.panel && sectionsState[group.id]?.summary"
              [isDisabled]="group.isRestricted"
              [sessionId]="group.id"
              [trackMeta]="getSummaryReportExtraTrackingMetadata(group.id)"></session-summary-report>
          </ng-container>
        </div>
      </ui-collapse-panel>
    </ui-collapse>
  </div>
</ui-loading-indicator>

<ng-template #loadingTemplate>
  <widget-skeleton />
</ng-template>

<ng-container *ngIf="!loading && !sections.length">
  <container-empty-state
    *hasPermissionWhen="'ManageOKRsGranularPermissions'; flagIsOn: 'goals:create'; flagIsOff: 'ManageGoals'; negate: true"
    [message]="'you_will_find_team_okrs_here' | localize"
    pictureUrl="/dist/img/home/widgets/empty_myokrs.svg" />

  <container-empty-state
    *hasPermissionWhen="'ManageOKRsGranularPermissions'; flagIsOn: 'goals:create'; flagIsOff: 'ManageGoals'"
    [message]="'draft_okrs_on_your_own_or_get_help' | localize"
    pictureUrl="/dist/img/home/widgets/empty_myokrs.svg">
    <create-okr-split-button *ngIf="this.assigneeType === 'user'" />
  </container-empty-state>
</ng-container>
