import { Injectable } from "@angular/core";
import { Observable, catchError, combineLatest, of, switchMap, take } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { ConversationChatEntryVM, ConversationVM } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { LoadingBlockVM, OrderedStrategyResultEntryVM, StrategyResultBlockTypeVM, TextStrategyResultVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategyChatQuestionVM, StrategyConversationChatEntryVM } from "../../models/chat/strategy-conversation-chat.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { CHAT_AI_LOADING_ID, CHAT_TEMP_HUMAN_ID } from "../../models/strategy.constants";
import { StrategyConversationService } from "../conversation/strategy-conversation.service";
import { conversationVM2AiVM } from "../utility/strategy-conversation-factory.utils";
import { StrategyConversationChatApiFactory } from "./api-utils/strategy-conversation-chat-api-factory.service";
import { PerplexityUsageStateService } from "./perplexity-usage-state.service";

@Injectable()
export class StrategyConversationChatService {
  private currentChat: Record<string, StrategyConversationChatEntryVM[]> = {};
  private chatEntriesStatus: Record<string, { inserting?: boolean; error?: boolean }> = {};

  constructor(
    private strategyConversationChatApiFactory: StrategyConversationChatApiFactory,
    private strategyConversationService: StrategyConversationService,
    private perplexityUsageStateService: PerplexityUsageStateService
  ) {}

  public updateChatEntryStatus(conversationId: string, chatEntry: ConversationChatEntryVM, entryStatus: { inserting?: boolean; error?: boolean }): void {
    const currentStatus = this.chatEntriesStatus[chatEntry.id] || {};
    chatEntry.inserting = entryStatus.inserting ?? currentStatus.inserting;
    chatEntry.insertingError = entryStatus.error ?? currentStatus.error;
    this.chatEntriesStatus[chatEntry.id] = {
      inserting: chatEntry.inserting,
      error: chatEntry.insertingError,
    };
    this.strategyConversationService.reloadConversation(conversationId);
  }

  public getConversationForAiChat$(conversationId: string): Observable<ConversationVM | null> {
    return this.strategyConversationService.getConversation$(conversationId).pipe(
      switchMap((strategyConversation: StrategyConversationVM | null) => {
        this.currentChat[conversationId] = strategyConversation ? strategyConversation.chat : [];
        const conversation = conversationVM2AiVM(strategyConversation);
        conversation.chat.forEach((chatEntry) => {
          if (this.chatEntriesStatus[chatEntry.id]) {
            chatEntry.inserting = this.chatEntriesStatus[chatEntry.id].inserting;
            chatEntry.insertingError = this.chatEntriesStatus[chatEntry.id].error;
          }
        });
        return of(conversation);
      })
    );
  }

  public askQuestion(
    conversationId: string,
    question: StrategyChatQuestionVM,
    reportOriginBlock: OrderedStrategyResultEntryVM | null,
    chatOverride?: StrategyConversationChatEntryVM[],
    questionDisplayValue?: string
  ): void {
    combineLatest({
      api: this.strategyConversationChatApiFactory.getApi(),
      includeRealTimeData: this.perplexityUsageStateService.getUsePerplexity$(),
    })
      .pipe(
        switchMap(({ api, includeRealTimeData }) =>
          api.askQuestion({
            conversationId,
            question: question.question,
            questionType: question.type as unknown as StrategyResultBlockTypeVM,
            includeRealTimeData,
            resultBlockType: reportOriginBlock?.blockType as unknown as StrategyResultBlockTypeVM,
          })
        ),
        take(1),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((response) => {
        if (response === null) {
          this.registerChatEntryResponse(conversationId, null);
        }
      });

    this.addLoadingChatEntry(conversationId, question, chatOverride, questionDisplayValue, new Date().toUTCString());
  }

  public registerChatEntryResponse(conversationId: string, conversationResponse: StrategyConversationVM | null): void {
    if (conversationResponse === null) {
      this.strategyConversationService.updateConversationChat(
        conversationId,
        this.getCurrentChatForId(conversationId).map((chatEntry) => {
          return chatEntry.id === CHAT_AI_LOADING_ID ? { ...chatEntry, blockType: StrategyResultBlockTypeVM.Error } : chatEntry;
        }),
        { currentlyAnsweringQuestion: false }
      );
    } else {
      this.strategyConversationService.updateConversationChat(conversationId, conversationResponse.chat, { currentlyAnsweringQuestion: false });
    }
  }

  public addLoadingChatEntry(
    conversationId: string,
    question: StrategyChatQuestionVM,
    chatOverride?: StrategyConversationChatEntryVM[],
    questionDisplayValue?: string,
    taskStartedAt?: string
  ): void {
    if (this.strategyConversationService.conversationIsLoading(conversationId)) return;

    this.strategyConversationService.updateConversationChat(
      conversationId,
      [
        ...(chatOverride || this.getCurrentChatForId(conversationId)),
        {
          id: CHAT_TEMP_HUMAN_ID,
          type: "human",
          blockContent: {
            text: questionDisplayValue || question.question,
          } as TextStrategyResultVM,
          blockType: StrategyResultBlockTypeVM.Text,
          createdBy: storage.get("userId"),
        },
        {
          id: CHAT_AI_LOADING_ID,
          type: "ai",
          blockContent: {
            type: question.type,
            text: question.question,
          } as LoadingBlockVM,
          blockType: StrategyResultBlockTypeVM.Loading,
          erroredBlockType: question.type,
          startedAt: taskStartedAt,
        },
      ],
      { currentlyAnsweringQuestion: true }
    );
  }

  public retryErroredMessage(conversationId: string): void {
    const oldQuestion: StrategyChatQuestionVM = {
      question: "",
      type: null,
    };
    let isRetryError = false;
    const noLoadingChat = this.getCurrentChatForId(conversationId).filter((chatEntry) => {
      if (chatEntry.id === CHAT_AI_LOADING_ID) {
        oldQuestion.question = (chatEntry.blockContent as TextStrategyResultVM).text;
        oldQuestion.type = chatEntry.erroredBlockType;
        return false;
      } else if (chatEntry.blockType === "error") {
        isRetryError = true;
      }
      return chatEntry.id !== CHAT_TEMP_HUMAN_ID;
    });
    if (oldQuestion.type !== null) {
      this.askQuestion(conversationId, oldQuestion, null, noLoadingChat);
    } else if (isRetryError) {
      this.regenerateLastChatEntry(conversationId);
    }
  }

  public provideFeedbackForMessage(conversationId: string, message: StrategyConversationChatEntryVM, type: "positive" | "negative"): void {
    this.strategyConversationChatApiFactory
      .getApi()
      .pipe(
        switchMap((api) => api.provideFeedbackForMessage(conversationId, message.id, type)),
        take(1)
      )
      .subscribe(() => {
        // loading complete, conversation already updated
      });
    this.strategyConversationService.updateConversationChat(
      conversationId,
      this.getCurrentChatForId(conversationId).map((chatEntry) => {
        return chatEntry.id === message.id
          ? ((chatEntry.feedback = {
              score: type === "positive" ? "good" : "bad",
            }),
            chatEntry)
          : chatEntry;
      })
    );
  }

  public regenerateLastChatEntry(conversationId: string): void {
    this.strategyConversationChatApiFactory
      .getApi()
      .pipe(
        switchMap((api) => api.regenerateLastChatEntry(conversationId)),
        take(1),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((response) => {
        if (response === null) {
          this.registerRegenerateLastChatEntryResponse(conversationId, null);
        }
      });
    this.regenerateLastChatEntryVisuals(conversationId);
  }

  public registerRegenerateLastChatEntryResponse(conversationId: string, updatedChatEntry: StrategyConversationChatEntryVM): void {
    if (updatedChatEntry) {
      const currentChatForConversation = this.getCurrentChatForId(conversationId);
      if (currentChatForConversation.length === 0) return;
      const lastEntry = currentChatForConversation[currentChatForConversation.length - 1];
      this.strategyConversationService.updateConversationChat(
        conversationId,
        this.getCurrentChatForId(conversationId).map((chatEntry) => {
          return chatEntry.id === lastEntry.id ? updatedChatEntry : chatEntry;
        }),
        { currentlyAnsweringQuestion: false }
      );
    } else {
      this.strategyConversationService.updateConversationChat(
        conversationId,
        this.getCurrentChatForId(conversationId).map((chatEntry) => {
          return chatEntry.blockType === StrategyResultBlockTypeVM.Loading.toString() ? { ...chatEntry, blockType: StrategyResultBlockTypeVM.Error } : chatEntry;
        }),
        { currentlyAnsweringQuestion: false }
      );
    }
  }

  public regenerateLastChatEntryVisuals(conversationId: string): void {
    const currentChatForConversation = this.getCurrentChatForId(conversationId);
    if (currentChatForConversation.length === 0) return;
    const lastEntry = currentChatForConversation[currentChatForConversation.length - 1];
    this.strategyConversationService.updateConversationChat(
      conversationId,
      this.getCurrentChatForId(conversationId).map((chatEntry) => {
        return chatEntry.id === lastEntry.id ? { ...chatEntry, blockType: StrategyResultBlockTypeVM.Loading } : chatEntry;
      }),
      { currentlyAnsweringQuestion: true }
    );
  }

  private getCurrentChatForId(id: string): StrategyConversationChatEntryVM[] {
    return this.currentChat[id] || [];
  }
}
