@for (group of groups; track group.name) {
  <div @highlight>
    <h3>{{ group.name }}</h3>

    <ul class="sp-mb-12">
      @for (item of group.children; track item.id; let last = $last, first=$first) {
        <li [@highlight] [class.sp-pb-12]="last" [traceElement]="'strategy-ai-list-item'" [tracingBounds]="{ start: first, end: last }" role="presentation">
          <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: item }"> </ng-container>
        </li>
      }
    </ul>
  </div>
}
