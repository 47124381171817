import { OrderedStrategyResultEntryVM, StrategyResultBlockContentVM, StrategyResultBlockTypeVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { REPORT_DUMMY_BLOCK_ID } from "../../models/strategy.constants";
import { swapResultIndexes } from "../utility/strategy-conversation.utils";

export class ResultPersistenceBuilder {
  private conversationToBeExported: StrategyConversationVM;
  constructor(private conversation: StrategyConversationVM) {
    this.conversationToBeExported = { ...this.conversation };
  }

  public deleteBlock(resultBlockToBeDeleted: OrderedStrategyResultEntryVM): ResultPersistenceBuilder {
    this.conversationToBeExported = {
      ...this.conversationToBeExported,
      results: this.conversationToBeExported.results.filter((resultBlock) => resultBlock.id !== resultBlockToBeDeleted.id),
    };
    return this;
  }

  public updateBlock(updatedResultBlock: OrderedStrategyResultEntryVM): ResultPersistenceBuilder {
    this.conversationToBeExported = {
      ...this.conversationToBeExported,
      results: this.conversationToBeExported.results.map((resultBlock) => {
        if (resultBlock.id === updatedResultBlock.id) {
          return updatedResultBlock;
        }
        return resultBlock;
      }),
    };
    return this;
  }

  public moveBlockDown(resultBlock: OrderedStrategyResultEntryVM): ResultPersistenceBuilder {
    const index = this.conversationToBeExported.results.findIndex((result) => result.id === resultBlock.id);
    if (index === this.conversationToBeExported.results.length - 1) {
      return;
    }
    this.conversationToBeExported = swapResultIndexes(this.conversationToBeExported, index, index + 1);
    return this;
  }

  public moveBlockUp(resultBlock: OrderedStrategyResultEntryVM): ResultPersistenceBuilder {
    const index = this.conversationToBeExported.results.findIndex((result) => result.id === resultBlock.id);
    if (index === 0) {
      return;
    }
    this.conversationToBeExported = swapResultIndexes(this.conversationToBeExported, index, index - 1);
    return this;
  }

  public insertBlockAtIndex(
    index: number,
    content: StrategyResultBlockContentVM,
    blockType: StrategyResultBlockTypeVM,
    suggestionId?: string,
    shapeId?: string
  ): ResultPersistenceBuilder {
    const newResultBlock: OrderedStrategyResultEntryVM = this.createOrderedResultBlockType(content, blockType, suggestionId, shapeId);

    const newResults: OrderedStrategyResultEntryVM[] = [
      ...this.conversationToBeExported.results.slice(0, index),
      newResultBlock,
      ...this.conversationToBeExported.results.slice(index),
    ];

    this.conversationToBeExported = {
      ...this.conversationToBeExported,
      results: newResults,
    };

    return this;
  }

  public fillDummyObject(content: StrategyResultBlockContentVM, blockType: StrategyResultBlockTypeVM, shapeId?: string): ResultPersistenceBuilder {
    this.conversationToBeExported = {
      ...this.conversationToBeExported,
      results: this.conversationToBeExported.results.map((resultBlock) => {
        if (resultBlock.id === REPORT_DUMMY_BLOCK_ID) {
          return this.createOrderedResultBlockType(content, blockType, null, shapeId);
        }
        return resultBlock;
      }),
    };
    return this;
  }

  public build(): OrderedStrategyResultEntryVM[] {
    // since this is for persistence's sake we need to purge it by removing the dummy object
    return this.conversationToBeExported.results.filter((result) => result.id !== REPORT_DUMMY_BLOCK_ID);
  }

  private createOrderedResultBlockType(
    content: StrategyResultBlockContentVM,
    blockType: StrategyResultBlockTypeVM,
    suggestionId?: string,
    shapeId?: string
  ): OrderedStrategyResultEntryVM {
    return {
      id: undefined,
      isCreatedInThisSession: true,
      blockType,
      blockContent: content,
      suggestionId,
      shapeId,
    };
  }
}
