<ng-template>
  <ui-date-picker
    [a11yLabelledby]="labelId"
    [attr.data-test-id]="e2eTestId"
    [formControl]="formControl"
    [readonly]="isReadonly"
    [uiAllowClear]="allowClear"
    [uiBackdrop]="backdrop"
    [uiBorderless]="borderless"
    [uiDisabledDate]="isDateDisabled"
    [uiId]="controlId"
    [uiOpen]="open"></ui-date-picker>
</ng-template>
