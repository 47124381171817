@for (kpiBlockContent of kpisBlockContent.kpis; track $index) {
  <section>
    <h6>{{ kpiBlockContent.kpi }}</h6>

    <p>{{ kpiBlockContent.reasoning }}</p>

    @for (reference of kpiBlockContent.references; track $index) {
      <p class="reference">
        {{ reference }}
      </p>
    }
  </section>
}
