import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCheckboxModule } from "@quantive/ui-kit/checkbox";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonLineComponent } from "@quantive/ui-kit/skeleton";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { StrategicMapAreaVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { AreaFormComponent } from "../area-form/area-form.component";

@Component({
  selector: "area-card",
  standalone: true,
  imports: [NgIf, NgFor, AreaFormComponent, DropdownModule, UiSkeletonLineComponent, UiButtonModule, UiIconModule, UiCheckboxModule],
  templateUrl: "./area-card.component.html",
  styleUrl: "./area-card.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaCardComponent {
  @Input() public area: StrategicMapAreaVM;
  @Input() public areaBeingModifiedId: string;
  @Input() public areaMenuItems: DropdownMenuItem[];
  @Input() public areaHasAtLeastOneHypothesis: boolean;
  @Input() public isAreaStatusCompleted: boolean;
  @Input() public grooming: boolean = false;

  @Output() private readonly editArea = new EventEmitter<StrategicMapAreaVM>();
  @Output() private readonly saveArea = new EventEmitter<Event>();
  @Output() private readonly cancelSaveArea = new EventEmitter<StrategicMapAreaVM>();
  @Output() private readonly cancelEditArea = new EventEmitter<StrategicMapAreaVM>();

  public onEditAreaSubmit(): void {
    this.editArea.emit(this.area);
  }

  public onSaveAreaSubmit(event: Event): void {
    this.saveArea.emit(event);
  }

  public onCancelSaveArea(): void {
    this.cancelSaveArea.emit(this.area);
  }

  public onCancelEditArea(): void {
    this.cancelEditArea.emit(this.area);
  }
}
