import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import isEqual from "lodash.isequal";
import { combineLatest } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { ICollection } from "@webapp/core/core.models";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { INavItem } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { StrategicBetsService } from "@webapp/strategy/services/bet/strategic-bets.service";
import { BaseSubNav } from "../services/base-sub-nav";

@UntilDestroy()
@Component({
  selector: "whiteboards-sub-navigation",
  templateUrl: "./whiteboards-sub-navigation.component.html",
})
export class WhiteboardsSubNavigationComponent extends BaseSubNav implements OnInit {
  public options: SubNavOption[] = [{ label: "whiteboards", state: "gtmhub.whiteboards" }];
  public suggested: ICollection<INavItem & { decisionId: string }>;
  public favorites: ICollection<INavItem & { decisionId: string }>;
  public recents: ICollection<INavItem & { decisionId: string }>;

  private recentsIds: string[] = [];
  private suggestedIds: string[] = [];
  private favoritesIds: string[] = [];

  public constructor(
    protected navigationItemsMediator: NavigationItemsMediator,
    private piFeatureVisibilityFacade: PiFeatureVisibilityFacade,
    private editionFeatureService: EditionFeatureService,
    private permissionsFacade: PermissionsFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
    private strategicBetsService: StrategicBetsService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    combineLatest([
      this.piFeatureVisibilityFacade.isGuidedOKRsCreationVisible$(),

      this.editionFeatureService.hasFeature$("strategies"),
      this.permissionsFacade.hasPermission$("AccessStrategies"),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.DecisionsV2),
    ])
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(([isGuidedOKRsCreationVisisble, hasStrategiesFeature, hasAccessStrategiesPermission, isDecisionsV2Enabled]) => {
        if (hasStrategiesFeature && hasAccessStrategiesPermission && !isDecisionsV2Enabled) {
          this.options.push({ label: "decisions", state: "gtmhub.decisions" });
        }

        if (hasStrategiesFeature && hasAccessStrategiesPermission && isDecisionsV2Enabled) {
          this.options.push({ label: "decisions", state: "gtmhub.decisionNewV2" });
        }

        if (isGuidedOKRsCreationVisisble) {
          this.options.push({ label: "okr_suggestions", state: "gtmhub.goalSuggestions" });
        }
      });

    this.setupEntityTypes(["whiteboard"], "whiteboards");
  }

  public ngOnInit(): void {
    super.ngOnInit();

    combineLatest([this.suggested$, this.favorites$, this.recents$])
      .pipe(untilDestroyed(this))
      .subscribe(([suggested, favorites, recents]) => {
        this.processBetsForAll(suggested, favorites, recents);
      });
  }

  private processBetsForAll(suggested: ICollection<INavItem>, favorites: ICollection<INavItem>, recents: ICollection<INavItem>): void {
    const suggestedIds = this.extractIds(suggested);
    const favoritesIds = this.extractIds(favorites);
    const recentsIds = this.extractIds(recents);

    if (!suggestedIds.length && !favoritesIds.length && !recentsIds.length) return;

    if (this.areCollectionsUnchanged(suggestedIds, favoritesIds, recentsIds)) {
      this.updateWithExistingDecisionIds(suggested, favorites, recents);
      return;
    }

    this.suggestedIds = suggestedIds;
    this.favoritesIds = favoritesIds;
    this.recentsIds = recentsIds;

    const allWhiteboardIds = [...new Set([...suggestedIds, ...favoritesIds, ...recentsIds])];
    this.fetchAndUpdateBets(allWhiteboardIds, suggested, favorites, recents);
  }

  private extractIds(collection: ICollection<INavItem>): string[] {
    return collection?.items.map((item) => item.id) || [];
  }

  private areCollectionsUnchanged(suggestedIds: string[], favoritesIds: string[], recentsIds: string[]): boolean {
    const allSuggestedSame = isEqual(suggestedIds, this.suggestedIds);
    const allFavoritesSame = isEqual(favoritesIds, this.favoritesIds);
    const allRecentsSame = isEqual(recentsIds, this.recentsIds);

    return allSuggestedSame && allFavoritesSame && allRecentsSame;
  }

  private updateWithExistingDecisionIds(suggested: ICollection<INavItem>, favorites: ICollection<INavItem>, recents: ICollection<INavItem>): void {
    this.suggested = this.mapCollectionWithExistingIds(suggested, this.suggested);
    this.favorites = this.mapCollectionWithExistingIds(favorites, this.favorites);
    this.recents = this.mapCollectionWithExistingIds(recents, this.recents);
    this.cdr.detectChanges();
  }

  private mapCollectionWithExistingIds(
    source: ICollection<INavItem>,
    target: ICollection<INavItem & { decisionId: string }>
  ): ICollection<INavItem & { decisionId: string }> {
    return {
      items:
        source?.items.map((item) => {
          const decisionId = target?.items.find((i) => i.id === item.id)?.decisionId || "";
          return { ...item, decisionId };
        }) || [],
      totalCount: source?.totalCount || 0,
    };
  }

  private fetchAndUpdateBets(whiteboardIds: string[], suggested: ICollection<INavItem>, favorites: ICollection<INavItem>, recents: ICollection<INavItem>): void {
    this.strategicBetsService
      .getBets$({
        filter: {
          whiteboardId: {
            $in: whiteboardIds,
          },
          conversationId: { $exists: true },
        },
      })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(({ bets }) => {
        const betMap = new Map<string, string>();
        bets.forEach((bet) => betMap.set(bet.whiteboardId, bet.id));

        this.suggested = this.mapCollectionWithBets(suggested, betMap);
        this.favorites = this.mapCollectionWithBets(favorites, betMap);
        this.recents = this.mapCollectionWithBets(recents, betMap);

        this.cdr.detectChanges();
      });
  }

  private mapCollectionWithBets(collection: ICollection<INavItem>, betMap: Map<string, string>): ICollection<INavItem & { decisionId: string }> {
    return {
      items: collection?.items.map((item) => ({ ...item, decisionId: betMap.get(item.id) || "" })) || [],
      totalCount: collection?.totalCount || 0,
    };
  }
}
