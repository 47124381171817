import { OrderedStrategyResultEntryVM, StrategyResultBlockContentVM, StrategyResultBlockTypeVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { REPORT_DUMMY_BLOCK_ID, REPORT_NEWLY_CREATED_BLOCK_ID } from "../../models/strategy.constants";
import { buildDummyConversationResultBlockVM } from "../utility/strategy-conversation-factory.utils";
import { swapResultIndexes } from "../utility/strategy-conversation.utils";
import {
  updateAnsoffBlock,
  updateBalanceScorecardBlock,
  updateBlueOceanBlock,
  updateFreeTextBlock,
  updatePestBlock,
  updatePorterBlock,
  updateSwotBlock,
} from "./strategy-elements-updaters.service";

const moveDummyToBottom = (results: OrderedStrategyResultEntryVM[]): OrderedStrategyResultEntryVM[] => {
  return [...results.filter((result) => result.id !== REPORT_DUMMY_BLOCK_ID), results.find((result) => result.id === REPORT_DUMMY_BLOCK_ID)];
};

// convention - methods starting with build are used to create new objects
// methods starting with change are used to update existing objects
// we need both approaches, as we need to be very careful when we replace the report related objects
// as we don't want to impede the edit experience
export const buildInitialReportConversation = (conversation: StrategyConversationVM): StrategyConversationVM => {
  return {
    ...conversation,
    results: [...conversation.results, buildDummyConversationResultBlockVM()],
  };
};

export const buildConversationWithDeletedBlock = (conversation: StrategyConversationVM, deletedBlock: OrderedStrategyResultEntryVM): StrategyConversationVM => {
  return {
    ...conversation,
    results: conversation.results.filter((result) => result.id !== deletedBlock.id),
  };
};

export const buildConversationMoveResultBlockDown = (conversation: StrategyConversationVM, resultBlock: OrderedStrategyResultEntryVM): StrategyConversationVM => {
  const index = conversation.results.findIndex((result) => result.id === resultBlock.id);
  if (index === conversation.results.length - 1) {
    return conversation;
  }
  return swapResultIndexes(conversation, index, index + 1);
};

export const buildConversationMoveResultBlockUp = (conversation: StrategyConversationVM, resultBlock: OrderedStrategyResultEntryVM): StrategyConversationVM => {
  const index = conversation.results.findIndex((result) => result.id === resultBlock.id);
  if (index === 0) {
    return conversation;
  }
  return swapResultIndexes(conversation, index, index - 1);
};

export const buildConversationByInsertingBlockAtIndex = (
  conversation: StrategyConversationVM,
  content: StrategyResultBlockContentVM,
  blockType: StrategyResultBlockTypeVM,
  index: number,
  options: { isCreatedInThisSession: boolean }
): StrategyConversationVM => {
  const newResultBlock: OrderedStrategyResultEntryVM = {
    id: REPORT_NEWLY_CREATED_BLOCK_ID,
    blockType,
    blockContent: content,
    isCreatedInThisSession: options.isCreatedInThisSession,
  };
  const newResults: OrderedStrategyResultEntryVM[] = [...conversation.results.slice(0, index), newResultBlock, ...conversation.results.slice(index)];
  return {
    ...conversation,
    results: moveDummyToBottom(newResults),
  };
};

export const changeConversationBasedOnNewResults = (
  conversation: StrategyConversationVM,
  updatedResultBlock: OrderedStrategyResultEntryVM,
  newResults: OrderedStrategyResultEntryVM[]
): StrategyConversationVM => {
  conversation.results.forEach((resultBlock) => {
    if (resultBlock.id === updatedResultBlock.id) {
      const newUpdatedResultBlock = newResults.find((result) => result.id === updatedResultBlock.id);
      if (newUpdatedResultBlock.blockType === "free_text") {
        updateFreeTextBlock(newUpdatedResultBlock, resultBlock);
      } else if (newUpdatedResultBlock.blockType === "swot") {
        updateSwotBlock(newUpdatedResultBlock, resultBlock);
      } else if (newUpdatedResultBlock.blockType === "ansoff") {
        updateAnsoffBlock(newUpdatedResultBlock, resultBlock);
      } else if (newUpdatedResultBlock.blockType === "pest") {
        updatePestBlock(newUpdatedResultBlock, resultBlock);
      } else if (newUpdatedResultBlock.blockType === "porter") {
        updatePorterBlock(newUpdatedResultBlock, resultBlock);
      } else if (newUpdatedResultBlock.blockType === "balanced_scorecard") {
        updateBalanceScorecardBlock(newUpdatedResultBlock, resultBlock);
      } else if (newUpdatedResultBlock.blockType === "blue_ocean") {
        updateBlueOceanBlock(newUpdatedResultBlock, resultBlock);
      }
    }
  });
  return conversation;
};

export const changeConversationByAddingMissingReportIds = (
  conversation: StrategyConversationVM,
  updatedResults: OrderedStrategyResultEntryVM[]
): StrategyConversationVM => {
  const newResultsIds = updatedResults.map((result) => result.id);
  const oldResultsIds = conversation.results.map((result) => result.id);
  const newlyCreatedBlock = conversation.results.find((resultBlock) => resultBlock.id === REPORT_NEWLY_CREATED_BLOCK_ID);
  const unusedId = newResultsIds.find((id) => oldResultsIds.indexOf(id) === -1);
  newlyCreatedBlock.id = unusedId;
  return conversation;
};

export const changeConversationByFillingInDummyObject = (
  conversation: StrategyConversationVM,
  content: StrategyResultBlockContentVM,
  blockType: StrategyResultBlockTypeVM
): StrategyConversationVM => {
  // replace the current dummy object with a newly created one containing the providede content
  const dummyObject = conversation.results.find((resultBlock) => resultBlock.id === REPORT_DUMMY_BLOCK_ID);
  dummyObject.id = REPORT_NEWLY_CREATED_BLOCK_ID;
  dummyObject.blockContent = content;
  dummyObject.blockType = blockType;

  // insert a new dummy block
  conversation.results.push(buildDummyConversationResultBlockVM());

  return conversation;
};
