import { RenamedUiToastDataOptionsType, UiToastConfig, UiToastDataOptions, renamedUiToastDataOptions } from "@webapp/ui/toast/toast.models";
import { ToastConfigBuilder } from "./toast-config-builder";

export const uiToastToNzMessageDataOptions = (options: UiToastDataOptions): UiToastDataOptions => {
  if (!options) return options;

  const transformedOptions: UiToastDataOptions = {};
  const keys: (keyof UiToastDataOptions)[] = Object.keys(options) as (keyof UiToastDataOptions)[];

  for (const index in keys) {
    const uiSyntaxKey = keys[index];
    const uiKeysToBeReplaced = renamedUiToastDataOptions;
    const nzSyntaxKey = uiKeysToBeReplaced.includes(uiSyntaxKey as RenamedUiToastDataOptionsType) ? uiSyntaxKey.replace("ui", "nz") : uiSyntaxKey;

    transformedOptions[nzSyntaxKey] = options[uiSyntaxKey];
  }

  return transformedOptions;
};

export const toToastConfig = (options: UiToastDataOptions = {}): UiToastConfig => {
  return new ToastConfigBuilder().setNzMaxStack().setNzDuration(options).setNzTop(options).setNzLeft(options).build();
};
