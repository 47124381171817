import { IModalService } from "angular-ui-bootstrap";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAlertModule } from "@quantive/ui-kit/alert";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { take } from "rxjs";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { generateDefaultMandatoryOkrAlignmentAccountSettings } from "@gtmhub/configuration/utils/utils";
import { humanError } from "@gtmhub/error-handling/util";
import { localize } from "@gtmhub/localization";
import { IChecklist, OnboardingService } from "@gtmhub/onboarding";
import { filterActiveSessions } from "@gtmhub/sessions/redux/session-selectors";
import { HowToStartModalOpenerService } from "@gtmhub/shared/services/how-to-start-modal-opener.service";
import { getCurrentUserId } from "@gtmhub/users";
import { UserInvitationModalService } from "@gtmhub/users/components/user-invitation-form/user-invitation-modal.service";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { IMandatoryOkrAlignmentSettings } from "@webapp/configuration/models/configuration.model";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { HomeWidgetService } from "@webapp/home/services/home-widget.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { CreateOkrFormModalService } from "@webapp/okrs/components/create-okr-form/create-okr-form-modal.service";
import { HowToStartModalOpenerServiceProvider } from "@webapp/onboarding/providers/how-to-start-modal-opener-service.provider";
import { OnboardingServiceProvider } from "@webapp/onboarding/providers/onboarding-service.provider";
import { HasAllPermissionsDirective } from "@webapp/permissions/directives/has-all-permissions.directive";
import { HasPermissionWithFeatureFlagDirective } from "@webapp/permissions/directives/has-permission-with-ff.directive";
import {
  GuidedOKRsModalData,
  GuidedOKRsModalWrapperComponent,
} from "@webapp/platform-intelligence/guided-okrs/components/modal-wrapper/guided-okrs-modal-wrapper.component";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { SessionsRepository } from "@webapp/sessions/services/sessions-repository.service";
import { UibModalProvider } from "@webapp/shared/providers/uib-modal.provider";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";
import { UiLinkModule } from "@webapp/ui/link/link.module";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { CurrentUserRepository } from "@webapp/users";
import { UserInvitationModalServiceProvider } from "@webapp/users/providers/users.provider";
import { CreateYourFirstWhiteboardComponent } from "@webapp/whiteboards/components/create-your-first-whiteboard/create-your-first-whiteboard.component";
import { CreateFirstWhiteboardType } from "@webapp/whiteboards/components/create-your-first-whiteboard/models";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";

@UntilDestroy()
@Component({
  selector: "onboarding-widget",
  templateUrl: "./onboarding-widget.component.html",
  styleUrls: ["./onboarding-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiButtonModule,
    UiCardModule,
    UiIconModule,
    CommonModule,
    UiLinkModule,
    LocalizationModule,
    AnalyticsModule,
    HasAllPermissionsDirective,
    UiAlertModule,
    WidgetActionsComponent,
    HasPermissionWithFeatureFlagDirective,
  ],
  providers: [OnboardingServiceProvider, UserInvitationModalServiceProvider, UibModalProvider, HowToStartModalOpenerServiceProvider, DefaultSessionResolverService],
})
export class OnboardingWidgetComponent extends BaseWidgetComponent implements OnInit {
  public error: string;
  private isOkrAlignmentMandatory: boolean;
  private checklist: IChecklist;
  private isGuidedOKRsCreationEnabled: boolean;
  private widget: UiDashboardWidget;

  public get title(): string {
    return "Get started with Quantive";
  }

  public get a11yLabel(): string {
    return "Get started with Quantive";
  }

  constructor(
    private homeWidgetService: HomeWidgetService,
    private toastService: UiToastService,
    private onboardingService: OnboardingService,
    private currentUserRepository: CurrentUserRepository,
    private userInvitationModal: UserInvitationModalService,
    private accountService: AccountService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private piFeatureVisibilityFacade: PiFeatureVisibilityFacade,
    private modalService: UiModalService,
    private analyticsService: AnalyticsService,
    private defaultSessionResolverService: DefaultSessionResolverService,
    private sessionsRepository: SessionsRepository,
    private howToStartModalOpenerService: HowToStartModalOpenerService,
    @Inject("$uibModal") private $uibModal: IModalService,
    private cdr: ChangeDetectorRef,
    private createOkrFormModalService: CreateOkrFormModalService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.checklist = this.onboardingService.initChecklist();

    const mandatoryOkrAlignmentSettings: IMandatoryOkrAlignmentSettings =
      this.accountService.getAccountSetting("mandatoryOkrAlignment") || generateDefaultMandatoryOkrAlignmentAccountSettings();

    this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.MandatoryOkrAlignmentFeature).subscribe((isMandatoryOkrAlignmentEnabled) => {
      this.isOkrAlignmentMandatory =
        isMandatoryOkrAlignmentEnabled && mandatoryOkrAlignmentSettings.enabled && !mandatoryOkrAlignmentSettings.excludedUserIds.includes(getCurrentUserId());
    });

    this.piFeatureVisibilityFacade
      .isGuidedOKRsCreationVisible$()
      .pipe(take(1))
      .subscribe((enabled) => (this.isGuidedOKRsCreationEnabled = enabled));

    this.homeWidgetService
      .getDashboardWidgets$()
      .pipe(untilDestroyed(this))
      .subscribe((widgets) => {
        this.widget = widgets.find((w) => w.id === this.widgetId);
      });
  }

  public remove(): void {
    if (!this.widget) {
      throw new Error(`Cannot find ${this.widgetId} widget`);
    }

    const widget = this.widget;
    this.homeWidgetService.removeWidget(this.widgetId);

    const message = localize("you_can_find_resources_in_support_center");
    this.toastService.info(message, {
      uiShouldTimeout: true,
      uiDuration: 5000,
      uiPrimaryCTA: localize("undo"),
      uiOnPrimaryAction: () => this.homeWidgetService.addWidget(widget),
    });
  }

  public createDemoAccount(): void {
    this.error = null;

    this.onboardingService.createDemoAccount(this.checklist).then(
      (result) => {
        this.checklist = result;
        this.updateChecklistInUserSettings();
      },
      (error) => {
        this.error = humanError(error);
        this.cdr.markForCheck();
      }
    );
  }

  public visitMarketplace(): void {
    this.checklist = this.onboardingService.visitMarketplace(this.checklist);
    this.updateChecklistInUserSettings();
  }

  public openInvitationModal(): void {
    this.userInvitationModal.open();
    this.checklist.stepsV1.invitedTeammate = true;
    this.currentUserRepository.setUserSetting({ checklist: this.checklist });
  }

  public openSelectHowToStartModal(): void {
    if (this.isOkrAlignmentMandatory) {
      this.createOkrFormModalService.openFormModal({
        flowName: PiTrackingFlowNameEnum.CreateObjectiveFormHomeEmptyState,
      });
    } else if (this.isGuidedOKRsCreationEnabled) {
      this.modalService
        .create<CreateYourFirstWhiteboardComponent, CreateFirstWhiteboardType>({
          uiTitle: localize("how_you_want_to_start"),
          uiContent: CreateYourFirstWhiteboardComponent,
          uiFooter: null,
        })
        .afterClose.subscribe((type) => {
          if (type === "suggestion") {
            this.openWizard();
            return;
          }
          if (type === "template") {
            this.analyticsService.track("Create First OKR Clicked", { how_to_start: "with a template" });
            this.openGoalCreationModal({ shouldStartWithTemplate: true });
            return;
          }

          this.analyticsService.track("Create First OKR Clicked", { how_to_start: "on your own" });
          this.openGoalCreationModal({ shouldStartWithTemplate: false });
        });
    } else {
      this.howToStartModalOpenerService.openHowToStartModal([
        {
          title: "start_with_template",
          text: "we_have_crafted_hundreds_templates",
          img: require("wwwroot/img/screens/onboarding-home/visit-marketplace-inverted.svg"),
          button: {
            actionCb: (): void => {
              this.analyticsService.track("Create First OKR Clicked", { how_to_start: "with a template" });
              this.openGoalCreationModal({ shouldStartWithTemplate: true });
            },
            kind: "primary",
          },
        },
        {
          title: "start_on_your_own",
          text: "if_you_know_how_to_create",
          img: require("wwwroot/img/screens/onboarding-home/live-demo.svg"),
          button: {
            actionCb: (): void => {
              this.analyticsService.track("Create First OKR Clicked", { how_to_start: "on your own" });
              this.openGoalCreationModal({ shouldStartWithTemplate: false });
            },
            kind: "secondary",
          },
        },
      ]);
    }
  }

  private openWizard(): void {
    this.sessionsRepository
      .getMap$()
      .pipe(take(1))
      .subscribe((sessionsMap) => {
        const defaultSession = this.defaultSessionResolverService.getDefaultSession(Array.from(sessionsMap.values()));
        this.modalService.create<GuidedOKRsModalWrapperComponent, GuidedOKRsModalData>({
          uiContent: GuidedOKRsModalWrapperComponent,
          uiFooter: null,
          uiClosable: false,
          uiKeyboard: false,
          uiWrapClassName: "guided-okrs-modal-wrapper",
          uiWidth: "100%",
          uiData: {
            currentSession: defaultSession,
            flowName: PiTrackingFlowNameEnum.GuidedOkrsCreationTrialHomeEmptyState,
          },
        });
      });
  }

  private openGoalCreationModal(params: { shouldStartWithTemplate: boolean }): void {
    this.sessionsRepository
      .getMap$()
      .pipe(take(1))
      .subscribe((sessionsMap) => {
        const activeSessions = filterActiveSessions(Array.from(sessionsMap.values()));
        // We don't care which is the first session
        const externalSessionId = activeSessions[0]?.id;
        const isOnlyOpenSession = activeSessions.length === 1;

        this.$uibModal.open({
          windowClass: `create-first-goal ${params.shouldStartWithTemplate ? "wide" : "small"}`,
          backdropClass: "dark-backdrop",
          component: "createFirstGoalForm",
          lazyLoadState: "gtmhub.goals",
          resolve: {
            externalSessionId: () => externalSessionId,
            isOnlyOpenSession: () => isOnlyOpenSession,
            shouldStartWithTemplate: () => params.shouldStartWithTemplate,
          },
        });
      });
  }

  private updateChecklistInUserSettings(): void {
    this.currentUserRepository.setUserSetting({ checklist: this.checklist });
  }
}
