import dayjs from "@webapp/shared/libs/dayjs";

// eslint-disable-next-line @typescript-eslint/no-empty-function

export function diffBetweenTodayAndEndOfDate(endDate: string): number {
  const end = dayjs.tz(endDate);
  const now = dayjs.tz(dayjs());

  return end.diff(now, "days");
}

export function encodeBase64SQL(originalString: string): string {
  try {
    return btoa(unescape(encodeURIComponent(originalString)));
  } catch {
    return originalString;
  }
}
