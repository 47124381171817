import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { PREDEFINED_FRAMEWORKS_PROMPTS_MAP } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/ai-chat.constants";
import { ConversationChatEntryVM, LoadingDataVM } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { LoadingIntervalMessages } from "@webapp/strategy/components/evaluate-bet-page/components/interval-based-loading-card/models";

@Component({
  selector: "ui-chat-loading-block",
  templateUrl: "./chat-loading-block.component.html",
  styleUrl: "./chat-loading-block.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatLoadingBlockComponent implements OnChanges {
  @Input() public message: ConversationChatEntryVM | undefined;
  @Input() public showSkeletonLoading = false;

  public intervalMessages: LoadingIntervalMessages[];

  public defaultMessage: string;

  public ngOnChanges(changes: SimpleChangesOf<ChatLoadingBlockComponent>): void {
    if (changes.message) {
      this.setLoadingMessages();
    }
  }

  private setLoadingMessages(): void {
    this.intervalMessages = [
      {
        intervalDuration: 5000,
        message: "Integrating the strategy model...",
      },
      {
        intervalDuration: 15000,
        message: "Searching context for relevant information...",
      },
      {
        intervalDuration: 25000,
        message: `Summarizing insights for ${this.frameworkLabel} analysis...`,
      },
      {
        intervalDuration: 40000,
        message: `Generating ${this.frameworkLabel} analysis...`,
      },
      {
        intervalDuration: 65000,
        message: `Please hold on, we're processing on the ${this.frameworkLabel} analysis ...`,
      },
      {
        intervalDuration: 90000,
        message: `Just a moment more, we're still processing your ${this.frameworkLabel} analysis...`,
      },
      {
        intervalDuration: 120000,
        message: `Thank you for your patience, we're on your ${this.frameworkLabel} analysis...`,
      },
    ];

    this.defaultMessage = `Thanks for waiting, ${this.frameworkLabel} analysis might take a bit longer...`;
  }

  private get frameworkLabel(): string {
    const frameworkName = (this.message?.blockContent as LoadingDataVM)?.framework;
    return PREDEFINED_FRAMEWORKS_PROMPTS_MAP[frameworkName]?.label;
  }
}
