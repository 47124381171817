import { IDeferred, ILogService, IPromise, IQService, IRootScopeService } from "angular";
import { NgZone } from "@angular/core";
import ReconnectingWebSocket from "reconnecting-websocket";
import { TokenProvider } from "@gtmhub/auth";
import { IBadge } from "@gtmhub/badges";
import { BadgesActions } from "@gtmhub/badges/redux/badges-actions";
import { storage } from "@gtmhub/core/storage";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { CustomFieldActions } from "@gtmhub/customFields/redux/custom-field-actions";
import { CurrentEmployeeActions } from "@gtmhub/employees";
import { EnvironmentService, IAppConfig } from "@gtmhub/env";
import { PlanningSessionsActions } from "@gtmhub/sessions/redux/session-actions";
import { INgRedux } from "@gtmhub/state-management";
import { ITeam, TeamsActions } from "@gtmhub/teams";
import { IUser } from "@gtmhub/users";
import { IdMap } from "@gtmhub/util";
import { AccountResolverService } from "@webapp/accounts";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { LoggingService } from "@webapp/core/logging/services/logging.service";
import { ICustomFieldWSData } from "@webapp/custom-fields/models/custom-fields.models";
import { Employee } from "@webapp/employees";
import { IDaxQueryResult } from "@webapp/integrations/models/powerbi-models";
import { AnswerDTOv2 } from "@webapp/platform-intelligence/pi-text-to-sql/services/conversation/models";
import { DocumentIngestionMessage } from "@webapp/shared/services/ingestion/ingestion.models";
import { AssigneeActions } from "../../assignees/redux/assignee-actions";
import { IEntityData, IGoalsMetricsData, IInsightCalculationData, INotificationsCountData, ISocketV2Data } from "../models";
import { applySocketUpdatesToIdMap } from "../util";

// untyped messages
interface IGeneralMessage {
  messageType:
    | "entityCreated"
    | "entityStatusChanged"
    | "dataFailuresRecalculated"
    | "dataSourceCreated"
    | "dataSourceStatusChanged"
    | "accountSessionsUpdated"
    | "insightStateUpdated"
    | "goalDesignScore"
    | "entitySyncProgress"
    | "subscriptionConverted"
    | "entitiesDeletedParentEntitiesChanged";
  data: unknown;
}

interface IGoalsAndMetricsMessage {
  messageType: "goalAndMetrics";
  data: IGoalsMetricsData;
}

interface IAccountAssigneesUpdatedV2Message {
  messageType: "accountAssigneesUpdatedV2";
  data: ISocketV2Data<Assignee>;
}

interface IAccountUsersUpdatedV2Message {
  messageType: "accountUsersUpdatedV2";
  data: ISocketV2Data<IUser>;
}

interface IAccountTeamsUpdatedV2Message {
  messageType: "accountTeamsUpdatedV2";
  data: ISocketV2Data<ITeam>;
}

interface IAccountBadgesUpdatedMessage {
  messageType: "accountBadgesUpdated";
  data: IBadge[];
}

interface IAccountCustomFieldsUpdatedMessage {
  messageType: "accountCustomFieldsUpdated";
  data: ICustomFieldWSData;
}

interface INotificationsCountMessage {
  messageType: "notificationsCount";
  data: INotificationsCountData;
}

export interface IInsightCalculationResultMessage {
  messageType: "insightResponse";
  data: IInsightCalculationData;
  transactionID: string;
}

interface IEntityDataMessage {
  messageType: "entityDataResponse";
  data: IEntityData;
}

interface IDaxQueryExecutedMessage {
  messageType: "daxQueryExecuted";
  data: IDaxQueryResult;
}

interface IConversationAnswerResponse {
  messageType: "conversationAnswerResponse";
  data: AnswerDTOv2;
}

interface IDataIngestionExecutionMessage {
  messageType: "dataIngestionExecution";
  data: DocumentIngestionMessage;
}

interface IMethodologySettingsChangedMessage {
  messageType: "methodologySettingsChanged";
  data: { methodologyType: "default" };
}

type IMessage =
  | IGoalsAndMetricsMessage
  | IAccountAssigneesUpdatedV2Message
  | IAccountUsersUpdatedV2Message
  | IAccountTeamsUpdatedV2Message
  | INotificationsCountMessage
  | IGeneralMessage
  | IInsightCalculationResultMessage
  | IAccountCustomFieldsUpdatedMessage
  | IEntityDataMessage
  | IAccountBadgesUpdatedMessage
  | IDaxQueryExecutedMessage
  | IConversationAnswerResponse
  | IDataIngestionExecutionMessage
  | IMethodologySettingsChangedMessage;

const handleUnknownMessage = (message: never) => console.warn("Unknown web socket message", message);

export class MessagingService {
  public static $inject = [
    "$rootScope",
    "$log",
    "$ngRedux",
    "$q",
    "EnvironmentService",
    "AccountResolverService",
    "PlanningSessionsActions",
    "AssigneeActions",
    "TeamsActions",
    "BadgesActions",
    "CustomFieldActions",
    "CurrentEmployeeActions",
    "appConfig",
    "ngZone",
    "TokenProvider",
    "ApmService",
  ];

  constructor(
    private $rootScope: IRootScopeService & ITraceRootScopeService,
    private $log: ILogService,
    private $ngRedux: INgRedux,
    private $q: IQService,
    private environmentService: EnvironmentService,
    private accountResolverService: AccountResolverService,
    private planningSessionActions: PlanningSessionsActions,
    private assigneeActions: AssigneeActions,
    private teamsActions: TeamsActions,
    private badgesActions: BadgesActions,
    private customFieldActions: CustomFieldActions,
    private currentEmployeeActions: CurrentEmployeeActions,
    appConfig: IAppConfig,
    private ngZone: NgZone,
    private tokenProvider: TokenProvider,
    private apmService: ApmService
  ) {
    this.$log.info("Initialized messaging service.");
    this.loggingService = new LoggingService(appConfig.logging, "spa");
    this.insightsSocketPromise = this.$q.defer();
  }

  private socket: ReconnectingWebSocket;
  private insightsSocket: ReconnectingWebSocket;
  private insightsSocketPromise: IDeferred<unknown>;
  private accountId: string;
  private loggingService: LoggingService;

  private readonly connectionTimeoutInMs = 10000;

  public init(): void {
    this.accountId = this.accountResolverService.getAccountId();
    if (!this.accountId) {
      return;
    }

    this.initializeSocket();
    this.initializeInsightsSocket();
  }

  public onInsightsSocketOpen(): IPromise<unknown> {
    if (!this.accountId) {
      this.insightsSocketPromise.reject("missing accountId");
      return this.insightsSocketPromise.promise;
    }
    if (!this.insightsSocket) {
      this.initializeInsightsSocket();
    }
    return this.insightsSocketPromise.promise;
  }

  private urlProvider = (getUrl: ({ accountId, token }: { accountId: string; token: string }) => string): (() => Promise<string>) => {
    return (() =>
      this.tokenProvider
        .getValidToken()
        .then((token) => getUrl({ accountId: this.accountId, token: token }))
        .catch((err) => {
          if (err.error?.error === "invalid_grant" || !err.expiredToken) {
            // The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid,
            // expired, revoked, etc... the request should not be retried. See: https://www.rfc-editor.org/rfc/rfc6749#section-5.2
            this.apmService.captureError(err.error);
            throw `Failed to establish WebSocket connection: ${err.error}`;
          } else {
            return getUrl({ accountId: this.accountId, token: err.expiredToken });
          }
        })) as () => Promise<string>;
  };

  private initializeSocket(): void {
    const getUrl = ({ accountId, token }: { accountId: string; token: string }): string =>
      this.environmentService.getWSEndpoint(`/subscribe/${accountId}?authorization=${token}`);

    this.ngZone.runOutsideAngular(() => {
      this.socket = new ReconnectingWebSocket(this.urlProvider(getUrl), null, { connectionTimeout: this.connectionTimeoutInMs });
      this.socket.onmessage = (event: MessageEvent) => this.socketOnMessage(event);
      this.socket.onerror = (event) => {
        const url = this.environmentService.getWSEndpoint(`/subscribe/${this.accountId}`);
        this.loggingService.logSocketError(event, url);
        this.apmService.captureError(event.error || event.message);
      };
    });
  }

  private initializeInsightsSocket(): void {
    const getUrl = ({ accountId, token }: { accountId: string; token: string }): string =>
      this.environmentService.getInsightsSocketsEndpoint(`/subscribe?authorization=${token}&accountId=${accountId}`);

    this.ngZone.runOutsideAngular(() => {
      this.insightsSocket = new ReconnectingWebSocket(this.urlProvider(getUrl), null, { connectionTimeout: this.connectionTimeoutInMs });
      this.insightsSocket.onmessage = (event: MessageEvent) => this.socketOnMessage(event);
      this.insightsSocket.onopen = () => this.insightsSocketPromise.resolve(ReconnectingWebSocket.OPEN);
      this.insightsSocket.onclose = () => {
        // Build a promise chain so that all deferred promises are resolved on 'onopen' event (not only the last one).
        const oldPromise = this.insightsSocketPromise;
        const newPromise = this.$q.defer();
        newPromise.promise.then(() => oldPromise.resolve());
        this.insightsSocketPromise = newPromise;
      };
      this.insightsSocket.onerror = (event) => {
        const url = this.environmentService.getInsightsSocketsEndpoint(`/subscribe?accountId=${this.accountId}`);
        this.loggingService.logSocketError(event, url);
        this.apmService.captureError(event.error || event.message);
      };
    });
  }

  private socketOnMessage(event: MessageEvent): void {
    const userId: string = storage.get("userId");
    const message: IMessage = JSON.parse(event.data);
    switch (message.messageType) {
      case "entityCreated":
        this.$rootScope.$broadcast("entityCreated", message.data);
        break;
      case "entityStatusChanged":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("entityStatusChanged", message.data));
        break;
      case "entityDataResponse":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("entityDataResponse", message.data));
        break;
      case "dataFailuresRecalculated":
        this.$rootScope.$broadcast("dataFailuresRecalculated", message.data);
        break;
      case "dataSourceCreated":
        this.$rootScope.$broadcast("dataSourceCreated", message.data);
        break;
      case "dataSourceStatusChanged":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("dataSourceStatusChanged", message.data));
        break;
      case "insightResponse":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("insightResponse", message));
        break;
      case "accountSessionsUpdated":
        this.$rootScope.traceAction("sessions_updated", () => {
          this.$ngRedux.dispatch(this.planningSessionActions.getSessions());
        });
        break;
      case "daxQueryExecuted":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("daxQueryExecuted", message.data));
        break;
      case "accountUsersUpdatedV2":
        {
          this.$rootScope.$broadcast("accountUsersUpdatedV2", message.data);

          const usersUpdatesIdMap = applySocketUpdatesToIdMap(<IdMap<IUser>>{}, message.data);
          if (usersUpdatesIdMap[userId]) {
            const { email, firstName, lastName } = usersUpdatesIdMap[userId];
            const updatedEmployee: Partial<Employee> = {
              email,
              firstName,
              lastName,
            };
            this.$ngRedux.dispatch(this.currentEmployeeActions.updateCurrentEmployee(updatedEmployee));
          }
        }
        break;
      case "accountAssigneesUpdatedV2":
        this.$rootScope.$broadcast("accountAssigneesUpdated", message.data);
        this.$ngRedux.dispatch(this.assigneeActions.updateAssignees(message.data));
        break;
      case "accountTeamsUpdatedV2":
        {
          this.$ngRedux.dispatch(this.teamsActions.updateTeams(message.data));
          this.$ngRedux.dispatch(this.currentEmployeeActions.updateCurrentEmployeeTeams(message.data));
          this.$rootScope.$broadcast("accountTeamsUpdatedV2", message.data);
        }
        break;
      case "accountBadgesUpdated":
        this.$rootScope.$broadcast("accountBadgesUpdated", message.data);
        this.$ngRedux.dispatch(this.badgesActions.updateBadges(message.data));
        break;
      case "notificationsCount":
        {
          if (userId === message.data.userID) {
            this.$rootScope.$broadcast("notificationsCount", message.data);
          }
        }
        break;
      case "goalAndMetrics":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("wsGoalsAndMetricsChanged", message.data));
        break;
      case "insightStateUpdated":
        this.$rootScope.$broadcast("insightStateUpdated", message);
        break;
      case "accountCustomFieldsUpdated":
        this.$ngRedux.dispatch(this.customFieldActions.updateAllCustomFields());
        this.$rootScope.$broadcast("accountCustomFieldsUpdated", message.data);
        break;
      case "goalDesignScore":
        this.$rootScope.$broadcast("updateGoalDesignScore", message.data);
        break;
      case "entitySyncProgress":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("entitySyncProgress", message.data));
        break;
      case "subscriptionConverted":
        this.$rootScope.$broadcast("subscriptionConverted", message.data);
        break;
      case "entitiesDeletedParentEntitiesChanged":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("entitiesDeletedParentEntitiesChanged", message.data));
        break;
      case "conversationAnswerResponse":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("conversationAnswerResponse", message.data));
        break;
      case "dataIngestionExecution":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("dataIngestionExecution", message.data));
        break;
      case "methodologySettingsChanged":
        this.$rootScope.$apply(() => this.$rootScope.$broadcast("methodologySettingsChanged", message.data));
        break;
      default:
        handleUnknownMessage(message);
        break;
    }
  }
}
