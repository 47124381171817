import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { Observable, map, switchMap } from "rxjs";
import { localize } from "@gtmhub/localization";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { FavoritesModule } from "@webapp/favorites/favorites.module";
import { INavItem } from "@webapp/navigation/models/nav-items-list.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { TabBuilder } from "@webapp/shared/builders/tab.builder";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { Tab } from "@webapp/shared/models/tabset-builder.models";
import { UploadButtonComponent } from "@webapp/shared/upload-button/upload-button.component";
import { SubTitle } from "@webapp/top-nav-bar/models/top-nav-bar.models";
import { TopNavBarModule } from "@webapp/top-nav-bar/top-nav-bar.module";
import { DecisionTabStateService, DecisionTopNavBarTabKey } from "../decision-v2/decision-tab-state.service";

@UntilDestroy()
@Component({
  selector: "decision-top-nav-bar",
  templateUrl: "./decision-top-nav-bar.component.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TopNavBarModule, UploadButtonComponent, FavoritesModule, DropdownModule, UiButtonModule, UiIconModule, AsyncPipe],
})
export class DecisionTopNavBarComponent implements OnInit, OnDestroy {
  @Input() public title: string;
  @Input() public documentIds: string[] = [];
  @Input() public whiteboardId: string;

  @Output() public readonly tabChanged = new EventEmitter<DecisionTopNavBarTabKey>();
  @Output() public readonly uploadedDocumentIds = new EventEmitter<string[]>();

  @Output() public readonly deleteClicked = new EventEmitter<void>();

  public subTitle: SubTitle = { text: localize("whiteboards"), action: { state: "gtmhub.whiteboards" } };
  public topNavBarTabsConfig: Tab[] = [];

  public dropdownVisible: boolean;
  public initialActiveTabKey: DecisionTopNavBarTabKey;

  public menuItems: DropdownMenuItem[];

  private navItem: INavItem;

  constructor(
    private decisionTabStateService: DecisionTabStateService,
    private readonly navigationItemsMediator: NavigationItemsMediator,
    private readonly analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.setTopNavBarTabsConfig();
    this.setMenuItems();

    this.decisionTabStateService
      .getTabState$()
      .pipe(untilDestroyed(this))
      .subscribe((tabKey) => {
        this.initialActiveTabKey = tabKey;
      });

    this.navigationItemsMediator
      .loadFavorites(["whiteboards"])
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.navigationItemsMediator.getFavoritesItems$("whiteboards"))
      )
      .subscribe((favorites) => {
        this.navItem = favorites.items.find((favorite) => favorite.id === this.whiteboardId);
      });
  }

  public ngOnDestroy(): void {
    this.decisionTabStateService.updateTabState("whiteboard_cap");
  }

  public isFavorite$(): Observable<boolean> {
    return this.navigationItemsMediator.getFavoritesItems$("whiteboards").pipe(
      untilDestroyed(this),
      map((favorites) => {
        return favorites?.items.some((favorite) => favorite.id === this.whiteboardId);
      })
    );
  }

  public addToFavorites(): void {
    this.navItem = {
      id: this.whiteboardId,
      uxcType: "whiteboard",
      title: this.title,
    };

    this.navigationItemsMediator.markItemAsFavorite(this.whiteboardId, "whiteboard", this.navItem, "whiteboards");
    this.analyticsService.track("New Experience Add to Favorites", { id: this.whiteboardId, type: "whiteboard", source: "detail-view" });
  }

  public removeFromFavorites(): void {
    this.navigationItemsMediator.unmarkItemAsFavorite(this.navItem?.favoriteId, "whiteboard", this.navItem, "whiteboards");
    this.analyticsService.track("New Experience Remove from Favorites", { id: this.whiteboardId, type: "whiteboard", source: "detail-view" });
  }

  public dropdownToggled(value: boolean): void {
    this.dropdownVisible = value;
  }

  private setMenuItems(): void {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const deleteItem = menuItemBuilder
      .setKey("delete")
      .setAction({
        handler: () => this.deleteClicked.emit(),
      })
      .setToBeDestructive()
      .build();

    this.menuItems = [deleteItem];
  }

  private setTopNavBarTabsConfig(): void {
    const tabBuilder = new TabBuilder();

    const canvasTab = tabBuilder.setKey("decision_cap").build();

    const whiteboardTab = tabBuilder
      .setKey("whiteboard_cap")
      .setAction({ handler: () => this.decisionTabStateService.updateTabState("whiteboard_cap") })
      .build();

    this.topNavBarTabsConfig.push(canvasTab, whiteboardTab);
  }
}
