<nz-upload
  class="file-upload-input"
  [class.disabled]="nzDisabled"
  [nzAccept]="nzAccept"
  [nzBeforeUpload]="nzBeforeUpload"
  [nzLimit]="nzLimit"
  [nzMultiple]="nzMultiple"
  [nzSize]="nzSize"
  nzListType="text"
  nzType="drag">
  <div class="file-upload-content">
    <div class="upload-container">
      <i ui-icon uiSize="lg" uiTheme="outline" uiType="attachment"></i>
      <span class="title">{{ "select_files_or_drag_n_drop_to_upload" | localize }}</span>
      <span class="subtitle">{{ "upload_x_files_max_y_each" | localize: { maxFiles: nzLimit, maxFileSize: fileSize + "MB", fileTypes: nzAccept.toLocaleUpperCase() } }}</span>
    </div>
  </div>
</nz-upload>
