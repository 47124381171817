import { ApplicationRef, ComponentRef, EmbeddedViewRef, createComponent } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import { localize } from "@gtmhub/localization";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { UiBannerComponent } from "@webapp/ui/banner/banner.component";
import { ITopNavBarNotificationInstance, TopNavBarNotificationParams } from "./top-nav-bar-notification.service";

const TOP_NAV_BAR_TERMINOLOGY_UPDATE_NOTIFICATION_KEY = "terminology-update";

export class TerminologyUpdateNotificationInstance implements ITopNavBarNotificationInstance {
  private static instance: TerminologyUpdateNotificationInstance;
  private isVisible: boolean = false;
  private componentRef: ComponentRef<UiBannerComponent>;

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly broadcastService: BroadcastService
  ) {
    if (TerminologyUpdateNotificationInstance.instance) {
      return TerminologyUpdateNotificationInstance.instance;
    }

    TerminologyUpdateNotificationInstance.instance = this;
  }

  public key: string = TOP_NAV_BAR_TERMINOLOGY_UPDATE_NOTIFICATION_KEY;

  public registerNotification$(params: TopNavBarNotificationParams): Observable<void> {
    return new Observable((subscriber) => {
      const observable$ = this.isVisible
        ? this.createBannerComponentRef$(params)
        : this.broadcastService.on("methodologySettingsChanged").pipe(
            switchMap(() => {
              if (this.isVisible) {
                return of(null);
              }

              return this.createBannerComponentRef$(params);
            })
          );

      const subscription = observable$.subscribe();

      subscriber.add(subscription);
      subscriber.next(null);
    });
  }

  private attachToTopNavBarInsideDOM(params: TopNavBarNotificationParams): void {
    const topNavBarElement = params.topNavBarElementRef.nativeElement;
    topNavBarElement.parentNode.insertBefore((<EmbeddedViewRef<unknown>>this.componentRef.hostView).rootNodes[0], topNavBarElement.nextSibling);
  }

  public destroyNotification(): void {
    this.destroyComponent();
  }

  private destroyComponent(): void {
    this.componentRef.destroy();
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef = null;

    this.isVisible = false;
  }

  private createBannerComponentRef$(params: TopNavBarNotificationParams): Observable<void> {
    // 1. Create a component reference
    const bannerComponentRef = createComponent(UiBannerComponent, {
      environmentInjector: this.appRef.injector,
    });

    bannerComponentRef.instance.uiCloseable = true;
    bannerComponentRef.instance.uiMessage = localize("terminology_update");
    bannerComponentRef.instance.uiDescription = localize("your_admin_has_updated_the_terminology_in_use");

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(bannerComponentRef.hostView);

    // 3. Subscribe to the close event
    const ref = bannerComponentRef.instance.uiOnClose.subscribe(() => {
      this.destroyComponent();
      ref.unsubscribe();
    });

    this.componentRef = bannerComponentRef;
    this.isVisible = true;

    this.attachToTopNavBarInsideDOM(params);

    return of(null);
  }
}
