import { Injectable } from "@angular/core";
import { ActionableTextContextListener } from "../../models/strategy.vm-models";

@Injectable()
export class ActionableTextContextService {
  private listeners: ActionableTextContextListener[] = [];

  public addListener(listener: ActionableTextContextListener): void {
    this.listeners.push(listener);
  }

  public mouseUp(target: EventTarget): void {
    this.listeners.forEach((listener) => listener.onMouseUp(target));
  }

  public mouseDown(target: EventTarget): void {
    this.listeners.forEach((listener) => listener.onMouseDown(target));
  }

  public focusOut(target: EventTarget): void {
    this.listeners.forEach((listener) => listener.onFocusOut(target));
  }
}
