import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Intercom } from "@gtmhub/shared/intercom";
import { BET_LOADING_ERROR, STRATEGIC_MAP_GENERATION_ERROR, WHITEBOARD_GENERATION_ERROR } from "@webapp/strategy/models/strategy.constants";
import { ErrorPageComponent } from "../../../error-page/error-page.component";

@Component({
  selector: "evaluate-bet-errors",
  templateUrl: "./evaluate-bet-errors.component.html",
  styleUrls: ["./evaluate-bet-errors.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ErrorPageComponent],
})
export class EvaluateBetErrorsComponent {
  @Input() public error: string | null = BET_LOADING_ERROR;
  @Input() public whiteboardGenerationError: string | null = null;

  @Output() public readonly generateOKRs: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly stopOKRGeneration: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly stopStrategyGeneration: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly regenerateStrategy: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly loadBet: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly closePage: EventEmitter<void> = new EventEmitter<void>();

  public whiteboardGenerationErrorValue = WHITEBOARD_GENERATION_ERROR;
  public strategicMapGenertionErrorValue = STRATEGIC_MAP_GENERATION_ERROR;
  public betLoadingErrorValue = BET_LOADING_ERROR;

  public onContactSupportClick(): void {
    Intercom("show");
  }
}
