import { StateService } from "@uirouter/angular";
import { AsyncPipe, NgIf } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Injector, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, catchError, combineLatest, filter, firstValueFrom, forkJoin, map, of, tap, timer } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { UIErrorConfiguration } from "@gtmhub/error-handling/models";
import { Intercom } from "@gtmhub/shared/intercom";
import { StrategySettings } from "@gtmhub/users/models";
import { mapToString } from "@webapp/core/abstracts/decorators/route-param-to-input/route-param-to-input.adapters";
import { RouteParamInput } from "@webapp/core/abstracts/decorators/route-param-to-input/route-param-to-input.decorator";
import { catchHttpError } from "@webapp/core/rxjs-operators/catch-http-error.operator";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { REPORT_DUMMY_BLOCK_ID, VALIDATION_STATUS_COMPLETED, VALIDATION_STATUS_NOT_STARTED } from "@webapp/strategy/models/strategy.constants";
import { closeLastDrawer } from "@webapp/strategy/services/utility/drawer";
import { StrategiesTrackingService } from "@webapp/strategy/services/utility/strategies-tracking.service";
import { UiErrorHandlingService } from "@webapp/strategy/services/utility/ui-error-handling.service";
import { QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";
import { StrategySocketsService } from "@webapp/strategy/services/web-sockets/services/strategy-sockets.service";
import { UiDrawerService } from "@webapp/ui/drawer/services/drawer.service";
import { UiLoadingIndicatorComponent } from "@webapp/ui/loading-indicator/loading-indicator.component";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { CurrentUserRepository } from "@webapp/users";
import { User } from "@webapp/users/models/users.models";
import { UsersFacade } from "@webapp/users/services/users-facade.service";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import {
  OrderedStrategyResultEntryVM,
  StrategicBetVM,
  StrategicMapAreaVM,
  StrategicMapHypothesisVM,
  StrategyResultBlockTypeVM,
} from "../../models/bets/strategic-bets.vm-models";
import { StrategyChatQuestionVM, StrategyConversationChatEntryVM } from "../../models/chat/strategy-conversation-chat.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { StrategyReportFollowupActionDefinition } from "../../models/strategy.vm-models";
import { AsyncTasksService } from "../../services/async-tasks/async-tasks.service";
import { BetLockService } from "../../services/bet/bets-lock.service";
import { StrategicBetsService } from "../../services/bet/strategic-bets.service";
import { StrategyConversationChatService } from "../../services/chat/strategy-conversation-chat.service";
import { StrategyConversationViewContextService } from "../../services/conversation/strategy-conversation-view-context.service";
import { StrategyConversationMediatorService } from "../../services/conversation/strategy-conversation.mediator";
import { StrategyConversationService } from "../../services/conversation/strategy-conversation.service";
import { StrategyConversationReportService } from "../../services/report/strategy-conversation-report.service";
import { StrategyConversationExportService } from "../../services/utility/strategy-conversation-export.service";
import { chatEntryAiVM2VM, conversationVM2AiVM } from "../../services/utility/strategy-conversation-factory.utils";
import { ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData } from "../confirm-delete-strategy-item/confirm-delete-strategy-item.component";
import { ContextDrawerComponent } from "../context-drawer/context-drawer.component";
import { ErrorDetailsComponent } from "../error-details/error-details.component";
import { ErrorPageComponent } from "../error-page/error-page.component";
import { UiAiChatModule } from "../evaluate-bet-page/components/ai-chat/ai-chat.module";
import { ChatQuestionVM, ChatTextResultVM, ConversationChatEntryVM, ConversationVM } from "../evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { AlertMessageComponent } from "../evaluate-bet-page/components/alert-message/alert-message.component";
import { HINT_INDEX_MAP } from "../evaluate-bet-page/evaluate-bet-page.component";
import { PageWrapperComponent } from "../page-wrapper/page-wrapper.component";
import { ResultsHeaderComponent } from "../results-header/results-header.component";
import { ResultsSectionComponent } from "../results-section/results-section.component";
import { MessageBoxComponent } from "../shared/message-box/message-box.component";

@UntilDestroy()
@Component({
  selector: "generate-strategy-page",
  templateUrl: "./generate-strategy-page.component.html",
  styleUrls: ["./generate-strategy-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiLoadingIndicatorComponent,
    NgIf,
    PageWrapperComponent,
    ResultsHeaderComponent,
    ResultsSectionComponent,
    UiAiChatModule,
    MessageBoxComponent,
    AsyncPipe,
    AlertMessageComponent,
    ErrorPageComponent,
    ErrorDetailsComponent,
  ],
  providers: [StrategyConversationViewContextService, UiErrorHandlingService],
})
export class GenerateStrategyPageComponent implements OnInit {
  @Input() @RouteParamInput<"betId", string>({ adapter: mapToString }) public betId: string;
  @Input() @RouteParamInput<"areaId", string>({ adapter: mapToString }) public areaId: string;
  @Input() @RouteParamInput<"hypothesisId", string>({ adapter: mapToString }) public hypothesisId: string;

  @ViewChild("hypothesisDeletionError")
  private hypothesisDeletionError: TemplateRef<object>;

  public reportConversation$: Observable<StrategyConversationVM>;
  public aiChatConversation$: Observable<ConversationVM>;
  public chatVisible = true;
  public initialLoadingIsOngoing = true;
  public isInErrorState = false;
  public currentUser: User | undefined;
  public usersMap: Record<string, User> = {};
  public editingReport: boolean;
  private conversation: StrategyConversationVM;
  public showAlert = false;
  public areaNotAvailableForUser = false;

  private strategySettings: StrategySettings;
  public verified: boolean;
  public conversationObject: StrategyConversationVM;
  public strategyActionsMode: {
    exportOKRs: boolean;
    close: boolean;
  } = {
    exportOKRs: false,
    close: true,
  };
  public uiError: UIErrorConfiguration;

  public hypothesis: StrategicMapHypothesisVM;
  public area: StrategicMapAreaVM;
  public contextId: string;

  constructor(
    public injector: Injector,
    private conversationService: StrategyConversationService,
    private chatService: StrategyConversationChatService,
    private reportService: StrategyConversationReportService,
    private exportService: StrategyConversationExportService,
    private cdr: ChangeDetectorRef,
    private drawerService: UiDrawerService,
    private strtegyViewContextService: StrategyConversationViewContextService,
    private usersFacade: UsersFacade,
    private asyncTasks: AsyncTasksService,
    private strategySocketsService: StrategySocketsService,
    private betLockService: BetLockService,
    private apmService: ApmService,
    private conversationMediatorService: StrategyConversationMediatorService,
    private currentUserRepository: CurrentUserRepository,
    private strategicBetsService: StrategicBetsService,
    private modalService: UiModalService,
    private errorHandlingService: UiErrorHandlingService,
    private stateService: StateService,
    private strategiesTrackingService: StrategiesTrackingService
  ) {}

  public ngOnInit(): void {
    this.loadBet$()
      .pipe(
        takeOneUntilDestroyed(this),
        tap((bet) => {
          const currentUserId: string = storage.get("userId");
          const principalIds = bet.access.permissions.map<string>((p) => p.principalId).filter(Boolean);
          const userIds: string[] = [...new Set([...principalIds, currentUserId])];

          this.fetchUsersData(userIds);
        })
      )
      .subscribe(() => {
        this.betLockService.initConnection(this.betId);

        this.loadConversation();
        this.setupSockets();
        this.markHypothesisAsSeen();

        this.strategySettings = this.currentUserRepository.getUserSetting("strategySettings");
        this.showAlert = !this.strategySettings.reportGenerationHintSeen;
        this.cdr.markForCheck();
      });
  }

  @HostListener("window:unload", ["$event"])
  public beforeUnloadHandler(): void {
    if (this.hypothesisId) {
      this.betLockService.unlockHypothesis(this.hypothesisId);
    }
  }

  public setupSockets(): void {
    this.strategySocketsService
      .onMessage$("askQuestion")
      .pipe(
        untilDestroyed(this),
        filter((message) => message.data.itemId === this.hypothesis.conversationId)
      )
      .subscribe(() => {
        this.markHypothesisAsSeen();
      });

    this.strategySocketsService
      .onMessage$("askQuestion")
      .pipe(filter((message) => message.data.itemId === this.hypothesis.conversationId))
      .subscribe((message: QuantiveResultsSocketVM<"askQuestion">) => {
        if (message.data.status === "SUCCESS") {
          this.chatService.registerRegenerateLastChatEntryResponse(this.hypothesis.conversationId, message.data.additionalData.response);
        } else if (message.data.status === "FAILURE") {
          this.chatService.registerChatEntryResponse(this.hypothesis.conversationId, null);
        }
      });

    this.strategySocketsService
      .onMessage$("regenerateChatAnswer")
      .pipe(filter((message) => message.data.itemId === this.hypothesis.conversationId))
      .subscribe((message) => {
        if (message.data.status === "SUCCESS") {
          this.chatService.registerRegenerateLastChatEntryResponse(this.hypothesis.conversationId, message.data.additionalData.chatEntry);
        } else if (message.data.status === "FAILURE") {
          this.chatService.registerRegenerateLastChatEntryResponse(this.hypothesis.conversationId, null);
        }
      });

    this.strategySocketsService
      .onMessage$("areaRemoved")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        if (message.data.additionalData.areaId === this.area.id && message.data.userId !== this.currentUser.id) {
          this.areaNotAvailableForUser = true;
          this.cdr.detectChanges();
        }
      });
  }

  public askQuestion(question: ChatQuestionVM): void {
    this.conversationMediatorService
      .setConversationToLoading$(this.betId, this.area.id, {
        id: this.hypothesisId,
        conversationId: this.hypothesis.conversationId,
      })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.onHypothesisIsSeenChange();
        this.chatService.askQuestion(this.conversation.id, question as unknown as StrategyChatQuestionVM, null);
      });
  }

  public toggleChat(): void {
    this.chatVisible = !this.chatVisible;
    Intercom("update", { horizontal_padding: this.chatVisible ? 520 : 20 });
  }

  public showCollectiveContext(): void {
    if (this.contextId) {
      this.drawerService.create({
        uiContent: ContextDrawerComponent,
        uiSize: "large",
        uiWidth: "850px",
        uiHeight: "100%",
        uiContentParams: {
          contextId: this.contextId,
          userSettings: this.strategySettings,
          hideAlert: () => this.onHideAlert(),
          closeForm: () => {
            closeLastDrawer(this.drawerService);
          },
        },
      });
    }
  }

  public executeFollowupAction(followupAction: StrategyReportFollowupActionDefinition): void {
    this.reportService.executeFollowupAction(this.conversation, followupAction);
  }

  public editResultSectionContent(resultBlock: OrderedStrategyResultEntryVM): void {
    if (resultBlock.id !== REPORT_DUMMY_BLOCK_ID) {
      this.editingReport = true;
      this.reportService
        .editResultSectionContent(this.conversation, resultBlock)
        .pipe(takeOneUntilDestroyed(this))
        .subscribe(() => {
          this.editingReport = this.reportService.reportIsBeingEdited();
          this.cdr.markForCheck();
        });
    } else {
      this.editingReport = true;
      this.reportService
        .addResultFromADummyBlock(this.conversation, resultBlock)
        .pipe(takeOneUntilDestroyed(this))
        .subscribe(() => {
          this.cdr.markForCheck();
          this.editingReport = this.reportService.reportIsBeingEdited();
        });
    }

    this.cdr.markForCheck();
  }

  public moveResultBlockDown(resultBlock: OrderedStrategyResultEntryVM): void {
    this.editingReport = true;
    this.reportService
      .moveResultBlockDown(this.conversation, resultBlock)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.editingReport = this.reportService.reportIsBeingEdited();
        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  public moveResultBlockUp(resultBlock: OrderedStrategyResultEntryVM): void {
    this.editingReport = true;
    this.reportService
      .moveResultBlockUp(this.conversation, resultBlock)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.editingReport = this.reportService.reportIsBeingEdited();
        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  public deleteResultBlock(resultBlock: OrderedStrategyResultEntryVM): void {
    this.editingReport = true;
    this.reportService
      .deleteResultBlock(this.conversation, resultBlock)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.editingReport = this.reportService.reportIsBeingEdited();
        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  public regenerateLastChatEntry(): void {
    this.chatService.regenerateLastChatEntry(this.conversation.id);
  }

  public retryErroredMessage(): void {
    this.chatService.retryErroredMessage(this.conversation.id);
  }

  public provideFeedbackForMessage(params: { message: ConversationChatEntryVM; type: "positive" | "negative" }): void {
    this.chatService.provideFeedbackForMessage(this.conversation.id, params.message as unknown as StrategyConversationChatEntryVM, params.type);
  }

  public exportReport(): void {
    const element = document.getElementById("content-for-export");
    if (element) {
      const elementToExport = element.cloneNode(true) as HTMLElement;
      const dummyElement = elementToExport.querySelector(".free-text .placeholder");
      dummyElement?.remove();

      this.exportService.exportReport(elementToExport, this.contextId);
    }
  }

  private loadConversation(timerValue: number = 0): void {
    this.apmService.startDataLoadSpan("load conversation async tasks");

    this.initialLoadingIsOngoing = true;

    const conversationObservable = this.conversationObject
      ? of(this.conversationObject)
      : this.conversationService.getConversation$(this.hypothesis.conversationId).pipe(filter((conversation) => conversation !== undefined));

    conversationObservable.pipe(untilDestroyed(this)).subscribe((conversation) => {
      this.verified = conversation?.results.length > 0;
    });

    combineLatest([
      timer(timerValue),
      conversationObservable,
      this.asyncTasks.getTasksForAccountAndItemId$(this.hypothesis.conversationId, ["ask_question", "regenerate_chat_answer"]),
    ])
      .pipe(
        catchError(() => {
          this.initialLoadingIsOngoing = false;
          return of([0, null]);
        })
      )
      .pipe(takeOneUntilDestroyed(this))
      .subscribe((response) => {
        const conversation = response[1] as StrategyConversationVM;
        const asyncTasks = response[2] as AsyncTaskVM[];

        if (!conversation) {
          this.isInErrorState = true;
          this.initialLoadingIsOngoing = false;
          this.cdr.markForCheck();
          return;
        }

        if (this.conversationObject) {
          this.reportConversation$ = of(this.conversationObject);
          this.aiChatConversation$ = of(conversationVM2AiVM(this.conversationObject));
        } else {
          this.reportConversation$ = this.reportService.getConversationForReport$(conversation.id);
          this.aiChatConversation$ = this.chatService.getConversationForAiChat$(conversation.id);
          this.updateAiConversationBasedOnTasks(asyncTasks, conversation.id);
        }

        this.aiChatConversation$.pipe(untilDestroyed(this)).subscribe((conversation) => {
          if (conversation.currentlyAnsweringQuestion) {
            this.strtegyViewContextService.converstionStartedAnsweringQuestion();
          } else {
            this.strtegyViewContextService.converstionStoppedAnsweringQuestion();
          }
        });
        this.apmService.endDataLoadSpan("load conversation async tasks");
        this.apmService.startDataLoadSpan("load conversation");
        forkJoin([
          this.reportConversation$.pipe(
            filter((reportConversation) => reportConversation !== null),
            takeOneUntilDestroyed(this)
          ),
          this.aiChatConversation$.pipe(
            filter((chatConversation) => chatConversation !== null),
            takeOneUntilDestroyed(this)
          ),
        ])
          .pipe(takeOneUntilDestroyed(this))
          .subscribe(([reportConversation]) => {
            this.conversation = reportConversation;
            conversation.contextId = this.contextId;
            if (conversation !== null) {
              this.initialLoadingIsOngoing = false;
            }
            this.apmService.endDataLoadSpan("load conversation");
            this.cdr.markForCheck();
          });

        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  private updateAiConversationBasedOnTasks(tasks: AsyncTaskVM[], conversationId: string): void {
    this.aiChatConversation$.pipe(takeOneUntilDestroyed(this)).subscribe(() => {
      const inProgressAskQuestionTasks = tasks.filter(
        (task) => task.name === "ask_question" && task.itemId === conversationId && ["PENDING", "STARTED"].includes(task.status)
      );
      const inProgressRegenerateChatAnswerTasks = tasks.filter((task) => task.name === "regenerate_chat_answer" && ["PENDING", "STARTED"].includes(task.status));

      if (inProgressAskQuestionTasks.length > 0) {
        const currentTask = tasks.find((task) => task.name === "ask_question" && task.itemId === conversationId && ["PENDING", "STARTED"].includes(task.status));
        const { additionalData } = currentTask;
        const type = (additionalData.followup_action_details as Record<string, unknown>)?.type as StrategyResultBlockTypeVM;
        const chatRequest = additionalData?.chatRequest as { question: string };

        this.chatService.addLoadingChatEntry(
          conversationId,
          {
            question: chatRequest?.question,
            type: type,
          },
          undefined,
          "",
          currentTask?.startedAt
        );
      } else if (inProgressRegenerateChatAnswerTasks.length > 0) {
        this.chatService.regenerateLastChatEntryVisuals(conversationId);
      }
    });
  }

  public onRetryClick(): void {
    this.loadConversation();
  }

  public addContent(chatEntry: ConversationChatEntryVM): void {
    this.editingReport = true;
    this.chatService.updateChatEntryStatus(this.hypothesis.conversationId, chatEntry, {
      inserting: true,
      error: false,
    });

    this.strategiesTrackingService.trackFreeTextInserted(this.conversation.id, chatEntry.followupActionDetails.type, {
      partial: (chatEntry.blockContent as ChatTextResultVM).partial,
    });

    this.cdr.markForCheck();
    this.reportService
      .addResultFromChat(this.conversation, chatEntryAiVM2VM(chatEntry))
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (response: OrderedStrategyResultEntryVM[] | null) => {
          if (response === null) {
            this.chatService.updateChatEntryStatus(this.hypothesis.conversationId, chatEntry, {
              error: true,
            });
          }
          this.editingReport = this.reportService.reportIsBeingEdited();
        },
        complete: () => {
          this.chatService.updateChatEntryStatus(this.hypothesis.conversationId, chatEntry, {
            inserting: false,
          });
          this.cdr.markForCheck();
        },
      });
  }

  public insertTextBlockAtIndex(index: number): void {
    this.editingReport = true;
    this.reportService
      .insertEmptyTextBlockAtIndex(this.conversation, index)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.editingReport = this.reportService.reportIsBeingEdited();
        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  public onContactSupportClick(): void {
    Intercom("show");
  }

  private fetchUsersData(userIds: string[]): void {
    this.usersFacade
      .getUsersV2$({
        filter: { _id: { $in: userIds } },
        fields: ["firstName", "lastName", "picture", "language"],
      })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (currentUserData) => {
          this.currentUser = currentUserData.items.find((u) => u.id === storage.get("userId"));

          currentUserData.items.forEach((user) => {
            this.usersMap[user.id] = user;
          });

          this.cdr.detectChanges();
        },
      });
  }

  public markHypothesisAsSeen(): void {
    if (this.hypothesis.isSeen) {
      return;
    }

    this.conversationMediatorService.markHypothesisAsSeen(this.betId, this.area.id, {
      id: this.hypothesisId,
      conversationId: this.hypothesis.conversationId,
    });
  }

  public hideAlertInternal(): void {
    this.strategySettings = {
      ...this.strategySettings,
      reportGenerationHintSeen: true,
    };

    this.currentUserRepository.setUserSetting({ strategySettings: this.strategySettings });

    this.showAlert = false;
    this.cdr.markForCheck();
  }

  public onCloseAnalysis(): void {
    this.conversationService
      .getConversation$(this.hypothesis.conversationId)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe((conversation: StrategyConversationVM) => {
        if (conversation.results.length === 0) {
          // this.flashingElements[this.area.id] =
          //   this.area.status !== VALIDATION_STATUS_NOT_STARTED;
          this.hypothesis.status = VALIDATION_STATUS_NOT_STARTED;
          this.area.status = VALIDATION_STATUS_NOT_STARTED;
        } else {
          this.hypothesis.status = VALIDATION_STATUS_COMPLETED;
          const notCompletedHypotheses = this.area.hypotheses.filter((h) => h.status !== VALIDATION_STATUS_COMPLETED);

          if (notCompletedHypotheses.length === 0) {
            // this.flashingElements[this.area.id] =
            //   this.area.status !== VALIDATION_STATUS_COMPLETED;
            this.area.status = VALIDATION_STATUS_COMPLETED;
          } else {
            // this.flashingElements[this.area.id] =
            //   this.area.status !== VALIDATION_STATUS_NOT_STARTED;
            this.area.status = VALIDATION_STATUS_NOT_STARTED;
          }
        }
      });

    this.onClosePage();
  }

  public onHypothesisDelete(): void {
    this.modalService.confirm<ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData>(
      {
        uiTitle: "Delete hypothesis?",
        uiContent: ConfirmDeleteStrategyItemComponent,
        uiData: {
          strategyItemName: this.hypothesis.title,
          strategyItemType: "hypothesis",
        },
        uiOkText: "Delete hypothesis",
        uiOkLoadingText: "Deleting hypothesis...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          this.cdr.markForCheck();

          return firstValueFrom(
            this.strategicBetsService.deleteHypothesis$(this.betId, this.area.id, this.hypothesis.id).pipe(
              catchHttpError((error: HttpErrorResponse) => {
                this.uiError = this.errorHandlingService.getUIErrorData(error, "delete", "hypothesis");
                throw this.hypothesisDeletionError;
              })
            )
          ).then(() => {
            this.area.hypotheses = this.area.hypotheses.filter((h) => h.id !== this.hypothesis.id);

            if (this.hypothesis.status === VALIDATION_STATUS_NOT_STARTED && this.area.hypotheses.every((h) => h.status === VALIDATION_STATUS_COMPLETED)) {
              this.area.status = VALIDATION_STATUS_COMPLETED;
            }

            this.onClosePage();
          });
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
  }

  public onClosePage(): void {
    this.betLockService.unlockHypothesis(this.hypothesis.id);
    this.stateService.go("gtmhub.decision", { betId: this.betId });
  }

  public onHideAlert(): void {
    this.strategySettings = {
      ...this.strategySettings,
      [HINT_INDEX_MAP[0]]: true,
    };

    this.currentUserRepository.setUserSetting({ strategySettings: this.strategySettings });
    this.cdr.markForCheck();
  }

  public onHypothesisIsSeenChange(): void {
    this.hypothesis.isSeen = false;
  }

  private loadBet$(): Observable<StrategicBetVM> {
    return this.strategicBetsService.getBet$(this.betId).pipe(
      takeOneUntilDestroyed(this),
      map((bet) => {
        this.contextId = bet.contextId;
        this.area = bet.strategicMap.areas.find((a) => a.id === this.areaId);
        this.hypothesis = this.area.hypotheses.find((h) => h.id === this.hypothesisId);

        return bet;
      })
    );
  }
}
