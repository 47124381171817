import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "widgets" */ "./widgets.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const widgetsFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.widgets",
    abstract: true,
    url: "/widgets",
    lazyLoad,
  },
  {
    name: "gtmhub.widgets.list",
    url: "/list",
    lazyLoad,
  },
  {
    name: "gtmhub.widgets.widget",
    url: "/:widgetId",
    lazyLoad,
  },
];

export const states: Ng2StateDeclaration[] = [...widgetsFutureStates];
