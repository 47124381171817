<ui-chat-block-ai
  class="chat-block"
  *ngIf="message.type === 'ai'"
  [message]="message"
  [showRegenerate]="showRegenerate"
  (addContent)="addContent.emit($event)"
  (provideFeedback)="provideFeedback.emit($event)"
  (refreshContent)="refreshContent.emit()"
  (retryErroredMessage)="retryErroredMessage.emit()" />
<ui-chat-block-human class="chat-block" *ngIf="message.type === 'human'" [message]="message" [user]="user" />
<article class="mock-container">
  <section class="chat-block-mock no-background" *ngIf="message.type === 'mock'">
    <section class="ai-icon">
      <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="artificial-intelligence"></i>
    </section>
    <section class="content">We will help you analyse this hypothesis</section>
  </section>
  <section class="chat-block-mock" *ngIf="message.type === 'mock'">
    <section class="title">
      <section class="ai-icon">
        <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="artificial-intelligence"></i>
      </section>
      <section class="content">Given the information you've provided I suggest you continue your analysis with one of the following frameworks:</section>
    </section>
    <section class="actions">
      <button *ngFor="let framework of getMessageSuggestedFrameworksContent()" (click)="executeFramework.emit(framework)" ui-button uiShape="round" uiSize="default" uiType="default">
        <span>{{ getFrameworkLabel(framework) }}</span>
      </button>
    </section>
  </section>
</article>
