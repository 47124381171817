<section class="conversation-results" (focusout)="handleFocusOut($event)" (mousedown)="handleMouseDown($event)" (mouseup)="handleMouseUp($event)">
  <result-block
    class="result-block"
    *ngFor="let result of conversation?.results; let index = index"
    [isFirst]="index === 0 && result.id !== 'dummy'"
    [isLast]="index === conversation.results.length - 2"
    [result]="result"
    (deleteResult)="deleteResult.emit($event)"
    (editContent)="editContent.emit($event)"
    (executeFollowupAction)="followup(result, $event)"
    (focusOutWithChanges)="focusOutWithChanges($event)"
    (insertAbove)="addNewTextBlock(index)"
    (insertBelow)="addNewTextBlock(index + 1)"
    (moveDown)="moveDown.emit($event)"
    (moveUp)="moveUp.emit($event)"></result-block>
  <div #resultsEnd></div>
</section>
