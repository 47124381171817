import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  PIKeyResultSuggestion,
  PIKeyResultSuggestionPayload,
  PIObjectiveDescriptionSuggestion,
  PIObjectiveDescriptionSuggestionPayload,
  PIObjectiveSuggestion,
  PIObjectiveSuggestionPayload,
  StrategyObjectiveSuggestionPayload,
} from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { IStrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.interface";
import { StrategyStrategicGuidedOkrRepositoryService } from "@webapp/strategy/services/strategic-guided-okr/strategic-guided-okr-repository.service";
import { StrategicGuidedOkrFacadeV1 } from "./v1/strategic-guided-okr-facade-v1.service";

@Injectable({
  providedIn: "any",
})
export class StrategicGuidedOkrFacade implements IStrategicGuidedOkrFacade {
  constructor(
    private strategicGuidedOkrFacadeV1: StrategicGuidedOkrFacadeV1,
    private strategyStrategicGuidedOkrRepositoryService: StrategyStrategicGuidedOkrRepositoryService
  ) {}

  public getObjectiveDescriptionSuggestion(payload: PIObjectiveDescriptionSuggestionPayload): Observable<PIObjectiveDescriptionSuggestion> {
    return this.strategicGuidedOkrFacadeV1.getObjectiveDescriptionSuggestion(payload);
  }

  public getObjectiveSuggestions(payload: PIObjectiveSuggestionPayload): Observable<PIObjectiveSuggestion> {
    return this.strategyStrategicGuidedOkrRepositoryService.getObjectiveSuggestions(payload as unknown as StrategyObjectiveSuggestionPayload);
  }

  public getKeyResultSuggestions(payload: PIKeyResultSuggestionPayload): Observable<PIKeyResultSuggestion> {
    return this.strategyStrategicGuidedOkrRepositoryService.getKeyResultSuggestions(payload);
  }
}
