import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { IStrategyConversationChatApi } from "./strategy-conversation-chat-api.interface";
import { StrategyConversationChatApiV2Service } from "./v2/strategy-conversation-chat-api-v2.service";

@Injectable()
export class StrategyConversationChatApiFactory {
  constructor(private injector: Injector) {}

  public getApi(): Observable<IStrategyConversationChatApi> {
    return of(this.injector.get(StrategyConversationChatApiV2Service));
  }
}
