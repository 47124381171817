import { Injectable } from "@angular/core";
import { Observable, forkJoin, map, switchMap, take } from "rxjs";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { ICollection } from "@webapp/core/core.models";
import { ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { CustomFieldsFacade } from "@webapp/custom-fields/services/custom-fields-facade.service";
import { sanitizeRestLayerFilter, toQuickMongoRestLayerFilterWithQuickSearch } from "@webapp/filters/utils/rest-layer/rest-layer-utils";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { enrichCustomFieldNames } from "@webapp/okrs/okrs-grid-page/shared/utils/utils";
import { convertOrGroupToExtendedFilterGroup } from "@webapp/okrs/okrs-grid-page/utils";
import { ShareableFilter } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { ShareableFiltersFacade } from "@webapp/shared/unified-filters/services/shareable-filters/shareable-filters-facade.service";
import { GoalOverviewItem, OkrsSessionGroup } from "../assignee-active-okrs-list.models";

function transformGoalCollectionsToOkrsSessionGroup(filter: ICollection<Goal>, id: string, title?: string): OkrsSessionGroup {
  return {
    id,
    title: title || id,
    items: filter.items.map(
      (goal) =>
        ({
          id: goal.id,
          attainment: goal.attainment,
          attainmentDelta: goal.attainmentDelta,
          name: goal.name,
          ownerIds: goal.ownerIds,
          sessionId: goal.sessionId,
          sessionName: "",
          metrics: goal.metrics,
        }) as unknown as GoalOverviewItem
    ),
  };
}

function buildGoalsRequestsPerOkrView(filters: ShareableFilter[], goalsFacade: GoalsFacade, customFields?: ICustomField[]): Observable<ICollection<Goal>>[] {
  return filters.map((filter) => {
    const viewFilters = convertOrGroupToExtendedFilterGroup(filter.filterGroups);
    const mongoQuery = sanitizeRestLayerFilter(toQuickMongoRestLayerFilterWithQuickSearch(viewFilters, customFields));
    const paging: RequestPaging = {
      filter: mongoQuery,
      fields: ["id", "attainment", "attainmentDelta", "name", "ownerIds", "sessionId", "metrics{id,name,attainment,ownerIds,attainmentDelta,confidence}", "customFields"],
    };

    return goalsFacade.getGoalsV2$(paging);
  });
}

@Injectable()
export class ShareableFiltersResolverService {
  private viewIdName: Record<string, string> = {};

  constructor(
    private shareableFiltersFacade: ShareableFiltersFacade,
    private goalsFacade: GoalsFacade,
    private customFieldsFacade: CustomFieldsFacade
  ) {}

  private getCustomFields(): Observable<ICustomField[]> {
    return this.customFieldsFacade.getCustomFieldsByTargetType$("goal", "metric", "team", "user").pipe(
      take(1),
      map((response) => {
        return enrichCustomFieldNames([...(response.map.goal ?? []), ...(response.map.metric ?? []), ...(response.map.team ?? []), ...(response.map.user ?? [])]) || [];
      })
    );
  }

  public getSectionsFromOkrViewIds(ids: string[]): Observable<OkrsSessionGroup[]> {
    return this.getCustomFields().pipe(
      switchMap((customFields) => {
        return this.shareableFiltersFacade.getShareableFilters$({ filter: { _id: { $in: ids } } }).pipe(
          take(1),
          switchMap((response) => {
            this.viewIdName = response.items.reduce((map, filter) => ({ ...map, [filter.id]: filter.name }), {});
            const goalsRequests = buildGoalsRequestsPerOkrView(response.items, this.goalsFacade, customFields);
            return forkJoin(goalsRequests);
          }),
          map((goalCollections) => goalCollections.map((col, i) => transformGoalCollectionsToOkrsSessionGroup(col, ids[i], this.viewIdName[ids[i]])))
        );
      })
    );
  }
}
