import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { getNames } from "country-list";
import { EMPTY, Observable, catchError, finalize, switchMap, take } from "rxjs";
import { IIndicatorMap } from "@gtmhub/error-handling";
import { getCurrentUserId } from "@gtmhub/users";
import { Account } from "@webapp/accounts/models/accounts.models";
import { CurrentAccountRepository } from "@webapp/accounts/services/current-account-repository.service";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { HubSpotService } from "@webapp/hubspot/hubspot.service";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";

@Component({
  selector: "account-marketing-info-form",
  templateUrl: "./account-marketing-info-form.component.html",
  styleUrls: ["./account-marketing-info-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccountMarketingInfoFormComponent implements OnInit {
  @Output() public readonly accountUpdated: EventEmitter<Account> = new EventEmitter<Account>();

  public form: FormGroup;
  public indicators: IIndicatorMap = {
    submittingForm: null,
  };
  public formErrors: {
    inlineCompanyNameError?: string;
    upperFormError?: string;
  } = {};
  public companySizes = [
    "1-49 employees",
    "50-99 employees",
    "100-250 employees",
    "251-499 employees",
    "500-1000 employees",
    "1,001-9,999 employees",
    "10,000+ employees",
  ];
  public countries = getNames().sort();
  public jobFunctions = ["Executive", "C-level", "VP", "Director", "Manager", "HR manager", "Team leader", "Team member"];

  constructor(
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private currentAccountRepository: CurrentAccountRepository,
    private analyticsService: AnalyticsService,
    private profileService: UserProfileService,
    private hubSpotService: HubSpotService
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      companyName: new FormControl("", [Validators.required]),
      companySize: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
    });
  }

  public onFormSubmit(): void {
    if (this.form.invalid) {
      return this.form.markAllAsTouched();
    }

    this.indicators.submittingForm = { progress: true };
    this.formErrors = {};

    const patchAccountPayload = {
      accountName: this.form.controls["companyName"].value,
      accountMarketingInfo: {
        companySize: this.form.controls["companySize"].value,
        country: this.form.controls["country"].value,
      },
    };
    this.currentAccountRepository
      .update$(patchAccountPayload, { shouldTryGeneratingDomainFromAccountName: false })
      .pipe(
        catchError((error) => this.handleAccountUpdateError(error)),
        switchMap(() => this.currentAccountRepository.get$()),
        take(1)
      )
      .subscribe((account) => {
        const trackData = {
          company_name: patchAccountPayload.accountName,
          company_size: patchAccountPayload.accountMarketingInfo.companySize,
          country: patchAccountPayload.accountMarketingInfo.country,
          user_id: getCurrentUserId(),
        };

        this.analyticsService.track("sign-up-form-completed", trackData);

        const email = this.profileService.getProfile()?.email;
        if (email) {
          this.hubSpotService
            .postForm({
              name: "marketingInfo",
              fields: {
                company: trackData.company_name,
                country: trackData.country,
                numemployees: trackData.company_size,
                email,
              },
            })
            .pipe(
              take(1),
              finalize(() => this.accountUpdated.emit(account))
            )
            .subscribe();
        } else {
          this.accountUpdated.emit(account);
        }
      });
  }

  get companyName(): AbstractControl {
    return this.form.get("companyName");
  }

  public onCompanyNameValueChange(value: string): void {
    if (this.formErrors.inlineCompanyNameError) delete this.formErrors.inlineCompanyNameError;

    this.form.patchValue({
      companyName: value,
    });

    this.cdRef.detectChanges();
  }

  private handleAccountUpdateError(error: { status: number }): Observable<never> {
    this.formErrors = {};
    switch (error.status) {
      case 400:
        this.formErrors.inlineCompanyNameError = "This name is already taken.";
        break;
      case 500:
      default:
        this.formErrors.upperFormError = "There is a problem submitting the form, please try again in a moment, or contact support.";
        break;
    }

    delete this.indicators.submittingForm;

    this.cdRef.detectChanges();
    return EMPTY;
  }
}
