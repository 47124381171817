import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import {
  AnsoffDataVM,
  BalancedScoreCardDataVM,
  BlueOceanDataVM,
  GoalsDataVM,
  KPIsDataVM,
  OrderedStrategyResultEntryVM,
  PestDataVM,
  PorterForcesDataVM,
  StructuredDataAspectVM,
  SwotDataVM,
  TextStrategyResultVM,
  TitleAndDescriptionDataVM,
} from "../../models/bets/strategic-bets.vm-models";
import { REPORT_DUMMY_BLOCK_ID } from "../../models/strategy.constants";
import { FollowupActionDefinition } from "../../models/strategy.vm-models";
import { ActionableTextBlockComponent } from "../actionable-text-block/actionable-text-block.component";
import { AnsoffResultBlockComponent } from "./components/ansoff-result-block/ansoff-result-block.component";
import { BalancedScorecardResultBlockComponent } from "./components/balanced-scorecard-result-block/balanced-scorecard-result-block.component";
import { BlueOceanResultBlockComponent } from "./components/blue-ocean-result-block/blue-ocean-result-block.component";
import { GoalsResultBlockComponent } from "./components/goals-result-block/goals-result-block.component";
import { KpisResultBlockComponent } from "./components/kpis-result-block/kpis-result-block.component";
import { PestResultBlockComponent } from "./components/pest-result-block/pest-result-block.component";
import { PorterResultBlockComponent } from "./components/porter-result-block/porter-result-block.component";
import { SwotResultBlockComponent } from "./components/swot-result-block/swot-result-block.component";
import { TitleDescriptionResultBlockComponent } from "./components/title-description-result-block/title-description-result-block.component";

@Component({
  selector: "result-block",
  templateUrl: "./result-block.component.html",
  styleUrls: ["./result-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    SwotResultBlockComponent,
    BalancedScorecardResultBlockComponent,
    PorterResultBlockComponent,
    PestResultBlockComponent,
    AnsoffResultBlockComponent,
    BlueOceanResultBlockComponent,
    ActionableTextBlockComponent,
    TitleDescriptionResultBlockComponent,
    DropdownModule,
    GoalsResultBlockComponent,
    KpisResultBlockComponent,
  ],
})
export class ResultBlockComponent implements OnInit, OnChanges {
  @Input() public result: OrderedStrategyResultEntryVM;
  @Input() public isFirst: boolean;
  @Input() public isLast: boolean;
  @Input() public isDummy: boolean;
  @Output()
  public readonly executeFollowupAction: EventEmitter<FollowupActionDefinition> = new EventEmitter<FollowupActionDefinition>();
  @Output()
  public readonly editContent: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly moveUp: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly moveDown: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly deleteResult: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly focusOutWithChanges: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly insertAbove: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public readonly insertBelow: EventEmitter<void> = new EventEmitter<void>();

  public swotBlockContent: SwotDataVM;
  public ansoffBlockContent: AnsoffDataVM;
  public pestBlockContent: PestDataVM;
  public porterBlockContent: PorterForcesDataVM;
  public blueOceanBlockContent: BlueOceanDataVM;
  public goalsBlockContent: GoalsDataVM;
  public kpisBlockContent: KPIsDataVM;
  public balancedScorecardBlockContent: BalancedScoreCardDataVM;
  public textBlockContent: TextStrategyResultVM;
  public titleDescriptionBlockContent: TitleAndDescriptionDataVM;
  public resultBlockItems: DropdownMenuItem[] = [];
  public isNewBlock = false;
  public dummyResultBlockId = REPORT_DUMMY_BLOCK_ID;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.result.blockType === "swot") {
      this.swotBlockContent = this.result.blockContent as SwotDataVM;
    }
    if (this.result.blockType === "free_text") {
      this.textBlockContent = this.result.blockContent as TextStrategyResultVM;
    }
    if (this.result.blockType === "ansoff") {
      this.ansoffBlockContent = this.result.blockContent as AnsoffDataVM;
    }
    if (this.result.blockType === "pest") {
      this.pestBlockContent = this.result.blockContent as PestDataVM;
    }
    if (this.result.blockType === "porter") {
      this.porterBlockContent = this.result.blockContent as PorterForcesDataVM;
    }
    if (this.result.blockType === "blue_ocean") {
      this.blueOceanBlockContent = this.result.blockContent as BlueOceanDataVM;
    }
    if (this.result.blockType === "goals") {
      this.goalsBlockContent = this.result.blockContent as GoalsDataVM;
    }
    if (this.result.blockType === "kpis") {
      this.kpisBlockContent = this.result.blockContent as KPIsDataVM;
    }
    if (this.result.blockType === "balanced_scorecard") {
      this.balancedScorecardBlockContent = this.result.blockContent as BalancedScoreCardDataVM;
    }
    if (this.result.blockType === "summary_text") {
      this.titleDescriptionBlockContent = this.result.blockContent as TitleAndDescriptionDataVM;
    }

    this.resultBlockItems = this.getBoardNodeDataDropdownItems();
    this.isNewBlock = true;
    setTimeout(() => {
      this.isNewBlock = false;
      this.cdr.detectChanges();
    }, 2000);
    this.cdr.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["isFirst"] || changes["isLast"]) {
      this.resultBlockItems = this.getBoardNodeDataDropdownItems();
    }
    this.cdr.markForCheck();
  }

  private getBoardNodeDataDropdownItems(): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    const result = [];
    if (!this.isFirst) {
      result.push(
        menuItemBuilder
          .setKey("Move up")
          .setUiType({
            iconTheme: "outline",
            uiType: "uiIcon",
            iconType: "gh-up",
          })
          .setEndOfSection(this.isLast)
          .setAction({ handler: () => this.moveUp.emit(this.result) })
          .build()
      );
    }
    if (!this.isLast) {
      result.push(
        menuItemBuilder
          .setKey("Move down")
          .setUiType({
            iconTheme: "outline",
            uiType: "uiIcon",
            iconType: "gh-down",
          })
          .setAction({ handler: () => this.moveDown.emit(this.result) })
          .setEndOfSection(true)
          .build()
      );
    }
    result.push(
      menuItemBuilder
        .setKey("Insert Text Above")
        .setAction({ handler: () => this.insertAbove.emit() })
        .build()
    );
    result.push(
      menuItemBuilder
        .setKey("Insert Text Below")
        .setAction({ handler: () => this.insertBelow.emit() })
        .build()
    );
    result.push(
      menuItemBuilder
        .setKey("Delete")
        .setUiType({
          iconTheme: "outline",
          uiType: "uiIcon",
          iconType: "trash-bin",
        })
        .setAction({ handler: () => this.deleteResult.emit(this.result) })
        .setEndOfSection(true)
        .build()
    );
    return result;
  }

  public editFreeText(content: string): void {
    this.editContent.emit({
      ...this.result,
      blockContent: {
        text: content,
        references: this.textBlockContent?.references || [],
      },
    });
  }

  public editDescriptionText(content: string): void {
    this.editContent.emit({
      ...this.result,
      blockContent: {
        title: this.titleDescriptionBlockContent.title,
        description: content,
      },
    });
  }

  public editSwot(swotSection: { content: StructuredDataAspectVM[]; section: keyof SwotDataVM }): void {
    const newSwotBlockContent = { ...this.swotBlockContent };
    newSwotBlockContent[swotSection.section] = swotSection.content;
    this.editContent.emit({
      ...this.result,
      blockContent: newSwotBlockContent,
    });
  }

  public editAnsoff(ansoffSection: { content: StructuredDataAspectVM[]; section: keyof AnsoffDataVM }): void {
    const newAnsoffBlockContent = { ...this.ansoffBlockContent };
    newAnsoffBlockContent[ansoffSection.section] = ansoffSection.content;
    this.editContent.emit({
      ...this.result,
      blockContent: newAnsoffBlockContent,
    });
  }

  public editPest(pestSection: { content: StructuredDataAspectVM[]; section: keyof PestDataVM }): void {
    const newPestBlockContent = { ...this.pestBlockContent };
    newPestBlockContent[pestSection.section] = pestSection.content;
    this.editContent.emit({
      ...this.result,
      blockContent: newPestBlockContent,
    });
  }

  public editPorter(porterSection: { content: StructuredDataAspectVM[]; section: keyof PorterForcesDataVM }): void {
    const newPorterBlockContent = { ...this.porterBlockContent };
    newPorterBlockContent[porterSection.section] = porterSection.content;
    this.editContent.emit({
      ...this.result,
      blockContent: newPorterBlockContent,
    });
  }

  public editBlueOcean(blueOceanSection: { content: StructuredDataAspectVM[]; section: keyof BlueOceanDataVM }): void {
    const newBlueOceanBlockContent = { ...this.blueOceanBlockContent };
    newBlueOceanBlockContent[blueOceanSection.section] = blueOceanSection.content;
    this.editContent.emit({
      ...this.result,
      blockContent: newBlueOceanBlockContent,
    });
  }

  public editBalancedScorecard(balancedScorecardSection: { content: StructuredDataAspectVM[]; section: keyof BalancedScoreCardDataVM }): void {
    const newBalancedScorecardBlockContent = {
      ...this.balancedScorecardBlockContent,
    };
    newBalancedScorecardBlockContent[balancedScorecardSection.section] = balancedScorecardSection.content;
    this.editContent.emit({
      ...this.result,
      blockContent: newBalancedScorecardBlockContent,
    });
  }

  public editGoals(goalsSection: { content: StructuredDataAspectVM[]; section: number }): void {
    const newGoalsBlockContent = { ...this.goalsBlockContent };

    const reasoning = goalsSection.content.pop();

    newGoalsBlockContent.goals[goalsSection.section].keyResults = goalsSection.content.map((aspect) => aspect.text);
    newGoalsBlockContent.goals[goalsSection.section].reasoning = reasoning.text;

    this.editContent.emit({
      ...this.result,
      blockContent: newGoalsBlockContent,
    });
  }

  public editKpis({ content, section }: { content: StructuredDataAspectVM[]; section: number }): void {
    const newKpisBlockContent = { ...this.kpisBlockContent };

    newKpisBlockContent.kpis[section] = {
      ...newKpisBlockContent.kpis[section],
      reasoning: content[0].text,
      references: content[0].references,
    };

    this.editContent.emit({
      ...this.result,
      blockContent: newKpisBlockContent,
    });
  }

  public executeAction($event: FollowupActionDefinition): void {
    this.executeFollowupAction.emit($event);
  }

  public focusOutWithChangesHandler(result: OrderedStrategyResultEntryVM): void {
    this.focusOutWithChanges.emit(result);
  }
}
