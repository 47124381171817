import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { BalancedScoreCardDataVM, StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { FollowupActionDefinition } from "@webapp/strategy/models/strategy.vm-models";
import { GenericResultsBoxComponent } from "../generic-results-box/generic-results-box.component";

@Component({
  selector: "balanced-scorecard-result-block",
  templateUrl: "./balanced-scorecard-result-block.component.html",
  styleUrls: ["./balanced-scorecard-result-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GenericResultsBoxComponent],
})
export class BalancedScorecardResultBlockComponent {
  @Input() public balancedScoreCard: BalancedScoreCardDataVM;
  @Output() public readonly contentChanged = new EventEmitter<{
    content: StructuredDataAspectVM[];
    section: keyof BalancedScoreCardDataVM;
  }>();
  @Output()
  public readonly executeAction: EventEmitter<FollowupActionDefinition> = new EventEmitter<FollowupActionDefinition>();
  @Output()
  public readonly focusOutWithChanges: EventEmitter<void> = new EventEmitter<void>();

  public changeContent($event: StructuredDataAspectVM[], section: keyof BalancedScoreCardDataVM): void {
    this.contentChanged.emit({
      content: $event,
      section,
    });
  }
}
