import { MonoTypeOperatorFunction, pipe } from "rxjs";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class PlatformStatusResponseStrategy implements QuantiveResultsSocketStrategy<"strategyGlobalStatus"> {
  public messageType: QuantiveResultsSocketStrategy<"strategyGlobalStatus">["messageType"] = "strategyGlobalStatus";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"strategyGlobalStatus">> {
    return pipe();
  }
}
