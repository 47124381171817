import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "text-references",
  standalone: true,
  templateUrl: "./text-references.component.html",
  styleUrls: ["./text-references.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class TextReferencesComponent {
  @Input() public references: string[];
}
