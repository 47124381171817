export const groupBy = <T>(arr: T[], grouping: string | ((index: number, item: T) => string)): Record<string, T[]> => {
  if (!arr || !arr.length || !grouping) {
    return {};
  }

  let groupFn: (index: number, item: T) => string;
  if (typeof grouping === "string") {
    groupFn = (index, item): string => item[grouping];
  } else {
    groupFn = grouping;
  }

  return arr.reduce((result, item, index) => {
    const group = groupFn(index, item);
    if (!(group in result)) {
      result[group] = [item];
    } else {
      result[group].push(item);
    }
    return result;
  }, {});
};
