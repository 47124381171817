import { Direction } from "@angular/cdk/bidi";
import { EventEmitter, InjectionToken, StaticProvider, TemplateRef, Type, ViewContainerRef } from "@angular/core";
import { UiButtonShape, UiButtonSize, UiButtonType } from "@quantive/ui-kit/button";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { ModalOptions } from "ng-zorro-antd/modal";
import { noop } from "rxjs";
import { UiModalRef } from "./abstracts/modal-ref";

export type UiOnClickCallback<T> = (instance: T) => (false | void | object) | Promise<false | void | object>;

export type UiModalModels = "default" | "confirm";

export type UiConfirmType = "confirm" | "info" | "success" | "error" | "warning";

export interface UiStyleObjectLike {
  [key: string]: string;
}

/**
 * Custom properties to the original modal options. Those need to be declared here if you intend to use them in modal components
 */
export class CustomModalOptions<T = NzSafeAny, D = NzSafeAny, R = NzSafeAny> extends ModalOptions<T, D, R> {
  public nzProviders?: StaticProvider[];
  public nzOkShape?: UiButtonShape = "round";
  public nzOkSize?: UiButtonSize = "large";
  public nzCancelShape?: UiButtonShape = "round";
  public nzCancelSize?: UiButtonSize = "large";
  public nzCancelType?: UiButtonType = "link";
  public nzError?: string;
  public nzLoadingIndicator?: boolean;
  public nzDescription?: string | TemplateRef<object>;
  public nzNoScrollableContent?: boolean;
  public nzModalSideContent?: TemplateRef<object>;
  public nzModalOptionsButton?: TemplateRef<object>;
  public nzModalContentCentered?: boolean;
}

export class UiModalOptions<T = NzSafeAny, D = NzSafeAny, R = NzSafeAny> {
  public uiCentered?: boolean = false;
  public uiClosable?: boolean = true;
  public uiOkDisabled?: boolean = false;
  public uiCancelDisabled?: boolean = false;
  public uiAutofocus?: "ok" | "cancel" | "auto" | null = "auto";
  public uiMask?: boolean;
  public uiMaskClosable? = false;
  public uiKeyboard?: boolean = true;
  public uiZIndex?: number = 1000;
  public uiWidth?: number | string = 500;
  public uiCloseIcon?: string | TemplateRef<void> = "close-big";
  public uiOkType?: UiButtonType = "primary";
  public uiOkDanger?: boolean = false;
  public uiModalType?: UiModalModels = "default";
  public uiOnCancel?: EventEmitter<T> | UiOnClickCallback<T> = noop;
  public uiOnOk?: EventEmitter<T> | UiOnClickCallback<T> = noop;
  public uiData?: D;
  public uiMaskStyle?: UiStyleObjectLike;
  public uiBodyStyle?: UiStyleObjectLike;
  public uiWrapClassName?: string;
  public uiClassName?: string;
  public uiStyle?: object;
  public uiTitle?: string | TemplateRef<object>;
  public uiFooter?: string | TemplateRef<object> | Array<UiModalButtonOptions<T>> | null; // Default Modal ONLY
  public uiCancelText?: string | null;
  public uiCancelLoading?: boolean;
  public uiOkText?: string | null;
  public uiOkLoadingText?: string | null;
  public uiOkLoading?: boolean;
  public uiContent?: string | TemplateRef<NzSafeAny> | Type<T>;
  public uiCloseOnNavigation?: boolean;
  public uiViewContainerRef?: ViewContainerRef;

  // Template use only
  public uiAfterOpen?: EventEmitter<void>;
  public uiAfterClose?: EventEmitter<R>;

  // Confirm
  public uiIconType?: string;
  public uiDirection?: Direction;

  // Custom
  public uiOkShape?: UiButtonShape = "round";
  public uiOkSize?: UiButtonSize = "large";
  public uiCancelShape?: UiButtonShape = "round";
  public uiCancelSize?: UiButtonSize = "large";
  public uiCancelType?: UiButtonType = "link";
  public uiProviders?: StaticProvider[];
  public uiError?: string;
  public uiLoadingIndicator?: boolean;
  public uiDescription?: string | TemplateRef<object>;
  public uiNoScrollableContent?: boolean;
  public uiModalSideContent?: TemplateRef<object>;
  public uiModalOptionsButton?: TemplateRef<object>;
  public uiModalContentCentered?: boolean;
}

export interface UiModalButtonOptions<T = NzSafeAny> {
  label: string;
  type?: UiButtonType;
  danger?: boolean;
  shape?: UiButtonShape;
  ghost?: boolean;
  size?: UiButtonSize;
  e2eTestId?: string;
  show?: boolean | ((this: UiModalButtonOptions<T>, component: T) => boolean);
  disabled?: boolean | ((this: UiModalButtonOptions<T>, component: T) => boolean);
  onClick?(this: UiModalButtonOptions<T>, component: T, modalRef?: UiModalRef<T>): NzSafeAny | Promise<NzSafeAny>;
}

export const UI_MODAL_DATA = new InjectionToken<NzSafeAny>("UI_MODAL_DATA");

export const UI_MODAL_INDEX = new InjectionToken<number>("UI_MODAL_INDEX");
