import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { TextReferencesComponent } from "@webapp/strategy/components/evaluate-bet-page/components/text-references/text-references.component";
import { StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { FollowupActionDefinition, StrategyConfig } from "@webapp/strategy/models/strategy.vm-models";
import { StrategyConfigService } from "@webapp/strategy/services/config/strategy-config.service";
import { ActionableTextBlockComponent } from "../../../actionable-text-block/actionable-text-block.component";
import { ResultsBoxLabelsListComponent } from "./components/results-box-labels-list/results-box-labels-list.component";

@Component({
  selector: "generic-results-box",
  templateUrl: "./generic-results-box.component.html",
  styleUrls: ["./generic-results-box.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ResultsBoxLabelsListComponent, NgFor, ActionableTextBlockComponent, TextReferencesComponent, AsyncPipe],
})
export class GenericResultsBoxComponent implements OnInit {
  @Output()
  public readonly executeAction: EventEmitter<FollowupActionDefinition> = new EventEmitter<FollowupActionDefinition>();
  @Output()
  public readonly contentChanged: EventEmitter<StructuredDataAspectVM[]> = new EventEmitter<StructuredDataAspectVM[]>();
  @Output()
  public readonly focusOutWithChanges: EventEmitter<void> = new EventEmitter<void>();
  @Input() public title: string;
  @Input() public labels: string[];
  @Input() public data: StructuredDataAspectVM[];
  @Input() public resultMenuItems: DropdownMenuItem[];
  public config$: Observable<StrategyConfig>;

  constructor(private strategyConfigService: StrategyConfigService) {}

  public ngOnInit(): void {
    this.config$ = this.strategyConfigService.getConfig$();
  }

  public editSwotSectionItem(item: StructuredDataAspectVM, content: string): void {
    this.contentChanged.emit(this.data.map((swotSectionItem) => (swotSectionItem.id === item.id ? { ...swotSectionItem, text: content } : swotSectionItem)));
  }
}
