/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UIErrorConfiguration } from "@gtmhub/error-handling/models";
import { ActionType, ErrorScreens, ScreenToUIErrorMapping } from "@webapp/strategy/models/error-handling.model";
import { AreaActionErrorMapIntercom } from "@webapp/strategy/models/mappings/area.mapping";
import { BetActionErrorMapIntercom } from "@webapp/strategy/models/mappings/decision.mapping";
import { HypothesisActionErrorMapIntercom } from "@webapp/strategy/models/mappings/hypothesis.mapping";

const TRANSACTION_ID_HEADER_NAME = "x-transaction-id";

@Injectable()
export class UiErrorHandlingService {
  private readonly screenToActionErrorMap: ScreenToUIErrorMapping = {
    decision: BetActionErrorMapIntercom,
    hypothesis: HypothesisActionErrorMapIntercom,
    area: AreaActionErrorMapIntercom,
  };

  public getUIErrorData = (error: HttpErrorResponse, action: ActionType, currentScreen: ErrorScreens, subtitle?: string): UIErrorConfiguration => {
    const genericSubtitle = "Please refresh the page to try again. If the problem persists, ";
    const desiredScreenMapping = this.screenToActionErrorMap[currentScreen];

    const actionTitle = desiredScreenMapping[action]?.title as string;

    if (!actionTitle) {
      throw new Error(`Unsupported action: ${action}`);
    }

    let transactionId = "";
    if (error.headers instanceof HttpHeaders) {
      transactionId = error.headers.get(TRANSACTION_ID_HEADER_NAME);
    } else if (error.headers && TRANSACTION_ID_HEADER_NAME in error.headers) {
      transactionId = error.headers[TRANSACTION_ID_HEADER_NAME] as string;
    }

    return {
      title: actionTitle,
      subtitle: subtitle || genericSubtitle,
      errorDetails: `${error.status} ${error.statusText}: ${JSON.stringify(error.error)}`,
      transactionId: transactionId,
      intercomAction: desiredScreenMapping[action].intercomAction as string,
    };
  };
}
