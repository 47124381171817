import { ResultBlockType } from "./models/chat.vm-models";

export const PREDEFINED_FRAMEWORKS_PROMPTS_MAP: Record<ResultBlockType, { label: string; action: ResultBlockType; question: string }> = {
  [ResultBlockType.PEST]: {
    label: "PEST",
    action: ResultBlockType.PEST,
    question: "Generate a PEST analysis",
  },
  [ResultBlockType.SWOT]: {
    label: "SWOT",
    action: ResultBlockType.SWOT,
    question: "Generate a SWOT analysis",
  },
  [ResultBlockType.Porter]: {
    label: "Porter's Five Forces",
    action: ResultBlockType.Porter,
    question: "Generate a Porter's Five Forces analysis",
  },
  [ResultBlockType.BalancedScorecard]: {
    label: "Balanced Scorecard",
    action: ResultBlockType.BalancedScorecard,
    question: "Generate a Balanced Scorecard",
  },
  [ResultBlockType.BlueOcean]: {
    label: "Blue Ocean",
    action: ResultBlockType.BlueOcean,
    question: "Generate a Blue Ocean",
  },
  [ResultBlockType.Ansoff]: {
    label: "Ansoff Matrix",
    action: ResultBlockType.Ansoff,
    question: "Generate an Ansoff Matrix",
  },
  [ResultBlockType.GenerateGoals]: {
    label: "Suggest OKRs",
    action: ResultBlockType.GenerateGoals,
    question: "Suggest OKRs",
  },
  [ResultBlockType.GenerateKPIs]: {
    label: "Suggest KPIs",
    action: ResultBlockType.GenerateKPIs,
    question: "Suggest KPIs",
  },
  [ResultBlockType.BCG]: {
    label: "BCG Matrix",
    action: ResultBlockType.BCG,
    question: "Analyze using BCG Matrix",
  },
  [ResultBlockType.ScenarioPlanning]: {
    label: "Scenario Planning",
    action: ResultBlockType.ScenarioPlanning,
    question: "Analyze using Scenario Planning",
  },
  [ResultBlockType.GapAnalysis]: {
    label: "Gap Analysis",
    action: ResultBlockType.GapAnalysis,
    question: "Analyze using Gap Analysis",
  },
  [ResultBlockType.ValueChainAnalysis]: {
    label: "Value Chain Analysis",
    action: ResultBlockType.ValueChainAnalysis,
    question: "Analyze using Value Chain Analysis",
  },
  [ResultBlockType.Benchmarking]: {
    label: "Benchmarking",
    action: ResultBlockType.Benchmarking,
    question: "Analyze using Benchmarking",
  },
  [ResultBlockType.McKinsey7SModel]: {
    label: "McKinsey 7S Model",
    action: ResultBlockType.McKinsey7SModel,
    question: "Analyze using McKinsey 7S Model",
  },
  [ResultBlockType.ValuePropositionCanvas]: {
    label: "Value Proposition Canvas",
    action: ResultBlockType.ValuePropositionCanvas,
    question: "Analyze using Value Proposition Canvas",
  },
  [ResultBlockType.GrowthShareMatrix]: {
    label: "Growth Share Matrix",
    action: ResultBlockType.GrowthShareMatrix,
    question: "Analyze using Growth Share Matrix",
  },
  [ResultBlockType.DecisionTrees]: {
    label: "Decision Trees",
    action: ResultBlockType.DecisionTrees,
    question: "Analyze using Decision Trees",
  },
  [ResultBlockType.RootCauseAnalysis]: {
    label: "Root Cause Analysis",
    action: ResultBlockType.RootCauseAnalysis,
    question: "Analyze using Root Cause Analysis",
  },
  [ResultBlockType.FishboneDiagram]: {
    label: "Fishbone Diagram",
    action: ResultBlockType.FishboneDiagram,
    question: "Analyze using Fishbone Diagram",
  },
  [ResultBlockType.ParetoAnalysis]: {
    label: "Pareto Analysis",
    action: ResultBlockType.ParetoAnalysis,
    question: "Analyze using Pareto Analysis",
  },
  [ResultBlockType.SixSigma]: {
    label: "Six Sigma",
    action: ResultBlockType.SixSigma,
    question: "Analyze using Six Sigma",
  },
  [ResultBlockType.LeanThinking]: {
    label: "Lean Thinking",
    action: ResultBlockType.LeanThinking,
    question: "Analyze using Lean Thinking",
  },
  [ResultBlockType.The4PsOfMarketing]: {
    label: "The 4Ps of Marketing",
    action: ResultBlockType.The4PsOfMarketing,
    question: "Analyze using The 4Ps of Marketing",
  },
  [ResultBlockType.The5PsModel]: {
    label: "The 5Ps Model",
    action: ResultBlockType.The5PsModel,
    question: "Analyze using The 5Ps Model",
  },
  [ResultBlockType.ScenarioAnalysis]: {
    label: "Scenario Analysis",
    action: ResultBlockType.ScenarioAnalysis,
    question: "Analyze using Scenario Analysis",
  },
  [ResultBlockType.CompanyCultureAnalysis]: {
    label: "Company Culture Analysis",
    action: ResultBlockType.CompanyCultureAnalysis,
    question: "Analyze using Company Culture Analysis",
  },
  [ResultBlockType.GameTheory]: {
    label: "Game Theory",
    action: ResultBlockType.GameTheory,
    question: "Analyze using Game Theory",
  },
  [ResultBlockType.FeasibilityStudy]: {
    label: "Feasibility Study",
    action: ResultBlockType.FeasibilityStudy,
    question: "Analyze using Feasibility Study",
  },
  [ResultBlockType.RiskRewardAnalysis]: {
    label: "Risk Reward Analysis",
    action: ResultBlockType.RiskRewardAnalysis,
    question: "Analyze using Risk Reward Analysis",
  },
  [ResultBlockType.QuickAnalysis]: {
    label: "Quick Plan",
    action: ResultBlockType.QuickAnalysis,
    question: "Generate a Quick Plan",
  },
  [ResultBlockType.DecisionMap]: {
    label: "Decision Map",
    action: ResultBlockType.DecisionMap,
    question: "Generate a Decision Map",
  },
  [ResultBlockType.Goals]: {
    label: "Goals",
    action: ResultBlockType.Goals,
    question: "Goals",
  },
  [ResultBlockType.KPIs]: {
    label: "KPIs",
    action: ResultBlockType.KPIs,
    question: "KPIs",
  },
  [ResultBlockType.Loading]: {
    label: "Loading",
    action: ResultBlockType.Loading,
    question: "Loading",
  },
  [ResultBlockType.Error]: {
    label: "Error",
    action: ResultBlockType.Error,
    question: "Error",
  },
  [ResultBlockType.Text]: {
    label: "Text",
    action: ResultBlockType.Text,
    question: "Text",
  },
  [ResultBlockType.SuggestedFrameworks]: {
    label: "Suggested Frameworks",
    action: ResultBlockType.SuggestedFrameworks,
    question: "Suggested Frameworks",
  },
};
