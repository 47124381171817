/* eslint-disable @typescript-eslint/naming-convention */
import { init as engagementInit } from "@amplitude/engagement-browser";
import { Inject, Injectable, NgZone, OnDestroy } from "@angular/core";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { Observable, Subscription, take, tap } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { storage } from "@gtmhub/core/storage";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";

declare global {
  interface Window {
    engagement: {
      boot(config: {
        user: {
          user_id: string;
          device_id: string;
          user_properties: Record<string, unknown>;
        };
        integrations: Array<{
          track(event: { event_type: string; event_properties: Record<string, unknown> }): void;
        }>;
      }): Promise<void>;
      forwardEvent(event: { event_type: string; event_properties: Record<string, unknown> }): void;
    };
  }
}

@Injectable()
export class AnalyticsFactoryService implements OnDestroy {
  private initializeSubscription$: Subscription;
  private analytics: AnalyticsBrowser;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private ngZone: NgZone,
    private broadcastService: BroadcastService
  ) {
    // This will initialize analytics and add all tracking events in queue
    // and send them when .load() method is called
    this.ngZone.runOutsideAngular(() => {
      this.initializeSubscription$ = this.initialize$().subscribe();
    });
  }

  public ngOnDestroy(): void {
    this.initializeSubscription$.unsubscribe();
  }

  public getAnalytics(): AnalyticsBrowser {
    return this.analytics;
  }

  private initialize$(): Observable<IAccount> {
    this.analytics = new AnalyticsBrowser();

    // Wait for account data initialization
    return this.broadcastService.on("accountDataInitialized").pipe(
      take(1),
      tap((account: IAccount) => {
        if (!this.appConfig.segment.enabled || !account.trackingProperties.shouldTrack) {
          // When segment || tracking we don't need to load analytics at all
          // Intercom will be loaded from container.ejs
          return;
        }
        this.analytics.load(
          {
            writeKey: this.appConfig.segment.writekey,
            cdnURL: "https://cdnpa.quantivestatic.com",
          },
          {
            obfuscate: true,
            integrations: {
              "Segment.io": {
                apiHost: "apipa.quantive.com/v1",
                protocol: "https",
              },
            },
          }
        );
        if (storage.get("enablegns") == 1) {
          engagementInit(this.appConfig.amplitude.amplitudeApiKey, { serverZone: this.appConfig.amplitude.amplitudeServerZone });
          this.analytics.ready(async () => {
            const user = await this.analytics.user();
            await window.engagement.boot({
              user: {
                // User Provider: Guides and Surveys requires either user_id or device_id for user identification
                user_id: user.id(),
                device_id: user.anonymousId(),
                user_properties: {},
              },
              integrations: [
                {
                  // Tracking Provider: Pass Guides and Surveys events to the 3rd party analytics provier
                  track: (event): void => {
                    this.analytics.track(event.event_type, event.event_properties);
                  },
                },
              ],
            });

            // (Optional) Forward events from segment to do event-based triggers for Guides and Surveys. These events aren't sent to the server
            this.analytics.on("track", (event, properties) => {
              window.engagement.forwardEvent({ event_type: event, event_properties: properties });
            });

            this.analytics.on("page", (event, properties) => {
              window.engagement.forwardEvent({ event_type: event, event_properties: properties });
            });
          });
        }
      })
    );
  }
}
