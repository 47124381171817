<top-nav-bar [navTitle]="'okr_suggestions' | localize" [topNavBarButtonsConfig]="topNavBarButtonsConfig" searchFacet="whiteboards" />

<article>
  <header description="Develop impactful OKRs with contextual and personalized AI recommendations" headerTitle="Co-create OKRs with AI" strategy-ai-header uiType="strategyai-develop"></header>

  <button class="suggest-okrs-button" (click)="onSuggestOKRs()">
    <i ui-icon uiType="artificial-intelligence"></i>
    {{ "suggest_okrs" | localize }}
  </button>

  <section>
    @if (isLoading) {
      <strategy-ai-card-loading-list />
    } @else {
      <strategy-ai-list [groups]="groupedSuggestedGoals" [templateRef]="template" />
    }
  </section>
</article>

<ng-template #template let-suggestedGoal>
  <strategy-ai-card [created]="suggestedGoal.createdAt" [dropdown]="dropdown" [name]="suggestedGoal.context" [ownerIds]="[currentUserId]" (click)="onOpenSuggestedGoal(suggestedGoal)">
  </strategy-ai-card>

  <ng-template #dropdown>
    <strategy-ai-dropdown>
      <ul>
        <li class="destructive" (click)="onDeleteSuggestedGoal(suggestedGoal.id)" role="presentation">
          <i class="fs-16px" ui-icon uiType="trash-bin"></i>
          <span>Delete</span>
        </li>
      </ul>
    </strategy-ai-dropdown>
  </ng-template>
</ng-template>
