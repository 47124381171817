import { ApplicationRef, NgModule } from "@angular/core";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { TerminologyUpdateNotificationInstance } from "./terminology-update-notification";
import { TOP_NAV_BAR_NOTIFICATION_INSTANCE, TopNavBarNotificationService } from "./top-nav-bar-notification.service";

@NgModule({
  providers: [
    TopNavBarNotificationService,
    {
      provide: TOP_NAV_BAR_NOTIFICATION_INSTANCE,
      useClass: TerminologyUpdateNotificationInstance,
      multi: true,
      deps: [ApplicationRef, BroadcastService],
    },
  ],
})
export class TopNavBarNotificationProvidersModule {}
