import { StateService } from "@uirouter/angular";
import { FocusKeyManager } from "@angular/cdk/a11y";
import { DOWN_ARROW, ENTER, ESCAPE, SPACE, TAB, UP_ARROW, hasModifierKey } from "@angular/cdk/keycodes";
import { OverlayRef } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, booleanAttribute } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropDownDirective, UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiMenuModule } from "@quantive/ui-kit/menu";
import { UiToggleModule } from "@quantive/ui-kit/toggle";
import { Subscription, combineLatest, noop } from "rxjs";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { HomeWidgetConfigurationService } from "@webapp/home/services/home-widget-configuration.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { HotkeysModule } from "@webapp/shared/hotkeys/hotkeys.module";
import { WidgetPositionChange } from "@webapp/ui/dashboard/dashboard.models";
import { UiListModule } from "@webapp/ui/list/list.module";
import { LocalizationModule } from "../../../../localization/localization.module";
import { MenuItemFocusableOption, WidgetTextMenuItem } from "./widget-actions.models";

@UntilDestroy()
@Component({
  selector: "widget-actions",
  templateUrl: "./widget-actions.component.html",
  styleUrls: ["./widget-actions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiButtonModule, UiDropdownModule, UiIconModule, UiMenuModule, UiListModule, UiToggleModule, LocalizationModule, AnalyticsModule, HotkeysModule],
})
export class WidgetActionsComponent implements OnDestroy, OnInit {
  @Input({ required: true }) public widgetId: string;
  @Input({ transform: booleanAttribute }) public canRemove: boolean;
  @Output() public readonly remove = new EventEmitter<void>();

  @ViewChild(UiDropDownDirective, { static: false })
  public dropDownButtonDirective: UiDropDownDirective;

  public visible: boolean;
  public textMenuItems: WidgetTextMenuItem[] = [];
  public isFullWidth = false;
  public canToggleFullWidth = false;

  public get currentUserId(): string {
    return getCurrentUserId();
  }

  private toggleFullWidthInternal = noop;
  private focusKeyManager: FocusKeyManager<MenuItemFocusableOption>;
  private overlayKeydownEventsSubscription: Subscription;
  private focusLastMenuItem: boolean;

  private get dropDownButtonElement(): HTMLElement {
    return this.dropDownButtonDirective.elementRef.nativeElement;
  }

  constructor(
    private homeWidgetConfiguratorService: HomeWidgetConfigurationService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private permissionsFacade: PermissionsFacade
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.HomeWidget),
      this.permissionsFacade.hasPermission$("ManageHomeWidgets"),
      this.homeWidgetConfiguratorService.createConfiguratorForCurrentDashboardWidgets$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([isCustomHomeWidgetAvailable, hasManageHomeWidgetsPermission, configurator]) => {
        this.isFullWidth = configurator.isFullWidth(this.widgetId);
        this.canToggleFullWidth = configurator.canToggleFullWidth(this.widgetId);
        this.toggleFullWidthInternal = (): void => configurator.toggleFullWidth(this.widgetId);

        this.textMenuItems = [
          {
            titleKey: "move_up",
            hotkey: "meta+shift+up",
            iconType: "up",
            disabled: !configurator.canMoveUp(this.widgetId),
            click: (): void => {
              const widgetPositionAfterMove = configurator.moveUp(this.widgetId);
              this.sendWidgetMovedAnalyticsEvent(this.widgetId, "move_up", widgetPositionAfterMove);
            },
          },
          {
            titleKey: "move_down",
            hotkey: "meta+shift+down",
            iconType: "down",
            disabled: !configurator.canMoveDown(this.widgetId),
            click: (): void => {
              const widgetPositionAfterMove = configurator.moveDown(this.widgetId);
              this.sendWidgetMovedAnalyticsEvent(this.widgetId, "move_down", widgetPositionAfterMove);
            },
          },
          {
            titleKey: "move_right",
            hotkey: "meta+shift+right",
            iconType: "next",
            disabled: !configurator.canMoveRight(this.widgetId),
            click: (): void => {
              const widgetPositionAfterMove = configurator.moveRight(this.widgetId);
              this.sendWidgetMovedAnalyticsEvent(this.widgetId, "move_right", widgetPositionAfterMove);
            },
          },
          {
            titleKey: "move_left",
            hotkey: "meta+shift+left",
            iconType: "back",
            disabled: !configurator.canMoveLeft(this.widgetId),
            click: (): void => {
              const widgetPositionAfterMove = configurator.moveLeft(this.widgetId);
              this.sendWidgetMovedAnalyticsEvent(this.widgetId, "move_left", widgetPositionAfterMove);
            },
          },
        ];

        if (this.widgetId === "my-okrs" && isCustomHomeWidgetAvailable && hasManageHomeWidgetsPermission) {
          this.textMenuItems.push({
            titleKey: "configure_widget",
            hotkey: "",
            disabled: false,
            iconType: "manage",
            click: (): void => {
              this.stateService.go("gtmhub.configuration.myOkrs");
            },
            isEndOfSection: true,
          });
        }
        this.cdr.markForCheck();
      });
  }

  public toggleFullWidth(): void {
    this.toggleFullWidthInternal();
    this.visible = false;
    this.cdr.markForCheck();
  }

  public handleVisibleChange($event: boolean): void {
    this.visible = $event;
    this.cdr.markForCheck();

    this.destroyFocusKeyManager();

    if (this.visible) {
      // run this in a promise to allow the creation of the dropdown overlay
      Promise.resolve().then(() => this.handleVisibleTrue());
    }
  }

  public onKeyDown($event: KeyboardEvent): void {
    if (($event.keyCode === UP_ARROW || $event.keyCode === DOWN_ARROW) && !this.visible) {
      this.focusLastMenuItem = $event.keyCode === UP_ARROW;
      this.dropDownButtonElement.click();

      // prevent page scroll when using up/down arrows
      $event.preventDefault();
    }
  }

  public ngOnDestroy(): void {
    this.destroyFocusKeyManager();
  }

  private destroyFocusKeyManager(): void {
    this.focusKeyManager?.destroy();
    this.overlayKeydownEventsSubscription?.unsubscribe();
  }

  private handleVisibleTrue(): void {
    const overlayRef = this.dropDownButtonDirective["overlayRef"] as OverlayRef;
    const menuItemElements = Array.from(overlayRef.hostElement.querySelectorAll<HTMLElement>("[role='menuitem']")).map((el) => new MenuItemFocusableOption(el));
    if (!menuItemElements.length) {
      return;
    }

    this.focusKeyManager = new FocusKeyManager(menuItemElements).withVerticalOrientation(true).withHomeAndEnd(true).withTypeAhead(200).withWrap().setFocusOrigin(null);
    if (this.focusLastMenuItem) {
      this.focusKeyManager.setLastItemActive();
      this.focusLastMenuItem = false;
    } else {
      this.focusKeyManager.setFirstItemActive();
    }

    this.overlayKeydownEventsSubscription = overlayRef.keydownEvents().subscribe((event) => {
      switch (event.keyCode) {
        case ESCAPE:
        case TAB:
          if (!hasModifierKey(event)) {
            this.dropDownButtonElement.click();
            this.dropDownButtonElement.focus();
            event.preventDefault();
          }
          break;
        case SPACE:
        case ENTER: {
          const target = event.target as HTMLElement;
          // ui-toggle's button has already caught the space/enter keydown before coming here,
          // so we need to avoid double clicking on the button
          if (target.getAttribute("role") === "menuitem") {
            this.focusKeyManager.activeItem.click();
          }
          event.preventDefault();
          break;
        }

        default:
          this.focusKeyManager.onKeydown(event);
          break;
      }
    });
  }

  private sendWidgetMovedAnalyticsEvent(widgetId: string, action: string, widgetPosition: WidgetPositionChange): void {
    this.analyticsService.track("Widget Moved", {
      name: "home_customizable",
      action: action,
      entity: widgetId,
      starting_widget_position: widgetPosition.startingWidgetPosition,
      ending_widget_position: widgetPosition.endingWidgetPosition,
      drag_and_drop: false,
      number_widgets: widgetPosition.widgetsCount,
      user_id: getCurrentUserId(),
    });
  }
}
