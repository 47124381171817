import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IPatchUnsyncedStateChangesResponse } from "@gtmhub/whiteboards/models";
import { IWhiteboardStateChange } from "@gtmhub/whiteboards/rendering/state/models";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { ICollection } from "@webapp/core/core.models";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { Whiteboard } from "../models/whiteboard.models";
import { WhiteboardsApiV2Service } from "./whiteboards-api-v2.service";

@Injectable({ providedIn: "root" })
export class WhiteboardsRepository implements Pick<IBaseRepository<Whiteboard>, "getAll$" | "patch$" | "delete$"> {
  constructor(private readonly api: WhiteboardsApiV2Service) {}

  public getAll$<RT = ICollection<Whiteboard>>(params?: { filter?: RequestPaging; config?: RequestConfig }): Observable<RT> {
    return this.api.getAll$<RT>(params.filter, params.config);
  }

  public patch$<RT = Whiteboard>(params: { id: string; patchModel: Partial<Whiteboard>; config?: RequestConfig }): Observable<RT> {
    return this.api.patch$(params.id, params.patchModel, params.config);
  }

  public delete$(params: { id: string; config?: RequestConfig }): Observable<void> {
    return this.api.delete$(params.id, params.config);
  }

  public applyStateChanges$(params: { id: string; stateChanges: IWhiteboardStateChange[]; config?: RequestConfig }): Observable<IPatchUnsyncedStateChangesResponse> {
    const url = this.api.getBasePath(HttpActions.patch) + `${params.id}/state`;

    return this.api.patch$(null, params.stateChanges as unknown, {
      url: url,
      ...params.config,
    });
  }

  public applyStateChangesV2$(params: { id: string; stateChanges: IWhiteboardStateChange[]; config?: RequestConfig }): Observable<IPatchUnsyncedStateChangesResponse> {
    const url = this.api.getBasePath(HttpActions.patch, { apiVersionOverwrite: "v2" }) + params.id;

    return this.api.patch$(null, params.stateChanges as unknown, {
      url: url,
      ...params.config,
    });
  }

  public clone$(params: { id: string; config?: RequestConfig }): Observable<Whiteboard> {
    const url = this.api.getBasePath(HttpActions.post) + `${params.id}/clone`;

    return this.api.post$(null, { url, ...params.config });
  }
}
