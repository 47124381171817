import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class GenerateStrategyMapAreaResponseStrategy implements QuantiveResultsSocketStrategy<"createStrategyMapAreaResponse"> {
  public messageType: QuantiveResultsSocketStrategy<"createStrategyMapAreaResponse">["messageType"] = "createStrategyMapAreaResponse";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"createStrategyMapAreaResponse">> {
    return pipe(filter(({ data }) => data.item === "strategic_bet" && data.taskName === "create_strategic_area" && data.userId === userId.get()));
  }
}
