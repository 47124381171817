import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiBadgeModule } from "@quantive/ui-kit/badge";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { localize } from "@gtmhub/localization";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { truncateDocumentName } from "@webapp/strategy/services/documents/api-utils/document-mapper.utils";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { DEFAULT_ACCEPTED_FILE_EXTENSIONS } from "@webapp/ui/upload/upload.component";
import { primaryButton } from "../modal/modal.utils";
import { IngestionProvidersModule } from "../services/ingestion/ingestion-providers.module";
import { IngestionRepositoryService } from "../services/ingestion/ingestion-repository.service";
import { UploadFormComponent } from "./components/upload-form.component";
import { UploadStateService } from "./services/upload-state.service";

@UntilDestroy()
@Component({
  selector: "upload-button",
  templateUrl: "./upload-button.component.html",
  styleUrls: ["./upload-button.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiButtonModule, UiIconModule, UiBadgeModule, IngestionProvidersModule, UiTooltipModule, LocalizationModule],
})
export class UploadButtonComponent implements OnInit, OnDestroy {
  @Input() public documentIds: string[] = [];
  @Input() public design: "upload" | "files-context" = "upload";

  @Output() public readonly uploadedDocumentIds = new EventEmitter<string[]>();

  public uploadedFilesCount = 0;

  public acceptedFileExtentions = DEFAULT_ACCEPTED_FILE_EXTENSIONS.join(", ");

  constructor(
    private uiModalService: UiModalService,
    private uploadStateService: UploadStateService,
    private ingestionRepositoryService: IngestionRepositoryService,
    private cdr: ChangeDetectorRef,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public ngOnInit(): void {
    if (this.documentIds.length) {
      this.ingestionRepositoryService
        .getDocumentsStatus$(this.documentIds)
        .pipe(takeOneUntilDestroyed(this))
        .subscribe((documents) => {
          const newDocs = [];

          for (const document of documents) {
            newDocs.push({
              sourceKey: document.sourceKey,
              sourceName: document.source,
              sourceType: document.sourceType,
              status: document.ingestionStatus,
              truncatedName: truncateDocumentName(document.source),
              file: null,
              sizeInBytes: 0, // TODO: get size from API when it's added
            });
          }

          this.uploadStateService.addDocuments(newDocs);
        });
    }

    this.uploadStateService.uploadedDocuments$.pipe(untilDestroyed(this)).subscribe((documents) => {
      this.uploadedFilesCount = documents.length;

      const docIds = documents.filter((f) => f.status !== "uploading").map((f) => f.sourceKey);
      if (JSON.stringify(docIds) !== JSON.stringify(this.documentIds)) {
        this.uploadedDocumentIds.emit(docIds); // Filter and emit only completed docs?
      }

      this.cdr.detectChanges();
    });

    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.StrategyXLSXEnabled)
      .pipe(untilDestroyed(this))
      .subscribe((isEnabled) => {
        if (isEnabled) {
          this.acceptedFileExtentions += ", .xlsx";
          this.cdr.detectChanges();
        }
      });
  }

  public ngOnDestroy(): void {
    this.uploadStateService.reset();
  }

  public onClick(): void {
    this.uiModalService.create({
      uiTitle: localize("context_cap"),
      uiContent: UploadFormComponent,
      uiWidth: "765px",
      uiFooter: [
        primaryButton(localize("done"), {
          onClick: (component, modalRef) => modalRef.close(),
        }),
      ],
    });
  }
}
