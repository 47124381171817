import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { ShareableFilter, ShareableFilterPostDTO } from "../../models/shareable-filters.models";
import { ShareableFiltersState } from "./shareable-filters-state.service";

@Injectable({
  providedIn: "root",
})
export class ShareableFiltersApiService extends BaseApiService<ShareableFilter, ShareableFilterPostDTO, ShareableFiltersState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: ShareableFiltersState) {
    super("filterdefinitions/shareable", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getSingleEndpoint(filterId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${filterId}`;
  }

  public deleteSingleEndpoint(filterId: string): string {
    return `${this.getBasePath(HttpActions.delete)}/${filterId}`;
  }

  public getMultipleEndpoint(): string {
    return `${this.getBasePath(HttpActions.getAll)}`;
  }

  public postSingleEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/`;
  }

  public patchSingleEndpoint(filterId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/${filterId}`;
  }
}
