import { IRootScopeService } from "angular";
import { getCurrentUserId } from "@gtmhub/users";
import { WhiteboardEvents } from "../events";
import { INoteState } from "../rendering/shapes/note.models";
import { WhiteboardStateHolder } from "../rendering/state/whiteboard-state-holder";
import { createNewNote } from "../whiteboards.utils";

export class WhiteboardsShapeService {
  static readonly $inject = ["$rootScope", "WhiteboardStateHolder"];

  private readonly events: WhiteboardEvents;

  constructor(
    private readonly $rootScope: IRootScopeService,
    private whiteboardStateHolder: WhiteboardStateHolder
  ) {
    this.events = new WhiteboardEvents(this.$rootScope);
  }

  createNote({ content }: { content?: string } = {}): INoteState {
    const zIndex = this.whiteboardStateHolder.calculateNextZIndex();
    const shape: INoteState = createNewNote(null, getCurrentUserId(), zIndex);

    shape.content = content || "";

    this.events.broadcastAddShape(shape);
    return shape;
  }

  updateNote({ shapeId, content }: { shapeId: string; content: string }): void {
    this.events.broadcastShapeStateChange({ shapeId, changes: { content }, options: { undo: false, notify: true } });
  }
}
