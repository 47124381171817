import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { catchHttpError } from "@webapp/core/rxjs-operators/catch-http-error.operator";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { AsyncTaskVM } from "@webapp/strategy/models/async-tasks/async-tasks.vm-models";
import { ContextVM } from "@webapp/strategy/models/context/context.vm-models";
import { WHITEBOARD_GENERATION_ERROR } from "@webapp/strategy/models/strategy.constants";
import { AsyncOperationStatusVM } from "@webapp/strategy/models/strategy.vm-models";
import { AsyncTasksService } from "../../async-tasks/async-tasks.service";
import { getLastStartedTaskFromList } from "../../utility/async-tasks.utils";
import { QuantiveResultsSocketVM } from "../../web-sockets/models/strategy/socket-strategy.vm-models";
import { StrategySocketsService } from "../../web-sockets/services/strategy-sockets.service";
import { StrategyConversationContextService } from "../strategy-conversation-context.service";

type GenerateGoalsContentState = {
  status: AsyncOperationStatusVM;
  whiteboardId: string;
  errorMessage: string;
};

const asyncTaskStatusToContentStatus: {
  [AsyncTaskStatus in AsyncTaskVM["status"]]: GenerateGoalsContentState["status"];
} = {
  PENDING: "LOADING",
  STARTED: "LOADING",
  SUCCESS: "SUCCESS",
  FAILURE: "ERROR",
};

@UntilDestroy()
@Injectable()
export class GenerateGoalsForContextMediator {
  private socketSubscription: Subscription;
  private generatedContentByContextIdMap$: Record<string, BehaviorSubject<GenerateGoalsContentState>> = {};

  constructor(
    private strategyConversationContextService: StrategyConversationContextService,
    private asyncTasks: AsyncTasksService,
    private socketService: StrategySocketsService
  ) {}

  public listenForGoalGenerationChangesForContext$({ id, progressStep }: Pick<ContextVM, "id" | "progressStep">): Observable<GenerateGoalsContentState> {
    if (!this.socketSubscription) {
      this.socketSubscription = this.subscribeToChangesViaSocket();
    }

    return this.retrieveStateFromRunningTask$({
      id,
      progressStep,
    }).pipe(
      distinctUntilChanged((prev, curr) => prev.status === curr.status),
      switchMap((state) => {
        this.emitStateChangeForContextId(id, state);

        return this.generatedContentByContextIdMap$[id].asObservable();
      })
    );
  }

  public triggerGoalGenerationForContext$(contextId: string): void {
    this.retrieveStateFromAsyncGoalGeneration$(contextId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (state) => {
          this.emitStateChangeForContextId(contextId, state);
        },
      });
  }

  public invalidateGoalGenerationStateForContext(contextId: string): void {
    this.strategyConversationContextService
      .patchBetContext$({ id: contextId, progressStep: 0 })
      .pipe(
        takeOneUntilDestroyed(this),
        map(() => this.toIdleState()),
        catchHttpError((error: HttpErrorResponse) => of(this.toErrorState(error)))
      )
      .subscribe({
        next: (state) => {
          this.emitStateChangeForContextId(contextId, state);
        },
      });
  }

  private retrieveStateFromRunningTask$({ id, progressStep }: Pick<ContextVM, "id" | "progressStep">): Observable<GenerateGoalsContentState> {
    return this.retrieveCurrentGoalGenerationStateForContext$({
      id,
      progressStep,
    }).pipe(
      takeOneUntilDestroyed(this),
      switchMap((existingState) => {
        if (existingState) return of(existingState);

        return of(this.toIdleState());
      })
    );
  }

  private retrieveStateFromAsyncGoalGeneration$(contextId: string): Observable<GenerateGoalsContentState> {
    return this.strategyConversationContextService.generateGoalsAsync$(contextId).pipe(
      untilDestroyed(this),
      map(() => this.toLoadingState()),
      catchHttpError((error: HttpErrorResponse) => of(this.toErrorState(error)))
    );
  }

  private retrieveCurrentGoalGenerationStateForContext$({ id, progressStep }: Pick<ContextVM, "id" | "progressStep">): Observable<GenerateGoalsContentState | null> {
    if (progressStep !== 1) return of(null);

    return this.asyncTasks.getTasksForAccountAndItemIds$([id], ["generate_goals_for_context"]).pipe(
      takeOneUntilDestroyed(this),
      map((runningTasks) => {
        if (!runningTasks.length) return null;

        const lastStartedTask = getLastStartedTaskFromList(runningTasks);

        return {
          status: asyncTaskStatusToContentStatus[lastStartedTask.status],
          whiteboardId: (lastStartedTask.additionalData?.whiteboardId as string) || "",
          errorMessage: "",
        };
      }),
      catchHttpError((error: HttpErrorResponse) => of(this.toErrorState(error)))
    );
  }

  private subscribeToChangesViaSocket(): Subscription {
    return this.socketService
      .onMessage$("generateGoalsForContextResponse")
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ({ data }) => {
          const {
            additionalData: { contextId },
          } = data;
          const existingStateEntrySubject$ = this.generatedContentByContextIdMap$[contextId];

          if (existingStateEntrySubject$?.value.status === "SUCCESS") return;

          this.reflectSocketStateChange({ socketData: data });
        },
        error: (error: HttpErrorResponse) => {
          this.reflectSocketStateChange({ socketData: null, error });
        },
      });
  }

  private reflectSocketStateChange({
    socketData: {
      status: incommingStatus,
      additionalData: { whiteboardId },
      itemId: contextId,
    },
    error,
  }: {
    socketData: QuantiveResultsSocketVM<"generateGoalsForContextResponse">["data"];
    error?: HttpErrorResponse;
  }): void {
    const state = {
      status: asyncTaskStatusToContentStatus[incommingStatus],
      whiteboardId: whiteboardId || "",
      errorMessage: this.httpErrorToErrorMessage(error),
    };

    this.emitStateChangeForContextId(contextId, state);
  }

  private emitStateChangeForContextId(contextId: string, content: GenerateGoalsContentState): void {
    if (!this.generatedContentByContextIdMap$[contextId]) {
      this.generatedContentByContextIdMap$[contextId] = new BehaviorSubject<GenerateGoalsContentState>(content);
    }

    this.generatedContentByContextIdMap$[contextId].next({
      ...this.generatedContentByContextIdMap$[contextId].value,
      ...content,
    });
  }

  private toIdleState(): GenerateGoalsContentState {
    return {
      status: "IDLE",
      whiteboardId: "",
      errorMessage: "",
    };
  }

  private toLoadingState(): GenerateGoalsContentState {
    return {
      status: "LOADING",
      whiteboardId: "",
      errorMessage: "",
    };
  }

  private toErrorState(response: HttpErrorResponse): GenerateGoalsContentState {
    return {
      status: "ERROR",
      whiteboardId: "",
      errorMessage: this.httpErrorToErrorMessage(response),
    };
  }

  private httpErrorToErrorMessage(errorResponse: HttpErrorResponse): string {
    if (!errorResponse) return "";

    const errorMap: Record<string, string> = {
      "context does not have associated document summary":
        "Your Quantive Assistant needs some time to summarize all the data you provided! Please try again in a few minutes.",
    };

    return errorMap[errorResponse?.error?.message] ? errorMap[errorResponse?.error?.message] : WHITEBOARD_GENERATION_ERROR;
  }
}
