<header class="strategy-conversation-results-header">
  <section class="title">
    <section class="sub-title" (click)="closePage.emit()" (keyup.enter)="closePage.emit()" tabindex="0">
      <i class="fs-16px" ui-icon uiType="arrow-left-double"></i>

      <a>{{ areaTitle }}</a>
    </section>

    <span>/</span>

    <h2>
      {{ hypothesisTitle }}
    </h2>
  </section>

  <section class="actions">
    <button class="header-button" (click)="showCollectiveContext.emit()" ui-button ui-tooltip uiTooltipTitle="Context">
      <i class="fs-16px" ui-icon uiType="answers"></i>
    </button>

    <gh-dropdown class="header-dropdown" [menuItems]="conversationDropdownItems" iconName="menu-vertical" ui-tooltip uiTooltipTitle="More" />

    <button class="header-button" (click)="closePage.emit()" ui-button ui-tooltip uiTooltipTitle="Close Page">
      <i class="fs-16px" ui-icon uiType="close-big"></i>
    </button>
  </section>
</header>
