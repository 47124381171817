import ReconnectingWebSocket from "reconnecting-websocket";
import { BehaviorSubject, EMPTY, Observable, Subject, from, of, switchMap, throwIfEmpty } from "rxjs";
import { TokenProvider } from "@gtmhub/auth";
import { accountId } from "@webapp/core/storage/services/cache/account-id";
import {
  QuantiveResultsGenericSocketData,
  QuantiveResultsGenericSocketMessageType,
  QuantiveResultsGenericSocketStrategy,
} from "../models/socket-generic-strategy.models";
import { ISocketsApiService } from "../models/sockets-api.interface";
import { BetHypothesisLockResponseStrategy } from "../utils/quantive-results-socket-strategy/bet-hypothesis-lock-response.strategy";
import { BetHypothesisUnockResponseStrategy } from "../utils/quantive-results-socket-strategy/bet-hypothesis-unlock-response.strategy";
import { CollaborateResponseStrategy } from "../utils/quantive-results-socket-strategy/collaborate-response.strategy";

type SocketConnectionStatus = "closed" | "pending" | "success" | "error";

export class BaseGenericSocketsService {
  private socketsApiService: ISocketsApiService;

  public constructor(private tokenProvider: TokenProvider) {}

  private readonly connectionTimeoutInMs = 10000;
  private socket: ReconnectingWebSocket;
  private connectionStatus$ = new BehaviorSubject<SocketConnectionStatus>("closed");
  private messagesSubject$ = new Subject<QuantiveResultsGenericSocketData>();

  public setApiService(apiService: ISocketsApiService): void {
    this.socketsApiService = apiService;
  }

  public onMessage$<MessageType extends QuantiveResultsGenericSocketMessageType>(messageType: MessageType): Observable<QuantiveResultsGenericSocketData> {
    if (!this.socketsApiService) {
      throw new Error("Sockets API service is not set");
    }
    return this.connectionStatus$.pipe(
      switchMap((connectionStatus) => {
        if (connectionStatus === "closed") {
          return this.initializeSocketConnection();
        }

        return of(connectionStatus);
      }),
      switchMap((connectionStatus) => {
        if (connectionStatus === "error") {
          return EMPTY.pipe(throwIfEmpty(() => new Error("Socket connection failed")));
        }

        const socketContext = this.getSocketContext(messageType);

        return this.messagesSubject$.pipe(socketContext.onMessage());
      })
    );
  }

  public sendMessage$(socketMessage: QuantiveResultsGenericSocketData): void {
    this.messagesSubject$.next(socketMessage);
  }

  public sendSocketMessage(message: Record<string, unknown>): void {
    this.socket.send(JSON.stringify(message));
  }

  public closeConnection(): void {
    this.socket.close();
  }

  private urlProvider = (): Promise<string> => {
    return this.tokenProvider.getValidToken().then((token) => this.generateConnectionUrl(token)) as Promise<string>;
  };

  private initializeSocketConnection(): Observable<SocketConnectionStatus> {
    return from(this.tokenProvider.getValidToken()).pipe(
      switchMap((token) => {
        if (!token) {
          this.connectionStatus$.next("error");
          return this.connectionStatus$.asObservable();
        }

        this.connectionStatus$.next("pending");

        this.socket = new ReconnectingWebSocket(this.urlProvider, null, {
          connectionTimeout: this.connectionTimeoutInMs,
        });
        this.socket.onopen = (): void => {
          this.connectionStatus$.next("success");
        };
        this.socket.onerror = (): void => {
          this.connectionStatus$.next("error");
        };
        this.socket.onmessage = (event: MessageEvent<string>): void => {
          const socketMessage: QuantiveResultsGenericSocketData = JSON.parse(event.data) as QuantiveResultsGenericSocketData;

          this.messagesSubject$.next(socketMessage);
        };

        return this.connectionStatus$.asObservable();
      })
    );
  }

  private getSocketContext<MessageType extends QuantiveResultsGenericSocketMessageType>(messageType: MessageType): QuantiveResultsGenericSocketStrategy {
    switch (messageType) {
      case "collaborate":
        return new CollaborateResponseStrategy();
      case "betHypothesisLock":
        return new BetHypothesisLockResponseStrategy();
      case "betHypothesisUnlock":
        return new BetHypothesisUnockResponseStrategy();
    }
  }

  private generateConnectionUrl(token: string): string {
    return this.socketsApiService.getSocketEndpoint({
      token,
      accountId: accountId.get(),
    });
  }
}
