import { NgModule } from "@angular/core";
import { AsyncTasksApiFactory } from "./api-utils/async-tasks-api-factory.service";
import { AsyncTasksApiV2Service } from "./api-utils/v2/async-tasks-api-v2.service";
import { AsyncTasksUrlBuilderV2Service } from "./api-utils/v2/async-tasks-url-builder-v2.service";
import { AsyncTasksService } from "./async-tasks.service";

@NgModule({
  providers: [AsyncTasksUrlBuilderV2Service, AsyncTasksApiV2Service, AsyncTasksApiFactory, AsyncTasksService],
})
export class AsyncTasksProvidersModule {}
