import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RestLayerRequest } from "@webapp/core/abstracts/models/rest-layer-request.models";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { ICollection } from "@webapp/core/core.models";
import { BulkActionOnUsersResponse } from "./models/user-bulk.model";
import { InvitedUserDTO, RoleDTO, RolePostDTO, UserPostDTO } from "./models/user-management.dto-model";

@Injectable()
export class InviteUserApiService extends PlainBaseApiService {
  constructor(httpClient: HttpClient, apiEndpointService: ApiEndpointService) {
    super(httpClient, apiEndpointService);
  }

  private get usersListUrl(): string {
    return this.getApiEndpointV2("users");
  }

  private get rolesListUrl(): string {
    return this.getApiEndpointV1("roles");
  }

  private getUsersBaseUrl(): string {
    return this.getApiEndpointV1("users");
  }

  private getUserRolesUrl(userId: string): string {
    return this.getApiEndpointV1(`users/${userId}/roles`);
  }

  private getUserRoleDetailUrl(userId: string, roleId: string): string {
    return this.getApiEndpointV1(`users/${userId}/roles/${roleId}`);
  }

  public getRoles(filters: RestLayerRequest<RoleDTO>): Observable<ICollection<RoleDTO>> {
    const url = this.rolesListUrl;
    return this.getAll$<ICollection<RoleDTO>>(url, filters);
  }

  public getUsers(filters: RestLayerRequest<InvitedUserDTO>): Observable<ICollection<InvitedUserDTO>> {
    const url = this.usersListUrl;
    return this.getAll$<ICollection<InvitedUserDTO>>(url, filters);
  }

  public deleteUserRole(userId: string, roleId: string): Observable<void> {
    const url = this.getUserRoleDetailUrl(userId, roleId);
    return this.delete$(url);
  }

  public postUser(userData: Record<string, UserPostDTO>, queryParams?: Record<string, string>): Observable<ICollection<InvitedUserDTO>> {
    const url = this.usersListUrl;
    return this.post$<ICollection<InvitedUserDTO>>(url, userData, {
      queryParams,
    });
  }

  public postUserRole(userId: string, payload: RolePostDTO): Observable<unknown> {
    const url = this.getUserRolesUrl(userId);
    return this.post$(url, payload);
  }

  public postBulkUserRoles(userIds: string[], roleIds: string[]): Observable<BulkActionOnUsersResponse> {
    const url = `${this.getUsersBaseUrl()}/roles`;

    return this.post$<BulkActionOnUsersResponse>(url, { userIds, roleIds });
  }
}
