import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { WhiteboardDTO } from "@webapp/whiteboards-v2/single-whiteboard/models/dto/whiteboard.dto-models";
import { Whiteboard, WhiteboardDto } from "@webapp/whiteboards/models/whiteboard.models";
import { WhiteboardsState } from "@webapp/whiteboards/services/whiteboards-state.service";
import { WhiteboardsApiService } from "./whiteboards-api.service";

@Injectable({
  providedIn: "any",
})
export class WhiteboardsFacade extends BaseFacade<Whiteboard, WhiteboardDto, WhiteboardsState, WhiteboardsApiService> {
  constructor(state: WhiteboardsState, api: WhiteboardsApiService) {
    super(state, api);
  }

  public createWhiteboard(payload: Partial<Whiteboard | WhiteboardDto>, config?: RequestConfig): Observable<Whiteboard> {
    return this.post$(payload, {
      url: this.apiService.postWhiteboardEndpoint(),
      ...config,
    });
  }

  public cloneWhiteboardWithId$(whiteboardId: string, config?: RequestConfig): Observable<Whiteboard> {
    return this.post$(
      {},
      {
        ...config,
        url: this.apiService.cloneWhiteboardEndpoint(whiteboardId),
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updatePicture$(whiteboard: Whiteboard, canvasDataUrl: string): Observable<unknown> {
    throw new Error("Method not implemented.");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public createWhiteboardFromTemplate$(whiteboardId: string): Observable<WhiteboardDTO> {
    throw new Error("Method not implemented.");
  }

  // TODO: Add payload WhiteboardTemplatePayload
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public createWhiteboardTemplate$(whiteboardId: string, payload): Observable<WhiteboardDTO> {
    throw new Error("Method not implemented.");
  }
}
