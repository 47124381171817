import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiSkeletonLineComponent } from "@quantive/ui-kit/skeleton";

@Component({
  selector: "strategy-ai-card-loading",
  templateUrl: "./strategy-ai-card-loading.component.html",
  styleUrls: ["./strategy-ai-card-loading.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiSkeletonLineComponent],
})
export class StrategyAiCardLoadingComponent {}
