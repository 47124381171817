import { Injectable } from "@angular/core";
import { storage } from "@gtmhub/core/storage";

@Injectable()
export class AccountStorageService {
  public getAccountSetting<T>(key: string): T {
    if (!key) {
      throw new Error("You must provide account setting key.");
    }

    const settingsStr = storage.get("accountSettings");
    if (settingsStr) {
      const settings = typeof settingsStr === "string" ? (JSON.parse(settingsStr) as Record<string, unknown>) : settingsStr;
      return settings[key] as T;
    }

    return null;
  }
}
