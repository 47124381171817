import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "header[actionable-header]",
  template: `
    <h3 class="sp-mb-44">{{ headerTitle }}</h3>
    <ng-content></ng-content>
  `,
  styleUrls: ["./actionable-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ActionableHeaderComponent {
  @Input({ required: true }) public headerTitle: string;
  @Input() public descriptionBlunt: boolean;
}
