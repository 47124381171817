import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { StrategicMapHypothesisVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiInputTextAreaComponent } from "@webapp/ui/input/components/input-text-area/input-text-area.component";

@Component({
  selector: "hypothesis-form",
  templateUrl: "./hypothesis-form.component.html",
  styleUrl: "./hypothesis-form.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, UiInputTextAreaComponent, UiButtonComponent, FeatureTogglesModule, UiFormModule],
})
export class HypothesisFormComponent {
  @Input() public hypothesis: StrategicMapHypothesisVM;
  @Input() public submitButtonText: string;

  @Output() private readonly hypothesisSubmit = new EventEmitter<Event>();
  @Output() private readonly hypothesisDelete = new EventEmitter<void>();
  @Output() private readonly hypothesisCancel = new EventEmitter<void>();

  public onSubmit(event: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.hypothesisSubmit.emit(event);
  }

  public onDelete(event: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.hypothesisDelete.emit();
  }

  public onCancel(event: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.hypothesisCancel.emit();
  }
}
