@if (icon) {
  @switch (errorType) {
    @case ("error") {
      <i class="text-color-gray-7" ui-icon uiSize="xl" uiType="caution"></i>
    }
    @case ("no_access") {
      <i class="text-color-gray-7" ui-icon uiSize="xl" uiType="restricted"></i>
    }
  }
}
<p class="sp-mb-0" *ngIf="!!descriptionString">{{ descriptionString }}</p>
<ng-container *ngIf="!!descriptionTemplateRef" [ngTemplateOutlet]="descriptionTemplateRef"></ng-container>
<button *ngIf="!!buttonLabel" (click)="onButtonClick()" ui-button uiShape="round" uiType="link">
  @if (errorType === "error" && showButtonIcon) {
    <i [uiType]="buttonIcon" ui-icon uiSize="sm"></i>
  }
  <span>{{ buttonLabel }}</span>
</button>
