import { Injectable } from "@angular/core";
import { Observable, concat, of, switchMap, take, tap } from "rxjs";
import { RestLayerRequest } from "@webapp/core/abstracts/models/rest-layer-request.models";
import { AsyncResponseDTOv2 } from "../../models/bets/strategic-bets.dto-v2-models";
import { AsyncResponseVM, StrategicBetVM, StrategicMapAreaVM, StrategicMapHypothesisVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategiesTrackingService } from "../utility/strategies-tracking.service";
import { StrategicBetsApiFactory } from "./api-utils/strategic-bets-api-factory.service";

@Injectable()
export class StrategicBetsService {
  constructor(
    private strategicBetsApiFactory: StrategicBetsApiFactory,
    private strategiesTrackingService: StrategiesTrackingService
  ) {}

  //  ------------- BET ------------- //

  public createBet$({ betName, documentIds }: { betName: string; documentIds?: string[] }): Observable<StrategicBetVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.createBet$({ betName, documentIds })));
  }

  public getBets$(request: RestLayerRequest<StrategicBetVM>): Observable<{ bets: StrategicBetVM[]; count: number }> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.getBets$(request)));
  }

  public getBet$(betId: string): Observable<StrategicBetVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.getBet$(betId)));
  }

  public leaveDecision$(betId: string): Observable<void> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.leaveDecision$(betId)));
  }

  public deleteBet$(betId: string): Observable<void> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.deleteBet$(betId)));
  }

  public updateBet$(bet: Partial<StrategicBetVM>): Observable<void> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.patchBet$(bet)));
  }
  public markStrategicMapAsGroomed(betId: string, hypothesisIdsForDeletion: { hId: string; aId: string }[], areasIdForDeletion: string[]): Observable<void> {
    return this.strategicBetsApiFactory.getApi().pipe(
      switchMap((api) => {
        return concat(
          ...hypothesisIdsForDeletion.map(({ hId, aId }) => api.deleteHypothesis$(betId, aId, hId).pipe(take(1))),
          ...areasIdForDeletion.map((aId) => api.deleteArea$(betId, aId).pipe(take(1))),
          api.patchBet$({ id: betId, progressSubStep: 1 }).pipe(take(1))
        );
      }),
      switchMap((response) => of(response))
    );
  }

  public updateCollaborators$(bet: StrategicBetVM, userIds: string[]): Observable<StrategicBetVM> {
    return this.strategicBetsApiFactory.getApi().pipe(
      switchMap((api) =>
        api.updateCollaborators$(bet.id, bet.access, userIds).pipe(
          tap(() => {
            this.strategiesTrackingService.trackCollaboratorsAdded(userIds);
          })
        )
      )
    );
  }

  public removeCollaborator$(bet: StrategicBetVM, id: string): Observable<StrategicBetVM> {
    return this.strategicBetsApiFactory.getApi().pipe(
      switchMap((api) =>
        api
          .updateCollaborators$(
            bet.id,
            {
              ...bet.access,
              permissions: bet.access.permissions.filter((p) => p.principalId !== id),
            },
            []
          )
          .pipe(
            tap(() => {
              this.strategiesTrackingService.trackCollaboratorRemoved(id);
            })
          )
      )
    );
  }

  //  ------------- STRATEGIC MAP ------------- //

  public getStrategicMap$(betId: string): Observable<StrategicBetVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.getStrategicMapForBet$(betId)));
  }

  public getStrategicMapAsync$(betId: string): Observable<AsyncResponseVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.getStrategicMapForBetAsync$(betId)));
  }

  //  ------------- HYPOTHESIS ------------- //

  public createHypothesis$(betId: string, areaId: string, hypothesisName: string): Observable<StrategicMapHypothesisVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.createHypothesis$(betId, areaId, hypothesisName)));
  }

  public updateHypothesis$(betId: string, areaId: string, hypotheses: Partial<StrategicMapHypothesisVM>): Observable<AsyncResponseDTOv2> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.updateHypothesis$(betId, areaId, hypotheses)));
  }

  public updateHypothesisSeen$(betId: string, areaId: string, hypothesisId: string, { isSeen }: { isSeen: boolean }): Observable<AsyncResponseDTOv2> {
    return this.strategicBetsApiFactory.getApi().pipe(
      switchMap((api) =>
        api.updateHypothesis$(betId, areaId, {
          id: hypothesisId,
          isSeen,
        })
      )
    );
  }

  public deleteHypothesis$(betId: string, areaId: string, hypothesisId: string): Observable<void> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.deleteHypothesis$(betId, areaId, hypothesisId)));
  }

  //  ------------- AREA ------------- //

  public createArea$(betId: string, areaTitle: string): Observable<AsyncResponseVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.createArea$(betId, areaTitle)));
  }

  public updateArea$(betId: string, area: Partial<StrategicMapAreaVM>): Observable<StrategicMapAreaVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.updateArea$(betId, area)));
  }

  public deleteArea$(betId: string, areaId: string): Observable<void> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.deleteArea$(betId, areaId)));
  }

  //  ------------- OKRs ------------- //

  public generateGoals$(betId: string): Observable<string> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.generateGoals$(betId)));
  }

  public generateGoalsAsync$(betId: string, options: { onePagerContentOnly?: boolean } = {}): Observable<AsyncResponseVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.generateGoalsAsync$(betId, options)));
  }

  public generateOnePager$(betId: string): Observable<AsyncResponseVM> {
    return this.strategicBetsApiFactory.getApi().pipe(switchMap((api) => api.generateOnePager$(betId)));
  }
}
