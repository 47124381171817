<ng-template [uiDrawerTitle]>
  <div class="drawer-title-wrapper">
    <h4 class="sp-m-0">Context</h4>
  </div>
</ng-template>
<ui-loading-indicator class="loading-container" [uiSpinning]="!context && !loadingFailed" uiTip="Loading context...">
  <message-box *ngIf="loadingFailed" [description]="loadingErrorText" (buttonAction)="getContext()" buttonIcon="refresh" buttonLabel="Try again" icon="caution" size="medium" />

  <article class="context-container" *ngIf="context">
    <section class="content-container" [ngClass]="{ loading: mode === 'saving' }">
      @if (showAlert) {
        <section class="alert">
          <alert-message
            (dismissAlert)="hideAlertInternal()"
            buttonLabel="Okay, got it"
            message="Upload files and provide Quantive Assistant additional context for better responses when you analysing hypothesis."
            type="info"></alert-message>
        </section>
      }
      <context-form
        class="content"
        [contextId]="context.id"
        [form]="contextForm"
        [loadingContext]="!context"
        (formSubmitted)="updateContext()"
        additionalContextDescription="For example: Specific details about the company or the goals you want to achieve.">
      </context-form>
      <span class="loader" *ngIf="mode === 'saving'"></span>
      <section class="actions">
        <section *ngIf="saveFailed">
          <alert-message (dismissAlert)="hideAlertError()" dismissType="x-button" message="Unable to save context. Please try again in a moment." type="error"> </alert-message>
        </section>
        <section class="buttons">
          <button [disabled]="mode === 'saving'" (click)="updateContext()" type="submit" ui-button uiShape="round" uiSize="large" uiType="primary">
            {{ mode === "saving" ? "Saving context..." : "Save context" }}
          </button>
          <button [disabled]="mode === 'saving'" (click)="closeForm()" ui-button uiShape="round" uiSize="large" uiType="default">Cancel</button>
        </section>
      </section>
    </section>
  </article>
</ui-loading-indicator>

<ng-template #loadingErrorText>
  <p class="loading-error sp-mb-0">
    Unable to load context. Please try again or
    <span class="loading-error-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
