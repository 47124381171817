import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";

@Component({
  standalone: true,
  selector: "whiteboard-generation",
  templateUrl: "./whiteboard-generation.component.html",
  styleUrls: ["./whiteboard-generation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiButtonModule, UiIconModule, UiLoadingIndicatorModule, CommonModule],
})
export class WhiteboardGenerationComponent {
  @Input() public generatedWhiteboardId: string;
  @Input() public backButtonCaption: string;
  @Output() public readonly navigateToWhiteboard = new EventEmitter<void>();
  @Output() public readonly stopOKRGeneration = new EventEmitter<void>();
}
