import { UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";

const WELCOME_WIDGET_HEIGHT = 520;

export const WIDGET_MARGIN = 10;

export const DEFAULT_WIDGET_HEIGHT = 368 + 2 * WIDGET_MARGIN;

export const KICKSTART_OKR_WIDGET_ID = "kickstart-okr";

export const VIEW_ALIGNMENT_WIDGET_ID = "view-alignment";

export const VIEW_FEED_WIDGET_ID = "view-feed";

const KICKSTART_OKR_WIDGET: UiDashboardWidget = {
  id: KICKSTART_OKR_WIDGET_ID,
  x: 0,
  y: 12,
  w: 2,
  sizeToContent: true,
  noShadow: true,
  selector: "kickstart-okr-widget",
  bgColor: "#e8ecf2",
};

const VIEW_ONLY_ROLE_WIDGET: UiDashboardWidget = {
  id: "view-only-role",
  x: 0,
  y: 0,
  w: 2,
  sizeToContent: true,
  noShadow: true,
  selector: "view-only-role-widget",
  bgColor: "#e8ecf2",
};

const WELCOME_PANEL_WIDGET: UiDashboardWidget = {
  id: "welcome-panel",
  x: 0,
  y: 1,
  w: 2,
  minH: WELCOME_WIDGET_HEIGHT,
  sizeToContent: true,
  noShadow: true,
  selector: "welcome-panel",
  bgColor: "#e8ecf2",
};

const VIEW_ONLY_WIDGETS: UiDashboardWidget[] = [
  {
    id: VIEW_ALIGNMENT_WIDGET_ID,
    x: 0,
    y: 10,
    w: 1,
    sizeToContent: DEFAULT_WIDGET_HEIGHT,
    minH: DEFAULT_WIDGET_HEIGHT,
    noShadow: true,
    selector: "view-alignment-widget",
    bgColor: "#e8ecf2",
  },
  {
    id: VIEW_FEED_WIDGET_ID,
    x: 1,
    y: 10,
    w: 1,
    sizeToContent: DEFAULT_WIDGET_HEIGHT,
    minH: DEFAULT_WIDGET_HEIGHT,
    noShadow: true,
    selector: "view-feed-widget",
    bgColor: "#e8ecf2",
  },
  KICKSTART_OKR_WIDGET,
  VIEW_ONLY_ROLE_WIDGET,
];

export const SUPPORTED_WIDGETS: UiDashboardWidget[] = [
  WELCOME_PANEL_WIDGET,
  { id: "my-okrs", x: 0, y: 2, w: 2, sizeToContent: true, noResize: true, selector: "my-okrs-widget", minW: 2 },
  { id: "check-ins", x: 0, y: 3, w: 1, minH: DEFAULT_WIDGET_HEIGHT, sizeToContent: DEFAULT_WIDGET_HEIGHT, selector: "check-ins-widget" },
  { id: "to-do", x: 1, y: 3, w: 1, minH: DEFAULT_WIDGET_HEIGHT, sizeToContent: DEFAULT_WIDGET_HEIGHT, selector: "todos-widget" },
  { id: "whiteboards", x: 0, y: 4, w: 2, sizeToContent: DEFAULT_WIDGET_HEIGHT, selector: "whiteboards-widget" },
  ...VIEW_ONLY_WIDGETS,
];
