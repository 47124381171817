import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class BetRemovedResponseStrategy implements QuantiveResultsSocketStrategy<"betRemoved"> {
  public messageType: QuantiveResultsSocketStrategy<"betRemoved">["messageType"] = "betRemoved";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"betRemoved">> {
    return pipe(filter(({ data }) => data.type === "strategic_bet_deleted"));
  }
}
