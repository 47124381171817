import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "dashboards" */ "./dashboards.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const dashboardFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.dashboards",
    abstract: true,
    url: "/dashboards",
    lazyLoad,
  },
  {
    name: "gtmhub.dashboards.list",
    url: "/list",
    lazyLoad,
  },
  {
    name: "gtmhub.dashboards.dashboard",
    url: "/:dashboardId",
    lazyLoad,
  },
];

export const states: Ng2StateDeclaration[] = [...dashboardFutureStates];
