import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { FileserverState } from "./fileserver-state.service";

@Injectable({ providedIn: "root" })
export class FileserverApiService extends BaseApiService<null, null, FileserverState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: FileserverState) {
    super("fileserver", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getMediaEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/media`;
  }

  public downloadFile$(fileSrc: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(fileSrc, { responseType: "blob", observe: "response" });
  }

  public deleteFile$(url: string): Observable<unknown> {
    return this.httpClient.delete(url);
  }

  public getUploadEndpoint(path: string): string {
    return `${this.getBasePath(HttpActions.post)}/${path}`;
  }

  public getUploadV2Endpoint(path: string): string {
    return `${this.getBasePath(HttpActions.post, { apiVersionOverwrite: "v2" })}/${path}`;
  }
}
