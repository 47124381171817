import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class SummarizationResponseStrategy implements QuantiveResultsSocketStrategy<"summarization"> {
  public messageType: QuantiveResultsSocketStrategy<"summarization">["messageType"] = "summarization";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"summarization">> {
    return pipe(filter(({ data }) => data.itemType === "document" && data.taskType === "summarization" && data.userId === userId.get()));
  }
}
