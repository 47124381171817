import { Injectable } from "@angular/core";
import { Observable, map, zip } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";

@Injectable()
export class SystemOptionsVisibilityService {
  constructor(
    private featureModuleService: FeatureModuleService,
    private permissionsFacade: PermissionsFacade,
    private editionFeatureService: EditionFeatureService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  get anyOption$(): Observable<boolean> {
    return zip(
      this.methodology$,
      this.tagManagement$,
      this.language$,
      this.remindersAndReport$,
      this.features$,
      this.customFields$,
      this.insightboardParameters$,
      this.automation$,
      this.accountNotifications$,
      this.homeWidgets$,
      this.vivagoals$
    ).pipe(
      map(
        ([
          methodology,
          tagManagement,
          language,
          remindersAndReport,
          features,
          customFields,
          insightboardParameters,
          automation,
          accountNotifications,
          homeWidgets,
          vivagoals,
        ]) => {
          return (
            methodology ||
            tagManagement ||
            language ||
            remindersAndReport ||
            features ||
            customFields ||
            insightboardParameters ||
            automation ||
            accountNotifications ||
            homeWidgets ||
            vivagoals
          );
        }
      )
    );
  }

  get methodology$(): Observable<boolean> {
    return zip(
      this.permissionsFacade.hasPermission$("ManageConfiguration"),
      this.editionFeatureService.hasFeature$("granular-methodology"),
      this.editionFeatureService.hasFeature$("custom-kr-confidence-levels"),
      this.editionFeatureService.hasFeature$("okrs.cap-progress")
    ).pipe(
      map(([manageConfigurationPermission, granularMethodologyFeature, customKrConfidenceFeature, okrsProgressFeature]) => {
        return manageConfigurationPermission && (granularMethodologyFeature || customKrConfidenceFeature || okrsProgressFeature);
      })
    );
  }

  get tagManagement$(): Observable<boolean> {
    return this.featureModuleService.isTagsModuleEnabled$();
  }

  get language$(): Observable<boolean> {
    return this.permissionsFacade.hasPermission$("ManageConfiguration");
  }

  get remindersAndReport$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("notifications.kr-reminders")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get features$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("modularity")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get customFields$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("okrs.custom-fields")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get insightboardParameters$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("setup.insights")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get automation$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageData"), this.editionFeatureService.hasFeature$("automation")).pipe(
      map(([manageDataPermission, featureAvailable]) => {
        return manageDataPermission && featureAvailable;
      })
    );
  }

  get homeWidgets$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageHomeWidgets"), this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.HomeWidget)).pipe(
      map(([manageHomeWidgetsPermission, featureAvailable]) => {
        return manageHomeWidgetsPermission && featureAvailable;
      })
    );
  }

  get accountNotifications$(): Observable<boolean> {
    return this.permissionsFacade.hasPermission$("ManageAccountNotifications");
  }

  get vivagoals$(): Observable<boolean> {
    return this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.VivaGoalsImportInQuantive);
  }
}
