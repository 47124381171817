<top-nav-bar [hideGlobalSearch]="true" [navTitle]="'decisions' | localize" />

<article>
  <h5>What can I help you decide on?</h5>
  <p class="create-decision-description">
    Are you looking for new markets to enter or a new product launch? Upload files such as company reports and analyses related to strategy, marketing, sales, finance etc. and ask away.
  </p>

  <form [formGroup]="formGroup" ui-form>
    <ui-form-item ui-row>
      <ui-form-control>
        <section class="text-area-section">
          <ui-input-text-area
            [isAutoresized]="true"
            [isBorderless]="true"
            [isManualResizeEnabled]="false"
            [textAreaClassName]="'sp-py-6 sp-pl-8'"
            [visibleRowCount]="1"
            e2eTestId="context"
            formControlName="context"
            uiPlaceholder="Message..."></ui-input-text-area>

          <div class="text-area-actions">
            @if (formGroupContext.length === 0) {
              <div class="text-area-button-group">
                <button (click)="setContextAndSubmit('What market should I go into?')" ui-button uiSize="default" uiType="default">
                  <i class="text-color-yellow-8" ui-icon uiTheme="outline" uiType="map"></i>
                  <span>{{ "what_market_should_i_go_into" | localize }}</span>
                </button>
                <button (click)="setContextAndSubmit('Should I launch a new product?')" ui-button uiSize="default" uiType="default">
                  <i class="text-color-purple-8" ui-icon uiTheme="outline" uiType="light-bulb"></i>
                  <span>{{ "should_i_launch_a_new_product" | localize }}</span>
                </button>
              </div>
            }

            <div class="empty-space-flex-1"></div>

            <upload-button [documentIds]="documentIds" (uploadedDocumentIds)="onUploadedDocumentIds($event)" />
          </div>
        </section>

        <p class="text-area-max-count">{{ formGroupContext.length }}/5000</p>
      </ui-form-control>
    </ui-form-item>
  </form>

  <p class="in-whiteboards">Your decisions will be saved in <a (click)="openWhiteboards()" href="javascript:void(0)">Whiteboards</a> for easy access and collaboration.</p>
</article>

<footer>
  <button [disabled]="!formGroupContext.length" (click)="onSubmit()" ui-button uiShape="round" uiSize="default" uiType="primary">Submit</button>
</footer>
