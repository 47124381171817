import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiSkeletonLineComponent } from "@quantive/ui-kit/skeleton";
import { StrategyAiCardLoadingComponent } from "../strategy-ai-card-loading/strategy-ai-card-loading.component";

@Component({
  selector: "strategy-ai-card-loading-list",
  templateUrl: "./strategy-ai-card-loading-list.component.html",
  styleUrls: ["./strategy-ai-card-loading-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, StrategyAiCardLoadingComponent, UiSkeletonLineComponent],
})
export class StrategyAiCardLoadingListComponent {}
