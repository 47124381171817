import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest, map } from "rxjs";
import { IReport } from "@gtmhub/reporting/models";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { InsightboardRepositoryService } from "@webapp/insightboards/services/insightboard/insightboard-repository.service";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import { NavigationReportsCacheService } from "@webapp/navigation/services/navigation-reports-cache.service";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { BaseSubNav } from "../services/base-sub-nav";

@UntilDestroy()
@Component({
  selector: "reports-sub-navigation-v2",
  templateUrl: "./reports-sub-navigation-v2.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsSubNavigationV2Component extends BaseSubNav implements OnInit {
  public options: SubNavOption[] = [
    {
      label: "dashboards",
      state: "gtmhub.dashboards.list",
      icon: "grid-view",
    },
    {
      label: "widgets",
      state: "gtmhub.widgets.list",
      icon: "insight",
    },
  ];

  public reportOptions: SubNavOption[] = [
    {
      label: "insightboards",
      state: "gtmhub.insightboards",
      icon: "insights",
    },
    {
      label: "lists",
      state: "gtmhub.lists",
      icon: "list-bullet",
    },
  ];

  public hideAllReports$: Observable<boolean>;

  constructor(
    private navigationReportsCache: NavigationReportsCacheService,
    private insightboardRepositoryService: InsightboardRepositoryService,
    private featureTogglesFacade: FeatureTogglesFacade,
    public permissionsFacade: PermissionsFacade,
    protected navigationItemsMediator: NavigationItemsMediator,
    private editionFeatureService: EditionFeatureService
  ) {
    super();
    this.setupEntityTypes(["dashboard"], "insightboards");
  }

  public ngOnInit(): void {
    this.setProcessAndPerformanceReports();

    this.hideAllReports$ = combineLatest([
      this.editionFeatureService.hasFeature$("customizable-report"),
      this.featureTogglesFacade.isFeatureAvailable$("hide-lists"),
    ]).pipe(
      map(([hasCustomizableReportFeature, hideListsFeatureFlag]) => {
        return !hasCustomizableReportFeature || hideListsFeatureFlag;
      }),
      untilDestroyed(this)
    );

    this.hideAllReports$.subscribe((enabled) => {
      if (!enabled) {
        super.ngOnInit();
      }
    });
  }

  private getReportId(reportName: string): string {
    if (!this.navigationReportsCache.get()) return null;
    const performanceReport = this.navigationReportsCache.get().find((report) => report.name.toLowerCase() === reportName);
    if (!performanceReport) return null;
    return performanceReport.id || null;
  }

  private setProcessAndPerformanceReports(): void {
    const performanceReportId = this.getReportId("performance report");
    const processReportId = this.getReportId("process report");
    if (performanceReportId && processReportId) {
      this.reportOptions.unshift(this.buildProcessReportOption(processReportId));
      this.reportOptions.unshift(this.buildPerformanceReportOption(performanceReportId));
    } else {
      this.insightboardRepositoryService.getReports$().subscribe((reports: IReport[]) => {
        this.navigationReportsCache.set(reports);
        const performanceReport = reports.find((report) => report.name.toLowerCase() === "performance report");
        const processReport = reports.find((report) => report.name.toLowerCase() === "process report");
        this.reportOptions.unshift(this.buildProcessReportOption(processReport.id));
        this.reportOptions.unshift(this.buildPerformanceReportOption(performanceReport.id));
      });
    }
  }

  private buildPerformanceReportOption(reportId: string): SubNavOption {
    return { label: "performance_report", state: "gtmhub.insightboard", icon: "performance", stateOptions: { dashboardId: reportId } };
  }

  private buildProcessReportOption(reportId: string): SubNavOption {
    return { label: "process_report", state: "gtmhub.insightboard", icon: "process", stateOptions: { dashboardId: reportId } };
  }
}
