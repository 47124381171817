<div class="sub-nav" id="sub-navigation" [attr.data-test-id]="'sub-nav-container'" [class.sub-nav__nav-expanded]="isNavExpanded">
  <div class="sub-nav__header">
    <h1 class="sub-nav__title" id="new-nav-sub-nav-title" data-test-id="new-nav-sub-nav-title">{{ activeScreen | localize }}</h1>
    <toggle-nav action="collapse" uiId="subnav-toggle"></toggle-nav>
  </div>
  <div class="sub-nav__billing-info-banner" *ngIf="accountIsInternal$ | async">
    <h2>{{ "internal_account" | localize }}</h2>
  </div>
  <div class="sub-nav__billing-upgrade-alert" *ngIf="isTrial && (showUpgrade$ | async)" [attr.data-test-id]="'remaining-trial-days-sub-navigation'">
    <ui-alert [uiMessage]="upgradeButton" uiType="info"></ui-alert>
    <ng-template #upgradeButton>
      <span class="fs-14px" id="upgrade-button-label" ui-row>{{ "your_current_plan_expires_in" | localize: { timeLeft: timeLeft } }}</span>
      <button class="sp-mt-10 sp-mb-11" id="upgrade-button" (click)="goToBilling()" aria-labelledby="upgrade-button-label upgrade-button" ui-button uiShape="round" uiSize="small" uiType="primary">
        <i ui-icon uiTheme="fill" uiType="upgrade"></i> {{ "upgrade" | localize }}
      </button>
    </ng-template>
  </div>
  <div class="sub-nav__container" [ngSwitch]="activeScreen">
    <home-sub-navigation *ngSwitchCase="'home'"></home-sub-navigation>
    <sessions-sub-navigation *ngSwitchCase="'okrs'"></sessions-sub-navigation>
    <kpis-sub-navigation *ngSwitchCase="'kpis'"></kpis-sub-navigation>
    <tasks-sub-navigation *ngSwitchCase="'tasks'"></tasks-sub-navigation>
    <whiteboards-sub-navigation *ngSwitchCase="'plans'"></whiteboards-sub-navigation>
    <ng-container *featureToggleDisabled="'new-insights'">
      <reports-sub-navigation *ngSwitchCase="'reports'"></reports-sub-navigation>
      <insightboards-sub-navigation *ngSwitchCase="'insightboards'"></insightboards-sub-navigation>
    </ng-container>
    <ng-container *featureToggleEnabled="'new-insights'">
      <reports-sub-navigation-v2 *ngSwitchCase="'reports'"></reports-sub-navigation-v2>
      <reports-sub-navigation-v2 *ngSwitchCase="'insightboards'"></reports-sub-navigation-v2>
    </ng-container>
    <people-sub-navigation *ngSwitchCase="'people'"></people-sub-navigation>
    <settings-sub-navigation *ngSwitchCase="'settings'"></settings-sub-navigation>
  </div>
  <div class="sub-nav__billing-info-upgrade-plan" *ngIf="showUpgrade$ | async">
    <button class="sub-nav__billing-btn" *ngIf="!isTrial" (click)="goToBilling()" role="link" ui-button uiShape="round" uiSize="default">
      {{ "upgrade_plan" | localize }}
    </button>
  </div>
</div>
