import { UIErrorMappingPerAction } from "../error-handling.model";

export type AreaAction = "delete";

export const AreaActionErrorMapIntercom: UIErrorMappingPerAction<AreaAction> = {
  delete: {
    title: "Delete area",
    intercomAction: "trying to delete an area",
  },
};
