import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { validateWhitespaces } from "@webapp/platform-intelligence/guided-okrs/utils";
import { UploadButtonComponent } from "@webapp/shared/upload-button/upload-button.component";
import { StrategicBetsService } from "@webapp/strategy/services/bet/strategic-bets.service";
import { TopNavBarModule } from "@webapp/top-nav-bar/top-nav-bar.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiInputTextAreaComponent } from "@webapp/ui/input/components/input-text-area/input-text-area.component";
import { DecisionTabStateService } from "../decision-v2/decision-tab-state.service";

@UntilDestroy()
@Component({
  selector: "decision-new-v2",
  templateUrl: "./decision-new-v2.component.html",
  styleUrls: ["./decision-new-v2.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TopNavBarModule,
    LocalizationModule,
    UiButtonModule,
    UploadButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    UiFormModule,
    UiInputTextAreaComponent,
    UiIconModule,
  ],
})
export class DecisionNewV2Component implements OnInit {
  public documentIds = [];
  public formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private strategicBetsService: StrategicBetsService,
    private decisionTabStateService: DecisionTabStateService
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      context: ["", [Validators.required, Validators.maxLength(5000), validateWhitespaces]],
    });
  }

  get formGroupContext(): string {
    return this.formGroup.get("context").value;
  }

  set formGroupContext(value: string) {
    this.formGroup.get("context").setValue(value);
  }

  public setContextAndSubmit(context: "Should I launch a new product?" | "What market should I go into?"): void {
    this.formGroupContext = context;
    this.onSubmit();
  }

  public onSubmit(): void {
    if (!this.formGroup.valid) return;

    this.strategicBetsService
      .createBet$({ betName: this.formGroupContext, documentIds: this.documentIds })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe((bet) => {
        this.decisionTabStateService.updateTabState("decision_cap");
        this.stateService.go("gtmhub.decisionV2", {
          decisionId: bet.id,
        });
      });
  }

  public onUploadedDocumentIds(documentIds: string[]): void {
    this.documentIds = documentIds;
  }

  public openWhiteboards(): void {
    this.stateService.go("gtmhub.whiteboards");
  }
}
