<section class="actions-panel">
  <h3>Get started with Quantive</h3>
  <section class="actions-cards">
    @for (actionCard of actionCards; track $index) {
      <action-card
        class="action-card"
        [buttonLabel]="actionCard.buttonLabel"
        [description]="actionCard.description"
        [image]="actionCard.image"
        [title]="actionCard.title"
        (actionButtonClicked)="handleActionButtonClicked(actionCard)">
      </action-card>
    }
  </section>
</section>
