import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { QuillEditorComponent, QuillModules } from "ngx-quill";
import Quill from "quill";
import "quill-mention";
import sanitize from "sanitize-html";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { Assignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { assigneeFromRedux } from "@webapp/assignees/utils/assignee.utils";
import { Gif, SizeOfGif } from "@webapp/shared/components/gifs/gif.models";
import { MentionBlot } from "@webapp/shared/rich-text-editor/blots/mention.blot";
import { MarkedJsService } from "@webapp/shared/rich-text-editor/marked-js/marked-js.service";
import { RichTextEditorIconEnum } from "@webapp/shared/rich-text-editor/rich-text-editor-icon/rich-text-editor-icon.enum";
import { convertMarkdownToRichText, stripMarkdownNewLinesAfterLineN } from "@webapp/shared/rich-text-editor/rich-text-editor.utils";
import { EmbeddedEmoji } from "../blots/embedded-emoji.blot";
import { OKRLinkBlot } from "../blots/okr-link.blot";
import { OkrLinkClickEvent } from "../events/okr-link.event";
import { WebappQuillTheme } from "../rich-text-editor.theme";

Quill.register("themes/ghQuillTheme", WebappQuillTheme, true);
Quill.register(MentionBlot, true);
Quill.register(EmbeddedEmoji, true);
Quill.register(OKRLinkBlot, true);

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "rich-text-viewer",
  templateUrl: "./rich-text-viewer.component.html",
  styleUrls: ["../rich-text-editor.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * @example <rich-text-viewer [text]="externalVariable"></rich-text-viewer>
 *
 * @param text string (default: "") - Text to be populated in the editor
 * @param gif Gif - Gif to be populated in the editor
 * @param textAlign string (default: "left") - text alignment in the editor
 */
export class RichTextViewerComponent implements OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() public text = "";
  @Input() public gif: Gif;
  @Input() public textAlign: "left" | "right" | "center" = "left";
  @Input() public truncateAfter: number;
  @Input() public truncateAfterRow: number;
  @Input() public fontSize: number;
  @Input() public additionalSanitizeOptions: sanitize.IOptions;
  @Output() public readonly okrLinkClick = new EventEmitter<OkrLinkClickEvent>();

  @ViewChild("richTextViewer") public element: ElementRef;

  public modules: QuillModules;
  public sizeOfGif = SizeOfGif;
  public isFocused = false;
  public mentionPopoverUser: Assignee | UnknownAssignee;
  public mentionElRef: ElementRef;
  public mentions: string[] = [];
  public icons = RichTextEditorIconEnum;
  public richText = "";

  @ViewChild(QuillEditorComponent, { static: true }) public editor: QuillEditorComponent;

  private removeMentionClickedListener: () => void;
  private removeOKRLinkAttachedListener: () => void;

  private markdownText = "";
  private truncatedAfter = false;

  constructor(
    private markedJsService: MarkedJsService,
    private renderer: Renderer2,
    private zone: NgZone
  ) {
    this.markedJsService.loadCustomExtensionsForRichTextEditor();

    this.setModules();
  }

  get multilineTruncateClass(): string {
    return this.truncateAfterRow ? `multiline-truncate-${this.truncateAfterRow}` : "";
  }

  get fontSizeClass(): string {
    return this.fontSize ? `fs-${this.fontSize}` : "";
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && !changes.text.currentValue) {
      this.setRichText("");

      return;
    }

    const isTextChanged =
      (changes.text?.firstChange || changes.text?.previousValue !== changes.text?.currentValue) &&
      !!changes.text?.currentValue &&
      changes.text?.currentValue !== this.markdownText;
    if (isTextChanged) {
      this.setRichText(changes.text.currentValue);
    }

    if (changes.truncateAfterRow) {
      this.setRichText(this.text);
    }
  }

  private setRichText(value: string): void {
    const strippedExcessRows = stripMarkdownNewLinesAfterLineN(value, this.truncateAfterRow);
    this.richText = convertMarkdownToRichText(strippedExcessRows);
  }

  public ngAfterViewInit(): void {
    this.removeMentionClickedListener = this.renderer.listen(this.element.nativeElement, "mention-clicked", (e: CustomEvent) => {
      this.mentionElRef = new ElementRef(e.detail.domNode);
      this.setMentionPopoverUser(e.detail.value.id);
    });

    this.attachOKRLinkListener();
    this.attachMentionListeners();
  }

  public ngAfterViewChecked(): void {
    if (!this.truncatedAfter && this.truncateAfter && this.editor?.quillEditor) {
      this.limitQuillText();
    }
  }

  public ngOnDestroy(): void {
    this.removeMentionClickedListener?.();
    this.removeOKRLinkAttachedListener?.();
  }

  public mentionPopoverClosed(): void {
    this.mentionPopoverUser = null;
    this.mentionElRef = null;
  }

  private setModules(): void {
    this.modules = {
      toolbar: false,
      mention: {
        blotName: "ghMention",
      },
      okrLink: {
        blotName: "okrLink",
      },
    };
  }

  private setMentionPopoverUser(userId: string): void {
    const state: IAssigneesStoreState = reduxStoreContainer.reduxStore.getState();
    this.mentionPopoverUser = assigneeFromRedux(state, userId);
  }

  private attachMentionListeners(): void {
    this.zone.runOutsideAngular(() => {
      this.removeMentionClickedListener = this.renderer.listen(this.element.nativeElement, "click", (event) => {
        const mentionElement = event.target?.closest(".mention");

        if (mentionElement) {
          this.zone.run(() => {
            this.mentionElRef = new ElementRef(mentionElement);
            this.setMentionPopoverUser(mentionElement.getAttribute("data-id"));
          });
        }
      });
    });
  }

  private attachOKRLinkListener(): void {
    this.zone.runOutsideAngular(() => {
      this.removeOKRLinkAttachedListener = this.renderer.listen(this.element.nativeElement, "okr-clicked", (event: OkrLinkClickEvent) => {
        this.okrLinkClick.emit(event);
      });
    });
  }

  private limitQuillText(): void {
    const maxLength = this.truncateAfter;
    const text = this.editor.quillEditor.getText().trim();

    if (text.length > maxLength) {
      this.editor.quillEditor.deleteText(maxLength, text.length);
      this.editor.quillEditor.insertText(maxLength, "...");
    }

    this.truncatedAfter = true;
  }
}
