import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, filter, forkJoin, map, mergeMap, take } from "rxjs";
import dayjs from "@webapp/shared/libs/dayjs";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import { DocumentVM } from "../../models/documents/documents.vm-models";
import { AsyncOperationPreviewState, StrategyContextDocument, StrategyContextDocumentState } from "../../models/strategy.vm-models";
import { AsyncTasksService } from "../async-tasks/async-tasks.service";
import { StrategyConversationContextService } from "../context/strategy-conversation-context.service";
import { getPreviewStateForDocument } from "../utility/strategy-conversation.utils";
import { StrategySocketsService } from "../web-sockets/services/strategy-sockets.service";
import { documentVM2EnrichedStrategyContextDocument, documentVM2StrategyContextDocument } from "./api-utils/document-mapper.utils";
import { DocumentsService } from "./documents.service";

@UntilDestroy()
@Injectable()
export class DocumentsMediatorService {
  private documents$ = new BehaviorSubject<StrategyContextDocument[] | null>(null);
  private documentsMessagesHistory: Record<string, StrategyContextDocumentState[]> = {};

  constructor(
    private documentsService: DocumentsService,
    private asyncTasks: AsyncTasksService,
    private contextService: StrategyConversationContextService,
    private strategySocketsService: StrategySocketsService,
    private strategyConversationContextService: StrategyConversationContextService
  ) {
    this.retrieveCurrentDocumentState();
    this.setupSocket();
  }

  public getContextAsyncPreviewState$(contextId: string): Observable<AsyncOperationPreviewState> {
    return this.getDocumentsForContext$(contextId).pipe(
      map((documents) => {
        const previewState: AsyncOperationPreviewState = { seen: true };
        const statesMap = this.getStatesMapForDocuments(documents);
        const tooltips = [];
        if (statesMap.loading > 0) {
          tooltips.push(`${statesMap.loading} file${statesMap.loading > 1 ? "s are" : " is"} in progress...`);
          previewState.state = "LOADING";
        }
        if (statesMap.loaded > 0) {
          tooltips.push(`${statesMap.loaded} file${statesMap.loaded > 1 ? "s are" : " is"} ready.`);
          previewState.state = "LOADED";
        }
        if (statesMap.errored > 0) {
          tooltips.push(`${statesMap.errored} file${statesMap.errored > 1 ? "s" : ""} have errors.`);
          previewState.state = "FAILED";
        }
        if (tooltips.length > 0) {
          previewState.seen = false;
          previewState.tooltip = tooltips.join("\n");
        }
        return previewState;
      })
    );
  }

  public getDocumentsForContext$(contextId: string): Observable<StrategyContextDocument[]> {
    return this.contextService.getBetContext$(contextId).pipe(
      mergeMap((context) => {
        return this.documents$.pipe(
          filter((value) => {
            return value !== null;
          }),
          map((documents) => {
            return documents.filter((d) => context.documentIds.includes(d.uid) || d.createdInContextId === contextId);
          }),
          map((documents) => {
            return documents.map((document) => ({
              ...document,
              asyncState: getPreviewStateForDocument(document, context),
            }));
          })
        );
      })
    );
  }

  public analyseFile(document: StrategyContextDocument): void {
    this.emitDocumentStateChange(document, "SUMMARIZING");
    this.documentsService
      .retryFileAnalysis$(document)
      .pipe(take(1))
      .subscribe({
        error: () => {
          this.emitDocumentStateChange(document, "FAILED_SUMMARIZATION");
        },
      });
  }

  public handleFileSelected(contextId: string, document: StrategyContextDocument): void {
    document.state = "UPLOADING";
    document.createdInContextId = contextId;
    const documentListFromState = this.documents$.value || [];
    const existingDocument = documentListFromState.find((d) => d.uid === document.uid);

    if (!existingDocument) {
      documentListFromState.push(document);
    } else {
      existingDocument.state = "UPLOADING";
    }

    this.emitAllDocumentsChange(documentListFromState);
    this.documentsService
      .uploadFile$(document.originalFile, contextId)
      .pipe(take(1))
      .subscribe({
        next: (documentId: string) => {
          const inMemoryDocument = this.documents$.value.find((document) => document.uid === documentId);
          const newState: StrategyContextDocumentState = this.getStateForDocumentId(documentId) || inMemoryDocument?.state || "UPLOADING";
          this.emitSingleDocumentChangeById(
            document.uid,
            (document) => ({
              ...document,
              state: newState,
              uid: documentId,
              createdInContextId: contextId,
            }),
            { deduplicateIds: true }
          );
        },
        error: () => {
          this.emitDocumentStateChange(document, "FAILED_UPLOAD");
        },
      });
  }

  private handleDocumentRemovedError(document: StrategyContextDocument): void {
    this.emitSingleDocumentChange(document, (document) => ({
      ...document,
      deletionState: "FAILED_DELETION",
    }));
  }

  public handleDocumentRemoved(contextId: string, document: StrategyContextDocument): void {
    this.contextService
      .getBetContext$(contextId)
      .pipe(take(1))
      .subscribe({
        next: (context) => {
          const filesToBePersisted = this.documents$.value.filter(
            (item) => item.state !== "FAILED_UPLOAD" && item.state !== "UPLOADING" && item.uid !== document.uid && context.documentIds.includes(item.uid)
          );
          this.emitSingleDocumentChange(document, (document) => ({
            ...document,
            deletionState: "DELETING",
          }));
          this.strategyConversationContextService
            .updateDocumentsForContext$(contextId, filesToBePersisted)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.emitAllDocumentsChange(this.documents$.value.filter((item) => item.uid !== document.uid));
              },
              error: () => this.handleDocumentRemovedError(document),
            });
        },
        error: () => this.handleDocumentRemovedError(document),
      });
  }

  public removeUploadError(document: StrategyContextDocument): void {
    this.emitAllDocumentsChange(this.documents$.value.filter((item) => item.uid !== document.uid));
  }

  public markContextAsSeen(contextId: string): void {
    this.contextService.markContextAsSeen$(contextId).subscribe();
  }

  private retrieveCurrentDocumentState(): void {
    forkJoin({
      documents: this.documentsService.getDocuments$({ limit: 0 }).pipe(take(1)),
      tasks: this.asyncTasks.getAllTasksForDocuments$().pipe(take(1)),
    }).subscribe(({ documents, tasks }) => {
      const tasksMap: Record<string, AsyncTaskVM[]> = tasks.reduce((acc, task) => {
        if (!acc[task.itemId]) {
          acc[task.itemId] = [];
        }
        acc[task.itemId].push(task);
        return acc;
      }, {});
      const documentList: StrategyContextDocument[] = documents.map((document: DocumentVM) => documentVM2EnrichedStrategyContextDocument(document, tasksMap));
      this.emitAllDocumentsChange(documentList);
    });
  }

  private setupSocket(): void {
    this.strategySocketsService
      .onMessage$("embedDocument")
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (message) => {
          const document = this.documents$.value.find((document) => document.uid === message.data.itemId);
          const documentHistory = this.documentsMessagesHistory[message.data.itemId] || [];
          if (document && document.state === "SUMMARIZED") return;
          if (message.data.status === "SUCCESS") {
            if (document) {
              document.flashing = false;
              this.emitDocumentStateChange(document, "SUMMARIZING");
            } else {
              documentHistory.push("SUMMARIZING");
            }
          } else if (message.data.status === "FAILURE") {
            if (document) {
              document.flashing = false;
              this.emitDocumentStateChange(document, "FAILED_UPLOAD");
            } else {
              documentHistory.push("FAILED_UPLOAD");
            }
          }
          this.documentsMessagesHistory[message.data.itemId] = documentHistory;
        },
        error: (message) => {
          const document = this.documents$.value.find((document) => document.uid === message.data.item_id);
          if (document && document.state === "SUMMARIZED") return;
          if (document) {
            this.emitDocumentStateChange(document, "FAILED_UPLOAD");
          } else {
            const documentHistory = this.documentsMessagesHistory[message.data.item_id] || [];
            documentHistory.push("FAILED_UPLOAD");
            this.documentsMessagesHistory[message.data.item_id] = documentHistory;
          }
        },
      });
    this.strategySocketsService
      .onMessage$("updateDocumentSummary")
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (message) => {
          if (message.data.status === "SUCCESS") {
            this.getStrategyContextDocumentById$(message.data.itemId).subscribe({
              next: (updatedDocument: StrategyContextDocument) => {
                this.registerDocumentStateChange(message.data.itemId, updatedDocument.state);
              },
              error: () => {
                this.registerDocumentStateChange(message.data.itemId, "FAILED_SUMMARIZATION");
              },
            });
          } else if (message.data.status === "FAILURE") {
            this.registerDocumentStateChange(message.data.itemId, "FAILED_SUMMARIZATION");
          }
        },
        error: (message) => {
          this.registerDocumentStateChange(message.data.itemId as string, "FAILED_SUMMARIZATION");
        },
      });
    this.strategySocketsService
      .onMessage$("summarization")
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (message) => {
          const updatedDocumentObject: Partial<StrategyContextDocument> = {
            summaryProcessingPercent: message.data.percentage,
          };
          if (message.data.percentage === 100) {
            updatedDocumentObject.state = "SUMMARIZED";
          }
          this.emitSingleDocumentChangeById(message.data.itemId, (document) => ({
            ...document,
            ...updatedDocumentObject,
          }));
        },
      });
  }

  private registerDocumentStateChange(documentId: string, state: StrategyContextDocumentState): void {
    const document = this.documents$.value.find((document) => document.uid === documentId);
    if (document) {
      this.emitDocumentStateChangeById(documentId, state);
    } else {
      const documentHistory = this.documentsMessagesHistory[documentId] || [];
      documentHistory.push(state);
      this.documentsMessagesHistory[documentId] = documentHistory;
    }
  }
  private emitSingleDocumentChange(
    document: StrategyContextDocument,
    transformer: (document: StrategyContextDocument) => StrategyContextDocument,
    options?: { deduplicateIds: boolean }
  ): void {
    const documentFromState = this.getDocumentFromState(document.uid);
    if (!documentFromState) return;
    const newDocumentsCollection = this.documents$.value.map((d) => (d.uid === document.uid ? transformer(documentFromState) : d));
    if (options?.deduplicateIds) {
      const documentsMap: Record<string, StrategyContextDocument> = {};
      for (let i = 0; i < newDocumentsCollection.length; i++) {
        documentsMap[newDocumentsCollection[i].uid] = newDocumentsCollection[i];
      }
      this.documents$.next(Object.values(documentsMap));
    } else {
      this.documents$.next(newDocumentsCollection);
    }
  }

  private emitSingleDocumentChangeById(
    uid: string,
    transformer: (document: StrategyContextDocument) => StrategyContextDocument,
    options?: { deduplicateIds: boolean }
  ): void {
    const documentFromState = this.getDocumentFromState(uid);
    if (!documentFromState) return;
    this.emitSingleDocumentChange(documentFromState, transformer, options);
  }

  private emitDocumentStateChange(document: StrategyContextDocument, newState: StrategyContextDocumentState): void {
    const FINAL_STATES: StrategyContextDocumentState[] = ["FAILED_SUMMARIZATION", "SUMMARIZED", "FAILED_UPLOAD"];
    const updateObject: Partial<StrategyContextDocument> = { state: newState };
    if (FINAL_STATES.includes(newState)) {
      updateObject.lastRelatedTaskDoneDate = dayjs.utc().toISOString();
    }
    this.emitSingleDocumentChange(document, (document) => ({
      ...document,
      ...updateObject,
    }));
  }

  private emitDocumentStateChangeById(documentId: string, newState: StrategyContextDocumentState): void {
    const FINAL_STATES: StrategyContextDocumentState[] = ["FAILED_SUMMARIZATION", "SUMMARIZED", "FAILED_UPLOAD"];
    const updateObject: Partial<StrategyContextDocument> = { state: newState };
    if (FINAL_STATES.includes(newState)) {
      updateObject.lastRelatedTaskDoneDate = dayjs.utc().toISOString();
    }
    this.emitSingleDocumentChangeById(documentId, (document) => ({
      ...document,
      ...updateObject,
    }));
  }

  private emitAllDocumentsChange(documents: StrategyContextDocument[]): void {
    this.documents$.next(documents);
  }

  private getDocumentFromState(uid: string): StrategyContextDocument {
    // we need to get the last document that was added to the state
    return this.documents$.value
      .slice()
      .reverse()
      .find((document) => document.uid === uid);
  }

  private getStrategyContextDocumentById$(documentId: string): Observable<StrategyContextDocument> {
    return this.documentsService.getDocumentById$(documentId).pipe(map((document: DocumentVM) => documentVM2StrategyContextDocument(document)));
  }

  private getStatesMapForDocuments(documents: StrategyContextDocument[]): {
    loading: number;
    loaded: number;
    errored: number;
  } {
    const statesMap = { loading: 0, loaded: 0, errored: 0 };
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].asyncState.seen) continue;
      switch (documents[i].asyncState.state) {
        case "LOADING":
          statesMap.loading++;
          break;
        case "LOADED":
          statesMap.loaded++;
          break;
        case "FAILED":
          statesMap.errored++;
          break;
      }
    }
    return statesMap;
  }

  private getStateForDocumentId(documentId: string): StrategyContextDocumentState | null {
    const documentStatesFromSocket = this.documentsMessagesHistory[documentId];
    if (!documentStatesFromSocket) return null;
    if (documentStatesFromSocket.includes("FAILED_SUMMARIZATION")) return "FAILED_SUMMARIZATION";
    if (documentStatesFromSocket.includes("SUMMARIZED")) return "SUMMARIZED";
    if (documentStatesFromSocket.includes("SUMMARIZING")) return "SUMMARIZING";
    if (documentStatesFromSocket.includes("FAILED_UPLOAD")) return "FAILED_UPLOAD";
    if (documentStatesFromSocket.includes("UPLOADING")) return "UPLOADING";
    return null;
  }
}
