import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, switchMap } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { CurrentUserAllowedActionsParamType } from "@webapp/permissions/models/permissions.model";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { IAllowedActionsMap } from "@webapp/sessions/models/sessions.model";
import { GranularTasksPermissionsService } from "./granular-tasks-permissions.service";

type NegateParam = { negate: boolean | undefined };

@Injectable({ providedIn: "root" })
export class TasksPermissionsService {
  constructor(
    private featureModuleService: FeatureModuleService,
    private editionFeatureService: EditionFeatureService,
    private granularTasksPermissionsService: GranularTasksPermissionsService,
    private permissionsFacade: PermissionsFacade
  ) {}

  private currentUserAllowedActionsParamToMap(currentUserAllowedActions: CurrentUserAllowedActionsParamType): IAllowedActionsMap {
    return Array.isArray(currentUserAllowedActions) ? currentUserAllowedActions.reduce((acc, action) => ((acc[action] = true), acc), {}) : currentUserAllowedActions;
  }

  public hasPermissionToView$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isTasksFeatureEnabledForAccount$(), this.granularTasksPermissionsService.hasPermissionToView$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }
  public hasPermissionToOwn$({ negate }: NegateParam = { negate: false }): Observable<boolean> {
    return combineLatest([this.isTasksFeatureEnabledForAccount$(), this.granularTasksPermissionsService.hasPermissionToOwn$()]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate }))
    );
  }

  public hasPermissionToCreateTaskInOKR$(
    params: { currentUserAllowedActions?: CurrentUserAllowedActionsParamType; negate?: boolean; parentId?: string } = {}
  ): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(params.currentUserAllowedActions);

    return combineLatest([
      this.isTasksFeatureEnabledForAccount$(),
      this.granularTasksPermissionsService.hasPermissionToCreate$(),
      of(params.currentUserAllowedActions ? currentUserAllowedActionsMap?.update || currentUserAllowedActionsMap?.create : true),
      params.parentId ? of(this.permissionsFacade.hasPermission$("AccessGoals")) : of(true),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate: params.negate }))
    );
  }

  public hasPermissionToCreate$(params: { currentUserAllowedActions?: CurrentUserAllowedActionsParamType; negate?: boolean } = {}): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(params.currentUserAllowedActions);

    return combineLatest([
      this.isTasksFeatureEnabledForAccount$(),
      this.granularTasksPermissionsService.hasPermissionToCreate$(),
      of(params.currentUserAllowedActions ? currentUserAllowedActionsMap?.create : true),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate: params.negate }))
    );
  }

  public hasPermissionToEdit$(params: { currentUserAllowedActions?: CurrentUserAllowedActionsParamType; negate?: boolean } = {}): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(params.currentUserAllowedActions);

    return combineLatest([
      this.isTasksFeatureEnabledForAccount$(),
      this.granularTasksPermissionsService.hasPermissionToEdit$(),
      of(params.currentUserAllowedActions ? currentUserAllowedActionsMap?.update : true),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate: params.negate }))
    );
  }

  public hasPermissionToUpdate$(params: { currentUserAllowedActions?: CurrentUserAllowedActionsParamType; negate?: boolean } = {}): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(params.currentUserAllowedActions);

    return combineLatest([
      this.isTasksFeatureEnabledForAccount$(),
      this.granularTasksPermissionsService.hasPermissionToUpdate$(),
      of(params.currentUserAllowedActions ? currentUserAllowedActionsMap?.update : true),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate: params.negate }))
    );
  }

  public hasPermissionToDelete$(params: { currentUserAllowedActions?: CurrentUserAllowedActionsParamType; negate?: boolean } = {}): Observable<boolean> {
    const currentUserAllowedActionsMap: IAllowedActionsMap = this.currentUserAllowedActionsParamToMap(params.currentUserAllowedActions);

    return combineLatest([
      this.isTasksFeatureEnabledForAccount$(),
      this.granularTasksPermissionsService.hasPermissionToDelete$(),
      of(params.currentUserAllowedActions ? currentUserAllowedActionsMap?.delete : true),
    ]).pipe(
      map((conditions) => conditions.every((condition) => condition)),
      switchMap((hasPermission) => this.negateIfNecessary$({ hasPermission, negate: params.negate }))
    );
  }

  private isTasksFeatureEnabledForAccount$(): Observable<boolean> {
    return combineLatest([this.featureModuleService.isTaskModuleEnabled$(), this.editionFeatureService.hasFeature$("hub.tasks")]).pipe(
      map((conditions) => conditions.every((condition) => condition))
    );
  }

  private negateIfNecessary$({ hasPermission, negate }: { hasPermission: boolean; negate: boolean }): Observable<boolean> {
    const shouldNegate = typeof negate !== "boolean" ? false : negate;
    return of(hasPermission !== shouldNegate);
  }
}
