import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  ChatSwotDataVM,
  StructuredChatBlockDisplayData,
  StructuredChatDataSection,
  StructuredChatDataSectionTextEntry,
  StructuredDataAspectVM,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";

@Component({
  selector: "ui-ai-chat-swot-block",
  templateUrl: "./ai-chat-swot-block.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatSwotBlockComponent implements OnInit {
  public swotSections: StructuredChatBlockDisplayData | undefined;
  @Input() public swot: ChatSwotDataVM | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const strengthsSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.swot?.strengths, "Strengths");
    const weaknessesSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.swot?.weaknesses, "Weaknesses");
    const opportunitiesSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.swot?.opportunity, "Opportunities");
    const threatsSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.swot?.threats, "Threats");
    this.swotSections = [strengthsSection, weaknessesSection, opportunitiesSection, threatsSection];
    this.cdr.markForCheck();
  }

  private transformChatDataAspectToSection(swotAspect: StructuredDataAspectVM[] | undefined, title: string): StructuredChatDataSection {
    return {
      title,
      entries: swotAspect ? (swotAspect as StructuredChatDataSectionTextEntry[]) : [],
    };
  }
}
