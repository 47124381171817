<ui-card
  [uiBordered]="false"
  [uiDescription]="isCustomHomeWidgetAvailable ? null : ('owned_by_me_and_my_teams' | localize)"
  [uiExtra]="extraHeaderContentTemplate"
  [uiHeadBorderless]="true"
  [uiRounded]="true"
  [uiTitle]="title">
  <ng-template #extraHeaderContentTemplate>
    <ng-container *ngIf="isTabSystem">
      <active-okrs-group-sort-dropdown
        *featureToggleEnabled="'my-okrs-group-sort-dropdown'"
        [group]="groupBy"
        [sort]="sortBy"
        [trackMeta]="{
          screen_name: 'Home Dashboard',
          widget: 'my_okrs',
        }"
        (groupChange)="handleGroupChange($event)"
        (sortChange)="handleSortChange($event)"></active-okrs-group-sort-dropdown>
    </ng-container>

    <widget-actions [widgetId]="widgetId"></widget-actions>
  </ng-template>

  <ng-container *featureToggleEnabled="'home-widget'">
    <ui-card-tab>
      <ui-tabset class="widget-tabs-container" [uiSelectedIndex]="currTabIdx" (uiSelectedIndexChange)="changeActiveTabIndex($event)">
        @for (tab of tabs; track $index) {
          <ui-tab [uiTitle]="tab.title | localize"></ui-tab>
        }
      </ui-tabset>
    </ui-card-tab>

    <div class="card-content">
      <assignee-active-okrs-list
        [assigneeId]="currentUserId"
        [groupBy]="groupBy"
        [showReport]="isTabSystem"
        [sortBy]="sortBy"
        [tabContext]="tabContext"
        [trackMeta]="{ widget: 'my_okrs', name: 'home_customizable' }"
        assigneeType="user"></assignee-active-okrs-list>
    </div>
  </ng-container>

  <ng-container *featureToggleDisabled="'home-widget'">
    <assignee-active-okrs-list
      [assigneeId]="currentUserId"
      [groupBy]="groupBy"
      [sortBy]="sortBy"
      [trackMeta]="{ widget: 'my_okrs', name: 'home_customizable' }"
      assigneeType="user"></assignee-active-okrs-list>
  </ng-container>
</ui-card>
