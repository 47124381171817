<section class="loading-block">
  <p>{{ currentLoadingMessage }}</p>
  @if (loadingPercentage < 100) {
    <div class="progress-loader">
      <div class="progress-loader-bar" [style.width]="loadingPercentage + '%'"></div>
    </div>
  } @else {
    <span class="infinite-loader"></span>
  }
</section>
