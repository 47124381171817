<ui-upload
  #uploadButton
  [uiAccept]="acceptedFileExtentions"
  [uiAcceptedMimeTypes]="acceptedMimeTypes"
  [uiDisabled]="documents.length >= filesLimit"
  [uiLimit]="filesLimit"
  (uiUploadFile)="onUploadDocument($event)" />

<section class="upload-instructions">
  <p>{{ "uploaded_files_are_analysed_for_insights" | localize }}</p>
  <p>{{ documents.length }}/{{ filesLimit }}</p>
</section>

@for (document of documents; track document.sourceKey) {
  <section class="document">
    @if (document.status === "failed") {
      <i class="fs-16px status-icon" ui-icon uiTheme="outline" uiType="caution"></i>

      <span class="failed">Unable to process {{ document.truncatedName }}. Please try again or <a class="ui-link" (click)="showIntercom()" href="javascript:void(0)">contact support</a>.</span>
    } @else {
      <i class="fs-16px status-icon" [class.processing]="document.status === 'uploading' || document.status === 'in_progress'" [uiType]="getIconTypeForDocument(document)" ui-icon uiTheme="fill"></i>

      @if (document.status === "uploading") {
        <span class="processing" [attr.id]="document.sourceKey" [uiTooltipTitle]="'(' + document.sourceName + ', ' + (document.sizeInBytes | fileSize) + ')'" tooltipIfOverflow="hover" ui-tooltip>
          Uploading ({{ document.sourceName }}, {{ document.sizeInBytes | fileSize }})
        </span>
      }

      @if (document.status === "in_progress") {
        <span class="processing" [attr.id]="document.sourceKey" [uiTooltipTitle]="'(' + document.sourceName + ', ' + (document.sizeInBytes | fileSize) + ')'" tooltipIfOverflow="hover" ui-tooltip>
          Processing ({{ document.sourceName }}, {{ document.sizeInBytes | fileSize }})
        </span>
      }

      @if (document.status === "completed") {
        <span>{{ document.sourceName }} ({{ document.sizeInBytes | fileSize }})</span>
      }
    }

    <div class="fl-1"></div>

    @if (document.status === "failed") {
      <button (click)="onTryAgain(document)" type="button" ui-button uiShape="round" uiSize="small" uiType="text">
        <i class="fs-14px delete-icon" ui-icon ui-tooltip uiShape="round" uiTheme="outline" uiTooltipPlacement="bottom" uiTooltipTitle="Try again" uiType="refresh"></i>
      </button>
    }

    @if (document.status !== "uploading" && document.status !== "in_progress") {
      <button (click)="onRemoveDocument(document)" type="button" ui-button ui-tooltip uiShape="round" uiSize="small" uiTooltipPlacement="bottom" uiTooltipTitle="Delete file" uiType="text">
        <i class="fs-14px" ui-icon uiTheme="outline" uiType="trash-bin"></i>
      </button>
    }

    <ui-loading-indicator [uiSpinning]="document.status === 'uploading' || document.status === 'in_progress'" uiSize="small" />
  </section>
}
