import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest, filter, map, switchMap } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { mapToString } from "@webapp/core/abstracts/decorators/route-param-to-input/route-param-to-input.adapters";
import { RouteParamInput } from "@webapp/core/abstracts/decorators/route-param-to-input/route-param-to-input.decorator";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { StrategicBetsService } from "@webapp/strategy/services/bet/strategic-bets.service";
import { StrategyConversationChatService } from "@webapp/strategy/services/chat/strategy-conversation-chat.service";
import { StrategyConversationContextService } from "@webapp/strategy/services/context/strategy-conversation-context.service";
import { QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";
import { StrategySocketsService } from "@webapp/strategy/services/web-sockets/services/strategy-sockets.service";
import { UiLoadingIndicatorComponent } from "@webapp/ui/loading-indicator/loading-indicator.component";
import { User } from "@webapp/users/models/users.models";
import { UsersFacade } from "@webapp/users/services/users-facade.service";
import { WhiteboardUpgradeDirective } from "@webapp/whiteboards/components/whiteboard-upgrade/whiteboard-upgrade.component";
import { DecisionTopNavBarComponent } from "../decision-top-nav-bar/decision-top-nav-bar.component";
import { DecisionViewComponent } from "../decision-view/decision-view.component";
import { DecisionTabStateService, DecisionTopNavBarTabKey } from "./decision-tab-state.service";

@UntilDestroy()
@Component({
  selector: "decision-v2",
  templateUrl: "./decision-v2.component.html",
  styleUrls: ["./decision-v2.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WhiteboardUpgradeDirective, DecisionTopNavBarComponent, DecisionViewComponent, UiLoadingIndicatorComponent],
})
export class DecisionV2Component implements OnInit {
  @Input()
  @RouteParamInput<"decisionId", string>({ adapter: mapToString })
  public decisionId: string;

  public whiteboardId: string;
  public contextId: string;
  public currentActiveTabKey: DecisionTopNavBarTabKey;
  public decisionTitle: string;
  public decisionDocumentIds: string[] = [];
  public conversationId: string;

  public usersMap: Record<string, User> = {};
  public loading: boolean;

  constructor(
    public injector: Injector,
    private cdr: ChangeDetectorRef,
    private strategicBetsService: StrategicBetsService,
    private usersFacade: UsersFacade,
    private strategySocketsService: StrategySocketsService,
    private chatService: StrategyConversationChatService,
    private strategyConversationContextService: StrategyConversationContextService,
    private decisionTabStateService: DecisionTabStateService,
    private stateService: StateService
  ) {}

  public ngOnInit(): void {
    this.loading = true;
    this.cdr.detectChanges();

    this.strategicBetsService
      .getBet$(this.decisionId)
      .pipe(
        takeOneUntilDestroyed(this),
        switchMap((bet) => {
          this.decisionTitle = bet.name;
          this.conversationId = bet.conversationId;
          this.contextId = bet.contextId;
          this.whiteboardId = bet.whiteboardId;

          const currentUserId: string = storage.get("userId");
          const principalIds = bet.access.permissions.map<string>((p) => p.principalId).filter(Boolean);
          const userIds: string[] = [...new Set([...principalIds, currentUserId])];

          return combineLatest([this.fetchUsersData(userIds), this.fetchContext(bet.contextId)]);
        })
      )
      .subscribe({
        next: () => {
          this.setupSockets();

          this.loading = false;
          this.cdr.detectChanges();
        },
      });

    this.decisionTabStateService
      .getTabState$()
      .pipe(untilDestroyed(this))
      .subscribe((tabKey) => {
        this.currentActiveTabKey = tabKey;
      });
  }

  public onUploadedDocumentIds(documentIds: string[]): void {
    this.strategyConversationContextService
      .patchBetContext$({
        id: this.contextId,
        documentIds: documentIds,
      })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => (this.decisionDocumentIds = documentIds));
  }

  private setupSockets(): void {
    this.strategySocketsService
      .onMessage$("askQuestion")
      .pipe(filter((message) => message.data.itemId === this.conversationId))
      .subscribe((message: QuantiveResultsSocketVM<"askQuestion">) => {
        if (message.data.status === "SUCCESS") {
          this.chatService.registerRegenerateLastChatEntryResponse(this.conversationId, message.data.additionalData.response);
        } else if (message.data.status === "FAILURE") {
          this.chatService.registerChatEntryResponse(this.conversationId, null);
        }
      });

    this.strategySocketsService
      .onMessage$("regenerateChatAnswer")
      .pipe(filter((message) => message.data.itemId === this.conversationId))
      .subscribe((message) => {
        if (message.data.status === "SUCCESS") {
          this.chatService.registerRegenerateLastChatEntryResponse(this.conversationId, message.data.additionalData.chatEntry);
        } else if (message.data.status === "FAILURE") {
          this.chatService.registerRegenerateLastChatEntryResponse(this.conversationId, null);
        }
      });
  }

  public onDeleteClicked(): void {
    this.strategicBetsService
      .deleteBet$(this.decisionId)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.stateService.go("gtmhub.whiteboards");
      });
  }

  private fetchUsersData(userIds: string[]): Observable<void> {
    return this.usersFacade
      .getUsersV2$({
        filter: { _id: { $in: userIds } },
        fields: ["firstName", "lastName", "picture", "language"],
      })
      .pipe(
        takeOneUntilDestroyed(this),
        map((currentUserData) => {
          currentUserData.items.forEach((user) => {
            this.usersMap[user.id] = user;
          });
        })
      );
  }

  private fetchContext(contextId: string): Observable<void> {
    return this.strategyConversationContextService.getBetContext$(contextId).pipe(
      takeOneUntilDestroyed(this),
      map((context) => {
        this.decisionDocumentIds = context.documentIds;
      })
    );
  }
}
