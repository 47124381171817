import { Injectable, OnDestroy } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, filter } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { BetLockVM } from "../../models/bets/strategic-bets.vm-models";
import { CollaborateSocketMessage, ItemLockedSocketMessage, ItemUnlockedSocketMessage } from "../web-sockets/models/socket-generic-strategy.models";
import { StrategyBetSocketService } from "../web-sockets/services/strategy-bet-socket.service";

@UntilDestroy()
@Injectable()
export class BetLockService implements OnDestroy {
  private clientsMap: Record<string, string> = {};
  private betLocks$: BehaviorSubject<BetLockVM | null> = new BehaviorSubject<BetLockVM>(null);
  private betId: string;
  private connectionId: string;

  constructor(private strategyBetSocketService: StrategyBetSocketService) {}

  public ngOnDestroy(): void {
    this.strategyBetSocketService.closeConnection();
  }

  public initConnection(betId: string): void {
    this.betId = betId;
    this.strategyBetSocketService.initConnection(betId);
    this.setupSockets();
  }

  public getLocks$(): Observable<BetLockVM> {
    return this.betLocks$.asObservable().pipe(filter((locks) => locks !== null));
  }

  public lockHypothesis(hypothesisId: string): void {
    this.strategyBetSocketService.sendSocketMessage({
      action: "itemLocked",
      clientId: userId.get(),
      connectionId: this.connectionId,
      payload: {
        type: "bet_hypotesis_lock",
        id: hypothesisId,
        metadata: {
          betId: this.betId,
        },
      },
    });
  }

  public unlockHypothesis(hypothesisId: string): void {
    this.strategyBetSocketService.sendSocketMessage({
      action: "itemUnlocked",
      clientId: userId.get(),
      connectionId: this.connectionId,
      payload: {
        type: "bet_hypotesis_unlock",
        id: hypothesisId,
      },
    });
  }

  private setupSockets(): void {
    this.strategyBetSocketService
      .onMessage$("collaborate")
      .pipe(untilDestroyed(this))
      .subscribe((message: CollaborateSocketMessage) => {
        this.clientsMap = {};
        if (message.clientId === userId.get()) {
          this.connectionId = message.connectionId;
        }
        message.payload.clients.forEach((client) => {
          this.clientsMap[client.connectionId] = client.clientId;
        });
        const locks = {};
        for (const key in message.payload.lockedItems) {
          locks[key] = this.clientsMap[message.payload.lockedItems[key]];
        }
        this.betLocks$.next(locks);
      });

    this.strategyBetSocketService
      .onMessage$("betHypothesisLock")
      .pipe(untilDestroyed(this))
      .subscribe((message: ItemLockedSocketMessage) => {
        if (message.payload.metadata.betId !== this.betId) {
          return;
        }
        const locks = this.betLocks$.value;
        locks[message.payload.id] = this.clientsMap[message.connectionId];
        this.betLocks$.next(locks);
      });

    this.strategyBetSocketService
      .onMessage$("betHypothesisUnlock")
      .pipe(untilDestroyed(this))
      .subscribe((message: ItemUnlockedSocketMessage) => {
        const locks = this.betLocks$.value;
        delete locks[message.payload.id];
        this.betLocks$.next(locks);
      });
  }
}
