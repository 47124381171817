import { AsyncTaskDTOv2 } from "@webapp/strategy/models/async-tasks/async-tasks.dto-v2-models";
import { AsyncTaskFiltersVM, AsyncTaskVM } from "@webapp/strategy/models/async-tasks/async-tasks.vm-models";

export const asyncTaskDTOv22VM = (task: AsyncTaskDTOv2): AsyncTaskVM => {
  const additionalData = task.result?.additionalData as Record<string, unknown>;
  return {
    id: task.id,
    result: task.result,
    status: task.status,
    name: task.name,
    item: task.item,
    itemId: task.itemId,
    dateDone: task.dateDone,
    message: task.result?.message,
    error: task.result?.error,
    startedAt: task.dateStarted,
    betId: additionalData?.betId as string,
    additionalData,
  };
};

const asyncTaskFilterV2Map: Record<AsyncTaskFiltersVM, string> = {
  name: "name",
  item: "item",
  itemId: "itemId",
  betId: "result.additionalData.betId",
  accountId: "accountId",
};

export const asyncTaskFilterV2Mapper = (filter: Record<AsyncTaskFiltersVM, unknown>): Record<string, unknown> => {
  return Object.entries(filter).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [asyncTaskFilterV2Map[key]]: value,
    };
  }, {});
};
