<top-nav-bar [navTitle]="'decisions' | localize" [topNavBarButtonsConfig]="topNavBarButtonsConfig" />

<article>
  <header
    class="sp-py-48"
    description="Leverage AI insights and guidance to make a strategic business decisions and generate an action plan."
    headerTitle="Make better strategic decisions faster"
    strategy-ai-header
    uiType="strategyai-develop"></header>

  <button class="make-decision" (click)="goToDetailedStrategy()" ui-button uiShape="round" uiSize="large" uiType="primary">
    <i ui-icon uiType="artificial-intelligence"></i>
    Make decision
  </button>

  <div class="inner-shadow" ui-scrollable-content>
    <div class="content">
      <div *ngIf="!hasError && betGroups.length">
        <section class="bets-list">
          <ng-template #template let-decision>
            <ng-template #name>
              <span [attr.id]="decision.id">
                {{ decision.title }}
              </span>
            </ng-template>
            <ng-template #dropdown>
              @if (isUserOwner(decision)) {
                <strategy-ai-dropdown>
                  <ul>
                    <li class="destructive" (click)="deleteBet(decision.id, decision.title)" role="presentation">
                      <i class="fs-16px" ui-icon uiType="trash-bin"></i>
                      <span>Delete decision</span>
                    </li>
                  </ul>
                </strategy-ai-dropdown>
              } @else {
                <strategy-ai-dropdown>
                  <ul>
                    <li class="destructive" (click)="leaveBet(decision)" role="presentation">
                      <i class="fs-16px" ui-icon uiType="export"></i>
                      <span>Leave Decision</span>
                    </li>
                  </ul>
                </strategy-ai-dropdown>
              }
            </ng-template>

            <strategy-ai-card [created]="decision.dateCreated" [dropdown]="dropdown" [name]="name" [ownerIds]="toCollaboratorId(decision.collaborators)" (handleClick)="onHandleClick(decision.id)">
            </strategy-ai-card>

            <section class="bet-async-status">
              <async-status [asyncPreviewState]="getBetStatus(decision.id) | async" [imageOnly]="false"></async-status>
            </section>
          </ng-template>

          <strategy-ai-list [groups]="betGroups" [templateRef]="template" [updatedItemId]="updatedBetId"> </strategy-ai-list>
          @if (isLoadMoreVisible && !loading) {
            <button class="load-more" (click)="loadMoreBets()" ui-button uiShape="round" uiType="link">Load More</button>
          }
        </section>
      </div>

      <div class="no-bets" *ngIf="!hasError && !betGroups.length && !loading">Past decisions will appear here.</div>

      <message-box *ngIf="hasError" [description]="deletionErrorText" (buttonAction)="fetchBets()" buttonIcon="refresh" buttonLabel="Try again" icon="caution" size="medium" />

      <strategy-ai-card-loading-list *ngIf="loading"></strategy-ai-card-loading-list>
    </div>
  </div>
</article>

<ng-template #deletionError>
  <p class="deletion-error sp-mb-0">
    Unable to delete decision. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
  <error-details *ngIf="uiError.transactionId" [transactionId]="uiError.transactionId"></error-details>
</ng-template>
<ng-template #decisionLeaveError>
  <p class="deletion-error sp-mb-0">
    Unable to leave decision. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
  <error-details *ngIf="uiError.transactionId" [transactionId]="uiError.transactionId"></error-details>
</ng-template>
<ng-template #deletionErrorText>
  <p class="deletion-error sp-mb-0">
    Unable to load past decisions. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
