import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";

@Injectable()
export class IngestionApiService extends BaseApiServiceV2<{ id: string }, RequestPaging> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("ingestion", { ...new ApiVersionModel("v1") }, httpClient, appConfig);
  }

  public uploadDocument$(file: File): Observable<string[]> {
    const formData = new FormData();
    formData.append("files", file, file.name);
    return this.post$(formData as unknown, { url: this.createDocumentEndpoint() });
  }

  public getStatusEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/status`;
  }

  private createDocumentEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/documents`;
  }
}
