import { IHttpService, IPromise, IQService } from "angular";
import { AccountType } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { INgRedux } from "@gtmhub/state-management/ng-redux";
import { IPermissionsStoreState, hasPermissions } from "@gtmhub/users/redux";
import { AccountResolverService } from "@webapp/accounts";
import { IPlan } from "../models";

export class PricingEditionService {
  static $inject = ["$http", "$q", "EnvironmentService", "$ngRedux", "AccountResolverService"];

  constructor(
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService,
    private $ngRedux: INgRedux,
    private accountResolverService: AccountResolverService
  ) {}

  getAllowedTransitions(accountType: AccountType, options?: { skipPermissionCheck: boolean }): IPromise<IPlan[]> {
    const isAccountNotTrialOrClientOrFree =
      accountType !== AccountType.TrialAccount && accountType !== AccountType.ClientAccount && accountType !== AccountType.FreeAccount;
    if (options && options.skipPermissionCheck) {
      if (isAccountNotTrialOrClientOrFree) {
        return this.$q.resolve([]);
      }
    } else {
      const isMissingManageBilling = !hasPermissions(this.$ngRedux.getState<IPermissionsStoreState>(), "ManageBilling");

      if (isMissingManageBilling || isAccountNotTrialOrClientOrFree) {
        return this.$q.resolve([]);
      }
    }

    const url = this.env.getApiEndpoint("/subscriptions/allowed-transitions");

    return this.$http.get<IPlan[]>(url).then((response) => {
      const accountData = this.accountResolverService.getAccountData();
      let currentWeight: number;

      const currentPlan = response.data.find((plan) => accountData.edition.name === plan.editionName);

      if (currentPlan) {
        currentWeight = currentPlan.weight;
      }

      const higherWeightPlanExists = response.data.some((plan) => plan.weight > currentWeight);

      if (accountType !== AccountType.TrialAccount && !higherWeightPlanExists) {
        return [];
      }

      return response.data;
    });
  }
}
