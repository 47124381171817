<!-- when route provided - use it, when route missing emit click event for custom control -->
<!-- bring back routerLink -->
<a (click)="!route ? handleClick.next() : null">
  <ng-container *ngIf="isIconTemplateRef">
    <ng-container *ngTemplateOutlet="iconTemplateRef"> </ng-container>
  </ng-container>

  <ng-container *ngIf="!isIconTemplateRef">
    <i class="fs-16px" *ngIf="icon" [uiType]="iconAsString" ui-icon uiTheme="fill"> </i>
  </ng-container>

  <span class="name" [uiTooltipTitle]="name" tooltipIfOverflow="hover" ui-tooltip>
    <ng-container *ngIf="isTemplateRef">
      <ng-container *ngTemplateOutlet="templateRef"> </ng-container>
    </ng-container>

    <ng-container *ngIf="!isTemplateRef">{{ name }}</ng-container>
  </span>
  <span class="date">
    {{ created | customDaysAgoPipe }}
  </span>

  <ui-assignee-avatar-group [uiMax]="3" uiSize="sm">
    @for (ownerId of ownerIds; track ownerId) {
      <ui-assignee-avatar [fromAssigneeId]="ownerId" uiSize="sm"> </ui-assignee-avatar>
    }
  </ui-assignee-avatar-group>
</a>

<ng-container *ngTemplateOutlet="dropdown"></ng-container>
