import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ResultsBoxLabelComponent } from "../results-box-label/results-box-label.component";

@Component({
  selector: "results-box-labels-list",
  templateUrl: "./results-box-labels-list.component.html",
  styleUrls: ["./results-box-labels-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, ResultsBoxLabelComponent],
})
export class ResultsBoxLabelsListComponent {
  @Input() public labels: string[];
}
