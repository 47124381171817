import { UIErrorMappingPerAction } from "../error-handling.model";

export type HypothesisAction = "delete";

export const HypothesisActionErrorMapIntercom: UIErrorMappingPerAction<HypothesisAction> = {
  delete: {
    title: "Delete hypothesis",
    intercomAction: "trying to delete a hypothesis",
  },
};
