import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "percent",
  pure: true,
  standalone: true,
})
export class PercentagePipe implements PipeTransform {
  public transform(value: number): string {
    if (value !== null && value !== undefined && !isNaN(value) && value >= 0 && value <= 100) {
      return `${Math.round(value)}%`;
    }
    return "";
  }
}
