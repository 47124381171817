import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule, UiThemeType } from "@quantive/ui-kit/icon";

@Component({
  selector: "decision-type-box",
  templateUrl: "./decision-type-box.component.html",
  styleUrls: ["./decision-type-box.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule, UiButtonComponent],
})
export class DecisionTypeBoxComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public iconType: string;
  @Input() public iconTheme: UiThemeType;
  @Input() public navigationLabel: string;
  @Output() public readonly navigate = new EventEmitter();
}
