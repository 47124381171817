import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { CONFIRM_DELETE_STRATEGY_ITEM_WARNING_MESSAGES_MAP } from "@webapp/strategy/models/strategy.constants";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

type StrategyItemType = keyof typeof CONFIRM_DELETE_STRATEGY_ITEM_WARNING_MESSAGES_MAP;

type ConfirmDeleteStrategyItemWarningMessage = (typeof CONFIRM_DELETE_STRATEGY_ITEM_WARNING_MESSAGES_MAP)[keyof typeof CONFIRM_DELETE_STRATEGY_ITEM_WARNING_MESSAGES_MAP];

export type ConfirmDeleteStrategyItemModalData = {
  strategyItemName: string;
  strategyItemType: StrategyItemType;
};

@Component({
  templateUrl: "./confirm-delete-strategy-item.component.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDeleteStrategyItemComponent {
  public itemName: string;
  public warningMessage: ConfirmDeleteStrategyItemWarningMessage;

  constructor(@Inject(UI_MODAL_DATA) modalData: ConfirmDeleteStrategyItemModalData) {
    this.itemName = modalData.strategyItemName;
    this.warningMessage = CONFIRM_DELETE_STRATEGY_ITEM_WARNING_MESSAGES_MAP[modalData.strategyItemType];
  }
}
