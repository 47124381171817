import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class BetContributorsModifiedResponseStrategy implements QuantiveResultsSocketStrategy<"betContributorsModified"> {
  public messageType: QuantiveResultsSocketStrategy<"betContributorsModified">["messageType"] = "betContributorsModified";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"betContributorsModified">> {
    return pipe(filter(({ data }) => data.type === "bet_contributors_modified"));
  }
}
