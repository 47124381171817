import { NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";

@Component({
  selector: "actionable-empty-state",
  templateUrl: "./actionable-empty-state.component.html",
  styleUrl: "./actionable-empty-state.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule, UiButtonComponent, NgClass, NgStyle],
})
export class ActionableEmptyStateComponent {
  @Input() public buttonText: string;
  @Input() public cardText: string;
  @Input() public paddingStyle: string;
  @Input() public iconType: string;
  @Input() public buttonOnly: boolean = false;

  @Output() public readonly action = new EventEmitter<void>();
}
