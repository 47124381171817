import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { RestLayerRequest } from "@webapp/core/abstracts/models/rest-layer-request.models";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { ICollection } from "@webapp/core/core.models";
import { AsyncResponseDTOv2, GenerateOKRsResponseDTOv2, StrategicBetDTOv2, StrategicMapAreaDTOv2 } from "@webapp/strategy/models/bets/strategic-bets.dto-v2-models";
import { AsyncResponseVM, BetLockVM, StrategicBetVM, StrategicMapAreaVM, StrategicMapHypothesisVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { VALIDATION_STATUS_NOT_STARTED } from "@webapp/strategy/models/strategy.constants";
import { AccessDefinitionVM } from "@webapp/strategy/models/users.models";
import { IStrategicBetsApi } from "../strategic-bets-api.interface";
import {
  asyncResponseDTOv22VM,
  strategicBetDTOv22VM,
  strategicBetVM2DTOv2,
  strategicMapAreaDTOv22VM,
  strategicMapHypothesisPatchVM2DTOv2,
  strategicMapPatchAreaVM2DTOv2,
} from "./strategic-bets-mapper-v2.utils";
import { StrategicBetsUrlBuilderV2Service } from "./strategic-bets-url-builder-v2.service";

@Injectable()
export class StrategicBetsApiV2Service extends PlainBaseApiService implements IStrategicBetsApi {
  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService,
    private strategicBetUrlBuilderV2Service: StrategicBetsUrlBuilderV2Service
  ) {
    super(httpClient, apiEndpointService);
  }
  public lockHypothesis$(betId: string, hypothesisId: string): Observable<void> {
    throw new Error("Method not implemented. " + betId + " " + hypothesisId);
  }

  public leaveDecision$(betId: string): Observable<void> {
    const betsUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(betId);
    return this.delete$<void>(`${betsUrl}/contributors`);
  }

  public unlockHypothesis$(betId: string, hypothesisId: string): Observable<void> {
    throw new Error("Method not implemented. " + betId + " " + hypothesisId);
  }

  public getLock$(): Observable<BetLockVM> {
    throw new Error("Method not implemented.");
  }

  public createBet$({ betName, documentIds }: { betName: string; documentIds: string[] }): Observable<StrategicBetVM> {
    const betsUrl = this.strategicBetUrlBuilderV2Service.getBetsUrl();
    return this.post$<StrategicBetDTOv2>(betsUrl, { name: betName, documentIds }).pipe(map((bet: StrategicBetDTOv2) => strategicBetDTOv22VM(bet)));
  }

  public updateCollaborators$(betId: string, access: AccessDefinitionVM, collaborators: string[]): Observable<StrategicBetVM> {
    const betUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(betId);
    const oldPermissions = access.permissions.filter((permission) => !collaborators.includes(permission.principalId));
    const newPermissions = collaborators.map((collaboratorId) => ({
      grant: {
        general: ["read", "update"],
      },
      principalId: collaboratorId,
      principalKind: "user",
    }));
    return this.patch$<StrategicBetDTOv2>(`${betUrl}`, {
      access: {
        permissions: [...oldPermissions, ...newPermissions],
      },
    }).pipe(map((bet: StrategicBetDTOv2) => strategicBetDTOv22VM(bet)));
  }

  public getBets$(request: RestLayerRequest<StrategicBetVM>): Observable<{ bets: StrategicBetVM[]; count: number }> {
    const betsUrl = this.strategicBetUrlBuilderV2Service.getBetsUrl();

    return this.getAll$<ICollection<StrategicBetDTOv2>>(betsUrl, request).pipe(
      map((collection: ICollection<StrategicBetDTOv2>) => {
        const bets = collection.items.map((bet) => strategicBetDTOv22VM(bet));
        return { bets, count: collection.totalCount };
      })
    );
  }

  public getBet$(betId: string): Observable<StrategicBetVM> {
    const betUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(betId);
    return this.get$<StrategicBetDTOv2>(betUrl).pipe(map((bet: StrategicBetDTOv2) => strategicBetDTOv22VM(bet)));
  }

  public patchBet$({ id, ...bet }: Partial<StrategicBetVM>): Observable<void> {
    const betUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(id);
    return this.patch$<void>(betUrl, strategicBetVM2DTOv2(bet));
  }

  public deleteBet$(betId: string): Observable<void> {
    const betUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(betId);
    return this.delete$(betUrl);
  }

  public getStrategicMapForBet$(betId: string): Observable<StrategicBetVM> {
    const strategicMapUrl = this.strategicBetUrlBuilderV2Service.getStrategicMapUrl(betId);
    return this.get$<StrategicBetDTOv2>(strategicMapUrl).pipe(map((bet: StrategicBetDTOv2) => strategicBetDTOv22VM(bet)));
  }

  public getStrategicMapForBetAsync$(betId: string): Observable<AsyncResponseVM> {
    const strategicMapUrl = this.strategicBetUrlBuilderV2Service.getStrategicMapAsyncUrl(betId);
    return this.post$<AsyncResponseDTOv2>(strategicMapUrl, {}).pipe(map((response: AsyncResponseDTOv2) => asyncResponseDTOv22VM(response)));
  }

  public createHypothesis$(betId: string, areaId: string, hypothesisName: string): Observable<StrategicMapHypothesisVM> {
    const hypothesesUrl = this.strategicBetUrlBuilderV2Service.getHypothesesUrl(betId, areaId);
    return this.post$<StrategicBetDTOv2>(hypothesesUrl, {
      statement: hypothesisName,
      status: VALIDATION_STATUS_NOT_STARTED,
      description: "",
    }).pipe(
      map((bet: StrategicBetDTOv2) => {
        const betVM = strategicBetDTOv22VM(bet);
        const area = betVM.strategicMap.areas.find((a) => a.id === areaId);
        return area.hypotheses.find((h) => h.title === hypothesisName);
      })
    );
  }

  public updateHypothesis$(betId: string, areaId: string, hypothesis: Partial<StrategicMapHypothesisVM>): Observable<AsyncResponseDTOv2> {
    const hypothesisUrl = this.strategicBetUrlBuilderV2Service.getHypothesisUrl(betId, areaId, hypothesis.id);

    return this.patch$<AsyncResponseDTOv2>(hypothesisUrl, {
      ...strategicMapHypothesisPatchVM2DTOv2(hypothesis),
      async: true,
    });
  }

  public createArea$(betId: string, areaTitle: string): Observable<AsyncResponseVM> {
    const areaAsyncUrl = this.strategicBetUrlBuilderV2Service.getAreasUrl(betId);
    return this.post$<AsyncResponseDTOv2>(areaAsyncUrl, {
      title: areaTitle,
    }).pipe(map((asyncRepsonse: AsyncResponseDTOv2) => asyncResponseDTOv22VM(asyncRepsonse)));
  }
  public updateArea$(betId: string, area: Partial<StrategicMapAreaVM>): Observable<StrategicMapAreaVM> {
    const areaUrl = this.strategicBetUrlBuilderV2Service.getAreaUrl(betId, area.id);
    return this.patch$<StrategicMapAreaDTOv2>(areaUrl, strategicMapPatchAreaVM2DTOv2(area)).pipe(
      map((areaDTO: StrategicMapAreaDTOv2) => strategicMapAreaDTOv22VM(areaDTO))
    );
  }

  public deleteHypothesis$(betId: string, areaId: string, hypothesisId: string): Observable<void> {
    const hypothesisUrl = this.strategicBetUrlBuilderV2Service.getHypothesisUrl(betId, areaId, hypothesisId);
    return this.delete$(hypothesisUrl);
  }

  public deleteArea$(betId: string, areaId: string): Observable<void> {
    const hypothesisUrl = this.strategicBetUrlBuilderV2Service.getAreaUrl(betId, areaId);
    return this.delete$(hypothesisUrl);
  }

  public generateGoals$(betId: string): Observable<string> {
    const betUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(betId);
    return this.post$<GenerateOKRsResponseDTOv2>(`${betUrl}/generate-goals`, {}).pipe(map((response: GenerateOKRsResponseDTOv2) => response.whiteboardId));
  }

  public generateGoalsAsync$(betId: string, { onePagerContentOnly }: { onePagerContentOnly?: boolean } = {}): Observable<AsyncResponseVM> {
    const betUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(betId);
    return this.post$<AsyncResponseVM>(`${betUrl}/generate-goals/async`, {}, { queryParams: onePagerContentOnly ? { onePagerContentOnly } : {} });
  }

  public generateOnePager$(betId: string): Observable<AsyncResponseVM> {
    const betUrl = this.strategicBetUrlBuilderV2Service.getBetUrl(betId);
    return this.post$<AsyncResponseVM>(`${betUrl}/generate-one-pager`, {});
  }
}
