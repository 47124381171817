<section class="section-loading-skeleton">
  <h3>
    <ui-skeleton-line [style]="{ height: '22px', width: '20%' }" [uiActive]="true" />
  </h3>

  <ul>
    <li *ngFor="let _ of [0, 1, 2, 3, 4]">
      <strategy-ai-card-loading />
    </li>
  </ul>
</section>
