import dayjs from "@webapp/shared/libs/dayjs";

export const getStrategyAiGroup = (isoDateString: string): string => {
  const today = dayjs();
  const date = dayjs(isoDateString);

  if (date.isSame(today, "day")) {
    return "Today";
  }

  if (date.isSame(today.subtract(1, "day"), "day")) {
    return "Yesterday";
  }

  if (date.isAfter(today.subtract(7, "day").startOf("day"))) {
    return "Last 7 days";
  }

  if (date.isAfter(today.subtract(30, "day").startOf("day"))) {
    return "Last 30 days";
  }

  return date.format("MMMM YYYY");
};
