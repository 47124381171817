import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, catchError, forkJoin, map, of, switchMap } from "rxjs";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import { StrategicBetVM } from "../../models/bets/strategic-bets.vm-models";
import { ContextVM } from "../../models/context/context.vm-models";
import { DocumentVM } from "../../models/documents/documents.vm-models";
import { AsyncOperationPreviewState } from "../../models/strategy.vm-models";
import { AsyncTasksService } from "../async-tasks/async-tasks.service";
import { StrategyConversationContextService } from "../context/strategy-conversation-context.service";
import { DocumentsService } from "../documents/documents.service";
import { getBetTasksAsyncState } from "../utility/async-tasks.utils";
import { StrategySocketsService } from "../web-sockets/services/strategy-sockets.service";
import { BetTasksStateService } from "./bet-tasks-state.service";
import { StrategicBetsService } from "./strategic-bets.service";

@UntilDestroy()
@Injectable()
export class BetMediatorService {
  constructor(
    private documentsService: DocumentsService,
    private betTasksStateService: BetTasksStateService,
    private socketService: StrategySocketsService,
    private strategicBetsService: StrategicBetsService,
    private asyncTasksService: AsyncTasksService,
    private strategyConversationContextService: StrategyConversationContextService
  ) {
    this.setupSocketListeners();
  }

  private getAsyncTasksForBetIds$(betIds: string[]): Observable<AsyncTaskVM[]> {
    return this.asyncTasksService.getTasksForBetIds$(betIds, ["ask_question", "regenerate_chat_answer", "create_strategic_map"]);
  }

  private getDataForBetId$(betId: string): Observable<{
    bet: StrategicBetVM;
    betTasks: AsyncTaskVM[];
    documentTasks: AsyncTaskVM[];
    context: ContextVM;
    documents: DocumentVM[];
  }> {
    return this.strategicBetsService.getBet$(betId).pipe(
      switchMap((bet) => {
        return forkJoin({
          betTasks: this.getAsyncTasksForBetIds$([betId]).pipe(takeOneUntilDestroyed(this)),
          context: this.strategyConversationContextService.getBetContext$(betId).pipe(
            takeOneUntilDestroyed(this),
            // No context associated with the betId
            catchError(() => {
              return of(null);
            })
          ),
        }).pipe(
          map(({ betTasks, context }) => {
            return {
              bet,
              betTasks,
              context,
            };
          })
        );
      }),

      switchMap(({ bet, betTasks, context }) => {
        if (!context) {
          return of({
            bet,
            betTasks,
            context,
            documents: [],
            documentTasks: [],
          });
        }

        return forkJoin({
          documentTasks: this.asyncTasksService.getTasksForDocuments$(context.documentIds).pipe(takeOneUntilDestroyed(this)),
          documents: this.documentsService
            .getDocuments$({
              filter: { _id: { $in: context.documentIds } },
            })
            .pipe(takeOneUntilDestroyed(this)),
        }).pipe(
          map(({ documentTasks, documents }) => ({
            bet,
            betTasks,
            documentTasks,
            context,
            documents,
          }))
        );
      })
    );
  }

  private getDataFromContextId$(contextId: string): Observable<{
    bet: StrategicBetVM;
    betTasks: AsyncTaskVM[];
    documentTasks: AsyncTaskVM[];
    context: ContextVM;
    documents: DocumentVM[];
  }> {
    return forkJoin({
      context: this.strategyConversationContextService.getBetContext$(contextId).pipe(takeOneUntilDestroyed(this)),
      bet: this.strategicBetsService.getBets$({ filter: { contextId } }).pipe(
        takeOneUntilDestroyed(this),
        map(({ bets }) => bets[0])
      ),
    }).pipe(
      switchMap(({ bet, context }) => {
        if (!context) {
          return of({
            bet,
            context,
            documents: [],
            documentTasks: [],
            betTasks: [],
          });
        }

        return forkJoin({
          documents: this.documentsService
            .getDocuments$({
              filter: { _id: { $in: context.documentIds } },
            })
            .pipe(takeOneUntilDestroyed(this)),
          betTasks: this.getAsyncTasksForBetIds$([bet.id]).pipe(takeOneUntilDestroyed(this)),
          documentTasks: this.asyncTasksService.getTasksForDocuments$(context.documentIds).pipe(takeOneUntilDestroyed(this)),
        }).pipe(
          takeOneUntilDestroyed(this),
          map((res) => ({
            bet: bet,
            context,
            ...res,
          }))
        );
      })
    );
  }

  public getBetStatus$(betId: string): Observable<AsyncOperationPreviewState> {
    return this.betTasksStateService.betStatus$.pipe(
      untilDestroyed(this),
      map((tasks) => tasks?.[betId])
    );
  }

  public loadTaskStatus$(bets: StrategicBetVM[]): Observable<void> {
    return forkJoin({
      documentTasks: this.asyncTasksService.getAllTasksForDocuments$().pipe(takeOneUntilDestroyed(this)),
      betTasks: this.getAsyncTasksForBetIds$(bets.map((bet) => bet.id)).pipe(takeOneUntilDestroyed(this)),
      contexts: this.strategyConversationContextService.getContexts$().pipe(takeOneUntilDestroyed(this)),
      documents: this.documentsService.getDocuments$({ limit: 0 }).pipe(takeOneUntilDestroyed(this)),
    }).pipe(
      map(({ betTasks, documentTasks, contexts, documents }) => {
        bets.forEach((bet) => {
          const betContext = contexts.find((context) => context.id === bet.contextId);

          if (!betContext) return;

          const contextDocuments = betContext.documentIds.map((documentId) => documents.find((document) => document.id === documentId)).filter(Boolean);

          const documentIds = contextDocuments.map((document) => document.id);

          const asyncTasks = betTasks.filter((task) => task.betId === bet.id);
          const documentTasksForBet = documentTasks.filter((task) => documentIds.includes(task.itemId));

          const previewState = getBetTasksAsyncState({
            bet,
            betTasks: asyncTasks,
            documentTasks: documentTasksForBet,
            context: betContext,
            documents: contextDocuments,
          });

          this.betTasksStateService.setStatus(bet.id, previewState);
        });
      })
    );
  }

  public setupSocketListeners(): void {
    this.socketService
      .onMessage$("askQuestion")
      .pipe(
        untilDestroyed(this),
        switchMap(({ data }) => {
          const betId = data.additionalData.betId;
          return this.getDataForBetId$(betId).pipe(takeOneUntilDestroyed(this));
        })
      )
      .subscribe(({ bet, ...taskData }) => {
        const previewState = getBetTasksAsyncState({ bet, ...taskData });
        this.betTasksStateService.setStatus(bet.id, previewState);
      });

    this.socketService
      .onMessage$("createStrategyMapResponse")
      .pipe(
        untilDestroyed(this),
        switchMap(({ data }) => {
          const betId = data.itemId;
          return this.getDataForBetId$(betId).pipe(takeOneUntilDestroyed(this));
        }),
        switchMap(({ bet, ...taskData }) => {
          return this.strategicBetsService
            .updateBet$({
              id: bet.id,
              isSeen: false,
            })
            .pipe(
              takeOneUntilDestroyed(this),
              map(() => {
                bet.isSeen = false;
                return { bet, ...taskData };
              })
            );
        })
      )
      .subscribe(({ bet, ...taskData }) => {
        const previewState = getBetTasksAsyncState({ bet, ...taskData });
        this.betTasksStateService.setStatus(bet.id, previewState);
      });

    this.socketService
      .onMessage$("embedDocument")
      .pipe(
        untilDestroyed(this),
        switchMap(({ data }) => {
          const contextId = data.additionalData.contextId;
          return this.getDataFromContextId$(contextId).pipe(takeOneUntilDestroyed(this));
        })
      )
      .subscribe(({ bet, ...taskData }) => {
        const previewState = getBetTasksAsyncState({ bet, ...taskData });
        this.betTasksStateService.setStatus(bet.id, previewState);
      });

    this.socketService
      .onMessage$("updateDocumentSummary")
      .pipe(
        untilDestroyed(this),
        switchMap(({ data }) => {
          const contextId = data.additionalData.contextId;
          return this.getDataFromContextId$(contextId).pipe(takeOneUntilDestroyed(this));
        })
      )
      .subscribe(({ bet, ...taskData }) => {
        const previewState = getBetTasksAsyncState({ bet, ...taskData });
        this.betTasksStateService.setStatus(bet.id, previewState);
      });
  }
}
