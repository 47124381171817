import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PlanningSessionsActions } from "@gtmhub/sessions/redux/session-actions";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { CreateOkrDropdownButtonService } from "@webapp/okrs/services/create-okr-dropdown-button.service";
import { PermissionsFacadeWithFeatureFlag } from "@webapp/permissions/services/permission-facade-ff.service";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { TopNavBarButtonsConfig } from "@webapp/top-nav-bar/models/top-nav-bar-buttons.models";
import { TopNavBarModule } from "@webapp/top-nav-bar/top-nav-bar.module";

@UntilDestroy()
@Component({
  selector: "home-top-nav-bar",
  templateUrl: "./home-top-nav-bar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TopNavBarModule, LocalizationModule],
  providers: [CreateOkrDropdownButtonService, DefaultSessionResolverService],
})
export class HomeTopNavBarComponent implements OnInit {
  public topNavBarButtonsConfig: TopNavBarButtonsConfig;

  constructor(
    private createOkrDropdownButtonService: CreateOkrDropdownButtonService,
    private permissionsFacade: PermissionsFacadeWithFeatureFlag,
    private planningSessionsActions: PlanningSessionsActions
  ) {}

  public ngOnInit(): void {
    this.setTopNavBarButtonsConfig();
    const { reduxStore } = reduxStoreContainer;
    reduxStore.dispatch(this.planningSessionsActions.getSessionsIfMissing());
  }

  private setTopNavBarButtonsConfig(): void {
    this.topNavBarButtonsConfig = {
      dropdownConfig: {
        key: "create_okr",
        menuItems: this.createOkrDropdownButtonService.menuItems(),
        isVisible$: this.permissionsFacade.hasPermissionWithFeatureFlag$(FeatureFlag.ManageOKRsGranularPermissions, "goals:create", "ManageGoals"),
      },
    };
  }
}
