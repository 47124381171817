import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { IStrategyConfigApi } from "../strategy-config-api.interface";
import { StrategyConfigUrlBuilderV2Service } from "./strategy-config-url-builder-v2.service";

@Injectable()
export class StrategyConfigApiV2Service extends PlainBaseApiService implements IStrategyConfigApi {
  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService,
    private strategyConfigUrlBuilderV2Service: StrategyConfigUrlBuilderV2Service
  ) {
    super(httpClient, apiEndpointService);
  }

  public getConfig$(): Observable<unknown> {
    const url = this.strategyConfigUrlBuilderV2Service.getConfigUrl();
    return this.get$<unknown>(url);
  }
}
