<generic-results-box
  class="result-box"
  [data]="swot.strengths"
  [labels]="['positive', 'internal']"
  [resultMenuItems]="swotMenuItems['Strengths']"
  (contentChanged)="changeContent($event, 'strengths')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Strengths"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="swot.weaknesses"
  [labels]="['negative', 'internal']"
  [resultMenuItems]="swotMenuItems['Weaknesses']"
  (contentChanged)="changeContent($event, 'weaknesses')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Weaknesses"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="swot.opportunity"
  [labels]="['positive', 'external']"
  [resultMenuItems]="swotMenuItems['Opportunities']"
  (contentChanged)="changeContent($event, 'opportunity')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Opportunities"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="swot.threats"
  [labels]="['negative', 'external']"
  [resultMenuItems]="swotMenuItems['Threats']"
  (contentChanged)="changeContent($event, 'threats')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Threats"></generic-results-box>
