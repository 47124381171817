import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActionCardDefinition } from "./actions-panel.models";
import { ActionCardComponent } from "./components/action-card/action-card.component";

@Component({
  selector: "actions-panel",
  standalone: true,
  templateUrl: "./actions-panel.component.html",
  styleUrls: ["actions-panel.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ActionCardComponent],
})
export class ActionsPanelComponent implements OnInit {
  public actionCards: ActionCardDefinition[];
  public ngOnInit(): void {
    this.actionCards = [
      {
        title: "Set Goals",
        description: "Align your organization and unify team efforts toward a common mission",
        buttonLabel: "Create OKR",
        image: "dashboard1",
        id: "set-goals",
      },
      {
        title: "Make strategic decisions",
        description: "Use AI insights and guidance to make strategic business decisions and create action plans.",
        buttonLabel: "Make decision",
        image: "dashboard2",
        id: "make-decisions",
      },
      {
        title: "Brainstorm strategies",
        description: "Collaborate and brainstorm strategies in one shared space with Whiteboards.",
        buttonLabel: "Brainstorm",
        image: "dashboard3",
        id: "brainstorm-strategies",
      },
    ];
  }

  public handleActionButtonClicked(actionCard: ActionCardDefinition): void {
    switch (actionCard.id) {
      case "set-goals":
        window.open("/results/sessions/", "_self");
        break;
      case "make-decisions":
        window.open("/results/decisions/", "_self");
        break;
      case "brainstorm-strategies":
        window.open("/results/whiteboards/", "_self");
        break;
    }
  }
}
