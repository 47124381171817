import { UIRouterModule } from "@uirouter/angular";
import { NgModule } from "@angular/core";
import { states as ng1States } from "@gtmhub/strategy/strategy.states";
import { loadChildFutureStatesConfig } from "@webapp/core/routing/routing.utils";

const states: unknown[] = [...ng1States];

@NgModule({
  imports: [UIRouterModule.forChild({ states, config: loadChildFutureStatesConfig })],
})
export class StrategyRoutingModule {}
