import {
  AnsoffDataVM,
  BalancedScoreCardDataVM,
  BlueOceanDataVM,
  GoalsDataVM,
  KPIsDataVM,
  PestDataVM,
  PorterForcesDataVM,
  StrategyResultBlockContentVM,
  StrategyResultBlockTypeVM,
  StructuredDataAspectVM,
  SwotDataVM,
} from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { BlockContent, ChatGoalsDataVM, ChatKPIsDataVM, ChatTextResultVM, ResultBlockType } from "../evaluate-bet-page/components/ai-chat/models/chat.vm-models";

export function handleNoteContent(blockType: ResultBlockType | StrategyResultBlockTypeVM, blockContent: BlockContent | StrategyResultBlockContentVM): string {
  let content = "";

  switch (blockType) {
    case ResultBlockType.Text:
      content = (blockContent as ChatTextResultVM).text;
      break;
    case ResultBlockType.SWOT:
      content = handleSWOTBlockContent(blockContent as SwotDataVM);
      break;
    case ResultBlockType.PEST:
      content = handlePestBlockContent(blockContent as PestDataVM);
      break;
    case ResultBlockType.Porter:
      content = handlePorterBlockContent(blockContent as PorterForcesDataVM);
      break;
    case ResultBlockType.BalancedScorecard:
      content = handleBalancedScorecardBlockContent(blockContent as BalancedScoreCardDataVM);
      break;
    case ResultBlockType.BlueOcean:
      content = handleBlueOceanBlockContent(blockContent as BlueOceanDataVM);
      break;
    case ResultBlockType.Ansoff:
      content = handleAnsoffBlockContent(blockContent as AnsoffDataVM);
      break;
    case ResultBlockType.Goals:
      content = handleGoalsBlockContent(blockContent as GoalsDataVM);
      break;
    case ResultBlockType.KPIs:
      content = handleKPIsBlockContent(blockContent as KPIsDataVM);
      break;
  }

  return content;
}

function handleSWOTBlockContent(blockContent: SwotDataVM): string {
  let content = "";

  Object.entries(blockContent).forEach((entry) => {
    const [key, blockContentValues] = entry as [keyof SwotDataVM, StructuredDataAspectVM[]];
    content += `${key.charAt(0).toUpperCase()}${key.slice(1)}\n`;

    blockContentValues.forEach((blockContent: StructuredDataAspectVM) => {
      content += `${blockContent.text}\n`;
    });

    content += "\n";
  });

  return content;
}

function handlePestBlockContent(blockContent: PestDataVM): string {
  let content = "";

  Object.entries(blockContent).forEach((entry) => {
    const [key, blockContentValues] = entry as [keyof PestDataVM, StructuredDataAspectVM[]];
    switch (key) {
      case "politicalFactors":
        content += "Political Factors\n";
        break;
      case "economicFactors":
        content += "Economic Factors\n";
        break;
      case "socioculturalFactors":
        content += "Sociocultural Factors\n";
        break;
      case "technologicalFactors":
        content += "Technological Factors\n";
        break;
    }

    blockContentValues.forEach((blockContent: StructuredDataAspectVM) => {
      content += `${blockContent.text}\n`;
    });

    content += "\n";
  });

  return content;
}

function handlePorterBlockContent(blockContent: PorterForcesDataVM): string {
  let content = "";

  Object.entries(blockContent).forEach((entry) => {
    const [key, blockContentValues] = entry as [keyof PorterForcesDataVM, StructuredDataAspectVM[]];
    switch (key) {
      case "newEntrantThreats":
        content += "Threat of New Entrants\n";
        break;
      case "supplierBargainingPowers":
        content += "Bargaining Power of Suppliers\n";
        break;
      case "buyersBargainingPowers":
        content += "Bargaining Power of Buyers\n";
        break;
      case "substituteThreats":
        content += "Threat of Substitute Products or Services\n";
        break;
      case "competitionRivalry":
        content += "Rivalry Among Existing Competitor\n";
        break;
    }

    blockContentValues.forEach((blockContent: StructuredDataAspectVM) => {
      content += `${blockContent.text}\n`;
    });

    content += "\n";
  });

  return content;
}

function handleBalancedScorecardBlockContent(blockContent: BalancedScoreCardDataVM): string {
  let content = "";

  Object.entries(blockContent).forEach((entry) => {
    const [key, blockContentValues] = entry as [keyof BalancedScoreCardDataVM, StructuredDataAspectVM[]];
    switch (key) {
      case "alignmentAndSynergies":
        content += "Alignment and Synergies\n";
        break;
      case "customerPerspectives":
        content += "Customer Perspectives\n";
        break;
      case "financialPerspectives":
        content += "Financial Perspectives\n";
        break;
      case "internalProcessPerspectives":
        content += "Internal Process Perspectives\n";
        break;
      case "learningGrowthPerspectives":
        content += "Learning and Growth Perspectives\n";
        break;
      case "riskAndAdaptations":
        content += "Risk and Adaptations\n";
        break;
    }

    blockContentValues.forEach((blockContent: StructuredDataAspectVM) => {
      content += `${blockContent.text}\n`;
    });

    content += "\n";
  });

  return content;
}

function handleBlueOceanBlockContent(blockContent: BlueOceanDataVM): string {
  let content = "";

  Object.entries(blockContent).forEach((entry) => {
    const [key, blockContentValues] = entry as [keyof BlueOceanDataVM, StructuredDataAspectVM[]];
    switch (key) {
      case "errcGridAnalysis":
        content += "ERRC Grid Analysis\n";
        break;
      case "fourActionsFramework":
        content += "Four Actions Framework\n";
        break;
      case "leadershipAndPeopleDevelopment":
        content += "Leadership and People Development\n";
        break;
      case "marketBoundaryReconstruction":
        content += "Reconstruction of Market Boundaries\n";
        break;
      case "sixPathsFramework":
        content += "Six Paths Framework\n";
        break;
      case "strategicSequence":
        content += "Strategic Sequence\n";
        break;
      case "strategyCanvas":
        content += "Strategy Canvas\n";
        break;
      case "threeNoncustomerTiers":
        content += "Three Tiers of Noncustomers\n";
        break;
      case "valueInnovation":
        content += "Value Innovation\n";
        break;
    }

    blockContentValues.forEach((blockContent: StructuredDataAspectVM) => {
      content += `${blockContent.text}\n`;
    });

    content += "\n";
  });

  return content;
}

function handleAnsoffBlockContent(blockContent: AnsoffDataVM): string {
  let content = "";

  Object.entries(blockContent).forEach((entry) => {
    const [key, blockContentValues] = entry as [keyof AnsoffDataVM, StructuredDataAspectVM[]];
    switch (key) {
      case "marketPenetration":
        content += "Market Penetration\n";
        break;
      case "marketDevelopment":
        content += "Market Development\n";
        break;
      case "productDevelopment":
        content += "Product Development\n";
        break;
      case "diversification":
        content += "Diversification\n";
        break;
    }

    blockContentValues.forEach((blockContent: StructuredDataAspectVM) => {
      content += `${blockContent.text}\n`;
    });

    content += "\n";
  });

  return content;
}

function handleGoalsBlockContent(blockContent: ChatGoalsDataVM): string {
  let content = "";

  blockContent.goals.forEach((goal) => {
    content += `${goal.goal}\n`;

    goal.keyResults.forEach((keyResult) => {
      content += `- ${keyResult}\n`;
    });

    content += `${goal.reasoning}\n`;

    content += "\n";
  });

  return content;
}

function handleKPIsBlockContent(blockContent: ChatKPIsDataVM): string {
  let content = "";

  blockContent.kpis.forEach((kpi) => {
    content += `${kpi.kpi}\n`;
    content += `${kpi.reasoning}\n`;
    content += "\n";
  });

  return content;
}
