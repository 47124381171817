import { StateService } from "@uirouter/angular";
import { AsyncPipe, NgIf } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { Observable, firstValueFrom, map, switchMap } from "rxjs";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { UIErrorConfiguration } from "@gtmhub/error-handling/models";
import { Intercom } from "@gtmhub/shared/intercom";
import { catchHttpError } from "@webapp/core/rxjs-operators/catch-http-error.operator";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import dayjs from "@webapp/shared/libs/dayjs";
import { groupBy } from "@webapp/shared/utils/array";
import { STRATEGY_BET_PAGE_SIZE } from "@webapp/strategy/models/strategy.constants";
import { UiErrorHandlingService } from "@webapp/strategy/services/utility/ui-error-handling.service";
import { StrategySocketsService } from "@webapp/strategy/services/web-sockets/services/strategy-sockets.service";
import { TopNavBarButtonsConfig } from "@webapp/top-nav-bar/models/top-nav-bar-buttons.models";
import { TopNavBarModule } from "@webapp/top-nav-bar/top-nav-bar.module";
import { UiButtonBuilder } from "@webapp/top-nav-bar/utils/ui-button.builder";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { UiScrollableContentDirective } from "@webapp/ui/utils/directives/scrollable-content.directive";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import { BetCollaboratorVM, StrategicBetVM } from "../../models/bets/strategic-bets.vm-models";
import { AsyncOperationPreviewState, BetStatus, DecisionDisplayItem } from "../../models/strategy.vm-models";
import { BetMediatorService } from "../../services/bet/bet.mediator";
import { StrategicBetsService } from "../../services/bet/strategic-bets.service";
import { AsyncStatusComponent } from "../async-status/async-status.component";
import { ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData } from "../confirm-delete-strategy-item/confirm-delete-strategy-item.component";
import { ErrorDetailsComponent } from "../error-details/error-details.component";
import { MessageBoxComponent } from "../shared/message-box/message-box.component";
import { StrategyAiCardLoadingListComponent } from "../shared/strategy-ai-card-loading-list/strategy-ai-card-loading-list.component";
import { StrategyAiCardComponent } from "../shared/strategy-ai-card/strategy-ai-card.component";
import { StrategyAiDropdownComponent } from "../shared/strategy-ai-dropdown/strategy-ai-dropdown.component";
import { StrategyAiHeaderComponent } from "../shared/strategy-ai-header/strategy-ai-header.component";
import { StrategyAiListComponent } from "../shared/strategy-ai-list/strategy-ai-list.component";
import { DecisionGroup } from "../shared/strategy-ai-list/strategy-ai-list.vm-models";
import { fromDecisionsToStrategyAiList } from "../shared/strategy-ai-list/transformers";
import { getStrategyAiGroup } from "../shared/strategy-ai-list/utils";

@UntilDestroy()
@Component({
  selector: "decisions-page",
  templateUrl: "./decisions-page.component.html",
  styleUrls: ["./decisions-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [],
  imports: [
    StrategyAiHeaderComponent,
    UiButtonComponent,
    UiScrollableContentDirective,
    NgIf,
    StrategyAiDropdownComponent,
    UiIconModule,
    StrategyAiCardComponent,
    AsyncStatusComponent,
    StrategyAiListComponent,
    MessageBoxComponent,
    StrategyAiCardLoadingListComponent,
    AsyncPipe,
    ErrorDetailsComponent,
    TopNavBarModule,
    LocalizationModule,
  ],
  providers: [UiErrorHandlingService],
})
export class DecisionsPageComponent implements OnInit {
  @ViewChild("deletionError") private deletionError: TemplateRef<object>;
  @ViewChild("decisionLeaveError")
  private decisionLeaveError: TemplateRef<object>;

  public loading = true;
  public hasError = false;
  public betMenuItems: DropdownMenuItem[];
  public betGroups: DecisionGroup[] = [];
  public page = 1;
  public isLoadMoreVisible = true;
  public updatedBetId: string;
  public uiError: UIErrorConfiguration;
  public topNavBarButtonsConfig: TopNavBarButtonsConfig;

  private bets: StrategicBetVM[] = [];
  private allDecisions: DecisionDisplayItem[] = [];
  private tasks: AsyncTaskVM[] = [];

  constructor(
    private strategicBetsService: StrategicBetsService,
    private strategySocketsService: StrategySocketsService,
    private modalService: UiModalService,
    private apmService: ApmService,
    private cdr: ChangeDetectorRef,
    private betMediatorService: BetMediatorService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private errorHandlingService: UiErrorHandlingService,
    private state: StateService
  ) {}

  public toCollaboratorId(collaborators: BetCollaboratorVM[]): string[] {
    return collaborators.map((collaborator) => collaborator.userId);
  }

  public isUserOwner(decision: StrategicBetVM): boolean {
    const owner = decision.collaborators.find((collaborator) => collaborator.role === "owner");
    return owner?.userId === userId.get();
  }

  public ngOnInit(): void {
    this.fetchBets();
    this.initSocketListeners();
    this.setTopNavBarButtonsConfig();
  }

  private initSocketListeners(): void {
    this.strategySocketsService
      .onMessage$("createStrategyMapResponse")
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (message) => {
          const decisionIndex = this.allDecisions.findIndex((decision) => decision.id === message.data.itemId);
          const decision = this.allDecisions[decisionIndex];
          if (decision) {
            if (message.data.status === "SUCCESS") {
              this.allDecisions[decisionIndex] = {
                ...decision,
                status: "SUCCESS",
                currentStep: 2,
              };
            } else {
              this.allDecisions[decisionIndex] = {
                ...decision,
                status: "ERROR",
                currentStep: 1,
              };
            }
          }

          this.createBetGroups(this.allDecisions);
          this.cdr.markForCheck();
        },
      });

    this.strategySocketsService
      .onMessage$("betContributorsModified")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        const decision = this.allDecisions.find((decision) => decision.id === message.data.betId);
        if (decision) {
          const currentCollaboratorsIds = decision.collaborators.map((collaborator) => collaborator.userId);
          decision.collaborators = decision.collaborators
            .filter((collaborator) => {
              return !message.data.removed.includes(collaborator.userId);
            })
            .concat(
              message.data.added
                .filter((id) => !currentCollaboratorsIds.includes(id))
                .map((id) => ({
                  userId: id,
                  role: "collaborator",
                  uiState: "idle",
                }))
            );
          this.cdr.markForCheck();
        }
      });
    this.strategySocketsService
      .onMessage$("betContributorsModified")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        const currentUserId = userId.get();
        if (message.data.added.length > 0) {
          if (message.data.added.includes(currentUserId)) {
            this.page = 1;
            this.bets = [];
            this.fetchBets();
            this.cdr.markForCheck();
          }
        } else if (message.data.removed.length > 0 && message.data.removed.includes(currentUserId)) {
          this.page = 1;
          this.bets = [];
          this.fetchBets();
          this.cdr.markForCheck();
        }
      });

    this.strategySocketsService
      .onMessage$("contributorRemoved")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        const decision = this.allDecisions.find((b) => b.id === message.data.betId);
        if (decision) {
          decision.collaborators = decision.collaborators.filter((c) => c.userId !== message.data.contributorId);
          this.cdr.markForCheck();
        }
      });

    this.strategySocketsService
      .onMessage$("betRemoved")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        const removedBet = this.bets.find((b) => b.id === message.data.betId);
        if (removedBet) {
          this.page = 1;
          this.bets = [];
          this.fetchBets();
          this.cdr.markForCheck();
        }
      });
  }

  private getTaskForStatusAndIdAndName(tasks: AsyncTaskVM[], statuses: string[], id: string, name: string): AsyncTaskVM {
    return tasks.find((task) => task.itemId === id && task.name === name && statuses.includes(task.status));
  }

  private createBetGroups(bets: DecisionDisplayItem[]): void {
    const groupedBets = groupBy(bets, (unused, bet) => getStrategyAiGroup(bet.dateCreated));

    this.betGroups = fromDecisionsToStrategyAiList(groupedBets);
  }

  private getPagedBets$(): Observable<StrategicBetVM[]> {
    return this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.StrategyBetsPagination).pipe(
      switchMap((isFeatureAvailable) => {
        this.isLoadMoreVisible = isFeatureAvailable;

        if (isFeatureAvailable) {
          const limit = STRATEGY_BET_PAGE_SIZE;
          const skip = (this.page - 1) * limit;

          return this.strategicBetsService.getBets$({ limit, skip, sort: ["-createdAt"] }).pipe(
            map(({ bets, count }) => {
              this.isLoadMoreVisible = count > skip + limit;
              this.cdr.markForCheck();
              return bets;
            })
          );
        }

        return this.strategicBetsService.getBets$({ limit: 0 }).pipe(map(({ bets }) => bets));
      })
    );
  }

  public fetchBets(): void {
    this.loading = true;
    this.hasError = false;
    this.cdr.markForCheck();

    this.apmService.startDataLoadSpan("loading bets");
    this.getPagedBets$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (bets) => {
          this.betMediatorService.loadTaskStatus$(bets).subscribe(() => {
            this.bets = this.bets.concat(bets);
            this.allDecisions = this.initBetsDecisions(this.bets, this.tasks);
            this.createBetGroups(this.allDecisions);

            this.loading = false;
            this.strategySocketsService.setCurrentWatchers([
              {
                id: "",
                type: "decisionsList",
                metaData: {
                  bets: this.bets.map((b) => ({
                    id: b.id,
                    isOwner: b.ownerId === userId.get(),
                  })),
                },
              },
            ]);
            this.apmService.endDataLoadSpan("loading bets");
            this.cdr.markForCheck();
          });
        },
        error: () => {
          this.hasError = true;
          this.loading = false;
          this.cdr.markForCheck();
        },
      });
    this.cdr.markForCheck();
  }

  public deleteBet(betId: string, betName: string): void {
    this.modalService.confirm<ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData>(
      {
        uiTitle: "Delete decision?",
        uiContent: ConfirmDeleteStrategyItemComponent,
        uiData: {
          strategyItemName: betName,
          strategyItemType: "bet",
        },
        uiOkText: "Delete decision",
        uiOkLoadingText: "Deleting decision...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          return firstValueFrom(
            this.strategicBetsService.deleteBet$(betId).pipe(
              catchHttpError((error: HttpErrorResponse) => {
                this.uiError = this.errorHandlingService.getUIErrorData(error, "delete", "decision");
                throw this.deletionError;
              })
            )
          ).then(() => {
            this.updatedBetId = betId;
            this.bets = this.bets.filter((b) => b.id !== betId);
            this.allDecisions = this.initBetsDecisions(this.bets, this.tasks);
            this.createBetGroups(this.allDecisions);
            this.cdr.markForCheck();
          });
        },
        uiOnCancel: () => {
          this.cdr.markForCheck();
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
    this.cdr.markForCheck();
  }

  public leaveBet(decision: DecisionDisplayItem): void {
    this.modalService.confirm(
      {
        uiTitle: "Leave Decision?",
        uiContent: `${decision.title} <br /><br />You will not be able to open this decision again until the owner invites you again.`,
        uiOkText: "Leave Decision",
        uiOkLoadingText: "Leaving decision...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          return firstValueFrom(
            this.strategicBetsService.leaveDecision$(decision.id).pipe(
              catchHttpError((error: HttpErrorResponse) => {
                this.uiError = this.errorHandlingService.getUIErrorData(error, "leave", "decision");
                throw this.decisionLeaveError;
              })
            )
          ).then(() => {
            this.bets = this.bets.filter((b) => b.id !== decision.id);
            this.allDecisions = this.initBetsDecisions(this.bets, this.tasks);
            this.createBetGroups(this.allDecisions);

            this.cdr.markForCheck();
          });
        },
        uiOnCancel: () => {
          this.cdr.markForCheck();
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
    this.cdr.markForCheck();
  }

  public goToDetailedStrategy(): void {
    this.state.go("gtmhub.decisionNew");
  }

  public formatReportDate(isoDateString: string): string {
    const date = dayjs.utc(isoDateString);
    if (date.isAfter(dayjs().startOf("day"))) {
      return date.local().format("LT");
    }

    return date.local().format("l");
  }

  public onContactSupportClick(): void {
    Intercom("show");
  }

  public loadMoreBets(): void {
    this.page++;
    this.fetchBets();
  }

  private initBetsDecisions(bets: StrategicBetVM[], tasks: AsyncTaskVM[]): DecisionDisplayItem[] {
    return bets
      .map((bet) => {
        let betStatus: BetStatus = null;
        let betStep = 1;
        let errorMessage: null | string = null;
        if (bet.strategicMap?.areas.length === 0) {
          const lastDoneStrategicMapTask = this.getTaskForStatusAndIdAndName(tasks, ["FAILURE", "SUCCESS"], bet.id, "create_strategic_map");
          const ongoingStrategicMapTask = this.getTaskForStatusAndIdAndName(tasks, ["PENDING", "STARTED"], bet.id, "create_strategic_map");
          if (ongoingStrategicMapTask) {
            betStatus = "LOADING";
            betStep = 2;
          } else if (lastDoneStrategicMapTask && lastDoneStrategicMapTask.status === "FAILURE") {
            betStatus = "ERROR";
            betStep = 1;
            errorMessage = lastDoneStrategicMapTask.error;
          }
        } else {
          betStatus = "SUCCESS";
          betStep = 2;
        }
        return {
          id: bet.id,
          title: bet.name,
          ownerId: bet.ownerId,
          dateCreated: bet.createdAt,
          status: betStatus,
          currentStep: betStep,
          errorMessage,
          collaborators: bet.collaborators,
        };
      })
      .sort((a, b) => dayjs(b.dateCreated).unix() - dayjs(a.dateCreated).unix());
  }

  public getBetStatus(betId: string): Observable<AsyncOperationPreviewState> {
    return this.betMediatorService.getBetStatus$(betId);
  }

  public onHandleClick(betId: string): void {
    this.state.go("gtmhub.decision", { betId });
  }

  private setTopNavBarButtonsConfig(): void {
    const uiButtonBuilder = new UiButtonBuilder();

    const makeDecisionButton = uiButtonBuilder
      .setKey("Make Decision")
      .setKeyVisibilityMode("always")
      .setType({
        uiType: "buttonOnly",
        buttonType: "primary",
        buttonShape: "round",
        buttonSize: "default",
      })
      .setAction({ handler: () => this.goToDetailedStrategy() })
      .build();

    this.topNavBarButtonsConfig = {
      buttonsConfig: {
        uiButtons: [makeDecisionButton],
      },
    };
  }
}
