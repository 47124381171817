<section class="strategic-map" @empty>
  <section class="alert" *ngIf="!userSettings['strategicMapHintSeen'] && !(ckbFlowEnabled && progressSubStep === 0)">
    <alert-message *ngIf="!ckbFlowEnabled || progressSubStep !== 0" [message]="userMessage" (dismissAlert)="hideAlert.emit(1)" buttonLabel="Okay, got it" type="info" />
  </section>
  <section class="alert" *ngIf="ckbFlowEnabled && progressSubStep === 0 && !userSettings['strategicMapHintSeenCKBFlow']">
    <alert-message
      [message]="
        'The Decision Map contains areas with hypotheses. Select the key ones that will guide the execution that will follow. Set aside established truths or errors. Focus on hypotheses aligned with core business goals. Choose hypotheses with the highest strategic value for significant impact.'
      "
      (dismissAlert)="hideAlert.emit(2)"
      buttonLabel="Okay, got it"
      type="info" />
  </section>
  <ul class="content" *ngIf="strategicMap && strategicMap.areas?.length > 0">
    <li
      class="area"
      *ngFor="let area of strategicMap?.areas"
      [ngClass]="{
        ready: area.status === validationStatusCompleted && areaHasAtLeastOneHypothesis(area),
        error: area.error && !area.saving,
      }">
      <area-card
        [@highlightArea]="!area.saving && !!area.id"
        [area]="area"
        [areaBeingModifiedId]="areaBeingModifiedId"
        [areaHasAtLeastOneHypothesis]="areaHasAtLeastOneHypothesis(area)"
        [areaMenuItems]="dropdownMenuItemsMaps.areas.get(area.id)"
        [grooming]="ckbFlowEnabled && progressSubStep === 0"
        [isAreaStatusCompleted]="area.status === validationStatusCompleted && areaHasAtLeastOneHypothesis(area)"
        (cancelEditArea)="revertEditArea($event)"
        (cancelSaveArea)="removeTempArea($event)"
        (editArea)="updateAreaTitle($event)"
        (saveArea)="saveTempArea($event, area)">
        <ul class="hypothesis-list" *ngIf="area.hypotheses.length === 0">
          <actionable-empty-state
            [buttonText]="'Add hypothesis'"
            [cardText]="'Add a hypothesis to this area for analysis and make a decision.'"
            [iconType]="'plus-circle'"
            [paddingStyle]="'hypothesis'"
            (action)="createHypothesis(area)"></actionable-empty-state>
        </ul>
        <ul class="hypothesis-list" *ngIf="area.hypotheses.length > 0" @empty>
          <li class="hypothesis" *ngFor="let hypothesis of area.hypotheses" (click)="selectHypothesis(area, hypothesis)" (keyup.enter)="selectHypothesis(area, hypothesis)" tabindex="1">
            <hypothesis-card
              [@highlightHypothesis]="!hypothesis.saving ? getHighlightState(hypothesis.id) : false"
              [area]="area"
              [areaBeingModifiedId]="areaBeingModifiedId"
              [grooming]="ckbFlowEnabled && progressSubStep === 0"
              [hypothesis]="hypothesis"
              [hypothesisMenuItems]="dropdownMenuItemsMaps.hypotheses.get(hypothesis.id)"
              [hypothesisPassesGrooming]="hypothesis.passedGrooming && progressSubStep === 0"
              [hypothesisTasksState]="getConversationTaskState$(hypothesis.conversationId) | async"
              [lockedBy]="locks[hypothesis.id]"
              (cancelEditHypothesis)="revertEditHypothesis($event)"
              (cancelSaveHypothesis)="removeTempHypothesis($event)"
              (deleteHypothesis)="confirmDeleteHypothesis(hypothesis, area)"
              (editHypothesis)="saveEditHypothesis($event)"
              (hypothesisCheckboxChange)="selectHypothesis(area, hypothesis)"
              (saveHypothesis)="saveHypothesis($event)">
            </hypothesis-card>
          </li>
        </ul>
      </area-card>
    </li>
  </ul>
  <ul class="content" *ngIf="!strategicMap">
    <li class="area" *ngFor="let area of strategicMapMockLoadingAreas">
      <area-card [area]="area" />
    </li>
  </ul>
  <ul class="content" *ngIf="strategicMap && strategicMap.areas?.length === 0">
    <actionable-empty-state
      [buttonText]="'Add area'"
      [cardText]="'Add areas of strategic focus for your decision-making e.g. Market, Product, Finance etc.'"
      [iconType]="'plus-circle'"
      [paddingStyle]="'area'"
      (action)="createArea()"></actionable-empty-state>
  </ul>
  <section class="alert no-padding">
    <alert-message
      class="empty-hypothesis-error"
      *ngIf="emptyHypothesisErrorShown"
      (dismissAlert)="hideEmptyHypothesisError()"
      dismissType="x-button"
      message="Analyse at least one hypothesis to generate Objectives."
      type="warning">
    </alert-message>
    <alert-message
      class="empty-hypothesis-error"
      *ngIf="zeroHypotheseErrorShown"
      (dismissAlert)="hideZeroHypotheseErrorShown()"
      dismissType="x-button"
      message="Add at least one hypothesis to Continue."
      type="warning">
    </alert-message>
  </section>
  @if (userIsOwner) {
    <section class="actions">
      @if (ckbFlowEnabled && progressSubStep === 0) {
        <button (click)="moveToNextSubStep()" type="submit" ui-button uiShape="round" uiSize="large" uiType="primary">Done</button>
      }

      @if (!ckbFlowEnabled || (ckbFlowEnabled && progressSubStep === 1)) {
        <button
          [disabled]="areaBeingModifiedId || !strategicMap || strategicMap.areas?.length === 0"
          (click)="generateOKRsClicked()"
          type="submit"
          ui-button
          uiShape="round"
          uiSize="large"
          uiType="default">
          Generate Objectives
        </button>
      }

      @if ((!ckbFlowEnabled && onePagerEnabled) || (ckbFlowEnabled && progressSubStep === 1)) {
        <button
          [disabled]="areaBeingModifiedId || !strategicMap || strategicMap.areas?.length === 0"
          (click)="generateOnePagerClicked()"
          type="submit"
          ui-button
          uiShape="round"
          uiSize="large"
          uiType="default">
          {{ onePagerCopyChange ? "Get One-Pager" : "Generate summary" }}
        </button>
      }
    </section>
  }
</section>

<ng-template #hypothesisDeletionError>
  <p class="deletion-error sp-mb-0">
    Unable to delete hypothesis. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
  <error-details *ngIf="uiError.transactionId" [transactionId]="uiError.transactionId"></error-details>
</ng-template>

<ng-template #areaDeletionError>
  <p class="deletion-error sp-mb-0">
    Unable to delete area. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
  <error-details *ngIf="uiError.transactionId" [transactionId]="uiError.transactionId"></error-details>
</ng-template>
