import { Directive, TemplateRef } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { UiDrawerRef } from "../abstracts/drawer-ref";

@Directive({
  selector: "[uiDrawerTitle]",
  exportAs: "uiDrawerTitle",
  standalone: true,
})
export class UiDrawerTitleDirective {
  constructor(drawerRef: UiDrawerRef, templateRef: TemplateRef<NzSafeAny>) {
    drawerRef.setProperty("uiTitle", templateRef);
  }
}
