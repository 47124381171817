import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class RegenerateChatAnswerResponseStrategy implements QuantiveResultsSocketStrategy<"regenerateChatAnswer"> {
  public messageType: QuantiveResultsSocketStrategy<"regenerateChatAnswer">["messageType"] = "regenerateChatAnswer";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"regenerateChatAnswer">> {
    return pipe(filter(({ data }) => data.item === "conversation" && data.taskName === "regenerate_chat_answer" && data.userId === userId.get()));
  }
}
