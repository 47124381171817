import { FocusableOption } from "@angular/cdk/a11y";
import { Observable } from "rxjs";

export class MenuItemFocusableOption implements FocusableOption {
  private el: HTMLElement;

  public get disabled(): boolean {
    return this.el.hasAttribute("disabled") || this.el.getAttribute("aria-disabled") === "true";
  }

  constructor(el: HTMLElement) {
    if (el.getAttribute("role") !== "menuitem") {
      throw new Error("MenuItemFocusableOption can only be used with menu item roles");
    }

    this.el = el.querySelector<HTMLElement>("ui-toggle button") || el;
  }

  public focus(): void {
    this.el.focus();
  }

  public getLabel(): string {
    const ariaLabel = this.el.getAttribute("aria-label");
    if (ariaLabel) {
      return ariaLabel;
    }

    const ariaLabelledBy = this.el.getAttribute("aria-labelledby");
    if (ariaLabelledBy) {
      const labelEl = document.getElementById(ariaLabelledBy);
      if (labelEl) {
        return labelEl.textContent;
      }
    }

    return this.el.textContent;
  }

  public click(): void {
    this.el.click();
  }
}

export type WidgetTextMenuItem = {
  titleKey: string;
  hotkey: string;
  iconType: string;
  click(): void;
  disabled?: boolean;
  disabled$?: Observable<boolean>;
  isEndOfSection?: boolean;
};
