import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { EditionFeature } from "../models/edition.models";
import { CurrentAccountDTORepository } from "./current-account-dto.repository";

@Injectable()
export class EditionFeatureService {
  constructor(private currentAccountRepository: CurrentAccountDTORepository) {}

  public hasFeature$(feature: EditionFeature): Observable<boolean> {
    return this.currentAccountRepository.get$().pipe(map((account) => account.edition?.features?.includes(feature) ?? false));
  }
}
