import { Injectable } from "@angular/core";
import { TokenProvider } from "@gtmhub/auth";
import { BaseGenericSocketsService } from "./base-generic-sockets.service";
import { StrategyBetSocketApiService } from "./strategy-bet-socket-api.service";

@Injectable()
export class StrategyBetSocketService extends BaseGenericSocketsService {
  private currentWatchers: {
    id: string;
    type: string;
    metaData?: Record<string, object>;
  }[] = [];
  constructor(
    tokenProvider: TokenProvider,
    private apiService: StrategyBetSocketApiService
  ) {
    super(tokenProvider);
  }

  public initConnection(betId: string): void {
    this.apiService.betId = betId;
    this.setApiService(this.apiService);
  }

  public setCurrentWatchers(watchers: { id: string; type: string; metaData?: Record<string, object> }[]): void {
    this.currentWatchers = watchers;
  }

  public getCurrentWatchers(): {
    id: string;
    type: string;
    metaData?: Record<string, object>;
  }[] {
    return this.currentWatchers;
  }
}
