import { NgClass, NgIf, NgStyle } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter } from "rxjs";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { ActionableTextContextListener, FollowupActionDefinition } from "../../models/strategy.vm-models";
import { StrategyConversationViewContextService } from "../../services/conversation/strategy-conversation-view-context.service";
import { ActionableTextContextService } from "../../services/utility/actionable-text-context.service";

@UntilDestroy()
@Component({
  selector: "actionable-text-block",
  templateUrl: "./actionable-text-block.component.html",
  styleUrls: ["./actionable-text-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, DropdownModule, UiButtonComponent, UiIconModule],
})
export class ActionableTextBlockComponent implements OnInit, OnChanges, ActionableTextContextListener {
  private contentSubject = new BehaviorSubject<string | null>(null);
  private currentWord = "";

  public actualContent = "";
  public selectionTop = 0;
  public selectionLeft = 0;
  public showContextMenu = false;
  public aiHelpItems: DropdownMenuItem[] = [];
  public editableAreaFocused: boolean;
  public persistedContent: string;
  public aiWorking: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private actionableTextContextService: ActionableTextContextService,
    private conversationContextService: StrategyConversationViewContextService
  ) {}

  @ViewChild("editable")
  public editableElement: ElementRef<HTMLElement>;
  @ViewChild("aihelpbutton")
  public aiHelpButtonElement: ElementRef<HTMLElement>;
  @Input() public contextMenuAvailable = true;
  @Input() public content: string;
  @Input() public menuItems: DropdownMenuItem[] = [];
  @Input() public enablePlaceholder = false;
  @Output() public readonly contentChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public readonly executeAction: EventEmitter<FollowupActionDefinition> = new EventEmitter<FollowupActionDefinition>();
  @Output()
  public readonly focusOutWithChanges: EventEmitter<void> = new EventEmitter<void>();

  public ngOnInit(): void {
    this.contentSubject
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged(),
        filter((content) => content !== null)
      )
      .subscribe((content) => {
        this.contentChanged.emit(content);
      });

    this.conversationContextService
      .getConfig$()
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if (context.isAnsweringQuestions) {
          this.aiHelpItems = [];
          this.aiWorking = true;
        } else {
          this.aiHelpItems = this.getAiHelpItems();
          this.aiWorking = false;
        }
        this.cdr.markForCheck();
      });
    this.aiHelpItems = this.getAiHelpItems();
    this.actionableTextContextService.addListener(this);
    this.persistedContent = this.content;
    this.actualContent = this.content;
    this.aiWorking = false;
    this.cdr.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["menuItems"]) {
      this.aiHelpItems = this.getAiHelpItems();
    }
    if (changes["content"] && !this.editableAreaFocused) {
      this.persistedContent = changes["content"].currentValue as string;
      this.cdr.detectChanges();
    }
  }

  private getAiHelpItems(): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    return [
      menuItemBuilder
        .setKey("Elaborate")
        .setUiType({
          iconTheme: "fill",
          uiType: "uiIcon",
          iconType: "",
        })
        .setAction({
          handler: () =>
            this.executeAction.emit({
              type: "elaborate",
              question: this.currentWord,
            }),
        })
        .build(),

      menuItemBuilder
        .setKey("Research")
        .setUiType({
          iconTheme: "fill",
          uiType: "uiIcon",
          iconType: "",
        })
        .setAction({
          handler: () =>
            this.executeAction.emit({
              type: "research",
              question: this.currentWord,
            }),
        })
        .build(),
      ...(this.menuItems || []),
    ];
  }

  public onMouseUp(): void {
    // if our editable area contains a selection, process the selection, otherwise hide the context menu
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    if (!this.editableElement.nativeElement.contains(range.startContainer)) {
      this.showContextMenu = false;
    } else {
      this.processSelection();
    }
    this.cdr.markForCheck();
  }

  public onMouseDown(target: EventTarget): void {
    // don't do anything if we're clicking on the ai helper button
    if (this.aiHelpButtonElement.nativeElement.contains(target as Element)) return;
    this.showContextMenu = false;
    this.cdr.markForCheck();
  }

  public onFocusOut(): void {
    // if our editable area contains a non-empty selection, show the context menu, otherwise hide it
    setTimeout(() => {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;
      const range = selection.getRangeAt(0);
      if (!this.editableElement.nativeElement.contains(range.startContainer)) {
        this.showContextMenu = false;
      } else {
        this.showContextMenu = range.endOffset !== range.startOffset;
      }
      this.cdr.markForCheck();
    }, 0);
    if (this.persistedContent.trim() !== this.actualContent.trim()) {
      this.focusOutWithChanges.emit();
    }
    this.persistedContent = this.actualContent;
  }

  public processSelection(): void {
    const selection = window.getSelection();
    if (!selection) return;
    const range = selection.getRangeAt(0);
    const parentBoundingRect = this.editableElement.nativeElement.getBoundingClientRect();
    const boundingRect = range.getBoundingClientRect();
    this.selectionTop = boundingRect.top - 60 - parentBoundingRect.top;
    this.selectionLeft = boundingRect.left - parentBoundingRect.left + boundingRect.width / 2 - 60;
    const stopCharacters = [" ", "\n", "\r", "\t", ",", ".", "!", "?"];
    const text = this.editableElement.nativeElement.innerText;
    // offset counts the position between the letters and starts at 1, i.e.
    // a|bc|d, where |bc| is selected results in startOffset = 2 and endOffset = 4
    // we need to get the indexes of the first selected character and the last selected character
    // which are 1 and 2 respectively, so we need to subtract 1 from startOffset and 2 from endOffset
    let startLetterIndex = selection.getRangeAt(0).startOffset - 1;
    let endLetterIndex = selection.getRangeAt(0).endOffset;
    const startContainerText = range.startContainer.textContent;
    const endContainerText = range.endContainer.textContent;
    let currentWord = selection.toString();
    if (currentWord.trim() === "") {
      this.currentWord = "";
      this.showContextMenu = false;
    } else {
      if (stopCharacters.indexOf(currentWord[0]) === -1) {
        while (startLetterIndex > 0 && stopCharacters.indexOf(startContainerText[startLetterIndex]) === -1) {
          currentWord = startContainerText[startLetterIndex] + currentWord;
          startLetterIndex--;
        }
      }
      if (stopCharacters.indexOf(currentWord[currentWord.length - 1]) === -1) {
        while (stopCharacters.indexOf(endContainerText[endLetterIndex]) === -1 && endLetterIndex < text.length) {
          currentWord = currentWord + endContainerText[endLetterIndex];
          endLetterIndex++;
        }
      }
      this.currentWord = currentWord.trim();
      this.showContextMenu = this.currentWord !== "";
    }

    this.cdr.markForCheck();
  }

  public handleKeyUp(content: string): void {
    this.contentSubject.next(content.trim());
    this.actualContent = content.trim();
    this.cdr.markForCheck();
  }

  public handleFocusOut(): void {
    this.editableAreaFocused = false;
    this.cdr.markForCheck();
  }

  public handleFocusIn(): void {
    this.editableAreaFocused = true;
    this.cdr.markForCheck();
  }
}
