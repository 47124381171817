import { OperatorFunction, filter, map, pipe } from "rxjs";
import { QuantiveResultsSocketDTOv2 } from "../models/strategy/socket-strategy.dto-v2-models";
import { QuantiveResultsSocketMessageType, QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "../models/strategy/socket-strategy.vm-models";
import { strategySocketDataMapDTOv22VM } from "./quantive-results-socket-strategy/data-transformers/socket-strategy-response-v2.transformer";

export class QuantiveResultsSocketContext<MessageType extends QuantiveResultsSocketMessageType> {
  private socketStrategy: QuantiveResultsSocketStrategy<MessageType>;

  constructor(
    strategy: QuantiveResultsSocketStrategy<MessageType>,
    private filterByMessageType: boolean = true
  ) {
    this.socketStrategy = strategy;
  }

  public setStrategy(strategy: QuantiveResultsSocketStrategy<MessageType>): void {
    this.socketStrategy = strategy;
  }

  public onMessage(): OperatorFunction<QuantiveResultsSocketDTOv2<MessageType>, QuantiveResultsSocketVM<MessageType>> {
    return pipe(
      filter(({ messageType }) => !this.filterByMessageType || messageType === this.socketStrategy.messageType),

      map((response) => {
        const transformer = strategySocketDataMapDTOv22VM[this.socketStrategy.messageType];

        try {
          return transformer(response as QuantiveResultsSocketDTOv2<MessageType>);
        } catch {
          return { taskName: "unknown", messageType: "" };
        }
      }),
      this.socketStrategy.onMessage()
    );
  }
}
