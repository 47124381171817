import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { OkrWidgetDetailsDTO } from "../models/okr-widget-details.models";
import { OkrWidgetDetailsApi } from "./okr-widget-details-api.service";

@Injectable()
export class OkrWidgetDetailsRepository implements Pick<IBaseRepository<OkrWidgetDetailsDTO>, "getAll$" | "post$" | "put$"> {
  constructor(private apiService: OkrWidgetDetailsApi) {}

  public getAll$<T = OkrWidgetDetailsDTO>(params?: { filter?: RequestPaging; config?: RequestConfig }): Observable<T> {
    return this.apiService.getAll$(params?.filter, params?.config);
  }

  public put$(params?: { id: string; putModel: Partial<OkrWidgetDetailsDTO>; config?: RequestConfig }): Observable<OkrWidgetDetailsDTO> {
    const url = this.apiService.getBasePath(HttpActions.put);
    return this.apiService.put$("", params.putModel, { ...(params.config || { url }) });
  }

  public post$(params: { postModel: Partial<OkrWidgetDetailsDTO>; config?: RequestConfig }): Observable<OkrWidgetDetailsDTO> {
    const url = this.apiService.getBasePath(HttpActions.post);
    return this.apiService.post$(params.postModel, { ...(params.config || { url }) });
  }

  public getOkrWidgetConfig$(): Observable<Pick<OkrWidgetDetailsDTO, "tabs">> {
    return this.apiService.getAll$({}, { url: this.apiService.getPrincipalEndpointUrl() });
  }
}
