import { AfterViewInit, Directive, Input, OnInit } from "@angular/core";
import { RenderCollectorService } from "./render-collector.service";

@Directive({
  selector: "[traceElement]",
  standalone: true,
})
export class TraceElementDirective implements OnInit, AfterViewInit {
  @Input({ required: true }) public traceElement: string = "";
  @Input() public tracingBounds: { start: boolean; end: boolean };

  constructor(private renderCollectorService: RenderCollectorService) {}

  public ngOnInit(): void {
    if (this.tracingBounds === undefined || this.tracingBounds.start) {
      this.renderCollectorService.addElement(this.traceElement);
    }
  }

  public ngAfterViewInit(): void {
    if (this.tracingBounds === undefined || this.tracingBounds.end) {
      this.renderCollectorService.finishElementRender(this.traceElement);
    }
  }
}
