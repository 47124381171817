import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { DocumentIngestionMessage } from "./ingestion.models";

@UntilDestroy()
@Injectable()
export class IngestionMessagingService {
  private dataIngestionExecutionSubject = new Subject<DocumentIngestionMessage>();
  public readonly onDataIngestionExecution$ = this.dataIngestionExecutionSubject.asObservable();

  constructor(broadcastService: BroadcastService) {
    broadcastService
      .on<DocumentIngestionMessage>("dataIngestionExecution")
      .pipe(untilDestroyed(this))
      .subscribe((dataIngestion) => this.dataIngestionExecutionSubject.next(dataIngestion));
  }
}
