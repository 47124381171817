<generic-results-box
  class="result-box"
  [data]="pest.politicalFactors"
  (contentChanged)="changeContent($event, 'politicalFactors')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Political Factors"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="pest.economicFactors"
  (contentChanged)="changeContent($event, 'economicFactors')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Economic Factors"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="pest.socioculturalFactors"
  (contentChanged)="changeContent($event, 'socioculturalFactors')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Sociocultural Factors"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="pest.technologicalFactors"
  (contentChanged)="changeContent($event, 'technologicalFactors')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Technological Factors"></generic-results-box>
