/* eslint-disable @typescript-eslint/naming-convention */
import { RestLayerFilter } from "@gtmhub/core";
import { GridFilterOperator, IDateRange, INumericRange } from "@gtmhub/shared/components/grid-filtering-bar";
import { expandExtendedFilterValue } from "@gtmhub/shared/components/grid-filtering-bar/grid-filters/util";
import { getCurrentUserId } from "@gtmhub/users";
import { ExtendedArrayGridFilter, ExtendedGridFilter, ExtendedOrFilterGroups, FilterFieldName } from "@webapp/okrs/okrs-grid-page/models";
import { safeMongoCaseInsensitiveExactMatchRegex } from "@webapp/shared/utils/regex";

function cloneFilterWithPrefixKey(map: Record<string, unknown>, prefix?: string): RestLayerFilter[] {
  return Object.keys(map).map((key) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    return { [newKey]: map[key] };
  });
}

function toOwnerIdFilter(values: string[], operator: string, filterKey: FilterFieldName): Record<string, unknown> {
  const extractPreviousIds = (map: Record<string, unknown>, key: string): string[] => {
    const members = map[key];
    return (members && members[operator]) || [];
  };

  const previousIds = [];
  return values.reduce<Record<string, unknown>>((map, id) => {
    if (id.endsWith("_subteams")) {
      const [rawId] = id.split(".");
      const ids = extractPreviousIds(map, `${filterKey}_teamOrg`);

      return {
        ...map,
        [`${filterKey}_teamOrg`]: { [operator]: [...ids, rawId] },
      };
    }

    if (id.endsWith("_members")) {
      const [rawId] = id.split(".");
      const ids = extractPreviousIds(map, `${filterKey}_teamPlusMembers`);

      return {
        ...map,
        [`${filterKey}_teamPlusMembers`]: { [operator]: [...ids, rawId] },
      };
    }

    if (id === "my-teams" || id === "myTeams") {
      return {
        ...map,
        [`${filterKey}_myTeams`]: { [operator]: [getCurrentUserId()] },
      };
    }

    if (id === "teams-i-manage" || id === "teamsIManage") {
      return {
        ...map,
        [`${filterKey}_UserManagedTeams`]: { [operator]: [getCurrentUserId()] },
      };
    }

    if (id === "current-user" || id === "me") {
      id = getCurrentUserId();
    }

    previousIds.push(id);
    return {
      ...map,
      [filterKey]: { [operator]: previousIds },
    };
  }, {});
}

const operatorMappings: Record<ExtendedGridFilter["dataType"], Partial<Record<GridFilterOperator, string>>> = {
  text: {
    equals: "$eq",
    notEqual: "$ne",
    contains: "$regex",
    doesNotContain: "$not",
  },
  array: {
    isOneOf: "$in",
    equals: "$in",
    isNotOneOf: "$nin",
    notEqual: "$nin",
    contains: "$in",
    doesNotContain: "$nin",
  },
  boolean: {
    equals: "$eq",
    notEqual: "$ne",
  },
  numeric: {
    equals: "$eq",
    notEqual: "$ne",
    greater: "$gt",
    less: "$lt",
  },
  enum: {
    equals: "$eq",
    notEqual: "$ne",
    isOneOf: "$in",
    isNotOneOf: "$nin",
  },
  date: {
    equals: "$eq",
    notEqual: "$ne",
    isBefore: "$lte",
    isAfter: "$gte",
    isBetween: "isBetween",
    isNotBetween: "isNotBetween",
  },
};

export const resolveFilterOperator = (dataType: ExtendedGridFilter["dataType"], operator: string): string => {
  const typeMapping = operatorMappings[dataType];
  if (!typeMapping) {
    throw new Error(`Unsupported dataType: ${dataType}`);
  }

  const mongoOperator = typeMapping[operator];
  if (!mongoOperator) {
    throw new Error(`Unsupported operator for ${dataType}: ${operator}`);
  }

  return mongoOperator;
};

/**
 * `ResetModifiedTransformersFunc` should be called by the screen which modified the original transformers
 * When the screen is being destroyed. This way all other screens are able to utilize the default transformers.
 */
export type ResetModifiedTransformersFunc = () => void;

export type RestLayerDynamicValuesTransformerFactory = () => ToRestLayerDynamicValuesTransformer;

export abstract class ToRestLayerDynamicValuesTransformer {
  private static transformerMap: Record<FilterFieldName, RestLayerDynamicValuesTransformerFactory> = {
    sessionId: () => new ToSessionRestLayerDynamicValueTransformer(),
    ownerIds: () => new ToOwnerIdRestLayerDynamicValueTransformer(),
    ownerId: () => new ToOwnerIdRestLayerDynamicValueTransformer(),
    "metrics.ownerIds": () => new ToOwnerIdRestLayerDynamicValueTransformer(),
    watchers: () => new ToWatchersRestLayerDynamicValueTransformer(),
    "designScore.totalPoints": () => new ToOkrDesignScoreRestLayerDynamicValueTransformer(),
    attainment: () => new ToOkrAttainmentRestLayerDynamicValueTransformer(),
    "workflow.status": () => new ToWorkFlowRestLayerDynamicValueTransformer(),
    "tags.title": () => new ToTagsRestLayerDynamicValueTransformer(),
    "closedStatus.status": () => new ToOkrStatusRestLayerDynamicValueTransformer(),
    "metrics.latestSnapshotAt": () => new ToLastUpdatedRestLayerDynamicValueTransformer(),
    description: () => new ToTextRestLayerDynamicValueTransformer(),
    name: () => new ToTextRestLayerDynamicValueTransformer(),
    "metrics.name": () => new ToTextRestLayerDynamicValueTransformer(),
    "metrics.description": () => new ToTextRestLayerDynamicValueTransformer(),
    accessType: () => new ToAccessTypeRestLayerDynamicValueTransformer(),
  };

  public static createTransformer(filterKey: FilterFieldName): ToRestLayerDynamicValuesTransformer {
    const transformer = this.transformerMap[filterKey];

    if (!transformer) {
      throw new Error(`Unsupported rest dynamic value transformer for filter key ${filterKey}`);
    }

    return transformer();
  }

  public static withCustomTransformer(transformerMap: Partial<Record<FilterFieldName, RestLayerDynamicValuesTransformerFactory>>): ResetModifiedTransformersFunc {
    const originalTransformers: Partial<Record<FilterFieldName, RestLayerDynamicValuesTransformerFactory>> = {};

    Object.keys(transformerMap).forEach((key) => {
      originalTransformers[key] = this.transformerMap[key];
      this.transformerMap[key] = transformerMap[key];
    });

    return (): void => {
      Object.keys(originalTransformers).forEach((key) => {
        this.transformerMap[key] = originalTransformers[key];
      });
    };
  }

  public transform(filter: ExtendedOrFilterGroups): RestLayerFilter[] {
    const filterKey = filter.fieldName;
    const filterOperator = resolveFilterOperator(filter.dataType, filter.operator);

    if (filter.dataType === "array") {
      const values =
        Array.isArray(filter.value) || filter.dynamicValues?.length ? expandExtendedFilterValue(filter as ExtendedArrayGridFilter) : [filter.value as string];
      const strictMatch = filter.dataType === "array" && (filter.operator === "equals" || filter.operator === "notEqual");

      if (strictMatch) {
        const result = values.map((value) => this.resolveDynamicValues([value], filterOperator, filterKey));
        return result.flat();
      }

      return this.resolveDynamicValues(values, filterOperator, filterKey);
    }

    return this.resolveDynamicValues(filter.value, filterOperator, filterKey);
  }

  protected abstract resolveDynamicValues(
    value: string | number | boolean | string[] | INumericRange | IDateRange,
    operator: string,
    filterKey: FilterFieldName
  ): RestLayerFilter[];
}

class ToSessionRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    const previousValues = [];
    const map = values.reduce<Record<string, unknown>>((map, value) => {
      if (value === "activeSessions") {
        return {
          ...map,
          sessionId_activeSessions: { [operator]: [] },
        };
      }

      previousValues.push(value);
      return {
        ...map,
        [filterKey]: { [operator]: previousValues },
      };
    }, {});

    return Object.keys(map).map((key) => ({
      [key]: map[key],
    }));
  }
}

class ToOwnerIdRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    const map = toOwnerIdFilter(values, operator, filterKey);

    return Object.keys(map).map((key) => ({
      [key]: map[key],
    }));
  }
}

class ToWatchersRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    if (values.length === 0) {
      return [];
    }

    const map = toOwnerIdFilter(values, operator, filterKey);

    const objectiveFilter = cloneFilterWithPrefixKey(map);
    const metricsFilter = cloneFilterWithPrefixKey(map, "metrics");

    return [
      {
        $or: [...objectiveFilter, ...metricsFilter],
      },
    ];
  }
}

class ToOkrDesignScoreRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: string, operator: string): RestLayerFilter[] {
    if (operator === "$eq") {
      return [
        {
          "designScore.totalPoints": value,
        },
      ];
    }

    return [
      {
        "designScore.totalPoints": { [operator]: value },
      },
    ];
  }
}

class ToOkrAttainmentRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: string, operator: string): RestLayerFilter[] {
    if (operator === "$eq") {
      return [
        {
          $or: [
            {
              attainment: value,
            },
            {
              "metrics.attainment": value,
            },
          ],
        },
      ];
    }

    return [
      {
        $or: [
          {
            attainment: { [operator]: value },
          },
          {
            "metrics.attainment": { [operator]: value },
          },
        ],
      },
    ];
  }
}

class ToWorkFlowRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    const orConditions = [];

    if (values.length > 0) {
      orConditions.push({ [filterKey]: { [operator]: values } });
    }

    return orConditions;
  }
}

class ToTagsRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string): RestLayerFilter[] {
    if (!values.length) {
      return [];
    }

    return [
      {
        tags: {
          $elemMatch: {
            title: {
              [operator]: [...values],
            },
          },
        },
      },
    ];
  }
}

class ToOkrStatusRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    const previousStatuses = [];
    const map = values.reduce<Record<string, unknown>>((map, status) => {
      if (status === "active") {
        status = "";
      }

      previousStatuses.push(status);
      return {
        ...map,
        [filterKey]: {
          [operator]: previousStatuses,
        },
      };
    }, {});

    return Object.keys(map).map((key) => ({
      [key]: map[key],
    }));
  }
}

class ToLastUpdatedRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: IDateRange, operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    switch (operator) {
      case "isBetween": {
        return [
          {
            $and: [{ [filterKey]: { $gte: value.startDate } }, { [filterKey]: { $lte: value.endDate } }],
          },
        ];
      }
      case "isNotBetween": {
        return [
          {
            $or: [{ [filterKey]: { $gte: value.startDate } }, { [filterKey]: { $lte: value.endDate } }],
          },
        ];
      }
      case "$eq":
        return [{ [filterKey]: value }];
      case "$ne":
      case "$gte":
      case "$lte":
        return [{ [filterKey]: { [operator]: value } }];
      default:
        return [];
    }
  }
}
class ToTextRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: string, operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    if (operator === "$eq") {
      return [
        {
          [filterKey]: { $regex: safeMongoCaseInsensitiveExactMatchRegex(value) },
        },
      ];
    }

    return [
      {
        [filterKey]: { [operator]: value },
      },
    ];
  }
}

class ToAccessTypeRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: string, operator: string): RestLayerFilter[] {
    if (!value.length) return [];
    return [
      {
        accessType: { [operator]: value },
      },
    ];
  }
}

// =========================
// =  Custom transformers  =
// =========================
export class ToOkrGridTagsRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string): RestLayerFilter[] {
    if (!values.length) {
      return [];
    }

    return [
      {
        $or: [
          {
            tags: {
              $elemMatch: {
                title: {
                  [operator]: [...values],
                },
              },
            },
          },
          {
            "metrics.tags": {
              $elemMatch: {
                title: {
                  [operator]: [...values],
                },
              },
            },
          },
        ],
      },
    ];
  }
}

export class ToSessionNoneOkrGridSessionRestLayerDynamicValueTransformer extends ToRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    if (!values.length) {
      return [{ sessionId: { $exists: false } }];
    }

    const previousValues = [];
    const map = values.reduce<Record<string, unknown>>((map, value) => {
      if (value === "activeSessions") {
        return {
          ...map,
          sessionId_activeSessions: { [operator]: [] },
        };
      }

      if (value !== "none") {
        previousValues.push(value);
      }

      return {
        ...map,
        [filterKey]: { [operator]: previousValues },
      };
    }, {});

    return Object.keys(map).map((key) => ({
      $or: [{ [key]: map[key] }, { sessionId: { $exists: false } }],
    }));
  }
}
