<top-nav-bar [hideGlobalSearch]="true" [navTitle]="'okr_suggestions' | localize" [topNavBarButtonsConfig]="topNavBarButtonsConfig" />

@if (stage === "initial") {
  <section class="sp-py-32 body" ui-row uiAlign="middle" uiJustify="center">
    <section ui-col uiFlex="628px">
      <section class="sp-p-16">
        <!-- TODO: https://quantive-inc.atlassian.net/browse/GVS-61200 -->
        <h5 class="title-text">Who will be involved?</h5>
        <p class="description">Select users and teams who will take responsibility for the success of these goals.</p>
      </section>

      <people-selector
        [a11yRequired]="true"
        [atLeastOneValue]="true"
        [placeholderKey]="'add_users' | localize"
        [request]="peopleSelectorRequestInternal"
        [selectedIds]="selectedIds"
        (selectionChange)="selectedIds = $event"
        data-test-id="suggest-okrs-modal-who-is-involved"></people-selector>

      <section class="sp-p-16 sp-mt-32">
        <!-- TODO: https://quantive-inc.atlassian.net/browse/GVS-61200 -->
        <h5 class="title-text">What should they achieve?</h5>
        <p class="description">
          Imagine the possibilities! Think about the milestones this group of people should aim for. Upload files such as company reports and analyses related to strategy, marketing, sales, finance
          etc. that these goals should align with.
        </p>
      </section>

      <form [formGroup]="objectiveForm" nzLayout="vertical" ui-form>
        <ui-form-item ui-row>
          <ui-form-control>
            <section class="text-area-section">
              <ui-input-text-area
                [isAutoresized]="true"
                [isBorderless]="true"
                [isManualResizeEnabled]="false"
                [textAreaClassName]="'sp-py-6 sp-pl-8'"
                [visibleRowCount]="3"
                e2eTestId="strategy"
                formControlName="strategy"
                uiPlaceholder="Describe the exciting milestones you envision..."></ui-input-text-area>

              <upload-button [documentIds]="documentIds" (uploadedDocumentIds)="onUploadedDocumentIds($event)" />
            </section>

            <p class="text-area-max-count">{{ this.getStrategy.value.length }}/5000</p>
          </ui-form-control>
        </ui-form-item>
      </form>
    </section>
  </section>
}

<!-- Second & Third Step | Generate/Edit Objectives & Generate/Edit Key Results -->
<div class="bg-color-gray-2 sp-py-32 body" *ngIf="stage !== 'initial' || hasSuggestedObjectives || suggestingObjectives || suggestingObjectivesError || getObjectives.value.length">
  <div ui-row uiJustify="center">
    <div ui-col uiFlex="628px">
      <div *ngIf="!suggestingObjectivesError" ui-row>
        <div *ngIf="suggestingObjectives">{{ "suggesting_objectives" | localize }}</div>
        <div *ngIf="!suggestingObjectives">{{ "these_are_your_recommended_objs" | localize }}</div>
      </div>

      <quantive-plus-wizard-objective
        class="sp-mt-12"
        *ngIf="!suggestingObjectivesError"
        [flowId]="flowId"
        [flowName]="flowName"
        [formGroup]="objectiveForm"
        [hasSuggestedKrs]="hasSuggestedKrs"
        [isEditing]="isEditing()"
        [isKeyResultsStage]="stage === 'keyresults'"
        [suggestingKeyResults]="suggestingKeyResults"
        (addKeyResultCb)="addKeyResult($event)"
        (confirmObjective)="confirmObjective($event)"
        (refreshKeyResultsErrorEvent)="suggestMoreKeyResults($event)"
        (refreshObjectiveDescriptionEvent)="refreshObjectiveDescription($event)"
        (removeKeyResultCb)="removeKeyResult($event)"
        (removeObjective)="removeObjective($event)"
        (suggestMoreKeyResultsEvent)="suggestMoreKeyResults($event)"
        ui-row>
      </quantive-plus-wizard-objective>

      <ng-container *ngIf="suggestingObjectives">
        <div class="sp-mt-12" *ngFor="let i of [1, 2, 3, 4]" ui-row>
          <div class="skeleton" ui-col uiFlex="1">
            <div ui-row>
              <div ui-col>
                <i class="fs-16px" ui-icon uiTheme="fill" uiType="goal"></i>
              </div>
              <div class="sp-ml-12" ui-col uiFlex="1">
                <ui-skeleton [uiActive]="true" [uiParagraph]="{ rows: 3, width: ['100%', '100%', '24px'] }" [uiSectionHeight]="{ title: 'h-md', paragraph: 'h-sm' }" [uiTitle]="false"></ui-skeleton>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <pi-error-card
        *ngIf="suggestingObjectivesError"
        [errorMessage]="'unable_to_suggest_objectives' | localize"
        [isRemoveBtnAvailable]="false"
        [refreshBtnTooltipTitle]="'suggest_again' | localize"
        (errorRefresh)="suggestObjectives()"></pi-error-card>

      <div class="sp-mt-12" *ngIf="suggestingMoreObjectivesError">
        <pi-error-card
          [errorMessage]="'unable_to_suggest_objectives' | localize"
          [isRemoveBtnAvailable]="false"
          [refreshBtnTooltipTitle]="'suggest_again' | localize"
          (errorRefresh)="suggestMoreObjectives()"></pi-error-card>
      </div>

      <div class="sp-mt-12" *ngIf="stage !== 'keyresults' && !suggestingObjectives && !suggestingKeyResults && !hasSuggestedKrs && getObjectives.value.length < 5" ui-row>
        <button class="sp-mr-12" *ngIf="!suggestingObjectivesError" [disabled]="isEditing()" (click)="suggestMoreObjectives()" ui-button uiShape="round" uiSize="default" uiType="link">
          <i ui-icon uiTheme="fill" uiType="loader_refresh"></i>
          <span class="sp-ml-6" *ngIf="getObjectives.value.length !== 0">{{ "suggest_more" | localize }}</span>
          <span class="sp-ml-6" *ngIf="getObjectives.value.length === 0">{{ "suggest_objectives" | localize | titlecase }}</span>
        </button>

        <button class="sp-mr-12" *ngIf="suggestingObjectivesError" (click)="suggestObjectives()" ui-button uiShape="round" uiSize="default" uiType="link">
          <i ui-icon uiTheme="fill" uiType="loader_refresh"></i>
          <span class="sp-ml-6">{{ "suggest_objectives" | localize | titlecase }}</span>
        </button>

        <button [disabled]="isEditing()" (click)="addObjective()" ui-button uiShape="round" uiSize="default" uiType="link">
          <i class="fs-18px" ui-icon uiType="plus"></i>
          <span class="sp-ml-6">{{ "add_manually" | localize }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<footer class="sp-px-32 sp-py-12">
  <div class="sp-mb-12" *ngIf="addingOkrsToWhiteboardError" ui-row uiJustify="end">
    <div class="sp-p-10 bg-color-error-3 error-container" ui-row uiAlign="middle">
      <i class="fs-16px text-color-error-8" ui-col ui-icon uiType="failed"></i>

      <div class="text-color-gray-13">
        <span>{{ "we_experience_problems" | localize }}</span>
        <a (click)="openIntercom()">{{ "contact_support" | localize }}.</a>
      </div>

      <i class="fs-16px" (click)="addingOkrsToWhiteboardError = false" ui-col ui-icon uiTheme="fill" uiType="ui-1_simple-remove"></i>
    </div>
  </div>

  <div [uiJustify]="'center'" ui-row uiAlign="middle">
    <div *ngIf="showConfirmationDialog" ui-row uiAlign="middle">
      <div class="sp-mr-12" ui-col>{{ "going_back_reset_progress" | localize }}</div>

      <button *ngIf="stage !== 'initial'" (click)="back()" ui-button ui-col uiShape="round" uiType="primary">{{ "back" | localize }}</button>
      <button *ngIf="stage !== 'initial'" (click)="reset(false)" ui-button ui-col uiShape="round" uiType="link">{{ "cancel" | localize }}</button>
    </div>

    <div *ngIf="!showConfirmationDialog">
      <button *ngIf="stage !== 'initial'" [disabled]="suggestingObjectives || suggestingKeyResults || isEditing()" (click)="reset(true)" ui-button uiShape="round" uiType="link">
        {{ "back" | localize }}
      </button>

      <button
        *ngIf="stage === 'initial'"
        [disabled]="getStrategy.invalid || isEditing()"
        (click)="suggestObjectives()"
        data-test-id="suggest-okrs-modal-suggest-objectives-button"
        ui-button
        uiShape="round"
        uiSize="large"
        uiType="primary">
        {{ "suggest_okrs" | localize }}
      </button>

      <button
        class="sp-ml-12"
        *ngIf="stage === 'objectives'"
        [disabled]="suggestingObjectives || isFetchingDescription() || getObjectives.invalid || isEditing()"
        (click)="suggestKeyResults()"
        ui-button
        uiShape="round"
        uiType="primary">
        <span class="sp-mr-6" data-test-id="suggest-okrs-modal-suggest-key-results-button">{{ "suggest_key_results" | localize | titlecase }}</span>
        <i ui-icon uiType="chevron-right-big"></i>
      </button>

      <button
        class="sp-ml-12"
        *ngIf="stage === 'keyresults'"
        [disabled]="suggestingKeyResults || isSuggestingMoreKeyResults() || getObjectives.invalid || isEditing()"
        (click)="addOkrsToWhiteboard()"
        ui-button
        uiShape="round"
        uiType="primary">
        {{ "add_okrs_to_wb" | localize }}
      </button>
    </div>
  </div>
</footer>
