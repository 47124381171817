import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule, UiThemeType } from "@quantive/ui-kit/icon";

@Component({
  selector: "header[strategy-ai-header]",
  templateUrl: "./strategy-ai-header.component.html",
  styleUrls: ["./strategy-ai-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiIconModule, UiButtonModule],
})
export class StrategyAiHeaderComponent {
  @Input({ required: true }) public headerTitle: string;

  @Input() public description: string;
  @Input() public uiType: string;
  @Input() public uiTheme: UiThemeType = "outline";
}
