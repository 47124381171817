import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class KpiSnapshotsEventsMediatorService {
  private kpiSnapshotDeleted$ = new Subject<{ kpiId: string; snapshotId: string }>();
  private kpiSnapshotCreated$ = new Subject<{ kpiId: string }>();
  private kpiSnapshotEdited$ = new Subject<{ kpiId: string; snapshotId: string }>();

  public onKpiSnapshotDeleted$(): Observable<{ kpiId: string; snapshotId: string }> {
    return this.kpiSnapshotDeleted$.asObservable();
  }

  public emitKpiSnapshotDeleted(kpiId: string, snapshotId: string): void {
    this.kpiSnapshotDeleted$.next({ kpiId, snapshotId });
  }

  public onKpiSnapshotCreated$(): Observable<{ kpiId: string }> {
    return this.kpiSnapshotCreated$.asObservable();
  }

  public emitKpiSnapshotCreated(kpiId: string): void {
    this.kpiSnapshotCreated$.next({ kpiId });
  }

  public onKpiSnapshotEdited$(): Observable<{ kpiId: string; snapshotId: string }> {
    return this.kpiSnapshotEdited$.asObservable();
  }

  public emitKpiSnapshotEdited(kpiId: string, snapshotId: string): void {
    this.kpiSnapshotEdited$.next({ kpiId, snapshotId });
  }
}
