import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { Observable } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PerplexityUsageStateService } from "@webapp/strategy/services/chat/perplexity-usage-state.service";
import { PREDEFINED_FRAMEWORKS_PROMPTS_MAP } from "../../ai-chat.constants";
import { ChatQuestionVM, ConversationVM, ResultBlockType } from "../../models/chat.vm-models";

@Component({
  selector: "ui-chat-ask-question",
  templateUrl: "./chat-ask-question.component.html",
  styleUrls: ["./chat-ask-question.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatAskQuestionComponent implements OnInit {
  public questionText = "";
  public generateButtonVisible = false;
  public isPerplexityEnabled$: Observable<boolean>;
  public usePerplexity$: Observable<boolean>;
  public prompts = [];
  public promptsMenu = [];
  public isDecisionsV2Enabled: boolean = false;

  @ViewChild("generateButton")
  public generateButton: TemplateRef<UiButtonComponent> | undefined;
  @ViewChild("questionInput")
  public questionInput: ElementRef<HTMLElement>;

  @Input() public placeholder = "Ask a question";
  @Input() public conversation: ConversationVM | undefined;

  @Output()
  public readonly askQuestion: EventEmitter<ChatQuestionVM> = new EventEmitter<ChatQuestionVM>();

  constructor(
    private featureTogglesFacade: FeatureTogglesFacade,
    private perplexityUsageStateService: PerplexityUsageStateService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.isPerplexityEnabled$ = this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.EnablePerplexityInPlanning);

    this.usePerplexity$ = this.perplexityUsageStateService.getUsePerplexity$();

    this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.DecisionsV2).subscribe((isEnabled) => {
      if (isEnabled) {
        this.isDecisionsV2Enabled = isEnabled;

        this.prompts = [
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.GenerateGoals],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.GenerateKPIs],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.QuickAnalysis],
        ];
        this.promptsMenu = [
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.DecisionMap],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.SWOT],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.PEST],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.Porter],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.Ansoff],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.BalancedScorecard],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.BlueOcean],
        ];
      } else {
        this.prompts = [
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.PEST],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.SWOT],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.Porter],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.BalancedScorecard],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.BlueOcean],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.Ansoff],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.GenerateGoals],
          PREDEFINED_FRAMEWORKS_PROMPTS_MAP[ResultBlockType.GenerateKPIs],
        ];
      }

      this.cdr.markForCheck();
    });

    this.cdr.markForCheck();
  }

  public submitQuestion(): void {
    if (this.questionText.trim() === "") {
      this.questionInput.nativeElement.focus();
      return;
    }
    this.askQuestion.emit({
      question: this.questionText,
      type: ResultBlockType.Text,
    });
    this.generateButtonVisible = false;
    this.questionText = "";
    this.cdr.markForCheck();
  }

  public executePredefinedPrompt(question: string, type: ResultBlockType, btn: HTMLButtonElement | HTMLLIElement): void {
    btn.blur();
    this.askQuestion.emit({
      question: question,
      type,
    });
    this.cdr.markForCheck();
  }

  public onFocusOut($event: FocusEvent): void {
    if (this.generateButton && this.generateButton.elementRef.nativeElement.contains($event.relatedTarget as Node)) return;
    this.generateButtonVisible = false;
  }

  public onFocusIn(): void {
    this.generateButtonVisible = true;
  }

  public togglePerplexityUsage(params: { usePerplexity: boolean }): void {
    this.perplexityUsageStateService.setUsePerplexity({
      usePerplexity: params.usePerplexity,
    });
  }
}
