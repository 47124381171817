<article
  [ngClass]="{
    info: type === 'info',
    error: type === 'error',
    warning: type === 'warning',
  }">
  <section class="icon-container">
    <i class="fs-20px" *ngIf="type === 'info'" ui-icon uiTheme="outline" uiType="info"></i>
    <i class="fs-20px" *ngIf="type === 'error'" ui-icon uiTheme="outline" uiType="failed"></i>
    <i class="fs-20px" *ngIf="type === 'warning'" ui-icon uiTheme="outline" uiType="attention"></i>
  </section>
  <section class="message-container">
    <section class="message-text">
      <section class="textual-content">
        <span *ngIf="message">{{ message }}</span>
        <ng-content *ngIf="projectContent"></ng-content>
        <span *ngIf="attachContactSupport">Please try again or </span>
        <a class="ui-link" *ngIf="attachContactSupport" (click)="showIntercom()" href="javascript:void(0)"> contact support. </a>
      </section>
      <button *ngIf="dismissType === 'x-button'" (click)="onDismissAlertClick()" ui-button uiShape="round" uiSize="default" uiType="default">
        <i class="fs-16px" ui-icon uiTheme="outline" uiType="close-big"></i>
      </button>
    </section>
    <section class="message-actions" *ngIf="buttonLabel && dismissType === 'text-button'">
      <button (click)="onDismissAlertClick()" ui-button uiShape="round" uiSize="default" uiType="default">
        <span>{{ buttonLabel }}</span>
      </button>
    </section>
  </section>
</article>
