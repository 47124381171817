import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";

@Injectable({ providedIn: "root" })
export class StrategyStrategicGuidedOkrApiService extends BaseApiServiceV2<{ id: string }, RequestPaging> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("strategic-guided-okr", { ...new ApiVersionModel("v2") }, httpClient, appConfig);
  }

  public createObjectiveEndpoint(): string {
    return `${this.getStrategyBasePath(HttpActions.post)}/objective`;
  }

  public createKeyResultEndpoint(): string {
    return `${this.getStrategyBasePath(HttpActions.post)}/kr`;
  }
}
