import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Intercom } from "@gtmhub/shared/intercom";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { FileSizePipe } from "@webapp/shared/pipes/file-size.pipe";
import { IngestionMessagingService } from "@webapp/shared/services/ingestion/ingestion-messaging.service";
import { IngestionProvidersModule } from "@webapp/shared/services/ingestion/ingestion-providers.module";
import { IngestionRepositoryService } from "@webapp/shared/services/ingestion/ingestion-repository.service";
import { DocumentIngestionVM } from "@webapp/shared/services/ingestion/ingestion.models";
import { truncateDocumentName } from "@webapp/strategy/services/documents/api-utils/document-mapper.utils";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { DEFAULT_ACCEPTED_FILE_EXTENSIONS, DEFAULT_ACCEPTED_MIME_TYPES, UiUploadFile } from "@webapp/ui/upload/upload.component";
import { UiUploadModule } from "@webapp/ui/upload/upload.module";
import { UploadStateService } from "../services/upload-state.service";

@UntilDestroy()
@Component({
  selector: "upload-form",
  templateUrl: "./upload-form.component.html",
  styleUrls: ["./upload-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiButtonModule, UiIconModule, UiUploadModule, IngestionProvidersModule, FileSizePipe, UiTooltipModule, UiLoadingIndicatorModule, LocalizationModule],
})
export class UploadFormComponent implements OnInit {
  @ViewChild("uploadButton", { read: ElementRef }) public uploadButtonElement: ElementRef<HTMLElement>;

  public filesLimit = 10;
  public documents: DocumentIngestionVM[] = [];

  public acceptedFileExtentions = DEFAULT_ACCEPTED_FILE_EXTENSIONS.join(", ");
  public acceptedMimeTypes = DEFAULT_ACCEPTED_MIME_TYPES;

  constructor(
    private cdr: ChangeDetectorRef,
    private uploadStateService: UploadStateService,
    private ingestionRepositoryService: IngestionRepositoryService,
    private ingestionMessagingService: IngestionMessagingService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public ngOnInit(): void {
    this.uploadStateService.uploadedDocuments$.pipe(untilDestroyed(this)).subscribe((documents) => {
      this.documents = documents;
      this.cdr.detectChanges();
    });

    this.ingestionMessagingService.onDataIngestionExecution$.pipe(untilDestroyed(this)).subscribe((document) => {
      this.uploadStateService.updateDocument(document.sourceKey, { status: document.status });
    });

    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.StrategyXLSXEnabled)
      .pipe(untilDestroyed(this))
      .subscribe((isEnabled) => {
        if (isEnabled) {
          this.acceptedMimeTypes.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
          this.acceptedFileExtentions += ", .xlsx";
          this.cdr.detectChanges();
        }
      });

    this.documents = this.uploadStateService.getCurrentDocuments();
    this.cdr.detectChanges();

    if (this.documents.length === 0) {
      this.triggerFileInputClick();
    }
  }

  public onUploadDocument(file: UiUploadFile): void {
    const doc: DocumentIngestionVM = {
      file: file as unknown as File,
      sizeInBytes: file.size,
      status: "uploading",
      sourceKey: file.uid,
      sourceName: file.name,
      sourceType: "file",
      truncatedName: truncateDocumentName(file.name),
    };

    this.uploadStateService.addDocument(doc);
    this.uploadDocument(doc);
  }

  public onRemoveDocument(document: DocumentIngestionVM): void {
    this.uploadStateService.removeDocument(document.sourceKey);
  }

  public onTryAgain(document: DocumentIngestionVM): void {
    this.uploadStateService.updateDocument(document.sourceKey, { status: "uploading" });
    this.uploadDocument(document);
  }

  public getIconTypeForDocument(document: DocumentIngestionVM): "pdf-file" | "presentation" | "spreadsheet" | "text-file" {
    if (document.sourceName.endsWith("pdf")) return "pdf-file";
    if (document.sourceName.endsWith("pptx")) return "presentation";
    if (document.sourceName.endsWith("xlsx")) return "spreadsheet";
    return "text-file";
  }

  public showIntercom(): void {
    Intercom("show");
  }

  private uploadDocument(document: DocumentIngestionVM): void {
    this.ingestionRepositoryService
      .uploadDocument$(document.file)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (documentId) => {
          if (this.documents.some((doc) => doc.sourceKey === documentId)) {
            // Remove already existing document
            this.uploadStateService.removeDocument(document.sourceKey);
          } else {
            // Update document with new ID
            this.uploadStateService.updateDocument(document.sourceKey, { sourceKey: documentId, status: "in_progress" });
          }
        },
        error: () => {
          this.uploadStateService.updateDocument(document.sourceKey, { status: "failed" });
        },
      });
  }

  private triggerFileInputClick(): void {
    const fileInputElement: HTMLInputElement = this.uploadButtonElement.nativeElement.querySelector("input[type='file']");
    if (fileInputElement) {
      fileInputElement.click();
    }
  }
}
