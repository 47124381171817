import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";

@Component({
  selector: "dropdown-menu-with-trigger",
  templateUrl: "./dropdown-menu-with-trigger.component.html",
  styleUrls: ["./dropdown-menu-with-trigger.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiButtonModule, UiIconModule, UiDropdownModule],
})
export class DropdownMenuWithTriggerComponent {
  @Input() public menuButtonIconType: string | null = null;
}
