import dayjs from "@webapp/shared/libs/dayjs";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import { StrategicBetVM, StrategicMapHypothesisVM } from "../../models/bets/strategic-bets.vm-models";
import { ContextVM } from "../../models/context/context.vm-models";
import { DocumentVM } from "../../models/documents/documents.vm-models";
import { AsyncOperationPreviewState } from "../../models/strategy.vm-models";
import { documentVM2EnrichedStrategyContextDocument } from "../documents/api-utils/document-mapper.utils";
import { getTaskPreviewStatePeriodString } from "./strategy-conversation.utils";

export const getOngoingTaskForName = (tasks: AsyncTaskVM[], taskName: AsyncTaskVM["name"]): AsyncTaskVM | null => {
  return tasks.find((item) => item.name === taskName && (item.status === "PENDING" || item.status === "STARTED"));
};

export const getLastRelatedTaskDoneDate = (documentId: string, tasksMap: Record<string, AsyncTaskVM[]>): string => {
  return tasksMap[documentId].reduce((acc, task) => {
    return task.dateDone && dayjs.utc(task.dateDone) > dayjs.utc(acc) ? task.dateDone : acc;
  }, "0");
};

export const getLastStartedTaskFromList = (tasks: AsyncTaskVM[]): AsyncTaskVM => {
  return tasks.reduce((currentLastStartedTask, task) => {
    return dayjs.utc(task.startedAt) > dayjs.utc(currentLastStartedTask.startedAt) ? task : currentLastStartedTask;
  }, tasks[0]);
};

export function getLatestHypothesisTask(hypothesis: StrategicMapHypothesisVM, tasks: AsyncTaskVM[]): AsyncTaskVM {
  const hypothesesTasks = tasks.filter((task) => task.itemId === hypothesis.conversationId);

  return hypothesesTasks.reduce((acc, task) => {
    if (!acc) {
      return task;
    }
    return dayjs(task.startedAt).isAfter(dayjs(acc.startedAt)) ? task : acc;
  }, null);
}

function getStateMapForResponses(
  bet: StrategicBetVM,
  responseTasks: AsyncTaskVM[]
): {
  loading: number;
  loaded: number;
  failed: number;
} {
  const stateMap = {
    loading: 0,
    loaded: 0,
    failed: 0,
  };

  if (!responseTasks || responseTasks.length === 0) {
    return stateMap;
  }

  bet.strategicMap?.areas.forEach((area) => {
    area.hypotheses.forEach((hypothesis) => {
      const hypothesisTask = getLatestHypothesisTask(hypothesis, responseTasks);
      if (hypothesisTask) {
        if (hypothesisTask.status === "PENDING" || hypothesisTask.status === "STARTED") {
          stateMap.loading++;
        } else if (hypothesisTask.status === "SUCCESS" && !hypothesis.isSeen) {
          stateMap.loaded++;
        } else if (hypothesisTask.status === "FAILURE" && !hypothesis.isSeen) {
          stateMap.failed++;
        }
      }
    });
  }, []);

  return stateMap;
}

function getStateMapForContext(
  context: ContextVM,
  documents: DocumentVM[],
  documentsTasksMap: Record<string, AsyncTaskVM[]>
): {
  loading: number;
  loaded: number;
  failed: number;
} {
  const stateMap = {
    loading: 0,
    loaded: 0,
    failed: 0,
  };

  documents.forEach((document) => {
    const enrichedDocument = documentVM2EnrichedStrategyContextDocument(document, documentsTasksMap);

    if (enrichedDocument.state === "SUMMARIZING" || enrichedDocument.state === "UPLOADING" || enrichedDocument.deletionState === "DELETING") {
      stateMap.loading++;
    } else if (!context.dateSeen || dayjs.utc(context.dateSeen) < dayjs.utc(enrichedDocument.lastRelatedTaskDoneDate)) {
      if (enrichedDocument.state === "FAILED_SUMMARIZATION" || enrichedDocument.state === "FAILED_UPLOAD" || enrichedDocument.deletionState === "FAILED_DELETION") {
        stateMap.failed++;
      }

      if (enrichedDocument.state === "SUMMARIZED" && enrichedDocument.deletionState === null) {
        stateMap.loaded++;
      }
    }
  });

  return stateMap;
}

function tooltipPrefixBuilder(responses: number, files: number, singularText: string, pluralText: string): string {
  const suffixes = [];

  if (responses) {
    suffixes.push(`${responses} response${responses > 1 ? "s " : " "}`);
  }

  if (files) {
    suffixes.push(`${files} file${files > 1 ? "s " : " "}`);
  }

  return suffixes.join(" and ") + (responses + files > 1 ? pluralText : singularText);
}

function getStateTooltip(
  responsesStateMap: { loading: number; loaded: number; failed: number },
  contextStateMap: { loading: number; loaded: number; failed: number },
  createStrategyMapTask: AsyncTaskVM,
  options: { isSeen: boolean }
): string {
  const loadingText =
    responsesStateMap.loading || contextStateMap.loading ? `${tooltipPrefixBuilder(responsesStateMap.loading, contextStateMap.loading, "is", "are")} in progress...` : "";

  const loadedText =
    responsesStateMap.loaded || contextStateMap.loaded ? `${tooltipPrefixBuilder(responsesStateMap.loaded, contextStateMap.loaded, "is", "are")} ready` : "";
  const failedText =
    responsesStateMap.failed || contextStateMap.failed ? tooltipPrefixBuilder(responsesStateMap.failed, contextStateMap.failed, "have error", "have errors") : "";

  const tooltip = [loadingText, loadedText, failedText].filter(Boolean);

  if (createStrategyMapTask) {
    // Decision Map Tooltip
    if (!options.isSeen && createStrategyMapTask?.status === "SUCCESS") {
      tooltip.push(`
      Decision Map was ready ${getTaskPreviewStatePeriodString(createStrategyMapTask.dateDone)}
      `);
    } else if (["PENDING", "STARTED"].includes(createStrategyMapTask.status)) {
      tooltip.push("Decision Map is in progress...");
    }
  }

  return tooltip.join("\n");
}

export function getBetTasksAsyncState(args: {
  bet: StrategicBetVM;
  betTasks: AsyncTaskVM[];
  documentTasks: AsyncTaskVM[];
  context: ContextVM;
  documents: DocumentVM[];
}): AsyncOperationPreviewState {
  const { bet, betTasks, documentTasks, context, documents } = args;
  const responseTasks = betTasks.filter((task) => task.name === "ask_question" || task.name === "regenerate_chat_answer");

  const responsesStateMap = getStateMapForResponses(bet, responseTasks);

  const documentTasksMap: Record<string, AsyncTaskVM[]> = documentTasks.reduce((acc, task) => {
    if (!acc[task.itemId]) {
      acc[task.itemId] = [];
    }
    acc[task.itemId].push(task);
    return acc;
  }, {});

  const contextStateMap = getStateMapForContext(context, documents, documentTasksMap);

  const createStrategyMapTask = betTasks.find((task) => task.name === "create_strategic_map");

  const tooltip = getStateTooltip(responsesStateMap, contextStateMap, createStrategyMapTask, { isSeen: bet.isSeen });

  if (contextStateMap.failed || responsesStateMap.failed) {
    return {
      seen: false,
      state: "FAILED",
      tooltip,
    };
  } else if (contextStateMap.loading || responsesStateMap.loading || createStrategyMapTask?.status === "PENDING" || createStrategyMapTask?.status === "STARTED") {
    return {
      seen: false,
      state: "LOADING",
      tooltip,
    };
  } else if (contextStateMap.loaded || responsesStateMap.loaded || !bet.isSeen) {
    return {
      seen: false,
      state: "LOADED",
      tooltip,
    };
  } else {
    return { seen: true };
  }
}
