import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { OkrWidgetDetailsDTO } from "../models/okr-widget-details.models";

@Injectable()
export class OkrWidgetDetailsApi extends BaseApiServiceV2<OkrWidgetDetailsDTO> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("uxcustomization/okr-widget-details", { ...new ApiVersionModel("v1") }, httpClient, appConfig);
  }

  public getPrincipalEndpointUrl(): string {
    return this.getBasePath(HttpActions.getAll).replace("uxcustomization/", "uxcustomization/principal/");
  }
}
