import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import {
  ChatAnsoffDataVM,
  ChatBalancedScoreCardDataVM,
  ChatBlueOceanDataVM,
  ChatGoalsDataVM,
  ChatKPIsDataVM,
  ChatPestDataVM,
  ChatPorterForcesDataVM,
  ChatSwotDataVM,
  ChatTextResultVM,
  ConversationChatEntryVM,
  FollowupTypes,
  LoadingDataVM,
  ResultBlockType,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { StrategyConfig } from "@webapp/strategy/models/strategy.vm-models";
import { StrategyConfigService } from "@webapp/strategy/services/config/strategy-config.service";

@Component({
  selector: "ui-chat-block-ai",
  templateUrl: "./chat-block-ai.component.html",
  styleUrls: ["./chat-block-ai.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBlockAiComponent implements OnInit {
  @Input() public message: ConversationChatEntryVM | undefined;
  @Input() public showRegenerate = false;
  @Output() public readonly addContent: EventEmitter<ConversationChatEntryVM> = new EventEmitter<ConversationChatEntryVM>();
  @Output() public readonly refreshContent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly retryErroredMessage: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly provideFeedback: EventEmitter<"positive" | "negative"> = new EventEmitter<"positive" | "negative">();

  @ViewChild("chatBlock")
  public chatBlockElement: ElementRef<HTMLElement>;

  public textBlockContent: ChatTextResultVM | undefined;
  public swotBlockContent: ChatSwotDataVM | undefined;
  public ansoffBlockContent: ChatAnsoffDataVM | undefined;
  public porterBlockContent: ChatPorterForcesDataVM | undefined;
  public pestBlockContent: ChatPestDataVM | undefined;
  public balancedScorecardBlockContent: ChatBalancedScoreCardDataVM | undefined;
  public blueOceanBlockContent: ChatBlueOceanDataVM | undefined;
  public goalsBlockContent: ChatGoalsDataVM | undefined;
  public kpisBlockContent: ChatKPIsDataVM | undefined;
  public loadingData: LoadingDataVM | undefined;
  public config$: Observable<StrategyConfig>;

  public insertButtonStyle: {
    display: "block" | "none";
    top: string;
    left: string;
  } = {
    display: "none",
    top: "0px",
    left: "0px",
  };

  private selectedText: string = "";

  constructor(
    private strategyConfigService: StrategyConfigService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  public get isSuggested(): boolean {
    const framework = (this.message?.blockContent as LoadingDataVM).framework;
    return !!framework && !["free_text", "elaborate", "research"].includes(framework);
  }

  public ngOnInit(): void {
    this.config$ = this.strategyConfigService.getConfig$();

    if (this.message) {
      switch (this.message.blockType) {
        case "swot":
          this.swotBlockContent = this.message.blockContent as ChatSwotDataVM;
          break;
        case "free_text":
          this.textBlockContent = this.message.blockContent as ChatTextResultVM;
          break;
        case "ansoff":
          this.ansoffBlockContent = this.message.blockContent as ChatAnsoffDataVM;
          break;
        case "porter":
          this.porterBlockContent = this.message.blockContent as ChatPorterForcesDataVM;
          break;
        case "pest":
          this.pestBlockContent = this.message.blockContent as ChatPestDataVM;
          break;
        case "balanced_scorecard":
          this.balancedScorecardBlockContent = this.message.blockContent as ChatBalancedScoreCardDataVM;
          break;
        case "blue_ocean":
          this.blueOceanBlockContent = this.message.blockContent as ChatBlueOceanDataVM;
          break;
        case "goals":
          this.goalsBlockContent = this.message.blockContent as ChatGoalsDataVM;
          break;
        case "kpis":
          this.kpisBlockContent = this.message.blockContent as ChatKPIsDataVM;
          break;
        case "loading":
          this.loadingData = this.message.blockContent as LoadingDataVM;
          break;
      }
    }
  }

  @HostListener("document:click", ["$event"])
  public onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.insertButtonStyle.display = "none";
    }
  }

  public onTextSelection(): void {
    const selection = window.getSelection();
    this.selectedText = selection.toString().trim();

    if (this.selectedText) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const chatBlockRect = this.chatBlockElement.nativeElement.getBoundingClientRect();

      this.insertButtonStyle.display = "block";
      this.insertButtonStyle.top = rect.top - 60 - chatBlockRect.top + "px";
      this.insertButtonStyle.left = rect.left - chatBlockRect.left + rect.width / 2 - 60 + "px";
    } else {
      this.insertButtonStyle.display = "none";
    }

    this.cdr.markForCheck();
  }

  public insertSelectedText(): void {
    const selectedTextBlock: ConversationChatEntryVM = {
      id: "",
      type: "ai",
      blockType: ResultBlockType.Text,
      blockContent: {
        text: this.selectedText,
      },
      followupActionDetails: {
        type: "" as FollowupTypes,
        isSuggestion: false,
      },
      inserting: false,
      insertingError: false,
    };

    this.insertButtonStyle.display = "none";
    this.selectedText = "";
    window.getSelection()?.removeAllRanges();

    this.addContent.emit(selectedTextBlock);
  }
}
