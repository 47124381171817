<ui-skeleton-line [style]="{ height: '22px', width: '60%' }" [uiActive]="true" />
<ui-skeleton-line
  class="date"
  [style]="{
    height: '16px',
    width: '40px',
    margin: '2px 0 2px auto',
  }"
  [uiActive]="true" />
<ui-skeleton-line [style]="{ height: '22px', width: '24px' }" [uiActive]="true" />
