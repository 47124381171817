import { Injectable } from "@angular/core";
import { ObservableCache } from "@webapp/core/state-management/observable-cache";
import { UserSettings } from "./user-settings.model";

@Injectable()
export class UserSettingsCache extends ObservableCache<UserSettings> {
  constructor() {
    super(undefined);
  }
}
