import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileSize",
  pure: true,
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  public transform(value: number): string {
    if (value) {
      if (isNaN(value)) return value.toString();
      const k = 1024;
      const dm = 2;
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(value) / Math.log(k));
      return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    return value.toString();
  }
}
