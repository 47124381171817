import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable } from "rxjs";
import { AsyncOperationPreviewState } from "../../models/strategy.vm-models";

@UntilDestroy()
@Injectable()
export class BetTasksStateService {
  constructor() {}

  private statusSubject$: BehaviorSubject<Record<string, AsyncOperationPreviewState>> = new BehaviorSubject<Record<string, AsyncOperationPreviewState>>(null);

  public get betStatus$(): Observable<Record<string, AsyncOperationPreviewState>> {
    return this.statusSubject$.asObservable();
  }

  public setStatus(betId: string, status: AsyncOperationPreviewState): void {
    this.statusSubject$.next({
      ...this.statusSubject$.value,
      [betId]: status,
    });
  }
}
