import { Injectable } from "@angular/core";
import { BaseUrlBuilder } from "@webapp/core/http/services/base-url-builder";

@Injectable()
export class StrategyConversationUrlBuilderV2Service extends BaseUrlBuilder {
  private strategyBaseUrl = "conversations";

  public getConversationsUrl(): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", `${this.strategyBaseUrl}`);
  }

  public getConversationUrl(conversationId: string): string {
    const conversationsUrl = this.getConversationsUrl();
    return `${conversationsUrl}/${conversationId}`;
  }

  public getConversationContextUrl(conversationId: string): string {
    const conversationUrl = this.getConversationUrl(conversationId);
    return `${conversationUrl}/context`;
  }

  public getResultUrl(conversationId: string): string {
    const conversationsUrl = this.getConversationUrl(conversationId);
    return `${conversationsUrl}/result`;
  }

  public getDocumentsUrl(): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", `documents`);
  }
}
