<section
  class="editable-section"
  #editable
  [innerText]="persistedContent"
  (focusin)="handleFocusIn()"
  (focusout)="handleFocusOut()"
  (keyup)="handleKeyUp(editable.innerText)"
  contenteditable="true"></section>
<span class="placeholder" *ngIf="enablePlaceholder && !editableAreaFocused && actualContent.trim() === ''" contenteditable="false">You can start typing here...</span>
<nav
  class="ai-help skipped-pdf-content"
  #aihelpbutton
  [ngClass]="{
    show: showContextMenu && contextMenuAvailable,
    hide: !showContextMenu,
  }"
  [ngStyle]="{
    top: selectionTop + 'px',
    left: selectionLeft + 'px',
  }">
  <gh-dropdown *ngIf="!aiWorking" [hasCustomButtonContent]="true" [menuItems]="aiHelpItems" iconName="artificial-intelligence">
    <button class="ai-help-button" gh-dropdown-custom-button-content ui-button uiShape="round" uiSize="default" uiType="link">
      <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="artificial-intelligence"></i>
      Explore
    </button>
  </gh-dropdown>
  <button class="ai-help-button" *ngIf="aiWorking" ui-button uiShape="round" uiSize="default" uiType="link">
    <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="artificial-intelligence-animated"></i>
    Explore
  </button>
</nav>
