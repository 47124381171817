import { FormControl, FormGroup } from "@angular/forms";
import { IntegerRange } from "../integer-range";
import { AccessDefinitionVM } from "../users.models";

export interface BetCollaboratorVM {
  userId: string;
  role: "collaborator" | "owner";
  uiState: "idle" | "removing" | "removed" | "error";
}

type StrategicBetProgressStepVM = IntegerRange<1, 4>;
export type StrategicBetProgressSubStepVM = IntegerRange<0, 2>;

export interface StrategicBetVM {
  id: string;
  accountId: string;
  name: string;
  description: string;
  ownerId: string;
  contextId: string;
  strategicMap: StrategicMapVM;
  createdAt: string;
  progressStep: StrategicBetProgressStepVM;
  progressSubStep: StrategicBetProgressSubStepVM;
  collaborators: BetCollaboratorVM[];
  access: AccessDefinitionVM;
  isSeen: boolean;
  onePagerContent: string;
  whiteboardId: string;
  conversationId: string;
}

export interface StrategicMapVM {
  id: string;
  areas: StrategicMapAreaVM[];
}

export interface StrategicMapAreaVM {
  uid?: string;
  id: string;
  title: string;
  status: string;
  hypotheses: StrategicMapHypothesisVM[];
  form?: FormGroup<{
    title: FormControl<string>;
  }>;
  saving: boolean;
  error?: boolean;
  editing?: boolean;
}

export interface StrategicMapHypothesisVM {
  uid?: string;
  conversationId: string;
  description: string;
  id: string;
  status: string;
  suggestedFrameworks: string[];
  title: string;
  form?: FormGroup<{
    statement: FormControl<string>;
  }>;
  saving: boolean;
  editing: boolean;
  isSeen: boolean;
  passedGrooming: boolean;
}

export interface OrderedStrategyResultEntryVM {
  id: string;
  blockType: StrategyResultBlockTypeVM;
  blockContent: StrategyResultBlockContentVM;
  isCreatedInThisSession: boolean;
  suggestionId?: string;
  shapeId?: string;
}

export enum StrategyResultBlockTypeVM {
  Text = "free_text",
  SWOT = "swot",
  PEST = "pest",
  Porter = "porter",
  BalancedScorecard = "balanced_scorecard",
  BlueOcean = "blue_ocean",
  Ansoff = "ansoff",
  Goals = "goals",
  KPIs = "kpis",
  SummaryText = "summary_text",
  Loading = "loading",
  Error = "error",
}

export type StrategyResultBlockContentVM =
  | SwotDataVM
  | AnsoffDataVM
  | PorterForcesDataVM
  | PestDataVM
  | BalancedScoreCardDataVM
  | BlueOceanDataVM
  | GoalsDataVM
  | KPIsDataVM
  | TextStrategyResultVM
  | TitleAndDescriptionDataVM
  | null;

export interface StructuredDataAspectVM {
  id: number;
  text: string;
  references: string[];
}
export interface SwotDataVM {
  strengths: StructuredDataAspectVM[];
  weaknesses: StructuredDataAspectVM[];
  opportunity: StructuredDataAspectVM[];
  threats: StructuredDataAspectVM[];
}
export interface AnsoffDataVM {
  diversification: StructuredDataAspectVM[];
  marketDevelopment: StructuredDataAspectVM[];
  marketPenetration: StructuredDataAspectVM[];
  productDevelopment: StructuredDataAspectVM[];
}

export interface PorterForcesDataVM {
  newEntrantThreats: StructuredDataAspectVM[];
  substituteThreats: StructuredDataAspectVM[];
  buyersBargainingPowers: StructuredDataAspectVM[];
  supplierBargainingPowers: StructuredDataAspectVM[];
  competitionRivalry: StructuredDataAspectVM[];
  conclusion: StructuredDataAspectVM[];
}

export interface PestDataVM {
  politicalFactors: StructuredDataAspectVM[];
  economicFactors: StructuredDataAspectVM[];
  socioculturalFactors: StructuredDataAspectVM[];
  technologicalFactors: StructuredDataAspectVM[];
  environmentalFactors: StructuredDataAspectVM[];
  legalFactors: StructuredDataAspectVM[];
}

export interface BalancedScoreCardDataVM {
  alignmentAndSynergies: StructuredDataAspectVM[];
  customerPerspectives: StructuredDataAspectVM[];
  financialPerspectives: StructuredDataAspectVM[];
  internalProcessPerspectives: StructuredDataAspectVM[];
  learningGrowthPerspectives: StructuredDataAspectVM[];
  riskAndAdaptations: StructuredDataAspectVM[];
}

export interface BlueOceanDataVM {
  errcGridAnalysis: StructuredDataAspectVM[];
  fourActionsFramework: StructuredDataAspectVM[];
  leadershipAndPeopleDevelopment: StructuredDataAspectVM[];
  marketBoundaryReconstruction: StructuredDataAspectVM[];
  sixPathsFramework: StructuredDataAspectVM[];
  strategicSequence: StructuredDataAspectVM[];
  strategyCanvas: StructuredDataAspectVM[];
  threeNoncustomerTiers: StructuredDataAspectVM[];
  valueInnovation: StructuredDataAspectVM[];
}

export interface GoalsDataVM {
  goals: GoalDataVM[];
  text?: string;
}

interface GoalDataVM {
  goal: string;
  keyResults: string[];
  reasoning: string;
  references?: string[];
}

export interface KPIsDataVM {
  kpis: KPIDataVM[];
  text?: string;
}

interface KPIDataVM {
  kpi: string;
  reasoning: string;
  references: string[];
}

export interface TextStrategyResultVM {
  text: string;
  references?: string[];
}

export interface LoadingBlockVM {
  text: string;
  references?: string[];
  isSuggested: boolean;
  type: StrategyResultBlockTypeVM | null;
}

export interface TitleAndDescriptionDataVM {
  title: string;
  description: string;
  loading?: boolean;
  exportPagebreak?: boolean;
}

export interface AsyncResponseVM {
  taskId: string;
}

export interface GenerateOKRsResponseVM {
  whiteboardId: string;
}

export type BetLockVM = Record<string, string>;
