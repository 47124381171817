import { Injectable } from "@angular/core";
import { ObservableCache } from "@webapp/core/state-management/observable-cache";
import { AccountDTO } from "../models/account.models";

@Injectable()
export class CurrentAccountCache extends ObservableCache<AccountDTO> {
  constructor() {
    super(undefined);
  }
}
