import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, inject } from "@angular/core";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { Observable, combineLatest, filter, interval, of, takeWhile } from "rxjs";

@Component({
  selector: "ui-loading-progress",
  templateUrl: "./loading-progress.component.html",
  styleUrl: "./loading-progress.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle],
})
export class LoadingProgressComponent implements OnChanges {
  @Input() public duration: number = 5000;
  @Input() public suspend: Observable<boolean>;

  public width = 0;
  private cdr = inject(ChangeDetectorRef);

  public ngOnChanges(changes: SimpleChangesOf<this>): void {
    if (changes.duration) {
      this.startTimer();
    }
  }

  private startTimer(): void {
    combineLatest({ tick: interval(this.duration / 100), shouldSuspend: this.suspend || of(false) })
      .pipe(
        filter(({ shouldSuspend }) => !shouldSuspend),
        takeWhile(() => this.width < 100)
      )
      .subscribe(() => {
        this.width += 1;
        this.cdr.markForCheck();
      });
  }
}
