import { TransitionService, UIRouterGlobals } from "@uirouter/angular";
import { ChangeDetectorRef, Injector, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { fromTransitionHook } from "@webapp/core/routing/rxjs";

type ComponentWithMember<MemberName extends string, MemberType> = Record<MemberName, MemberType>;
type ComponentWithOptionalMember<MemberName extends string, MemberType> = Partial<Record<MemberName, MemberType>>;
type TargetComponent<MemberName extends string, MemberType> = ComponentWithMember<MemberName, MemberType> &
  ComponentWithMember<"injector", Injector> &
  ComponentWithOptionalMember<"ngOnInit", OnInit["ngOnInit"]> &
  ComponentWithOptionalMember<"ngOnDestroy", OnDestroy["ngOnDestroy"]>;

/**
 * Decorator that binds a route parameter to an input property of a component.
 * The route parameter is read from the UIRouterGlobals.params.
 * @param adapter Used to map the route param to the desired member type.
 * @returns A decorator function.
 * @example
 * *@Component*({
 *    selector: "example",
 *    templateUrl: "./example-component.component.html",
 *    styleUrls: ["./example-component.component.less"],
 *    changeDetection: ChangeDetectionStrategy.OnPush,
 *  })
 *  export class ExampleComponent {
 *    *@Input*()
 *    *@RouteParamInput*<"pageNumber", number>({ adapter: (param: string) => Number(param) })
 *    public pageNumber: number;
 *
 *    constructor(public injector: Injector) {}
 *  }
 * */
export function RouteParamInput<MemberName extends string, MemberType extends TargetComponent<MemberName, MemberType>[MemberName]>({
  adapter,
}: {
  adapter(routeParamValue: string): MemberType;
}) {
  return function (target: TargetComponent<MemberName, MemberType>, key: MemberName): void {
    const originalNgOnInit = target.ngOnInit;
    const originalNgOnDestroy = target.ngOnDestroy;
    const destroy$ = new Subject<void>();

    target.ngOnDestroy = function (): void {
      destroy$.next();
      destroy$.complete();

      // call the original ngOnDestroy method
      originalNgOnDestroy?.apply(this);
    };

    target.ngOnInit = function (): void {
      const routerGlobals = this.injector.get(UIRouterGlobals);
      const cdr = this.injector.get(ChangeDetectorRef);

      const paramValue = routerGlobals.params[key];
      if (paramValue !== undefined && paramValue !== null) {
        // set the value of the input property to the route param value
        this[key] = adapter(paramValue);
        if (!NgZone.isInAngularZone()) {
          cdr.markForCheck();
        }
      }

      const transitionService = this.injector.get(TransitionService);
      fromTransitionHook(transitionService, "onSuccess", {})
        .pipe(takeUntil(destroy$))
        .subscribe((transition) => {
          // update the value of the input property on route param change
          if (transition.paramsChanged()?.[key]) {
            this[key] = adapter(routerGlobals.params[key]);
            if (!NgZone.isInAngularZone()) {
              cdr.markForCheck();
            }
          }
        });

      // call the original ngOnInit method
      originalNgOnInit?.apply(this);
    };
  };
}
