import { OkrMethodologyFacade } from "@gtmhub/goals/components/facades/okr-methodology.facade";
import { WhiteboardsShapeService } from "@gtmhub/whiteboards/services/whiteboards-shapes.service";
import { WhiteboardsService } from "@gtmhub/whiteboards/whiteboards-service";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

export const WhiteboardsServiceProvider = {
  provide: WhiteboardsService,
  useFactory: providerUpgradeFactory("WhiteboardsService"),
  deps: ["$injector"],
};

export const OkrMethodologyFacadeProvider = {
  provide: OkrMethodologyFacade,
  useFactory: providerUpgradeFactory("OkrMethodologyFacade"),
  deps: ["$injector"],
};

export const WhiteboardsShapeServiceProvider = {
  provide: WhiteboardsShapeService,
  useFactory: providerUpgradeFactory("WhiteboardsShapeService"),
  deps: ["$injector"],
};
