import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiCollapseModule } from "@quantive/ui-kit/collapse";

@Component({
  standalone: true,
  selector: "error-details",
  templateUrl: "./error-details.component.html",
  styleUrls: ["./error-details.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiCollapseModule],
})
export class ErrorDetailsComponent {
  @Input()
  public transactionId: string;
}
