import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { OrderedStrategyResultEntryDTOv2 } from "@webapp/strategy/models/bets/strategic-bets.dto-v2-models";
import { OrderedStrategyResultEntryVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { StrategyConversationDTOv2 } from "@webapp/strategy/models/strategy-conversation/strategy-conversation.dto-v2-models";
import { StrategyConversationVM } from "@webapp/strategy/models/strategy-conversation/strategy-conversation.vm-models";
import { IStrategyConversationApi } from "../strategy-conversation-api.interface";
import { resultBlockDTOv22VM, resultBlockVM2DTOv2, strategyConversationDTOv22VM } from "./strategy-conversation-mapper-v2.utils";
import { StrategyConversationUrlBuilderV2Service } from "./strategy-conversation-url-builder-v2.service";

@Injectable()
export class StrategyConversationApiV2Service extends PlainBaseApiService implements IStrategyConversationApi {
  constructor(
    protected httpClient: HttpClient,
    apiednpointService: ApiEndpointService,
    private strategyConversationUrlBuilderV2Service: StrategyConversationUrlBuilderV2Service
  ) {
    super(httpClient, apiednpointService);
  }

  public getStrategyConversation$(conversationId: string): Observable<StrategyConversationVM> {
    const singleConversationUrl = this.strategyConversationUrlBuilderV2Service.getConversationUrl(conversationId);
    return this.get$<StrategyConversationDTOv2>(singleConversationUrl).pipe(map((conversation: StrategyConversationDTOv2) => strategyConversationDTOv22VM(conversation)));
  }

  public updateConversationResults$(conversationId: string, resultEntries: OrderedStrategyResultEntryVM[]): Observable<OrderedStrategyResultEntryVM[]> {
    const resultUrl = this.strategyConversationUrlBuilderV2Service.getResultUrl(conversationId);
    const body = {
      report: {
        orderedResults: resultEntries.map((resultEntry) => resultBlockVM2DTOv2(resultEntry)),
      },
    };
    return this.patch$<OrderedStrategyResultEntryDTOv2[]>(resultUrl, body).pipe(
      map((updatedResults: OrderedStrategyResultEntryDTOv2[]) => updatedResults.map((updatedResultEntry) => resultBlockDTOv22VM(updatedResultEntry)))
    );
  }
}
