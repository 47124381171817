import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "results-box-label",
  templateUrl: "./results-box-label.component.html",
  styleUrls: ["./results-box-label.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class ResultsBoxLabelComponent {
  @Input() public label: string;
}
