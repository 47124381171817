import { Inject, Injectable, InjectionToken, Provider } from "@angular/core";
import { ApmService } from "@gtmhub/core/tracing/apm.service";

type RenderCollector = {
  name: string;
  elementsRegister: Set<string>;
};

const COLLECTOR_NAME = new InjectionToken<string>("COLLECTOR_NAME");

/**
 * Use this function to provide the RenderCollectorService instance for each screen you want to trace.
 * @param {string} name The collector name we want set for tracing. The field is mandatory for proper dependency injection.
 */
export function renderCollectorServiceProvider(name: string): Provider {
  return {
    provide: RenderCollectorService,
    useFactory: (apmService: ApmService) => new RenderCollectorService(apmService, name),
    deps: [ApmService],
  };
}

/**
 * RenderCollectorService instance should be provided per traced screen
 *
 * In order to properly provide the service use the `renderCollectorServiceProvider` util
 * at the level of the component holding the screen you want to trace. Then import `TraceElementDirective`
 * in every subsequent module of the screen being trace and add it to all the elements you want traced,
 * this way each `TraceElementDirective` will get the nearest instance of `RenderCollectorService`
 *
 * ```
 * @Component({
 *   selector: "app",
 *   template: `
 *   <child-cmp-1></child-cmp-1>
 *   <child-cmp-2></child-cmp-2>
 *   `,
 *   providers: [renderCollectorServiceProvider("trace-app-cmp")]
 *   imports: [ChildCmp1Component, ChildModule]
 * })
 * export class AppComponent {}
 * ...
 * @Component({
 *   selector: "child-cmp-1",
 *   template: `<something-to-trace traceElement="trace-something-to-trace"></something-to-trace>`,
 *   standalone: true,
 *   imports: [TraceElementDirective],
 * })
 * export class ChildCmp1Component {}
 * ...
 * @Component({
 *   selector: "child-cmp-2",
 *   template: `
 *   <something-else-to-trace *ngFor="let item of items; let first = first; let last = last" traceElement="trace-something-else-to-trace" [tracingBounds]="{ 'start': first, 'end': last }"></something-else-to-trace>
 *   @for (item of items; let first = $first; let last = $last) {
 *   <something-third-to-trace traceElement="trace-something-third-to-trace" [tracingBounds]="{ 'start': first, 'end': last }"></something-third-to-trace>
 *   }
 *   `,
 * })
 * export class ChildCmp2Component {}
 * ...
 * @NgModule({
 *   declarations: [ChildCmp2Component],
 *   exports: [ChildCmp2Component],
 *   imports: [TraceElementDirective],
 *   // No need for provider for the RenderCollectorService here, since we provide it at the level of the screen we're tracing
 * })
 * export class ChildModule {}
 * ```
 */
@Injectable()
export class RenderCollectorService {
  private collector: RenderCollector = null;

  constructor(
    private apmService: ApmService,
    @Inject(COLLECTOR_NAME) collectorName: string
  ) {
    if (!collectorName) {
      throw new Error("Collector name is mandatory!");
    }

    this.collector = {
      name: collectorName,
      elementsRegister: new Set<string>(),
    };
  }

  public addElement(elName: string): void {
    if (this.collector.elementsRegister.has(elName)) {
      console.warn("Element already being traced!");
      return;
    }

    this.collector.elementsRegister.add(elName);
    this.addSpanTracingElement(elName);
  }

  public finishElementRender(elName: string): void {
    if (!this.collector.elementsRegister.has(elName)) {
      console.warn("No such element being traced!");
      return;
    }

    this.endSpanTracingElement(elName);
  }

  private addSpanTracingElement(name: string): void {
    this.apmService.startRenderSpan(`[${this.collector.name}]${name}`);
  }

  private endSpanTracingElement(name: string): void {
    this.apmService.endRenderSpan(`[${this.collector.name}]${name}`);
  }
}
