import { UIErrorMappingPerAction } from "../error-handling.model";

export type DecisionAction = "delete" | "leave";

export const BetActionErrorMapIntercom: UIErrorMappingPerAction<DecisionAction> = {
  delete: {
    title: "Delete decision",
    intercomAction: "trying to delete a decision",
  },
  leave: {
    title: "Leave decision",
    intercomAction: "trying to leave a decision",
  },
};
