import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class PerplexityUsageStateService {
  private usePerplexity$ = new BehaviorSubject<boolean>(false);

  public getUsePerplexity$(): Observable<boolean> {
    return this.usePerplexity$.asObservable();
  }

  public setUsePerplexity(params: { usePerplexity: boolean }): void {
    this.usePerplexity$.next(params.usePerplexity);
  }
}
