import { IHttpResponse, IHttpService, IPromise } from "angular";
import { IAccount } from "@gtmhub/core";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { EnvironmentService, getPathPrefix } from "@gtmhub/env";
import {
  getLanguageFromLocalStorage,
  getMethodologyFromLocalStorage,
  isDefaultLanguage,
  setLanguageInLocalStorage,
  setMethodologyTypeInLocalStorage,
} from "@gtmhub/localization";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { setCurrentUserId } from "@gtmhub/users";
import { AccountResolverService } from "@webapp/accounts";
import { LastUrlService } from "@webapp/shared/services/last-url-service";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";
import { IUser } from "../users/models";
import { IAuthResponse, IAuthorizationProcessStatus, IUserAccountResponse } from "./models";

const isNew = (user: IUser, account: IAccount) => user.id === "" && account.id === "";

const isSuspended = (account: IAccount) => !account.isActive;

const isDefault = (user: IUser, account: IAccount) => user.accountId === account.id;

// return 'true' if the login identity that the user used was successfully linked with
// another identity. In this case the user needs to be redirected to the login since the
// token he is issued is for the slave identity and therefore is no longer valid.
const isLinked = (user: IUser, auth0UserId: string) => user.clientId !== auth0UserId;

const isSwitched = (user: IUser) => user.isSwitched;

export class AuthorizationService {
  public static $inject = [
    "$rootScope",
    "$http",
    "EnvironmentService",
    "AccountResolverService",
    "LastUrlService",
    "CurrentUserRepository",
    "UserProfileService",
    "ApmService",
  ];

  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $http: IHttpService,
    private env: EnvironmentService,
    private accountResolverService: AccountResolverService,
    private lastUrlService: LastUrlService,
    private currentUserRepository: CurrentUserRepository,
    private profileService: UserProfileService,
    private apmService: ApmService
  ) {}

  public process(auth0UserId: string, host: string): IPromise<IAuthorizationProcessStatus> {
    return this.initAccount(auth0UserId, host).then((result) => {
      const { user, account } = result;

      this.currentUserRepository.setUserSettings(user);
      this.apmService.setUserContext({ id: user.id });
      setCurrentUserId(user.id);
      let shouldReload = false;
      if (getLanguageFromLocalStorage() !== user.language) {
        setLanguageInLocalStorage(user.language);
        shouldReload = isDefaultLanguage(user.language) === false;
      }

      const aMethodology = account.methodologyType === undefined ? "default" : account.methodologyType;
      if (getMethodologyFromLocalStorage() !== aMethodology) {
        setMethodologyTypeInLocalStorage(aMethodology);
        shouldReload = true;
      }

      this.accountResolverService.setAccountData(account);

      // confidenceSetting will be used to display KR confidence levels
      this.$rootScope.confidenceSettings = account.confidenceSettings;

      if (isNew(user, account)) {
        return { status: "new", shouldReload: false };
      }

      if (isSuspended(account)) {
        return { status: "suspended", shouldReload };
      }

      if (isSwitched(user)) {
        return { status: "switched", shouldReload };
      }

      if (isLinked(user, auth0UserId)) {
        return { status: "linked", shouldReload };
      }

      if (this.isReturning(user, account)) {
        return { status: "returning", shouldReload };
      }

      if (isDefault(user, account)) {
        return { status: "default", shouldReload };
      }

      throw new Error("Cannot process authorization for user");
    });
  }

  public getAuthData(data: { email?: string; domain?: string }): IPromise<IHttpResponse<IAuthResponse>> {
    return this.$http.post<IAuthResponse>(`${getPathPrefix()}/auth`, data);
  }

  private initAccount(auth0UserId: string, host: string): IPromise<IUserAccountResponse> {
    const accDomain = host.split(".")[0];

    // replacing '/' in auth0UserIds with | since our API router does not support encoded slashes in a path segment
    auth0UserId = auth0UserId.split("/").join("|");

    const url = this.env.getApiEndpoint(`/users/${accDomain}/${encodeURIComponent(auth0UserId)}`);

    return this.$http.get<IUserAccountResponse>(url).then((result) => {
      const user = result.data.user;
      this.profileService.setProfileFromUser(user);

      return result.data;
    });
  }

  private isReturning(user: IUser, account: IAccount) {
    return user && account && user.accountId === account.id && this.lastUrlService.getStoredLocationPath();
  }
}
