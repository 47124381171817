import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { UserSettings } from "./user-settings.model";

@Injectable()
export class UserSettingsApiService extends PlainBaseApiService {
  public getUserSettings$(): Observable<UserSettings> {
    return this.get$<UserSettings>(this.getUserSettingsUrl());
  }

  public getUserSettingsV2$(): Observable<{ settings: UserSettings }> {
    return this.get$<{ settings: UserSettings }>(this.getUserSettingsUrlV2());
  }

  public updateUserSettings$(userSettings: UserSettings): Observable<UserSettings> {
    return this.post$(this.getUserSettingsUrl(), userSettings);
  }

  public updateUserSettingsV2$(userSettings: UserSettings): Observable<UserSettings> {
    return this.post$(this.getUserSettingsUrlV2(), userSettings);
  }

  private getUserSettingsUrl(): string {
    return this.getApiEndpointV1WithAdditionalBase("strategy", "user_settings");
  }

  private getUserSettingsUrlV2(): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", "user-settings");
  }
}
