<section class="result-container" [ngClass]="{ highlighted: isNewBlock && result.isCreatedInThisSession }">
  <section class="result-block-data">
    <swot-result-block
      class="swot-analysis"
      *ngIf="result.blockType === 'swot'"
      [swot]="swotBlockContent"
      (contentChanged)="editSwot($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)"></swot-result-block>
    <balanced-scorecard-result-block
      class="balanced-scorecard-analysis"
      *ngIf="result.blockType === 'balanced_scorecard'"
      [balancedScoreCard]="balancedScorecardBlockContent"
      (contentChanged)="editBalancedScorecard($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)">
    </balanced-scorecard-result-block>
    <porter-result-block
      class="porter-analysis"
      *ngIf="result.blockType === 'porter'"
      [porter]="porterBlockContent"
      (contentChanged)="editPorter($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)"></porter-result-block>
    <pest-result-block
      class="pest-analysis"
      *ngIf="result.blockType === 'pest'"
      [pest]="pestBlockContent"
      (contentChanged)="editPest($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)"></pest-result-block>
    <ansoff-result-block
      class="ansoff-analysis"
      *ngIf="result.blockType === 'ansoff'"
      [ansoff]="ansoffBlockContent"
      (contentChanged)="editAnsoff($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)"></ansoff-result-block>
    <blue-ocean-result-block
      class="blue-ocean-analysis"
      *ngIf="result.blockType === 'blue_ocean'"
      [blueOcean]="blueOceanBlockContent"
      (contentChanged)="editBlueOcean($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)"></blue-ocean-result-block>
    <goals-result-block
      *ngIf="result.blockType === 'goals'"
      [goalsBlockContent]="goalsBlockContent"
      (contentChanged)="editGoals($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)" />
    <kpis-result-block
      *ngIf="result.blockType === 'kpis'"
      [kpisBlockContent]="kpisBlockContent"
      (contentChanged)="editKpis($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)" />
    <actionable-text-block
      class="free-text"
      *ngIf="result.blockType === 'free_text'"
      [content]="textBlockContent.text"
      [enablePlaceholder]="true"
      (contentChanged)="editFreeText($event)"
      (executeAction)="executeAction($event)"
      (focusOutWithChanges)="focusOutWithChangesHandler(result)"></actionable-text-block>
    <title-description-result-block *ngIf="result.blockType === 'summary_text'" [content]="titleDescriptionBlockContent" (contentChanged)="editDescriptionText($event)">
    </title-description-result-block>

    <section class="result-block-actions skipped-pdf-content" *ngIf="result.id !== dummyResultBlockId && result.blockType !== 'summary_text'">
      <gh-dropdown class="result-block-dropdown" [menuItems]="resultBlockItems" iconName="menu-horizontal"></gh-dropdown>
    </section>
  </section>
</section>
