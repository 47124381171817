import { IQService, auto } from "angular";
import uiRouter, { StateProvider, StateService, UIRouterGlobals, UrlRouterProvider, UrlService } from "@uirouter/angularjs";
import { combineLatest, from, take } from "rxjs";
import { CurrentUserRolesActions, RolesActions } from "@gtmhub/roles";
import { MessagingService } from "@gtmhub/sockets";
import sockets from "@gtmhub/sockets/module";
import { INgRedux } from "@gtmhub/state-management";
import { AccountResolverService } from "@webapp/accounts";
import { IColoringSetup } from "@webapp/configuration/models/configuration.model";
import { ColoringService } from "@webapp/configuration/services/coloring.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { CurrentUserRepository } from "@webapp/users";
import { AccountService } from "./accounts/accounts.service";
import { AssigneeActions } from "./assignees";
import { ApmService } from "./core/tracing/apm.service";
import { CustomFieldActions } from "./customFields/redux/custom-field-actions";
import { states as editionPlanChangeStates } from "./edition-plan-change/edition-plan-change.states";
import { states as notificationsStates } from "./notifications/notifications.states";
import "./resolve-guards";
import { StateFactory } from "./state-factory";
import { states as userProfileStates } from "./user-profile/user-profile.states";
import { getCurrentUserLoginsCount } from "./users";
import { PermissionsActions } from "./users/redux";

function registerStates($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider): void {
  function register(states: unknown[]): void {
    for (const state of states) {
      $stateProvider.state(state);
    }
  }

  const stateFactory = new StateFactory();

  // root state that requires a token, profile, effectivePermissions and available features (editions)
  // see accountResolverService authResolverService
  const rootProtectedState = stateFactory.rootProtectedState();
  rootProtectedState.resolve = {
    // all sub-states are going to resolve the returned promise which will make the effective permissions available in the $rootScope
    effectivePermissions: [
      "$ngRedux",
      "PermissionsActions",
      function ($ngRedux: INgRedux, permissionsActions: PermissionsActions) {
        return $ngRedux.dispatch(permissionsActions.getEffectivePermissionsIfMissing());
      },
    ],
    _account: [
      "$injector",
      function ($injector: auto.IInjectorService) {
        const accountResolverService = $injector.get<AccountResolverService>("AccountResolverService");
        return accountResolverService.initAccount();
      },
    ],
    _coloring: [
      "$injector",
      "AccountService",
      function ($injector: auto.IInjectorService, accountService: AccountService) {
        const accountColoring = accountService.getAccountSetting<IColoringSetup>("coloring");
        const coloringService = $injector.get<ColoringService>("ColoringService");

        return coloringService.setColoring(accountColoring);
      },
    ],
    webSocketMessaging: [
      "MessagingService",
      function (messagingService: MessagingService) {
        messagingService.init();
      },
    ],
    currentUserRoles: [
      "$ngRedux",
      "CurrentUserRolesActions",
      function ($ngRedux: INgRedux, currentUserRolesActions: CurrentUserRolesActions) {
        return $ngRedux.dispatch(currentUserRolesActions.getCurrentUserRoles());
      },
    ],
    roles: [
      "$ngRedux",
      "RolesActions",
      function ($ngRedux: INgRedux, rolesActions: RolesActions) {
        return $ngRedux.dispatch(rolesActions.getRolesIfMissing());
      },
    ],
    customFields: [
      "$ngRedux",
      "CustomFieldActions",
      function ($ngRedux: INgRedux, customFieldActions: CustomFieldActions) {
        return $ngRedux.dispatch(customFieldActions.getCustomFields());
      },
    ],
    assignees: [
      "$ngRedux",
      "AssigneeActions",
      function ($ngRedux: INgRedux, assigneeActions: AssigneeActions) {
        // Don't return here since we don't want this request to block the routing
        $ngRedux.dispatch(assigneeActions.getAssignees());
      },
    ],
    privacyNotice: [
      "$injector",
      "$state",
      "$q",
      ($injector: auto.IInjectorService, $state: StateService, $q: IQService) => {
        const defer = $q.defer();
        const featureTogglesFacade = $injector.get<FeatureTogglesFacade>("FeatureTogglesFacade");
        const uiRouterGlobals = $injector.get<UIRouterGlobals>("$uiRouterGlobals");
        featureTogglesFacade
          .isFeatureAvailable$(FeatureFlag.PrivacyNotice)
          .pipe(take(1))
          .subscribe({
            next: (enabled: boolean) => {
              if (!enabled) {
                defer.resolve();
                return;
              }

              const currentUserRepository = $injector.get<CurrentUserRepository>("CurrentUserRepository");
              const hasAcceptedPrivacyNotice = currentUserRepository.getUserSetting<boolean>("hasAcceptedPrivacyNotice");
              const isFirstLogin = getCurrentUserLoginsCount() === 1;

              if (isFirstLogin && hasAcceptedPrivacyNotice !== true) {
                currentUserRepository.setUserSetting({ hasAcceptedPrivacyNotice: false });
                if (uiRouterGlobals.current.name !== "gtmhub.privacyNotice") {
                  $state.go("gtmhub.privacyNotice");
                }
              } else if (hasAcceptedPrivacyNotice === false && uiRouterGlobals.current.name !== "gtmhub.privacyNotice") {
                $state.go("gtmhub.privacyNotice");
              }
              defer.resolve();
            },
          });

        return defer.promise;
      },
    ],
    addAccountDetails: [
      "$injector",
      "$state",
      "$q",
      ($injector: auto.IInjectorService, $state: StateService, $q: IQService) => {
        const defer = $q.defer();
        const featureTogglesFacade = $injector.get<FeatureTogglesFacade>("FeatureTogglesFacade");
        const accountResolverService = $injector.get<AccountResolverService>("AccountResolverService");

        combineLatest([featureTogglesFacade.isFeatureAvailable$(FeatureFlag.AccountMarketingInfo), from(accountResolverService.shouldShowAccountDetailsForm())])
          .pipe(take(1))
          .subscribe(([featureEnabled, shouldShowForm]) => {
            if (featureEnabled && shouldShowForm) {
              $state.go("gtmhub.addAccountDetails");
              defer.resolve();
            } else {
              defer.resolve();
            }
          });

        return defer.promise;
      },
    ],
  };

  // We want to create a performance mark once all dependencies
  // for the root state have been resolved
  rootProtectedState.onEnter = [
    "ApmService",
    ...Object.keys(rootProtectedState.resolve),
    (apmService: ApmService) => {
      apmService.addMarkToCurrentTransactions("rootStateEnter");
    },
  ];

  $stateProvider.state("default", stateFactory.default());
  $stateProvider.state("gtmhub", rootProtectedState);
  $stateProvider.state("error", stateFactory.errorState());

  // ======================================================================================
  // multi-account
  $stateProvider.state("gtmhub.multiAccountInvitationResponse", stateFactory.multiAccountStates().respondToMultiInvitation());

  // ======================================================================================
  // account bootstrapStates
  $stateProvider.state("accountBootstrap", stateFactory.accountBootstrapStates().accountBootstrapRoot());
  $stateProvider.state("accountBootstrap.resolveToken", stateFactory.accountBootstrapStates().resolveToken());
  $stateProvider.state("accountBootstrap.resolveAccount", stateFactory.accountBootstrapStates().resolveAccount());
  $stateProvider.state("accountBootstrap.createAccount", stateFactory.accountBootstrapStates().createAccount());
  $stateProvider.state("accountSuspended", stateFactory.accountBootstrapStates().accountSuspendedRoot());
  $stateProvider.state("accountBootstrapModal", stateFactory.accountBootstrapModalStates().accountBootstrapModalRoot());
  $stateProvider.state("accountBootstrapModal.accountSwitched", stateFactory.accountBootstrapModalStates().accountSwitchedRoot());

  $stateProvider.state("userDeactivated", stateFactory.accountBootstrapStates().userDeactivatedRoot());

  // redirect states needed for wrong URLs in notifications
  $stateProvider.state(
    "gtmhub.fromGoalsMetricToHomeMetric",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/goals/metric/:metricId/", state: "gtmhub.home.dashboard.metric" })
  );
  $stateProvider.state(
    "gtmhub.fromMetricToHomeMetric",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/home/metric/:metricId/", state: "gtmhub.home.dashboard.metric" })
  );
  $stateProvider.state(
    "gtmhub.fromGoalsMetricToHomeMetricWithoutTrailingSlash",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/goals/metric/:metricId", state: "gtmhub.home.dashboard.metric" })
  );
  $stateProvider.state(
    "gtmhub.fromHomeDoubleSlashGoalsMetricToHomeMetric",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/home//goals/metric/:metricId/", state: "gtmhub.home.dashboard.metric" })
  );
  $stateProvider.state(
    "gtmhub.fromHomeDoubleSlashGoalsMetricToHomeMetricWithoutTrailingSlash",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/home//goals/metric/:metricId", state: "gtmhub.home.dashboard.metric" })
  );
  $stateProvider.state(
    "gtmhub.fromGoalsToHomeGoal",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/goals/:id/metrics/", state: "gtmhub.home.dashboard._goals.goal" })
  );
  $stateProvider.state(
    "gtmhub.fromGoalsToHomeGoalWithoutTrailingSlash",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/goals/:id/metrics", state: "gtmhub.home.dashboard._goals.goal" })
  );
  $stateProvider.state(
    "gtmhub.fromHomeDoubleSlashGoalsToHomeGoal",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/home//goals/:id/metrics/", state: "gtmhub.home.dashboard._goals.goal" })
  );
  $stateProvider.state(
    "gtmhub.fromHomeDoubleSlashGoalsToHomeGoalWithoutTrailingSlash",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/home//goals/:id/metrics", state: "gtmhub.home.dashboard._goals.goal" })
  );
  $stateProvider.state(
    "gtmhub.toTasksTask",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/tasks/task/task-preview/:taskId/", state: "gtmhub.tasks.task" })
  );
  $stateProvider.state(
    "gtmhub.toTasksTaskWithoutTrailingSlash",
    stateFactory.notificationsUrlsRedirectStates().fromUrlToStateRedirect({ url: "/tasks/task/task-preview/:taskId", state: "gtmhub.tasks.task" })
  );

  // global search states
  $stateProvider.state("gtmhub.globalSearch", stateFactory.globalSearchStates().globalSearch());

  // platform redirect component states
  $stateProvider.state("gtmhub.redirectToPlatform", stateFactory.redirectToPlatformStates().platformRedirect());

  register(userProfileStates);

  register(notificationsStates);

  register(editionPlanChangeStates);

  // ======================================================================================
  // privacy notice
  $stateProvider.state("gtmhub.privacyNotice", stateFactory.privacyNoticeStates().privacyNotice());

  // ======================================================================================
  // add account details (account-marketing-info form)
  $stateProvider.state("gtmhub.addAccountDetails", stateFactory.addAccountDetailsStates().addAccountDetails());

  $stateProvider.state("gtmhub.dispatch", stateFactory.miscStates().dispatch());

  // ======================================================================================

  // Deal with missing trailing slash in states
  $urlRouterProvider.rule(function ($injector, $location) {
    if ($location.hash()) {
      $location.url(`/#${insertTrailingSlashInUri($location.hash())}`, true);
    } else {
      const result = insertTrailingSlashInUri($location.url());
      if (result != $location.url()) {
        return result;
      }
    }
  });

  $urlRouterProvider.otherwise(($injector) => {
    const $state = $injector.get<StateService>("$state");
    $state.go("default");
  });
}

function insertTrailingSlashInUri(uri: string): string {
  if (uri === undefined || uri === null) return "";

  const uriParts = uri.split("?");
  const path = uriParts[0];
  const search = uriParts[1];
  let result = path;
  if (!path.endsWith("/")) {
    result += "/";
  }
  if (search) {
    result += `?${search}`;
  }

  return result;
}

const mod = angular.module("gh-states", [uiRouter, sockets]);

mod.config([
  "$stateProvider",
  "$urlRouterProvider",
  "$urlServiceProvider",
  ($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider, $urlService: UrlService) => {
    $urlService.config.strictMode(false);
    registerStates($stateProvider, $urlRouterProvider);
  },
]);

export default mod.name;
