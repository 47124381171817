import { InvitedUserDTO, UserPostDTO } from "../models/user-management.dto-model";
import { InvitedUserVM } from "../models/user-management.vm-model";

export function invitedUserDTO2VM(invitedUserDTO: InvitedUserDTO): InvitedUserVM {
  const { email, id, firstName, lastName, roles } = invitedUserDTO;
  return {
    id,
    email,
    firstName,
    lastName,
    roles: roles.map((role) => {
      return {
        id: role.id,
        name: role.name,
      };
    }),
  };
}

export function emails2PostDTO(emails: string[], roleIds: string[]): Record<string, UserPostDTO> {
  const invitations = emails.reduce((acc, email) => {
    if (email) {
      acc[email] = {
        email,
        firstName: "",
        lastName: " ",
        roleIds,
      };
    }
    return acc;
  }, {});

  // set first name to the email and last to empty space
  Object.values(invitations).forEach((i: UserPostDTO) => {
    i.firstName = i.email.split("@")[0];
  });

  return invitations;
}
