<article class="collaborators">
  <header>
    @if (selectedUsers.length === 0) {
      <section class="content">
        <h3>Invite people to collaborate</h3>
        <h4>Collaborators can manage context, decision map hypothesis and areas.</h4>
      </section>
    } @else {
      <section class="action-content">
        <button class="back-button" (click)="goBack()" gh-dropdown-custom-button-content ui-button uiShape="round" uiSize="default" uiType="link">
          <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="back"></i>
          Back
        </button>
      </section>
    }
    <section class="actions">
      <button (click)="data.closeModal()" ui-button uiShape="round" uiType="link">
        <i class="fs-16px" ui-icon uiTheme="outline" uiType="close-big"></i>
      </button>
    </section>
  </header>
  <section class="people-management">
    <people-selector
      class="people-selector"
      [request]="peopleSelectorRequestInternal"
      [selectedIds]="selectedUsers"
      (selectionChange)="updateExcluded($event)"
      mode="multiple"
      placeholderKey="Add collaborators"></people-selector>
    @if (selectedUsers.length === 0) {
      <section class="people-list">
        <h4>People</h4>
        <ul>
          @for (collaborator of data.collaborators; track $index) {
            <li class="collaborators-card" [class.flash]="flashItemIds[collaborator.userId]">
              <section class="collaborators-details">
                <ui-assignee-avatar class="avatar" [fromAssigneeId]="collaborator.userId" uiSize="md"></ui-assignee-avatar>
                <div class="name">
                  <ui-assignee-name [fromAssigneeId]="collaborator.userId" />
                  @if (collaborator.role === "owner") {
                    <span> (you) </span>
                  }
                </div>
                @if (collaborator.role === "owner") {
                  <span class="collaborator-type">Owner</span>
                } @else if (collaborator.uiState === "idle" || collaborator.uiState === "error") {
                  <gh-dropdown class="collaborator-type actions" [hasCustomButtonContent]="true" [menuItems]="getCollaboratorItems(collaborator)" iconName="artificial-intelligence">
                    <button class="collaborator-role-button" gh-dropdown-custom-button-content ui-button uiShape="round" uiSize="default" uiType="link">
                      Collaborator
                      <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="chevron-down-small"></i>
                    </button>
                  </gh-dropdown>
                } @else if (collaborator.uiState === "removing") {
                  <span class="collaborator-type removing">Removing...</span>
                } @else if (collaborator.uiState === "removed") {
                  <span class="collaborator-type removing">Removed</span>
                }
              </section>
              @if (collaborator.uiState === "error") {
                <section class="card-error">
                  <alert-message [attachContactSupport]="true" (dismissAlert)="removeErrorState(collaborator)" dismissType="x-button" message="Unable to remove collaborator." type="error">
                  </alert-message>
                </section>
              }
              @if (collaborator.uiState === "removing") {
                <section class="loader"></section>
              }
            </li>
          }
        </ul>
      </section>
    }
  </section>
  @if (invitingStatus === "error") {
    <alert-message class="invite-error" [attachContactSupport]="true" (dismissAlert)="resetInvitingStatus()" dismissType="x-button" message="Unable to invite collaborators." type="error">
    </alert-message>
  }
  @if (selectedUsers.length > 0) {
    <footer>
      @if (invitingStatus === "loading") {
        <span class="loader"></span>
        <button (click)="invite()" disabled ui-button uiShape="round" uiType="primary">Inviting...</button>
        <button (click)="goBack()" disabled ui-button uiShape="round" uiType="link">Cancel</button>
      } @else {
        <button (click)="invite()" ui-button uiShape="round" uiType="primary">Invite</button>
        <button (click)="goBack()" ui-button uiShape="round" uiType="link">Cancel</button>
      }
    </footer>
  }
</article>
