import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "whiteboards" */ "./whiteboards.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const whiteboardsFutureState: Ng2StateDeclaration = {
  name: "gtmhub.whiteboards.**",
  url: "/whiteboards/",
  lazyLoad,
};

const whiteboardFutureState: Ng2StateDeclaration = {
  name: "gtmhub.whiteboard.**",
  url: "/whiteboards/:whiteboardId/",
  lazyLoad,
};

// whiteboards POC
const whiteboardsV2FutureState: Ng2StateDeclaration = {
  name: "gtmhub.whiteboardsV2.**",
  url: "/whiteboards-v2/",
  lazyLoad,
};

const whiteboardV2FutureState: Ng2StateDeclaration = {
  name: `gtmhub.whiteboardV2.**`,
  url: "/whiteboards-v2/:whiteboardId/",
  lazyLoad,
};
// --------------------------------------------

const goalSuggestionsFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goalSuggestions.**",
  url: "/goal-suggestions/",
  lazyLoad,
};

const goalSuggestionFutureState: Ng2StateDeclaration = {
  name: "gtmhub.goalSuggestion.**",
  url: "/goal-suggestions/:goalSuggestionId/",
  lazyLoad,
};

export const states: Ng2StateDeclaration[] = [
  whiteboardsFutureState,
  whiteboardFutureState,
  whiteboardsV2FutureState,
  whiteboardV2FutureState,
  goalSuggestionsFutureState,
  goalSuggestionFutureState,
];
