import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { sendIntercomMessage } from "@gtmhub/shared/intercom";

@Component({
  selector: "container-empty-state",
  templateUrl: "./container-empty-state.component.html",
  styleUrls: ["./container-empty-state.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class ContainerEmptyStateComponent implements AfterViewInit {
  @Input() public message: string;
  @Input({ required: true }) public pictureUrl: string;
  @Input() public heading: string;
  @Input() public errorMessageForIntercom: string;

  public ngAfterViewInit(): void {
    if (this.errorMessageForIntercom) {
      this.showIntercom();
    }
  }

  public showIntercom(): void {
    const contactUsElement = document.querySelector(".clickable-link");
    contactUsElement.addEventListener("click", () => sendIntercomMessage(this.errorMessageForIntercom));
  }
}
