<article>
  <section class="icon-holder">
    <i class="fs-32px" [uiTheme]="iconTheme" [uiType]="iconType" ui-icon></i>
  </section>
  <section class="info">
    <h4 class="title">
      {{ title }}
    </h4>
    <span class="description">{{ description }}</span>
  </section>
  <button (click)="navigate.emit()" type="submit" ui-button uiShape="round" uiType="primary">
    {{ navigationLabel }}
  </button>
</article>
