@for (goalBlockContent of goalsBlockContent.goals; track $index) {
  <section>
    <h6>{{ goalBlockContent.goal }}</h6>

    <ul>
      @for (keyResult of goalBlockContent.keyResults; track $index) {
        <li>{{ keyResult }}</li>
      }
    </ul>

    <p>{{ goalBlockContent.reasoning }}</p>

    @for (reference of goalBlockContent.references; track $index) {
      <p class="reference">
        {{ reference }}
      </p>
    }
  </section>
}
