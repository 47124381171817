import { NzUploadFile } from "ng-zorro-antd/upload";
import { AsyncTaskVM } from "@webapp/strategy/models/async-tasks/async-tasks.vm-models";
import { DocumentVM } from "@webapp/strategy/models/documents/documents.vm-models";
import { StrategyContextDocument } from "@webapp/strategy/models/strategy.vm-models";
import { getLastRelatedTaskDoneDate } from "../../utility/async-tasks.utils";

// if name > 20 chars get first ten and last ten characters and concatenate with "..." inbetween
export const truncateDocumentName = (name: string): string => {
  if (name.length <= 20) {
    return name;
  }
  return `${name.slice(0, 10)}...${name.slice(-10)}`;
};

export const documentVM2StrategyContextDocument = (document: DocumentVM): StrategyContextDocument => {
  return {
    uid: document.id,
    name: document.name,
    truncatedName: truncateDocumentName(document.name),
    sizeInBytes: document.sizeInBytes,
    state: document.summary || !document.isSummarizable ? "SUMMARIZED" : "FAILED_SUMMARIZATION",
    deletionState: null,
    flashing: false,
  };
};

export const documentVM2EnrichedStrategyContextDocument = (document: DocumentVM, tasksMap: Record<string, AsyncTaskVM[]>): StrategyContextDocument => {
  const documentVM = documentVM2StrategyContextDocument(document);
  if (tasksMap[document.id]) {
    documentVM.lastRelatedTaskDoneDate = getLastRelatedTaskDoneDate(document.id, tasksMap);
    const ongoingTask = tasksMap[document.id].find((task) => ["PENDING", "STARTED"].includes(task.status));
    if (ongoingTask) {
      documentVM.state = ongoingTask.name === "embed_document" ? "UPLOADING" : "SUMMARIZING";
      const progress: Record<string, string> = ongoingTask.result.progress as Record<string, string>;
      documentVM.summaryProcessingPercent = progress?.percentage ? parseInt(progress?.percentage) : undefined;
    }
  } else {
    documentVM.lastRelatedTaskDoneDate = "-1";
  }
  return documentVM;
};

export const nzUploadFile2StrategyContextDocument = (file: NzUploadFile): StrategyContextDocument => {
  return {
    uid: file.uid,
    name: file.name,
    truncatedName: truncateDocumentName(file.name),
    sizeInBytes: file.size,
    originalFile: file as unknown as File,
    state: "UPLOADING",
    deletionState: null,
    flashing: false,
  };
};
