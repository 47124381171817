import { Directionality } from "@angular/cdk/bidi";
import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, Output } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzI18nService } from "ng-zorro-antd/i18n";
import { NzUploadComponent, NzUploadFile } from "ng-zorro-antd/upload";

export type UiUploadFile = NzUploadFile;
export const DEFAULT_ACCEPTED_FILE_EXTENSIONS = [".txt", ".pdf", ".pptx"];
export const DEFAULT_ACCEPTED_MIME_TYPES = ["text/plain", "application/pdf", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];

@Component({
  selector: "ui-upload",
  exportAs: "uiUpload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiUploadComponent extends NzUploadComponent {
  @Input("uiLimit") public nzLimit: number = 10;
  @Input("uiMultiple") public nzMultiple: boolean = true;
  @Input("uiAccept") public nzAccept: string = DEFAULT_ACCEPTED_FILE_EXTENSIONS.join(", ");
  @Input("uiDisabled") public nzDisabled: boolean = false;
  @Input("uiAcceptedMimeTypes") public acceptedMimeTypes: string[] = DEFAULT_ACCEPTED_MIME_TYPES;

  @Output() public readonly uiUploadFile = new EventEmitter<NzUploadFile>();

  public fileSize = 50;
  public nzSize = this.fileSize * 1024;

  constructor(ngZone: NgZone, @Inject(DOCUMENT) document: NzSafeAny, cdr: ChangeDetectorRef, i18n: NzI18nService, directionality: Directionality) {
    super(ngZone, document, cdr, i18n, directionality);
  }

  public nzBeforeUpload = (file: NzUploadFile): boolean => {
    if (this.nzDisabled || !this.acceptedMimeTypes.includes(file.type)) return false;

    this.uiUploadFile.emit(file);

    return false;
  };
}
