import { Injectable } from "@angular/core";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";

enum ApiVersionEnum {
  v1 = "v1",
  v2 = "v2",
  v3 = "v3",
}

@Injectable({
  providedIn: "root",
})
export class BaseUrlBuilder {
  private baseUrl: string;

  constructor(apiEndpointService: ApiEndpointService) {
    this.baseUrl = apiEndpointService.getApiBaseUrl();
  }

  protected getApiEndpointV1 = (path: string): string => {
    return `${this.baseUrl}/api/${ApiVersionEnum.v1}/${path}`;
  };

  protected getApiEndpointV2 = (path: string): string => {
    return `${this.baseUrl}/api/${ApiVersionEnum.v2}/${path}`;
  };

  protected getApiEndpointV3 = (path: string): string => {
    return `${this.baseUrl}/api/${ApiVersionEnum.v3}/${path}`;
  };

  protected getApiEndpointV1WithAdditionalBase = (additionalBase: string, path: string): string => {
    return `${this.baseUrl}/${additionalBase}/api/${ApiVersionEnum.v1}/${path}`;
  };

  protected getApiEndpointV2WithAdditionalBase = (additionalBase: string, path: string): string => {
    return `${this.baseUrl}/${additionalBase}/api/${ApiVersionEnum.v2}/${path}`;
  };
}
