import dayjs from "@webapp/shared/libs/dayjs";
import {
  OrderedStrategyResultEntryDTOv2,
  StrategyResultBlockTypeDTOv2,
  StructuredDataAspectDTOv2,
  SwotDataDTOv2,
} from "@webapp/strategy/models/bets/strategic-bets.dto-v2-models";
import {
  AnsoffDataVM,
  BalancedScoreCardDataVM,
  BlueOceanDataVM,
  GoalsDataVM,
  KPIsDataVM,
  OrderedStrategyResultEntryVM,
  PestDataVM,
  PorterForcesDataVM,
  StrategyResultBlockContentVM,
  StrategyResultBlockTypeVM,
  StructuredDataAspectVM,
  TextStrategyResultVM,
} from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { StrategyConversationChatEntryDTOv2 } from "@webapp/strategy/models/chat/strategy-conversation-chat.dto-v2-models";
import { StrategyConversationChatEntryVM } from "@webapp/strategy/models/chat/strategy-conversation-chat.vm-models";
import { ContextDTOv2 } from "@webapp/strategy/models/context/context.dto-v2-models";
import { ContextVM } from "@webapp/strategy/models/context/context.vm-models";
import { StrategyConversationDTOv2 } from "@webapp/strategy/models/strategy-conversation/strategy-conversation.dto-v2-models";
import { StrategyConversationVM } from "@webapp/strategy/models/strategy-conversation/strategy-conversation.vm-models";

export const resultBlockDTOv22VM = (resultBlockDTO: OrderedStrategyResultEntryDTOv2): OrderedStrategyResultEntryVM => {
  return {
    id: resultBlockDTO.id,
    blockContent: contentDTOv22VM(resultBlockDTO),
    blockType: resultBlockDTO.blockType as unknown as StrategyResultBlockTypeVM,
    isCreatedInThisSession: false,
    shapeId: resultBlockDTO.shapeId,
  };
};

const contentDTOv22VM = (resultBlockDTO: OrderedStrategyResultEntryDTOv2 | StrategyConversationChatEntryVM): StrategyResultBlockContentVM => {
  let id = 1;
  switch (resultBlockDTO.blockType) {
    case StrategyResultBlockTypeDTOv2.Text:
      return resultBlockDTO.blockContent as TextStrategyResultVM;
    case StrategyResultBlockTypeDTOv2.Ansoff:
      return resultBlockDTO.blockContent as AnsoffDataVM;
    case StrategyResultBlockTypeDTOv2.PEST:
      return resultBlockDTO.blockContent as PestDataVM;
    case StrategyResultBlockTypeDTOv2.Porter:
      return resultBlockDTO.blockContent as PorterForcesDataVM;
    case StrategyResultBlockTypeDTOv2.BalancedScorecard:
      return resultBlockDTO.blockContent as BalancedScoreCardDataVM;
    case StrategyResultBlockTypeDTOv2.BlueOcean:
      return resultBlockDTO.blockContent as BlueOceanDataVM;
    case StrategyResultBlockTypeDTOv2.Goals:
      return resultBlockDTO.blockContent as GoalsDataVM;
    case StrategyResultBlockTypeDTOv2.KPIs:
      return resultBlockDTO.blockContent as KPIsDataVM;
    case StrategyResultBlockTypeDTOv2.SWOT: {
      const addIdToSWOTAspect = (entry: StructuredDataAspectDTOv2): StructuredDataAspectVM => {
        return {
          ...entry,
          id: id++,
        };
      };
      const swotResultBlockDTO = resultBlockDTO.blockContent as unknown as SwotDataDTOv2;
      return {
        strengths: swotResultBlockDTO.strengths.map(addIdToSWOTAspect),
        weaknesses: swotResultBlockDTO.weaknesses.map(addIdToSWOTAspect),
        opportunity: swotResultBlockDTO.opportunity.map(addIdToSWOTAspect),
        threats: swotResultBlockDTO.threats.map(addIdToSWOTAspect),
      };
    }
    default:
      return null;
  }
};

export const strategyConversationDTOv22VM = (conversationDTO: StrategyConversationDTOv2): StrategyConversationVM => {
  return {
    ...conversationDTO,
    results: [...(conversationDTO.report?.orderedResults || []).map((resultBlock) => resultBlockDTOv22VM(resultBlock))],
    reportId: conversationDTO.report?.id || "",
    chat: (conversationDTO.chat || []).map((chatEntry) => chatEntryDTOv22VM(chatEntry)),
    accountId: conversationDTO.accountId,
    suggestedFrameworks: conversationDTO.suggestedFrameworks,
  };
};

const chatEntryTypeDTOv22VM = (type: StrategyConversationChatEntryDTOv2["type"]): StrategyConversationChatEntryVM["type"] => {
  switch (type) {
    case "assistant":
      return "ai";
    case "user":
      return "human";
    default:
      return null;
  }
};

export const chatEntryDTOv22VM = (chatEntryDTO: StrategyConversationChatEntryDTOv2): StrategyConversationChatEntryVM => {
  return {
    id: chatEntryDTO.id,
    type: chatEntryTypeDTOv22VM(chatEntryDTO.type),
    blockContent: contentDTOv22VM(chatEntryDTO),
    blockType: chatEntryDTO.blockType as unknown as StrategyResultBlockTypeVM,
    feedback: chatEntryDTO.feedback,
    followupActionDetails: chatEntryDTO.followupActionDetails,
    createdBy: chatEntryDTO.createdBy,
  };
};

export const contextDTOv22VM = (contextDTO: ContextDTOv2): ContextVM => {
  return {
    id: contextDTO.id,
    name: contextDTO.name,
    ownerId: contextDTO.createdBy,
    notes: contextDTO.notes,
    documentIds: contextDTO.documentIds || [],
    createdAt: dayjs.utc(contextDTO.createdAt).toISOString(), // API createdAt format is not in UTC -> we convert to UTC,
    dateSeen: contextDTO.seenAt,
    progressStep: contextDTO.progressStep,
    documentSummary: contextDTO.documentSummary,
  };
};

export const resultBlockVM2DTOv2 = (resultBlockVM: OrderedStrategyResultEntryVM): OrderedStrategyResultEntryDTOv2 => {
  return {
    id: resultBlockVM.id,
    blockContent: resultBlockVM.blockContent,
    blockType: resultBlockVM.blockType as unknown as StrategyResultBlockTypeDTOv2,
    suggestionId: resultBlockVM.suggestionId,
    shapeId: resultBlockVM.shapeId,
  };
};
