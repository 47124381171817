import { animate, query, style, transition, trigger } from "@angular/animations";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, TemplateRef } from "@angular/core";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { DecisionDisplayItem } from "@webapp/strategy/models/strategy.vm-models";
import { renderCollectorServiceProvider } from "@webapp/tracing/apm/render-collector.service";
import { TraceElementDirective } from "@webapp/tracing/apm/trace-element.directive";
import { DecisionGroup, DiscoveryGroup, QuickDecisionsGroup, SuggestGoalsGroup } from "./strategy-ai-list.vm-models";

@Component({
  selector: "strategy-ai-list",
  templateUrl: "./strategy-ai-list.component.html",
  styleUrls: ["./strategy-ai-list.component.less"],
  // Using OnPush for strategies does not trigger proper change detection for sub-sequent components properly
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [CommonModule, TraceElementDirective],
  animations: [
    // disable the animations on initial rendering
    // https://stackoverflow.com/questions/44111239/angular-is-there-a-way-to-skip-enter-animation-on-initial-render
    trigger("empty", [transition(":enter, :leave", [])]),
    trigger("highlight", [
      transition(":enter, :leave", [
        query("strategy-ai-card", [
          style({ "background-color": "#fff0b3" }), // @yellow-color-3
          animate(
            "1500ms",
            style({ "background-color": "#fff0b3" }) // @yellow-color-3
          ),
        ]),
      ]),
    ]),
  ],
  providers: [renderCollectorServiceProvider("strategy-ai-list")],
})
export class StrategyAiListComponent implements OnChanges {
  @Input() public groups: (DecisionGroup | DiscoveryGroup | QuickDecisionsGroup | SuggestGoalsGroup)[];
  @Input() public templateRef: TemplateRef<{
    $implicit: DecisionDisplayItem;
  }>;
  @Input() public updatedItemId: string | number;

  private isItemUpdated = false;

  // attaches the empty animation to the host element
  @HostBinding("@empty") public get emptyAnimation(): boolean {
    return true;
  }

  // disables highlight animation unless a single item is created or removed
  @HostBinding("@.disabled") public get disableAnimation(): boolean {
    return !this.isItemUpdated;
  }

  public ngOnChanges(changes: SimpleChangesOf<StrategyAiListComponent>): void {
    const current = changes.groups?.currentValue;
    const previuos = changes.groups?.previousValue;
    const updatedItemId = changes.updatedItemId?.currentValue;

    if (!current || !previuos || !updatedItemId) {
      return;
    }

    this.checkItemUpdated(current, previuos, updatedItemId);
  }

  private checkItemUpdated(current: typeof this.groups, previous: typeof this.groups, updatedItemId: typeof this.updatedItemId): void {
    const currentIds = current
      .map((c) => c.children)
      .flat(1)
      .map((c) => c.id);
    const previousIds = previous
      .map((c) => c.children)
      .flat(1)
      .map((c) => c.id);

    this.isItemUpdated = currentIds.includes(updatedItemId) || previousIds.includes(updatedItemId);
  }
}
