<section class="action-card-content">
  <img [alt]="title" [src]="'/dist/img/' + image + '.svg'" />
  <section class="content">
    <h4>{{ title }}</h4>
    <p>{{ description }}</p>
    <section class="button-container">
      <button (click)="buttonClicked()" ui-button uiShape="round" uiSize="medium" uiType="primary">
        {{ buttonLabel }}
      </button>
    </section>
  </section>
</section>
