<ui-loading-indicator class="loading-container" [uiSpinning]="loadingContext" uiTip="Loading context...">
  <article class="context-container" *ngIf="!loadingContext">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <section class="content-container">
        <section class="content">
          <section
            class="context-files"
            [ngClass]="{
              'empty-errored-state': showErrors && uploadedDocumentsCount === 0,
            }">
            <section class="context-field-label">
              <h5>Files{{ filesRequired ? "*" : "" }}</h5>
              <span>Such as company or business unit reports and analyses related to strategy, marketing, sales, finance etc.</span>
            </section>
            <ui-form-item class="form-item files">
              <section class="upload-area">
                <nz-upload
                  class="file-upload-input"
                  [ngClass]="{ disabled: totalDocumentsCount >= 10 }"
                  [nzAccept]="fileAcceptExtensionsList"
                  [nzBeforeUpload]="beforeUpload"
                  [nzLimit]="10"
                  [nzMultiple]="true"
                  [nzSize]="50 * 1024"
                  nzListType="text"
                  nzType="drag">
                  <div class="file-upload-content">
                    <div class="upload-container">
                      <i ui-icon uiSize="lg" uiTheme="outline" uiType="attachment"></i>
                      <span class="title">Select files or drag and drop to upload</span>
                      <span class="subtitle">{{ fileAcceptExtensionsDescription }}</span>
                    </div>
                  </div>
                </nz-upload>
                <section class="limit-info" *ngIf="!showErrors || uploadedDocumentsCount !== 0">
                  <span class="text-info">Uploaded files are analysed for insights. Processing time depends on file size.</span><span>{{ totalDocumentsCount }}/10</span>
                </section>
                <section class="empty-files-error" *ngIf="showErrors && uploadedDocumentsCount === 0">
                  <i class="fs-16px" ui-icon uiTheme="outline" uiType="caution"></i>
                  <span>Upload at least one file to generate Objectives.</span>
                </section>
              </section>

              <section class="uploaded-files-container" *ngIf="totalDocumentsCount">
                <section class="single-file-container" *ngFor="let document of documents" [ngClass]="{ flash: document.flashing }">
                  <section class="file-display">
                    <ui-loading-indicator [uiSize]="'small'" [uiSpinning]="document.state === 'UPLOADING' || document.deletionState === 'DELETING'">
                      @if ((document.state === "SUMMARIZED" || document.state === "SUMMARIZING") && document.deletionState === null) {
                        <i
                          class="fs-16px icon file-type-icon"
                          [ngClass]="{
                            faded: document.state === 'SUMMARIZING',
                          }"
                          [uiType]="getIconTypeForDocument(document)"
                          ui-icon
                          uiTheme="fill"></i>
                      } @else if (document.deletionState === "FAILED_DELETION" || document.state === "FAILED_UPLOAD" || document.state === "FAILED_SUMMARIZATION") {
                        <i class="fs-16px icon file-type-icon error" ui-icon uiTheme="outline" uiType="caution"></i>
                      }
                    </ui-loading-indicator>
                    <span *ngIf="document.deletionState === 'DELETING'"> Deleting ({{ document.name }}, {{ document.sizeInBytes | fileSize }}) ... </span>
                    <ng-container *ngIf="document.deletionState === null">
                      <span *ngIf="document.state === 'UPLOADING'"> Uploading ({{ document.name }}, {{ document.sizeInBytes | fileSize }}) ... </span>
                      <span class="faded" *ngIf="document.state === 'SUMMARIZING'"> Processing ({{ document.name }}, {{ document.sizeInBytes | fileSize }}) ... </span>

                      <span
                        #contextDocument
                        *ngIf="document.state === 'SUMMARIZED'"
                        [attr.id]="document.uid"
                        [uiTooltipTitle]="document.name + '(' + (document.sizeInBytes | fileSize) + ')'"
                        tooltipIfOverflow="hover"
                        ui-tooltip>
                        {{ document.name }} ({{ document.sizeInBytes | fileSize }})
                      </span>
                    </ng-container>
                    <span class="error" *ngIf="document.state === 'FAILED_UPLOAD'">
                      Unable to upload
                      <ng-template
                        [ngTemplateOutlet]="errorStateDocumentName"
                        [ngTemplateOutletContext]="{
                          fullName: document.name,
                          truncatedName: document.truncatedName,
                        }"></ng-template
                      >. Please try again or <a class="ui-link" (click)="showIntercom()" href="javascript:void(0)"> contact support</a>.
                    </span>
                    <span class="error" *ngIf="document.deletionState === 'FAILED_DELETION'">
                      Unable to delete
                      <ng-template
                        [ngTemplateOutlet]="errorStateDocumentName"
                        [ngTemplateOutletContext]="{
                          fullName: document.name,
                          truncatedName: document.truncatedName,
                        }"></ng-template
                      >. Please try again or <a class="ui-link" (click)="showIntercom()" href="javascript:void(0)"> contact support</a>.
                    </span>
                    <span class="error" *ngIf="document.state === 'FAILED_SUMMARIZATION' && document.deletionState === null">
                      Unable to process
                      <ng-template
                        [ngTemplateOutlet]="errorStateDocumentName"
                        [ngTemplateOutletContext]="{
                          fullName: document.name,
                          truncatedName: document.truncatedName,
                        }"></ng-template
                      >. Please try again or <a class="ui-link" (click)="showIntercom()" href="javascript:void(0)"> contact support</a>.
                    </span>
                    <span class="addon-text" *ngIf="document.state === 'SUMMARIZING' && document.summaryProcessingPercent !== null">
                      {{ document.summaryProcessingPercent | percent }}
                    </span>
                    <button
                      *ngIf="!document.deletionState && document.state === 'FAILED_UPLOAD'"
                      (click)="retryDocumentUpload($event, document)"
                      type="button"
                      ui-button
                      uiShape="round"
                      uiSize="small"
                      uiType="text">
                      <i class="fs-14px delete-icon" ui-icon ui-tooltip uiShape="round" uiTheme="outline" uiTooltipPlacement="bottom" uiTooltipTitle="Try again" uiType="refresh"></i>
                    </button>
                    <button
                      *ngIf="!document.deletionState && document.state === 'FAILED_SUMMARIZATION'"
                      (click)="retryDocumentAnalysis($event, document)"
                      type="button"
                      ui-button
                      uiShape="round"
                      uiSize="small"
                      uiType="text">
                      <i class="fs-14px delete-icon" ui-icon ui-tooltip uiShape="round" uiTheme="outline" uiTooltipPlacement="bottom" uiTooltipTitle="Try again" uiType="refresh"></i>
                    </button>
                    <button
                      *ngIf="document.deletionState === 'FAILED_DELETION'"
                      (click)="removeDocument($event, document)"
                      type="button"
                      ui-button
                      ui-tooltip
                      uiShape="round"
                      uiSize="small"
                      uiTooltipPlacement="bottom"
                      uiTooltipTitle="Try again"
                      uiType="text">
                      <i class="fs-14px delete-icon" ui-icon uiShape="round" uiTheme="outline" uiType="refresh"></i>
                    </button>
                    <button
                      *ngIf="document.state === 'SUMMARIZED' && document.deletionState === null"
                      (click)="removeDocument($event, document)"
                      type="button"
                      ui-button
                      ui-tooltip
                      uiShape="round"
                      uiSize="small"
                      uiTooltipPlacement="bottom"
                      uiTooltipTitle="Delete file"
                      uiType="text">
                      <i class="fs-14px delete-icon" ui-icon uiTheme="outline" uiType="trash-bin"></i>
                    </button>
                    <button
                      *ngIf="document.deletionState === null && document.state === 'FAILED_UPLOAD'"
                      (click)="removeUploadError($event, document)"
                      type="button"
                      ui-button
                      ui-tooltip
                      uiShape="round"
                      uiSize="small"
                      uiTooltipPlacement="bottom"
                      uiTooltipTitle="Remove error"
                      uiType="text">
                      <i class="fs-14px delete-icon" ui-icon uiTheme="outline" uiType="close-big"></i>
                    </button>
                    <button
                      *ngIf="document.deletionState === 'FAILED_DELETION'"
                      (click)="removeDeleteError($event, document)"
                      type="button"
                      ui-button
                      ui-tooltip
                      uiShape="round"
                      uiSize="small"
                      uiTooltipPlacement="bottom"
                      uiTooltipTitle="Remove error"
                      uiType="text">
                      <i class="fs-14px delete-icon" ui-icon uiTheme="outline" uiType="close-big"></i>
                    </button>
                    <button
                      *ngIf="document.deletionState === null && document.state === 'FAILED_SUMMARIZATION'"
                      (click)="removeDocument($event, document)"
                      type="button"
                      ui-button
                      ui-tooltip
                      uiShape="round"
                      uiSize="small"
                      uiTooltipPlacement="bottom"
                      uiTooltipTitle="Remove error"
                      uiType="text">
                      <i class="fs-14px delete-icon" ui-icon uiTheme="outline" uiType="close-big"></i>
                    </button>
                  </section>
                  <section class="file-status">
                    <async-status *ngIf="document.state !== 'UPLOADING'" [asyncPreviewState]="document.asyncState"></async-status>
                  </section>
                </section>
              </section>
            </ui-form-item>
          </section>
          <section class="context-notes">
            <section class="context-field-label">
              <h5>Additional context</h5>
              <span>{{ additionalContextDescription }} </span>
            </section>
            <ui-form-control class="input-container">
              <ui-input-text-area
                id="context-notes"
                [formControlName]="'notes'"
                [maxCharacterCount]="5000"
                [showCharacterCount]="true"
                [showMaxCharacterCount]="true"
                [visibleRowCount]="8"
                (valueChange)="notesValueChanged($event)"
                valueChangeEvent="blur"></ui-input-text-area>
            </ui-form-control>
          </section>
        </section>
      </section>
    </form>
  </article>
</ui-loading-indicator>

<ng-template #errorStateDocumentName let-fullName="fullName" let-truncatedName="truncatedName">
  @if (truncatedName.length < fullName.length) {
    <span class="error" [uiTooltipTitle]="fullName" ui-tooltip>{{ truncatedName }}</span>
  } @else {
    <span class="error">{{ truncatedName }}</span>
  }
</ng-template>
