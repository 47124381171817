import { IRootScopeService, IScope } from "angular";
import { IOnShapeStateChange } from "./components/whiteboard.component";
import { DraftToImportedGoalMap, IWhiteboard } from "./models";
import { IWhiteboardShapeState } from "./rendering/shapes/models";
import { WhiteboardShape } from "./rendering/shapes/shape";
import {
  ICollaborateWhiteboardSocketMessage,
  IGetPropertiesSocketMessage,
  IReactionSettingsChangeSocketMessage,
  IShapeDeletedSocketMessage,
  IShapeLockedSocketMessage,
  IShapeReactionSocketMessage,
  IShapeUnlockedSocketMessage,
  IStateChangeSocketMessage,
  IWhiteboardPropertyChangedSocketMessage,
} from "./whiteboard-socket-service";

export enum WhiteboardEventType {
  WHITEBOARD_UPDATED = "WHITEBOARD_UPDATED",
  WHITEBOARD_DELETED = "WHITEBOARD_DELETED",
  WHITEBOARD_VISITED = "WHITEBOARD_VISITED",
}

export interface IDraftUpdatedArgs {
  id: string;
  changes: Record<string, unknown>;
}

export class WhiteboardEvents {
  private $rootScope: IRootScopeService;

  constructor(private $scope: IScope) {
    this.$rootScope = this.$scope.$root;
  }

  broadcastDraftsPublished(map: DraftToImportedGoalMap): void {
    this.$rootScope.$broadcast("draftsPublished", map);
  }

  onDraftsPublished(callback: (args: DraftToImportedGoalMap) => unknown): void {
    this.$scope.$on("draftsPublished", (evt, args: DraftToImportedGoalMap) => callback(args));
  }

  broadcastDraftUpdated(id: string, changes: Record<string, unknown>): void {
    const args: IDraftUpdatedArgs = { id, changes };
    this.$rootScope.$broadcast("draftUpdated", args);
  }

  onDraftUpdated(callback: (args: IDraftUpdatedArgs) => unknown): void {
    this.$scope.$on("draftUpdated", (evt, args: IDraftUpdatedArgs) => callback(args));
  }

  broadcastCollaborate(message: ICollaborateWhiteboardSocketMessage): void {
    this.$rootScope.$broadcast("whiteboardCollaborate", message);
  }

  onCollaborate(callback: (args: ICollaborateWhiteboardSocketMessage) => unknown): void {
    this.$scope.$on("whiteboardCollaborate", (evt, args) => callback(args));
  }

  broadcastForeignStateChange(message: IStateChangeSocketMessage): void {
    this.$rootScope.$broadcast("whiteboardForeignStateChange", message);
  }

  onForeignStateChange(callback: (args: IStateChangeSocketMessage) => unknown): void {
    this.$scope.$on("whiteboardForeignStateChange", (evt, args) => callback(args));
  }

  broadcastShapeLocked(message: IShapeLockedSocketMessage): void {
    this.$rootScope.$broadcast("whiteboardShapeLocked", message);
  }

  onShapeLocked(callback: (args: IShapeLockedSocketMessage) => unknown): void {
    this.$scope.$on("whiteboardShapeLocked", (evt, args) => callback(args));
  }

  broadcastShapeUnlocked(message: IShapeUnlockedSocketMessage): void {
    this.$rootScope.$broadcast("whiteboardShapeUnlocked", message);
  }

  onShapeUnlocked(callback: (args: IShapeUnlockedSocketMessage) => unknown): void {
    this.$scope.$on("whiteboardShapeUnlocked", (evt, args) => callback(args));
  }

  broadcastShapeDeleted(message: IShapeDeletedSocketMessage): void {
    this.$rootScope.$broadcast("whiteboardShapeDeleted", message);
  }

  onShapeDeleted(callback: (args: IShapeDeletedSocketMessage) => unknown): void {
    this.$scope.$on("whiteboardShapeDeleted", (evt, args) => callback(args));
  }

  broadcastSocketError(): void {
    this.$rootScope.$broadcast("whiteboardSocketError");
  }

  onSocketError(callback: () => unknown): void {
    this.$scope.$on("whiteboardSocketError", () => callback());
  }

  broadcastSocketOpened(): void {
    this.$rootScope.$broadcast("whiteboardSocketOpened");
  }

  onSocketOpened(callback: () => unknown): void {
    this.$scope.$on("whiteboardSocketOpened", () => callback());
  }

  broadcastReactionsEnabled(): void {
    this.$rootScope.$broadcast("emojis-on");
  }

  onReactionsEnabled(callback: () => unknown): void {
    this.$scope.$on("emojis-on", () => callback());
  }

  broadcastReactionsDisabled(): void {
    this.$rootScope.$broadcast("emojis-off");
  }

  onReactionsDisabled(callback: () => unknown): void {
    this.$scope.$on("emojis-off", () => callback());
  }

  onReactionSettingsChanged(callback: (args: IReactionSettingsChangeSocketMessage) => unknown): void {
    this.$scope.$on("settingsChanged", (evt, args) => callback(args));
  }

  broadcastReactionSettingsChanged(message: IReactionSettingsChangeSocketMessage): void {
    this.$rootScope.$broadcast("settingsChanged", message);
  }

  broadcastShapeReaction(message: IShapeReactionSocketMessage): void {
    this.$rootScope.$broadcast("shapeReaction", message);
  }

  onShapeReaction(callback: (args: IShapeReactionSocketMessage) => unknown): void {
    this.$scope.$on("shapeReaction", (evt, args) => callback(args));
  }

  broadcastMaxReactionsPerUserAllowedChanged(maxReactionCount: number): void {
    this.$rootScope.$broadcast("maxReactionsPerUserAllowedChanged", maxReactionCount);
  }

  broadcastGetProperties(message: IGetPropertiesSocketMessage): void {
    this.$rootScope.$broadcast("getProperties", message);
  }

  onGetProperties(callback: (args: IGetPropertiesSocketMessage) => unknown): void {
    this.$scope.$on("getProperties", (evt, args) => callback(args));
  }

  onMaxReactionsPerUserAllowedChanged(callback: (maxReactionCount: number) => unknown) {
    this.$scope.$on("maxReactionsPerUserAllowedChanged", (evt, args) => callback(args));
  }

  broadcastLockWhiteboard(): void {
    this.$rootScope.$broadcast("lockWhiteboard");
  }

  onLockWhiteboard(callback: () => void): void {
    this.$scope.$on("lockWhiteboard", () => callback());
  }

  broadcastUnlockSelectedShapes(shapes: WhiteboardShape<IWhiteboardShapeState>[]): void {
    this.$rootScope.$broadcast("unlockSelectedShapes", shapes);
  }

  onUnlockSelectedShapes(callback: (shapes: WhiteboardShape<IWhiteboardShapeState>[]) => void): void {
    this.$scope.$on("unlockSelectedShapes", (ev, args) => callback(args));
  }

  onWhiteboardPropertyChanged(callback: (args: IWhiteboardPropertyChangedSocketMessage) => unknown): void {
    this.$scope.$on("whiteboardPropertyChanged", (evt, args) => callback(args));
  }

  broadcastWhiteboardPropertyChanged(message: IWhiteboardPropertyChangedSocketMessage): void {
    this.$rootScope.$broadcast("whiteboardPropertyChanged", message);
  }

  broadcastWhiteboardDeleted(whiteboard: IWhiteboard): void {
    this.$rootScope.$broadcast(WhiteboardEventType.WHITEBOARD_DELETED, {
      whiteboard,
    });
  }

  broadcastWhiteboardUpdated(id: string, name: string): void {
    this.$rootScope.$broadcast(WhiteboardEventType.WHITEBOARD_UPDATED, {
      id,
      name,
    });
  }

  broadcastWhiteboardVisited(whiteboard: IWhiteboard): void {
    this.$rootScope.$broadcast(WhiteboardEventType.WHITEBOARD_VISITED, {
      whiteboard,
    });
  }

  onAddShape(callback: (shape: IWhiteboardShapeState) => unknown): void {
    this.$scope.$on("addShape", (evt, args) => callback(args));
  }

  broadcastAddShape(shape: IWhiteboardShapeState): void {
    this.$rootScope.$broadcast("addShape", shape);
  }

  onShapeStateChange(callback: (params: IOnShapeStateChange) => unknown): void {
    this.$scope.$on("shapeStateChange", (evt, args) => callback(args));
  }

  broadcastShapeStateChange(params: IOnShapeStateChange): void {
    this.$rootScope.$broadcast("shapeStateChange", params);
  }
}
