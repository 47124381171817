import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  ChatAnsoffDataVM,
  StructuredChatBlockDisplayData,
  StructuredChatDataSection,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";

@Component({
  selector: "ui-ai-chat-ansoff-block",
  templateUrl: "./ai-chat-ansoff-block.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatAnsoffBlockComponent implements OnInit {
  public ansoffSections: StructuredChatBlockDisplayData | undefined;
  @Input() public ansoff: ChatAnsoffDataVM | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const diversificationSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.ansoff?.diversification, "Diversification");
    const marketDevelopmentSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.ansoff?.marketDevelopment, "Market Development");
    const marketPenetrationSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.ansoff?.marketPenetration, "Market Penetration");
    const productDevelopmentSection: StructuredChatDataSection = this.transformChatDataAspectToSection(this.ansoff?.productDevelopment, "Product Development");
    this.ansoffSections = [marketPenetrationSection, marketDevelopmentSection, productDevelopmentSection, diversificationSection];
    this.cdr.markForCheck();
  }

  private transformChatDataAspectToSection(swotAspect: StructuredDataAspectVM[] | undefined, title: string): StructuredChatDataSection {
    return {
      title,
      entries: swotAspect || [],
    };
  }
}
