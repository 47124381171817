import { Observable } from "rxjs";
import { IBaseElementBuilder } from "@webapp/shared/models/base-element-builder.models";
import { ItemAction } from "@webapp/top-nav-bar/models/action-handler.models";

export abstract class BaseElementBuilder<T extends IBaseElementBuilder> {
  protected elem: T;

  protected constructor() {
    this.reset();
  }

  public reset(): void {
    this.elem = {} as T;
  }
  public build(): T {
    const i = this.elem;
    this.reset();
    return i;
  }

  public setKey(key: string): this {
    this.elem.key = key;
    return this;
  }

  public setAction(action: ItemAction): this {
    this.elem.action = action;
    return this;
  }

  public setIsVisibleCondition(isVisible$: Observable<boolean>): this {
    this.elem.isVisible$ = isVisible$;
    return this;
  }
}
