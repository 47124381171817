import { Injectable } from "@angular/core";
import { Observable, combineLatest, forkJoin, map, switchMap, take, tap } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { ICollection } from "@webapp/core/core.models";
import { StrategiesTrackingService } from "../utility/strategies-tracking.service";
import { InviteUserApiService } from "./invite-user.api.service";
import { InvitedUserDTO, RoleDTO } from "./models/user-management.dto-model";
import { InvitedUserVM, RoleVM } from "./models/user-management.vm-model";
import { SIGNALS_ROLE_NAME, STRATEGY_ROLE_NAME, USERS_DEFAULT_PAGE_SIZE } from "./utility/invite-users.constants";
import { getRolesFilters, getUsersWithEmailsFilters, getUsersWithRoleFilters } from "./utility/invited-users.api.utils";
import { emails2PostDTO, invitedUserDTO2VM } from "./utility/invited-users.factory.utils";

@Injectable()
export class InviteUsersService {
  private roles: Observable<RoleVM[]>;

  constructor(
    private inviteUserApiService: InviteUserApiService,
    private editionsFeature: EditionFeatureService,
    private strategiesTrackingService: StrategiesTrackingService
  ) {}

  private getRolesAndCache(): void {
    if (!this.roles) {
      this.roles = this.inviteUserApiService.getRoles(getRolesFilters()).pipe(map((roles: ICollection<RoleDTO>) => roles.items));
    }
  }

  private getRoleByName$(roleName: string): Observable<RoleVM> {
    this.getRolesAndCache();

    return this.roles.pipe(map((roles: RoleVM[]) => roles.find((role) => role.name === roleName)));
  }

  private getStrategyRole$(): Observable<RoleVM> {
    return this.getRoleByName$(STRATEGY_ROLE_NAME);
  }

  public getRolesNameMap$(): Observable<Record<string, RoleVM>> {
    this.getRolesAndCache();

    return this.roles.pipe(map((roles) => roles.reduce<Record<string, RoleVM>>((all, role) => Object.assign(all, { [role.name]: role }), {})));
  }

  private getUserRole$(): Observable<RoleVM> {
    return this.getRoleByName$("user");
  }

  public getInvitedUsersWithStrategyRole$(args: { pageIndex?: number; pageSize?: number }): Observable<{ users: InvitedUserVM[]; totalUsers: number }> {
    return this.getStrategyRole$().pipe(
      switchMap((role: RoleVM) => {
        const { pageIndex = 1, pageSize = USERS_DEFAULT_PAGE_SIZE } = args;

        const skip = (pageIndex - 1) * pageSize;
        const take = pageIndex * pageSize;

        return this.inviteUserApiService.getUsers(getUsersWithRoleFilters(role.id, skip, take)).pipe(
          map((invitedUsers: ICollection<InvitedUserDTO>) => {
            const users = invitedUsers.items.map((invitedUser: InvitedUserDTO) => invitedUserDTO2VM(invitedUser));
            const totalUsers = invitedUsers.totalCount;

            return { users, totalUsers };
          })
        );
      })
    );
  }

  private getUsersWithEmails$(emails: string[]): Observable<InvitedUserVM[]> {
    return this.inviteUserApiService
      .getUsers(getUsersWithEmailsFilters(emails))
      .pipe(map((invitedUsers: ICollection<InvitedUserDTO>) => invitedUsers.items.map((invitedUser: InvitedUserDTO) => invitedUserDTO2VM(invitedUser))));
  }

  private inviteWithEmails$(emails: string[], roleIds: string[], options: { shouldSkipInvites: boolean }): Observable<void> {
    const queryParams = options.shouldSkipInvites ? { skipEmail: "true" } : {};
    return this.inviteUserApiService.postUser(emails2PostDTO(emails, roleIds), queryParams).pipe(map(() => {}));
  }

  public inviteNewUsers$(emails: string[], options: { shouldSkipInvites: boolean }): Observable<void> {
    return combineLatest({
      roles: this.getRolesNameMap$(),
      hasSignals: this.editionsFeature.hasFeature$("signals").pipe(take(1)),
    }).pipe(
      switchMap(({ roles, hasSignals }) => this.filterExistingUsersAndGetRoleIds(emails, roles, { hasSignals })),
      switchMap(({ existingUsers, newEmails, roleIds }) => this.inviteNewAndExistingUsers(existingUsers, newEmails, roleIds, options))
    );
  }

  private filterExistingUsersAndGetRoleIds(
    emails: string[],
    roles: Record<string, RoleVM>,
    options: { hasSignals: boolean }
  ): Observable<{
    existingUsers: InvitedUserVM[];
    newEmails: string[];
    roleIds: string[];
  }> {
    const roleIds = [roles[STRATEGY_ROLE_NAME].id];
    if (options.hasSignals) {
      roleIds.push(roles[SIGNALS_ROLE_NAME].id);
    }

    return this.getUsersWithEmails$(emails).pipe(
      map((existingUsers: InvitedUserVM[]) => {
        const existingEmails = existingUsers.map((user) => user.email);
        const newEmails = emails.filter((email) => !existingEmails.includes(email));

        return { existingUsers, newEmails, roleIds };
      })
    );
  }

  private inviteNewAndExistingUsers(existingUsers: InvitedUserVM[], newEmails: string[], roleIds: string[], options: { shouldSkipInvites: boolean }): Observable<void> {
    // Step 3 - If there are no new emails, update the roles of existing users
    if (newEmails.length === 0) {
      return this.inviteUserApiService
        .postBulkUserRoles(
          existingUsers.map((user) => user.id),
          roleIds
        )
        .pipe(map(() => {}));
    }

    if (existingUsers.length === 0) {
      return this.inviteWithEmails$(newEmails, roleIds, options);
    }

    // Step 4 - Invite new users and update roles of existing users
    return forkJoin([
      this.inviteWithEmails$(newEmails, roleIds, options).pipe(take(1)),
      this.inviteUserApiService
        .postBulkUserRoles(
          existingUsers.map((user) => user.id),
          roleIds
        )
        .pipe(take(1)),
    ]).pipe(
      tap(() => {
        this.strategiesTrackingService.trackUsersInvited(existingUsers, newEmails);
      }),
      map(() => {})
    );
  }

  private invokeDeleteRole(userId: string, strategyRoleId: string): Observable<void> {
    return this.inviteUserApiService.deleteUserRole(userId, strategyRoleId);
  }

  private replaceUserRole(userId: string, strategyRoleId: string): Observable<void> {
    return this.getUserRole$().pipe(
      switchMap((userRole: RoleVM) => this.inviteUserApiService.postUserRole(userId, { roleId: userRole.id })),
      switchMap(() => this.invokeDeleteRole(userId, strategyRoleId))
    );
  }

  public deleteStrategyRoles$(userId: string, userRoles: RoleVM[]): Observable<void> {
    return combineLatest({
      roles: this.getRolesNameMap$(),
      hasSignals: this.editionsFeature.hasFeature$("signals").pipe(take(1)),
    }).pipe(
      switchMap(({ roles, hasSignals }) => {
        const strategyRole = roles[STRATEGY_ROLE_NAME];
        const signalsRole = roles[SIGNALS_ROLE_NAME];
        let resultObservable: Observable<void>;
        if (userRoles.length > 1) {
          if (hasSignals) {
            resultObservable = forkJoin([
              this.inviteUserApiService.deleteUserRole(userId, strategyRole.id),
              this.inviteUserApiService.deleteUserRole(userId, signalsRole.id),
            ]).pipe(map(() => {}));
          }

          resultObservable = this.inviteUserApiService.deleteUserRole(userId, strategyRole.id);
        } else {
          if (hasSignals) {
            resultObservable = forkJoin([this.replaceUserRole(userId, strategyRole.id), this.replaceUserRole(userId, signalsRole.id)]).pipe(map(() => {}));
          }

          resultObservable = this.replaceUserRole(userId, strategyRole.id);
        }
        resultObservable.pipe(
          tap(() => {
            this.strategiesTrackingService.trackUserRemoved(userId);
          })
        );
        return resultObservable;
      })
    );
  }
}
