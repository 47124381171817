import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { BuilderOperator } from "./filter.models";

/** TODO: Once the grid with the new grid filters are enabled for all users
 * adjust the default and custom values (e.g. if a value is not being used - remove, etc.)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
FeatureFlag.OkrGrid;

const legacyGridOperators: { [key: string]: BuilderOperator } = {
  any: { value: "isOneOf", label: "any" },
  all: { value: "equals", label: "all" },
  not: { value: "isNotOneOf", label: "not" },
  equals: { value: "equals", label: "equal_to" },
  notEqual: { value: "notEqual", label: "is_not_equal_to" },
  greater: { value: "greater", label: "greater_than" },
  less: { value: "less", label: "less_than" },
  isBetween: { value: "isBetween", label: "is_between" },
  isNotBetween: { value: "isNotBetween", label: "is_not_between" },
  never: { value: "never", label: "never" },
  isBefore: { value: "isBefore", label: "is_before" },
  isAfter: { value: "isAfter", label: "is_after" },
  contains: { value: "contains", label: "contains" },
  doesNotContain: { value: "doesNotContain", label: "does_not_contain" },
  is: { value: "is", label: "is" },
  isNot: { value: "isNot", label: "is_not" },
};

const okrGridBeta2Operators: { [key: string]: BuilderOperator } = {
  anyOperator: { value: "contains", label: "any" },
  allOperator: { value: "equals", label: "all" },
  isText: { value: "equals", label: "is" },
  isNotText: { value: "notEqual", label: "is_not" },
};

export const filterOperators: { [key: string]: BuilderOperator } = {
  ...legacyGridOperators,
  ...okrGridBeta2Operators,
};
