<error-page
  *ngIf="error === whiteboardGenerationErrorValue && whiteboardGenerationError !== whiteboardGenerationErrorValue"
  [backButtonCaption]="'Back'"
  [errorMessage]="whiteboardGenerationError"
  (back)="stopOKRGeneration.emit()"
  (retry)="generateOKRs.emit()"></error-page>
<error-page
  *ngIf="error === whiteboardGenerationErrorValue && whiteboardGenerationError === whiteboardGenerationErrorValue"
  [backButtonCaption]="'Back'"
  [errorMessage]="suggestOKRsError"
  (back)="stopOKRGeneration.emit()"
  (retry)="generateOKRs.emit()"></error-page>
<error-page
  *ngIf="error === strategicMapGenertionErrorValue"
  [backButtonCaption]="'Back'"
  [errorMessage]="strategicMapGenerationError"
  (back)="stopStrategyGeneration.emit()"
  (retry)="regenerateStrategy.emit()"></error-page>
<error-page *ngIf="error === betLoadingErrorValue" [backButtonCaption]="'Cancel'" [errorMessage]="betLoadingError" (back)="closePage.emit()" (retry)="loadBet.emit()"></error-page>
<ng-template #strategicMapGenerationError>
  <p class="error-panel sp-mb-0">
    Unable to generate decision map. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
<ng-template #betLoadingError>
  <p class="error-panel sp-mb-0">
    Unable to load decision. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
<ng-template #suggestOKRsError>
  <p class="error-panel sp-mb-0">
    Unable to prepare whiteboard and suggest Objectives. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
