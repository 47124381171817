import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export type DecisionTopNavBarTabKey = "decision_cap" | "whiteboard_cap";

@Injectable({
  providedIn: "root",
})
export class DecisionTabStateService {
  private decisionTabStateSubject = new BehaviorSubject<DecisionTopNavBarTabKey>("whiteboard_cap");

  public getTabState$(): Observable<DecisionTopNavBarTabKey> {
    return this.decisionTabStateSubject.asObservable();
  }

  public updateTabState(newState: DecisionTopNavBarTabKey): void {
    this.decisionTabStateSubject.next(newState);
  }
}
