<section class="structured-block-section" *ngFor="let section of sections">
  <h6>{{ section.title }}</h6>
  <section class="section-content">
    <ul>
      <li *ngFor="let entry of section.entries">
        <span>{{ entry.text }}</span>
        <text-references *ngIf="(config$ | async).showReferences && entry.references?.length > 0" [references]="entry.references"></text-references>
      </li>
    </ul>
  </section>
</section>
