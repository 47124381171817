<section class="results">
  <results-section
    class="results-content"
    [conversation]="reportConversation$ | async"
    (deleteResult)="deleteResultBlock($event)"
    (editContent)="editResultSectionContent($event)"
    (executeFollowupAction)="executeFollowupAction($event)"
    (insertTextBlockAtIndex)="insertTextBlockAtIndex($event)"
    (moveDown)="moveResultBlockDown($event)"
    (moveUp)="moveResultBlockUp($event)" />
</section>

<section class="chat">
  <ui-ai-chat
    [conversation]="aiChatConversation$ | async"
    [suggestedFrameworks]="(aiChatConversation$ | async)?.suggestedFrameworks || []"
    [usersMap]="usersMap"
    (addContent)="addContent($event)"
    (askQuestion)="askQuestion($event)"
    (provideFeedback)="provideFeedbackForMessage($event)"
    (refreshContent)="regenerateLastChatEntry()"
    (retryErroredMessage)="retryErroredMessage()" />
</section>
