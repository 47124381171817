import languages from "wwwroot/json/languages.json";
import { Language, MethodologyType } from "./models";

const defaultLanguage = "english";
const defaultMethodology = "default";

export const removeLanguageFromLocalStorage = (): void => {
  localStorage.removeItem("language");
};

export const setLanguageInLocalStorage = (language: Language): void => {
  localStorage.setItem("language", JSON.stringify(language));
};

export const getLanguageFromLocalStorage = (): Language => {
  const language: Language = JSON.parse(localStorage.getItem("language"));
  return language || defaultLanguage;
};

export const setMethodologyTypeInLocalStorage = (methodology: MethodologyType): void => {
  let refresh = false;
  const aMethodology = methodology === undefined ? defaultMethodology : methodology;
  const oldMethodology: MethodologyType = getMethodologyFromLocalStorage();
  if (aMethodology !== oldMethodology) {
    refresh = true;
  }

  if (aMethodology === defaultMethodology) {
    localStorage.removeItem("methodology");
  } else {
    localStorage.setItem("methodology", JSON.stringify(methodology));
  }

  if (refresh) {
    location.reload();
  }
};

export const getMethodologyFromLocalStorage = (): MethodologyType => {
  const methodologyString: string | null = localStorage.getItem("methodology");

  if (!methodologyString) {
    return defaultMethodology;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedMethodology = JSON.parse(methodologyString);

    if (typeof parsedMethodology !== "string" || !["default", "generic", "custom", "outcome-and-measure"].includes(parsedMethodology)) {
      return defaultMethodology;
    }

    return parsedMethodology as MethodologyType;
  } catch (error) {
    return defaultMethodology;
  }
};

export const getLanguageCode = (): string => {
  const language = getLanguageFromLocalStorage();
  const entry = languages.languageMap.find((x) => x.backend_label === language);
  if (!entry) {
    console.warn(`Unknown language found in localStorage: '${language}'. Defaulting to English`);
    return "en";
  }
  return entry.id;
};

export const isDefaultLanguage = (language: Language): boolean => language === defaultLanguage;
