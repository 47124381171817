import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Kpi, KpiDTO } from "../models/kpis.models";
import { KpisState } from "./kpis-state.service";

@Injectable({
  providedIn: "any",
})
export class KpisApiService extends BaseApiService<Kpi, KpiDTO, KpisState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: KpisState) {
    super("kpis", { ...new ApiVersionModel("v1"), getAll: "v2" }, httpClient, appConfig, state);
  }

  public getKpisStatsForTimeRangeEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/snapshots/stats`;
  }

  public getTagGroupsEndpoint(): string {
    return `${this.getBasePath(HttpActions.getAll)}/kpis-grouping/tag`;
  }

  public getKpisGroupingEndpoint(fields: string[]): string {
    return `${this.getBasePath(HttpActions.getAll)}/kpis-grouping?groupFields=${fields.join(",")}`;
  }

  public getPatchKpiEndpoint(kpiId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/${kpiId}`;
  }

  public getPatchBulkTagsOperationEndpoint(): string {
    return `${this.getBasePath(HttpActions.patch)}/tags`;
  }
}
