import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, switchMap, take } from "rxjs";
import { catchHttpError } from "@webapp/core/rxjs-operators/catch-http-error.operator";
import { OrderedStrategyResultEntryVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategyConversationChatEntryVM } from "../../models/chat/strategy-conversation-chat.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { CHAT_AI_LOADING_ID } from "../../models/strategy.constants";
import { StrategyConversationApiFactory } from "./api-utils/strategy-conversation-api-factory.service";

@Injectable()
export class StrategyConversationService {
  private conversations$: Record<string, BehaviorSubject<StrategyConversationVM | null | undefined>> = {};

  constructor(private strategyConversationApiFactory: StrategyConversationApiFactory) {}

  //  ------------- STRATEGY CONVERSATION ------------- //

  public getConversation$(conversationId: string): Observable<StrategyConversationVM | null | undefined> {
    this.generateConversationIfNeeded(conversationId);
    return this.conversations$[conversationId].asObservable();
  }

  public reloadConversation(conversationId: string): void {
    if (!this.conversations$[conversationId]) return;
    this.conversations$[conversationId].next(this.conversations$[conversationId].value);
  }

  public updateConversationReport$(id: string, resultEntries: OrderedStrategyResultEntryVM[]): Observable<OrderedStrategyResultEntryVM[]> {
    return this.strategyConversationApiFactory.getApi().pipe(
      switchMap((api) => api.updateConversationResults$(id, resultEntries)),
      switchMap((updatedResultEntries: OrderedStrategyResultEntryVM[]) => {
        const updatedResultEntriesVM = [...updatedResultEntries];

        this.conversations$[id].next({
          ...this.conversations$[id].value,
          results: updatedResultEntriesVM,
        });

        return of(updatedResultEntriesVM);
      })
    );
  }

  public conversationIsLoading(conversationId: string): boolean {
    const conversation = this.conversations$[conversationId]?.value;
    return (
      conversation?.chat?.length > 0 &&
      conversation?.chat[conversation.chat.length - 1].id === CHAT_AI_LOADING_ID &&
      conversation?.chat[conversation.chat.length - 1].blockType !== "error"
    );
  }

  public updateConversationChat(conversationId: string, newChat: StrategyConversationChatEntryVM[], options?: { currentlyAnsweringQuestion: boolean }): void {
    const newConversation = {
      ...this.conversations$[conversationId].value,
      chat: newChat,
    };
    if (options) {
      newConversation.currentlyAnsweringQuestion = options.currentlyAnsweringQuestion;
    }
    this.conversations$[conversationId].next(newConversation);
  }

  public clearConversationCache(conversationId: string): void {
    if (this.conversations$[conversationId]) {
      this.conversations$[conversationId] = null;
    }
  }

  private generateConversationIfNeeded(conversationId: string): void {
    if (!this.conversations$[conversationId]) {
      this.conversations$[conversationId] = new BehaviorSubject<StrategyConversationVM>(undefined);
    }
    if (!this.conversations$[conversationId].value) {
      this.strategyConversationApiFactory
        .getApi()
        .pipe(
          switchMap((api) => api.getStrategyConversation$(conversationId)),
          take(1),
          catchHttpError(() => {
            return of(null);
          })
        )
        .subscribe({
          next: (conversation: StrategyConversationVM | null) => {
            this.conversations$[conversationId].next(conversation);
          },
        });
    }
  }
}
