import { Injectable } from "@angular/core";
import { Observable, map, switchMap } from "rxjs";
import { RestLayerRequest } from "@webapp/core/abstracts/models/rest-layer-request.models";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import { DocumentVM } from "../../models/documents/documents.vm-models";
import { StrategyContextDocument } from "../../models/strategy.vm-models";
import { DocumentsApiFactory } from "./api-utils/documents-api-factory.service";

@Injectable()
export class DocumentsService {
  constructor(private documentsApiFactory: DocumentsApiFactory) {}

  public getDocuments$(request: RestLayerRequest<DocumentVM>): Observable<DocumentVM[]> {
    return this.documentsApiFactory.getApi().pipe(switchMap((api) => api.getDocuments$(request)));
  }

  public uploadFile$(file: File, contextId: string): Observable<string> {
    return this.documentsApiFactory.getApi().pipe(
      switchMap((api) => api.uploadFile$(file, contextId)),
      map(({ documentIds }: { documentIds: string[] }) => documentIds[0])
    );
  }

  public retryFileAnalysis$(document: StrategyContextDocument): Observable<AsyncTaskVM> {
    return this.documentsApiFactory.getApi().pipe(switchMap((api) => api.retryFileAnalysis$(document.uid)));
  }

  public getDocumentById$(id: string): Observable<DocumentVM> {
    return this.documentsApiFactory.getApi().pipe(switchMap((api) => api.getDocumentById$(id)));
  }

  public deleteDocument$(documentId: string): Observable<void> {
    return this.documentsApiFactory.getApi().pipe(switchMap((api) => api.deleteDocument$(documentId)));
  }
}
