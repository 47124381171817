<ui-avatar
  *ngIf="uiAssignee"
  [class.has-border]="hasBorder"
  [hasBorder]="hasBorder"
  [hasHighlight]="hasHighlight"
  [style.border-color]="hasBorder ? borderColor : null"
  [uiAlt]="realAlt"
  [uiBgColor]="backgroundColor"
  [uiDisabled]="disabled"
  [uiIcon]="icon"
  [uiPicture]="picture"
  [uiSize]="uiSize"
  [uiTheme]="theme"
  [uiTooltipPlacement]="uiTooltipPlacement"
  [uiTooltipTitle]="uiShowTooltip && (uiTooltipTitle || avatarTooltipTemplate)"
  [uiTooltipTrigger]="uiShowTooltip ? 'hover' : null"
  (uiError)="onError()"
  ui-tooltip></ui-avatar>

<ng-template #avatarTooltipTemplate>
  <ui-assignee-name class="ui-avatar-tooltip" [uiAssignee]="uiAssignee" ui-multiline uiAlign="center"></ui-assignee-name>
</ng-template>
