import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { of } from "rxjs";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { BetCollaboratorVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { AsyncOperationPreviewState } from "@webapp/strategy/models/strategy.vm-models";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { AsyncStatusComponent } from "../../async-status/async-status.component";

@Component({
  selector: "evaluate-bet-header",
  templateUrl: "./evaluate-bet-header.component.html",
  styleUrls: ["./evaluate-bet-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiTooltipModule, FeatureTogglesModule, UiAssigneeModule, AssigneesModule, UiButtonComponent, UiIconModule, AsyncStatusComponent, NgIf, DropdownModule],
})
export class EvaluateBetHeaderComponent implements OnInit, OnChanges {
  @Input() public strategicBetName: string;
  @Input() public currentStepIndex: number;
  @Input() public contextId: string;
  @Input() public stepCaptionOverride?: string;
  @Input() public userIsOwner: boolean;
  @Input()
  public contextAsyncPreviewState: AsyncOperationPreviewState;
  @Input() public hideRightActionButtons = false;
  @Input() public collaborators: BetCollaboratorVM[];
  @Input() public hideShareAndPDFButtons: boolean = false;

  @Output() public readonly showInviteCollaborators: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly leaveDecision: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly closePage = new EventEmitter<void>();
  @Output() public readonly deleteBet = new EventEmitter<void>();
  @Output() public readonly openContextDrawer = new EventEmitter<void>();
  @Output() public readonly exportPDF = new EventEmitter<void>();

  public stepsLabelsArray = ["Contextual information", "Decision map", "Decision map"];
  public contextMenuItems = [];
  public contextMenuItemsForContextPage = [];

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.setMenuItems();
  }

  public ngOnChanges(changes: SimpleChangesOf<EvaluateBetHeaderComponent>): void {
    if (changes.hideShareAndPDFButtons) {
      this.setMenuItems();
    }
  }

  public exportPDFClicked(): void {
    this.exportPDF.emit();
    this.cdr.markForCheck();
  }

  public get contextIconTooltipText(): string {
    if (this.contextAsyncPreviewState?.tooltip && !this.contextAsyncPreviewState?.seen) {
      return this.contextAsyncPreviewState?.tooltip;
    }

    return "Context";
  }

  private setMenuItems(): void {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    const exportPDFItem = menuItemBuilder
      .setKey("Save as PDF")
      .setUiType({
        iconType: "pdf",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.exportPDF.emit(),
      })
      .setIsVisibleCondition(of(!this.hideShareAndPDFButtons))
      .build();

    const deleteItem = menuItemBuilder
      .setKey("Delete decision")
      .setToBeDestructive()
      .setUiType({
        iconType: "trash-bin",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.deleteBet.emit(),
      })
      .build();

    const leaveDecision = menuItemBuilder
      .setKey("Leave Decision")
      .setUiType({
        iconType: "export",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setToBeDestructive()
      .setAction({
        handler: () => this.leaveDecision.emit(),
      })
      .build();

    this.contextMenuItems = this.userIsOwner ? [exportPDFItem, deleteItem] : [exportPDFItem, leaveDecision];

    this.contextMenuItemsForContextPage = [deleteItem];
  }
}
