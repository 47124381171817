import { UIRouterModule } from "@uirouter/angular";
import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { firstValueFrom, forkJoin, take } from "rxjs";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { Intercom } from "@gtmhub/shared/intercom";
import dayjs from "@webapp/shared/libs/dayjs";
import { groupBy } from "@webapp/shared/utils/array";
import { UiLoadingIndicatorComponent } from "@webapp/ui/loading-indicator/loading-indicator.component";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { UiScrollableContentDirective } from "@webapp/ui/utils/directives/scrollable-content.directive";
import { BetCollaboratorVM } from "../../models/bets/strategic-bets.vm-models";
import { ContextVM } from "../../models/context/context.vm-models";
import { DecisionDisplayItem } from "../../models/strategy.vm-models";
import { StrategyConversationContextService } from "../../services/context/strategy-conversation-context.service";
import { StrategiesTrackingService } from "../../services/utility/strategies-tracking.service";
import { BetContextStatusComponent } from "../bet-context-status/bet-context-status.component";
import { ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData } from "../confirm-delete-strategy-item/confirm-delete-strategy-item.component";
import { AlertMessageComponent } from "../evaluate-bet-page/components/alert-message/alert-message.component";
import { MessageBoxComponent } from "../shared/message-box/message-box.component";
import { StrategyAiCardLoadingListComponent } from "../shared/strategy-ai-card-loading-list/strategy-ai-card-loading-list.component";
import { StrategyAiCardComponent } from "../shared/strategy-ai-card/strategy-ai-card.component";
import { StrategyAiDropdownComponent } from "../shared/strategy-ai-dropdown/strategy-ai-dropdown.component";
import { StrategyAiHeaderComponent } from "../shared/strategy-ai-header/strategy-ai-header.component";
import { StrategyAiListComponent } from "../shared/strategy-ai-list/strategy-ai-list.component";
import { DecisionGroup } from "../shared/strategy-ai-list/strategy-ai-list.vm-models";
import { fromDecisionsToStrategyAiList } from "../shared/strategy-ai-list/transformers";
import { getStrategyAiGroup } from "../shared/strategy-ai-list/utils";

@UntilDestroy()
@Component({
  selector: "generated-objectives-page",
  templateUrl: "./generated-objectives-page.component.html",
  styleUrls: ["./generated-objectives-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    StrategyAiHeaderComponent,
    UiButtonComponent,
    UiScrollableContentDirective,
    UiLoadingIndicatorComponent,
    NgIf,
    StrategyAiDropdownComponent,
    UiIconModule,
    StrategyAiCardComponent,
    BetContextStatusComponent,
    StrategyAiListComponent,
    MessageBoxComponent,
    StrategyAiCardLoadingListComponent,
    AlertMessageComponent,
    UIRouterModule,
  ],
})
export class GeneratedObjectivesPageComponent implements OnInit {
  @ViewChild("deletionError") private deletionError: TemplateRef<object>;

  public loading = true;
  public hasError = false;
  public updatedContextId: string;
  public limitReached = false;

  private contexts: ContextVM[] = [];

  public contextGroups: DecisionGroup[] = [];
  private allDecisions: DecisionDisplayItem[] = [];

  constructor(
    private contextService: StrategyConversationContextService,
    private trackingService: StrategiesTrackingService,
    private modalService: UiModalService,
    private apmService: ApmService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.fetchContexts();
  }

  private createContextGroups(contexts: DecisionDisplayItem[]): void {
    const groupedContexts = groupBy(contexts, (index, context) => getStrategyAiGroup(context.dateCreated));

    this.contextGroups = fromDecisionsToStrategyAiList(groupedContexts);
  }

  public fetchContexts(): void {
    this.loading = true;
    this.hasError = false;
    this.apmService.startDataLoadSpan("fetch contexts");

    forkJoin({
      contexts: this.contextService.getNonBetContexts$().pipe(take(1)),
    }).subscribe({
      next: ({ contexts }) => {
        this.contexts = contexts;
        this.allDecisions = this.initAndSortContextDecisions(contexts);
        this.createContextGroups(this.allDecisions);

        this.loading = false;
        this.cdr.markForCheck();
        this.apmService.endDataLoadSpan("fetch contexts");
      },
      error: () => {
        this.hasError = true;
        this.loading = false;
        this.cdr.markForCheck();
      },
    });
    this.cdr.markForCheck();
  }

  public deleteContext(contextId: string): void {
    this.modalService.confirm<ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData>(
      {
        uiTitle: "Delete Objective generation?",
        uiContent: ConfirmDeleteStrategyItemComponent,
        uiData: {
          strategyItemName: this.contexts.find((b) => b.id === contextId).name,
          strategyItemType: "objectiveGeneration",
        },
        uiOkText: "Delete Objective generation",
        uiOkLoadingText: "Deleting Objective generation...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          return firstValueFrom(this.contextService.deleteContext$(contextId))
            .then(() => {
              this.updatedContextId = contextId;
              this.contexts = this.contexts.filter((b) => b.id !== contextId);
              this.allDecisions = this.initAndSortContextDecisions(this.contexts);
              this.createContextGroups(this.allDecisions);

              this.cdr.markForCheck();
            })
            .catch(() => {
              throw this.deletionError;
            });
        },
        uiOnCancel: () => {
          this.cdr.markForCheck();
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
    this.cdr.markForCheck();
  }

  public goToTurnStrategyIntoActions(): void {
    this.contextService
      .createContext$(`Strategy into Action #${this.contexts.length + 1}`)
      .pipe(take(1))
      .subscribe({
        next: (context: ContextVM) => {
          this.trackingService.trackStrategyGenerateOKRsFlowInitiated(context.id);
          this.router.navigate([`/strategies/strategy-into-action/${context.id}`]);
        },
        error: (error) => {
          if (error.status === 403) {
            this.limitReached = true;
            this.cdr.markForCheck();
          }
        },
      });
  }

  public onContactSupportClick(): void {
    Intercom("show");
  }

  private initAndSortContextDecisions(contexts: ContextVM[]): DecisionDisplayItem[] {
    return contexts
      .map((context) => {
        const contextOwner: BetCollaboratorVM = {
          userId: context.ownerId,
          role: "owner",
          uiState: "idle",
        };

        return {
          id: context.id,
          isContext: true,
          title: context.name,
          ownerId: context.ownerId,
          dateCreated: context.createdAt,
          status: null,
          currentStep: null,
          collaborators: [contextOwner],
        };
      })
      .sort((a, b) => dayjs(b.dateCreated).unix() - dayjs(a.dateCreated).unix());
  }

  public toCollaboratorId(collaborators: BetCollaboratorVM[]): string[] {
    return collaborators.map((collaborator) => collaborator.userId);
  }

  public hideLimitReachedError(): void {
    this.limitReached = false;
    this.cdr.markForCheck();
  }

  public goToBilling(): void {
    this.router.navigate(["/settings/manageSubscription"]);
  }
}
