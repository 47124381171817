<section
  class="status-container"
  *ngIf="asyncPreviewState && !asyncPreviewState.seen"
  [ngClass]="{ 'image-only': imageOnly }"
  [style.--status-size]="size"
  [uiTooltipPlacement]="'bottom'"
  [uiTooltipTitle]="asyncPreviewState.tooltip"
  [uiTooltipTrigger]="imageOnly ? null : 'hover'"
  ui-tooltip>
  <div class="loading" *ngIf="asyncPreviewState.state === 'LOADING'">
    <div class="spinner">
      <div class="mask">
        <div class="maskedCircle"></div>
      </div>
    </div>
  </div>
  <i class="fs-12px ready-icon" *ngIf="asyncPreviewState.state === 'LOADED'" ui-icon uiTheme="fill" uiType="async-status-ready"></i>
  <i class="fs-12px failed-icon" *ngIf="asyncPreviewState.state === 'FAILED'" ui-icon uiTheme="outline" uiType="caution"></i>
</section>
