import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ChatKPIsDataVM } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";

@Component({
  selector: "ui-ai-chat-kpis-block",
  templateUrl: "./ai-chat-kpis-block.component.html",
  styleUrls: ["./ai-chat-kpis-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatKpisBlockComponent {
  @Input() public kpisBlockContent: ChatKPIsDataVM;
}
