import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { StructuredDataAspectVM, SwotDataVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { FollowupActionDefinition } from "@webapp/strategy/models/strategy.vm-models";
import { GenericResultsBoxComponent } from "../generic-results-box/generic-results-box.component";

@Component({
  selector: "swot-result-block",
  templateUrl: "./swot-result-block.component.html",
  styleUrls: ["./swot-result-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GenericResultsBoxComponent],
})
export class SwotResultBlockComponent implements OnInit {
  @Input() public swot: SwotDataVM;
  @Output() public readonly contentChanged = new EventEmitter<{
    content: StructuredDataAspectVM[];
    section: keyof SwotDataVM;
  }>();
  @Output()
  public readonly executeAction: EventEmitter<FollowupActionDefinition> = new EventEmitter<FollowupActionDefinition>();
  @Output()
  public readonly focusOutWithChanges: EventEmitter<void> = new EventEmitter<void>();
  public swotMenuItems: Record<string, DropdownMenuItem[]> = {};

  public changeContent($event: StructuredDataAspectVM[], section: keyof SwotDataVM): void {
    this.contentChanged.emit({
      content: $event,
      section,
    });
  }

  public ngOnInit(): void {
    this.swotMenuItems = this.getSwotSectionItemMenuItems();
  }

  public getSwotSectionItemMenuItems(): Record<string, DropdownMenuItem[]> {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    const opportunitiesMenuItems: DropdownMenuItem[] = [
      menuItemBuilder
        .setKey("Analyze using Ansoff Matrix")
        .setUiType({
          iconTheme: "fill",
          uiType: "uiIcon",
          iconType: "",
        })
        .setAction({
          handler: () =>
            this.executeAction.emit({
              type: "swot_opportunities_ansoff",
              question: this.swot.opportunity.map((item) => item.text).join(" "),
            }),
        })
        .build(),

      menuItemBuilder
        .setKey("Analyze using PEST")
        .setUiType({
          iconTheme: "fill",
          uiType: "uiIcon",
          iconType: "",
        })
        .setAction({
          handler: () =>
            this.executeAction.emit({
              type: "swot_opportunities_pest",
              question: this.swot.opportunity.map((item) => item.text).join(" "),
            }),
        })
        .build(),
    ];

    const threatsMenuItems: DropdownMenuItem[] = [
      menuItemBuilder
        .setKey("Analyze using Porter’s 5 Forces")
        .setUiType({
          iconTheme: "fill",
          uiType: "uiIcon",
          iconType: "",
        })
        .setAction({
          handler: () =>
            this.executeAction.emit({
              type: "swot_threats_porter",
              question: this.swot.threats.map((item) => item.text).join(" "),
            }),
        })
        .build(),

      menuItemBuilder
        .setKey("Analyze using PEST")
        .setUiType({
          iconTheme: "fill",
          uiType: "uiIcon",
          iconType: "",
        })
        .setAction({
          handler: () =>
            this.executeAction.emit({
              type: "swot_opportunities_pest",
              question: this.swot.threats.map((item) => item.text).join(" "),
            }),
        })
        .build(),
    ];

    const strengthsMenuItems: DropdownMenuItem[] = [];
    const weaknessesMenuItems: DropdownMenuItem[] = [];

    return {
      Opportunities: opportunitiesMenuItems,
      Threats: threatsMenuItems,
      Strengths: strengthsMenuItems,
      Weaknesses: weaknessesMenuItems,
    };
  }
}
