import { ElementRef, Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, combineLatest, map } from "rxjs";

export interface ITopNavBarNotificationInstance {
  readonly key: string;
  registerNotification$(params: TopNavBarNotificationParams): Observable<void>;
  destroyNotification(): void;
}

export type TopNavBarNotificationParams = { topNavBarElementRef: ElementRef<HTMLElement> };

export const TOP_NAV_BAR_NOTIFICATION_INSTANCE = new InjectionToken<ITopNavBarNotificationInstance[]>("TOP_NAV_BAR_NOTIFICATION_INSTANCE");

@Injectable()
export class TopNavBarNotificationService {
  private readonly notificationInstanceMap: Record<string, ITopNavBarNotificationInstance> = {};

  constructor(@Inject(TOP_NAV_BAR_NOTIFICATION_INSTANCE) private readonly topNavBarNotificationInstances: ITopNavBarNotificationInstance[]) {}

  public listenForAvailableNotifications$(params: TopNavBarNotificationParams): Observable<void> {
    return combineLatest(
      this.topNavBarNotificationInstances.map((instance) => {
        this.notificationInstanceMap[instance.key] = instance;

        return instance.registerNotification$(params);
      })
    ).pipe(map(() => {}));
  }

  public destroyNotification(key: string): void {
    const instance = this.notificationInstanceMap[key];

    if (instance) {
      instance.destroyNotification();
      delete this.notificationInstanceMap[key];
    }
  }
}
