import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { IAsyncTasksApi } from "./async-tasks-api.interface";
import { AsyncTasksApiV2Service } from "./v2/async-tasks-api-v2.service";

@Injectable()
export class AsyncTasksApiFactory {
  constructor(private injector: Injector) {}

  public getApi(): Observable<IAsyncTasksApi> {
    return of(this.injector.get(AsyncTasksApiV2Service));
  }
}
