import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { ErrorViewModule } from "@webapp/shared/error-view/error-view.module";
import { MessageBoxComponent } from "../shared/message-box/message-box.component";

@Component({
  standalone: true,
  selector: "error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiTooltipModule, UiButtonModule, UiIconModule, ErrorViewModule, MessageBoxComponent, NgIf],
})
export class ErrorPageComponent {
  @Input()
  public errorMessage: string | TemplateRef<object>;
  @Input()
  public errorType: "error" | "no_access" = "error";
  @Input()
  public backButtonCaption: string;
  @Input()
  public errorButtonCaption?: string;
  @Input()
  public showButtonIcon: boolean = true;
  @Input()
  public showRetry: boolean = true;
  @Input()
  public showXClose: boolean = true;

  @Output()
  public readonly retry = new EventEmitter<void>();
  @Output()
  public readonly back = new EventEmitter<void>();
  @Output()
  public readonly closePage = new EventEmitter<void>();

  public onRetry = (): void => {
    this.retry.emit();
  };

  public onBack = (): void => {
    this.back.emit();
  };

  public onClosePage = (): void => {
    if (this.closePage.observed) {
      this.closePage.emit();
    } else {
      this.back.emit();
    }
  };
}
