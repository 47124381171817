import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class GenerateOnePagerResponseStrategy implements QuantiveResultsSocketStrategy<"generateOnePager"> {
  public messageType: QuantiveResultsSocketStrategy<"generateOnePager">["messageType"] = "generateOnePager";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"generateOnePager">> {
    return pipe(
      filter(({ data }) => {
        return data.item === "strategic_bet" && data.taskName === "generate_strategic_bet_one_pager" && data.userId === userId.get();
      })
    );
  }
}
