import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { Session } from "@webapp/sessions/models/sessions.model";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export type GuidedOKRsModalData = {
  flowName?: string;
  currentSession: Session;
  readOnlySession?: boolean;
  parent?: { id: string; title: string; ownerIds: string[] };
};

@Component({
  selector: "guided-okrs-modal-wrapper",
  templateUrl: "./guided-okrs-modal-wrapper.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuidedOKRsModalWrapperComponent {
  public flowName: string;
  public currentSession: Session;

  constructor(
    private modalRef: UiModalRef,
    @Inject(UI_MODAL_DATA) modalData: GuidedOKRsModalData
  ) {
    Object.assign(this, modalData);
  }

  public close(): void {
    this.modalRef.close();
  }
}
