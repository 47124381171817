import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { IStrategicBetsApi } from "./strategic-bets-api.interface";
import { StrategicBetsApiV2Service } from "./v2/strategic-bets-api-v2.service";

@Injectable()
export class StrategicBetsApiFactory {
  constructor(private injector: Injector) {}

  public getApi(): Observable<IStrategicBetsApi> {
    return of(this.injector.get(StrategicBetsApiV2Service));
  }
}
