import { AbstractConstructorTypeOf } from "@webapp/core/abstracts/models/generic-helpers.models";
import { OverlayComponentStateDeclarationFactory, StateDeclarationForVersion, StateVersion } from "./overlay-component-state-declaration.factory";

export class OverlayComponentStateDeclarationBuilder<Version extends StateVersion, StateBase extends StateDeclarationForVersion[Version]> {
  private factory: AbstractConstructorTypeOf<typeof OverlayComponentStateDeclarationFactory<Version, StateBase>>;
  private stateBase: StateBase;

  public constructor() {
    this.resetInternalBuilderState();
  }

  public withFactory(
    factory: AbstractConstructorTypeOf<typeof OverlayComponentStateDeclarationFactory<Version, StateBase>>
  ): OverlayComponentStateDeclarationBuilder<Version, StateBase> {
    this.factory = factory;

    return this;
  }

  public withStateBase(state: StateBase): OverlayComponentStateDeclarationBuilder<Version, StateBase> {
    this.stateBase = state;

    return this;
  }

  public build(): ReturnType<OverlayComponentStateDeclarationFactory<Version, StateBase>["getStateDeclaration"]> {
    if (!this.factory) throw new Error("Factory is required");

    if (!this.stateBase) throw new Error("State declaration is required");

    const factoryInstance = new this.factory();
    const stateDeclaration = factoryInstance.getStateDeclaration(this.stateBase);

    this.resetInternalBuilderState();

    return stateDeclaration;
  }

  private resetInternalBuilderState(): void {
    this.resetFactory();
    this.resetStateBase();
  }

  private resetFactory(): void {
    this.factory = null;
  }

  private resetStateBase(): void {
    this.stateBase = null;
  }
}
