import { strategicMapHypothesisDTOv22VM } from "@webapp/strategy/services/bet/api-utils/v2/strategic-bets-mapper-v2.utils";
import { chatEntryDTOv22VM } from "@webapp/strategy/services/conversation/api-utils/v2/strategy-conversation-mapper-v2.utils";
import { QuantiveResultsSocketDTOv2 } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.dto-v2-models";
import { QuantiveResultsSocketMessageType, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

const askQuestionResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"askQuestion">): QuantiveResultsSocketVM<"askQuestion"> => {
  const additionalData = response.data.additionalData;

  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      additionalData: {
        betId: additionalData.betId,
        areaId: additionalData.areaId,
        hypothesisId: additionalData.hypothesisId,
        response: chatEntryDTOv22VM(additionalData.chatResponse),
      },
      item: response.data.item,
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

const betContributorsModifiedResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"betContributorsModified">): QuantiveResultsSocketVM<"betContributorsModified"> => {
  return {
    messageType: response.messageType,
    data: {
      action: response.data.action,
      betId: response.data.betId,
      added: response.data.added,
      betName: response.data.betName,
      removed: response.data.removed,
      type: response.data.type,
    },
  };
};

const betContributorsRemovedResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"betRemoved">): QuantiveResultsSocketVM<"betRemoved"> => {
  return {
    messageType: response.messageType,
    data: {
      betId: response.data.betId,
      betName: response.data.betName,
      type: response.data.type,
    },
  };
};

const areaContributorsRemovedResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"areaRemoved">): QuantiveResultsSocketVM<"areaRemoved"> => {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      userId: response.data.userId,
      message: response.data.message,
      taskId: response.data.itemId,
      item: response.data.item,
      status: response.data.status,
      taskName: response.data.taskName,
      itemId: response.data.itemId,
      additionalData: { areaId: response.data.additionalData.areaId },
    },
  };
};

const onePagerGeneratedResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"generateOnePager">): QuantiveResultsSocketVM<"generateOnePager"> => {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      userId: response.data.userId,
      message: response.data.message,
      taskId: response.data.itemId,
      item: response.data.item,
      status: response.data.status,
      taskName: response.data.taskName,
      itemId: response.data.itemId,
      additionalData: {
        onePagerContent: response.data.additionalData.onePagerContent,
      },
    },
  };
};

const contributorLockResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"contributorLock">): QuantiveResultsSocketVM<"contributorLock"> => {
  return {
    messageType: response.messageType,
    data: {
      action: response.data.action,
      added: response.data.added,
      current: response.data.current,
      removed: response.data.removed,
      type: response.data.type,
    },
  };
};

const contributorRemovedResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"contributorRemoved">): QuantiveResultsSocketVM<"contributorRemoved"> => {
  return {
    messageType: response.messageType,
    data: {
      betId: response.data.betId,
      contributorId: response.data.contributorId,
      type: response.data.type,
    },
  };
};

function createStrategyMapResponseDTOv22VM<T extends "createStrategyMapAreaResponse" | "createStrategyMapResponse">(
  response: QuantiveResultsSocketDTOv2<T>
): QuantiveResultsSocketVM<T> {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      item: "strategic_bet",
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  } as QuantiveResultsSocketVM<T>;
}

const embedDocumentResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"embedDocument">): QuantiveResultsSocketVM<"embedDocument"> => {
  return {
    messageType: response.messageType,
    data: {
      additionalData: {
        contextId: response.data.additionalData.contextId,
      },
      accountId: response.data.accountId,
      item: "document",
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskName,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

const updateContextDocumentsSummaryDTOv22VM = (
  response: QuantiveResultsSocketDTOv2<"updateContextDocumentsSummary">
): QuantiveResultsSocketVM<"updateContextDocumentsSummary"> => {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      item: "context",
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

const regenerateChatAnswerDTOv22VM = (response: QuantiveResultsSocketDTOv2<"regenerateChatAnswer">): QuantiveResultsSocketVM<"regenerateChatAnswer"> => {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      additionalData: {
        betId: response.data.additionalData.betId,
        chatEntry: chatEntryDTOv22VM(response.data.additionalData.chatEntry),
      },
      item: response.data.item,
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

const strategyGlobalStatusDTOv22VM = (response: QuantiveResultsSocketDTOv2<"strategyGlobalStatus">): QuantiveResultsSocketVM<"strategyGlobalStatus"> => {
  return {
    messageType: response.messageType,
    data: {
      status: response.data.status,
    },
  };
};

const summarizationDTOv22VM = (response: QuantiveResultsSocketDTOv2<"summarization">): QuantiveResultsSocketVM<"summarization"> => {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      itemId: response.data.itemId,
      itemType: response.data.itemType,
      percentage: response.data.percentage,
      processed: response.data.processed,
      taskType: response.data.taskType,
      title: response.data.title,
      total: response.data.total,
      userId: response.data.userId,
    },
  };
};

const updateDocumentSummaryDTOv22VM = (response: QuantiveResultsSocketDTOv2<"updateDocumentSummary">): QuantiveResultsSocketVM<"updateDocumentSummary"> => {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      additionalData: {
        betId: response.data.additionalData.betId,
        contextId: response.data.additionalData.contextId,
      },
      item: response.data.item,
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

const generateGoalsForBetResponseDTOv22VM = (
  response: QuantiveResultsSocketDTOv2<"generateGoalsForBetResponse">
): QuantiveResultsSocketVM<"generateGoalsForBetResponse"> => {
  return {
    messageType: response.messageType,
    data: {
      additionalData: {
        whiteboardId: response.data.additionalData.whiteboardId,
        betId: response.data.additionalData.betId,
      },
      accountId: response.data.accountId,
      item: response.data.item,
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

const generateGoalsForContextResponseDTOv22VM = (
  response: QuantiveResultsSocketDTOv2<"generateGoalsForContextResponse">
): QuantiveResultsSocketVM<"generateGoalsForContextResponse"> => {
  return {
    messageType: response.messageType,
    data: {
      additionalData: {
        whiteboardId: response.data.additionalData.whiteboardId,
        contextId: response.data.additionalData.contextId,
      },
      accountId: response.data.accountId,
      item: response.data.item,
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

const updateHypothesisResponseDTOv22VM = (response: QuantiveResultsSocketDTOv2<"updateHypothesis">): QuantiveResultsSocketVM<"updateHypothesis"> => {
  return {
    messageType: response.messageType,
    data: {
      accountId: response.data.accountId,
      additionalData: {
        hypothesisResponse: strategicMapHypothesisDTOv22VM(response.data.additionalData.hypothesisResponse),
      },
      item: response.data.item,
      itemId: response.data.itemId,
      message: response.data.message,
      status: response.data.status,
      taskId: response.data.taskId,
      taskName: response.data.taskName,
      userId: response.data.userId,
    },
  };
};

export const strategySocketDataMapDTOv22VM: {
  [K in QuantiveResultsSocketMessageType]: (response: QuantiveResultsSocketDTOv2<K>) => QuantiveResultsSocketVM<K>;
} = {
  askQuestion: askQuestionResponseDTOv22VM,
  betContributorsModified: betContributorsModifiedResponseDTOv22VM,
  betRemoved: betContributorsRemovedResponseDTOv22VM,
  contributorLock: contributorLockResponseDTOv22VM,
  contributorRemoved: contributorRemovedResponseDTOv22VM,
  createStrategyMapAreaResponse: createStrategyMapResponseDTOv22VM,
  createStrategyMapResponse: createStrategyMapResponseDTOv22VM,
  embedDocument: embedDocumentResponseDTOv22VM,
  regenerateChatAnswer: regenerateChatAnswerDTOv22VM,
  strategyGlobalStatus: strategyGlobalStatusDTOv22VM,
  summarization: summarizationDTOv22VM,
  updateContextDocumentsSummary: updateContextDocumentsSummaryDTOv22VM,
  updateDocumentSummary: updateDocumentSummaryDTOv22VM,
  generateGoalsForBetResponse: generateGoalsForBetResponseDTOv22VM,
  generateGoalsForContextResponse: generateGoalsForContextResponseDTOv22VM,
  areaRemoved: areaContributorsRemovedResponseDTOv22VM,
  generateOnePager: onePagerGeneratedResponseDTOv22VM,
  updateHypothesis: updateHypothesisResponseDTOv22VM,
};
