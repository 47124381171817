import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { ContextVM } from "@webapp/strategy/models/context/context.vm-models";
import { DocumentsMediatorService } from "@webapp/strategy/services/documents/documents-mediator.service";
import { UserSettings } from "@webapp/strategy/services/user-settings/user-settings.model";
import { ContextFormComponent } from "../../../context-form/context-form.component";
import { AlertMessageComponent } from "../alert-message/alert-message.component";

@Component({
  selector: "evaluate-bet-context",
  templateUrl: "./evaluate-bet-context.component.html",
  styleUrls: ["./evaluate-bet-context.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AlertMessageComponent, ContextFormComponent, UiButtonComponent, UiIconModule],
})
export class EvaluateBetContextComponent implements OnChanges, OnInit {
  @Input() public userSettings: UserSettings;
  @Input() public betContext: ContextVM | null = null;
  @Output() public readonly hideAlert: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly generateStrategy: EventEmitter<string> = new EventEmitter<string>();

  public contextForm: FormGroup<{ notes: FormControl<string> }>;

  constructor(
    private formBuilder: FormBuilder,
    public documentsMediator: DocumentsMediatorService
  ) {}

  public ngOnInit(): void {
    if (this.betContext) {
      this.init();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.betContext && changes.betContext.currentValue !== null && changes.betContext.previousValue === null) {
      this.init();
    }
  }

  public submitContextForm(): void {
    if (this.contextForm.invalid) return;
    const notesFormControl = this.contextForm.get("notes");
    const notes = notesFormControl?.value || "";
    this.generateStrategy.emit(notes);
  }

  private init(): void {
    this.contextForm = this.formBuilder.group({
      notes: new FormControl<string>(this.betContext.notes || ""),
    });
  }
}
