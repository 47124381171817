import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { AsyncResponseDTOv2 } from "@webapp/strategy/models/bets/strategic-bets.dto-v2-models";
import { AsyncResponseVM, StrategyResultBlockTypeVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { asyncResponseDTOv22VM } from "../../../bet/api-utils/v2/strategic-bets-mapper-v2.utils";
import { IStrategyConversationChatApi } from "../strategy-conversation-chat-api.interface";
import { StrategyConversationChatUrlBuilderV2Service } from "./strategy-conversation-chat-url-builder-v2.service";

@Injectable()
export class StrategyConversationChatApiV2Service extends PlainBaseApiService implements IStrategyConversationChatApi {
  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService,
    private strategyConversationChatUrlBuilderV2Service: StrategyConversationChatUrlBuilderV2Service
  ) {
    super(httpClient, apiEndpointService);
  }

  public askQuestion(params: {
    conversationId: string;
    question: string;
    questionType: StrategyResultBlockTypeVM;
    includeRealTimeData: boolean;
    resultBlockType?: StrategyResultBlockTypeVM;
  }): Observable<AsyncResponseVM> {
    const { conversationId, question, questionType, includeRealTimeData, resultBlockType } = params;

    const chatUrl = this.strategyConversationChatUrlBuilderV2Service.getChatUrl(conversationId);
    const body = {
      question,
      includeRealTimeData,
      isFollowupAction: questionType !== StrategyResultBlockTypeVM.Text,
      ...(questionType !== StrategyResultBlockTypeVM.Text && {
        followupActionDetails: {
          type: questionType,
          isSuggestion: true,
        },
      }),
      ...(resultBlockType && { reportSectionType: resultBlockType }),
    };

    return this.post$<AsyncResponseDTOv2>(chatUrl, body).pipe(map((response) => asyncResponseDTOv22VM(response)));
  }

  public regenerateLastChatEntry(conversationId: string): Observable<AsyncResponseVM> {
    const chatUrl = this.strategyConversationChatUrlBuilderV2Service.getChatUrl(conversationId);
    const regenerateLastChatEntryUrl = `${chatUrl}/regenerate-last-chat-entry`;

    return this.post$<AsyncResponseDTOv2>(regenerateLastChatEntryUrl, {}).pipe(map((response) => asyncResponseDTOv22VM(response)));
  }

  public provideFeedbackForMessage(conversationId: string, messageId: string, type: "positive" | "negative"): Observable<void> {
    const chatUrl = this.strategyConversationChatUrlBuilderV2Service.getChatUrl(conversationId);
    return this.post$(`${chatUrl}/${messageId}/feedback`, {
      score: type === "positive" ? "good" : "bad",
    });
  }
}
