import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Observable, map } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { DocumentExportPostDTOv3 } from "../models/document-exporter.dto-v3-models";
import { DocumentExporterUrlBuilderV3Service } from "./document-exporter-url-builder-v3.service";

@Injectable()
export class DocumentExporterV3ApiService extends PlainBaseApiService {
  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService,
    private urlBuilderService: DocumentExporterUrlBuilderV3Service
  ) {
    super(httpClient, apiEndpointService);
  }

  public exportAsPDF$(payload: DocumentExportPostDTOv3): Observable<SafeResourceUrl> {
    const url = this.urlBuilderService.getPDFUrl();

    return this.httpClient.post(url, payload, { responseType: "arraybuffer" }).pipe(
      map((response: ArrayBuffer) => {
        const blob = new Blob([response], { type: "application/pdf" });
        return URL.createObjectURL(blob);
      })
    );
  }
}
