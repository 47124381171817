import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class UpdateContextDocumentsSummaryResponseStrategy implements QuantiveResultsSocketStrategy<"updateContextDocumentsSummary"> {
  public messageType: QuantiveResultsSocketStrategy<"updateContextDocumentsSummary">["messageType"] = "updateContextDocumentsSummary";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"updateContextDocumentsSummary">> {
    return pipe(filter(({ data }) => data.item === "context" && data.taskName === "update_context_document_summary" && data.userId === userId.get()));
  }
}
