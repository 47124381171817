import { NgFor } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { OrderedStrategyResultEntryVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { FollowupActionDefinition, StrategyReportFollowupActionDefinition } from "../../models/strategy.vm-models";
import { ActionableTextContextService } from "../../services/utility/actionable-text-context.service";
import { StrategiesTrackingService } from "../../services/utility/strategies-tracking.service";
import { ResultBlockComponent } from "../result-block/result-block.component";

@Component({
  selector: "results-section",
  templateUrl: "./results-section.component.html",
  styleUrls: ["./results-section.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, ResultBlockComponent],
})
export class ResultsSectionComponent implements OnInit, OnChanges {
  public content = "";
  @ViewChild("resultsEnd")
  public resultsEndRef: ElementRef<HTMLDivElement>;
  @Input() public conversation: StrategyConversationVM;
  @Output()
  public readonly executeFollowupAction: EventEmitter<StrategyReportFollowupActionDefinition> = new EventEmitter<StrategyReportFollowupActionDefinition>();
  @Output()
  public readonly editContent: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly moveUp: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly moveDown: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly deleteResult: EventEmitter<OrderedStrategyResultEntryVM> = new EventEmitter<OrderedStrategyResultEntryVM>();
  @Output()
  public readonly insertTextBlockAtIndex: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private cdr: ChangeDetectorRef,
    private actionableTextContextService: ActionableTextContextService,
    private strategiesTrackingService: StrategiesTrackingService
  ) {}

  public handleMouseDown($event: MouseEvent): void {
    this.actionableTextContextService.mouseDown($event.target);
  }

  public handleMouseUp($event: MouseEvent): void {
    this.actionableTextContextService.mouseUp($event.target);
  }

  public handleFocusOut($event: FocusEvent): void {
    this.actionableTextContextService.focusOut($event.relatedTarget);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes["conversation"]) {
      return;
    }
    const currentConversation = changes["conversation"].currentValue as StrategyConversationVM | null;
    const previousValue = changes["conversation"].previousValue as StrategyConversationVM | null;
    if (!previousValue || !currentConversation || currentConversation.results.length > previousValue.results.length) {
      if (currentConversation) {
        if (!previousValue) {
          this.scrollToLastMessage();
        } else {
          const newlyAddedResult = currentConversation.results.find((result) => !previousValue.results.find((r) => r.id === result.id));
          if (newlyAddedResult?.isCreatedInThisSession) {
            this.scrollToLastMessage();
          }
        }
      } else {
        this.scrollToLastMessage();
      }
    }
    this.cdr.markForCheck();
  }

  public ngOnInit(): void {
    this.scrollToLastMessage();
  }

  public followup(result: OrderedStrategyResultEntryVM, $event: FollowupActionDefinition): void {
    this.executeFollowupAction.emit({
      ...$event,
      resultEntry: result,
    });
  }

  public focusOutWithChanges(resultBlock: OrderedStrategyResultEntryVM): void {
    this.strategiesTrackingService.trackConversationEdited(this.conversation, resultBlock, "free_text");
  }

  private scrollToLastMessage(): void {
    try {
      setTimeout(() => {
        this.resultsEndRef.nativeElement.scrollIntoView({
          block: "nearest",
        });
        this.cdr.markForCheck();
      }, 0);
    } catch (e) {
      // no messages found
    }
  }

  public addNewTextBlock(index: number): void {
    this.insertTextBlockAtIndex.emit(index);
  }
}
