/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from "@angular/core";
import { storage } from "@gtmhub/core/storage";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { OrderedStrategyResultEntryVM } from "../../models/bets/strategic-bets.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { InvitedUserVM } from "../user-management/models/user-management.vm-model";

const STRATEGY_FLOW_INITIATED = "Strategy Flow Initiated";
const STRATEGY_REDIRECTION_ACTION = "Strategy Redirection Action";

const STRATEGY_CONVERSATION_INITIATED = "Strategy Conversation Initiated";
const STRATEGY_CONTEXT_INPUT = "Strategy Context Input";
const SUMMARY_REPORT_EXPORT = "Summary Report Export";
const STRATEGY_CONVERSATION_EDITED = "Strategy Conversation Edited";
const STRATEGY_COLLABORATOR_ADDED = "Strategy Collaborator Added";
const STRATEGY_COLLABORATOR_REMOVED = "Strategy Collaborator Removed";
const STRATEGY_INVITE_USERS = "Strategy Invite Users";
const STRATEGY_REMOVE_USER = "Strategy Remove Users";
const STRATEGY_VIEW_OBJECTIVES_BUTTON_CLICKED = "Strategy View Objectives Button Clicked";
const STRATEGY_HYPOTHESIS_SELECTED = "Strategy Hypothesis Selected";
const STRATEGY_HYPOTHESIS_DESELECTED = "Strategy Hypothesis Deselected";

const STRATEGY_FREE_TEXT_INSERTED = "Strategy Free Text Inserted";

@Injectable()
export class StrategiesTrackingService {
  constructor(private analyticsService: AnalyticsService) {}

  public trackStrategyRedirectionAction(
    contextId: string,
    flowName: "Decision Map" | "Generate OKRs",
    resolution: "continue" | "close",
    opts?: {
      hypotheses_total: number;
      hypotheses_completed: number;
    }
  ): void {
    this.analyticsService.track(STRATEGY_REDIRECTION_ACTION, {
      flow_id: contextId,
      user_id: this.getCurrentUserId(),
      flow_name: flowName,
      resolution,
      ...(opts ? opts : {}),
    });
  }

  public trackStrategyGenerateOKRsFlowInitiated(contextId: string): void {
    this.trackStrategyFlowInitiated(contextId, "Generate OKRs", "", "", "");
  }

  public trackGenerateOnePagerFlowInitiated(contextId: string): void {
    this.analyticsService.track(STRATEGY_FLOW_INITIATED, {
      user_id: this.getCurrentUserId(),
      flow_id: contextId,
      flow_name: "One Pager",
    });
  }

  public trackDownloadOnePagerFlowInitiated(contextId: string): void {
    this.analyticsService.track(STRATEGY_FLOW_INITIATED, {
      user_id: this.getCurrentUserId(),
      flow_id: contextId,
      flow_name: "Download One Pager",
    });
  }

  public trackRegenerateOnePagerFlowInitiated(contextId: string): void {
    this.analyticsService.track(STRATEGY_FLOW_INITIATED, {
      user_id: this.getCurrentUserId(),
      flow_id: contextId,
      flow_name: "Regenerate One Pager",
    });
  }

  public trackStrategyFlowInitiated(
    contextId: string,
    flowName: "Decision Map" | "Generate OKRs",
    name: string,
    predefinedOption: "" | "What market should I go into?" | "Should I launch a new product?",
    questionAsked: string
  ): void {
    this.analyticsService.track(STRATEGY_FLOW_INITIATED, {
      user_id: this.getCurrentUserId(),
      flow_id: contextId,
      flow_name: flowName,
      name,
      predefined_option_selected: predefinedOption,
      question_asked: questionAsked,
    });
  }

  public trackStrategyConversationInitiated(contextId: string, reportId: string): void {
    this.analyticsService.track(STRATEGY_CONVERSATION_INITIATED, {
      id: contextId,
      user_id: this.getCurrentUserId(),
      report_id: reportId,
    });
  }

  public trackSummaryReportExported(contextId: string, pdfReportPageCount: number, exportTimeMs: number): void {
    this.analyticsService.track(SUMMARY_REPORT_EXPORT, {
      flow_id: contextId,
      user_id: this.getCurrentUserId(),
      export_time: exportTimeMs,
      pages: pdfReportPageCount,
    });
  }

  public trackStrategyContextInput(contextId: string, source: "Decision Map" | "Generate OKRs"): void {
    this.analyticsService.track(STRATEGY_CONTEXT_INPUT, {
      flow_id: contextId,
      flow_name: source,
    });
  }

  public trackConversationEdited(
    conversation: StrategyConversationVM,
    updatedResultBlock: OrderedStrategyResultEntryVM,
    editAction: "free_text" | "move_up" | "move_down" | "delete"
  ): void {
    this.analyticsService.track(STRATEGY_CONVERSATION_EDITED, {
      report_id: conversation.reportId,
      flow_id: conversation.contextId,
      id: conversation.id,
      user_id: this.getCurrentUserId(),
      edit_action: editAction,
      report_section: updatedResultBlock.blockType,
    });
  }

  public trackUsersInvited(existingUsers: InvitedUserVM[], newEmails: string[]): void {
    const invitedUsersIds = [...existingUsers.map((user) => user.id), ...newEmails.map(() => "new user")];
    this.analyticsService.track(STRATEGY_INVITE_USERS, {
      invited_by_user_id: this.getCurrentUserId(),
      user_ids: invitedUsersIds,
    });
  }

  public trackUserRemoved(userId: string): void {
    this.analyticsService.track(STRATEGY_REMOVE_USER, {
      removed_by_user_id: this.getCurrentUserId(),
      user_id: userId,
    });
  }

  public trackCollaboratorsAdded(userIds: string[]): void {
    this.analyticsService.track(STRATEGY_COLLABORATOR_ADDED, {
      invited_by_user_id: this.getCurrentUserId(),
      user_ids: userIds,
    });
  }

  public trackCollaboratorRemoved(userId: string): void {
    this.analyticsService.track(STRATEGY_COLLABORATOR_REMOVED, {
      removed_by_user_id: this.getCurrentUserId(),
      user_id: userId,
    });
  }

  public trackGoToWhiteboard(
    whiteboardId: string,
    source: "Decision Map" | "Generate OKRs" | "Executive Summary",
    opts?: {
      flow_id: string;
      hypotheses_total: number;
      hypotheses_completed: number;
    }
  ): void {
    this.analyticsService.track(STRATEGY_VIEW_OBJECTIVES_BUTTON_CLICKED, {
      user_id: this.getCurrentUserId(),
      flow_name: source,
      whiteboard_id: whiteboardId,
      ...(opts ? opts : {}),
    });
  }

  public trackSelectHypothese(contextId: string, options: { isSelected: boolean }, hypothesisId: string, areaId: string): void {
    const eventName = options.isSelected ? STRATEGY_HYPOTHESIS_SELECTED : STRATEGY_HYPOTHESIS_DESELECTED;

    this.analyticsService.track(eventName, {
      user_id: this.getCurrentUserId(),
      flow_id: contextId,
      hypothesis_id: hypothesisId,
      area_id: areaId,
    });
  }

  public trackFreeTextInserted(conversationId: string, insertionType: string, { partial }: { partial: boolean }): void {
    this.analyticsService.track(STRATEGY_FREE_TEXT_INSERTED, {
      user_id: this.getCurrentUserId(),
      conversation_id: conversationId,
      insertion_type: insertionType || "user_input",
      partial: partial || false,
    });
  }

  private getCurrentUserId(): string {
    return storage.get("userId");
  }
}
