import { SuggestedGoal } from "@webapp/goal-suggestions/models/suggested-goals.models";
import { DecisionDisplayItem } from "@webapp/strategy/models/strategy.vm-models";
import { DecisionDB } from "@webapp/strategy/services/decision/decision-storage.service";
import { DecisionGroup, QuickDecisionsGroup, SuggestGoalsGroup } from "./strategy-ai-list.vm-models";

export function fromDecisionsToStrategyAiList(groups: Record<string, DecisionDisplayItem[]>): DecisionGroup[] {
  return Object.entries(groups).map(([groupName, decisions]) => {
    return {
      name: groupName,
      children: decisions,
    };
  });
}

export function fromQuickDecisionsToStrategyAiList(groups: Record<string, DecisionDB[]>): QuickDecisionsGroup[] {
  return Object.entries(groups).map(([groupName, decision]) => {
    return {
      name: groupName,
      children: decision,
    };
  });
}

export function fromSuggestedGoalsToStrategyAiList(groups: Record<string, SuggestedGoal[]>): SuggestGoalsGroup[] {
  return Object.entries(groups).map(([groupName, suggestedGoal]) => {
    return {
      name: groupName,
      children: suggestedGoal,
    };
  });
}
