<generic-results-box
  class="result-box"
  [data]="porter.newEntrantThreats"
  (contentChanged)="changeContent($event, 'newEntrantThreats')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Threat of New Entrants"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="porter.supplierBargainingPowers"
  (contentChanged)="changeContent($event, 'supplierBargainingPowers')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Bargaining Power of Suppliers"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="porter.buyersBargainingPowers"
  (contentChanged)="changeContent($event, 'buyersBargainingPowers')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Bargaining Power of Buyers"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="porter.substituteThreats"
  (contentChanged)="changeContent($event, 'substituteThreats')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Threat of Substitute Products or Services"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="porter.competitionRivalry"
  (contentChanged)="changeContent($event, 'competitionRivalry')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Rivalry Among Existing Competitors"></generic-results-box>
