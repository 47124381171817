import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { StructuredChatBlockDisplayData } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { StrategyConfig } from "@webapp/strategy/models/strategy.vm-models";
import { StrategyConfigService } from "@webapp/strategy/services/config/strategy-config.service";

@Component({
  selector: "ui-generic-structured-chat-block",
  templateUrl: "./generic-structured-chat-block.component.html",
  styleUrls: ["./generic-structured-chat-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericStructuredChatBlockComponent implements OnInit {
  @Input() public sections: StructuredChatBlockDisplayData | undefined;
  @Input() public showReferences = false;

  public config$: Observable<StrategyConfig>;

  constructor(private strategyConfigService: StrategyConfigService) {}

  public ngOnInit(): void {
    this.config$ = this.strategyConfigService.getConfig$();
  }
}
