import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class UpdateDocumentSummaryResponseStrategy implements QuantiveResultsSocketStrategy<"updateDocumentSummary"> {
  public messageType: QuantiveResultsSocketStrategy<"updateDocumentSummary">["messageType"] = "updateDocumentSummary";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"updateDocumentSummary">> {
    return pipe(filter(({ data }) => data.item === "document" && data.taskName === "update_document_summary" && data.userId === userId.get()));
  }
}
