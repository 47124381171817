import { localize } from "@gtmhub/localization";
import { IdMap } from "@gtmhub/util";
import { ICanLinkInfoRecord } from "@webapp/links/models/links.models";
import { IRelatedItemsSelectorNode } from "@webapp/links/models/related-items.models";
import { Search } from "@webapp/search/models/search.models";
import { Session } from "@webapp/sessions/models/sessions.model";
import { IMultiSelectorGoal, IMultiSelectorKpi, IMultiSelectorMetric } from "@webapp/shared/components/multi-selector/multi-selector.models";
import { AdvancedOkrSelectorItem } from "../components/advanced-okr-selector/advanced-okr-selector.models";
import { IParentSelector, IParentSelectorNode } from "../models/parent-selector.models";

export const sectionNodeRelatedItems = (section: string, suggestedNodes: IRelatedItemsSelectorNode[]): IRelatedItemsSelectorNode => {
  return {
    key: section,
    title: localize(section).toLocaleUpperCase(),
    ownerIds: [],
    selectable: false,
    expanded: true,
    children: suggestedNodes,
    hideExpand: true,
    isHeader: true,
  };
};

export const sectionNode = (section: string, sessionNodes: IParentSelectorNode[]): IParentSelectorNode => {
  return {
    key: section,
    title: localize(section).toLocaleUpperCase(),
    ownerIds: [],
    selectable: false,
    expanded: true,
    children: sessionNodes,
    hideExpand: true,
    isHeader: true,
  };
};

export const sessionNode = ({
  sessionId,
  sessionTitle,
  childrenNodes,
  expanded,
}: {
  sessionId: string;
  sessionTitle: string;
  childrenNodes: IParentSelectorNode[];
  expanded: boolean;
}): IParentSelectorNode => {
  const SESSION = "session";

  return {
    key: sessionId,
    title: sessionTitle,
    ownerIds: [],
    selectable: false,
    expanded: expanded,
    children: childrenNodes,
    icon: SESSION,
  };
};

export const suggestedSessionNode = ({
  sessionId,
  goalNodes,
  sessionsMap,
}: {
  sessionId: string;
  goalNodes: IParentSelectorNode[];
  sessionsMap: IdMap<Session>;
}): IParentSelectorNode => {
  const sessionTitle = sessionsMap[sessionId].title.toUpperCase();
  const suggestedGoals = localize("suggested_okrs").toUpperCase();
  const title = `${suggestedGoals} - ${sessionTitle}`;

  return {
    key: sessionId,
    title: title,
    ownerIds: [],
    selectable: false,
    expanded: true,
    children: goalNodes,
    hideExpand: true,
    isHeader: true,
  };
};

export const objectiveNode = (goal: IMultiSelectorGoal): IParentSelectorNode => {
  const GOAL = "goal";
  return {
    key: goal.id,
    title: goal.name,
    isLeaf: isLeafGoal(goal),
    children: goal?.metrics?.map((metric) => metricNode(metric)),
    icon: GOAL,
    sessionId: goal.sessionId,
    ownerIds: goal.ownerIds,
    type: GOAL,
    isGoal: true,
    private: goal.private,
    attainmentTypeString: goal.attainmentTypeString,
    disabled: goal.currentUserAllowedActions ? !goal.currentUserAllowedActions.includes("create") && !goal.currentUserAllowedActions.includes("update") : false,
  };
};

export const metricNode = (metric: IMultiSelectorMetric): IParentSelectorNode => {
  const METRIC = "metric";

  return {
    key: metric.id,
    title: metric.name,
    isLeaf: true,
    icon: METRIC,
    ownerIds: metric.ownerIds,
    sessionId: metric.sessionId,
    type: METRIC,
    disabled: metric.currentUserAllowedActions ? !metric.currentUserAllowedActions.includes("create") && !metric.currentUserAllowedActions.includes("update") : false,
  };
};

export const kpiNode = (kpi: IMultiSelectorKpi): IRelatedItemsSelectorNode => {
  const KPI = "kpi";

  return {
    key: kpi.id,
    title: kpi.name,
    isLeaf: true,
    icon: KPI,
    ownerIds: kpi.ownerIds,
    type: KPI,
  };
};

export const emptyNode = (): IParentSelectorNode => {
  return { title: "", key: "not available", noOKRsAvailable: true, isLeaf: true, isSelectable: false, readonly: true };
};

const noResultsFoundNode = (): IParentSelectorNode => {
  // key here should not be "", else when this case occur there is a weird chip with "---" in the selector
  // because key is mathing to missing value
  // it is not related to localization
  return { title: "", key: "no results", noResultsFound: true, isLeaf: true, isSelectable: false, readonly: true };
};

const isLeafGoal = (goal: IMultiSelectorGoal): boolean => {
  return Boolean(!goal?.metrics || goal?.metrics?.length === 0);
};

export const buildSearchNodes = (items: Search<"goals" | "metrics" | "kpis">[]): Array<IParentSelectorNode | IRelatedItemsSelectorNode> => {
  return items.reduce((nodes, item) => {
    let childNode: IParentSelectorNode | IRelatedItemsSelectorNode;

    if (item.collectionName === "metrics") {
      const metricItem = item as Search<"metrics">;

      childNode = metricNode({
        id: metricItem.id,
        name: metricItem.fields.name,
        ownerIds: metricItem.fields.ownerIds,
        currentUserAllowedActions: metricItem.fields.currentUserAllowedActions,
      });
    }

    if (item.collectionName === "goals") {
      const goalItem = item as Search<"goals">;

      childNode = objectiveNode({
        id: goalItem.id,
        name: goalItem.fields.name,
        sessionId: goalItem.fields.session?._id,
        ownerIds: goalItem.fields.ownerIds,
        currentUserAllowedActions: goalItem.fields.currentUserAllowedActions,
      });
    }

    if (item.collectionName === "kpis") {
      const kpiItem = item as Search<"kpis">;

      childNode = kpiNode({
        id: kpiItem.id,
        name: kpiItem.fields.name,
        ownerIds: kpiItem.fields.ownerIds,
      });
    }

    nodes.push(childNode);

    return nodes;
  }, []);
};

export function getAllChildrenNodeIdsAndType(nodes: IParentSelectorNode[]): IParentSelector[] {
  const childrenNodeIdsAndType: IParentSelector[] = [];

  const traverseNodes = (nodes: IParentSelectorNode[]): void => {
    nodes.forEach((node) => {
      if (node.isLeaf) {
        childrenNodeIdsAndType.push({ id: node.key, type: node.type, sessionId: node.sessionId });
      } else {
        traverseNodes(node.children);
      }
    });
  };

  traverseNodes(nodes);

  return childrenNodeIdsAndType;
}

export function applyDisabledStatusAndTooltipToAllChildrenNodes(nodes: IParentSelectorNode[], statusMap: Record<string, ICanLinkInfoRecord>): void {
  const traverseNodes = (nodes: IParentSelectorNode[]): void => {
    nodes.forEach((node) => {
      node.disabled = statusMap[node.key]?.canLink === false;
      node.tooltip = !statusMap[node.key]?.canLink && statusMap[node.key]?.reason;

      if (node.children) {
        traverseNodes(node.children);
      }
    });
  };

  traverseNodes(nodes);
}

function buildSessionsNode(
  section: string,
  sections: Map<string, Map<string, { expand: boolean }>>,
  goalItemsMap: Map<string, AdvancedOkrSelectorItem>,
  sessionMap: Map<string, Session>,
  limitOkrsShownInSession: number
): IParentSelectorNode[] {
  const sessionNodes: IParentSelectorNode[] = [];

  for (const [sessionId, shouldExpand] of sections.get(section).entries()) {
    const goalNodes = [];
    for (const item of goalItemsMap.values()) {
      if (goalNodes.length >= limitOkrsShownInSession) break;

      if (item.sessionId === sessionId) {
        goalNodes.push(item);
      }
    }

    if (sessionMap.has(sessionId)) {
      sessionNodes.push(sessionNode({ sessionId, sessionTitle: sessionMap.get(sessionId).title, childrenNodes: goalNodes, expanded: shouldExpand.expand }));
    }
  }

  return sessionNodes;
}

function buildFirstSectionSessionNodes(
  section: string,
  sections: Map<string, Map<string, { expand: boolean }>>,
  goalItemsMap: Map<string, AdvancedOkrSelectorItem>,
  sessionMap: Map<string, Session>,
  limitOkrsShownInSession: number
): IParentSelectorNode[] {
  const sessionNodes: IParentSelectorNode[] = [];

  for (const [sessionId, shouldExpand] of sections.get(section).entries()) {
    const goalNodes = [];
    for (const item of goalItemsMap.values()) {
      if (goalNodes.length >= limitOkrsShownInSession) break;

      if (item.sessionId === sessionId) {
        goalNodes.push(item);
      }
    }

    if (sessionMap.has(sessionId) && goalNodes.length) {
      sessionNodes.push(sessionNode({ sessionId, sessionTitle: sessionMap.get(sessionId).title, childrenNodes: goalNodes, expanded: shouldExpand.expand }));
    }
  }

  return sessionNodes;
}

export function buildSectionNode(
  sections: Map<string, Map<string, { expand: boolean }>>,
  goalItemsMap: Map<string, AdvancedOkrSelectorItem>,
  sessionMap: Map<string, Session>,
  limitOkrsShownInSession: number
): IParentSelectorNode[] {
  const sectionsMap: IParentSelectorNode[] = [];

  const [firstSection, ...secondSection] = sections.keys();

  const firstSectionSessionNodes: IParentSelectorNode[] = buildFirstSectionSessionNodes(firstSection, sections, goalItemsMap, sessionMap, limitOkrsShownInSession);
  if (firstSectionSessionNodes.length) {
    sectionsMap.push(sectionNode(firstSection, firstSectionSessionNodes));
  }

  secondSection.forEach((section) => {
    const sessionNodes: IParentSelectorNode[] = buildSessionsNode(section, sections, goalItemsMap, sessionMap, limitOkrsShownInSession);

    if (sessionNodes.length) {
      sectionsMap.push(sectionNode(section, sessionNodes));
    }
  });

  if (sectionsMap.length === 0) {
    sectionsMap.push(noResultsFoundNode());
  }

  return sectionsMap;
}
