import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, take } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { IPlan, PricingEditionService } from "@gtmhub/edition-plan-change";
import { ISubscriptionConverted } from "@gtmhub/login/models";
import { AccountResolverService } from "@webapp/accounts";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import dayjs from "@webapp/shared/libs/dayjs";
import { STRATEGIES_ADDON_ID, TRIAL_END_DATE, TRIAL_START_DATE } from "@webapp/strategy/models/strategy.constants";
import { AccountDTO, AddonDTO, GOLD_PLAN_IDS, TEAM_PLAN_IDS } from "@webapp/strategy/services/accounts/models/account.models";
import { CurrentAccountDTORepository } from "@webapp/strategy/services/accounts/services/current-account-dto.repository";
import { diffBetweenTodayAndEndOfDate } from "@webapp/strategy/services/shared/utils";

export enum UpgradeButtonState {
  STATIC = "static",
  TRIAL = "trial",
}

const gtmhubScaleV2 = "gtmhub-scale-v2";

@Injectable()
export class BillingInfoService {
  private secondsLeft: number;
  private timeLeft: string;
  private account: IAccount;
  private isAccountTrial: boolean;
  private isDemoAccount: boolean;
  private isInternalAccount$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private shouldShowUpgrade$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private availableUpgradePlans: IPlan[];
  private accountTypes: {
    trial: number;
    client: number;
    internal: number;
    demo: number;
  };
  private upgradeButtonState: UpgradeButtonState;
  private isAccountConverted: boolean;

  constructor(
    private broadcastService: BroadcastService,
    private accountResolverService: AccountResolverService,
    private priceEditionService: PricingEditionService,
    private currentAccountRepository: CurrentAccountDTORepository
  ) {
    this.accountTypes = {
      trial: 0,
      client: 1,
      internal: 2,
      demo: 3,
    };

    this.account = this.accountResolverService.getAccountData();

    if (this.account.subscriptions.length) {
      this.isAccountConverted = this.account.subscriptions.find((license) => license.type === "regular").isConverted;
    }

    this.isAccountTrial = this.account.type === this.accountTypes.trial && !this.isAccountConverted;
    this.isDemoAccount = this.account.type === this.accountTypes.demo;
    this.isInternalAccount$.next(this.account.type === this.accountTypes.internal);

    this.checkExpiration();
    this.upgradeButtonState = this.setUpgradeButtonState();

    this.broadcastService.on("subscriptionConverted").subscribe((subscriptionConverted: ISubscriptionConverted) => this.subscriptionConverted(subscriptionConverted));

    this.priceEditionService.getAllowedTransitions(this.account.type).then((plans) => {
      this.availableUpgradePlans = plans;
      this.shouldShowUpgrade$.next(this.showUpgradePlanButton());
    });
  }

  public getAccountIsInternal$(): BehaviorSubject<boolean> {
    return this.isInternalAccount$;
  }

  public getUpgradeShouldBeShown$(): Observable<boolean> {
    return this.shouldShowUpgrade$.asObservable();
  }

  public getUpgradeButtonState(): UpgradeButtonState {
    return this.upgradeButtonState;
  }

  public getTimeLeft(): string {
    return this.timeLeft;
  }

  public getStrategiesTrialStartEndDate$(): Observable<{
    startDate: string;
    endDate: string;
  }> {
    return this.currentAccountRepository.get$().pipe(
      take(1),
      map((account) => {
        if (this.accountPlanIsGold(account) || this.accountPlanIsTeams(account)) {
          return {
            startDate: account.dateCreated,
            endDate: account.trialEnds,
          };
        }
        const addon = this.getStrategiesTrialAddonFromAccount(account);
        const trialEnds = addon?.trialEnds || TRIAL_END_DATE;
        return {
          startDate: addon?.addonAdded || TRIAL_START_DATE,
          endDate: trialEnds,
        };
      })
    );
  }

  public checkExpirationOfTrialAccount$(): Observable<{
    secondsLeft: number;
    timeLeft: string;
    isTrialAccount: boolean;
  }> {
    return this.currentAccountRepository.get$().pipe(
      take(1),
      map((account) => {
        const endDate = this.getStrategiesTrialEndDateFromAccount(account);
        const secondsLeft = endDate.diff(dayjs(), "seconds");
        let timeLeft: string = null;

        // hours converted to seconds
        const twentyFourHours = 86400;
        const fortyEightHours = 172800;
        const twoHours = 7200;
        const oneHour = 3600;

        if (secondsLeft > twentyFourHours && secondsLeft < fortyEightHours) {
          timeLeft = `1 day`;
        }

        if (secondsLeft > fortyEightHours) {
          timeLeft = `${diffBetweenTodayAndEndOfDate(endDate.format("YYYY-MM-DD"))} days`;
        }

        if (secondsLeft < twoHours && secondsLeft > oneHour) {
          timeLeft = `1 hour`;
        }

        if (secondsLeft < twentyFourHours && secondsLeft > twoHours) {
          timeLeft = `${Math.ceil(secondsLeft / oneHour)} hours`;
        }

        if (secondsLeft > 0 && secondsLeft < oneHour) {
          timeLeft = `${Math.ceil(secondsLeft / 60)} minutes`;
        }

        if (secondsLeft <= 0) {
          timeLeft = "0";
        }

        return {
          secondsLeft,
          timeLeft,
          isTrialAccount: account.type === 0,
        };
      })
    );
  }

  private getStrategiesTrialEndDateFromAccount(account: AccountDTO): dayjs.Dayjs {
    let endDate = dayjs(TRIAL_END_DATE);
    if (this.accountPlanIsGold(account) || this.accountPlanIsTeams(account)) {
      endDate = dayjs(account.trialEnds);
    } else {
      const addon = this.getStrategiesTrialAddonFromAccount(account);
      if (addon) {
        endDate = dayjs(addon.trialEnds);
      }
    }
    return endDate;
  }

  private showUpgradePlanButton(): boolean {
    if (!this.availableUpgradePlans?.length) {
      return false;
    }

    if (this.account.saasSubscriptionId && this.account.subscriptionProvider === "azure") {
      return false;
    }

    return !this.isDemoAccount && !this.isInternalAccount$.value && (this.account.edition.name !== gtmhubScaleV2 || this.isAccountTrial);
  }

  private setUpgradeButtonState(): UpgradeButtonState {
    if (this.account.edition.name === "gtmhub-essential") {
      return UpgradeButtonState.STATIC;
    }

    return this.secondsLeft > 0 ? UpgradeButtonState.TRIAL : UpgradeButtonState.STATIC;
  }

  private checkExpiration(): void {
    const trialExpiration = this.accountResolverService.checkExpirationOfTrialAccount();
    this.secondsLeft = trialExpiration.secondsLeft;
    this.timeLeft = trialExpiration.timeLeft;
  }

  private subscriptionConverted(subscriptionConverted: ISubscriptionConverted): void {
    if (this.account.id === subscriptionConverted.accountId) {
      const subscriptionTypeRegular = subscriptionConverted.subscriptions.find((license) => license.type === "regular");
      this.isAccountTrial = !subscriptionTypeRegular.isConverted;
      this.shouldShowUpgrade$.next(this.showUpgradePlanButton());
    }
  }

  private getStrategiesTrialAddonFromAccount(account: AccountDTO): AddonDTO | null {
    if (account.subscriptions.length > 0) {
      for (let i = 0; i < account.subscriptions.length; i++) {
        const subscription = account.subscriptions[i];
        if (!subscription.addons || subscription.addons.length === 0) continue;
        for (let j = 0; j < subscription.addons.length; j++) {
          const addon = subscription.addons[j];
          if (addon.addonId === STRATEGIES_ADDON_ID) {
            return addon;
          }
        }
      }
    }
    return null;
  }

  private accountPlanIsGold(account: AccountDTO): boolean {
    return GOLD_PLAN_IDS.includes(account.edition.planId);
  }

  private accountPlanIsTeams(account: AccountDTO): boolean {
    return TEAM_PLAN_IDS.includes(account.edition.planId);
  }
}
