import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiAvatarSize } from "@quantive/ui-kit/avatar";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiAssigneeAvatarGroupComponent } from "@webapp/ui/assignee/components/assignee-avatar-group/assignee-avatar-group.component";

@Component({
  standalone: true,
  selector: "ui-current-user-assignee-avatar",
  templateUrl: "./current-user-assignee-avatar.html",
  styleUrls: ["./current-user-assignee-avatar.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiAssigneeModule, AssigneesModule],
})
export class CurrentUserAssigneeAvatarComponent {
  @Input() public id: string;
  @Input() public size: UiAvatarSize;

  /**
   * @internal
   */
  public group: UiAssigneeAvatarGroupComponent;
}
