import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiAlertModule } from "@quantive/ui-kit/alert";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiOutletModule } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTabModule } from "@quantive/ui-kit/tab";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ToggleNavComponent } from "@webapp/navigation/components/toggle-navigation/toggle-nav.component";
import { SimplePermissionsComponent } from "@webapp/permissions/components/simple-permissions/simple-permissions.component";
import { GlobalSearchComponent } from "@webapp/search/components/global-search/global-search.component";
import { TopNavBarAvatarsComponent } from "@webapp/top-nav-bar/components/top-nav-bar-avatars/top-nav-bar-avatars";
import { TopNavBarTabsComponent } from "@webapp/top-nav-bar/components/top-nav-bar-tabs/top-nav-bar-tabs.component";
import { UiDividerModule } from "@webapp/ui/divider/divider.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiInputTextFieldComponent } from "@webapp/ui/input/components/input-text-field/input-text-field.component";
import { TopNavBarButtonsComponent } from "./components/top-nav-bar-buttons/top-nav-bar-buttons.component";
import { TopNavBarNotificationProvidersModule } from "./services/notifications/top-nav-bar-notification-providers-module";
import { TopNavBarComponent } from "./top-nav-bar.component";

@NgModule({
  imports: [
    CommonModule,
    UiButtonModule,
    UiGridModule,
    GlobalSearchComponent,
    UiIconModule,
    UiTabModule,
    UiDividerModule,
    UiOutletModule,
    ToggleNavComponent,
    UiTooltipModule,
    TopNavBarButtonsComponent,
    TopNavBarTabsComponent,
    TopNavBarAvatarsComponent,
    SimplePermissionsComponent,
    UiInputTextFieldComponent,
    TopNavBarNotificationProvidersModule,
    UiAlertModule,
    LocalizationModule,
  ],
  declarations: [TopNavBarComponent],
  exports: [TopNavBarComponent],
})
export class TopNavBarModule {}
