@if (design === "upload") {
  <button
    [uiTooltipTitle]="'upload_x_files_max_y_each' | localize: { maxFiles: '10', maxFileSize: '50MB', fileTypes: acceptedFileExtentions.toLocaleUpperCase() }"
    (click)="onClick()"
    ui-button
    ui-tooltip
    uiShape="circle"
    uiSize="default"
    uiTooltipPlacement="bottom"
    uiType="default">
    @if (uploadedFilesCount) {
      <ui-badge [uiCount]="uploadedFilesCount" [uiShowDot]="false" [uiStyle]="{ 'background-color': '#0057d7' }" uiSize="small" />
    }

    <i ui-icon uiType="upload"></i>
  </button>
}

@if (design === "files-context") {
  <button
    class="files-context"
    [uiTooltipTitle]="'upload_x_files_max_y_each' | localize: { maxFiles: '10', maxFileSize: '50MB', fileTypes: acceptedFileExtentions.toLocaleUpperCase() }"
    (click)="onClick()"
    ui-tooltip
    uiTooltipPlacement="bottom">
    <span>{{ uploadedFilesCount }} file{{ uploadedFilesCount !== 1 ? "s" : "" }}</span>
    <i ui-icon uiTheme="fill" uiType="files-context"></i>
  </button>
}
