import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiCheckboxModule } from "@quantive/ui-kit/checkbox";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonLineComponent } from "@quantive/ui-kit/skeleton";
import { storage } from "@gtmhub/core/storage";
import { ItemActionHandler } from "@gtmhub/shared/components/ui/models";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { StrategicMapAreaVM, StrategicMapHypothesisVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { VALIDATION_STATUS_COMPLETED } from "@webapp/strategy/models/strategy.constants";
import { AsyncOperationPreviewState } from "@webapp/strategy/models/strategy.vm-models";
import { UiAssigneeAvatarComponent } from "@webapp/ui/assignee/components/assignee-avatar/assignee-avatar.component";
import { AsyncStatusComponent } from "../../../../../async-status/async-status.component";
import { HypothesisFormComponent } from "../hypothesis-form/hypothesis-form.component";

type HypothesisCardOutput = {
  area: StrategicMapAreaVM;
  hypothesis: StrategicMapHypothesisVM;
};

@Component({
  selector: "hypothesis-card",
  templateUrl: "./hypothesis-card.component.html",
  styleUrl: "./hypothesis-card.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiIconModule,
    NgIf,
    HypothesisFormComponent,
    NgClass,
    FeatureTogglesModule,
    DropdownModule,
    UiButtonComponent,
    UiAssigneeAvatarComponent,
    AsyncStatusComponent,
    UiSkeletonLineComponent,
    UiCheckboxModule,
  ],
})
export class HypothesisCardComponent implements OnInit, OnChanges {
  public validationStatusCompleted = VALIDATION_STATUS_COMPLETED;
  public currentUserId = storage.get("userId");
  public hypothesisLoadingState: AsyncOperationPreviewState;
  public assignee: Assignee | null = null;
  public assigneeTooltip: string;

  @Input() public hypothesisPassesGrooming: boolean;
  @Input() public area: StrategicMapAreaVM;
  @Input() public hypothesis: StrategicMapHypothesisVM;
  @Input() public hypothesisMenuItems: DropdownMenuItem[];
  @Input() public areaBeingModifiedId: string;
  @Input() public lockedBy?: string;
  @Input() public hypothesisTasksState: AsyncOperationPreviewState = {
    state: "LOADED",
    tooltip: "Response is Ready",
    seen: false,
  };
  @Input() public grooming: boolean = false;

  @Output() public readonly hypothesisCheckboxChange = new EventEmitter<void>();
  @Output() private readonly saveHypothesis = new EventEmitter<
    HypothesisCardOutput & {
      event: Event;
    }
  >();
  @Output() public readonly deleteHypothesis = new EventEmitter<void>();
  @Output() private readonly cancelSaveHypothesis = new EventEmitter<HypothesisCardOutput>();
  @Output() private readonly editHypothesis = new EventEmitter<HypothesisCardOutput>();
  @Output() private readonly cancelEditHypothesis = new EventEmitter<HypothesisCardOutput>();

  constructor(
    private assigneesFacade: AssigneesFacade,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.setupHypothesisLoadingState();
  }

  public ngOnChanges(changes: SimpleChangesOf<HypothesisCardComponent>): void {
    if (changes.lockedBy && changes.lockedBy.currentValue !== changes.lockedBy.previousValue) {
      this.setupHypothesisLoadingState();
      this.setupHypothesisLockedBy();
    }
  }

  private setupHypothesisLockedBy(): void {
    if (!this.lockedBy) return;
    this.assignee = this.assigneesFacade.getById(this.lockedBy);
    this.assigneeTooltip = `${this.assignee.name}${this.currentUserId === this.lockedBy ? " (owner)" : ""} is online`;
    this.cdr.markForCheck();
  }

  private setupHypothesisLoadingState(): void {
    if (!this.lockedBy) return;
    const assignee = this.assigneesFacade.getById(this.lockedBy);
    this.hypothesisLoadingState = {
      state: "LOADING",
      tooltip: `${assignee?.name}${assignee.id === this.currentUserId ? " (owner)" : ""} is analysing the hypothesis...`,
      seen: false,
    };
  }

  public onSaveHypothesis(event: Event): void {
    this.saveHypothesis.emit({
      event,
      area: this.area,
      hypothesis: this.hypothesis,
    });
  }

  public onCancelSaveHypothesis(): void {
    this.cancelSaveHypothesis.emit({
      area: this.area,
      hypothesis: this.hypothesis,
    });
  }

  public onEditHypothesis(): void {
    this.editHypothesis.emit({
      area: this.area,
      hypothesis: this.hypothesis,
    });
  }

  public editHypothesisDuringGrooming($event: MouseEvent): void {
    $event.stopPropagation();
    const editMenuItems = this.hypothesisMenuItems.filter((menuItem) => menuItem.key.toLowerCase().includes("edit"));
    const editMenuItem = editMenuItems.length > 0 ? editMenuItems[0] : null;
    if (editMenuItem) {
      const { handler } = editMenuItem.action as ItemActionHandler;
      if (handler) {
        handler();
      }
    }
  }

  public onCancelEditHypothesis(): void {
    this.cancelEditHypothesis.emit({
      area: this.area,
      hypothesis: this.hypothesis,
    });
  }
}
