<ui-chat-content
  class="chat-content"
  [conversation]="conversation"
  [suggestedFrameworks]="validSuggestedFrameworks"
  [usersMap]="usersMap"
  (addContent)="addContent.emit($event)"
  (askQuestion)="askQuestion.emit($event)"
  (executeFramework)="executeSuggestedFramework($event)"
  (provideFeedback)="provideFeedback.emit($event)"
  (refreshContent)="refreshContent.emit()"
  (retryErroredMessage)="retryErroredMessage.emit()" />
<ui-chat-ask-question class="ask-question" [conversation]="conversation" (askQuestion)="askQuestion.emit($event)" />
