import { DocumentDTOv2 } from "@webapp/strategy/models/documents/documents.dto-v2-models";
import { DocumentVM } from "@webapp/strategy/models/documents/documents.vm-models";
import { NON_SUMMARIZABLE_FILE_EXTENSIONS } from "@webapp/strategy/models/strategy.constants";

export const documentDTOv22VM = (document: DocumentDTOv2): DocumentVM => {
  const fileIsSummarizable = NON_SUMMARIZABLE_FILE_EXTENSIONS.every((extension) => !document.title.endsWith(extension));
  return {
    id: document.id,
    name: document.title,
    sizeInBytes: document.size,
    summary: document.summary,
    isSummarizable: fileIsSummarizable,
  };
};
