import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { AccountDTO } from "../models/account.models";

@Injectable()
export class AccountsApiService extends PlainBaseApiService {
  private accountsBaseUrl = "accounts";

  constructor(httpClient: HttpClient, apiEndpointService: ApiEndpointService) {
    super(httpClient, apiEndpointService);
  }

  public getAccount$(accountId: string): Observable<AccountDTO> {
    const url = this.getApiEndpointV1(`${this.accountsBaseUrl}/${accountId}`);

    return this.get$<AccountDTO>(url);
  }
}
