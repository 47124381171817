import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { take } from "rxjs";
import { Intercom } from "@gtmhub/shared/intercom";
import { DocumentsMediatorService } from "@webapp/strategy/services/documents/documents-mediator.service";
import { UserSettings } from "@webapp/strategy/services/user-settings/user-settings.model";
import { UiDrawerTitleDirective } from "@webapp/ui/drawer/directives/drawer-title.directive";
import { UiLoadingIndicatorComponent } from "@webapp/ui/loading-indicator/loading-indicator.component";
import { ContextVM } from "../../models/context/context.vm-models";
import { StrategyContextForm } from "../../models/strategy.vm-models";
import { StrategyConversationContextService } from "../../services/context/strategy-conversation-context.service";
import { ContextFormComponent } from "../context-form/context-form.component";
import { AlertMessageComponent } from "../evaluate-bet-page/components/alert-message/alert-message.component";
import { MessageBoxComponent } from "../shared/message-box/message-box.component";

@Component({
  selector: "context-drawer",
  templateUrl: "./context-drawer.component.html",
  styleUrls: ["./context-drawer.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiDrawerTitleDirective, UiLoadingIndicatorComponent, NgIf, NgClass, AlertMessageComponent, ContextFormComponent, UiButtonComponent, MessageBoxComponent],
})
export class ContextDrawerComponent implements OnInit {
  @Input() public contextId: string;
  @Input() public closeForm: () => void;
  @Input() public hideAlert: () => void;
  @Input() public userSettings: UserSettings;

  public context: ContextVM | null = null;
  public contextForm: StrategyContextForm;
  public mode = "editing";
  public loadingFailed = false;
  public saveFailed = false;
  public showAlert = false;

  constructor(
    private strategyConversationContextService: StrategyConversationContextService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    public documentsMediator: DocumentsMediatorService
  ) {}

  public ngOnInit(): void {
    this.getContext();
    this.showAlert = !this.userSettings.contextHintSeen;
  }

  public getContext(): void {
    this.loadingFailed = false;

    this.strategyConversationContextService
      .getBetContext$(this.contextId)
      .pipe(take(1))
      .subscribe({
        next: (context) => {
          this.context = context;
          this.contextForm = this.formBuilder.group({
            notes: new FormControl<string>(this.context.notes || ""),
          });
          this.cdr.markForCheck();
        },
        error: () => {
          this.loadingFailed = true;
          this.cdr.markForCheck();
        },
      });
  }

  public updateContext(): void {
    if (this.contextForm.invalid) {
      return;
    }
    const notes = this.contextForm.get("notes").value;
    this.mode = "saving";
    this.saveFailed = false;
    this.strategyConversationContextService
      .updateBetContext$(this.contextId, notes, "Decision Map")
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.closeForm();
        },
        error: () => {
          this.saveFailed = true;
          this.mode = "editing";
          this.cdr.markForCheck();
        },
      });
    this.cdr.markForCheck();
  }

  public hideAlertError(): void {
    this.saveFailed = false;
    this.cdr.markForCheck();
  }

  public hideAlertInternal(): void {
    this.hideAlert();
    this.showAlert = false;
  }

  public onContactSupportClick(): void {
    Intercom("show");
  }
}
