import { GroupDescriptor } from "@progress/kendo-data-query";
import { OrFiltersGroup } from "@gtmhub/shared/components/grid-filtering-bar";
import { ExtendedOrFilterGroups, FilterFieldName } from "@webapp/okrs/okrs-grid-page/models";
import {
  ARRAY_OPERATORS,
  COMMON_OPERATORS,
  createFilterGroup,
  generateAccessTypeFilterFromKey,
  generatePeopleSettings,
} from "@webapp/okrs/okrs-grid-page/shared/utils/filter-factories";
import { ShareableFilterEntityType, ShareableFilterGroup } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { EntityTypeFilter } from "../kpi-views-page/models";

const generateOwnersFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
  createFilterGroup(filterKey, "contains", "array", COMMON_OPERATORS, generatePeopleSettings());

export const generateDefaultKpiViewGridFilters = (): ExtendedOrFilterGroups[] => {
  return [generateOwnersFilterFromKey("ownerId"), generateAccessTypeFilterFromKey("accessType")];
};
const generateTagsFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "contains", "array", ARRAY_OPERATORS);

export const areThereFiltersWithValues = (groups: ExtendedOrFilterGroups[][] | OrFiltersGroup[]): boolean => {
  if (!groups) {
    return false;
  }

  return groups.some((filters) =>
    filters.some((filter) => (Array.isArray(filter.value) && filter.value.length) || (Array.isArray(filter.dynamicValues) && filter.dynamicValues.length))
  );
};

export const generateDefaultKpiGridFilters = (tagsModuleEnabled: boolean): ExtendedOrFilterGroups[] => {
  const orFilterGroups = [generateOwnersFilterFromKey("ownerIds")];

  if (tagsModuleEnabled) {
    orFilterGroups.push(generateTagsFilterFromKey("tags.title"));
  }

  return orFilterGroups;
};

export const entityTypeFilter = (string: ShareableFilterEntityType): EntityTypeFilter => ({ entityType: string });

export const shareableFilterGroupToKendoGroupDescriptor = (groupings: ShareableFilterGroup[]): GroupDescriptor[] =>
  groupings?.map((group) => ({
    field: group.fieldName,
  }));

export const kendoGroupDescriptorToShareableFilterGroup = (groupings: GroupDescriptor[]): ShareableFilterGroup[] =>
  groupings.map((group, index) => ({
    fieldName: group.field,
    groupingOrder: index + 1,
  }));
