import { localize } from "@gtmhub/localization";
import dayjs from "@webapp/shared/libs/dayjs";

export const DATE_FORMAT = "YYYY-MM-DD";

export interface IKpiCadenceRange {
  from: string;
  to: string;
}

export const quarterPattern = /^(\d{4})-Q(\d)$/;
export const weekPattern = /^(\d{4})-W(\d{1,2})$/;
export const yearPattern = /^(\d{4})$/;
export const monthPattern = /^(\d{4})-(\d{2})$/;

const toDateRange = (date: dayjs.Dayjs, period: "year" | "quarter" | "month" | "isoWeek" | "day"): IKpiCadenceRange => ({
  from: date.startOf(period as dayjs.OpUnitType).format(DATE_FORMAT),
  to: date.endOf(period as dayjs.OpUnitType).format(DATE_FORMAT),
});

export const dateRangeForCadence = (cadence: string): IKpiCadenceRange => {
  const quarterMatches = cadence.match(quarterPattern);
  if (quarterMatches) {
    const date = dayjs(`${quarterMatches[1]}-01-01`).quarter(+quarterMatches[2]);
    return toDateRange(date, "quarter");
  }

  const weekMatches = cadence.match(weekPattern);
  if (weekMatches) {
    const date = dayjs(`${weekMatches[1]}-01-31`).isoWeek(+weekMatches[2]);
    return toDateRange(date, "isoWeek");
  }

  if (yearPattern.test(cadence)) {
    const date = dayjs(`${cadence}-01-01`);
    return toDateRange(date, "year");
  }

  if (monthPattern.test(cadence)) {
    const date = dayjs(`${cadence}-01`);
    return toDateRange(date, "month");
  }

  return toDateRange(dayjs(cadence), "day");
};

const getQuarterBoundaries = (quarter: string): string => {
  const quarters = {
    "1": `${localize("january")} - ${localize("march")}`,
    "2": `${localize("april")} - ${localize("june")}`,
    "3": `${localize("july")} - ${localize("september")}`,
    "4": `${localize("october")} - ${localize("december")}`,
  };

  return quarters[quarter];
};

export const generateSnapshotRange = (groupBy: string, dateKey: dayjs.Dayjs): string => {
  const ranges = {
    day: dateKey.format("MMMM, YYYY"),
    week: dateKey.startOf("isoWeek").format("DD") + "-" + dateKey.endOf("isoWeek").format("DD MMM"),
    month: dateKey.format(`[Q]Q, ${dayjs(dateKey.format("YYYY-MM-DD")).daysInMonth()}`) + " " + localize("days"),
    quarter: getQuarterBoundaries(dateKey.format("Q")),
    year: "",
  };

  return ranges[groupBy];
};

export const isMac = (): boolean => {
  return window.navigator.userAgent.indexOf("Mac") != -1;
};

export const isElementInKPITableView = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  const kpiTable = document.querySelector<HTMLElement>("#kpi-table");

  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= kpiTable.clientHeight && rect.right <= kpiTable.clientHeight;
};
