import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, take } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";

type StrategyAiAccess = {
  hasAccess: boolean;
  hasFeature: boolean;
  hasPermission: boolean;
  hasSettingsPermission: boolean;
};

const evaluateAccess = (access$: Observable<StrategyAiAccess>): Observable<boolean> =>
  access$.pipe(map((access) => access.hasPermission && access.hasFeature && access.hasSettingsPermission));

@Injectable()
export class AccessRepository {
  constructor(
    private featureTogglesFacade: FeatureTogglesFacade,
    private editionFeatureService: EditionFeatureService,
    private permissionsFacade: PermissionsFacade
  ) {}

  public shouldSeeSettingsInNav(): Observable<boolean> {
    return combineLatest([
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.SingularitySettingsAvailable),
      evaluateAccess(this.getStrategyAccess$()),
      evaluateAccess(this.getSignalsAccess$()),
      evaluateAccess(this.getDiscoveryAccess$()),
    ]).pipe(map((values) => values.some(Boolean)));
  }

  public getStrategyAccess$(): Observable<StrategyAiAccess> {
    return combineLatest({
      hasFeature: this.editionFeatureService.hasFeature$("strategies").pipe(take(1)),
      hasPermission: this.permissionsFacade.hasPermission$("AccessStrategies").pipe(take(1)),
      hasSettingsPermission: this.permissionsFacade.hasPermission$("AccessSingularitySettings").pipe(take(1)),
      featureFlag: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.SingularityStrategyAvailable).pipe(take(1)),
    }).pipe(
      map((response) => {
        return this.generateAccessObject(response);
      })
    );
  }

  public getSignalsAccess$(): Observable<StrategyAiAccess> {
    return combineLatest({
      hasFeature: this.editionFeatureService.hasFeature$("signals").pipe(take(1)),
      hasPermission: this.permissionsFacade.hasPermission$("AccessSignals").pipe(take(1)),
      hasSettingsPermission: this.permissionsFacade.hasPermission$("AccessSingularitySettings").pipe(take(1)),
      featureFlag: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.SingularityEffortAlignmentAvailable).pipe(take(1)),
    }).pipe(
      map((response) => {
        return this.generateAccessObject(response);
      })
    );
  }

  public getDiscoveryAccess$(): Observable<StrategyAiAccess> {
    return combineLatest({
      hasFeature: this.editionFeatureService.hasFeature$("discoveries").pipe(take(1)),
      hasPermission: this.permissionsFacade.hasPermission$("AccessDiscoveries").pipe(take(1)),
      hasSettingsPermission: this.permissionsFacade.hasPermission$("AccessSingularitySettings").pipe(take(1)),
      featureFlag: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.SingularityDiscoveryAvailable).pipe(take(1)),
    }).pipe(
      map((response) => {
        return this.generateAccessObject(response);
      })
    );
  }

  private generateAccessObject({ hasFeature, hasPermission, hasSettingsPermission, featureFlag }): StrategyAiAccess {
    return {
      hasAccess: (hasFeature && hasPermission) || featureFlag,
      hasPermission,
      hasFeature: hasFeature || featureFlag, // TODO: this is a temp solution for internal accounts until correct account edition features are patched
      hasSettingsPermission,
    };
  }
}
