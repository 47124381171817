import { Injectable } from "@angular/core";
import { TokenProvider } from "@gtmhub/auth";
import { ISocketsApiService } from "../models/sockets-api.interface";
import { BaseSocketsService } from "./base-sockets.service";
import { StrategySocketsApiV2Service } from "./strategy-sockets-api-v2.service";

@Injectable()
export class StrategySocketsService extends BaseSocketsService {
  private currentWatchers: {
    id: string;
    type: string;
    metaData?: Record<string, object>;
  }[] = [];
  constructor(tokenProvider: TokenProvider, apiServiceV2: StrategySocketsApiV2Service) {
    super(tokenProvider);

    this.initApiService(apiServiceV2);
  }

  private initApiService(apiServiceV2: ISocketsApiService): void {
    this.setApiService(apiServiceV2);
  }

  public setCurrentWatchers(watchers: { id: string; type: string; metaData?: Record<string, object> }[]): void {
    this.currentWatchers = watchers;
  }

  public getCurrentWatchers(): {
    id: string;
    type: string;
    metaData?: Record<string, object>;
  }[] {
    return this.currentWatchers;
  }
}
