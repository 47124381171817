<section class="result-box-container">
  <header class="result-box-container-header">
    <h4>{{ title }}</h4>
    <results-box-labels-list class="labels" *ngIf="labels && labels.length > 0" [labels]="labels"></results-box-labels-list>
  </header>
  <ul class="data-list">
    <li *ngFor="let aspect of data">
      <actionable-text-block
        [content]="aspect.text"
        [menuItems]="resultMenuItems"
        (contentChanged)="editSwotSectionItem(aspect, $event)"
        (executeAction)="executeAction.emit($event)"
        (focusOutWithChanges)="focusOutWithChanges.emit()">
      </actionable-text-block>
      <text-references *ngIf="(config$ | async).showReferences && aspect.references?.length > 0" [references]="aspect.references"></text-references>
    </li>
  </ul>
</section>
