import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { ISocketsApiService, SocketEndpointParams } from "../models/sockets-api.interface";

@Injectable()
export class StrategySocketsApiV2Service extends PlainBaseApiService implements ISocketsApiService {
  constructor(httpClient: HttpClient, appConfigService: ApiEndpointService) {
    super(httpClient, appConfigService);
  }

  public getSocketEndpoint = ({ token, accountId }: SocketEndpointParams): string => {
    return this.getWebSocketStrategyV2Endpoint(`/ws/subscribe?authorization=${token}&accountId=${accountId}`);
  };
}
