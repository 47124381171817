import { NgModule } from "@angular/core";
import { AccountStorageService } from "./services/account-storage.service";
import { AccountsApiService } from "./services/accounts-api.service";
import { CurrentAccountCache } from "./services/current-account-cache";
import { CurrentAccountDTORepository } from "./services/current-account-dto.repository";
import { EditionFeatureService } from "./services/edition-feature.service";

@NgModule({
  providers: [AccountStorageService, AccountsApiService, CurrentAccountCache, CurrentAccountDTORepository, EditionFeatureService],
})
export class AccountsProvidersModule {}
