import { IAccountNotification, IConfidenceSettings, IEdition, ILicense } from "@gtmhub/core";
import { Language } from "@gtmhub/localization";

export type Account = {
  id: string;
  name: string;
  isActive: boolean;
  domain: string;
  settings: string;
  edition?: IEdition;
  subscriptionId?: string;
  subscriptions: ILicense[];
  dateCreated: string;
  trialEnds: string;
  ownerId: string;
  type: AccountType;
  notifications: IAccountNotification;
  confidenceSettings: IConfidenceSettings;
  language: Language;
  csManaged?: boolean;
  billingSystem: string;
  trackingProperties: AccountTrackingProperties;
  saasSubscriptionId?: string;
  subscriptionProvider?: string;
  methodologySummary?: string;
  companyDescription?: AccountCompanyDescription;
  accountMarketingInfo?: AccountMarketingInfo;
  campaign?: string;
};

type AccountMarketingInfo = {
  companySize: string;
  country: string;
};

type AccountTrackingProperties = {
  shouldTrack: boolean;
};

export type AccountCompanyDescription = {
  sector?: string;
  industry?: string;
  summary?: string;
};

export type UpdateAccount = {
  language: Language;
  accountName: string;
  domain: string;
  settings: string;
  methodologySummary: string;
  companyDescription: AccountCompanyDescription;
  accountMarketingInfo: AccountMarketingInfo;
};

export enum AccountType {
  TrialAccount,
  ClientAccount,
  InternalAccount,
  DemoAccount,
  PartnerAccount,
  FreeAccount,
}
