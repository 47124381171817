import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiSkeletonLineComponent } from "@quantive/ui-kit/skeleton";
import { TitleAndDescriptionDataVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { ActionableTextBlockComponent } from "../../../actionable-text-block/actionable-text-block.component";

@Component({
  selector: "title-description-result-block",
  templateUrl: "./title-description-result-block.component.html",
  styleUrls: ["./title-description-result-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, ActionableTextBlockComponent, UiSkeletonLineComponent],
})
export class TitleDescriptionResultBlockComponent {
  @Input() public content: TitleAndDescriptionDataVM;
  @Output() public readonly contentChanged = new EventEmitter<string>();
}
