import { StateService } from "@uirouter/angular";
import { query, transition, trigger, useAnimation } from "@angular/animations";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Observable, combineLatest, concat, firstValueFrom, take } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { Intercom } from "@gtmhub/shared/intercom";
import { catchHttpError } from "@webapp/core/rxjs-operators/catch-http-error.operator";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import {
  BetLockVM,
  StrategicBetProgressSubStepVM,
  StrategicMapAreaVM,
  StrategicMapHypothesisVM,
  StrategicMapVM,
} from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { UIErrorConfiguration } from "@webapp/strategy/models/error-handling.model";
import { VALIDATION_STATUS_COMPLETED, VALIDATION_STATUS_NOT_STARTED } from "@webapp/strategy/models/strategy.constants";
import { AsyncOperationPreviewState } from "@webapp/strategy/models/strategy.vm-models";
import { AsyncTasksService } from "@webapp/strategy/services/async-tasks/async-tasks.service";
import { BetLockService } from "@webapp/strategy/services/bet/bets-lock.service";
import { StrategicBetsService } from "@webapp/strategy/services/bet/strategic-bets.service";
import { StrategyConversationMediatorService } from "@webapp/strategy/services/conversation/strategy-conversation.mediator";
import { StrategyConversationService } from "@webapp/strategy/services/conversation/strategy-conversation.service";
import { UserSettings } from "@webapp/strategy/services/user-settings/user-settings.model";
import { StrategiesTrackingService } from "@webapp/strategy/services/utility/strategies-tracking.service";
import { UiErrorHandlingService } from "@webapp/strategy/services/utility/ui-error-handling.service";
import { StrategySocketsService } from "@webapp/strategy/services/web-sockets/services/strategy-sockets.service";
import { UiInputTextAreaComponent } from "@webapp/ui/input/components/input-text-area/input-text-area.component";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData } from "../../../confirm-delete-strategy-item/confirm-delete-strategy-item.component";
import { ErrorDetailsComponent } from "../../../error-details/error-details.component";
import { AlertMessageComponent } from "../alert-message/alert-message.component";
import { ActionableEmptyStateComponent } from "./components/actionable-empty-state/actionable-empty-state.component";
import { AreaCardComponent } from "./components/area-card/area-card.component";
import { HypothesisCardComponent } from "./components/hypothesis-card/hypothesis-card.component";
import { flashAnimation } from "./utils/flash-animation";

@UntilDestroy()
@Component({
  selector: "evaluate-bet-strategic-map",
  templateUrl: "./evaluate-bet-strategic-map.component.html",
  styleUrls: ["./evaluate-bet-strategic-map.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // Prevent the animation on initial load
    trigger("empty", [transition(":enter, :leave", [])]),
    trigger("highlightHypothesis", [
      transition(":leave, * => true, * => status-updated", [
        query(
          ".hypothesis-card > .hypothesis-container",
          [
            useAnimation(flashAnimation, {
              params: {
                duration: 1200,
                flashColor: "#fff0b3",
              },
            }),
          ],
          { optional: true }
        ),
      ]),
    ]),
    trigger("highlightArea", [
      transition(":leave, * => true", [
        query(
          ":self, .hypothesis-card > .hypothesis-container",
          [
            useAnimation(flashAnimation, {
              params: {
                duration: 1200,
                flashColor: "#fff0b3",
              },
            }),
          ],
          {
            optional: true,
          }
        ),
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    AlertMessageComponent,
    NgFor,
    NgClass,
    UiIconModule,
    UiTooltipModule,
    DropdownModule,
    UiButtonComponent,
    HypothesisCardComponent,
    FormsModule,
    ReactiveFormsModule,
    UiTooltipModule,
    AsyncPipe,
    AreaCardComponent,
    ActionableEmptyStateComponent,
    ErrorDetailsComponent,
    FeatureTogglesModule,
  ],
  providers: [UiErrorHandlingService],
})
export class EvaluateBetStrategicMapComponent implements OnInit, OnChanges {
  private hypothesisToOpen: StrategicMapHypothesisVM | null = null;
  private areaToOpen: StrategicMapAreaVM | null = null;

  @Input() public betId: string;
  @Input() public userIsOwner: boolean;
  @Input() public contextId: string;
  @Input() public strategicMap: StrategicMapVM | null;
  @Input() public userSettings: UserSettings;
  @Input() public exportPdfAttempts: Observable<void>;
  @Input() public progressSubStep: StrategicBetProgressSubStepVM;
  @ViewChildren("hypothesisStatement")
  private hypothesisStatements: QueryList<UiInputTextAreaComponent>;
  @ViewChild("hypothesisDeletionError")
  private hypothesisDeletionError: TemplateRef<object>;
  @ViewChild("areaDeletionError")
  private areaDeletionError: TemplateRef<object>;
  @Output() public readonly hideAlert: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly generateOKRs: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly finalizeGrooming: EventEmitter<{
    done: boolean;
  }> = new EventEmitter<{
    done: boolean;
  }>();
  @Output() public readonly generateOnePager: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly updateHypothesisGroomStatus: EventEmitter<{
    areaId: string;
    hypothesisId: string;
    passes: boolean;
  }> = new EventEmitter<{
    areaId: string;
    hypothesisId: string;
    passes: boolean;
  }>();

  public flashingElements: Record<string, boolean> = {};
  public validationStatusCompleted = VALIDATION_STATUS_COMPLETED;
  public updatedHypothesisId: string;
  public readyHypothesisId: string;
  public emptyHypothesisErrorShown = false;
  public zeroHypotheseErrorShown = false;
  public locks: BetLockVM = {};
  public state: "status-updated" | undefined = undefined;
  public uiError: UIErrorConfiguration;

  public areaBeingModifiedId: string | null = null;
  public strategicMapMockLoadingAreas: StrategicMapAreaVM[] = new Array<StrategicMapAreaVM>(5).fill({
    id: null,
    saving: true,
    title: "Loading area",
    status: VALIDATION_STATUS_NOT_STARTED,
    hypotheses: [],
  });

  public dropdownMenuItemsMaps = {
    areas: new Map<StrategicMapAreaVM["id"], DropdownMenuItem[]>(),
    hypotheses: new Map<StrategicMapHypothesisVM["id"], DropdownMenuItem[]>(),
  };
  public ckbFlowEnabled = false;
  public onePagerEnabled = false;
  public onePagerCopyChange = false;

  public showActionsOnHoverEnabled = false;
  public hypothesisToUpdate: StrategicMapHypothesisVM | null = null;

  constructor(
    private strategicBetsService: StrategicBetsService,
    private betLockService: BetLockService,
    private conversationService: StrategyConversationService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalService: UiModalService,
    private trackingService: StrategiesTrackingService,
    private asyncTasksService: AsyncTasksService,
    private conversationMediatorService: StrategyConversationMediatorService,
    private strategySocketsService: StrategySocketsService,
    private errorHandlingService: UiErrorHandlingService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private stateService: StateService
  ) {}

  public ngOnChanges(changes: SimpleChangesOf<EvaluateBetStrategicMapComponent>): void {
    if (changes.strategicMap) {
      if (changes.strategicMap.currentValue) {
        this.setAreasAndHypothesesDropdownMenuItems((<StrategicMapVM>changes.strategicMap.currentValue).areas);
      }

      this.setupOngoingCreationTasks();
    }

    if (changes.progressSubStep && this.strategicMap?.areas) {
      this.setAreasAndHypothesesDropdownMenuItems(this.strategicMap.areas);
    }
  }

  public ngOnInit(): void {
    if (this.strategicMap) {
      this.setupOngoingCreationTasks();
      this.conversationMediatorService.loadConversationState(this.betId, this.strategicMap);
    }

    combineLatest([
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.CKBFlowEnabled),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.OnePagerCopyChange),
    ]).subscribe(([isCKBFlowEnabled, onePagerCopyChange]) => {
      this.ckbFlowEnabled = isCKBFlowEnabled;
      this.onePagerCopyChange = onePagerCopyChange;

      if (this.strategicMap?.areas) {
        this.setAreasAndHypothesesDropdownMenuItems(this.strategicMap.areas);
      }
    });

    this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.OnePagerGeneration).subscribe((isFeatureAvailable: boolean) => {
      this.onePagerEnabled = isFeatureAvailable;
    });

    this.betLockService.initConnection(this.betId);
    this.betLockService
      .getLocks$()
      .pipe(untilDestroyed(this))
      .subscribe((locks: BetLockVM) => {
        this.locks = locks;
        if (this.hypothesisToOpen && this.areaToOpen && locks[this.hypothesisToOpen.id] === userId.get()) {
          this.openHypothesis(this.areaToOpen, this.hypothesisToOpen);
        }
        this.cdr.markForCheck();
      });
    this.exportPdfAttempts.subscribe(() => {
      if (this.strategicMap?.areas.every((a) => a.hypotheses.every((h) => h.status === VALIDATION_STATUS_NOT_STARTED))) {
        this.emptyHypothesisErrorShown = true;
      }
    });

    this.strategySocketsService
      .onMessage$("areaRemoved")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        if (message.data.additionalData.areaId) {
          this.removeArea(message.data.additionalData.areaId);
        }
      });

    this.strategySocketsService
      .onMessage$("updateHypothesis")
      .pipe(untilDestroyed(this))
      .subscribe(({ data }) => {
        const hypothesisResponse = data.additionalData.hypothesisResponse;

        this.hypothesisToUpdate.title = hypothesisResponse.title;
        this.hypothesisToUpdate.editing = false;
        this.hypothesisToUpdate.saving = false;

        this.areaBeingModifiedId = null;
        this.hypothesisToUpdate = null;
        this.cdr.markForCheck();
      });
  }

  public getConversationTaskState$(conversationId: string): Observable<AsyncOperationPreviewState> {
    return this.conversationMediatorService.getConversationTaskState$(conversationId);
  }

  public areaHasAtLeastOneHypothesis(area: StrategicMapAreaVM): boolean {
    return area.hypotheses.filter((h) => h.id).length > 0;
  }

  public get userMessage(): string {
    if (this.userIsOwner) {
      return "The decision map contains areas with hypotheses. Open a hypothesis and use the suggested frameworks to analyse it. The more comprehensive the analyses, the more accurate the generated Objectives will be.";
    }
    return "The decision map contains areas with hypotheses. Open a hypothesis and use the suggested frameworks to analyse it.";
  }

  private setAreasAndHypothesesDropdownMenuItems(areas: StrategicMapAreaVM[]): void {
    if (areas?.length) {
      areas.forEach((area) => {
        if (area.id) {
          this.dropdownMenuItemsMaps.areas.set(area.id, this.getAreaMenuItems(area));

          area.hypotheses.forEach((hypothesis) => {
            this.dropdownMenuItemsMaps.hypotheses.set(hypothesis.id, this.getHypothesisMenuItems(area, hypothesis));
          });
        }
      });
    }
  }

  private setupOngoingCreationTasks(): void {
    this.asyncTasksService
      .getTasksForAccountAndItemId$(this.betId, ["create_strategic_area"])
      .pipe(takeOneUntilDestroyed(this))
      .subscribe((tasks) => {
        const ongoingTasks = tasks.filter((task) => task.status === "PENDING" || task.status === "STARTED");
        const currentlyOngoingTasks = this.strategicMap?.areas.filter((area) => area.id === undefined) || [];
        let missingOngoingTasks = ongoingTasks.length - currentlyOngoingTasks.length;
        while (missingOngoingTasks > 0) {
          const loadingArea = this.createMockedArea(true);
          this.strategicMap.areas.push(loadingArea);
          missingOngoingTasks--;
        }
        this.cdr.markForCheck();
      });
  }

  public getHypothesisMenuItems(area: StrategicMapAreaVM, hypotheses: StrategicMapHypothesisVM): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    const deleteHypothesisItem = menuItemBuilder
      .setKey("Delete hypothesis")
      .setToBeDestructive()
      .setUiType({
        iconType: "trash-bin",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.confirmDeleteHypothesis(hypotheses, area),
      })
      .build();
    const addHypothesisItem = menuItemBuilder
      .setKey("Add hypothesis")
      .setUiType({
        iconType: "plus-circle",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setToBeEndOfSection()
      .setAction({
        handler: () => this.createHypothesis(area),
      })
      .build();

    const editHypothesisItem = menuItemBuilder
      .setKey("Edit hypothesis")
      .setUiType({
        iconType: "edit",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.editHypothesis(area, hypotheses),
      })
      .build();
    return [addHypothesisItem, editHypothesisItem, deleteHypothesisItem];
  }

  public getAreaMenuItems(area: StrategicMapAreaVM): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    const deleteAreaItem = menuItemBuilder
      .setKey("Delete area")
      .setToBeDestructive()
      .setUiType({
        iconType: "trash-bin",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.confirmDeleteArea(area),
      })
      .build();

    const addAreaItemBuilder = menuItemBuilder
      .setKey("Add area")
      .setUiType({
        iconType: "plus-circle",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.createArea(),
      });

    const addAreaItem = addAreaItemBuilder.build();

    const editAreaItem = menuItemBuilder
      .setKey("Edit area")
      .setUiType({
        iconType: "edit",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.editArea(area),
      })
      .build();

    const addHypotheseItem = menuItemBuilder
      .setKey("Add hypothesis")
      .setUiType({
        iconType: "plus-circle",
        iconTheme: "outline",
        uiType: "uiIcon",
      })
      .setAction({
        handler: () => this.createHypothesis(area),
      })
      .setToBeEndOfSection()
      .build();

    return [addHypotheseItem, addAreaItem, editAreaItem, deleteAreaItem];
  }

  public selectHypothesis = (area: StrategicMapAreaVM, hypothesis: StrategicMapHypothesisVM): void => {
    if (this.ckbFlowEnabled && this.progressSubStep === 0) {
      hypothesis.passedGrooming = !hypothesis.passedGrooming;
      this.updateHypothesisGroomStatus.emit({
        areaId: area.id,
        hypothesisId: hypothesis.id,
        passes: hypothesis.passedGrooming,
      });

      this.trackingService.trackSelectHypothese(this.contextId, { isSelected: hypothesis.passedGrooming }, hypothesis.id, area.id);

      this.cdr.markForCheck();
    } else {
      const hypothesisIsLocked = !!this.locks[hypothesis.id];
      const currentUserHasLockedHypothesis = this.locks[hypothesis.id] === storage.get("userId");
      if (!hypothesis.id || hypothesis.editing || (hypothesisIsLocked && !currentUserHasLockedHypothesis)) return;
      if (currentUserHasLockedHypothesis) {
        this.openHypothesis(area, hypothesis);
      } else {
        this.hypothesisToOpen = hypothesis;
        this.areaToOpen = area;
        this.betLockService.lockHypothesis(hypothesis.id);
      }
    }
  };

  private openHypothesis = (area: StrategicMapAreaVM, hypothesis: StrategicMapHypothesisVM): void => {
    this.trackingService.trackStrategyConversationInitiated(this.contextId, hypothesis.conversationId);
    this.conversationService.clearConversationCache(hypothesis.conversationId);

    this.stateService.go("gtmhub.decision.hypothesis", {
      betId: this.betId,
      areaId: area.id,
      hypothesisId: hypothesis.id,
    });
  };

  public removeArea(areaId: string): void {
    this.strategicMap.areas = this.strategicMap.areas.filter((area) => area.id !== areaId);
  }

  public createHypothesis = (area: StrategicMapAreaVM, event?: MouseEvent): void => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.areaBeingModifiedId = area.id;
    const mockedHypothesis = this.createMockedHypothesis();
    area.hypotheses.push(mockedHypothesis);
  };

  public editHypothesis = (area: StrategicMapAreaVM, hypothesis: StrategicMapHypothesisVM, event?: MouseEvent): void => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.areaBeingModifiedId = area.id;
    const hypothesisForm = this.createHypothesisForm(hypothesis);
    hypothesis.editing = true;
    hypothesis.form = hypothesisForm;
  };

  public createArea = (): void => {
    const mockedArea = this.createMockedArea();
    if (this.strategicMap) {
      this.strategicMap.areas.push(mockedArea);
    }
    this.cdr.markForCheck();
  };

  public editArea = (area: StrategicMapAreaVM, event?: MouseEvent): void => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    area.error = false;
    const areaForm = this.formBuilder.group({
      title: new FormControl(area.title),
    });
    this.areaBeingModifiedId = area.id;
    area.form = areaForm;
    area.editing = true;
  };

  public revertEditArea = (area: StrategicMapAreaVM): void => {
    area.editing = false;
    this.areaBeingModifiedId = null;
    this.cdr.markForCheck();
  };

  public confirmDeleteHypothesis = (hypothesis: StrategicMapHypothesisVM, area: StrategicMapAreaVM, event?: MouseEvent): void => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.modalService.confirm<ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData>(
      {
        uiTitle: "Delete hypothesis?",
        uiContent: ConfirmDeleteStrategyItemComponent,
        uiData: {
          strategyItemName: hypothesis.title,
          strategyItemType: "hypothesis",
        },
        uiOkText: "Delete hypothesis",
        uiOkLoadingText: "Deleting hypothesis...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          this.cdr.markForCheck();
          return firstValueFrom(
            this.strategicBetsService.deleteHypothesis$(this.betId, area.id, hypothesis.id).pipe(
              catchHttpError((error: HttpErrorResponse) => {
                this.uiError = this.errorHandlingService.getUIErrorData(error, "delete", "hypothesis");
                throw this.hypothesisDeletionError;
              })
            )
          ).then(() => {
            area.hypotheses = area.hypotheses.filter((h) => h.id !== hypothesis.id);
            this.dropdownMenuItemsMaps.hypotheses.delete(hypothesis.id);

            if (hypothesis.status === VALIDATION_STATUS_NOT_STARTED && area.hypotheses.every((h) => h.status === VALIDATION_STATUS_COMPLETED)) {
              area.status = VALIDATION_STATUS_COMPLETED;
            }

            this.cdr.markForCheck();
          });
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
  };

  public confirmDeleteArea = (area: StrategicMapAreaVM, event?: MouseEvent): void => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.modalService.confirm<ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData>(
      {
        uiTitle: "Delete area?",
        uiContent: ConfirmDeleteStrategyItemComponent,
        uiData: {
          strategyItemName: area.title,
          strategyItemType: "area",
        },
        uiOkText: "Delete area",
        uiOkLoadingText: "Deleting area...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          return firstValueFrom(
            this.strategicBetsService.deleteArea$(this.betId, area.id).pipe(
              catchHttpError((error: HttpErrorResponse) => {
                this.uiError = this.errorHandlingService.getUIErrorData(error, "delete", "area");
                throw this.areaDeletionError;
              })
            )
          ).then(() => {
            this.strategicMap.areas = this.strategicMap.areas.filter((a) => a.id !== area.id);

            this.dropdownMenuItemsMaps.areas.delete(area.id);

            area.hypotheses.forEach((hypothesis) => this.dropdownMenuItemsMaps.hypotheses.delete(hypothesis.id));

            this.cdr.markForCheck();
          });
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
  };

  public saveHypothesis(args: { event: Event; area: StrategicMapAreaVM; hypothesis: StrategicMapHypothesisVM }): void {
    const { event, area, hypothesis } = args;
    const hypothesisName = hypothesis.form.get("statement").value;
    if (hypothesisName === "" || hypothesisName.length > 1000) {
      const hypothesisTextArea = this.hypothesisStatements.find((hypothesisStatement) => {
        return (event.target as HTMLElement).contains(hypothesisStatement.textareaRef.nativeElement);
      });
      if (hypothesisTextArea) {
        hypothesisTextArea.textareaRef.nativeElement.focus();
      }
      return;
    }
    hypothesis.saving = true;
    this.strategicBetsService
      .createHypothesis$(this.betId, area.id, hypothesisName)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (createdHypothesis: StrategicMapHypothesisVM) => {
          hypothesis.id = createdHypothesis.id;
          hypothesis.title = hypothesisName;
          hypothesis.conversationId = createdHypothesis.conversationId;
          hypothesis.status = createdHypothesis.status;
          hypothesis.suggestedFrameworks = createdHypothesis.suggestedFrameworks;
          hypothesis.description = createdHypothesis.description;
          hypothesis.saving = false;
          this.updateAreaBeingModifiedState();
          area.status = VALIDATION_STATUS_NOT_STARTED;

          this.dropdownMenuItemsMaps.hypotheses.set(hypothesis.id, this.getHypothesisMenuItems(area, hypothesis));

          this.cdr.markForCheck();
        },
      });
  }

  public saveTempArea(event: Event, area: StrategicMapAreaVM): void {
    const areaTitle = area.form.get("title").value;
    if (areaTitle === "" || areaTitle.length > 300) {
      const textAreaFormArea = this.hypothesisStatements.find((hypothesisStatement) => {
        return (event.target as HTMLElement).contains(hypothesisStatement.textareaRef.nativeElement);
      });
      if (textAreaFormArea) {
        textAreaFormArea.textareaRef.nativeElement.focus();
      }
      return;
    }
    area.saving = true;
    area.error = false;
    this.cdr.markForCheck();
    this.strategicBetsService
      .createArea$(this.betId, areaTitle)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        error: () => this.markAreaAsErrored(area),
      });
  }

  public updateAreaTitle(area: StrategicMapAreaVM): void {
    area.title = area.form.get("title").value;
    area.saving = true;
    this.strategicBetsService.updateArea$(this.betId, area).subscribe({
      next: (updatedArea) => {
        area.title = updatedArea.title;
        area.editing = false;
        area.saving = false;
        this.areaBeingModifiedId = null;
        this.cdr.markForCheck();
      },
      error: () => this.markAreaAsErrored(area),
    });
  }

  private markAreaAsErrored(area: StrategicMapAreaVM): void {
    area.error = true;
    area.saving = false;
    this.cdr.markForCheck();
  }

  public removeTempHypothesis(args: { area: StrategicMapAreaVM; hypothesis: StrategicMapHypothesisVM }): void {
    const { area, hypothesis } = args;
    area.hypotheses = area.hypotheses.filter((h) => h.uid !== hypothesis.uid);
    this.updateAreaBeingModifiedState();
    this.cdr.markForCheck();
  }

  public removeTempArea(area: StrategicMapAreaVM): void {
    this.strategicMap.areas = this.strategicMap.areas.filter((a) => a.uid !== area.uid);
    this.updateAreaBeingModifiedState();
    this.cdr.markForCheck();
  }

  public retryTempAreaCreation(event: Event, area: StrategicMapAreaVM): void {
    this.saveTempArea(event, area);
  }

  public onContactSupportClick(): void {
    Intercom("show");
  }

  public hideEmptyHypothesisError(): void {
    this.emptyHypothesisErrorShown = false;
  }

  public hideZeroHypotheseErrorShown(): void {
    this.zeroHypotheseErrorShown = false;
  }

  public generateOKRsClicked(): void {
    if (this.strategicMap?.areas.every((a) => a.hypotheses.every((h) => h.status === VALIDATION_STATUS_NOT_STARTED))) {
      this.emptyHypothesisErrorShown = true;
    } else {
      this.generateOKRs.emit();

      const allHypothesis = this.strategicMap.areas.reduce((acc, area) => acc.concat(area.hypotheses), []);

      this.trackingService.trackStrategyRedirectionAction(this.contextId, "Decision Map", "continue", {
        hypotheses_total: allHypothesis.length,
        hypotheses_completed: allHypothesis.filter((hypothesis) => hypothesis.status === VALIDATION_STATUS_COMPLETED).length,
      });
    }
  }

  public generateOnePagerClicked(): void {
    this.generateOnePager.emit();
  }

  public moveToNextSubStep(): void {
    const hasOneHypothesePassedGrooming = this.strategicMap.areas.some((area) => area.hypotheses.some((h) => h.passedGrooming));

    if (!hasOneHypothesePassedGrooming) {
      this.zeroHypotheseErrorShown = true;
      return;
    }

    const hypothesisIdsForDeletion: { aId: string; hId: string }[] = [];
    const areasIdForDeletion: string[] = [];

    for (const area of this.strategicMap.areas) {
      const areaHypothesisForDeletion = area.hypotheses.filter((hypothesis) => !hypothesis.passedGrooming);
      if (areaHypothesisForDeletion.length === area.hypotheses.length) {
        // all hypothesis should be deleted, so we just delete the area
        areasIdForDeletion.push(area.id);
      } else {
        for (const hypothesis of areaHypothesisForDeletion) {
          hypothesisIdsForDeletion.push({ aId: area.id, hId: hypothesis.id });
        }
      }
    }

    let observablesCount = 0;
    concat(
      ...hypothesisIdsForDeletion.map(({ aId, hId }) => {
        return this.strategicBetsService.deleteHypothesis$(this.betId, aId, hId).pipe(take(1));
      }),
      ...areasIdForDeletion.map((aId) => {
        return this.strategicBetsService.deleteArea$(this.betId, aId).pipe(take(1));
      }),
      this.strategicBetsService
        .updateBet$({
          id: this.betId,
          progressSubStep: 1,
        })
        .pipe(take(1))
    ).subscribe(() => {
      observablesCount++;

      if (
        observablesCount ===
        hypothesisIdsForDeletion.length + areasIdForDeletion.length + 1 // +1 for the updateBet$ observable
      ) {
        this.strategicMap.areas.forEach((area) => {
          area.hypotheses = area.hypotheses.filter((h) => h.passedGrooming);
        });

        this.finalizeGrooming.emit({
          done: true,
        });

        this.cdr.markForCheck();
      }
    });

    this.finalizeGrooming.emit({
      done: false,
    });
  }

  public showIntercom(): void {
    Intercom("show");
  }

  private createMockedHypothesis = (): StrategicMapHypothesisVM => {
    const hypothesisForm = this.formBuilder.group({
      statement: new FormControl(""),
    });
    return {
      uid: `temp-${Date.now()}`,
      conversationId: "",
      description: "",
      id: undefined,
      suggestedFrameworks: [],
      status: VALIDATION_STATUS_NOT_STARTED,
      title: "",
      form: hypothesisForm,
      saving: false,
      editing: false,
      isSeen: false,
      passedGrooming: true,
    };
  };

  private createHypothesisForm = (
    hypothesis: StrategicMapHypothesisVM
  ): FormGroup<{
    statement: FormControl<string>;
  }> => {
    return this.formBuilder.group({
      statement: new FormControl(hypothesis.title),
    });
  };

  private createMockedArea = (saving?: boolean): StrategicMapAreaVM => {
    const areaForm = this.formBuilder.group({
      title: new FormControl(""),
    });
    return {
      id: undefined,
      uid: `temp-${Date.now()}`,
      title: "",
      status: VALIDATION_STATUS_NOT_STARTED,
      hypotheses: [],
      form: areaForm,
      saving,
    };
  };

  private updateAreaBeingModifiedState(): void {
    const areaBeingModified: StrategicMapAreaVM | undefined = this.strategicMap.areas.find((a) => a.id === this.areaBeingModifiedId);
    if (areaBeingModified && areaBeingModified.hypotheses.every((h) => h.id !== undefined)) {
      this.areaBeingModifiedId = null;
    }
  }

  public revertEditHypothesis(args: { area: StrategicMapAreaVM; hypothesis: StrategicMapHypothesisVM }): void {
    const { hypothesis } = args;
    hypothesis.editing = false;
    this.areaBeingModifiedId = null;
    this.cdr.markForCheck();
  }

  public saveEditHypothesis(args: { area: StrategicMapAreaVM; hypothesis: StrategicMapHypothesisVM }): void {
    const { hypothesis, area } = args;
    const updatedStatement = hypothesis.form.get("statement").value;

    if (!updatedStatement?.trim()) return;

    if (updatedStatement === hypothesis.title) {
      hypothesis.editing = false;
      this.areaBeingModifiedId = null;
      this.cdr.markForCheck();

      return;
    }

    hypothesis.saving = true;
    this.cdr.markForCheck();

    this.strategicBetsService
      .updateHypothesis$(this.betId, area.id, {
        ...hypothesis,
        title: updatedStatement,
      })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: () => (this.hypothesisToUpdate = hypothesis),
      });
  }

  public getHighlightState(elementId: string): "status-updated" | undefined {
    return this.flashingElements[elementId] ? "status-updated" : undefined;
  }
}
