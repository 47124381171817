import { IIndicator } from "@gtmhub/error-handling";
import { MetricDeadLine } from "@gtmhub/okrs/metrics/utils";
import { IUnitFormatting } from "@gtmhub/shared/models";
import { CreateCommentDTO } from "@webapp/comments";
import { CfMap } from "@webapp/custom-fields/models/custom-fields.models";
import { Links, LinksRequest } from "@webapp/links/models/links.models";
import { IOkrClosedStatus } from "@webapp/okrs/goals/models/goal-status.models";
import { Milestone } from "@webapp/okrs/metrics/components/metric-milestone/metric-milestone.models";
import { IAccess, UserAction } from "@webapp/sessions/models/sessions.model";
import { Gif } from "@webapp/shared/components/gifs/gif.models";
import { NoteWithGifAndMentions } from "@webapp/shared/rich-text-editor-with-mentions/rich-text-editor-with-mentions.component";
import { Tag } from "@webapp/tags/models/tag.model";
import { AggregatedMentions } from "../../../comments/models/comments.models";

interface IBaseMetric {
  id: string;
  name: string;
}

export class Metric implements IBaseMetric, IMetricUI {
  public id!: string;
  public name: string;
  public description?: string;
  public ownerIds: string[];
  public ownerId?: string;
  public sessionId: string;

  public accountId?: string;

  public access?: IAccess;
  public currentUserAllowedActions?: UserAction[];

  public goalId: string;
  public goalName: string;
  public goalOwnerId?: string;
  public goalOwnerIds?: string[];
  public goalAttainment?: number;

  public targetOperator: MetricTargetOperator;
  public cascadeType?: MetricCascadeType;

  public target: number;
  public milestones?: Milestone[];
  public initialValue: number;
  public actual?: number;
  public attainment?: number;
  public critical?: number;

  public insightName?: string;
  public fieldName?: string;
  public settings?: IMetricSettings[];
  public sourceMetricId?: string;
  public dynamic?: boolean;
  public manualType?: string;

  public progressStatus?: string;
  public goalClosedStatus?: Pick<IOkrClosedStatus, "status">;

  public trend?: IMetricTrend[];
  public metricHistory?: IMetricSnapshot[];
  public lastCheckInDate?: string;

  public softDueDate?: string;
  public dueDate?: string;
  public dateCreated?: string;

  public customFields?: CfMap;
  public links?: Links<"metric">;
  public linksRequest?: LinksRequest<"metric">;

  public confidence?: IConfidence;
  public format?: IUnitFormatting;
  public obfuscated?: boolean;

  public color?: string;
  public percentTime?: number;
  public daysLeft?: number;
  public percentTimeToSoftDeadline?: number;
  public daysLeftToSoftDeadline?: number;
  public draft?: boolean;

  public childType?: "metric";
  public orderId?: number;

  public createdById?: string;
  public latestSnapshot?: IMetricSnapshot;
  public deleting?: IIndicator;

  public tags?: Tag[];
  public watchers?: string[];
  public private?: boolean;
  public attainmentDelta?: number;
}

export class MetricDTO {}

export enum MetricTargetOperatorEnum {
  AT_LEAST = "at_least",
  AT_MOST = "at_most",
  SHOULD_STAY_ABOVE = "should_stay_above",
  SHOULD_STAY_BELOW = "should_stay_below",
  BOOLEAN = "boolean",
}

type MetricCascadeType = "absolute_value" | "delta_value" | "";
export type MetricTargetOperator = `${MetricTargetOperatorEnum}`;

interface IMetricTrend {
  date: Date;
  projected: number;
  actual: number;
}

export interface IMetricSnapshot {
  id: string;
  updatedAt: string;
  createdAt: string;
  metricValue: number;
  confidenceValue: number;
  changedBy: string;
  comment: string;
  gif?: Gif;
  valueChange: number;
  attainmentChange: number;
  currentAttainment: number;
  commentsCount: number;
  sourceInfo?: ISnapshotSourceInfo;
  // below properties are used in the UI
  updatedLate?: boolean;
  view?: {
    color: string;
  };
  mentions?: {
    mentioned: {
      comment?: string[];
    };
  };
  customFields?: CfMap;
  // Used for Check-ins reactions
  targetParentId?: string;
}

interface ISnapshotSourceInfo {
  id: string;
  name: string;
  ownerIds: string[];
  updateReason: "valueChange" | "contributorAdded" | "contributorDeleted" | "otherChange";
  goalId?: string;
  type?: "kpi" | "metric";
}

export interface IMetricSettings {
  name: string;
  title?: string;
  value: string;
  valueDisplayName?: string;
  type?: string;
}

export interface IConfidence {
  value: number;
  reason: string;
  ownerId?: string;
  userId?: string;
  date?: string;
}

interface IMetricUI {
  color?: string;
  percentTime?: number; // used in the webapp for the timestamped KRs
  daysLeft?: number; // used in the webapp for the timestamped KRs
  percentTimeToSoftDeadline?: number;
  daysLeftToSoftDeadline?: number;
  draft?: boolean;
}

export interface ICheckin {
  id?: string;
  actual?: number;
  checkInDate?: string;
  createdAt?: string;
  confidence?: number;
  comment?: string;
  customFields?: CfMap;
  gif?: Gif;
}

export interface EditMetricUpdateData {
  confidenceValue?: number;
  value?: number;
  comment?: string;
  gif?: Gif;
  mentions?: AggregatedMentions;
  customFields?: CfMap;
  date?: string;
}

export interface EditMetricUpdateModalData {
  confidence?: number;
  newValue?: number;
  note?: NoteWithGifAndMentions;
  customFields?: CfMap;
  dateOfUpdate?: string;
}

export interface ICheckinV2 {
  format: IUnitFormatting;
  accountId: string;
  attainment: number;
  attainmentChange: number;
  comment: string;
  confidenceValue: number;
  createdAt: string;
  date: string;
  goalId: string;
  id: string;
  metricId: string;
  modifiedBy: string;
  modifiedByInfo: {
    accountId: string;
    avatar: string;
    color: string;
    email: string;
    id: string;
    name: string;
    picture: string;
    type: string;
  };
  name: string;
  ownerId: string;
  target: number;
  targetOperator: string;
  manualType: string;
  value: number;
  gif?: Gif;
  newComment?: CreateCommentDTO;

  // ux
  showForm: boolean;
}

export enum MetricFormEventType {
  KR_FORM_OPENED = "KR Form Opened",
  KR_FORM_CLOSED = "KR Form Closed",
}

export enum MetricMilestoneEventType {
  KR_MILESTONES_ENABLED = "KR Milestones Enabled",
  KR_MILESTONES_CREATED = "KR Milestones Created",
  KR_MILESTONES_DELETED = "KR Milestones Deleted",
}

export enum MetricType {
  DYNAMIC = "dynamic",
  MANUAL = "manual",
}

export enum MetricFormState {
  EDIT = "edit",
  CREATE = "create",
}

export interface Deadline {
  useDeadline: MetricDeadLine;
  dueDate: string;
  softDueDate: string;
}
