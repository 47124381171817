import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  PIKeyResultSuggestion,
  PIKeyResultSuggestionPayload,
  PIObjectiveDescriptionSuggestion,
  PIObjectiveDescriptionSuggestionPayload,
  PIObjectiveSuggestion,
  StrategyObjectiveSuggestionPayload,
} from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { IStrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.interface";
import { StrategyStrategicGuidedOkrApiService } from "./strategic-guided-okr-api.service";

@Injectable({ providedIn: "root" })
export class StrategyStrategicGuidedOkrRepositoryService implements IStrategicGuidedOkrFacade {
  constructor(private api: StrategyStrategicGuidedOkrApiService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getObjectiveDescriptionSuggestion(payload: PIObjectiveDescriptionSuggestionPayload): Observable<PIObjectiveDescriptionSuggestion> {
    throw new Error("Method not implemented.");
  }

  public getObjectiveSuggestions(payload: StrategyObjectiveSuggestionPayload): Observable<PIObjectiveSuggestion> {
    return this.api.post$(payload as unknown, { url: this.api.createObjectiveEndpoint() });
  }

  public getKeyResultSuggestions(payload: PIKeyResultSuggestionPayload): Observable<PIKeyResultSuggestion> {
    return this.api.post$(payload as unknown, { url: this.api.createKeyResultEndpoint() });
  }
}
