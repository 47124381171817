import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { IStrategyConfigApi } from "./strategy-config-api.interface";
import { StrategyConfigApiV2Service } from "./v2/strategy-config-api-v2.service";

@Injectable()
export class StrategyConfigApiFactory {
  constructor(private injector: Injector) {}

  public getApi(): Observable<IStrategyConfigApi> {
    return of(this.injector.get(StrategyConfigApiV2Service));
  }
}
