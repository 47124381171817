import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "strategy" */ "./strategy.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const decisionsFutureState: Ng2StateDeclaration = {
  name: "gtmhub.decisions.**",
  url: "/decisions/",
  lazyLoad,
};

const decisionFutureState: Ng2StateDeclaration = {
  name: "gtmhub.decision.**",
  url: "/decision/:betId/",
  lazyLoad,
};

const decisionNewFutureState: Ng2StateDeclaration = {
  name: "gtmhub.decisionNew.**",
  url: "/create-decision/",
  lazyLoad,
};

const decisionNewV2FutureState: Ng2StateDeclaration = {
  name: "gtmhub.decisionNewV2.**",
  url: "/create-decision-v2/",
  lazyLoad,
};

const decisionV2FutureState: Ng2StateDeclaration = {
  name: "gtmhub.decisionV2.**",
  url: "/decision-v2/:decisionId/",
  lazyLoad,
};

export const states: Ng2StateDeclaration[] = [decisionsFutureState, decisionFutureState, decisionNewFutureState, decisionNewV2FutureState, decisionV2FutureState];
