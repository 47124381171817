<nav *ngIf="showXClose">
  <button class="header-button" (click)="onClosePage()" ui-button uiShape="round">
    <i class="fs-16px" ui-icon uiType="close-big"></i>
  </button>
</nav>
<section class="error-message">
  <message-box
    [buttonLabel]="showRetry ? errorButtonCaption || 'Try again' : ''"
    [description]="errorMessage"
    [errorType]="errorType"
    [showButtonIcon]="showButtonIcon"
    (buttonAction)="onRetry()"
    buttonIcon="refresh"
    icon="caution"
    size="medium" />
</section>
<section class="actions">
  <button (click)="onBack()" ui-button uiShape="round" uiType="link">
    {{ backButtonCaption }}
  </button>
</section>
