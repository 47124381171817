import ngLocale from "@angular/common/locales/en";
import { enLocaleProviders } from "@quantive/ui-kit/i18n";
import "dayjs/locale/en";
import { currentTexts } from "@gtmhub/localization";
import { LocaleBundle } from "@webapp/localization/models/localization.models";
import { kendoMessages } from "@webapp/shared/kendo/i18n/locales/en-us";
import lang from "../../../../../../localization/lang_en.json";

currentTexts.map = lang.l;

// ts-unused-exports:disable-next-line
export const locale: LocaleBundle = { ngLocale, uiLocaleProviders: enLocaleProviders, kendoMessages };
