import dayjs from "@webapp/shared/libs/dayjs";
import {
  AccessDefinitionDTOv2,
  AsyncResponseDTOv2,
  StrategicBetDTOv2,
  StrategicMapAreaDTOv2,
  StrategicMapDTOv2,
  StrategicMapHypothesisDTOv2,
} from "@webapp/strategy/models/bets/strategic-bets.dto-v2-models";
import { AsyncResponseVM, StrategicBetVM, StrategicMapAreaVM, StrategicMapHypothesisVM, StrategicMapVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { AccessDefinitionVM } from "@webapp/strategy/models/users.models";

const isSeenDTO2VM = (state: string): boolean => (state ? state === "seen" : true);
const isSeenVM2DTO = (isSeen: boolean): string => (isSeen ? "seen" : "unseen");

export const strategicBetDTOv22VM = (bet: StrategicBetDTOv2): StrategicBetVM => {
  return {
    id: bet.id,
    name: bet.name,
    ownerId: bet.createdBy,
    contextId: bet.contextId,
    strategicMap: strategicMapDTOv22VM(bet.strategicMap),
    createdAt: dayjs.utc(bet.createdAt).toISOString(), // API createdAt format is not in UTC -> we convert to UTC
    progressStep: bet.progressStep,
    progressSubStep: bet.progressSubStep,
    description: bet.description,
    accountId: bet.accountId,
    collaborators: bet.access.permissions
      .filter((permission) => permission.principalKind === "user")
      .map((permission) => {
        return {
          userId: permission.principalId,
          uiState: "idle",
          role: permission.grant.general.includes("create") && permission.grant.general.includes("delete") ? "owner" : "collaborator",
        };
      }),
    access: accessDefinitionMapDTOv22VM(bet.access),
    isSeen: isSeenDTO2VM(bet.state),
    onePagerContent: bet.onePagerContent,
    whiteboardId: bet.whiteboardId,
    conversationId: bet.conversationId,
  };
};

const accessDefinitionMapDTOv22VM = (access: AccessDefinitionDTOv2): AccessDefinitionVM => {
  return {
    ...access,
    permissions: access.permissions.map((permission) => {
      return {
        principalId: permission.principalId,
        principalKind: permission.principalKind,
        grant: permission.grant,
      };
    }),
  };
};

export const strategicBetVM2DTOv2 = (bet: Partial<StrategicBetVM>): Partial<StrategicBetDTOv2> => {
  return {
    id: bet.id,
    name: bet.name,
    createdBy: bet.ownerId,
    contextId: bet.contextId,
    createdAt: bet.createdAt,
    progressStep: bet.progressStep,
    progressSubStep: bet.progressSubStep,
    description: bet.description,
    accountId: bet.accountId,
    state: isSeenVM2DTO(bet.isSeen),
    ...(bet.strategicMap && {
      strategicMap: strategicMapVM2DTOv2(bet.strategicMap),
    }),
    onePagerContent: bet.onePagerContent,
  };
};

const strategicMapDTOv22VM = (strategicMap: StrategicMapDTOv2): StrategicMapVM => {
  return {
    id: strategicMap.id,
    areas: strategicMap.areas ? strategicMap.areas.map((area) => strategicMapAreaDTOv22VM(area)) : [],
  };
};

const strategicMapVM2DTOv2 = (strategicMap: StrategicMapVM): StrategicMapDTOv2 => {
  return {
    id: strategicMap.id,
    areas: strategicMap.areas.map((area) => strategicMapAreaVM2DTOv2(area)),
  };
};

export const strategicMapAreaDTOv22VM = (area: StrategicMapAreaDTOv2): StrategicMapAreaVM => {
  return {
    id: area.id,
    title: area.title,
    status: area.status,
    hypotheses: area.hypotheses ? area.hypotheses.map((hypothesis) => strategicMapHypothesisDTOv22VM(hypothesis)) : [],
    saving: false,
  };
};

const strategicMapAreaVM2DTOv2 = (area: StrategicMapAreaVM): StrategicMapAreaDTOv2 => {
  return {
    id: area.id,
    title: area.title,
    status: area.status,
    hypotheses: area.hypotheses.map((hypothesis) => strategicMapHypothesisVM2DTOv2(hypothesis)),
  };
};

export const strategicMapPatchAreaVM2DTOv2 = (area: Partial<StrategicMapAreaVM>): Partial<StrategicMapAreaDTOv2> => {
  return {
    id: area.id,
    title: area.title,
    status: area.status,
  };
};

export const strategicMapHypothesisDTOv22VM = (hypothesis: StrategicMapHypothesisDTOv2): StrategicMapHypothesisVM => {
  return {
    conversationId: hypothesis.conversationId,
    description: hypothesis.description,
    id: hypothesis.id,
    status: hypothesis.status,
    suggestedFrameworks: hypothesis.suggestedFrameworks,
    title: hypothesis.title || hypothesis.statement,
    saving: false,
    editing: false,
    isSeen: isSeenDTO2VM(hypothesis.state),
    passedGrooming: false,
  };
};

const strategicMapHypothesisVM2DTOv2 = (hypothesis: StrategicMapHypothesisVM): StrategicMapHypothesisDTOv2 => {
  return {
    id: hypothesis.id,
    conversationId: hypothesis.conversationId,
    description: hypothesis.description,
    status: hypothesis.status,
    suggestedFrameworks: hypothesis.suggestedFrameworks,
    state: isSeenVM2DTO(hypothesis.isSeen),
    // tbd
    title: hypothesis.title,
    statement: hypothesis.title,
  };
};

export const strategicMapHypothesisPatchVM2DTOv2 = (hypothesis: Partial<StrategicMapHypothesisVM>): Partial<StrategicMapHypothesisDTOv2> => {
  return {
    id: hypothesis.id,
    conversationId: hypothesis.conversationId,
    description: hypothesis.description,
    status: hypothesis.status,
    statement: hypothesis.title,
    state: isSeenVM2DTO(hypothesis.isSeen),
  };
};

export const asyncResponseDTOv22VM = (responseDTO: AsyncResponseDTOv2): AsyncResponseVM => {
  return {
    taskId: responseDTO.taskId,
  };
};
