import { FormControl, ValidationErrors } from "@angular/forms";
import { localize } from "@gtmhub/localization";
import { Assignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { isUnknownAssignee } from "@webapp/assignees/utils/assignee.utils";
import { AggregatedMentions } from "@webapp/comments";
import { Kpi } from "@webapp/kpis/models/kpis.models";
import { AutomatedWithKpiMetric } from "@webapp/kpis/services/kpi-modal.service";
import { ILinksSpecDetail, LinksRequest } from "@webapp/links/models/links.models";
import dayjs from "@webapp/shared/libs/dayjs";
import { CHART_NUMBER_OF_ACCESSIBLE_YEAR_IN_FUTURE } from "./components/kpi-details/kpi-chart/utils";

export const MAX_KPI_FRACTION_SIZE = 5;

export function dateValidator(control: FormControl): ValidationErrors {
  if (isDateAfterAllowedAccessibleFutureDate(control.value)) {
    return { dateIsInvalid: true };
  }

  return null;
}

export function isDateAfterAllowedAccessibleFutureDate(date: Date): boolean {
  return dayjs(date).isAfter(dayjs().add(CHART_NUMBER_OF_ACCESSIBLE_YEAR_IN_FUTURE, "years"));
}

export function generateLinksRequest(kpiId: string): LinksRequest<"metric"> {
  return {
    spec: {
      cascading: [
        {
          type: "kpi",
          id: kpiId,
        },
      ] as ILinksSpecDetail<"kpi">[],
    },
  };
}

export function getAutomatedWithKpiMetrics(kpi: Pick<Kpi, "links">): AutomatedWithKpiMetric[] {
  if (kpi?.links?.spec?.contributing) {
    const metricIds = kpi.links.spec.contributing.filter((item) => item.type === "metric").map((item) => item.id);

    return kpi.links.expanded.metrics
      .filter((metric) => metricIds.includes(metric.id))
      .map((metric) => {
        return {
          name: metric.name,
          ownerIds: metric.ownerIds,
        };
      });
  }

  return [];
}

export function generateRemoveAllMentionsAriaLabel(mentions: AggregatedMentions, assignees: Record<string, Assignee | UnknownAssignee>): string {
  const allMentions = [...mentions.removable, ...mentions.nonRemovable];
  const names = [];
  let removeAllMentionsAriaLabel = "";

  for (const mention of allMentions) {
    const assignee = assignees[mention];

    if (isUnknownAssignee(assignee)) {
      names.push(localize("deleted_user"));
    } else {
      names.push(assignee.name);
    }
  }

  if (names.length > 0) {
    removeAllMentionsAriaLabel = `${localize("remove_all")}. ${localize("people_notified")}: ${names.join(", ")}`;
  }

  return removeAllMentionsAriaLabel;
}
