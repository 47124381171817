import { RestLayerRequest } from "@webapp/core/abstracts/models/rest-layer-request.models";
import { InvitedUserDTO, RoleDTO } from "../models/user-management.dto-model";

export function getUsersWithRoleFilters(roleId: string, skip: number, limit: number): RestLayerRequest<InvitedUserDTO> {
  return {
    fields: ["id", "email", "firstName", "lastName", ["roles", ["id", "name"]]],
    skip,
    limit,
    filter: {
      roles: {
        $elemMatch: {
          _id: roleId,
        },
      },
    },
    sort: ["email"],
  };
}

export function getUsersWithEmailsFilters(emails: string[]): RestLayerRequest<InvitedUserDTO> {
  return {
    fields: ["id", "email", "firstName", "lastName", ["roles", ["id", "name"]]],
    filter: {
      email: {
        $in: emails,
      },
    },
  };
}

export function getRolesFilters(): RestLayerRequest<RoleDTO> {
  return {
    fields: ["id", "name"],
  };
}
