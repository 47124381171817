import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { getUnknownFrameworkLabel } from "@webapp/strategy/services/utility/strategy-conversation.utils";
import { ConversationChatEntryVM, UserDTO } from "../../models/chat.vm-models";

@Component({
  selector: "ui-chat-block",
  templateUrl: "./chat-block.component.html",
  styleUrls: ["./chat-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBlockComponent {
  @Input() public message: ConversationChatEntryVM | undefined;
  @Input() public user: UserDTO | undefined;
  @Input() public showRegenerate = false;
  @Output() public readonly addContent: EventEmitter<ConversationChatEntryVM> = new EventEmitter<ConversationChatEntryVM>();
  @Output() public readonly refreshContent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly retryErroredMessage: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly provideFeedback: EventEmitter<"positive" | "negative"> = new EventEmitter<"positive" | "negative">();
  @Output() public readonly executeFramework: EventEmitter<string> = new EventEmitter<string>();

  public frameworksMap = {
    pest: "PEST Analysis",
    swot: "SWOT Analysis",
    blue_ocean: "Blue Ocean Analysis",
    porter: "Porter's 5 forces Analysis",
    ansoff: "Ansoff matrix",
    balanced_scorecard: "Balanced Scorecard",
    bcg_matrix: "BCG Matrix",
    scenario_planning: "Scenario Planning",
    gap: "Gap Analysis",
    value_chain: "Value Chain Analysis",
    benchmarking: "Benchmarking",
    mckinsey_7s_model: "McKinsey 7S Model",
    value_proposition_canvas: "Value Proposition Canvas",
    growth_share_matrix: "Growth Share Matrix",
    decision_trees: "Decision Trees",
    root_cause: "Root Cause Analysis",
    fishbone_diagram: "Fishbone Diagram",
    pareto: "Pareto Analysis",
    six_sigma: "Six Sigma",
    lean_thinking: "Lean Thinking",
    the_4ps_of_marketing: "The 4Ps of Marketing",
    the_5ps_model: "The 5Ps Model",
    scenario: "Scenario Analysis",
    company_culture: "Company Culture Analysis",
    game_theory: "Game Theory",
    feasibility_study: "Feasibility Study",
    risk_reward: "Risk Reward Analysis",
  };

  public getFrameworkLabel(framework: string): string {
    if (this.frameworksMap[framework]) {
      return this.frameworksMap[framework];
    }

    return getUnknownFrameworkLabel(framework);
  }

  public getMessageSuggestedFrameworksContent(): string[] {
    return this.message.blockContent as unknown as string[];
  }
}
