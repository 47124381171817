import { IFavoritesItemsGroupedResponse } from "@gtmhub/favorites/models";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";

export interface INavItem {
  id: string;
  uxcType: UXCustomizationType;
  color?: string;
  title?: string;
  icon?: string;
  order?: number;
  lastVisited?: string;
  favoriteId?: string;
  isActive?: boolean;
}

export type NavItemsType = "okrs" | "metrics" | "sessions" | "whiteboards" | "kpis" | "tasks" | "insightboards" | "lists" | "people" | "widgets" | "dashboards"; // will depend on api parameters

export const uxcToNavItemsMap: { [K in UXCustomizationType]?: NavItemsType } = {
  session: "sessions",
  filter: "sessions",
  goal: "okrs",
  whiteboard: "whiteboards",
  kpi: "kpis",
  kpigroup: "kpis",
  kpiview: "kpis",
  task: "tasks",
  dashboard: "insightboards",
  list: "lists",
  team: "people",
  user: "people",
};

type FavoriteItemsResponseKey = keyof IFavoritesItemsGroupedResponse;
export const uxcResponseNavItemsMap: { [key in FavoriteItemsResponseKey]?: NavItemsType } = {
  sessionItems: "sessions",
  filterItems: "sessions",
  goalItems: "okrs",
  whiteboardItems: "whiteboards",
  kpiGroupsItems: "kpis",
  kpiViewItems: "kpis",
  taskItems: "tasks",
  dashboardItems: "insightboards",
  listItems: "lists",
  teamItems: "people",
  userItems: "people",
};

export type NavItemsGroupType = "suggested" | "favorites" | "recent" | "taskgroups" | "myteams";
export interface NavItemsSectionConfig {
  visibleItemsCount: number;
  maxItemsCount?: number;
  sectionLabelKey: string;
  itemsType: NavItemsType;
  itemsGroupType: NavItemsGroupType;
  enableContextMenu?: boolean;
  enableDragAndDrop?: boolean;
  broadcastConfig: NavItemsSectionBroadcastConfig;
}

export class NavItemsSectionBroadcastConfig {
  public navItemActions: NavItemBroadcastConfig = {};
  public navItemContextMenuActions: NavItemContextMenuBroadcastConfig = {};
}

interface NavItemBroadcastConfig {
  viewNavItem?: boolean;
}

interface NavItemContextMenuBroadcastConfig {
  shareLinkToNavItem?(navItemId: string): boolean;
}

export interface NavItemsContextMenuConfig {
  itemsType: NavItemsType;
  itemsGroupType: NavItemsGroupType;
  broadcastConfig: NavItemContextMenuBroadcastConfig;
}

export interface SubNavItemsConfig {
  hasFavorites: boolean;
  hasRecents: boolean;
  hasSuggested: boolean;
}
