import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class EmbedDocumentResponseStrategy implements QuantiveResultsSocketStrategy<"embedDocument"> {
  public messageType: QuantiveResultsSocketStrategy<"embedDocument">["messageType"] = "embedDocument";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"embedDocument">> {
    return pipe(filter(({ data }) => data.item === "document" && data.taskName === "embed_document" && data.userId === userId.get()));
  }
}
