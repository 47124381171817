import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { Observable, switchMap, tap, timer } from "rxjs";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { PeopleSelectorRequest, PeopleSelectorRequestInternal } from "@webapp/shared/components/people-selector/models/models";
import { PeopleSelectorModule } from "@webapp/shared/components/people-selector/people-selector.module";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { BetCollaboratorVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { InviteUserApiService } from "@webapp/strategy/services/user-management/invite-user.api.service";
import { InviteUsersService } from "@webapp/strategy/services/user-management/invite-users.service";
import { StrategySocketsService } from "@webapp/strategy/services/web-sockets/services/strategy-sockets.service";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";
import { AlertMessageComponent } from "../alert-message/alert-message.component";

type InviteUserModalData = {
  inviteUsersToCollaborate$(ids: string[]): Observable<void>;
  removeUsersFromCollaborators$(id: string): Observable<void>;
  closeModal(): void;
  collaborators: BetCollaboratorVM[];
  betId: string;
};

@Component({
  standalone: true,
  selector: "invite-collaborators-modal",
  templateUrl: "./invite-collaborators-modal.component.html",
  styleUrl: "./invite-collaborators-modal.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiButtonModule, CommonModule, UiIconModule, UiAssigneeModule, DropdownModule, AlertMessageComponent, AssigneesModule, PeopleSelectorModule],
  providers: [InviteUsersService, InviteUserApiService],
})
export class InviteCollaboratorsModalComponent implements OnInit {
  public peopleSelectorRequestInternal: PeopleSelectorRequestInternal;
  public selectedUsers: string[] = [];
  public collaboratorDropdownItems: DropdownMenuItem[];
  public invitingStatus: "idle" | "loading" | "error" = "idle";
  public flashItemIds: Record<string, boolean> = {};

  constructor(
    @Inject(UI_MODAL_DATA) public data: InviteUserModalData,
    private cdr: ChangeDetectorRef,
    private invitedUsersService: InviteUsersService,
    private strategySocketsService: StrategySocketsService
  ) {}

  public ngOnInit(): void {
    this.invitedUsersService
      .getInvitedUsersWithStrategyRole$({
        pageIndex: 1,
        pageSize: 1000,
      })
      .subscribe(({ users }) => {
        const userIds = users.map((user) => user.id);
        this.peopleSelectorRequestInternal = {
          ...new PeopleSelectorRequest(),
          type: "user",
        };
        this.peopleSelectorRequestInternal.shouldDisplayAssignee = (assignee): boolean =>
          userIds.includes(assignee.id) && !this.data.collaborators.map((collaborator) => collaborator.userId).includes(assignee.id);
        this.cdr.markForCheck();
      });

    this.strategySocketsService.onMessage$("contributorRemoved").subscribe({
      next: (message) => {
        if (message.data.betId === this.data.betId) {
          this.data.collaborators = this.data.collaborators.filter((collaborator) => collaborator.userId !== message.data.contributorId);
          this.cdr.markForCheck();
        }
      },
    });
  }

  public getCollaboratorItems(collaborator: BetCollaboratorVM): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    const collaboratorFakeItem = menuItemBuilder.setKey("Collaborator").build();
    const removeCollaborator = menuItemBuilder
      .setKey("Remove")
      .setUiType({
        iconTheme: "outline",
        uiType: "uiIcon",
        iconType: "trash-bin",
      })
      .setToBeDestructive()
      .setAction({
        handler: () => {
          this.flashItemIds = {};
          collaborator.uiState = "removing";
          this.cdr.markForCheck();
          this.data
            .removeUsersFromCollaborators$(collaborator.userId)
            .pipe(
              switchMap(() => {
                this.flashItemIds[collaborator.userId] = true;
                collaborator.uiState = "removed";
                this.cdr.markForCheck();

                return timer(1200).pipe(
                  tap(() => {
                    this.flashItemIds = {};
                  })
                );
              })
            )
            .subscribe({
              next: () => {
                this.data.collaborators = this.data.collaborators.filter((c) => c.userId !== collaborator.userId);
                collaborator.uiState = "idle";
                this.cdr.markForCheck();
              },
              error: () => {
                collaborator.uiState = "error";
                this.cdr.markForCheck();
              },
            });
        },
      })
      .build();

    return [collaboratorFakeItem, removeCollaborator];
  }

  public updateExcluded(ids: string[]): void {
    this.selectedUsers = ids;
    this.cdr.markForCheck();
  }

  public invite(): void {
    this.invitingStatus = "loading";
    this.flashItemIds = {};
    this.cdr.markForCheck();
    this.data.inviteUsersToCollaborate$(this.selectedUsers).subscribe({
      next: () => {
        this.data.collaborators = this.data.collaborators.concat(
          this.selectedUsers.map((id) => ({
            userId: id,
            role: "collaborator",
            uiState: "idle",
          }))
        );
        this.selectedUsers.forEach((id) => {
          this.flashItemIds[id] = true;
        });
        this.selectedUsers = [];
        this.invitingStatus = "idle";
        this.cdr.markForCheck();
      },
      error: () => {
        this.invitingStatus = "error";
        this.cdr.markForCheck();
      },
    });
  }

  public goBack(): void {
    this.selectedUsers = [];
    this.cdr.markForCheck();
  }

  public removeErrorState(collaborator: BetCollaboratorVM): void {
    collaborator.uiState = "idle";
    this.cdr.markForCheck();
  }

  public resetInvitingStatus(): void {
    this.invitingStatus = "idle";
    this.cdr.markForCheck();
  }
}
