import { animate, animation, keyframes, style } from "@angular/animations";

export const flashAnimation = animation([
  style({
    "background-color": "{{ flashColor }}",
    "border-color": "{{ flashColor }}",
  }),
  animate(
    "{{ duration }}ms",
    keyframes([
      style({
        "background-color": "{{ flashColor }}",
        "border-color": "{{ flashColor }}",
        offset: 0.8,
      }),
      style({
        "background-color": "*",
        offset: 1,
      }),
    ])
  ),
]);
