import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StrategyConversationViewContext } from "../../models/strategy.vm-models";

@Injectable()
export class StrategyConversationViewContextService {
  private contextObject$ = new BehaviorSubject<StrategyConversationViewContext>({
    isAnsweringQuestions: false,
  });

  public getConfig$(): Observable<StrategyConversationViewContext> {
    return this.contextObject$.asObservable();
  }

  public converstionStartedAnsweringQuestion(): void {
    this.contextObject$.next({ isAnsweringQuestions: true });
  }

  public converstionStoppedAnsweringQuestion(): void {
    this.contextObject$.next({ isAnsweringQuestions: false });
  }
}
