import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { ICollection } from "@webapp/core/core.models";
import { PatchModel } from "@webapp/core/http/models/http.models";
import { GenerateOKRsResponseDTOv2 } from "@webapp/strategy/models/bets/strategic-bets.dto-v2-models";
import { AsyncResponseVM, GenerateOKRsResponseVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { ContextDTOv2 } from "@webapp/strategy/models/context/context.dto-v2-models";
import { ContextVM } from "@webapp/strategy/models/context/context.vm-models";
import { contextDTOv22VM } from "../../../conversation/api-utils/v2/strategy-conversation-mapper-v2.utils";
import { IStrategyConversationContextApi } from "../strategy-conversation-context-api.interface";
import { StrategyConversationContextUrlBuilderV2Service } from "./strategy-conversation-context-url-builder-v2.service";

@Injectable()
export class StrategyConversationContextApiV2Service extends PlainBaseApiService implements IStrategyConversationContextApi {
  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService,
    private strategyConversationContextUrlBuilderV2Service: StrategyConversationContextUrlBuilderV2Service
  ) {
    super(httpClient, apiEndpointService);
  }

  public updateBetContext$(contextId: string, notes: string, name?: string): Observable<ContextVM> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(contextId);
    const newContext: Partial<ContextDTOv2> = {
      notes,
      ...(name && { name }),
    };

    return this.patch$<ContextDTOv2>(url, newContext).pipe(map((context: ContextDTOv2) => contextDTOv22VM(context)));
  }

  public patchBetContext$({ id, ...context }: PatchModel<ContextVM>): Observable<ContextVM> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(id);
    return this.patch$<ContextDTOv2>(url, context).pipe(map((context: ContextDTOv2) => contextDTOv22VM(context)));
  }

  public updateBetContextDocuments$(contextId: string, newDocumentIds: string[]): Observable<ContextVM> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(contextId);
    const newContext: Partial<ContextDTOv2> = { documentIds: newDocumentIds };
    return this.patch$<ContextDTOv2>(url, newContext).pipe(map((context: ContextDTOv2) => contextDTOv22VM(context)));
  }

  public getBetContext$(contextId: string): Observable<ContextVM> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(contextId);
    return this.get$<ContextDTOv2>(url).pipe(map((context: ContextDTOv2) => contextDTOv22VM(context)));
  }

  public createContext$(contextName?: string): Observable<ContextVM> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextsUrl();
    return this.post$<ContextDTOv2>(url, {
      notes: "",
      name: contextName || "",
      questions: [],
    }).pipe(map((context: ContextDTOv2) => contextDTOv22VM(context)));
  }

  public getContexts$({ isStrategicBet }): Observable<ContextVM[]> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextsUrl();
    return this.getAll$<ICollection<ContextDTOv2>>(url, {
      limit: 0,
      filter: { isStrategicBet },
    }).pipe(map((collection: ICollection<ContextDTOv2>) => collection.items.map((context) => contextDTOv22VM(context))));
  }

  public deleteContext$(contextId: string): Observable<void> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(contextId);
    return this.delete$(url);
  }

  public generateGoals$(contextId: string): Observable<GenerateOKRsResponseVM> {
    const contextUrl = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(contextId);
    return this.post$<GenerateOKRsResponseDTOv2>(`${contextUrl}/generate-goals`, {}).pipe(
      map((generateOKRsResponse: GenerateOKRsResponseDTOv2) => ({
        whiteboardId: generateOKRsResponse.whiteboardId,
      }))
    );
  }

  public generateGoalsAsync$(contextId: string): Observable<AsyncResponseVM> {
    const contextUrl = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(contextId);
    return this.post$<AsyncResponseVM>(`${contextUrl}/generate-goals/async`, {});
  }

  public markContextAsSeen$(contextId: string): Observable<void> {
    const url = this.strategyConversationContextUrlBuilderV2Service.getContextUrl(contextId);
    return this.patch$<void>(url, { state: "seen" });
  }
}
