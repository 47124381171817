<ng-container *ngIf="conversation?.chat?.length === 0">
  <ui-chat-block class="chat-message mocked" [message]="suggestedFrameworkMessage" [showRegenerate]="false" (executeFramework)="executeFramework.emit($event)" />
</ng-container>
<ui-chat-block
  class="chat-message"
  *ngFor="let message of conversation?.chat; let last = last"
  [message]="message"
  [showRegenerate]="last"
  [user]="usersMap[message.createdBy]"
  (addContent)="addContent.emit($event)"
  (provideFeedback)="provideFeedbackForMessage(message, $event)"
  (refreshContent)="refreshContent.emit()"
  (retryErroredMessage)="retryErroredMessage.emit()" />
<div #conversationBottom></div>
