<section class="bet-context">
  <section class="alert" *ngIf="!userSettings['contextHintSeen']">
    <alert-message
      (dismissAlert)="hideAlert.emit()"
      buttonLabel="Okay, got it"
      message="Upload files securely to provide additional context on your decision. They remain safe and secure within your Quantive StrategyAI instance."
      type="info" />
  </section>
  <context-form
    class="content"
    *ngIf="contextForm"
    [contextId]="betContext.id"
    [form]="contextForm"
    [loadingContext]="false"
    (formSubmitted)="submitContextForm()"
    additionalContextDescription="For example: Specific details about the company or the goals you want to achieve.">
  </context-form>
</section>
<section class="actions">
  <button (click)="submitContextForm()" type="submit" ui-button uiShape="round" uiSize="large" uiType="primary">
    Generate Decision map
    <i class="fs-16px" ui-icon uiTheme="outline" uiType="next"></i>
  </button>
</section>
