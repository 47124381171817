import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiCardModule } from "@quantive/ui-kit/card";
import { UiTabModule } from "@quantive/ui-kit/tab";
import { combineLatest, of, switchMap, take } from "rxjs";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { OkrWidgetDetailsDTO, Tab } from "@webapp/configuration/components/home-widgets/models/okr-widget-details.models";
import { OkrWidgetDetailsApi } from "@webapp/configuration/components/home-widgets/services/okr-widget-details-api.service";
import { OkrWidgetDetailsRepository } from "@webapp/configuration/components/home-widgets/services/okr-widget-details-repository.service";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ActiveOkrsGroupSortDropdownComponent } from "@webapp/okrs/components/assignee-active-okrs-list/active-okrs-group-sort-dropdown/active-okrs-group-sort-dropdown.component";
import { AssigneeActiveOkrsListComponent } from "@webapp/okrs/components/assignee-active-okrs-list/assignee-active-okrs-list.component";
import {
  ActiveOkrsListGroup,
  ActiveOkrsListGroupSort,
  ActiveOkrsListSort,
  DEFAULT_ACTIVE_OKRS_LIST_GROUP,
  DEFAULT_ACTIVE_OKRS_LIST_SORT,
} from "@webapp/okrs/components/assignee-active-okrs-list/assignee-active-okrs-list.models";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { CurrentUserRepository } from "@webapp/users";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";

@UntilDestroy()
@Component({
  selector: "my-okrs-widget",
  templateUrl: "./my-okrs-widget.component.html",
  styleUrls: ["./my-okrs-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LocalizationModule,
    UiCardModule,
    AssigneeActiveOkrsListComponent,
    WidgetActionsComponent,
    ActiveOkrsGroupSortDropdownComponent,
    FeatureTogglesModule,
    NgIf,
    UiTabModule,
  ],
  providers: [OkrWidgetDetailsApi, OkrWidgetDetailsRepository],
})
export class MyOkrsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public groupBy: ActiveOkrsListGroup;
  public sortBy: ActiveOkrsListSort;

  public tabs: Tab[] = [];
  public tabContext: { okrViewIds: string[]; isSystem: boolean };
  public currTabIdx = 0;
  public isCustomHomeWidgetAvailable = false;
  public isTabSystem = false;
  private hasManageHomePermission: boolean;
  private widgetConfig: OkrWidgetDetailsDTO;

  public constructor(
    private currentUserRepository: CurrentUserRepository,
    private featureTogglesFacade: FeatureTogglesFacade,
    private okrWidgetDetailsRepo: OkrWidgetDetailsRepository,
    private cdr: ChangeDetectorRef,
    private permissionsFacade: PermissionsFacade
  ) {
    super();
    this.processDefaultOrderValues();
  }

  public ngOnInit(): void {
    this.setMyOkrsTabs();
    this.hasPermissions();
  }

  public get title(): string {
    return localize(this.widgetConfig?.title);
  }

  public get a11yLabel(): string {
    return "My OKRs. Owned by me and my teams.";
  }

  public get currentUserId(): string {
    return getCurrentUserId();
  }

  public handleGroupChange(value: ActiveOkrsListGroup): void {
    this.groupBy = value;
    this.persistOkrsGroupSortSettings();
  }

  public handleSortChange(value: ActiveOkrsListSort): void {
    this.sortBy = value;
    this.persistOkrsGroupSortSettings();
  }

  private persistOkrsGroupSortSettings(): void {
    this.currentUserRepository.setUserSetting({
      myOkrsGrouping: {
        groupBy: this.groupBy,
        sortBy: this.sortBy,
      },
    });
  }

  private processDefaultOrderValues(): void {
    combineLatest([
      this.featureTogglesFacade.isFeatureAvailable$("switch-default-group-by-my-okrs-axpo"),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.HomeWidget),
    ])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([isAxpo, isCustomHomeWidgetAvailable]) => {
        this.isCustomHomeWidgetAvailable = isCustomHomeWidgetAvailable;
        const currentUserSettingsForMyOkrsGrouping = this.currentUserRepository.getUserSetting<ActiveOkrsListGroupSort>("myOkrsGrouping");

        if (isAxpo && !currentUserSettingsForMyOkrsGrouping) {
          this.groupBy = "ownership";
          this.sortBy = currentUserSettingsForMyOkrsGrouping?.sortBy ?? DEFAULT_ACTIVE_OKRS_LIST_SORT;
          this.currentUserRepository.setUserSetting({
            myOkrsGrouping: {
              groupBy: this.groupBy,
              sortBy: this.sortBy,
            },
          });
        } else {
          this.groupBy = currentUserSettingsForMyOkrsGrouping?.groupBy ?? DEFAULT_ACTIVE_OKRS_LIST_GROUP;
          this.sortBy = currentUserSettingsForMyOkrsGrouping?.sortBy ?? DEFAULT_ACTIVE_OKRS_LIST_SORT;
        }
      });
  }

  // Customizable widget API (START)
  public changeActiveTabIndex(index: number): void {
    if (index === this.currTabIdx) {
      return;
    }
    this.currTabIdx = index;
    this.isTabSystem = this.tabs[this.currTabIdx].isSystem;
    this.getOkrViewsIds();
    this.cdr.markForCheck();
  }

  public getOkrViewsIds(): void {
    const activeTab = this.tabs[this.currTabIdx];
    this.tabContext = {
      okrViewIds: (activeTab.views || []).map((view) => view.id),
      isSystem: this.tabs[this.currTabIdx].isSystem,
    };
  }

  public hasPermissions(): void {
    this.permissionsFacade.hasPermission$("ManageHomeWidgets").subscribe((hasPermission) => {
      this.hasManageHomePermission = hasPermission;
    });
  }

  private isEmptyOkrWidgetDetails(details: OkrWidgetDetailsDTO): boolean {
    return Object.keys(details).length === 0;
  }

  private setMyOkrsTabs(): void {
    this.okrWidgetDetailsRepo
      .getOkrWidgetConfig$()
      .pipe(
        switchMap((details) => {
          if (!this.isEmptyOkrWidgetDetails(details as OkrWidgetDetailsDTO)) {
            return of(details);
          }

          const defaultDetails: Pick<OkrWidgetDetailsDTO, "tabs"> = {
            tabs: [
              {
                index: 1,
                title: "owned_by_me_and_my_teams",
                isSystem: true,
              },
            ],
          };

          if (!this.hasManageHomePermission) {
            return of(defaultDetails);
          }

          return this.okrWidgetDetailsRepo.post$({
            postModel: defaultDetails,
          });
        })
      )
      .subscribe((details) => {
        this.setWidgetConfigFromDetails(details as OkrWidgetDetailsDTO);
      });
  }

  private setWidgetConfigFromDetails(details: OkrWidgetDetailsDTO): void {
    this.widgetConfig = details;
    this.tabs = details.tabs || [];
    this.isTabSystem = this.tabs[this.currTabIdx].isSystem;
    this.cdr.markForCheck();
  }
  // Customizable widget API (END)
}
