import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class AreaRemovedResponseStrategy implements QuantiveResultsSocketStrategy<"areaRemoved"> {
  public messageType: QuantiveResultsSocketStrategy<"areaRemoved">["messageType"] = "areaRemoved";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"areaRemoved">> {
    return pipe(filter(({ data }) => data.taskName === "delete_strategic_area"));
  }
}
