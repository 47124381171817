export type AccountDTO = {
  id: string;
  name: string;
  isActive: boolean;
  domain: string;
  edition?: AccountEdition;
  dateCreated: string;
  trialEnds: string;
  ownerId: string;
  type: AccountType;
  language: string;
  csManaged?: boolean;
  trackingProperties?: TrackingProperties;
  subscriptions: SubscriptionDTO[];
};

type SubscriptionDTO = {
  addons: AddonDTO[];
};

export type AddonDTO = {
  trialEnds: string;
  addonId: string;
  addonAdded: string;
};

export enum AccountType {
  TrialAccount,
  ClientAccount,
  InternalAccount,
  DemoAccount,
  PartnerAccount,
  FreeAccount,
}

type AccountEdition = {
  id?: string;
  name?: string;
  features?: string[];
  trialDuration: number;
  planId?: string;
  activityHistoryLimit?: number;
  activityHistoryLimitUnit?: "day" | "month";
  activityHistoryRecordLimit?: number;
};

type TrackingProperties = {
  shouldTrack: boolean;
  enableLiveChat?: boolean;
  ignoreConversion?: boolean;
};

export type AccountStatus = "Unknown" | "Trial" | "Active" | "Internal" | "Demo" | "Free";

export const GOLD_PLAN_IDS = ["gtmhub-enterprise-gold-annual"];
export const TEAM_PLAN_IDS = ["quantive-teams-repack-2024"];
