import {
  AnsoffDataVM,
  BalancedScoreCardDataVM,
  BlueOceanDataVM,
  OrderedStrategyResultEntryVM,
  PestDataVM,
  PorterForcesDataVM,
  SwotDataVM,
  TextStrategyResultVM,
} from "../../models/bets/strategic-bets.vm-models";

export const updateSwotBlock = (newUpdatedResultBlock: OrderedStrategyResultEntryVM, resultBlock: OrderedStrategyResultEntryVM): void => {
  (newUpdatedResultBlock.blockContent as SwotDataVM).strengths.forEach((strength, index) => {
    (resultBlock.blockContent as SwotDataVM).strengths[index].text = strength.text;
  });
  (newUpdatedResultBlock.blockContent as SwotDataVM).weaknesses.forEach((weakness, index) => {
    (resultBlock.blockContent as SwotDataVM).weaknesses[index].text = weakness.text;
  });
  (newUpdatedResultBlock.blockContent as SwotDataVM).opportunity.forEach((opportunity, index) => {
    (resultBlock.blockContent as SwotDataVM).opportunity[index].text = opportunity.text;
  });
  (newUpdatedResultBlock.blockContent as SwotDataVM).threats.forEach((threat, index) => {
    (resultBlock.blockContent as SwotDataVM).threats[index].text = threat.text;
  });
};

export const updatePestBlock = (newUpdatedResultBlock: OrderedStrategyResultEntryVM, resultBlock: OrderedStrategyResultEntryVM): void => {
  (newUpdatedResultBlock.blockContent as PestDataVM).economicFactors.forEach((item, index) => {
    (resultBlock.blockContent as PestDataVM).economicFactors[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PestDataVM).environmentalFactors.forEach((item, index) => {
    (resultBlock.blockContent as PestDataVM).environmentalFactors[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PestDataVM).legalFactors.forEach((item, index) => {
    (resultBlock.blockContent as PestDataVM).legalFactors[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PestDataVM).politicalFactors.forEach((item, index) => {
    (resultBlock.blockContent as PestDataVM).politicalFactors[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PestDataVM).socioculturalFactors.forEach((item, index) => {
    (resultBlock.blockContent as PestDataVM).socioculturalFactors[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PestDataVM).technologicalFactors.forEach((item, index) => {
    (resultBlock.blockContent as PestDataVM).technologicalFactors[index].text = item.text;
  });
};

export const updatePorterBlock = (newUpdatedResultBlock: OrderedStrategyResultEntryVM, resultBlock: OrderedStrategyResultEntryVM): void => {
  (newUpdatedResultBlock.blockContent as PorterForcesDataVM).buyersBargainingPowers.forEach((item, index) => {
    (resultBlock.blockContent as PorterForcesDataVM).buyersBargainingPowers[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PorterForcesDataVM).competitionRivalry.forEach((item, index) => {
    (resultBlock.blockContent as PorterForcesDataVM).competitionRivalry[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PorterForcesDataVM).conclusion.forEach((item, index) => {
    (resultBlock.blockContent as PorterForcesDataVM).conclusion[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PorterForcesDataVM).newEntrantThreats.forEach((item, index) => {
    (resultBlock.blockContent as PorterForcesDataVM).newEntrantThreats[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PorterForcesDataVM).substituteThreats.forEach((item, index) => {
    (resultBlock.blockContent as PorterForcesDataVM).substituteThreats[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as PorterForcesDataVM).supplierBargainingPowers.forEach((item, index) => {
    (resultBlock.blockContent as PorterForcesDataVM).supplierBargainingPowers[index].text = item.text;
  });
};

export const updateAnsoffBlock = (newUpdatedResultBlock: OrderedStrategyResultEntryVM, resultBlock: OrderedStrategyResultEntryVM): void => {
  (newUpdatedResultBlock.blockContent as AnsoffDataVM).diversification.forEach((item, index) => {
    (resultBlock.blockContent as AnsoffDataVM).diversification[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as AnsoffDataVM).diversification.forEach((item, index) => {
    (resultBlock.blockContent as AnsoffDataVM).diversification[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as AnsoffDataVM).marketDevelopment.forEach((item, index) => {
    (resultBlock.blockContent as AnsoffDataVM).marketDevelopment[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as AnsoffDataVM).marketPenetration.forEach((item, index) => {
    (resultBlock.blockContent as AnsoffDataVM).marketPenetration[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as AnsoffDataVM).productDevelopment.forEach((item, index) => {
    (resultBlock.blockContent as AnsoffDataVM).productDevelopment[index].text = item.text;
  });
};

export const updateBlueOceanBlock = (newUpdatedResultBlock: OrderedStrategyResultEntryVM, resultBlock: OrderedStrategyResultEntryVM): void => {
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).errcGridAnalysis.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).errcGridAnalysis[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).strategyCanvas.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).strategyCanvas[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).fourActionsFramework.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).fourActionsFramework[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).leadershipAndPeopleDevelopment.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).leadershipAndPeopleDevelopment[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).sixPathsFramework.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).sixPathsFramework[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).marketBoundaryReconstruction.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).marketBoundaryReconstruction[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).strategicSequence.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).strategicSequence[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).threeNoncustomerTiers.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).threeNoncustomerTiers[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BlueOceanDataVM).valueInnovation.forEach((item, index) => {
    (resultBlock.blockContent as BlueOceanDataVM).valueInnovation[index].text = item.text;
  });
};

export const updateBalanceScorecardBlock = (newUpdatedResultBlock: OrderedStrategyResultEntryVM, resultBlock: OrderedStrategyResultEntryVM): void => {
  (newUpdatedResultBlock.blockContent as BalancedScoreCardDataVM).alignmentAndSynergies.forEach((item, index) => {
    (resultBlock.blockContent as BalancedScoreCardDataVM).alignmentAndSynergies[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BalancedScoreCardDataVM).customerPerspectives.forEach((item, index) => {
    (resultBlock.blockContent as BalancedScoreCardDataVM).customerPerspectives[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BalancedScoreCardDataVM).financialPerspectives.forEach((item, index) => {
    (resultBlock.blockContent as BalancedScoreCardDataVM).financialPerspectives[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BalancedScoreCardDataVM).internalProcessPerspectives.forEach((item, index) => {
    (resultBlock.blockContent as BalancedScoreCardDataVM).internalProcessPerspectives[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BalancedScoreCardDataVM).riskAndAdaptations.forEach((item, index) => {
    (resultBlock.blockContent as BalancedScoreCardDataVM).riskAndAdaptations[index].text = item.text;
  });
  (newUpdatedResultBlock.blockContent as BalancedScoreCardDataVM).learningGrowthPerspectives.forEach((item, index) => {
    (resultBlock.blockContent as BalancedScoreCardDataVM).learningGrowthPerspectives[index].text = item.text;
  });
};

export const updateFreeTextBlock = (newUpdatedResultBlock: OrderedStrategyResultEntryVM, resultBlock: OrderedStrategyResultEntryVM): void => {
  (resultBlock.blockContent as TextStrategyResultVM).text = (newUpdatedResultBlock.blockContent as TextStrategyResultVM).text;
};
