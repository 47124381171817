import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { catchHttpError } from "@webapp/core/rxjs-operators/catch-http-error.operator";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { AsyncTaskVM } from "@webapp/strategy/models/async-tasks/async-tasks.vm-models";
import { StrategicBetVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { BET_NO_SUMMARY_ERROR_MESSAGE, WHITEBOARD_GENERATION_ERROR } from "@webapp/strategy/models/strategy.constants";
import { AsyncOperationStatusVM } from "@webapp/strategy/models/strategy.vm-models";
import { AsyncTasksService } from "../../async-tasks/async-tasks.service";
import { getLastStartedTaskFromList } from "../../utility/async-tasks.utils";
import { QuantiveResultsSocketVM } from "../../web-sockets/models/strategy/socket-strategy.vm-models";
import { StrategySocketsService } from "../../web-sockets/services/strategy-sockets.service";
import { StrategicBetsService } from "../strategic-bets.service";

type GenerateGoalsContentState = {
  status: AsyncOperationStatusVM;
  whiteboardId: string;
  errorMessage: string;
};

const asyncTaskStatusToContentStatus: {
  [AsyncTaskStatus in AsyncTaskVM["status"]]: GenerateGoalsContentState["status"];
} = {
  PENDING: "LOADING",
  STARTED: "LOADING",
  SUCCESS: "SUCCESS",
  FAILURE: "ERROR",
};

@UntilDestroy()
@Injectable()
export class GenerateGoalsForBetMediator {
  private socketSubscription: Subscription;
  private generatedContentByBetIdMap$: Record<string, BehaviorSubject<GenerateGoalsContentState>> = {};

  constructor(
    private strategicBetsService: StrategicBetsService,
    private asyncTasks: AsyncTasksService,
    private socketService: StrategySocketsService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public listenForGoalGenerationChangesForBet$({ id, progressStep }: Pick<StrategicBetVM, "id" | "progressStep">): Observable<GenerateGoalsContentState> {
    if (!this.socketSubscription) {
      this.socketSubscription = this.subscribeToChangesViaSocket();
    }

    return this.retrieveStateFromRunningTask$({
      id,
      progressStep,
    }).pipe(
      distinctUntilChanged((prev, curr) => prev.status === curr.status),
      switchMap((state) => {
        this.emitStateChangeForBetId(id, state);

        return this.generatedContentByBetIdMap$[id].asObservable();
      })
    );
  }

  public triggerGoalGenerationForBet(betId: string, options: { onePagerContentOnly?: boolean } = {}): void {
    this.retrieveStateFromAsyncGoalGeneration$(betId, options)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (state) => {
          this.emitStateChangeForBetId(betId, state);
        },
      });
  }

  public triggerOnePagerGenerationForBet(betId: string): void {
    this.checkIfOnePagerGenerationIsEnabled$()
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (isOnePagerGenerationEnabled) => {
          if (!isOnePagerGenerationEnabled) return;

          this.strategicBetsService
            .generateOnePager$(betId)
            .pipe(
              takeOneUntilDestroyed(this),
              map(() => this.toSuccessState(""))
            )
            .subscribe({
              next: (state) => {
                this.emitStateChangeForBetId(betId, state);
              },
            });
        },
      });
  }

  public invalidateGoalGenerationStateForBet(betId: string): void {
    this.strategicBetsService
      .updateBet$({ id: betId, progressStep: 1 })
      .pipe(
        takeOneUntilDestroyed(this),
        map(() => this.toIdleState()),
        catchHttpError((error: HttpErrorResponse) => of(this.toErrorState(error)))
      )
      .subscribe({
        next: (state) => {
          this.emitStateChangeForBetId(betId, state);
        },
      });
  }

  private retrieveStateFromRunningTask$({ id, progressStep }: Pick<StrategicBetVM, "id" | "progressStep">): Observable<GenerateGoalsContentState> {
    return this.retrieveCurrentGoalGenerationStateForBet$({
      id,
      progressStep,
    }).pipe(
      takeOneUntilDestroyed(this),
      switchMap((existingState) => {
        if (existingState) return of(existingState);

        return of(this.toIdleState());
      })
    );
  }

  private retrieveStateFromAsyncGoalGeneration$(betId: string, options: { onePagerContentOnly?: boolean } = {}): Observable<GenerateGoalsContentState> {
    return this.strategicBetsService.generateGoalsAsync$(betId, options).pipe(
      untilDestroyed(this),
      map(() => this.toLoadingState()),
      catchHttpError((error: HttpErrorResponse) => of(this.toErrorState(error)))
    );
  }

  private retrieveCurrentGoalGenerationStateForBet$({ id, progressStep }: Pick<StrategicBetVM, "id" | "progressStep">): Observable<GenerateGoalsContentState | null> {
    if (progressStep !== 3) return of(null);

    return this.asyncTasks.getTasksForBetIds$([id], ["generate_goals_for_bet"]).pipe(
      takeOneUntilDestroyed(this),
      map((runningTasks) => {
        if (!runningTasks.length) return null;

        const lastStartedTask = getLastStartedTaskFromList(runningTasks);

        return {
          status: asyncTaskStatusToContentStatus[lastStartedTask.status],
          whiteboardId: (lastStartedTask.additionalData?.whiteboardId as string) || "",
          errorMessage: "",
        };
      }),
      catchHttpError((error: HttpErrorResponse) => of(this.toErrorState(error)))
    );
  }

  private subscribeToChangesViaSocket(): Subscription {
    return this.socketService
      .onMessage$("generateGoalsForBetResponse")
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ({ data }) => {
          const {
            additionalData: { betId },
          } = data;
          const existingStateEntrySubject$ = this.generatedContentByBetIdMap$[betId];

          if (existingStateEntrySubject$?.value.status === "SUCCESS") return;

          this.reflectSocketStateChange({ socketData: data });
        },
        error: (error: HttpErrorResponse) => {
          this.reflectSocketStateChange({ socketData: null, error });
        },
      });
  }

  private reflectSocketStateChange({
    socketData: {
      status: incommingStatus,
      additionalData: { whiteboardId },
      itemId: betId,
    },
    error,
  }: {
    socketData: QuantiveResultsSocketVM<"generateGoalsForBetResponse">["data"];
    error?: HttpErrorResponse;
  }): void {
    const betState = {
      status: asyncTaskStatusToContentStatus[incommingStatus],
      whiteboardId: whiteboardId || "",
      errorMessage: this.httpErrorToErrorMessage(error),
    };

    this.emitStateChangeForBetId(betId, betState);
  }

  private emitStateChangeForBetId(betId: string, content: GenerateGoalsContentState): void {
    if (!this.generatedContentByBetIdMap$[betId]) {
      this.generatedContentByBetIdMap$[betId] = new BehaviorSubject<GenerateGoalsContentState>(content);
    }

    this.generatedContentByBetIdMap$[betId].next({
      ...this.generatedContentByBetIdMap$[betId].value,
      ...content,
    });
  }

  private toIdleState(): GenerateGoalsContentState {
    return {
      status: "IDLE",
      whiteboardId: "",
      errorMessage: "",
    };
  }

  private toLoadingState(): GenerateGoalsContentState {
    return {
      status: "LOADING",
      whiteboardId: "",
      errorMessage: "",
    };
  }

  private toSuccessState(whiteboardId: string): GenerateGoalsContentState {
    return {
      status: "SUCCESS",
      whiteboardId,
      errorMessage: "",
    };
  }

  private toErrorState(response: HttpErrorResponse): GenerateGoalsContentState {
    return {
      status: "ERROR",
      whiteboardId: "",
      errorMessage: this.httpErrorToErrorMessage(response),
    };
  }

  private httpErrorToErrorMessage(errorResponse: HttpErrorResponse): string {
    if (!errorResponse) return "";

    const errorMap: Record<string, string> = {
      [BET_NO_SUMMARY_ERROR_MESSAGE]: "Your Quantive Assistant needs some time to summarize all the data you provided! Please try again in a few minutes.",
    };

    return errorMap[errorResponse?.error?.message] ? errorMap[errorResponse?.error?.message] : WHITEBOARD_GENERATION_ERROR;
  }

  private checkIfOnePagerGenerationIsEnabled$(): Observable<boolean> {
    return this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.OnePagerGeneration).pipe(takeOneUntilDestroyed(this));
  }
}
