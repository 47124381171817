<!-- Create Hypothesis Form -->
@if (!hypothesis.id) {
  <section class="hypothesis-container">
    <section class="hypothesis-content" *ngIf="!hypothesis.saving; else cardLoading">
      <h6>Add hypothesis</h6>
      <hypothesis-form [hypothesis]="hypothesis" (hypothesisCancel)="onCancelSaveHypothesis()" (hypothesisSubmit)="onSaveHypothesis($event)" submitButtonText="Add Hypothesis"> </hypothesis-form>
    </section>
  </section>
} @else {
  @if (hypothesis.editing) {
    <!-- Edit Hypothesis Form -->
    <section class="hypothesis-container">
      <section class="hypothesis-content" *ngIf="!hypothesis.saving; else cardLoading">
        @if (!grooming) {
          <h6>Edit hypothesis</h6>
        }
        <hypothesis-form
          [hypothesis]="hypothesis"
          [submitButtonText]="'Save'"
          (hypothesisCancel)="onCancelEditHypothesis()"
          (hypothesisDelete)="deleteHypothesis.emit()"
          (hypothesisSubmit)="onEditHypothesis()">
        </hypothesis-form>
      </section>
    </section>
  } @else {
    <section class="hypothesis-card card-with-async">
      <section
        class="hypothesis-container"
        [ngClass]="{
          ready: hypothesis.status === validationStatusCompleted && !hypothesisPassesGrooming,
          grooming: grooming && !hypothesisPassesGrooming,
          temp: !hypothesis.id,
        }">
        @if (grooming) {
          <section class="hypothesis-status">
            <ui-checkbox [uiChecked]="hypothesisPassesGrooming" (uiCheckedChange)="hypothesisCheckboxChange.emit()" />
          </section>
        }

        <section class="hypothesis-content" [ngClass]="{ grooming: grooming }">
          <h6>
            {{ hypothesis.title }}
          </h6>
          <span class="sub-heading" *ngIf="!grooming">{{ hypothesis.description }}</span>
        </section>
        <section class="hypothesis-actions">
          @if (!lockedBy) {
            @if (!areaBeingModifiedId && !grooming) {
              <button class="hypothesis-actions-button" (click)="editHypothesisDuringGrooming($event)" type="button" ui-button>
                <i class="fs-12px" ui-icon uiTheme="outline" uiType="edit"></i>
              </button>
              <button class="hypothesis-actions-button" type="button" ui-button>
                <i class="fs-12px" ui-icon uiTheme="outline" uiType="chevron-right-small"></i>
              </button>
            }

            @if (grooming) {
              <button class="hypothesis-actions-button" (click)="editHypothesisDuringGrooming($event)" type="button" ui-button>
                <i class="fs-12px" ui-icon uiTheme="outline" uiType="edit"></i>
              </button>
            }
          } @else {
            <ui-assignee-avatar [hasHighlight]="lockedBy === currentUserId" [uiAssignee]="assignee" [uiShowTooltip]="true" [uiTooltipTitle]="assigneeTooltip" uiSize="sm"></ui-assignee-avatar>
          }
        </section>
      </section>
      <section class="hypothesis-async-status">
        <async-status [asyncPreviewState]="lockedBy ? hypothesisLoadingState : hypothesisTasksState" [imageOnly]="false"></async-status>
      </section>
    </section>
  }
}

<ng-template #cardLoading>
  <section class="hypothesis-content">
    <ui-skeleton-line [style]="{ height: '20px', width: '100%' }" [uiActive]="true" />
    <ui-skeleton-line
      [style]="{
        height: '16px',
        width: '40%',
      }"
      [uiActive]="true" />
  </section>
</ng-template>
