import dayjs from "@webapp/shared/libs/dayjs";
import { PREDEFINED_FRAMEWORKS_PROMPTS_MAP } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/ai-chat.constants";
import { ContextVM } from "../../models/context/context.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { AsyncOperationPreviewState, StrategyContextDocument, StrategyReportFollowupActionDefinition } from "../../models/strategy.vm-models";

export const getFollowupQuestion = (followupAction: StrategyReportFollowupActionDefinition): string | null => {
  const QUESTION_TEMPLATE_MAP = {
    elaborate: `Elaborate on the topic: "${followupAction.question}"`,
    swot_opportunities_ansoff: `Generate a new Ansoff matrix based on these opportunities:\n${followupAction.question}`,
    swot_opportunities_pest: `Generate a new PEST analysis based on these opportunities:\n${followupAction.question}`,
    swot_threats_porter: `Generate a new Porter's five forces analysis based on these threats:\n${followupAction.question}`,
    swot_threats_pest: `Generate a new PEST analysis based on these threats:\n${followupAction.question}`,
    research: `Research on the topic: "${followupAction.question}"`,
  };

  return QUESTION_TEMPLATE_MAP[followupAction.type] || followupAction.question;
};

export const swapResultIndexes = (conversation: StrategyConversationVM, idx1: number, idx2: number): StrategyConversationVM => {
  const newResults = [...conversation.results];
  const temp = newResults[idx1];
  newResults[idx1] = newResults[idx2];
  newResults[idx2] = temp;
  return {
    ...conversation,
    results: newResults,
  };
};

export const getTaskPreviewStatePeriodString = (date: string): string => {
  const now = dayjs.utc();
  const fileDate = dayjs.utc(date);
  const diffInMinutes = now.diff(fileDate, "minute");
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInMinutes <= 1) {
    return "a minute ago";
  } else if (diffInMinutes > 1 && diffInMinutes <= 59) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInHours >= 1 && diffInHours < 2) {
    return "an hour ago";
  } else if (diffInHours >= 2 && diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else if (diffInDays >= 1 && diffInDays < 2) {
    return "a day ago";
  }
  return fileDate.local().format("l");
};

export const getPreviewStateForDocument = (document: StrategyContextDocument, context: ContextVM): AsyncOperationPreviewState => {
  let asyncState: AsyncOperationPreviewState = { seen: true };
  if (document.state === "SUMMARIZING" || document.state === "UPLOADING" || document.deletionState === "DELETING") {
    // we're currently processing the document, so it's in progress
    asyncState = {
      seen: false,
      state: "LOADING",
      tooltip: "File is in progress...",
    };
  } else if (!context.dateSeen || dayjs.utc(context.dateSeen) < dayjs.utc(document.lastRelatedTaskDoneDate)) {
    // one of these is true:
    // 1. either the context was never seen or
    // 2. the document was processed after the context was last seen
    if (document.state === "FAILED_SUMMARIZATION" || document.state === "FAILED_UPLOAD" || document.deletionState === "FAILED_DELETION") {
      const periodDescription = getTaskPreviewStatePeriodString(document.lastRelatedTaskDoneDate);
      asyncState = {
        seen: false,
        state: "FAILED",
        tooltip: `Unable to process file ${periodDescription}`,
      };
    }
    if (document.state === "SUMMARIZED" && document.deletionState === null) {
      const periodDescription = getTaskPreviewStatePeriodString(document.lastRelatedTaskDoneDate);
      asyncState = {
        seen: false,
        state: "LOADED",
        tooltip: `File was ready ${periodDescription}`,
      };
    }
  }

  return asyncState;
};

export function getUnknownFrameworkLabel(framework: string): string {
  const existingFramework = PREDEFINED_FRAMEWORKS_PROMPTS_MAP[framework];

  if (existingFramework) {
    return existingFramework.label;
  }

  const sentenceCase = framework
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return sentenceCase.endsWith(" Analysis") ? sentenceCase : `${sentenceCase} Analysis`;
}
