export function mapToBoolean(value: string): boolean {
  return value == "true";
}

export function mapToString(value: string): string {
  return value;
}

export function mapToNumber(value: string): number {
  return Number(value);
}
