import { Language, getLanguageFromLocalStorage, getMethodologyFromLocalStorage } from "@gtmhub/localization";
import { locale } from "@gtmhub/localization/locale/en-us";
import { LocaleBundle } from "../models/localization.models";

export const resolveLocales = (): [string, () => Promise<LocaleBundle>] => {
  const language = getLanguageFromLocalStorage();
  const methodology = getMethodologyFromLocalStorage();
  switch (methodology) {
    case "generic":
      return resolveLocalesGeneric(language);
    case "outcome-and-measure":
      return resolveLocalesOutcomeAndMeasure(language);
    default:
      return resolveLocalesDefault(language);
  }
};

const resolveLocalesDefault = (language: Language): [string, () => Promise<LocaleBundle>] => {
  switch (language) {
    case "french":
      return ["fr", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/fr" */ "@gtmhub/localization/locale/fr").then(({ locale }) => locale)];

    case "portuguese":
      return ["pt", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/pt" */ "@gtmhub/localization/locale/pt").then(({ locale }) => locale)];
    case "spanish":
      return ["es", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/es" */ "@gtmhub/localization/locale/es").then(({ locale }) => locale)];
    case "german":
      return ["de", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/de" */ "@gtmhub/localization/locale/de").then(({ locale }) => locale)];
    case "italian":
      return ["it", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/it" */ "@gtmhub/localization/locale/it").then(({ locale }) => locale)];
    case "chinese":
      return ["zh-cn", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/zh-cn" */ "@gtmhub/localization/locale/zh-cn").then(({ locale }) => locale)];
    case "bulgarian":
      return ["bg", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/bg" */ "@gtmhub/localization/locale/bg").then(({ locale }) => locale)];
    case "arabic":
      return ["ar", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/ar" */ "@gtmhub/localization/locale/ar").then(({ locale }) => locale)];
    case "english":
      break;
    default:
      console.warn(`Unknown language: '${language}'. Defaulting to English.`);
  }
  return ["en", (): Promise<LocaleBundle> => Promise.resolve(locale)];
};

const resolveLocalesGeneric = (language: Language): [string, () => Promise<LocaleBundle>] => {
  switch (language) {
    case "french":
      return ["fr", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-fr" */ "@gtmhub/localization/locale/gen-fr").then(({ locale }) => locale)];
    case "portuguese":
      return ["pt", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-pt" */ "@gtmhub/localization/locale/gen-pt").then(({ locale }) => locale)];
    case "spanish":
      return ["es", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-es" */ "@gtmhub/localization/locale/gen-es").then(({ locale }) => locale)];
    case "german":
      return ["de", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-de" */ "@gtmhub/localization/locale/gen-de").then(({ locale }) => locale)];
    case "italian":
      return ["it", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-it" */ "@gtmhub/localization/locale/gen-it").then(({ locale }) => locale)];
    case "chinese":
      return [
        "zh-cn",
        (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-zh-cn" */ "@gtmhub/localization/locale/gen-zh-cn").then(({ locale }) => locale),
      ];
    case "bulgarian":
      return ["bg", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-bg" */ "@gtmhub/localization/locale/gen-bg").then(({ locale }) => locale)];
    case "arabic":
      return ["ar", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-ar" */ "@gtmhub/localization/locale/gen-ar").then(({ locale }) => locale)];
    case "english":
      return ["en", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-en-us" */ "@gtmhub/localization/locale/gen-en-us").then(({ locale }) => locale)];
    default:
      console.warn(`Unknown language: '${language}'. Defaulting to English.`);
      return ["en", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/gen-en-us" */ "@gtmhub/localization/locale/gen-en-us").then(({ locale }) => locale)];
  }
};

const resolveLocalesOutcomeAndMeasure = (language: Language): [string, () => Promise<LocaleBundle>] => {
  switch (language) {
    case "french":
      return ["fr", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-fr" */ "@gtmhub/localization/locale/oam-fr").then(({ locale }) => locale)];
    case "portuguese":
      return ["pt", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-pt" */ "@gtmhub/localization/locale/oam-pt").then(({ locale }) => locale)];
    case "spanish":
      return ["es", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-es" */ "@gtmhub/localization/locale/oam-es").then(({ locale }) => locale)];
    case "german":
      return ["de", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-de" */ "@gtmhub/localization/locale/oam-de").then(({ locale }) => locale)];
    case "italian":
      return ["it", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-it" */ "@gtmhub/localization/locale/oam-it").then(({ locale }) => locale)];
    case "chinese":
      return [
        "zh-cn",
        (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-zh-cn" */ "@gtmhub/localization/locale/oam-zh-cn").then(({ locale }) => locale),
      ];
    case "bulgarian":
      return ["bg", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-bg" */ "@gtmhub/localization/locale/oam-bg").then(({ locale }) => locale)];
    case "arabic":
      return ["ar", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-ar" */ "@gtmhub/localization/locale/oam-ar").then(({ locale }) => locale)];
    case "english":
      return ["en", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-en-us" */ "@gtmhub/localization/locale/oam-en-us").then(({ locale }) => locale)];
    default:
      console.warn(`Unknown language: '${language}'. Defaulting to English.`);
      return ["en", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/oam-en-us" */ "@gtmhub/localization/locale/oam-en-us").then(({ locale }) => locale)];
  }
};
