import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { getCurrentUserId } from "@gtmhub/users";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { SuggestedGoalsProvidersModule } from "@webapp/goal-suggestions/goal-suggestions.providers.module";
import { SuggestedGoal } from "@webapp/goal-suggestions/models/suggested-goals.models";
import { SuggestedGoalsRepository } from "@webapp/goal-suggestions/services/suggested-goals-repository.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { groupBy } from "@webapp/shared/utils/array";
import { StrategyAiCardLoadingListComponent } from "@webapp/strategy/components/shared/strategy-ai-card-loading-list/strategy-ai-card-loading-list.component";
import { StrategyAiCardComponent } from "@webapp/strategy/components/shared/strategy-ai-card/strategy-ai-card.component";
import { StrategyAiDropdownComponent } from "@webapp/strategy/components/shared/strategy-ai-dropdown/strategy-ai-dropdown.component";
import { StrategyAiHeaderComponent } from "@webapp/strategy/components/shared/strategy-ai-header/strategy-ai-header.component";
import { StrategyAiListComponent } from "@webapp/strategy/components/shared/strategy-ai-list/strategy-ai-list.component";
import { SuggestGoalsGroup } from "@webapp/strategy/components/shared/strategy-ai-list/strategy-ai-list.vm-models";
import { fromSuggestedGoalsToStrategyAiList } from "@webapp/strategy/components/shared/strategy-ai-list/transformers";
import { getStrategyAiGroup } from "@webapp/strategy/components/shared/strategy-ai-list/utils";
import { TopNavBarButtonsConfig } from "@webapp/top-nav-bar/models/top-nav-bar-buttons.models";
import { TopNavBarModule } from "@webapp/top-nav-bar/top-nav-bar.module";
import { UiButtonBuilder } from "@webapp/top-nav-bar/utils/ui-button.builder";

export const DUMMY_GOAL_SUGGESTION_ID = "new";

@UntilDestroy()
@Component({
  selector: "goal-suggestions",
  templateUrl: "./goal-suggestions.component.html",
  styleUrls: ["./goal-suggestions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TopNavBarModule,
    LocalizationModule,
    SuggestedGoalsProvidersModule,
    StrategyAiHeaderComponent,
    StrategyAiListComponent,
    StrategyAiDropdownComponent,
    StrategyAiCardComponent,
    StrategyAiCardLoadingListComponent,
    UiIconModule,
  ],
})
export class GoalSuggestionsComponent implements OnInit {
  public topNavBarButtonsConfig: TopNavBarButtonsConfig;
  public groupedSuggestedGoals: SuggestGoalsGroup[];
  public currentUserId = getCurrentUserId();
  public isLoading = false;

  constructor(
    private suggestedGoalsRepository: SuggestedGoalsRepository,
    private cdr: ChangeDetectorRef,
    private state: StateService
  ) {}

  public ngOnInit(): void {
    this.setTopNavBarButtonsConfig();

    this.isLoading = true;
    this.cdr.markForCheck();

    this.suggestedGoalsRepository
      .getSuggestedGoals$()
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(({ items }) => {
        const sortedSuggestedGoals = items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        const groupedSuggestedGoalsRecord = groupBy(sortedSuggestedGoals, (i, decision) => getStrategyAiGroup(decision.createdAt));
        this.groupedSuggestedGoals = fromSuggestedGoalsToStrategyAiList(groupedSuggestedGoalsRecord);

        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  public onOpenSuggestedGoal(suggestedGoal: SuggestedGoal): void {
    if (suggestedGoal.state === "whiteboardGenerated" && suggestedGoal.whiteboardId) {
      this.state.go("gtmhub.whiteboard", { whiteboardId: suggestedGoal.whiteboardId });
    } else {
      this.state.go("gtmhub.goalSuggestion", { goalSuggestionId: suggestedGoal.id });
    }
  }

  public onDeleteSuggestedGoal(suggestedGoalId: string): void {
    this.suggestedGoalsRepository
      .deleteSuggestedGoal$(suggestedGoalId)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.groupedSuggestedGoals = this.groupedSuggestedGoals.map((group) => ({
          ...group,
          children: group.children.filter((item) => item.id !== suggestedGoalId),
        }));

        this.cdr.markForCheck();
      });
  }

  public onSuggestOKRs(): void {
    this.state.go("gtmhub.goalSuggestion", { goalSuggestionId: DUMMY_GOAL_SUGGESTION_ID });
  }

  private setTopNavBarButtonsConfig(): void {
    const uiButtonBuilder = new UiButtonBuilder();

    const suggestGoalsButton = uiButtonBuilder
      .setKey("suggest_okrs")
      .setKeyVisibilityMode("always")
      .setType({
        uiType: "buttonOnly",
        buttonType: "primary",
        buttonShape: "round",
        buttonSize: "default",
      })
      .setAction({ handler: () => this.onSuggestOKRs() })
      .build();

    this.topNavBarButtonsConfig = {
      buttonsConfig: {
        uiButtons: [suggestGoalsButton],
      },
    };
  }
}
