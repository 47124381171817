import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { ConversationChatEntryVM } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";

@Component({
  selector: "ui-chat-block-actions",
  templateUrl: "./chat-block-actions.component.html",
  styleUrls: ["./chat-block-actions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBlockActionsComponent {
  @Input() public chatEntry: ConversationChatEntryVM | null;
  @Input() public showRegenerate = false;
  @Input() public feedbackScore: "good" | "bad" | null | undefined;
  @Output() public readonly addContent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly refreshContent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly provideFeedback: EventEmitter<"positive" | "negative"> = new EventEmitter<"positive" | "negative">();

  constructor(private cdr: ChangeDetectorRef) {}

  public hideAlertError(): void {
    this.chatEntry.insertingError = false;
    this.cdr.markForCheck();
  }
}
