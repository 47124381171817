import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UiAvatarModule } from "@quantive/ui-kit/avatar";
import { take } from "rxjs/operators";
import { storage } from "@gtmhub/core/storage";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { BillingInfoService } from "@webapp/navigation/services/billing-info.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { UserDTO } from "@webapp/strategy/models/users.models";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UsersFacade } from "@webapp/users/services/users-facade.service";
import { ActionsPanelComponent } from "../actions-panel/actions-panel.component";

@UntilDestroy()
@Component({
  selector: "welcome-panel",
  standalone: true,
  templateUrl: "./welcome-panel.component.html",
  styleUrls: ["welcome-panel.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiAvatarModule, ActionsPanelComponent],
})
export class WelcomePanelComponent extends BaseWidgetComponent implements OnInit {
  public get title(): string {
    return "Get started with Quantive";
  }

  public get a11yLabel(): string {
    return "Get started with Quantive";
  }

  public timeLeftInDays: number;
  public isTrialAccount: boolean;
  public canManageBilling = false;
  public currentUser: Pick<UserDTO, "id" | "firstName" | "lastName" | "picture" | "language">;

  constructor(
    private usersFacade: UsersFacade,
    private cdr: ChangeDetectorRef,
    private billingInfoService: BillingInfoService,
    private permissionsFacade: PermissionsFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    this.fetchCurrentUserData();
    this.billingInfoService
      .checkExpirationOfTrialAccount$()
      .pipe(take(1))
      .subscribe((result) => {
        this.timeLeftInDays = Math.floor(result.secondsLeft / 60 / 60 / 24);
        this.isTrialAccount = result.isTrialAccount;
      });
    this.permissionsFacade
      .hasPermission$("ManageBilling")
      .pipe(take(1))
      .subscribe({
        next: (hasPermissions: boolean) => {
          this.canManageBilling = hasPermissions;
          this.cdr.markForCheck();
        },
      });
  }

  private fetchCurrentUserData(): void {
    this.usersFacade
      .getUsersV2$({
        filter: { _id: storage.get("userId") },
        fields: ["firstName", "lastName", "picture", "language"],
      })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (currentUserData) => {
          this.currentUser = currentUserData.items[0];
          this.cdr.detectChanges();
        },
      });
  }
}
