import {
  BlockContent,
  ChatAnsoffDataVM,
  ChatBalancedScoreCardDataVM,
  ChatBlueOceanDataVM,
  ChatGoalsDataVM,
  ChatKPIsDataVM,
  ChatPestDataVM,
  ChatPorterForcesDataVM,
  ConversationChatEntryVM,
  ConversationVM,
  OrderedResultEntryVM,
  ResultBlockType,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import {
  AnsoffDataVM,
  BalancedScoreCardDataVM,
  BlueOceanDataVM,
  GoalsDataVM,
  KPIsDataVM,
  LoadingBlockVM,
  OrderedStrategyResultEntryVM,
  PestDataVM,
  PorterForcesDataVM,
  StrategyResultBlockContentVM,
  StrategyResultBlockTypeVM,
  SwotDataVM,
  TextStrategyResultVM,
} from "../../models/bets/strategic-bets.vm-models";
import { StrategyConversationChatEntryVM } from "../../models/chat/strategy-conversation-chat.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { REPORT_DUMMY_BLOCK_ID } from "../../models/strategy.constants";

export const conversationVM2AiVM = (strategyConversation: StrategyConversationVM | null): ConversationVM => {
  if (strategyConversation === null) {
    return null;
  }
  return {
    id: strategyConversation.id,
    accountId: strategyConversation.accountId,
    reportId: strategyConversation.reportId,
    currentlyAnsweringQuestion: strategyConversation.currentlyAnsweringQuestion,
    chat: strategyConversation.chat.map((chatEntry) => chatEntryVM2AiVM(chatEntry)),
    results: strategyConversation.results.map((resultBlock) => blockVM2AiVM(resultBlock)),
    suggestedFrameworks: strategyConversation.suggestedFrameworks,
  };
};

export const chatEntryAiVM2VM = (chatEntry: ConversationChatEntryVM & { shapeId?: string }): StrategyConversationChatEntryVM => {
  return {
    id: chatEntry.id,
    type: chatEntry.type,
    blockType: chatEntry.blockType as unknown as StrategyResultBlockTypeVM,
    blockContent: blockContentAiVM2VM(chatEntry.blockType, chatEntry.blockContent),
    feedback: chatEntry.feedback,
    ...(chatEntry.followupActionDetails && {
      followupActionDetails: {
        type: chatEntry.followupActionDetails.type,
        isSuggestion: chatEntry.followupActionDetails.isSuggestion,
      },
    }),
    shapeId: chatEntry.shapeId,
  };
};

const blockContentAiVM2VM = (blockType: ResultBlockType | "suggested_frameworks", blockContent: BlockContent): StrategyResultBlockContentVM => {
  switch (blockType) {
    case ResultBlockType.Text:
      return {
        text: (blockContent as TextStrategyResultVM).text,
        references: (blockContent as TextStrategyResultVM).references,
      };
    case ResultBlockType.Ansoff:
      return {
        diversification: (blockContent as ChatAnsoffDataVM).diversification,
        marketDevelopment: (blockContent as ChatAnsoffDataVM).marketDevelopment,
        marketPenetration: (blockContent as ChatAnsoffDataVM).marketPenetration,
        productDevelopment: (blockContent as ChatAnsoffDataVM).productDevelopment,
      };
    case ResultBlockType.PEST:
      return {
        politicalFactors: (blockContent as ChatPestDataVM).politicalFactors,
        economicFactors: (blockContent as ChatPestDataVM).economicFactors,
        socioculturalFactors: (blockContent as ChatPestDataVM).socioculturalFactors,
        technologicalFactors: (blockContent as ChatPestDataVM).technologicalFactors,
        environmentalFactors: (blockContent as ChatPestDataVM).environmentalFactors,
        legalFactors: (blockContent as ChatPestDataVM).legalFactors,
      };
    case ResultBlockType.Porter:
      return {
        newEntrantThreats: (blockContent as ChatPorterForcesDataVM).newEntrantThreats,
        supplierBargainingPowers: (blockContent as ChatPorterForcesDataVM).supplierBargainingPowers,
        buyersBargainingPowers: (blockContent as ChatPorterForcesDataVM).buyersBargainingPowers,
        substituteThreats: (blockContent as ChatPorterForcesDataVM).substituteThreats,
        competitionRivalry: (blockContent as ChatPorterForcesDataVM).competitionRivalry,
        conclusion: (blockContent as ChatPorterForcesDataVM).conclusion,
      };
    case ResultBlockType.BalancedScorecard:
      return {
        alignmentAndSynergies: (blockContent as ChatBalancedScoreCardDataVM).alignmentAndSynergies,
        customerPerspectives: (blockContent as ChatBalancedScoreCardDataVM).customerPerspectives,
        financialPerspectives: (blockContent as ChatBalancedScoreCardDataVM).financialPerspectives,
        internalProcessPerspectives: (blockContent as ChatBalancedScoreCardDataVM).internalProcessPerspectives,
        learningGrowthPerspectives: (blockContent as ChatBalancedScoreCardDataVM).learningGrowthPerspectives,
        riskAndAdaptations: (blockContent as ChatBalancedScoreCardDataVM).riskAndAdaptations,
      };
    case ResultBlockType.BlueOcean:
      return {
        errcGridAnalysis: (blockContent as ChatBlueOceanDataVM).errcGridAnalysis,
        fourActionsFramework: (blockContent as ChatBlueOceanDataVM).fourActionsFramework,
        sixPathsFramework: (blockContent as ChatBlueOceanDataVM).sixPathsFramework,
        leadershipAndPeopleDevelopment: (blockContent as ChatBlueOceanDataVM).leadershipAndPeopleDevelopment,
        marketBoundaryReconstruction: (blockContent as ChatBlueOceanDataVM).marketBoundaryReconstruction,
        strategicSequence: (blockContent as ChatBlueOceanDataVM).strategicSequence,
        strategyCanvas: (blockContent as ChatBlueOceanDataVM).strategyCanvas,
        threeNoncustomerTiers: (blockContent as ChatBlueOceanDataVM).threeNoncustomerTiers,
        valueInnovation: (blockContent as ChatBlueOceanDataVM).valueInnovation,
      };
    case ResultBlockType.Goals:
      return {
        goals: (blockContent as ChatGoalsDataVM).goals,
      };
    case ResultBlockType.KPIs:
      return {
        kpis: (blockContent as ChatKPIsDataVM).kpis,
      };
    case ResultBlockType.SWOT:
      return blockContent as SwotDataVM;
  }
};

const chatEntryVM2AiVM = (chatEntry: StrategyConversationChatEntryVM): ConversationChatEntryVM => {
  return {
    id: chatEntry.id,
    type: chatEntry.type,
    blockType: chatEntry.blockType as unknown as ResultBlockType,
    blockContent: blockContentVM2AiVM(chatEntry.blockType, chatEntry.blockContent),
    feedback: chatEntry.feedback,
    ...(chatEntry.followupActionDetails && {
      followupActionDetails: {
        type: chatEntry.followupActionDetails.type,
        isSuggestion: chatEntry.followupActionDetails.isSuggestion,
      },
    }),
    startedAt: chatEntry.startedAt,
    createdBy: chatEntry.createdBy,
  };
};

const blockVM2AiVM = (resultBlock: OrderedStrategyResultEntryVM): OrderedResultEntryVM => {
  return {
    id: resultBlock.id,
    blockType: resultBlock.blockType as unknown as ResultBlockType,
    blockContent: blockContentVM2AiVM(resultBlock.blockType, resultBlock.blockContent),
    isCreatedInThisSession: resultBlock.isCreatedInThisSession,
    shapeId: resultBlock.shapeId,
  };
};

const blockContentVM2AiVM = (blockType: StrategyResultBlockTypeVM, blockContent: StrategyResultBlockContentVM | "suggested_frameworks"): BlockContent => {
  switch (blockType) {
    case StrategyResultBlockTypeVM.Text:
      return {
        text: (blockContent as TextStrategyResultVM).text,
        references: (blockContent as TextStrategyResultVM).references,
      };
    case StrategyResultBlockTypeVM.Ansoff:
      return {
        diversification: (blockContent as AnsoffDataVM).diversification,
        marketDevelopment: (blockContent as AnsoffDataVM).marketDevelopment,
        marketPenetration: (blockContent as AnsoffDataVM).marketPenetration,
        productDevelopment: (blockContent as AnsoffDataVM).productDevelopment,
      };
    case StrategyResultBlockTypeVM.PEST:
      return {
        politicalFactors: (blockContent as PestDataVM).politicalFactors,
        economicFactors: (blockContent as PestDataVM).economicFactors,
        socioculturalFactors: (blockContent as PestDataVM).socioculturalFactors,
        technologicalFactors: (blockContent as PestDataVM).technologicalFactors,
        environmentalFactors: (blockContent as PestDataVM).environmentalFactors,
        legalFactors: (blockContent as PestDataVM).legalFactors,
      };
    case StrategyResultBlockTypeVM.Porter:
      return {
        newEntrantThreats: (blockContent as PorterForcesDataVM).newEntrantThreats,
        supplierBargainingPowers: (blockContent as PorterForcesDataVM).supplierBargainingPowers,
        buyersBargainingPowers: (blockContent as PorterForcesDataVM).buyersBargainingPowers,
        substituteThreats: (blockContent as PorterForcesDataVM).substituteThreats,
        competitionRivalry: (blockContent as PorterForcesDataVM).competitionRivalry,
        conclusion: (blockContent as PorterForcesDataVM).conclusion,
      };
    case StrategyResultBlockTypeVM.BalancedScorecard:
      return {
        alignmentAndSynergies: (blockContent as BalancedScoreCardDataVM).alignmentAndSynergies,
        customerPerspectives: (blockContent as BalancedScoreCardDataVM).customerPerspectives,
        financialPerspectives: (blockContent as BalancedScoreCardDataVM).financialPerspectives,
        internalProcessPerspectives: (blockContent as BalancedScoreCardDataVM).internalProcessPerspectives,
        learningGrowthPerspectives: (blockContent as BalancedScoreCardDataVM).learningGrowthPerspectives,
        riskAndAdaptations: (blockContent as BalancedScoreCardDataVM).riskAndAdaptations,
      };
    case StrategyResultBlockTypeVM.BlueOcean:
      return {
        errcGridAnalysis: (blockContent as BlueOceanDataVM).errcGridAnalysis,
        fourActionsFramework: (blockContent as BlueOceanDataVM).fourActionsFramework,
        sixPathsFramework: (blockContent as BlueOceanDataVM).sixPathsFramework,
        leadershipAndPeopleDevelopment: (blockContent as BlueOceanDataVM).leadershipAndPeopleDevelopment,
        marketBoundaryReconstruction: (blockContent as BlueOceanDataVM).marketBoundaryReconstruction,
        strategicSequence: (blockContent as BlueOceanDataVM).strategicSequence,
        strategyCanvas: (blockContent as BlueOceanDataVM).strategyCanvas,
        threeNoncustomerTiers: (blockContent as BlueOceanDataVM).threeNoncustomerTiers,
        valueInnovation: (blockContent as BlueOceanDataVM).valueInnovation,
      };
    case StrategyResultBlockTypeVM.SWOT:
      return {
        strengths: (blockContent as SwotDataVM).strengths,
        weaknesses: (blockContent as SwotDataVM).weaknesses,
        opportunity: (blockContent as SwotDataVM).opportunity,
        threats: (blockContent as SwotDataVM).threats,
      };
    case StrategyResultBlockTypeVM.Goals:
      return {
        goals: (blockContent as GoalsDataVM).goals,
      };
    case StrategyResultBlockTypeVM.KPIs:
      return {
        kpis: (blockContent as KPIsDataVM).kpis,
      };
    case StrategyResultBlockTypeVM.Loading:
      return {
        framework: (blockContent as LoadingBlockVM).type,
        isSuggested: (blockContent as LoadingBlockVM).isSuggested,
      };
  }
};

export const buildDummyConversationResultBlockVM = (): OrderedStrategyResultEntryVM => {
  return {
    id: REPORT_DUMMY_BLOCK_ID,
    blockType: StrategyResultBlockTypeVM.Text,
    blockContent: {
      text: "",
      references: [],
    },
    isCreatedInThisSession: false,
  };
};
