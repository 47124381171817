import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  ChatPestDataVM,
  StructuredChatBlockDisplayData,
  StructuredChatDataSection,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";

@Component({
  selector: "ui-ai-chat-pest-block",
  templateUrl: "./ai-chat-pest-block.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatPestBlockComponent implements OnInit {
  public pestSections: StructuredChatBlockDisplayData | undefined;
  @Input() public pest: ChatPestDataVM | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const politicalSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.pest?.politicalFactors, "Political");
    const economicSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.pest?.economicFactors, "Economic");
    const socialSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.pest?.socioculturalFactors, "Social");
    const technologicalSection: StructuredChatDataSection = this.transformSwotAspectToSection(this.pest?.technologicalFactors, "Technological");
    this.pestSections = [politicalSection, economicSection, socialSection, technologicalSection];

    this.cdr.markForCheck();
  }

  private transformSwotAspectToSection(swotAspect: StructuredDataAspectVM[] | undefined, title: string): StructuredChatDataSection {
    return {
      title,
      entries: swotAspect || [],
    };
  }
}
