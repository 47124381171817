import { Injectable } from "@angular/core";
import { BaseUrlBuilder } from "@webapp/core/http/services/base-url-builder";

@Injectable()
export class DocumentExporterUrlBuilderV3Service extends BaseUrlBuilder {
  private exporterBaseUrl = "exporter";

  private getExporterUrl(): string {
    return this.getApiEndpointV3(this.exporterBaseUrl);
  }

  public getPDFUrl(): string {
    return `${this.getExporterUrl()}/pdf`;
  }
}
