<generic-results-box
  class="result-box"
  [data]="blueOcean.valueInnovation"
  (contentChanged)="changeContent($event, 'valueInnovation')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Value Innovation"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.errcGridAnalysis"
  (contentChanged)="changeContent($event, 'errcGridAnalysis')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="ERRC Grid Analysis"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.sixPathsFramework"
  (contentChanged)="changeContent($event, 'sixPathsFramework')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Six Paths Framework"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.fourActionsFramework"
  (contentChanged)="changeContent($event, 'fourActionsFramework')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Four Actions Framework"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.strategyCanvas"
  (contentChanged)="changeContent($event, 'strategyCanvas')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Strategy Canvas"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.threeNoncustomerTiers"
  (contentChanged)="changeContent($event, 'threeNoncustomerTiers')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Three Tiers of Noncustomers"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.marketBoundaryReconstruction"
  (contentChanged)="changeContent($event, 'marketBoundaryReconstruction')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Reconstruction of Market Boundaries"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.strategicSequence"
  (contentChanged)="changeContent($event, 'strategicSequence')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Strategic Sequence"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="blueOcean.leadershipAndPeopleDevelopment"
  (contentChanged)="changeContent($event, 'leadershipAndPeopleDevelopment')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Leadership and People Development"></generic-results-box>
