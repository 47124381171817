import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { StrategyConfigService } from "../../services/config/strategy-config.service";

@UntilDestroy()
@Component({
  selector: "results-header",
  templateUrl: "./results-header.component.html",
  styleUrls: ["./results-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [UiIconModule, UiButtonComponent, UiTooltipModule, DropdownModule],
})
export class ResultsHeaderComponent implements OnInit {
  @Input() public verified: boolean;
  @Input() public hypothesisTitle: string;
  @Input() public hypothesisId: string;
  @Input() public areaTitle: string;

  @Output() public readonly toggleChat: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly showCollectiveContext: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly deleteHypothesis: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly closePage: EventEmitter<void> = new EventEmitter<void>();

  public conversationDropdownItems: DropdownMenuItem[];
  public showTextReferences: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private strategyConfigService: StrategyConfigService
  ) {}

  public ngOnInit(): void {
    this.conversationDropdownItems = this.getBoardNodeDataDropdownItems();
    this.strategyConfigService
      .getConfig$()
      .pipe(untilDestroyed(this))
      .subscribe((config) => {
        this.showTextReferences = config.showReferences;
        this.conversationDropdownItems = this.getBoardNodeDataDropdownItems();
      });
    this.cdr.detectChanges();
  }

  private getBoardNodeDataDropdownItems(): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const menuItems = [
      menuItemBuilder
        .setKey(this.showTextReferences ? "Hide References" : "Show References")
        .setUiType({
          iconTheme: "fill",
          uiType: "uiIcon",
          iconType: "",
        })
        .setToBeEndOfSection()
        .setAction({
          handler: () => (this.showTextReferences ? this.strategyConfigService.hideReferences() : this.strategyConfigService.showReferences()),
        })
        .build(),
    ];

    if (this.hypothesisId) {
      menuItems.push(
        menuItemBuilder
          .setKey("Delete hypothesis")
          .setUiType({
            iconTheme: "outline",
            uiType: "uiIcon",
            iconType: "trash-bin",
          })
          .setAction({
            handler: () => {
              this.deleteHypothesis.emit();
            },
          })
          .setToBeDestructive()
          .build()
      );
    }

    return menuItems;
  }
}
