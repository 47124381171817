<form class="account-details-form" [formGroup]="form" (ngSubmit)="onFormSubmit()" ui-form>
  <ui-row *ngIf="formErrors.upperFormError">
    <ui-alert class="sp-mb-24" [uiDescription]="formErrors.upperFormError" uiCloseable uiType="error"></ui-alert>
  </ui-row>
  <ui-form-item ui-row>
    <ui-form-label [uiNoColon]="true">
      <div class="label-content">Company name</div>
    </ui-form-label>
    <ui-form-control>
      <ui-input-text-field
        [debounceTime]="300"
        [required]="true"
        [validationError]="formErrors.inlineCompanyNameError || null"
        [value]="companyName.value"
        (valueChange)="onCompanyNameValueChange($event)"
        uiPlaceholder="Enter your company name"
        updateOn="change"></ui-input-text-field>
    </ui-form-control>
  </ui-form-item>
  <ui-form-item ui-row>
    <ui-form-label [uiNoColon]="true">
      <div class="label-content">Company size</div>
    </ui-form-label>
    <ui-form-control uiValidateStatus="error">
      <ui-select class="full-width" [uiPlaceHolder]="'Select size'" [uiSize]="'large'" formControlName="companySize" uiDropdownClassName="company-size-dropdown-select">
        <ui-option *ngFor="let size of companySizes" [uiLabel]="size" [uiValue]="size"></ui-option>
      </ui-select>
    </ui-form-control>
  </ui-form-item>

  <ui-form-item ui-row>
    <ui-form-label [uiNoColon]="true">
      <div class="label-content">Country</div>
    </ui-form-label>
    <ui-form-control>
      <ui-select class="full-width" [uiPlaceHolder]="'Select company location'" [uiSize]="'large'" formControlName="country" uiDropdownClassName="country-dropdown-select">
        <ui-option *ngFor="let country of countries" [uiLabel]="country" [uiValue]="country"></ui-option>
      </ui-select>
    </ui-form-control>
  </ui-form-item>
  <div class="form-actions">
    <gh-indicator [(ghValue)]="indicators.submittingForm" [ghMessage]="'fetching_data' | localize"></gh-indicator>
    <div class="actions-holder" *ngIf="!indicators.submittingForm">
      <button class="submit-btn sp-mr-10" [disabled]="!form.valid || formErrors.inlineCompanyNameError" type="submit" ui-button uiShape="round" uiSize="large" uiType="primary">
        Let's get started
      </button>
    </div>
  </div>
</form>
