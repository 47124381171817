@if (loading) {
  <ui-loading-indicator class="loading-container" [uiSpinning]="true" uiTip="Preparing your whiteboard and generating a tailored response…" />
} @else {
  <whiteboard-upgrade [class.display-block]="currentActiveTabKey === 'whiteboard_cap'" [isAIWhiteboard]="true" [whiteboardId]="whiteboardId" />

  <!-- toggle with z-index ??? -->

  <decision-top-nav-bar
    [class.display-block]="currentActiveTabKey === 'decision_cap'"
    [documentIds]="decisionDocumentIds"
    [title]="decisionTitle"
    [whiteboardId]="whiteboardId"
    (deleteClicked)="onDeleteClicked()"
    (uploadedDocumentIds)="onUploadedDocumentIds($event)" />
  <decision-view [class.display-flex]="currentActiveTabKey === 'decision_cap'" [conversationId]="conversationId" [usersMap]="usersMap" />
}
