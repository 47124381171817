import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { Intercom } from "@gtmhub/shared/intercom";
import { StrategicMapAreaVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { UiFormControlComponent } from "@webapp/ui/form/components/form-control/form-control.component";
import { UiInputTextAreaComponent } from "@webapp/ui/input/components/input-text-area/input-text-area.component";

@Component({
  selector: "area-form",
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, UiInputTextAreaComponent, UiFormControlComponent, UiButtonComponent, UiIconModule],
  templateUrl: "./area-form.component.html",
  styleUrl: "./area-form.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaFormComponent {
  @Input() public area: StrategicMapAreaVM;
  @Input() public submitButtonText: string;
  @Output() private readonly areaSubmit = new EventEmitter<Event>();
  @Output() private readonly areaCancel = new EventEmitter<Event>();

  public onSubmit(event: Event): void {
    this.areaSubmit.emit(event);
  }

  public onCancel(event: Event): void {
    this.areaCancel.emit(event);
  }

  public showIntercom(): void {
    Intercom("show");
  }
}
