export const storage = {
  set: <T>(key: string, value: T): void => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: <T>(key: string): T | undefined => {
    const saved = localStorage.getItem(key);
    let obj: T | undefined;
    try {
      if (typeof saved === "undefined" || saved === "undefined") {
        obj = undefined;
      } else {
        obj = JSON.parse(saved ?? "") as T | undefined;
      }
    } catch (e) {
      localStorage.removeItem(key);
    }
    return obj;
  },
  remove: (key: string): void => {
    localStorage.removeItem(key);
  },
};
