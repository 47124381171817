import { RestLayerFilter } from "@gtmhub/core";
import { ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { entityTypeFilter } from "@webapp/kpis/components/kpis-grid-page/util";
import { ExtendedArrayGridFilter, ExtendedOrFilterGroups, FilterFieldName } from "@webapp/okrs/okrs-grid-page/models";
import { ShareableFilterEntityType } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { ToCustomFieldRestLayerDynamicValuesTransformer } from "./custom-fields/rest-layer-dynamic-custom-field.transformer";
import { ToRestLayerDynamicValuesTransformer } from "./rest-layer-dynamic-values.transformer";

export type GoalsOrKpisRestLayerFilter = Record<string, RestLayerFilter | RestLayerFilter[]>;

const shouldResolveCustomFieldDynamicValue = (filterKey: FilterFieldName, customFields: ICustomField[]): boolean => {
  if (!customFields || customFields.length === 0) {
    return false;
  }
  return customFields.some((cf) => filterKey.includes(cf.name));
};

function quickSearchToMongoRestLayerFilter(targets: string[], searchTerm: string): RestLayerFilter {
  if (!targets?.length || !searchTerm) {
    return null;
  }

  if (targets.length === 1) {
    return { [targets[0]]: { $regex: searchTerm } };
  }

  return targets.reduce(
    (filters, key) => {
      filters.$or.push({ [key]: { $regex: searchTerm } });
      return filters;
    },
    { $or: [] }
  );
}

const toRestLayerFilter = (filters: ExtendedOrFilterGroups[], customFields: ICustomField[]): RestLayerFilter[] => {
  const restLayerFilters: RestLayerFilter[] = [];
  const filtersWithSelectedValues = filters.filter((filter: ExtendedArrayGridFilter) => filter.value || filter.dynamicValues?.length);

  try {
    for (const filter of filtersWithSelectedValues) {
      const transformer = shouldResolveCustomFieldDynamicValue(filter.fieldName, customFields)
        ? ToCustomFieldRestLayerDynamicValuesTransformer.createTransformer(filter.fieldName, customFields)
        : ToRestLayerDynamicValuesTransformer.createTransformer(filter.fieldName);

      const result = transformer.transform(filter);

      restLayerFilters.push(...result);
    }
  } catch (e) {
    console.error(e);
    console.error("Operator is not available in the list of supported operators, did you forget to add a mapping function?");

    return restLayerFilters;
  }

  return restLayerFilters;
};

type GroupedFilter = { $and: RestLayerFilter[] };
type OrGroupedFilter = { $or: GroupedFilter[] };
export type GroupedRestLayerFilter = GroupedFilter | OrGroupedFilter | Record<string, never>;

export const filterTagFilterByModuleTagsEnabled = (
  groups: ExtendedOrFilterGroups[][],
  { tagsModuleEnabled }: { tagsModuleEnabled: boolean }
): ExtendedOrFilterGroups[][] => {
  return tagsModuleEnabled
    ? groups
    : groups
        .map((group) => group.filter((filter) => filter.fieldName !== "tags.title"))
        // make sure we filter the group, if it has no more filters in it, [ex: group had tag filter only which is now filtered]
        .filter((group) => group.length > 0);
};

export const toMongoRestLayerFilterFromGroups = (groups: ExtendedOrFilterGroups[][], customFields: ICustomField[] = []): GroupedRestLayerFilter => {
  if (groups.length === 0 || groups[0].length === 0) {
    return {};
  }

  if (groups.length === 1) {
    return {
      $and: toRestLayerFilter(groups[0], customFields),
    };
  }

  const query: OrGroupedFilter = { $or: [] };

  for (const group of groups) {
    query.$or.push({
      $and: toRestLayerFilter(group, customFields),
    });
  }

  return query;
};

export function toQuickMongoRestLayerFilterWithQuickSearch(
  groups: ExtendedOrFilterGroups[][],
  customFields?: ICustomField[],
  search?: {
    term: string;
    targets: string[];
  }
): GroupedRestLayerFilter {
  const mongoRestLayerFilter = toMongoRestLayerFilterFromGroups(groups, customFields);
  const quickSearchFilter = quickSearchToMongoRestLayerFilter(search?.targets, search?.term);

  if ("$and" in mongoRestLayerFilter && mongoRestLayerFilter.$and.length === 0 && !quickSearchFilter) {
    return {};
  }

  if ("$or" in mongoRestLayerFilter && mongoRestLayerFilter.$or.length === 0 && !quickSearchFilter) {
    return {};
  }

  if (quickSearchFilter) {
    if ("$and" in mongoRestLayerFilter) {
      mongoRestLayerFilter.$and.push(quickSearchFilter);
    }

    if ("$or" in mongoRestLayerFilter) {
      mongoRestLayerFilter.$or.push({ $and: [quickSearchFilter] });
    }
  }

  return mongoRestLayerFilter;
}

export function toRestLayerFilterWithEntityType(
  groups: ExtendedOrFilterGroups[][],
  entityType: ShareableFilterEntityType,
  customFields?: ICustomField[],
  search?: {
    term: string;
    targets: string[];
  }
): GroupedRestLayerFilter {
  const mongoRestLayerFilter = toQuickMongoRestLayerFilterWithQuickSearch(groups, customFields, search);

  if ("$and" in mongoRestLayerFilter) {
    mongoRestLayerFilter.$and.unshift(entityTypeFilter(entityType));
  }

  return mongoRestLayerFilter;
}

export function sanitizeRestLayerFilter(filter: GoalsOrKpisRestLayerFilter): GoalsOrKpisRestLayerFilter {
  const newFilter: GoalsOrKpisRestLayerFilter = {};

  if ("$and" in filter && Array.isArray(filter.$and) && filter.$and.length) {
    newFilter.$and = filter.$and.map((f) => sanitizeRestLayerFilter(f as GoalsOrKpisRestLayerFilter));
  }

  if ("$or" in filter && Array.isArray(filter.$or) && filter.$or.length) {
    newFilter.$or = filter.$or.map((f) => sanitizeRestLayerFilter(f as GoalsOrKpisRestLayerFilter));
  }

  Object.keys(filter).forEach((key) => {
    if (key === "$and" || key === "$or") {
      return;
    }

    if (typeof filter[key] === "object" && !Array.isArray(filter[key])) {
      newFilter[key] = sanitizeRestLayerFilter(filter[key] as GoalsOrKpisRestLayerFilter);
      return;
    }

    newFilter[key] = filter[key];
  });

  return newFilter;
}
