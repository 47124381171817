import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, filter, map, take, tap } from "rxjs";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import dayjs from "@webapp/shared/libs/dayjs";
import { AsyncResponseDTOv2 } from "../../models/bets/strategic-bets.dto-v2-models";
import { StrategicMapHypothesisVM, StrategicMapVM } from "../../models/bets/strategic-bets.vm-models";
import { AsyncOperationPreviewState } from "../../models/strategy.vm-models";
import { StrategicBetsService } from "../bet/strategic-bets.service";
import { StrategySocketsService } from "../web-sockets/services/strategy-sockets.service";
import { StrategyConversationTasksStateService } from "./strategy-conversation-task-state.service";

@UntilDestroy()
@Injectable()
export class StrategyConversationMediatorService {
  constructor(
    private socketService: StrategySocketsService,
    private conversationTasksStateService: StrategyConversationTasksStateService,
    private strategicBetsService: StrategicBetsService
  ) {
    this.updateStateOnTaskCompletion();
  }

  public loadConversationState(betId: string, strategicMap: StrategicMapVM): void {
    this.conversationTasksStateService.loadTaskState$(betId, strategicMap);
  }

  private updateStateOnTaskCompletion(): void {
    this.socketService
      .onMessage$("askQuestion")
      .pipe(untilDestroyed(this))
      .subscribe(({ data }) => {
        if (data.status === "SUCCESS") {
          this.conversationTasksStateService.setStatusAsLoaded(data.itemId, dayjs().toISOString());
        } else if (data.status === "FAILURE") {
          this.conversationTasksStateService.setStatusAsFailed(data.itemId, dayjs().toISOString());
        }
      });
  }

  public getConversationTaskState$(conversationId: string): Observable<AsyncOperationPreviewState> {
    return this.conversationTasksStateService.conversationTasks$.pipe(map((tasks) => tasks?.[conversationId]));
  }

  public setConversationToLoading$(betId: string, areaId: string, hypothesis: Partial<StrategicMapHypothesisVM>): Observable<AsyncResponseDTOv2> {
    return this.strategicBetsService.updateHypothesisSeen$(betId, areaId, hypothesis.id, { isSeen: false }).pipe(
      takeOneUntilDestroyed(this),
      tap(() => {
        this.conversationTasksStateService.setStatusAsLoading(hypothesis.conversationId);
      })
    );
  }

  public markHypothesisAsSeen(betId: string, areaId: string, hypothesis: Partial<StrategicMapHypothesisVM>): void {
    this.getConversationTaskState$(hypothesis.conversationId)
      .pipe(
        take(1),
        filter((task) => task?.state !== "LOADING")
      )
      .subscribe(() => {
        this.strategicBetsService
          .updateHypothesisSeen$(betId, areaId, hypothesis.id, { isSeen: true })
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.conversationTasksStateService.setTaskSeenStatus(hypothesis.conversationId, { seen: true });
          });
      });
  }
}
