import { Injectable } from "@angular/core";
import { Observable, map, shareReplay, take } from "rxjs";
import { cacheOrGet } from "@webapp/core/state-management/state-management.utils";
import { accountId } from "@webapp/core/storage/services/cache/account-id";
import { AccountDTO } from "../models/account.models";
import { AccountsApiService } from "./accounts-api.service";
import { CurrentAccountCache } from "./current-account-cache";

@Injectable()
export class CurrentAccountDTORepository {
  private apiGetObservable$: Observable<AccountDTO>;

  constructor(
    private api: AccountsApiService,
    private cache: CurrentAccountCache
  ) {}

  public get$(): Observable<AccountDTO> {
    if (!this.apiGetObservable$) {
      const id = accountId.get();

      this.apiGetObservable$ = this.api.getAccount$(id).pipe(shareReplay(1));
    }
    return cacheOrGet(this.cache, this.apiGetObservable$);
  }

  public forceGet$(): Observable<AccountDTO> {
    return (this.apiGetObservable$ = this.api.getAccount$(accountId.get()).pipe(
      take(1),
      map((account) => {
        this.cache.set(account);
        return account;
      })
    ));
  }
}
