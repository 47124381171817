import { Injectable } from "@angular/core";
import { BaseUrlBuilder } from "@webapp/core/http/services/base-url-builder";

@Injectable()
export class DocumentsUrlBuilderV2Service extends BaseUrlBuilder {
  public getDocumentsUrl(): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", "documents");
  }

  public getDocumentUrl(documentId: string): string {
    const documentsUrl = this.getDocumentsUrl();
    return `${documentsUrl}/${documentId}`;
  }
}
