import { Injectable } from "@angular/core";
import { BaseUrlBuilder } from "@webapp/core/http/services/base-url-builder";

@Injectable()
export class StrategicBetsUrlBuilderV2Service extends BaseUrlBuilder {
  public getBetUrl(betId: string): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", `bets/${betId}`);
  }

  public getBetsUrl(): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", `bets`);
  }

  public getAreaUrl(betId: string, areaId: string): string {
    const betUrl = this.getBetUrl(betId);
    return `${betUrl}/areas/${areaId}`;
  }

  public getAreasUrl(betId: string): string {
    const betUrl = this.getBetUrl(betId);
    return `${betUrl}/areas`;
  }

  public getStrategicMapUrl(betId: string): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", `bets/${betId}/strategic-map`);
  }

  public getStrategicMapAsyncUrl(betId: string): string {
    const strategicMapUrl = this.getStrategicMapUrl(betId);
    return `${strategicMapUrl}`;
  }

  public getHypothesesUrl(betId: string, areaId: string): string {
    const areaUrl = this.getAreaUrl(betId, areaId);
    return `${areaUrl}/hypotheses`;
  }

  public getHypothesisUrl(betId: string, areaId: string, hypothesisId: string): string {
    const areaUrl = this.getAreaUrl(betId, areaId);
    return `${areaUrl}/hypotheses/${hypothesisId}`;
  }
}
