import ReconnectingWebSocket from "reconnecting-websocket";
import { BehaviorSubject, EMPTY, Observable, Subject, from, of, switchMap, throwIfEmpty } from "rxjs";
import { TokenProvider } from "@gtmhub/auth";
import { accountId } from "@webapp/core/storage/services/cache/account-id";
import { ISocketsApiService } from "../models/sockets-api.interface";
import { QuantiveResultsSocketDTOv2 } from "../models/strategy/socket-strategy.dto-v2-models";
import { QuantiveResultsSocketMessageType, QuantiveResultsSocketVM } from "../models/strategy/socket-strategy.vm-models";
import { AreaRemovedResponseStrategy } from "../utils/quantive-results-socket-strategy/area-removed-response.strategy";
import { AskQuestionResponseStrategy } from "../utils/quantive-results-socket-strategy/ask-question-response.strategy";
import { BetContributorsModifiedResponseStrategy } from "../utils/quantive-results-socket-strategy/bet-contributors-modified-response.strategy";
import { BetRemovedResponseStrategy } from "../utils/quantive-results-socket-strategy/bet-removed-response.strategy";
import { ContributorLockResponseStrategy } from "../utils/quantive-results-socket-strategy/contributor-lock-response.strategy";
import { ContributorRemovedResponseStrategy } from "../utils/quantive-results-socket-strategy/contributor-removed-response.strategy";
import { EmbedDocumentResponseStrategy } from "../utils/quantive-results-socket-strategy/embed-document-response.strategy";
import { GenerateGoalsForBetResponseStrategy } from "../utils/quantive-results-socket-strategy/generate-goals-for-bet-response.strategy";
import { GenerateGoalsForContextResponseStrategy } from "../utils/quantive-results-socket-strategy/generate-goals-for-context-response.strategy";
import { GenerateOnePagerResponseStrategy } from "../utils/quantive-results-socket-strategy/generate-one-pager-response.strategy";
import { GenerateStrategyMapAreaResponseStrategy } from "../utils/quantive-results-socket-strategy/generate-strategy-map-area-response.strategy";
import { GenerateStrategyMapResponseStrategy } from "../utils/quantive-results-socket-strategy/generate-strategy-map-response.strategy";
import { PlatformStatusResponseStrategy } from "../utils/quantive-results-socket-strategy/platform-status-response.strategy";
import { RegenerateChatAnswerResponseStrategy } from "../utils/quantive-results-socket-strategy/regenerate-chat-answer-response.strategy";
import { SummarizationResponseStrategy } from "../utils/quantive-results-socket-strategy/summarization-response.strategy";
import { UpdateContextDocumentsSummaryResponseStrategy } from "../utils/quantive-results-socket-strategy/update-context-document-summary.strategy";
import { UpdateDocumentSummaryResponseStrategy } from "../utils/quantive-results-socket-strategy/update-document-summary-response.strategy";
import { UpdateHypothesisResponseStrategy } from "../utils/quantive-results-socket-strategy/update-hypothesis-response.strategy";
import { QuantiveResultsSocketContext } from "../utils/quantive-results-socket.context";

type SocketConnectionStatus = "closed" | "pending" | "success" | "error";

type StrategySocketDTO<T extends QuantiveResultsSocketMessageType> = QuantiveResultsSocketDTOv2<T>;

export class BaseSocketsService {
  private socketsApiService: ISocketsApiService;

  public constructor(private tokenProvider: TokenProvider) {}

  private readonly connectionTimeoutInMs = 10000;
  private socket: ReconnectingWebSocket;
  private connectionStatus$ = new BehaviorSubject<SocketConnectionStatus>("closed");
  private messagesSubject$ = new Subject<StrategySocketDTO<QuantiveResultsSocketMessageType>>();

  public setApiService(apiService: ISocketsApiService): void {
    this.socketsApiService = apiService;
  }

  public onMessage$<MessageType extends QuantiveResultsSocketMessageType>(messageType: MessageType): Observable<QuantiveResultsSocketVM<MessageType>> {
    if (!this.socketsApiService) {
      throw new Error("Sockets API service is not set");
    }
    return this.connectionStatus$.pipe(
      switchMap((connectionStatus) => {
        if (connectionStatus === "closed") {
          return this.initializeSocketConnection();
        }

        return of(connectionStatus);
      }),
      switchMap((connectionStatus) => {
        if (connectionStatus === "error") {
          return EMPTY.pipe(throwIfEmpty(() => new Error("Socket connection failed")));
        }

        const socketContext = this.getSocketContext(messageType);

        return this.messagesSubject$.pipe(socketContext.onMessage());
      })
    );
  }

  public sendMessage$(
    messageType: StrategySocketDTO<QuantiveResultsSocketMessageType>["messageType"],
    data: StrategySocketDTO<QuantiveResultsSocketMessageType>["data"]
  ): void {
    this.messagesSubject$.next({
      messageType,
      data,
    } as StrategySocketDTO<QuantiveResultsSocketMessageType>);
  }

  public sendSocketMessage(message: Record<string, unknown>): void {
    this.socket.send(JSON.stringify(message));
  }

  private urlProvider = (): Promise<string> => {
    return this.tokenProvider.getValidToken().then((token) => this.generateConnectionUrl(token)) as Promise<string>;
  };

  private initializeSocketConnection(): Observable<SocketConnectionStatus> {
    return from(this.tokenProvider.getValidToken()).pipe(
      switchMap((token) => {
        if (!token) {
          this.connectionStatus$.next("error");
          return this.connectionStatus$.asObservable();
        }

        this.connectionStatus$.next("pending");

        this.socket = new ReconnectingWebSocket(this.urlProvider, null, {
          connectionTimeout: this.connectionTimeoutInMs,
        });
        this.socket.onopen = (): void => {
          this.connectionStatus$.next("success");
        };
        this.socket.onerror = (): void => {
          this.connectionStatus$.next("error");
        };
        this.socket.onmessage = (event: MessageEvent<string>): void => {
          const socketMessage: {
            messageType: QuantiveResultsSocketMessageType;
            data: StrategySocketDTO<QuantiveResultsSocketMessageType>["data"];
          } = JSON.parse(event.data) as {
            messageType: QuantiveResultsSocketMessageType;
            data: StrategySocketDTO<QuantiveResultsSocketMessageType>["data"];
          };

          const { messageType, data } = socketMessage;
          this.messagesSubject$.next({
            data,
            messageType,
          } as StrategySocketDTO<QuantiveResultsSocketMessageType>);
        };

        return this.connectionStatus$.asObservable();
      })
    );
  }

  private getSocketContext<MessageType extends QuantiveResultsSocketMessageType>(messageType: MessageType): QuantiveResultsSocketContext<MessageType> {
    switch (messageType) {
      case "createStrategyMapResponse": {
        return new QuantiveResultsSocketContext(new GenerateStrategyMapResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "createStrategyMapAreaResponse": {
        return new QuantiveResultsSocketContext(new GenerateStrategyMapAreaResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "embedDocument": {
        return new QuantiveResultsSocketContext(new EmbedDocumentResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "updateContextDocumentsSummary": {
        return new QuantiveResultsSocketContext(new UpdateContextDocumentsSummaryResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "updateDocumentSummary": {
        return new QuantiveResultsSocketContext(new UpdateDocumentSummaryResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "summarization": {
        return new QuantiveResultsSocketContext(new SummarizationResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "askQuestion": {
        return new QuantiveResultsSocketContext(new AskQuestionResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "regenerateChatAnswer": {
        return new QuantiveResultsSocketContext(new RegenerateChatAnswerResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "betContributorsModified": {
        return new QuantiveResultsSocketContext(new BetContributorsModifiedResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "contributorLock": {
        return new QuantiveResultsSocketContext(new ContributorLockResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "contributorRemoved": {
        return new QuantiveResultsSocketContext(new ContributorRemovedResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "betRemoved": {
        return new QuantiveResultsSocketContext(new BetRemovedResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "strategyGlobalStatus": {
        return new QuantiveResultsSocketContext(new PlatformStatusResponseStrategy()) as QuantiveResultsSocketContext<MessageType>;
      }
      case "generateGoalsForBetResponse": {
        return new QuantiveResultsSocketContext(new GenerateGoalsForBetResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "generateGoalsForContextResponse": {
        return new QuantiveResultsSocketContext(new GenerateGoalsForContextResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "areaRemoved": {
        return new QuantiveResultsSocketContext(new AreaRemovedResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "generateOnePager": {
        return new QuantiveResultsSocketContext(new GenerateOnePagerResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
      case "updateHypothesis": {
        return new QuantiveResultsSocketContext(new UpdateHypothesisResponseStrategy(), false) as QuantiveResultsSocketContext<MessageType>;
      }
    }
  }

  private generateConnectionUrl(token: string): string {
    return this.socketsApiService.getSocketEndpoint({
      token,
      accountId: accountId.get(),
    });
  }
}
