import { Injectable } from "@angular/core";
import { StrategyConversationUrlBuilderV2Service } from "../../../conversation/api-utils/v2/strategy-conversation-url-builder-v2.service";

@Injectable()
export class StrategyConversationContextUrlBuilderV2Service extends StrategyConversationUrlBuilderV2Service {
  private contextBaseUrl = "context";

  public getConversationContextUrl(conversationId: string): string {
    const conversationUrl = this.getConversationUrl(conversationId);
    return `${conversationUrl}/${this.contextBaseUrl}`;
  }

  public getContextsUrl(): string {
    return this.getApiEndpointV2WithAdditionalBase("strategy", "contexts");
  }

  public getContextUrl(contextId: string): string {
    const contextsUrl = this.getContextsUrl();
    return `${contextsUrl}/${contextId}`;
  }
}
