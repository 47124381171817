<generic-results-box
  class="result-box"
  [data]="ansoff.marketPenetration"
  [labels]="['existing_products', 'existing_markets']"
  (contentChanged)="changeContent($event, 'marketPenetration')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Market Penetration"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="ansoff.marketDevelopment"
  [labels]="['existing_products', 'new_markets']"
  (contentChanged)="changeContent($event, 'marketDevelopment')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Market Development"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="ansoff.productDevelopment"
  [labels]="['new_products', 'existing_markets']"
  (contentChanged)="changeContent($event, 'productDevelopment')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Product Development"></generic-results-box>
<generic-results-box
  class="result-box"
  [data]="ansoff.diversification"
  [labels]="['new_products', 'new_markets']"
  (contentChanged)="changeContent($event, 'diversification')"
  (executeAction)="executeAction.emit($event)"
  (focusOutWithChanges)="focusOutWithChanges.emit()"
  title="Diversification"></generic-results-box>
