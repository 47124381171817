import { NgModule } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzUploadComponent } from "ng-zorro-antd/upload";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiUploadComponent } from "./upload.component";

@NgModule({
  declarations: [UiUploadComponent],
  imports: [NzUploadComponent, UiIconModule, LocalizationModule],
  exports: [UiUploadComponent],
})
export class UiUploadModule {}
