@if (!area.error) {
  <form [formGroup]="area.form" (ngSubmit)="onSubmit($event)">
    <ui-form-control class="input-container">
      <ui-input-text-area
        [attr.id]="area.id"
        [focusMe]="true"
        [formControlName]="'title'"
        [maxCharacterCount]="300"
        [showCharacterCount]="true"
        [showMaxCharacterCount]="true"
        [uiPlaceholder]="'Enter area...'"
        [visibleRowCount]="4"></ui-input-text-area>
    </ui-form-control>
    <section class="actions">
      <button type="submit" ui-button uiShape="round" uiSize="small" uiType="primary">
        {{ submitButtonText }}
      </button>
      <button (click)="onCancel($event)" ui-button uiShape="round" uiSize="small" uiType="link">Cancel</button>
    </section>
  </form>
} @else {
  <section class="area-error">
    <i class="text-color-gray-9" ui-icon uiType="caution"></i>
    <p class="sp-mb-0">
      Unable to save area. Please try again or
      <span class="contact-support" (click)="showIntercom()" (keypress)="showIntercom()" tabindex="0"> contact support. </span>
    </p>
    <section class="actions">
      <button (click)="onSubmit($event)" ui-button uiShape="round" uiType="link">
        <i class="fs-14px delete-icon" ui-icon ui-tooltip uiShape="round" uiSize="sm" uiTheme="outline" uiTooltipPlacement="bottom" uiTooltipTitle="Try again" uiType="refresh"></i>
      </button>
      <button (click)="onCancel($event)" ui-button uiShape="round" uiSize="small" uiType="link">
        <i class="fs-14px delete-icon" ui-icon ui-tooltip uiShape="round" uiTheme="outline" uiTooltipPlacement="bottom" uiTooltipTitle="Remove error" uiType="close-big"></i>
      </button>
    </section>
  </section>
}
