import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { IngestionApiService } from "./ingestion-api.service";
import { DocumentIngestion } from "./ingestion.models";

@Injectable()
export class IngestionRepositoryService {
  constructor(private api: IngestionApiService) {}

  public uploadDocument$(file: File): Observable<string> {
    return this.api.uploadDocument$(file).pipe(map((response) => response[0]));
  }

  public getDocumentsStatus$(documentIds: string[]): Observable<DocumentIngestion[]> {
    return this.api.getAll$({}, { url: this.api.getStatusEndpoint(), queryParams: { sourceKeys: documentIds.join(",") } });
  }
}
