<span class="loader" *ngIf="chatEntry?.inserting"></span>
<section class="error" *ngIf="chatEntry?.insertingError">
  <alert-message [attachContactSupport]="true" (dismissAlert)="hideAlertError()" dismissType="x-button" message="Unable to insert comment." type="error"> </alert-message>
</section>
<section class="actions">
  <section class="primary-container">
    <button *ngIf="!chatEntry || !chatEntry?.inserting" (click)="addContent.emit()" ui-button uiShape="round" uiSize="default" uiType="primary">
      <i class="fs-16px" ui-icon uiTheme="outline" uiType="plus"></i>
      <span>Insert</span>
    </button>
    <button *ngIf="chatEntry?.inserting" [disabled]="true" (click)="addContent.emit()" ui-button uiShape="round" uiSize="default" uiType="primary">
      <i class="fs-16px" ui-icon uiTheme="outline" uiType="plus"></i>
      <span>Inserting</span>
    </button>
    <button class="action-button" *ngIf="showRegenerate" (click)="refreshContent.emit()" ui-button>
      <i class="fs-16px" ui-icon uiType="refresh"></i>
    </button>
  </section>
  <section class="feedback-container" [ngClass]="{ 'feedback-provided': feedbackScore !== null }">
    <button class="action-button reaction" [ngClass]="{ active: feedbackScore === 'good' }" (click)="provideFeedback.emit('positive')" ui-button>
      <i class="fs-16px" ui-icon uiTheme="fill" uiType="thumb-up"></i>
    </button>
    <button class="action-button reaction" [ngClass]="{ active: feedbackScore === 'bad' }" (click)="provideFeedback.emit('negative')" ui-button>
      <i class="fs-16px" ui-icon uiTheme="fill" uiType="thumb-down"></i>
    </button>
  </section>
</section>
