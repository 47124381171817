<form class="hypothesis-form" [formGroup]="hypothesis.form" (ngSubmit)="onSubmit($event)">
  <ui-form-control>
    <ui-input-text-area
      #areaTitle
      [attr.id]="hypothesis.id"
      [focusMe]="true"
      [maxCharacterCount]="1000"
      [showCharacterCount]="true"
      [showMaxCharacterCount]="true"
      [visibleRowCount]="4"
      formControlName="statement"
      uiPlaceholder="Enter hypothesis..."></ui-input-text-area>
  </ui-form-control>
  <section class="actions">
    <button (click)="onSubmit($event)" type="submit" ui-button uiShape="round" uiSize="small" uiType="primary">
      {{ submitButtonText }}
    </button>
    <button [uiDanger]="true" (click)="onDelete($event)" ui-button uiShape="round" uiSize="small" uiType="link">Delete</button>
    <button (click)="onCancel($event)" ui-button uiShape="round" uiSize="small" uiType="link">Cancel</button>
  </section>
</form>
