import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { StrategyConfig } from "../../models/strategy.vm-models";
import { StrategyConfigApiFactory } from "./api-utils/strategy-config-api-factory.service";

@Injectable()
export class StrategyConfigService {
  private configSubject$ = new BehaviorSubject<StrategyConfig>({
    showReferences: storage.get("showReferences") === "1",
  });

  constructor(private strategyConfigApiFactory: StrategyConfigApiFactory) {}

  public getFrameworksConfig$(): Observable<unknown> {
    return this.strategyConfigApiFactory.getApi().pipe(switchMap((api) => api.getConfig$()));
  }

  public getConfig$(): Observable<StrategyConfig> {
    return this.configSubject$.asObservable();
  }

  public showReferences(): void {
    this.configSubject$.next({ showReferences: true });
    storage.set("showReferences", "1");
  }

  public hideReferences(): void {
    this.configSubject$.next({ showReferences: false });
    storage.set("showReferences", "0");
  }
}
