<top-nav-bar [hideGlobalSearch]="true" [initialActiveTabKey]="initialActiveTabKey" [navSubTitle]="subTitle" [navTitle]="title" [topNavBarTabsConfig]="topNavBarTabsConfig" searchFacet="whiteboards">
  <ng-container top-nav-bar-favorite>
    <favorite-button [isFavorite]="isFavorite$() | async" (addToFavorites)="addToFavorites()" (removeFromFavorites)="removeFromFavorites()" a11yLabel="Add to favorites"></favorite-button>
  </ng-container>

  <ng-container top-nav-bar-dropdown>
    @if (menuItems) {
      <gh-dropdown
        [hasCustomButtonContent]="true"
        [menuItems]="menuItems"
        (dropdownToggled)="dropdownToggled($event)"
        data-test-id="dropdown-actions-menu"
        dropdownMenuClass="top-nav-bar-title-actions"
        placement="bottomLeft"
        testIdSuffix="top-nav-bar-dropdown">
        <button [attr.aria-expanded]="dropdownVisible" aria-haspopup="menu" aria-label="settings" gh-dropdown-custom-button-content ui-button ui-dropdown uiTrigger="click">
          <i ui-icon uiType="menu-horizontal"></i>
        </button>
      </gh-dropdown>
    }
  </ng-container>

  <ng-container top-nav-bar-upload-button>
    <upload-button [documentIds]="documentIds" (uploadedDocumentIds)="uploadedDocumentIds.emit($event)" design="files-context" />
  </ng-container>
</top-nav-bar>
