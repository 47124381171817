// These models must be moved from Ajs to A12
import { IEdition, ILicense } from "@gtmhub/core";
import { Assignee, AssigneeType } from "@webapp/assignees/models/assignee.models";

export const DEFAULT_OKR_KR_OWNER = "user and team";
export const DEFAULT_KPI_OWNER = "user and team";

export type AssignPermissions = AssigneeType | "user and team";

export type ColoringStrategy = "none" | "colors" | "both";

export interface IColoringSetup {
  strategy: ColoringStrategy;
  ranges: IColoringRange[];
  defaultColor: string;
}

export interface IColoringRange {
  lower: number;
  color: string;
}

export interface IAccountSubscription {
  hasScheduledCancellation: boolean;
  items: ILicense[];
  subscriptionTypesCount: number;
}

export interface IPortalSectionOptions {
  sectionType: string;
  params?: {
    subscriptionId?: string;
    paymentSourceId?: string;
  };
}

export interface IPortalSections {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SUBSCRIPTION_DETAILS: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EDIT_SUBSCRIPTION: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VIEW_SCHEDULED_CHANGES: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ACCOUNT_DETAILS: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EDIT_ACCOUNT_DETAILS: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ADDRESS: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EDIT_BILLING_ADDRESS: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EDIT_SHIPPING_ADDRESS: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PAYMENT_SOURCES: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ADD_PAYMENT_SOURCE: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EDIT_PAYMENT_SOURCE: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VIEW_PAYMENT_SOURCE: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BILLING_HISTORY: string;
}

export interface IUpgradePlan {
  edition: string;
  billingPeriod: string;
}

export interface IUpgradedEdition {
  nextBillingAt: string;
  edition: IEdition;
}

export interface IBrandingImage {
  url: string;
}

export interface IFeature {
  title: string;
  description: string;
  value: boolean;
  isFeatureAvailable: boolean;
  key: string;
  imgSrc: string;
}
export interface IFeatureAdditionalInfo {
  [key: string]: IFeature;
}

export interface IActivityHistoryRecord {
  created: string;
  kind: string;
  ownerId: string;
  target: string;
  targetId: string;
  targetName: string;
  content: string;
  metadata?: { [key: string]: unknown };
  isDeleted: boolean;
  hasPermission: boolean;
}

export interface IGridActivityHistoryRecord {
  id: string;
  targetId: string;
  dateTime: {
    date: string;
    time: string;
  };
  role: string;
  user: Assignee | string;
  action: string;
  item: string | Assignee;
  type: string;
  metadata?: { [key: string]: unknown };
  displayType: string;
  kind: string;
  description: string;
  isDeleted: boolean;
  currentUserHasAccess: boolean;
  content?: { [key: string]: unknown };
  targetName?: string;
  hasValidUrl?: boolean;
}

export interface IMandatoryOkrAlignmentSettings {
  enabled: boolean;
  excludedUserIds: string[];
}

export type ConfidenceScale = `${ConfidenceScaleEnum}`;
export type ConfidenceType = `${ConfidenceTypeEnum}`;

export enum ConfidenceScaleEnum {
  Minor = "minor",
  Major = "major",
}

export enum ConfidenceTypeEnum {
  Emoji = "emoji",
  Numeric = "numeric",
  Text = "text",
}

export type IdpRoleMappingsProvisioningSetupType = "sso" | "scim";

type SSOSupportedIntegrationStrategy = "waad" | "google-apps";

type SSOConnectionStrategy = SSOSupportedIntegrationStrategy | string;

export interface IPostSSOConnection {
  strategy: SSOConnectionStrategy;
  domainAllias: string[];
  adDomain: string;
  canEditPicture?: boolean;
  multiConnection?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface IPatchSSOConnection extends Pick<IPostSSOConnection, "canEditPicture"> {}

export interface ISSOConnection {
  id: string;
  strategy: SSOConnectionStrategy;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  provisioning_ticket_url: string;
  options?: ISSOConnectionOptions;
  canEditPicture?: boolean;
  dateCreated?: string;
  isConnectionConfirmed?: boolean;
}

interface ISSOConnectionOptions {
  passwordPolicy: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  password_complexity_options: ISSOConnectionPasswordComplexityOptions;
}

interface ISSOConnectionPasswordComplexityOptions {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  min_length: number;
}

export interface IIdpGroup {
  id: string;
  memberIds: string[];
  displayName: string;
}

export interface IIdpRoleMapping {
  id: string;
  externalRole: string;
  internalRole: string;
  isDefault: boolean;
}

export interface IPutIdpRoleMapping {
  id?: string;
  externalRole: string;
  internalRole: string;
  isDefault: boolean;
}

export interface IConfigActivity {
  name: string;
  display: string;
}

export interface SlackIntegration {
  teamId: string;
  channel: string;
  activities: SlackConfigActivity;
}

export interface SlackConfigActivity {
  [activityName: string]: {
    display: string;
    enabled: boolean;
  };
}

export interface SlackIntegrationDTO {
  teamId: string;
  channel: string;
  activities: SlackConfigActivity;
}

export const titleDictionary: Record<string, string> = {
  methodology: "methodology",
  tags: "tag_management",
  language: "language",
  accountNotificationSettings: "account_notifications",
  okrUpdateReminders: "okr_update_reminders_title",
  aggregatedReport: "aggregated_report",
  features: "features",
  customFields: "custom_fields",
  parameters: "insightboard_parameters",
  pluginList: "installed_apps",
  apiTokens: "api_tokens",
  slack: "slack",
  jira: "jira",
  workday: "workday",
  accountNameDomainChange: "account_and_domain",
  branding: "branding",
  billing: "billing",
  singleSignOn: "single_sign_on",
  scim: "scim_provisioning",
  activityHistory: "activity_history",
  groupRoleMapping: "group_to_role_mapping",
  homeWidgets: "home_widgets",
  myOkrs: "my_okrs",
  vivagoalsconfig: "vivagoals_to_quantive_migration",
  vivagoalsmigration: "vivagoals_to_quantive_migration",
};
