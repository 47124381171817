import { UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { UiChipModule } from "@quantive/ui-kit/chip";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { AssigneeDetailsPopoverComponent } from "@webapp/assignees/components/assignee-details-popover/assignee-details-popover.component";
import { AssigneeActionsProvider } from "@webapp/assignees/providers/assignee-actions.provider";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { OkrWorkflowService } from "@webapp/okrs/components/okr-workflow/okr-workflow.service";
import { OnboardingServiceProvider } from "@webapp/onboarding/providers/onboarding-service.provider";
import { HasAllPermissionsDirective } from "@webapp/permissions/directives/has-all-permissions.directive";
import { GuidedOKRsModule } from "@webapp/platform-intelligence/guided-okrs/guided-okrs.module";
import { PlanningSessionsActionsProvider } from "@webapp/sessions/providers/sessions.provider";
import { ChipAssigneeDirective } from "@webapp/shared/chip/chip-assignee.directive";
import { TopNavBarModule } from "@webapp/top-nav-bar/top-nav-bar.module";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiDashboardComponent } from "@webapp/ui/dashboard/dashboard.component";
import { UiDashboardModule } from "@webapp/ui/dashboard/dashboard.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiListModule } from "@webapp/ui/list/list.module";
import { UiPopoverModule } from "@webapp/ui/popover/popover.module";
import { ContainerEmptyStateComponent } from "../shared/components/container-empty-state/container-empty-state.component";
import { HomeDashboardComponent } from "./components/home-dashboard/home-dashboard.component";
import { HomeSkeletonComponent } from "./components/home-skeleton/home-skeleton.component";
import { HomeTopNavBarComponent } from "./components/home-top-nav-bar/home-top-nav-bar.component";
import { ReadOnlyUserMessageComponent } from "./components/user-details/read-only-user-message/read-only-user-message.component";
import { UserDetailsComponent } from "./components/user-details/user-details.component";
import { ViewMoreComponent } from "./components/user-details/view-more/view-more.component";
import { WIDGET_COMPONENTS } from "./components/widgets";
import { HomeRoutingModule } from "./home-routing.module";
import { HomeWidgetConfigurationService } from "./services/home-widget-configuration.service";
import { HomeWidgetService } from "./services/home-widget.service";

@NgModule({
  declarations: [HomeSkeletonComponent, UserDetailsComponent, ViewMoreComponent, HomeDashboardComponent],
  imports: [
    CommonModule,
    UiButtonModule,
    UiIconModule,
    LocalizationModule,
    UiCardModule,
    UiSkeletonModule,
    UiListModule,
    UiGridModule,
    AssigneesModule,
    UiChipModule,
    UIRouterModule,
    ChipAssigneeDirective,
    UiDashboardModule,
    HomeRoutingModule,
    UiAssigneeModule,
    TopNavBarModule,
    HasAllPermissionsDirective,
    UiPopoverModule,
    ReadOnlyUserMessageComponent,
    ContainerEmptyStateComponent,
    AssigneeDetailsPopoverComponent,
    HomeTopNavBarComponent,
    GuidedOKRsModule,
  ],
  exports: [HomeSkeletonComponent],
  providers: [HomeWidgetService, HomeWidgetConfigurationService, OnboardingServiceProvider, AssigneeActionsProvider, PlanningSessionsActionsProvider, OkrWorkflowService],
})
export class HomeModule {
  constructor() {
    UiDashboardComponent.registerWidgetComponents(...WIDGET_COMPONENTS);
  }
}
