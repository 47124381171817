import { Injectable } from "@angular/core";
import { NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { Observable, from, map, of } from "rxjs";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { ISearchCondition, ISearchFieldCondition, ISearchRequestsBody, SearchOperatorsEnum } from "@webapp/search/models/search-api.models";
import { SearchDTO } from "@webapp/search/models/search.models";
import { SearchFacade } from "@webapp/search/services/search-facade.service";
import { Goal } from "../goals/models/goal.models";
import { GoalsFacade } from "../goals/services/goals-facade.service";
import { Metric } from "../metrics/models/metric.models";
import { MetricsFacade } from "../metrics/services/metrics-facade.service";
import { IGetParentSelectionItemsParams, IGetSearchParams, IParentSelectorNode } from "../models/parent-selector.models";
import { buildSearchNodes, metricNode, objectiveNode, sessionNode } from "../utils/okr-and-kpi-selector-nodes-builder.util";

const SUGGESTION_TAKE_ENTRIES_NUMBER = 10;
const SKIP_ENTRIES_NUMBER = 0;
const getItems = <T>({ items }: { items: Array<T> }): Array<T> => items;

@Injectable({
  providedIn: "any",
})
export class ParentSelectorFacade {
  constructor(
    private goalsFacade: GoalsFacade,
    private metricsFacade: MetricsFacade,
    private searchFacade: SearchFacade
  ) {}

  public getParentSelectionItems$({ sessionIds, ownerIds, goalIdToExclude }: IGetParentSelectionItemsParams): Observable<Goal[]> {
    const filter: Record<string, unknown> = {};

    if (sessionIds?.length) {
      filter.sessionId = { $in: sessionIds };
    }

    if (ownerIds?.length) {
      filter.ownerIds = { $in: ownerIds };
    }

    const filterOnEdit = { ...filter, _id: { $ne: goalIdToExclude } };
    const fields = [
      "name",
      "ownerIds",
      "sessionId",
      "metrics{id,name,ownerIds,sessionId,currentUserAllowedActions},private,attainmentTypeString",
      "currentUserAllowedActions",
    ];
    const skip = SKIP_ENTRIES_NUMBER;
    const take = SUGGESTION_TAKE_ENTRIES_NUMBER;
    const sort = ["name"];

    const getGoals = { ...new RequestPaging(), filter: goalIdToExclude ? filterOnEdit : filter, fields, skip, take, sort };

    return this.goalsFacade.getAll$(getGoals).pipe(map(getItems));
  }

  public getParentSelectionItem$({ sessionIds, type, parentId }: IGetParentSelectionItemsParams): Observable<Array<Goal | Metric>> {
    if (parentId) {
      const filter = { sessionId: { $in: sessionIds }, _id: parentId };
      const getParentParams = { ...new RequestPaging(), filter, skip: SKIP_ENTRIES_NUMBER, take: 1 };

      const goalFields = ["name", "ownerIds", "sessionId", "metrics{id,name,ownerIds}"];
      const getGoal = { ...getParentParams, fields: goalFields };
      const metricFields = ["id", "name", "ownerIds", "sessionId"];
      const getMetric = { ...getParentParams, fields: metricFields };

      return type === "goal" ? this.goalsFacade.getAll$(getGoal).pipe(map(getItems)) : this.metricsFacade.getAll$(getMetric).pipe(map(getItems));
    }

    return from([]);
  }

  private createSearchConditions(sessions: string[]): { goalCondition: ISearchCondition; metricCondition: ISearchCondition } {
    const fieldCondition: ISearchFieldCondition = {
      operator: "in",
      value: sessions,
    };

    const goalCondition = {
      fieldName: "sessionId",
      fieldCondition,
    };

    const metricCondition = {
      fieldName: "session._id",
      fieldCondition,
    };

    return {
      goalCondition,
      metricCondition,
    };
  }

  private createExcludeCondition(goalIdToExclude: string, fieldName: string): ISearchCondition {
    const fieldCondition: ISearchFieldCondition = {
      operator: "isNot",
      value: goalIdToExclude,
    };

    const excludeIdCondition: ISearchCondition = {
      fieldName: fieldName,
      fieldCondition,
    };

    return excludeIdCondition;
  }

  private createSearchRequests(goalsSearchConditions, metricsSearchConditions?): ISearchRequestsBody[] {
    const searchFields = [{ name: "name" }, { name: "description" }, { name: "assignees.name" }];

    const goalsRequest: ISearchRequestsBody = {
      collectionName: "goals",
      searchConditions: goalsSearchConditions,
      searchFields,
      responseFields: ["currentUserAllowedActions", "ownerIds", "access", "name", "description", "attainment", "accountId", "assignees", "orderId", "session"],
    };

    const metricsRequest: ISearchRequestsBody = {
      collectionName: "metrics",
      searchConditions: metricsSearchConditions,
      searchFields,
      responseFields: ["currentUserAllowedActions", "ownerIds", "access", "name", "description", "attainment", "accountId", "assignees", "orderId", "session"],
    };

    return [goalsRequest, metricsRequest];
  }

  private createGoalsSearchRequests(goalsSearchConditions): ISearchRequestsBody {
    const searchFields = [{ name: "name" }, { name: "description" }, { name: "assignees.name" }];

    return {
      collectionName: "goals",
      searchConditions: goalsSearchConditions,
      searchFields,
    };
  }

  private generateSessionNodes(searchBody: SearchDTO): Observable<NzTreeNodeOptions[]> {
    return this.searchFacade.searchData$<"goals" | "metrics">(searchBody).pipe(
      map(({ items }) => {
        const sessionNodes = items.reduce((sessions, item) => {
          const currentSession = item.fields.session;
          let childNode: IParentSelectorNode;

          if (item.collectionName === "metrics") {
            childNode = metricNode({
              id: item.id,
              name: item.fields.name,
              ownerIds: item.fields.ownerIds,
            });
          }

          if (item.collectionName === "goals") {
            childNode = objectiveNode({
              id: item.id,
              name: item.fields.name,
              sessionId: item.fields.session._id,
              ownerIds: item.fields.ownerIds,
            });
          }

          if (sessions[currentSession._id]) {
            const currentChildren = sessions[currentSession._id].children;
            currentChildren.push(childNode);
            sessions[currentSession._id] = sessionNode({
              sessionId: currentSession._id,
              sessionTitle: currentSession.title,
              childrenNodes: currentChildren,
              expanded: false,
            });
          } else {
            sessions[currentSession._id] = sessionNode({
              sessionId: currentSession._id,
              sessionTitle: currentSession.title,
              childrenNodes: [childNode],
              expanded: false,
            });
          }

          sessions[currentSession._id].expanded = false;

          return sessions;
        }, {});

        return Object.values(sessionNodes);
      })
    );
  }

  private generateChildrenNodes(searchBody: SearchDTO): Observable<NzTreeNodeOptions[]> {
    return this.searchFacade.searchData$<"goals" | "metrics">(searchBody).pipe(map(({ items }) => buildSearchNodes(items)));
  }

  public getParentSelectionItemsBySearchTerm$({
    searchTerm,
    sessionIds,
    goalIdToExclude,
    isImprovedParentOkrSelectorAvailable,
  }: IGetSearchParams): Observable<NzTreeNodeOptions[]> {
    const sessions = sessionIds?.filter(Boolean);
    const goalsSearchConditions: ISearchCondition[] = [];
    const metricsSearchConditions: ISearchCondition[] = [];

    if (sessions?.length > 0) {
      const searchConditions = this.createSearchConditions(sessions);

      goalsSearchConditions.push(searchConditions.goalCondition);
      metricsSearchConditions.push(searchConditions.metricCondition);
    }

    if (goalIdToExclude) {
      goalsSearchConditions.push(this.createExcludeCondition(goalIdToExclude, "_id"));
      metricsSearchConditions.push(this.createExcludeCondition(goalIdToExclude, "goalId"));
    }

    const searchBody: SearchDTO = {
      operator: SearchOperatorsEnum.matchPrefix,
      searchRequests: this.createSearchRequests(goalsSearchConditions, metricsSearchConditions),
      searchTerm,
    };

    if (isImprovedParentOkrSelectorAvailable) {
      return this.generateSessionNodes(searchBody);
    }

    return this.generateChildrenNodes(searchBody); // delete when enabling improved parent selector for everyone
  }

  public getGoalsBySearchTerm$({ searchTerm, sessionIds }: IGetSearchParams): Observable<NzTreeNodeOptions[]> {
    const sessions = sessionIds?.filter(Boolean);

    if (sessions.length) return of([]);

    const searchBody: SearchDTO = {
      operator: SearchOperatorsEnum.matchPrefix,
      searchRequests: [this.createGoalsSearchRequests(sessions)],
      searchTerm,
    };

    return this.generateChildrenNodes(searchBody);
  }
}
