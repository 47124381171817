import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  ChatBlueOceanDataVM,
  StructuredChatBlockDisplayData,
  StructuredChatDataSection,
} from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";

@Component({
  selector: "ui-ai-chat-blue-ocean-block",
  templateUrl: "./ai-chat-blue-ocean-block.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatBlueOceanBlockComponent implements OnInit {
  public blueOceanSections: StructuredChatBlockDisplayData | undefined;
  @Input() public blueOcean: ChatBlueOceanDataVM | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const erccGridSection = this.transformSwotAspectToSection(this.blueOcean?.errcGridAnalysis, "ERCC Grid Analysis");
    const fourActionsSection = this.transformSwotAspectToSection(this.blueOcean?.fourActionsFramework, "Four Actions Framework");
    const leadershipPeopleDevelopmentSection = this.transformSwotAspectToSection(this.blueOcean?.leadershipAndPeopleDevelopment, "Leadership and People Development");
    const marketBoundaryReconstructionSection = this.transformSwotAspectToSection(this.blueOcean?.marketBoundaryReconstruction, "Market Boundary Reconstruction");
    const sixPathsFrameworkSection = this.transformSwotAspectToSection(this.blueOcean?.sixPathsFramework, "Six Paths Framework");
    const strategicSequenceSection = this.transformSwotAspectToSection(this.blueOcean?.strategicSequence, "Strategic Sequence");
    const strategyCanvasSection = this.transformSwotAspectToSection(this.blueOcean?.strategyCanvas, " Canvas");
    const threeNonCustomerTiersSection = this.transformSwotAspectToSection(this.blueOcean?.threeNoncustomerTiers, "Three Noncustomer Tiers");
    const valueInnovationSection = this.transformSwotAspectToSection(this.blueOcean?.valueInnovation, "Value Innovation");
    this.blueOceanSections = [
      valueInnovationSection,
      erccGridSection,
      sixPathsFrameworkSection,
      fourActionsSection,
      strategyCanvasSection,
      threeNonCustomerTiersSection,
      marketBoundaryReconstructionSection,
      strategicSequenceSection,
      leadershipPeopleDevelopmentSection,
    ];
    this.cdr.markForCheck();
  }

  private transformSwotAspectToSection(swotAspect: StructuredDataAspectVM[] | undefined, title: string): StructuredChatDataSection {
    return {
      title,
      entries: swotAspect || [],
    };
  }
}
