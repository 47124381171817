import { localize } from "@gtmhub/localization";
import { GridFilterOperator, IDateRange, IDynamicValue } from "@gtmhub/shared/components/grid-filtering-bar";
import { filterOperators } from "@webapp/filters/models/filter-operator.models";
import { BuilderOperator, BuilderSetting } from "@webapp/filters/models/filter.models";
import { ExtendedGridFilter, ExtendedOrFilterGroups, FilterFieldName } from "@webapp/okrs/okrs-grid-page/models";

const NUMERIC_OPERATORS: BuilderOperator[] = [filterOperators.equals, filterOperators.notEqual, filterOperators.greater, filterOperators.less];

export const ARRAY_OPERATORS: BuilderOperator[] = [filterOperators.any, filterOperators.notEqual, filterOperators.contains, filterOperators.equals];

export const COMMON_OPERATORS: BuilderOperator[] = [filterOperators.anyOperator, filterOperators.allOperator, filterOperators.not];

const SESSION_OPERATORS: BuilderOperator[] = [filterOperators.any, filterOperators.not];

export const generateSessionSettings = (): BuilderSetting[] => [
  { label: localize("current_sessions"), value: true },
  { label: localize("past_sessions"), value: true },
  { label: localize("future_sessions"), value: true },
  { label: localize("archived_sessions"), value: false, isDivider: true },
];

export const generatePeopleSettings = (): BuilderSetting[] => [{ label: localize("deactivated_users_and_teams"), value: false }];

const TEXT_OPERATORS: BuilderOperator[] = [filterOperators.isText, filterOperators.isNotText, filterOperators.contains, filterOperators.doesNotContain];

const DATE_OPERATORS: BuilderOperator[] = [
  filterOperators.equals,
  filterOperators.notEqual,
  filterOperators.isBefore,
  filterOperators.isAfter,
  filterOperators.isBetween,
  filterOperators.isNotBetween,
];

const getDefaultValue = (dataType: ExtendedGridFilter["dataType"]): string | string[] | boolean | number | IDateRange => {
  switch (dataType) {
    case "text":
      return "";
    case "array":
      return [];
    case "boolean":
      return false;
    case "numeric":
      return 0;
    case "date":
      return new Date().toString();
    case "enum":
      return [];
    default:
      throw new Error("No such dataType exists! Make sure you're using the correct construct");
  }
};

export const createFilterGroup = (
  fieldName: FilterFieldName,
  operator: GridFilterOperator,
  dataType: ExtendedGridFilter["dataType"],
  operators: BuilderOperator[],
  settings: BuilderSetting[] = [],
  dynamicValues?: IDynamicValue[]
): ExtendedOrFilterGroups => ({
  fieldName,
  operator,
  dataType,
  operators,
  settings,
  value: getDefaultValue(dataType),
  dynamicValues,
});

export const generateWorkflowStatusFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "contains", "array", COMMON_OPERATORS);

export const generateTagsFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "contains", "array", COMMON_OPERATORS);

export const generateSessionFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "isOneOf", "array", SESSION_OPERATORS, generateSessionSettings(), []);

export const generateProgressScoreFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "less", "numeric", NUMERIC_OPERATORS);

export const generateOwnersFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "contains", "array", COMMON_OPERATORS, generatePeopleSettings(), []);

export const generateOkrStateFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "contains", "array", COMMON_OPERATORS);

export const generateOkrDesignScoreFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "less", "numeric", NUMERIC_OPERATORS);

export const generateAssigneeFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "isOneOf", "array", [filterOperators.any, filterOperators.equals, filterOperators.not], generatePeopleSettings(), []);

export const generateDatePickerFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "equals", "date", DATE_OPERATORS);

export const generateSelectFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "isOneOf", "enum", [filterOperators.any, filterOperators.not]);

export const generateMultiSelectFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups =>
  createFilterGroup(filterKey, "isOneOf", "array", [filterOperators.any, filterOperators.equals, filterOperators.notEqual]);

export const generateNumericFromFilterKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "less", "numeric", NUMERIC_OPERATORS);

export const generateTextInputFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "equals", "text", TEXT_OPERATORS);

export const generateLastUpdatedFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "equals", "date", DATE_OPERATORS);

export const generateAccessTypeFilterFromKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => createFilterGroup(filterKey, "isOneOf", "array", ARRAY_OPERATORS);
