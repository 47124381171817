import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UiSkeletonLineComponent } from "@quantive/ui-kit/skeleton";
import { Subscription, map, timer } from "rxjs";
import dayjs from "@webapp/shared/libs/dayjs";
import { LoadingIntervalMessages } from "./models";

@Component({
  selector: "interval-based-loading-card",
  templateUrl: "./interval-based-loading-card.component.html",
  styleUrl: "./interval-based-loading-card.component.less",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiSkeletonLineComponent],
})
export class IntervalBasedLoadingCardComponent implements OnInit, OnDestroy {
  @Input() public intervalMessages: LoadingIntervalMessages[] = [];
  @Input() public defaultMessage: string;
  @Input() public startTime: string;

  private readonly progressDuration = 25000; // after this time - 25s, we start showing indefinite loading;
  public loadingPercentage: number;
  private timeElapsed = 0;
  private timerSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.intervalMessages.sort((a, b) => a.intervalDuration - b.intervalDuration);
    this.startTimer();
  }

  public ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

  public get currentLoadingMessage(): string {
    for (let index = 0; index < this.intervalMessages.length; index++) {
      const { intervalDuration, message } = this.intervalMessages[index];
      if (this.timeElapsed < intervalDuration) {
        return message;
      }
    }

    return this.defaultMessage;
  }

  private startTimer(): void {
    const startTime = this.startTime ? dayjs.utc(this.startTime).valueOf() : dayjs.utc().valueOf();
    this.timerSubscription = timer(0, 100)
      .pipe(
        map(() => {
          return dayjs.utc().valueOf() - startTime;
        })
      )
      .subscribe((elapsed) => {
        this.timeElapsed = elapsed;
        this.loadingPercentage = Math.min((this.timeElapsed / this.progressDuration) * 100, 100);
        this.cdr.markForCheck();
      });
  }
}
