import { RestLayerFilter } from "@gtmhub/core";
import { IDateRange, INumericRange } from "@gtmhub/shared/components/grid-filtering-bar";
import { expandExtendedFilterValue } from "@gtmhub/shared/components/grid-filtering-bar/grid-filters/util";
import { getCurrentUserId } from "@gtmhub/users";
import { ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { ExtendedArrayGridFilter, ExtendedOrFilterGroups, FilterFieldName } from "@webapp/okrs/okrs-grid-page/models";
import { safeMongoCaseInsensitiveExactMatchRegex } from "@webapp/shared/utils/regex";
import { ToRestLayerDynamicValuesTransformer, resolveFilterOperator } from "../rest-layer-dynamic-values.transformer";

export abstract class ToCustomFieldRestLayerDynamicValuesTransformer extends ToRestLayerDynamicValuesTransformer {
  protected abstract resolveDynamicValues(
    value: string | number | boolean | string[] | INumericRange | IDateRange,
    operator: string,
    filterKey: FilterFieldName
  ): RestLayerFilter[];

  constructor(public readonly customFields: ICustomField[]) {
    super();
  }

  public static createTransformer(filterKey: FilterFieldName, customFields: ICustomField[] = []): ToRestLayerDynamicValuesTransformer {
    const customField = customFields.find((cf) => filterKey.includes(cf.name));
    if (!customField) {
      throw new Error(`Rest custom field not found in the list of custom fields ${filterKey}`);
    }

    switch (customField.visualizationKind) {
      case "gtmhub.selectassignee":
        return new ToOwnerIdRestLayerDynamicValueTransformer(customFields);
      case "gtmhub.select":
        return new ToSelectRestLayerDynamicValueTransformer(customFields);
      case "gtmhub.multiselect":
        return new ToMultiSelectRestLayerDynamicValueTransformer(customFields);
      case "gtmhub.datepicker":
        return new ToDatePickerRestLayerDynamicValueTransformer(customFields);
      case "gtmhub.numeric":
        return new ToNumericRestLayerDynamicValueTransformer(customFields);

      case "gtmhub.text":
      case "gtmhub.textarea":
        return new ToTextRestLayerDynamicValueTransformer(customFields);

      default:
        throw new Error(`Unsupported rest custom field dynamic value transformer for filter key ${filterKey}`);
    }
  }

  public transform(filter: ExtendedOrFilterGroups): RestLayerFilter[] {
    const filterOperator = resolveFilterOperator(filter.dataType, filter.operator);
    const customField = this.customFields.find((cf) => cf.name === filter.fieldName);
    const isAssigneeCustomField = customField?.targetTypes.some((type) => type === "user" || type === "team");
    const filterKey = isAssigneeCustomField ? (filter.fieldName.replace("assignees.", "") as FilterFieldName) : filter.fieldName;

    if (filter.dataType === "array") {
      const values = Array.isArray(filter.value) ? expandExtendedFilterValue(filter as ExtendedArrayGridFilter) : [filter.value as string];
      const strictMatch = filter.dataType === "array" && (filter.operator === "equals" || filter.operator === "notEqual");

      if (strictMatch) {
        const filter = values.map((value) => this.resolveDynamicValues([value], filterOperator, filterKey)).flat();

        return toFilterResult({ result: filter, isAssigneeCustomField });
      }

      return toFilterResult({ result: this.resolveDynamicValues(values, filterOperator, filterKey), isAssigneeCustomField });
    }

    return toFilterResult({ result: this.resolveDynamicValues(filter.value as string, filterOperator, filterKey), isAssigneeCustomField });
  }
}

const toFilterResult = ({ result, isAssigneeCustomField }: { result: RestLayerFilter[]; isAssigneeCustomField: boolean }): RestLayerFilter[] => {
  if (isAssigneeCustomField) {
    return result.map((filter) => ({
      assignees: {
        $elemMatch: filter,
      },
    }));
  }

  return result;
};

class ToOwnerIdRestLayerDynamicValueTransformer extends ToCustomFieldRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    const previousIds = [];
    const map = values.reduce<Record<string, unknown>>((map, id) => {
      if (id === "my-teams" || id === "myTeams") {
        return {
          ...map,
          [`${filterKey}_myTeams`]: { [operator]: [getCurrentUserId()] },
        };
      }

      if (id === "current-user" || id === "me") {
        id = getCurrentUserId();
      }

      previousIds.push(id);
      return {
        ...map,
        [filterKey]: { [operator]: previousIds },
      };
    }, {});

    return Object.keys(map).map((key) => ({
      [key]: map[key],
    }));
  }
}

class ToMultiSelectRestLayerDynamicValueTransformer extends ToCustomFieldRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(values: string[], operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    const previousIds = [];
    const map = values.reduce<Record<string, unknown>>((map, id) => {
      previousIds.push(id);
      return {
        ...map,
        [filterKey]: { [operator]: previousIds },
      };
    }, {});

    return Object.keys(map).map((key) => ({
      [key]: map[key],
    }));
  }
}

class ToSelectRestLayerDynamicValueTransformer extends ToCustomFieldRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: string, operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    if (!value.length) {
      return [];
    }

    if (operator === "$eq") {
      return [
        {
          [filterKey]: value,
        },
      ];
    }

    return [
      {
        [filterKey]: { [operator]: value },
      },
    ];
  }
}

class ToTextRestLayerDynamicValueTransformer extends ToCustomFieldRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: string, operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    if (operator === "$eq") {
      return [
        {
          [filterKey]: { $regex: safeMongoCaseInsensitiveExactMatchRegex(value) },
        },
      ];
    }

    return [
      {
        [filterKey]: { [operator]: value },
      },
    ];
  }
}

class ToDatePickerRestLayerDynamicValueTransformer extends ToCustomFieldRestLayerDynamicValuesTransformer {
  protected resolveDynamicValues(value: IDateRange, operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    switch (operator) {
      case "isBetween": {
        return [
          {
            $and: [{ [filterKey]: { $gte: value.startDate } }, { [filterKey]: { $lte: value.endDate } }],
          },
        ];
      }
      case "isNotBetween": {
        return [
          {
            $or: [{ [filterKey]: { $gte: value.startDate } }, { [filterKey]: { $lte: value.endDate } }],
          },
        ];
      }
      case "$eq":
        return [{ [filterKey]: value }];
      case "$ne":
      case "$gte":
      case "$lte":
        return [{ [filterKey]: { [operator]: value } }];
      default:
        return [];
    }
  }
}

class ToNumericRestLayerDynamicValueTransformer extends ToCustomFieldRestLayerDynamicValuesTransformer {
  // eslint-disable-next-line sonarjs/no-identical-functions
  protected resolveDynamicValues(value: string, operator: string, filterKey: FilterFieldName): RestLayerFilter[] {
    if (operator === "$eq") {
      return [
        {
          [filterKey]: value,
        },
      ];
    }

    return [
      {
        [filterKey]: { [operator]: value },
      },
    ];
  }
}
