import { StateService } from "@uirouter/angular";
import { AsyncPipe, NgIf } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EnvironmentInjector,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EMPTY, Observable, Subject, catchError, combineLatest, filter, firstValueFrom, forkJoin, map, of, switchMap, tap } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { Intercom } from "@gtmhub/shared/intercom";
import { StrategySettings } from "@gtmhub/users/models";
import { mapToString } from "@webapp/core/abstracts/decorators/route-param-to-input/route-param-to-input.adapters";
import { RouteParamInput } from "@webapp/core/abstracts/decorators/route-param-to-input/route-param-to-input.decorator";
import { catchHttpError } from "@webapp/core/rxjs-operators/catch-http-error.operator";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { UIErrorConfiguration } from "@webapp/strategy/models/error-handling.model";
import {
  BET_LOADING_ERROR,
  MOCK_REPORT_ID,
  STRATEGIC_MAP_GENERATION_ERROR,
  SUMMARY_CONVERSATION_MOCK_ID,
  VALIDATION_STATUS_COMPLETED,
} from "@webapp/strategy/models/strategy.constants";
import { closeLastDrawer } from "@webapp/strategy/services/utility/drawer";
import { UiErrorHandlingService } from "@webapp/strategy/services/utility/ui-error-handling.service";
import { QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";
import { StrategySocketsService } from "@webapp/strategy/services/web-sockets/services/strategy-sockets.service";
import { UiDrawerService } from "@webapp/ui/drawer/services/drawer.service";
import { UiLoadingIndicatorComponent } from "@webapp/ui/loading-indicator/loading-indicator.component";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { CurrentUserRepository } from "@webapp/users";
import { AsyncTaskVM } from "../../models/async-tasks/async-tasks.vm-models";
import { AsyncResponseVM, StrategicBetVM, StrategicMapVM, StrategyResultBlockTypeVM, TitleAndDescriptionDataVM } from "../../models/bets/strategic-bets.vm-models";
import { ContextVM } from "../../models/context/context.vm-models";
import { StrategyConversationVM } from "../../models/strategy-conversation/strategy-conversation.vm-models";
import { AsyncOperationPreviewState } from "../../models/strategy.vm-models";
import { AsyncTasksService } from "../../services/async-tasks/async-tasks.service";
import { GenerateGoalsForBetMediator } from "../../services/bet/generate-goals-for-bet/generate-goals-for-bet.mediator";
import { StrategicBetsService } from "../../services/bet/strategic-bets.service";
import { StrategyConversationContextService } from "../../services/context/strategy-conversation-context.service";
import { StrategyConversationService } from "../../services/conversation/strategy-conversation.service";
import { DocumentsMediatorService } from "../../services/documents/documents-mediator.service";
import { getOngoingTaskForName } from "../../services/utility/async-tasks.utils";
import { StrategiesTrackingService } from "../../services/utility/strategies-tracking.service";
import { StrategyConversationExportService } from "../../services/utility/strategy-conversation-export.service";
import { ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData } from "../confirm-delete-strategy-item/confirm-delete-strategy-item.component";
import { ContextDrawerComponent } from "../context-drawer/context-drawer.component";
import { ErrorDetailsComponent } from "../error-details/error-details.component";
import { ErrorPageComponent } from "../error-page/error-page.component";
import { GenerateStrategyPageComponent } from "../generate-strategy-page/generate-strategy-page.component";
import { ConversationExporterService } from "../result-block/services/conversation-exporter.service";
import { ResultBlockHtmlProvidersModule } from "../result-block/services/result-block-html-providers.module";
import { EvaluateBetHeaderComponent } from "../shared/evaluate-bet-header/evaluate-bet-header.component";
import { EvaluateBetContextComponent } from "./components/evaluate-bet-context/evaluate-bet-context.component";
import { EvaluateBetErrorsComponent } from "./components/evaluate-bet-errors/evaluate-bet-errors.component";
import { EvaluateBetStrategicMapComponent } from "./components/evaluate-bet-strategic-map/evaluate-bet-strategic-map.component";
import { InviteCollaboratorsModalComponent } from "./components/invite-collaborators-modal/invite-collaborators-modal.component";
import { WhiteboardGenerationComponent } from "./components/whiteboard-generation/whiteboard-generation.component";

export const HINT_INDEX_MAP: Record<number, keyof StrategySettings> = {
  0: "contextHintSeen",
  1: "strategicMapHintSeen",
  2: "strategicMapHintSeenCKBFlow",
};

@UntilDestroy()
@Component({
  selector: "evaluate-bet-page",
  templateUrl: "./evaluate-bet-page.component.html",
  styleUrls: ["./evaluate-bet-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiLoadingIndicatorComponent,
    NgIf,
    EvaluateBetHeaderComponent,
    EvaluateBetContextComponent,
    EvaluateBetStrategicMapComponent,
    WhiteboardGenerationComponent,
    ErrorPageComponent,
    EvaluateBetErrorsComponent,
    AsyncPipe,
    ResultBlockHtmlProvidersModule,
    ErrorDetailsComponent,
  ],
  providers: [UiErrorHandlingService],
})
export class EvaluateBetPageComponent implements OnInit, OnDestroy {
  @Input()
  @RouteParamInput<"betId", string>({ adapter: mapToString })
  public betId: string;

  @ViewChild("decisionDeletionError")
  private decisionDeletionError: TemplateRef<object>;
  @ViewChild("decisionLeaveError")
  private decisionLeaveError: TemplateRef<object>;
  @ViewChild("placeholder", { read: ViewContainerRef })
  private viewRef: ViewContainerRef;

  public generatedWhiteboardId: string | null = null;
  public strategicBet: StrategicBetVM | null = null;
  public currentStepIndex = 0;
  public betContext: ContextVM | null = null;
  public strategicMap: StrategicMapVM | null = null;
  public strategySettings: StrategySettings;
  public lastNotesValue: string | null = null;

  public redirectingToWhiteboard = false;
  public generatingStrategicMap = false;
  public loadingBet = false;
  public checkingForAsyncGeneration = false;

  public generateStrategyTaskId: string;

  public error: string | null = BET_LOADING_ERROR;
  public whiteboardGenerationError: string | null = null;
  public areaBeingModifiedId: string | null = null;
  public contextAsyncPreviewState$: Observable<AsyncOperationPreviewState>;
  public userIsOwner = false;
  public betNotAvaialbleForUser = false;
  public exportPdfAttempts = new Subject<void>();
  public uiError: UIErrorConfiguration;
  public onePagerGenerationFailed: boolean = false;
  public onePagerGenerationInProgress: boolean = false;
  public ckbFlowEnabled: boolean = false;

  constructor(
    public injector: Injector,
    private strategiesTrackingService: StrategiesTrackingService,
    private strategicBetsService: StrategicBetsService,
    private generateGoalsForBetMediator: GenerateGoalsForBetMediator,
    private strategyConversationContextService: StrategyConversationContextService,
    private cdr: ChangeDetectorRef,
    private modalService: UiModalService,
    private drawerService: UiDrawerService,
    private currentUserRepository: CurrentUserRepository,
    private socketsService: StrategySocketsService,
    private asyncTasksService: AsyncTasksService,
    private documentsMediator: DocumentsMediatorService,
    private conversationService: StrategyConversationService,
    private envInjector: EnvironmentInjector,
    private exportService: StrategyConversationExportService,
    private apmService: ApmService,
    private conversationExporterService: ConversationExporterService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private errorHandlingService: UiErrorHandlingService,
    private state: StateService
  ) {}

  public ngOnDestroy(): void {
    this.socketsService.setCurrentWatchers([]);
  }

  public ngOnInit(): void {
    this.loadBetData();

    this.socketsService.setCurrentWatchers([{ id: this.betId, type: "bet" }]);
    this.subscribeToSocketChanges();
  }

  public loadBetData(): void {
    this.checkingForAsyncGeneration = true;

    this.initializeBetData$()
      .pipe(
        takeOneUntilDestroyed(this),
        switchMap((bet) =>
          this.generateGoalsForBetMediator.listenForGoalGenerationChangesForBet$({
            id: bet.id,
            progressStep: bet.progressStep,
          })
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: ({ status, whiteboardId, errorMessage }) => {
          if (status === "IDLE") {
            this.generatedWhiteboardId = null;
            this.redirectingToWhiteboard = false;
            this.resetError();
          } else if (status === "ERROR") {
            this.generatedWhiteboardId = null;
            this.redirectingToWhiteboard = false;
            this.error = errorMessage;
            this.whiteboardGenerationError = errorMessage;
          } else if (status === "LOADING") {
            this.generatedWhiteboardId = null;
            this.redirectingToWhiteboard = true;
            this.resetError();
          } else if (status === "SUCCESS") {
            this.generatedWhiteboardId = whiteboardId;
            this.redirectingToWhiteboard = true;
            this.resetError();
          }

          this.checkingForAsyncGeneration = false;
          this.cdr.markForCheck();
        },
        error: () => {
          this.checkingForAsyncGeneration = false;
          this.cdr.markForCheck();
        },
      });
  }

  private initializeBetData$(): Observable<StrategicBetVM> {
    this.resetError();
    this.loadingBet = true;
    this.apmService.startDataLoadSpan("loading bet");
    return forkJoin({
      bet: this.strategicBetsService.getBet$(this.betId).pipe(takeOneUntilDestroyed(this)),
      ckbFlowEnabled: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.CKBFlowEnabled).pipe(takeOneUntilDestroyed(this)),
    }).pipe(
      catchError(() => {
        this.loadingBet = false;
        this.error = BET_LOADING_ERROR;
        this.cdr.markForCheck();

        return EMPTY;
      }),
      switchMap(({ bet, ckbFlowEnabled }) => {
        this.ckbFlowEnabled = ckbFlowEnabled;
        return forkJoin({
          context: this.strategyConversationContextService.getBetContext$(bet.contextId).pipe(takeOneUntilDestroyed(this)),
          strategyMapTasks: this.asyncTasksService.getTasksForAccountAndItemId$(this.betId, ["create_strategic_map"]).pipe(takeOneUntilDestroyed(this)),
          bet: of(bet),
        });
      }),
      tap(({ bet }) => {
        if (!bet.isSeen) {
          this.strategicBetsService.updateBet$({ id: bet.id, isSeen: true }).subscribe();
        }
      }),
      map(({ context, strategyMapTasks, bet }) => {
        this.betContext = context;
        this.contextAsyncPreviewState$ = this.documentsMediator.getContextAsyncPreviewState$(this.betContext.id);
        this.strategySettings = this.currentUserRepository.getUserSetting<StrategySettings>("strategySettings") ?? {};
        this.strategicBet = bet;
        const owner = this.strategicBet.collaborators.find((collaborator) => collaborator.role === "owner");
        this.userIsOwner = owner?.userId === storage.get("userId");

        this.setupInitialState(strategyMapTasks);

        this.loadingBet = false;
        this.apmService.endDataLoadSpan("loading bet");
        this.cdr.markForCheck();

        return bet;
      })
    );
  }

  public regenerateStrategy(): void {
    this.generateStrategy(this.lastNotesValue);
  }

  public generateStrategy(notes: string): void {
    this.resetError();
    if (!this.strategicBet) return;
    this.lastNotesValue = notes;
    this.strategyConversationContextService
      .updateBetContext$(this.strategicBet.contextId, notes, "Decision Map")
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: () => {
          this.loadStrategicMap();
          this.lastNotesValue = null;
        },
        error: () => {
          this.error = STRATEGIC_MAP_GENERATION_ERROR;
          this.cdr.markForCheck();
        },
      });
  }

  public stopStrategyGeneration(): void {
    this.generatingStrategicMap = false;
    this.resetError();
    this.cdr.markForCheck();
  }

  public nextStep(): void {
    this.goToStep(this.currentStepIndex + 1);
  }

  public closePage(): void {
    if (this.currentStepIndex > 0 && this.redirectingToWhiteboard) {
      this.stopOKRGeneration();
    }

    this.state.go("gtmhub.decisions");
  }

  public deleteBet(): void {
    this.modalService.confirm<ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData>(
      {
        uiTitle: "Delete decision?",
        uiContent: ConfirmDeleteStrategyItemComponent,
        uiData: {
          strategyItemName: this.strategicBet.name,
          strategyItemType: "bet",
        },
        uiOkText: "Delete decision",
        uiOkLoadingText: "Deleting decision...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          return firstValueFrom(
            this.strategicBetsService.deleteBet$(this.strategicBet.id).pipe(
              catchHttpError((error: HttpErrorResponse) => {
                this.uiError = this.errorHandlingService.getUIErrorData(error, "delete", "decision");
                throw this.decisionDeletionError;
              })
            )
          ).then(() => {
            this.state.go("gtmhub.decisions");
          });
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
  }

  private updateBetAccessAndCollaborators(updatedBet: StrategicBetVM): void {
    this.strategicBet.access = updatedBet.access;
    this.strategicBet.collaborators = updatedBet.collaborators;
    this.cdr.markForCheck();
  }

  public showInviteCollaborators(): void {
    const modalref = this.modalService.create({
      uiTitle: null,
      uiFooter: null,
      uiClosable: false,
      uiClassName: "no-padding",
      uiData: {
        inviteUsersToCollaborate$: (ids: string[]) => {
          return this.strategicBetsService
            .updateCollaborators$(this.strategicBet, ids)
            .pipe(takeOneUntilDestroyed(this))
            .pipe(
              map((bet) => bet),
              tap((updatedBet: StrategicBetVM) => {
                this.updateBetAccessAndCollaborators(updatedBet);
              })
            );
        },
        removeUsersFromCollaborators$: (id: string) => {
          return this.strategicBetsService.removeCollaborator$(this.strategicBet, id).pipe(
            tap((updatedBet: StrategicBetVM) => {
              this.updateBetAccessAndCollaborators(updatedBet);
            })
          );
        },
        closeModal: () => {
          modalref.close();
        },
        collaborators: this.strategicBet.collaborators,
        betId: this.strategicBet.id,
      },
      uiContent: InviteCollaboratorsModalComponent,
    });
  }

  public hideAlert(stepIndex: number): void {
    this.strategySettings = {
      ...this.strategySettings,
      [HINT_INDEX_MAP[stepIndex]]: true,
    };
    this.currentUserRepository.setUserSetting({ strategySettings: this.strategySettings });
    this.cdr.markForCheck();
  }

  public generateOKRs(): void {
    this.resetError();
    this.redirectingToWhiteboard = true;
    this.cdr.markForCheck();

    this.generateGoalsForBetMediator.triggerGoalGenerationForBet(this.betId);
  }

  public updateHypothesisGroomStatus({ areaId, hypothesisId, passes }: { areaId: string; hypothesisId: string; passes: boolean }): void {
    const hypothesis = this.strategicMap.areas.find((area) => area.id === areaId).hypotheses.find((hypothesis) => hypothesis.id === hypothesisId);
    hypothesis.passedGrooming = passes;
    this.cdr.markForCheck();
  }

  public finalizeGrooming({ done }: { done: boolean }): void {
    this.loadingBet = !done;
    if (done) {
      this.strategicBet.progressSubStep = 1;
    }
    this.cdr.markForCheck();
  }

  public resetOnePagerGenerationState(): void {
    this.onePagerGenerationFailed = false;
  }

  public generateOnePager(): void {
    this.onePagerGenerationFailed = false;
    this.onePagerGenerationInProgress = true;
    this.cdr.markForCheck();
    this.strategiesTrackingService.trackRegenerateOnePagerFlowInitiated(this.strategicBet.contextId);
    this.strategicBetsService.generateOnePager$(this.betId).subscribe({
      next: () => {
        this.state.go(".decisionMapSummary");
      },
      error: () => {
        this.onePagerGenerationFailed = true;
        this.onePagerGenerationInProgress = false;
        this.cdr.markForCheck();
      },
    });
  }

  public triggerOnePagerGeneration(): void {
    if (this.strategicBet) {
      if (this.strategicBet.onePagerContent) {
        this.modalService.confirm({
          uiTitle: "Generate summary",
          uiContent: `This decision has a generated summary. Do you wish to regenerate it or open the existing one?`,
          uiIconType: null,
          uiOkText: "Regenerate summary",
          uiOnOk: () => {
            this.generateOnePager();
          },
          uiCancelText: "Open existing one",
          uiOnCancel: () => {
            this.state.go(".decisionMapSummary");
          },
        });
      } else {
        this.state.go(".decisionMapSummary");
      }
    }
  }

  public navigateToWhiteboard(): void {
    const allHypothesis = this.strategicBet.strategicMap.areas.reduce((acc, area) => acc.concat(area.hypotheses), []);

    this.strategiesTrackingService.trackGoToWhiteboard(this.generatedWhiteboardId, "Decision Map", {
      flow_id: this.strategicBet.contextId,
      hypotheses_total: allHypothesis.length,
      hypotheses_completed: allHypothesis.filter((hypothesis) => hypothesis.status === VALIDATION_STATUS_COMPLETED).length,
    });

    this.state.go("gtmhub.whiteboard", { whiteboardId: this.generatedWhiteboardId });
  }

  public stopOKRGeneration(): void {
    this.decrementProgressStep();
    this.redirectingToWhiteboard = false;
    this.generatedWhiteboardId = null;
    this.resetError();
    this.cdr.markForCheck();
  }

  public openContextDrawer(): void {
    this.drawerService.create({
      uiContent: ContextDrawerComponent,
      uiSize: "large",
      uiWidth: "850px",
      uiHeight: "100%",
      uiContentParams: {
        contextId: this.betContext.id,
        userSettings: this.strategySettings,
        hideAlert: () => this.hideAlert(0),
        closeForm: () => {
          closeLastDrawer(this.drawerService);
          this.contextAsyncPreviewState$ = this.documentsMediator.getContextAsyncPreviewState$(this.betContext.id);
          this.cdr.markForCheck();
        },
      },
      uiOnCancel: () => {
        this.contextAsyncPreviewState$ = this.documentsMediator.getContextAsyncPreviewState$(this.betContext.id);
        this.cdr.markForCheck();
        return Promise.resolve(true);
      },
    });
  }

  public onContactSupportClick(): void {
    Intercom("show");
  }

  public exportPDF(): void {
    const conversationObservables: Observable<StrategyConversationVM>[] = [];
    const hypothesisNameConversationMap: Record<string, string> = {};
    for (let i = 0; i < this.strategicMap.areas.length; i++) {
      for (let j = 0; j < this.strategicMap.areas[i].hypotheses.length; j++) {
        const hypothesis = this.strategicMap.areas[i].hypotheses[j];
        if (hypothesis.status === VALIDATION_STATUS_COMPLETED) {
          hypothesisNameConversationMap[hypothesis.conversationId] = hypothesis.title;
          conversationObservables.push(
            this.conversationService.getConversation$(hypothesis.conversationId).pipe(
              filter((value) => !!value),
              takeOneUntilDestroyed(this)
            )
          );
        }
      }
    }
    this.exportPdfAttempts.next();

    combineLatest({
      conversations: forkJoin(conversationObservables),
      canExportConversationAsSearchablePDF: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ExportConversationAsSearchablePDF),
    }).subscribe(({ conversations, canExportConversationAsSearchablePDF }) => {
      this.exportConversationHTMLtoPDF({
        conversations,
        hypothesisNameConversationMap,
        canExportConversationAsSearchablePDF,
      });
    });
  }

  public leaveDecision(): void {
    this.modalService.confirm(
      {
        uiTitle: "Leave decision?",
        uiContent: `"${this.strategicBet.name}"<br /><br />You will not be able to open this decision again until the owner invites you again.`,
        uiOkText: "Leave decision",
        uiOkLoadingText: "Leaving decision...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          return firstValueFrom(
            this.strategicBetsService.leaveDecision$(this.betId).pipe(
              catchHttpError((error: HttpErrorResponse) => {
                this.uiError = this.errorHandlingService.getUIErrorData(error, "leave", "decision");
                throw this.decisionLeaveError;
              })
            )
          ).then(() => this.state.go("gtmhub.decisions"));
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
  }

  public reloadStrategicMap(): void {
    this.strategicBetsService
      .getBet$(this.betId)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe((bet) => {
        const currentAreas = this.strategicMap.areas.filter((area) => !area.saving);
        const currentAreaIds = currentAreas.map((area) => area.id);
        const newAreas = bet.strategicMap.areas.filter((area) => !currentAreaIds.includes(area.id));

        this.strategicMap = {
          ...this.strategicMap,
          areas: [...currentAreas, ...newAreas],
        };
        this.cdr.markForCheck();
      });
  }

  private goToStep(step: number): void {
    this.currentStepIndex = step;
    this.cdr.markForCheck();
  }

  private strategicMapGenerationErrored(): void {
    this.generatingStrategicMap = false;
    this.error = STRATEGIC_MAP_GENERATION_ERROR;
    this.cdr.markForCheck();
  }

  private loadStrategicMap(): void {
    this.resetError();
    this.generatingStrategicMap = true;
    this.strategicBetsService
      .getStrategicMapAsync$(this.betId)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (asyncResponse: AsyncResponseVM) => {
          this.generateStrategyTaskId = asyncResponse.taskId;
          this.generatingStrategicMap = false;
          this.goToStep(1);
          this.cdr.markForCheck();
        },
        error: () => this.strategicMapGenerationErrored(),
      });
    this.cdr.markForCheck();
  }

  private resetError(): void {
    this.error = null;
    this.cdr.markForCheck();
  }

  private decrementProgressStep(): void {
    this.generateGoalsForBetMediator.invalidateGoalGenerationStateForBet(this.betId);
  }

  private setupInitialState(strategyMapTasks: AsyncTaskVM[]): void {
    if (this.strategicBet.strategicMap && this.strategicBet.strategicMap.areas.length > 0) {
      this.strategicMap = this.strategicBet.strategicMap;
      this.goToStep(1);
    } else {
      const mapTask = getOngoingTaskForName(strategyMapTasks, "create_strategic_map");
      if (mapTask) {
        this.goToStep(1);
      }
    }
  }

  private subscribeToSocketChanges(): void {
    this.socketsService
      .onMessage$("createStrategyMapResponse")
      .pipe(untilDestroyed(this))
      .subscribe((message: QuantiveResultsSocketVM<"createStrategyMapResponse">) => {
        if ((this.generateStrategyTaskId && message.data.taskId === this.generateStrategyTaskId) || message.data.itemId === this.strategicBet.id) {
          if (message.data.status === "FAILURE") {
            this.generatingStrategicMap = false;
            this.error = STRATEGIC_MAP_GENERATION_ERROR;
            this.goToStep(0);
            this.cdr.markForCheck();
          } else {
            this.strategicBetsService
              .getBet$(this.betId)
              .pipe(takeOneUntilDestroyed(this))
              .subscribe({
                next: (bet: StrategicBetVM) => {
                  this.strategicMap = bet.strategicMap;
                  this.generatingStrategicMap = false;
                  this.goToStep(1);
                  this.cdr.markForCheck();
                },
                error: () => this.strategicMapGenerationErrored(),
              });
          }
        }
      });

    this.socketsService
      .onMessage$("createStrategyMapAreaResponse")
      .pipe(untilDestroyed(this))
      .subscribe((message: QuantiveResultsSocketVM<"createStrategyMapAreaResponse">) => {
        if (message.data.status === "SUCCESS") {
          if (message.data.itemId === this.betId) {
            this.reloadStrategicMap();
          }
        } else if (message.data.status === "FAILURE") {
          const firstLoadingArea = this.strategicMap.areas.find((area) => area.saving);
          if (firstLoadingArea) {
            firstLoadingArea.saving = false;
            firstLoadingArea.error = true;
            this.strategicMap = {
              ...this.strategicMap,
            };
            this.cdr.markForCheck();
          }
        }
      });

    this.socketsService
      .onMessage$("betContributorsModified")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        if (message.data.betId === this.betId) {
          if (message.data.removed?.length > 0 && message.data.removed.includes(userId.get())) {
            this.betNotAvaialbleForUser = true;
          } else {
            const currentCollaboratorsIds = this.strategicBet.collaborators.map((collaborator) => collaborator.userId);
            this.strategicBet.collaborators = this.strategicBet.collaborators
              .filter((collaborator) => {
                return !message.data.removed.includes(collaborator.userId);
              })
              .concat(
                message.data.added
                  .filter((id) => !currentCollaboratorsIds.includes(id))
                  .map((id) => ({
                    userId: id,
                    role: "collaborator",
                    uiState: "idle",
                  }))
              );
          }
          this.cdr.markForCheck();
        }
      });

    this.socketsService
      .onMessage$("contributorRemoved")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        const collaboratorIds = this.strategicBet.collaborators.map((collaborator) => collaborator.userId);
        if (collaboratorIds.includes(message.data.contributorId) && this.strategicBet.id === message.data.betId) {
          this.strategicBet.collaborators = this.strategicBet.collaborators.filter((collaborator) => collaborator.userId !== message.data.contributorId);
          this.cdr.markForCheck();
        }
      });

    this.socketsService
      .onMessage$("betRemoved")
      .pipe(untilDestroyed(this))
      .subscribe((message) => {
        if (message.data.betId === this.betId && this.strategicBet.ownerId !== userId.get()) {
          this.betNotAvaialbleForUser = true;
          this.cdr.markForCheck();
        }
      });
  }

  private exportConversationHTMLtoPDF({
    conversations,
    hypothesisNameConversationMap,
    canExportConversationAsSearchablePDF,
  }: {
    conversations: StrategyConversationVM[];
    hypothesisNameConversationMap: Record<string, string>;
    canExportConversationAsSearchablePDF: boolean;
  }): void {
    this.viewRef.clear();
    const componentRef = this.viewRef.createComponent(GenerateStrategyPageComponent, {
      environmentInjector: this.envInjector,
    });
    const mockedStrategicMapConversation = {
      id: SUMMARY_CONVERSATION_MOCK_ID,
      accountId: storage.get("accountId") as string,
      chat: [],
      results: [
        conversations.reduce((acc, conversation) => {
          const blockContent: TitleAndDescriptionDataVM = {
            description: hypothesisNameConversationMap[conversation.id],
            title: "Title",
            exportPagebreak: acc.length > 0,
          };
          // generate a header for each hypothesis
          // page break is only after the first one so we don't end up with a starting blank page
          const headerResultBlock = {
            id: `${conversation.id}-header`,
            blockType: StrategyResultBlockTypeVM.SummaryText,
            blockContent,
            isCreatedInThisSession: false,
          };
          return acc.concat([headerResultBlock, ...conversation.results]);
        }, []),
      ].flat(),
      reportId: MOCK_REPORT_ID,
    };
    componentRef.setInput("strategyActionsMode", {
      exportOKRs: false,
      close: false,
    });
    componentRef.setInput("disableStrategyActions", true);
    componentRef.setInput("contextId", this.betContext.id);
    componentRef.setInput("hideHeader", true);
    componentRef.setInput("hideChat", true);
    componentRef.setInput("conversationObject", mockedStrategicMapConversation);
    this.cdr.detectChanges();
    const intervalId = setInterval(() => {
      const element = document.getElementById("content-for-export");
      if (element) {
        const selectorsToRemove = [".skipped-pdf-content", ".free-text .placeholder"];
        selectorsToRemove.forEach((selector) => {
          const elements = element.querySelectorAll(selector);
          elements?.forEach((el) => el.remove());
        });

        if (canExportConversationAsSearchablePDF) {
          this.conversationExporterService.exportConversationAsPDF$({ htmlForExport: element.outerHTML }).subscribe({
            next: () => {
              this.viewRef.clear();
            },
          });
        } else {
          this.exportService.exportReport(element, this.betContext.id, () => {
            this.viewRef.clear();
          });
        }

        clearInterval(intervalId);
      }
    }, 1000);
  }
}
