import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "page-wrapper",
  templateUrl: "./page-wrapper.component.html",
  styleUrls: ["page-wrapper.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWrapperComponent {}
