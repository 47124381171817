import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { AccountResolverService } from "@webapp/accounts";
import { DomainService } from "@webapp/accounts/services/domain/domain.service";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";

@UntilDestroy()
@Component({
  selector: "account-marketing-info-screen",
  templateUrl: "./account-marketing-info-screen.component.html",
  styleUrls: ["./account-marketing-info-screen.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMarketingInfoScreenComponent implements OnInit {
  public isAccountMarketingInfoFormVisible: boolean;

  constructor(
    private domainService: DomainService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private accountResolverService: AccountResolverService,
    private state: StateService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    combineLatest([this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.AccountMarketingInfo), this.accountResolverService.shouldShowAccountDetailsForm()])
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(([featureEnabled, shouldShowForm]) => {
        this.isAccountMarketingInfoFormVisible = featureEnabled && shouldShowForm;
        this.cdr.markForCheck();

        if (!this.isAccountMarketingInfoFormVisible) {
          this.state.go("gtmhub.home", null, { location: "replace" });
        }
      });
  }

  public onAccountUpdated(account: IAccount): void {
    const domain = this.domainService.constructGtmhubDomain(account.domain);

    window.open(`${location.protocol}//${domain}/`, "_self");
  }
}
