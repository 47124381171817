// eslint-disable-next-line no-restricted-imports
import dayjs, { Dayjs, ManipulateType, QUnitType, UnitTypeLong } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localizedFormat from "./plugins/localized-format";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isBetween);
dayjs.extend(minMax);
dayjs.extend(quarterOfYear);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(tz);

export type { QUnitType, ManipulateType, Dayjs, UnitTypeLong };
export default dayjs;
