<section
  class="chat-block-ai"
  #chatBlock
  [ngClass]="{
    loading: message.blockType === 'loading' && !isSuggested,
  }">
  <section class="chat-block-content" *ngIf="message.blockType !== 'error'">
    <nav class="selection-insert" *featureToggleEnabled="'chat-text-selection-insert'" [ngStyle]="insertButtonStyle">
      <button (click)="insertSelectedText()" ui-button uiShape="round" uiSize="default" uiType="link">
        <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="plus"></i>
        <span>Insert</span>
      </button>
    </nav>

    <section class="ai-icon">
      <i class="fs-16px icon" ui-icon uiTheme="outline" uiType="artificial-intelligence"></i>
    </section>

    <section class="chat-block-ai-content" (mouseup)="onTextSelection()">
      <ui-ai-chat-swot-block class="swot-analysis" *ngIf="message.blockType === 'swot'" [swot]="swotBlockContent" />
      <ui-ai-chat-ansoff-block class="ansoff-matrix" *ngIf="message.blockType === 'ansoff'" [ansoff]="ansoffBlockContent" />
      <ui-ai-chat-porter-block class="porter-five-forces" *ngIf="message.blockType === 'porter'" [porter]="porterBlockContent" />
      <ui-ai-chat-pest-block class="pest-analysis" *ngIf="message.blockType === 'pest'" [pest]="pestBlockContent" />
      <ui-ai-chat-balanced-scorecard-block class="balanced-scorecard" *ngIf="message.blockType === 'balanced_scorecard'" [balancedScorecard]="balancedScorecardBlockContent" />
      <ui-ai-chat-blue-ocean-block class="blue-ocean" *ngIf="message.blockType === 'blue_ocean'" [blueOcean]="blueOceanBlockContent" />
      <ui-ai-chat-goals-block *ngIf="message.blockType === 'goals'" [goalsBlockContent]="goalsBlockContent" />
      <ui-ai-chat-kpis-block *ngIf="message.blockType === 'kpis'" [kpisBlockContent]="kpisBlockContent" />

      <section class="free-text" *ngIf="message.blockType === 'free_text'">
        {{ textBlockContent.text }}
      </section>
      <text-references *ngIf="(config$ | async)?.showReferences && textBlockContent?.references?.length > 0" [references]="textBlockContent?.references"> </text-references>
      <ui-chat-loading-block class="chat-loading-block" *ngIf="message?.blockType === 'loading'" [message]="message" [showSkeletonLoading]="!isSuggested"></ui-chat-loading-block>
    </section>
  </section>

  <section class="error-block-content" *ngIf="message.blockType === 'error'">
    <section class="content-area">
      <i class="fs-16px" ui-icon uiType="caution"></i>
      <p class="error-text">Unable to answer. Please try again.</p>
    </section>
    <section class="action-area">
      <button (click)="retryErroredMessage.emit()" ui-button uiShape="round" uiType="link">
        <i class="fs-16px" ui-icon uiType="refresh"></i>
        <span>Try again</span>
      </button>
    </section>
  </section>

  <ui-chat-block-actions
    *ngIf="message.blockType !== 'loading' && message.blockType !== 'error'"
    [chatEntry]="message"
    [feedbackScore]="message.feedback?.score"
    [showRegenerate]="showRegenerate"
    (addContent)="addContent.emit(message)"
    (provideFeedback)="provideFeedback.emit($event)"
    (refreshContent)="refreshContent.emit()" />
</section>
