import { Injectable } from "@angular/core";
import { Observable, map, tap } from "rxjs";
import { IdMap } from "@gtmhub/util";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BulkUpdateKpisResponse } from "../../tags/models/tag.model";
import { KpiEventsMediatorService } from "../components/kpis-grid-page/kpis-grid/services/kpi-events.mediator.service";
import { BulkOwnerKpiPayload } from "../components/kpis-grid-page/kpis-grid/utils/grid.utils";
import { KpisStatsDataForTimeRangePayload } from "../components/kpis-grid-page/models";
import { GroupedKpis, Kpi, KpiDTO, ModifiableExtendedKpi, ModifiableKpi } from "../models/kpis.models";
import { KpisApiService } from "./kpis-api.service";
import { KpisState } from "./kpis-state.service";

@Injectable({
  providedIn: "any",
})

// TODO: fix models
export class KpisFacade extends BaseFacade<Kpi, KpiDTO, KpisState, KpisApiService> {
  constructor(
    state: KpisState,
    api: KpisApiService,
    private kpiEventsMediator: KpiEventsMediatorService
  ) {
    super(state, api);
  }

  public getGroupedKpis(fields: string[], filters?: RequestPaging): Observable<GroupedKpis> {
    return this.apiService.getAll$<GroupedKpis>(filters, {
      ...new RequestConfig(),
      url: this.apiService.getKpisGroupingEndpoint(fields),
    });
  }

  public patchKpis$(patchableKpis: IdMap<ModifiableExtendedKpi>): Observable<unknown> {
    return this.apiService.patch$(null, { kpis: patchableKpis }, { url: this.apiService.getBasePath(HttpActions.patch) }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public patchKpi$(kpiId: string, kpi: ModifiableExtendedKpi): Observable<unknown> {
    return this.apiService.patch$(kpiId, kpi, { url: this.apiService.getPatchKpiEndpoint(kpiId) }).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public postKpi$(kpi: ModifiableKpi): Observable<unknown> {
    return this.apiService.post$(kpi).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public deleteKpi$(kpiId: string): Observable<unknown> {
    return this.apiService.delete$(kpiId).pipe(
      tap(() => {
        this.kpiEventsMediator.emitKpisChanged();
      })
    );
  }

  public getKpisStatsForTimeRange$(payload: KpisStatsDataForTimeRangePayload): Observable<unknown> {
    return this.apiService
      .post$(payload, {
        ...new RequestConfig(),
        url: this.apiService.getKpisStatsForTimeRangeEndpoint(),
      })
      .pipe(map((value) => value || { kpisStats: [] }));
  }

  public bulkPatchKpiTags$(kpiIds: string[], tagsToAdd?: string[], tagsToRemove?: string[]): Observable<BulkUpdateKpisResponse> {
    const tagsPayload: { tagsToAdd?: { titles: string[] }; tagsToRemove?: string[] } = {};
    if (tagsToAdd && tagsToAdd.length > 0) {
      tagsPayload.tagsToAdd = { titles: tagsToAdd };
    }
    if (tagsToRemove && tagsToRemove.length > 0) {
      tagsPayload.tagsToRemove = tagsToRemove;
    }
    return this.apiService
      .patch$<BulkUpdateKpisResponse>("", { ids: kpiIds, ...tagsPayload }, { ...new RequestConfig(), url: this.apiService.getPatchBulkTagsOperationEndpoint() })
      .pipe(
        tap(() => {
          this.kpiEventsMediator.emitKpisChanged();
        })
      );
  }

  public bulkPatchKpiOwnerIds$(payload: BulkOwnerKpiPayload): Observable<BulkUpdateKpisResponse> {
    return this.apiService
      .patch$<BulkUpdateKpisResponse>("", { kpis: payload }, { url: this.apiService.getBasePath(HttpActions.patch) })
      .pipe(tap(() => this.kpiEventsMediator.emitKpisChanged()));
  }
}
