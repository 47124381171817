// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
.text-color-primary-1 {
  color: #e5f2ff !important;
}
.text-color-success-1 {
  color: #e3fcef !important;
}
.text-color-error-1 {
  color: #fff2f2 !important;
}
.text-color-warning-1 {
  color: #fffae7 !important;
}
.text-color-gray-1 {
  color: #fff !important;
}
.text-color-purple-1 {
  color: #f0eefe !important;
}
.text-color-red-1 {
  color: #fff2f2 !important;
}
.text-color-primary-2 {
  color: #d3eaff !important;
}
.text-color-success-2 {
  color: #caf1e8 !important;
}
.text-color-error-2 {
  color: #fde6e6 !important;
}
.text-color-warning-2 {
  color: #fff6a3 !important;
}
.text-color-gray-2 {
  color: #f1f3f8 !important;
}
.text-color-purple-2 {
  color: #f6f0ff !important;
}
.text-color-red-2 {
  color: #fde6e6 !important;
}
.text-color-primary-3 {
  color: #aed2fe !important;
}
.text-color-success-3 {
  color: #afefe0 !important;
}
.text-color-error-3 {
  color: #ffd8d8 !important;
}
.text-color-warning-3 {
  color: #fff0b3 !important;
}
.text-color-gray-3 {
  color: #d8dfea !important;
}
.text-color-purple-3 {
  color: #d3caff !important;
}
.text-color-red-3 {
  color: #ffd8d8 !important;
}
.text-color-primary-4 {
  color: #9bbeff !important;
}
.text-color-success-4 {
  color: #60dbb0 !important;
}
.text-color-error-4 {
  color: #ffd4d1 !important;
}
.text-color-warning-4 {
  color: #ffe252 !important;
}
.text-color-gray-4 {
  color: #c0cbdd !important;
}
.text-color-purple-4 {
  color: #d9cef2 !important;
}
.text-color-red-4 {
  color: #ffd4d1 !important;
}
.text-color-primary-5 {
  color: #75a2f7 !important;
}
.text-color-success-5 {
  color: #66dec1 !important;
}
.text-color-error-5 {
  color: #ffb1b1 !important;
}
.text-color-warning-5 {
  color: #ffe380 !important;
}
.text-color-gray-5 {
  color: #9dabc5 !important;
}
.text-color-purple-5 {
  color: #ac9ef2 !important;
}
.text-color-red-5 {
  color: #ffb1b1 !important;
}
.text-color-primary-6 {
  color: #417aea !important;
}
.text-color-success-6 {
  color: #18c191 !important;
}
.text-color-error-6 {
  color: #f67c7c !important;
}
.text-color-warning-6 {
  color: #ffc400 !important;
}
.text-color-gray-6 {
  color: #8393ae !important;
}
.text-color-purple-6 {
  color: #8d73d9 !important;
}
.text-color-red-6 {
  color: #f67c7c !important;
}
.text-color-primary-7 {
  color: #2d5bc4 !important;
}
.text-color-success-7 {
  color: #0b9c77 !important;
}
.text-color-error-7 {
  color: #cf5d61 !important;
}
.text-color-warning-7 {
  color: #d99f00 !important;
}
.text-color-gray-7 {
  color: #7485a2 !important;
}
.text-color-purple-7 {
  color: #6a56b3 !important;
}
.text-color-red-7 {
  color: #cf5d61 !important;
}
.text-color-primary-8 {
  color: #0057d7 !important;
}
.text-color-success-8 {
  color: #00a688 !important;
}
.text-color-error-8 {
  color: #da555d !important;
}
.text-color-warning-8 {
  color: #eea000 !important;
}
.text-color-gray-8 {
  color: #617294 !important;
}
.text-color-purple-8 {
  color: #7e5fbf !important;
}
.text-color-red-8 {
  color: #da555d !important;
}
.text-color-primary-9 {
  color: #102878 !important;
}
.text-color-success-9 {
  color: #004f41 !important;
}
.text-color-error-9 {
  color: #c1333b !important;
}
.text-color-warning-9 {
  color: #e7820b !important;
}
.text-color-gray-9 {
  color: #445573 !important;
}
.text-color-purple-9 {
  color: #302766 !important;
}
.text-color-red-9 {
  color: #c1333b !important;
}
.text-color-primary-10 {
  color: #003a7b !important;
}
.text-color-success-10 {
  color: #01837b !important;
}
.text-color-error-10 {
  color: #b14055 !important;
}
.text-color-warning-10 {
  color: #bd6d0e !important;
}
.text-color-gray-10 {
  color: #283d5c !important;
}
.text-color-purple-10 {
  color: #603fa5 !important;
}
.text-color-red-10 {
  color: #b14055 !important;
}
.text-color-primary-12 {
  color: #002c5f !important;
}
.text-color-success-12 {
  color: #006c66 !important;
}
.text-color-warning-12 {
  color: #a9600a !important;
}
.text-color-gray-11 {
  color: #20354f !important;
}
.text-color-gray-12 {
  color: #0d232f !important;
}
.text-color-gray-13 {
  color: #021421 !important;
}
.text-color-white {
  color: #fff !important;
}
/* Primary Colors */
.bg-color-primary-1 {
  background-color: #e5f2ff;
}
.bg-color-primary-2 {
  background-color: #d3eaff;
}
.bg-color-primary-3 {
  background-color: #aed2fe;
}
.bg-color-primary-4 {
  background-color: #9bbeff;
}
.bg-color-primary-5 {
  background-color: #75a2f7;
}
.bg-color-primary-6 {
  background-color: #417aea;
}
.bg-color-primary-7 {
  background-color: #2d5bc4;
}
.bg-color-primary-8 {
  background-color: #0057d7;
}
.bg-color-primary-9 {
  background-color: #102878;
}
.bg-color-primary-10 {
  background-color: #003a7b;
}
.bg-color-primary-12 {
  background-color: #002c5f;
}
/* success colors */
.bg-color-success-1 {
  background-color: #e3fcef;
}
.bg-color-success-2 {
  background-color: #caf1e8;
}
.bg-color-success-3 {
  background-color: #afefe0;
}
.bg-color-success-4 {
  background-color: #60dbb0;
}
.bg-color-success-5 {
  background-color: #66dec1;
}
.bg-color-success-6 {
  background-color: #18c191;
}
.bg-color-success-7 {
  background-color: #0b9c77;
}
.bg-color-success-8 {
  background-color: #00a688;
}
.bg-color-success-9 {
  background-color: #004f41;
}
.bg-color-success-10 {
  background-color: #01837b;
}
.bg-color-success-12 {
  background-color: #006c66;
}
/* error colors */
.bg-color-error-1 {
  background-color: #fff2f2;
}
.bg-color-error-2 {
  background-color: #fde6e6;
}
.bg-color-error-3 {
  background-color: #ffd8d8;
}
.bg-color-error-4 {
  background-color: #ffd4d1;
}
.bg-color-error-5 {
  background-color: #ffb1b1;
}
.bg-color-error-6 {
  background-color: #f67c7c;
}
.bg-color-error-7 {
  background-color: #cf5d61;
}
.bg-color-error-8 {
  background-color: #da555d;
}
.bg-color-error-9 {
  background-color: #c1333b;
}
.bg-color-error-10 {
  background-color: #b14055;
}
/* warning colors */
.bg-color-warning-1 {
  background-color: #fffae7;
}
.bg-color-warning-2 {
  background-color: #fff6a3;
}
.bg-color-warning-3 {
  background-color: #fff0b3;
}
.bg-color-warning-4 {
  background-color: #ffe252;
}
.bg-color-warning-5 {
  background-color: #ffe380;
}
.bg-color-warning-6 {
  background-color: #ffc400;
}
.bg-color-warning-7 {
  background-color: #d99f00;
}
.bg-color-warning-8 {
  background-color: #eea000;
}
.bg-color-warning-9 {
  background-color: #e7820b;
}
.bg-color-warning-10 {
  background-color: #bd6d0e;
}
.bg-color-warning-12 {
  background-color: #a9600a;
}
/* grayscale colors */
.bg-color-gray-1,
.bg-color-white {
  background-color: #fff;
}
.bg-color-gray-2 {
  background-color: #f1f3f8;
}
.bg-color-gray-3 {
  background-color: #d8dfea;
}
.bg-color-gray-4 {
  background-color: #c0cbdd;
}
.bg-color-gray-5 {
  background-color: #9dabc5;
}
.bg-color-gray-6 {
  background-color: #8393ae;
}
.bg-color-gray-7 {
  background-color: #7485a2;
}
.bg-color-gray-8 {
  background-color: #617294;
}
.bg-color-gray-9 {
  background-color: #445573;
}
.bg-color-gray-10 {
  background-color: #283d5c;
}
.bg-color-gray-11 {
  background-color: #20354f;
}
.bg-color-gray-12 {
  background-color: #0d232f;
}
.bg-color-gray-13 {
  background-color: #021421;
}
/* purple colors */
.bg-color-purple-1 {
  background-color: #f0eefe;
}
.bg-color-purple-2 {
  background-color: #f6f0ff;
}
.bg-color-purple-3 {
  background-color: #d3caff;
}
.bg-color-purple-4 {
  background-color: #d9cef2;
}
.bg-color-purple-5 {
  background-color: #ac9ef2;
}
.bg-color-purple-6 {
  background-color: #8d73d9;
}
.bg-color-purple-7 {
  background-color: #6a56b3;
}
.bg-color-purple-8 {
  background-color: #7e5fbf;
}
.bg-color-purple-9 {
  background-color: #302766;
}
.bg-color-purple-10 {
  background-color: #f0eefe;
}
.fs-4px {
  font-size: 4px !important;
}
.fs-5px {
  font-size: 5px !important;
}
.fs-6px {
  font-size: 6px !important;
}
.fs-7px {
  font-size: 7px !important;
}
.fs-8px {
  font-size: 8px !important;
}
.fs-9px {
  font-size: 9px !important;
}
.fs-10px {
  font-size: 10px !important;
}
.fs-11px {
  font-size: 11px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-15px {
  font-size: 15px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-20px {
  font-size: 20px !important;
}
.fs-22px {
  font-size: 22px !important;
}
.fs-24px {
  font-size: 24px !important;
}
.fs-25px {
  font-size: 25px !important;
}
.fs-30px {
  font-size: 30px !important;
}
.fs-32px {
  font-size: 32px !important;
}
.fs-35px {
  font-size: 35px !important;
}
.fs-36px {
  font-size: 36px !important;
}
.fs-40px {
  font-size: 40px !important;
}
.fs-48px {
  font-size: 48px !important;
}
.fs-64px {
  font-size: 64px !important;
}
.fs-72px {
  font-size: 72px !important;
}
detailed-strategy-page-component {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 100vh;
  background: #fff;
}
nav {
  display: flex;
  padding: 6px 0;
  justify-content: flex-end;
  gap: 12px;
  align-self: stretch;
}
.detailed-strategy-page-header {
  display: flex;
  width: 800px;
  max-width: 800px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}
.detailed-strategy-page-header .ask-container {
  display: flex;
  padding: 4px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
}
.detailed-strategy-page-header .ask-container .place-bet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.detailed-strategy-page-header .ask-container .place-bet-container .predefined-questions,
.detailed-strategy-page-header .ask-container .place-bet-container .ask-form {
  max-width: 600px;
}
.detailed-strategy-page-header .ask-container .place-bet-container .predefined-questions {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}
.detailed-strategy-page-header .ask-container .place-bet-container .predefined-questions .smart-shortcut {
  display: flex;
  max-width: 300px;
  height: auto;
  padding: 7px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #d8dfea;
  color: #445573;
  background: #fff;
  font-family: "SF Pro Text", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: normal;
}
.detailed-strategy-page-header .ask-container .place-bet-container .predefined-questions .smart-shortcut:hover {
  background: rgba(68, 85, 115, 0.08);
  border: 1px solid #d8dfea;
}
.detailed-strategy-page-header .ask-container .place-bet-container .predefined-questions .smart-shortcut:focus,
.detailed-strategy-page-header .ask-container .place-bet-container .predefined-questions .smart-shortcut:focus-visible {
  background: rgba(68, 85, 115, 0.08);
  border: 1px solid #0057d7;
}
.detailed-strategy-page-header .ask-container .place-bet-container .ask-form {
  display: flex;
  min-width: 250px;
  max-width: 600px;
  width: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.detailed-strategy-page-header .ask-container .place-bet-container .ask-form .ask-textarea {
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid #c0cbdd;
  color: #021421;
  font-family: "SF Pro Text", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  outline: none;
}
.detailed-strategy-page-header .ask-container .place-bet-container .ask-form .ask-textarea:hover {
  border-color: #8393ae;
}
.detailed-strategy-page-header .ask-container .place-bet-container .ask-form .ask-textarea:focus-visible,
.detailed-strategy-page-header .ask-container .place-bet-container .ask-form .ask-textarea:focus {
  border-color: #0057d7;
  outline: none;
}
.detailed-strategy-page-header .ask-container .place-bet-container .ask-form .actions {
  display: flex;
  padding: 8px 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 16px;
}
:host .results-content-container {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
}
:host .results-content-container .results-content {
  display: flex;
  max-width: 800px;
  padding: 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  flex-direction: row;
  justify-content: center;
  border-radius: 0px;
}
.strategy-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #fff;
}
.strategy {
  background: #fff;
  display: flex;
}
.strategy .strategy-page-container {
  display: flex;
  flex: 1;
}
.strategy .results {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  flex: 1 0 50%;
  align-self: stretch;
  border-radius: 0px;
  border-right: 1px solid #d8dfea;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(63, 87, 110, 0.2);
}
.strategy .results .results-header {
  display: flex;
  align-self: stretch;
}
.strategy .results .results-content-container {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: 24px 0;
  justify-content: center;
  flex-direction: row;
}
.strategy .results .results-content-container .alert {
  padding: 0 24px;
  width: 800px;
}
.strategy .results .results-content-container .results-content {
  display: flex;
  max-width: 800px;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  flex-direction: row;
  justify-content: center;
  border-radius: 0px;
}
.strategy .results .results-actions {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 0px;
  background: #fff;
  flex-direction: row;
  justify-content: center;
}
.strategy .chat {
  display: flex;
  min-width: 430px;
  max-width: 620px;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 0px;
  background: #f1f3f8;
}
.strategy .chat .chat-content {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0px;
}
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-state .error-container {
  border: none;
  min-width: 512px;
}
.area-not-available {
  display: flex;
  flex: 1 0 0;
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  background: #fff;
  height: 100%;
}
.delete-modal-link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}
.alert-results-section {
  display: flex;
  flex-direction: column;
}
.no-alert-results-section {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  padding: 16px 0;
  width: 100%;
  overflow-y: auto;
  justify-content: center;
  flex-direction: row;
}
.result-container {
  display: flex;
  flex: 1 0 0;
  padding: 2px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}
.result-container.highlighted {
  background: #fff0b3;
}
.result-container:hover .result-block-actions {
  opacity: 1;
}
.result-container .result-block-data {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  padding: 2px 4px;
}
.result-container .result-block-data .ansoff-analysis,
.result-container .result-block-data .swot-analysis,
.result-container .result-block-data .pest-analysis,
.result-container .result-block-data .porter-analysis,
.result-container .result-block-data .blue-ocean-analysis,
.result-container .result-block-data .balanced-scorecard-analysis,
.result-container .result-block-data .free-text {
  display: flex;
  flex: 1 0 0;
  align-self: stretch;
}
.result-container .result-block-data .swot-analysis {
  padding-top: 4px;
}
.result-container .result-block-data .free-text {
  white-space: pre-line;
}
.result-container .result-block-actions {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0px;
  align-items: flex-start;
  gap: 10px;
  opacity: 0;
}
.result-container .result-block-actions .result-block-dropdown button {
  width: 24px;
  height: 24px;
  border-radius: 1000px;
}
.result-box-container {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  border-top: 2px solid #f1f3f8;
  border-right: 2px solid #f1f3f8;
  border-bottom: 2px solid #f1f3f8;
  border-left: 2px solid #f1f3f8;
  padding: 12px 16px;
}
.result-box-container .result-box-container-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  padding: 2px;
}
.result-box-container .result-box-container-header h4 {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  color: #021421;
  font-family: "SF Pro Text", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
}
.result-box-container .result-box-container-header .labels {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.result-box-container .data-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin: 0;
  padding: 0;
}
.result-box-container .data-list li {
  color: #021421;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  padding: 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
span {
  display: flex;
  height: 18px;
  padding: 0px 6px 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: #283d5c;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
}
.positive {
  background: #afefe0;
}
.external,
.internal {
  background: #d8dfea;
}
.negative {
  background: #ffd8d8;
}
.existing_products,
.existing_markets {
  background: #aed2fe;
}
.new_products,
.new_markets {
  background: #d3caff;
}
.labels-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
:host,
ansoff-result-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.result-box {
  display: flex;
  min-width: 310px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background: #fff;
  align-self: flex-start;
}
:host,
balanced-scorecard-result-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.result-box {
  display: flex;
  min-width: 310px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background: #fff;
  align-self: flex-start;
}
:host,
blue-ocean-result-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.result-box {
  display: flex;
  min-width: 310px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background: #fff;
  align-self: flex-start;
}
:host,
pest-result-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.result-box {
  display: flex;
  min-width: 310px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background: #fff;
  align-self: flex-start;
}
:host,
porter-result-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.result-box {
  display: flex;
  min-width: 310px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background: #fff;
  align-self: flex-start;
}
:host,
swot-result-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.result-box {
  display: flex;
  min-width: 310px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background: #fff;
  align-self: flex-start;
}
:host,
title-description-result-block {
  display: flex;
  flex: 1 0 0;
  flex-wrap: wrap;
  gap: 12px;
}
article {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 4px;
}
article header {
  color: #021421;
  /* H3-heading */
  font-family: "SF Pro Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  /* 125% */
  letter-spacing: 0.4px;
}
article p {
  color: #021421;
  /* paragraph_md */
  font-family: "SF Pro Text", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}
body {
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
