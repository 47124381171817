import { Component, OnInit } from "@angular/core";
import { first } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { NavItemsSectionBroadcastConfig } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { SearchFacetsOptions, SearchFacetsOptionsEnum } from "@webapp/search/models/search.models";
import { BaseSubNav } from "../services/base-sub-nav";
import { NavItemsSectionBroadcastConfigFactory } from "../services/nav-items-section-broadcast-config.factory";

@Component({
  selector: "kpis-sub-navigation",
  templateUrl: "./kpis-sub-navigation.component.html",
})
export class KpisSubNavigationComponent extends BaseSubNav implements OnInit {
  public options: SubNavOption[] = [];
  public broadcastConfig: NavItemsSectionBroadcastConfig = this.broadcastConfigFactory.buildKpiNavItemsSectionBroadcastConfig();
  public readonly searchFacet: SearchFacetsOptions = SearchFacetsOptionsEnum.KPIs;

  public constructor(
    protected navigationItemsMediator: NavigationItemsMediator,
    private broadcastConfigFactory: NavItemsSectionBroadcastConfigFactory,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {
    super();
    this.setupEntityTypes(["kpigroup", "kpiview"], "kpis");
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.KpiRevampBetaFirstIteration)
      .pipe(first())
      .subscribe((kpisGridEnabled) => {
        if (!kpisGridEnabled) {
          this.options = [{ label: "all_kpis", state: "gtmhub.kpis.list", icon: "kpi" }];
          return;
        }

        this.options = [
          {
            label: "kpi_views",
            state: "gtmhub.kpis.views.list",
            icon: "filter-view",
          },
          {
            label: "KPIs (Beta)",
            state: "gtmhub.kpis.grid",
          },
        ];
      });
  }
}
