import { NgModule } from "@angular/core";
import { ButtonComponent } from "@webapp/shared/components/button/button.component";
import { InputSearchModule } from "@webapp/shared/components/input-search/input-search.module";
import { ItemAutomationModule } from "@webapp/shared/components/item-automation/item-automation.module";
import { DateViewModule } from "@webapp/shared/date-view/date-view.module";
import { FeatureBannerModule } from "@webapp/shared/feature-banner/feature-banner.module";
import { SkipLinkComponent } from "@webapp/shared/skip-link/skip-link.component";
import { UiModalModule } from "@webapp/ui/modal/modal.module";
import { UiToastModule } from "@webapp/ui/toast/toast.module";
import { AssigneeAvatarGroupComponent } from "./components/assignee-avatar-group/assignee-avatar-group.component";
import { BadgeAvatarComponent } from "./components/badge-avatar/badge-avatar.component";
import { ButtonSelectModule } from "./components/button-select/button-select.module";
import { DatePickerLegacyModule } from "./components/date-picker-legacy/date-picker-legacy.module";
import { GifViewModule } from "./components/gifs/gif-view.module";
import { IconWithLabelModule } from "./components/icon-with-label/icon-with-label.module";
import { IconModule } from "./components/icon/icon.module";
import { OnboardingModule } from "./components/onboarding/onboarding.module";
import { PlatformRedirectComponent } from "./components/platform-redirect/platform-redirect.component";
import { IconWithPopoverModule } from "./icon-with-popover/icon-with-popover.module";
import { IndicatorModule } from "./indicator/indicator.module";
import { EditInsightModule } from "./insights/edit-modal/edit-insight.module";
import { SharedModalModule } from "./modal/shared-modal.module";
import { PaginationComponent } from "./pagination/pagination.component";
import { UnitSelectorModule } from "./unit-selector/unit-selector.module";

/**
 * Include only components that are downgraded in the shared module
 */
@NgModule({
  imports: [
    UnitSelectorModule,
    IconWithPopoverModule,
    IndicatorModule,
    GifViewModule,
    UiModalModule,
    UiToastModule,
    OnboardingModule,
    DatePickerLegacyModule,
    EditInsightModule,
    ButtonSelectModule,
    IconWithLabelModule,
    InputSearchModule,
    FeatureBannerModule,
    ItemAutomationModule,
    IconModule,
    DateViewModule,
    ButtonComponent,
    SharedModalModule,
    AssigneeAvatarGroupComponent,
    BadgeAvatarComponent,
    PaginationComponent,
    SkipLinkComponent,
    PlatformRedirectComponent,
  ],
})
export class DowngradedSharedModule {}
