<ui-loading-indicator class="loading-container" [uiSpinning]="loadingContext || checkingForAsyncGeneration" [uiTip]="'Loading Objective generation...'">
  <article class="context-container" *ngIf="context && !error">
    <header>
      <section class="content" id="context-title" [uiTooltipTitle]="context.name || 'Context'" tooltipIfOverflow="hover" ui-tooltip>
        {{ context.name || "Upload Files" }}
      </section>

      <section class="actions" *ngIf="!redirectingToWhiteboard">
        <dropdown-menu-with-trigger menuButtonIconType="menu-vertical" ui-tooltip uiTooltipTitle="More">
          <ul class="bet-dropdown-menu" ui-menu>
            <li (click)="deleteContext(context.name)" role="presentation" ui-menu-item>
              <i class="fs-16px sp-m-2" ui-icon uiType="trash-bin"> </i>
              <span>Delete Objective generation</span>
            </li>
          </ul>
        </dropdown-menu-with-trigger>
        <button (click)="closePage()" ui-button ui-tooltip uiShape="round" uiTooltipTitle="Close" uiType="default">
          <i ui-icon uiTheme="outline" uiType="close-big"></i>
        </button>
      </section>
    </header>
    <section class="content-container" *ngIf="!redirectingToWhiteboard">
      <section class="alert">
        @if (!strategySettings["contextHintSeen"] && !showContextFormError) {
          <alert-message
            (dismissAlert)="hideAlert()"
            buttonLabel="Okay, got it"
            message="Upload files and provide Quantive Strategies with additional context for generating Objectives."
            type="info" />
        }
      </section>
      <section class="alert">
        <alert-message *ngIf="showContextFormError" message="Fix the error to generate Objectives." type="error" />
      </section>
      <context-form
        class="content"
        [(documentsAreBeingProcessed)]="documentsAreBeingProcessed"
        [contextId]="context.id"
        [filesRequired]="true"
        [form]="contextForm"
        [loadingContext]="!context"
        [showErrors]="showContextFormError"
        (formSubmitted)="exportOKRsForContext()"
        (notesChanged)="notesValueChanged($event)"
        additionalContextDescription="Additional clarifications such as company mission and vision or related to the Objectives you want to generate.">
      </context-form>
      <section class="actions">
        <button [disabled]="documentsAreBeingProcessed || !contextSummarized" (click)="exportOKRsForContext()" type="submit" ui-button uiShape="round" uiSize="large" uiType="primary">
          Generate Objectives
        </button>
      </section>
    </section>
    <whiteboard-generation
      class="whiteboard-generation-page"
      *ngIf="redirectingToWhiteboard"
      [generatedWhiteboardId]="generatedWhiteboardId"
      (navigateToWhiteboard)="navigateToWhiteboard()"
      (stopOKRGeneration)="stopOKRGeneration()"
      backButtonCaption="Back to context"></whiteboard-generation>
  </article>
  <error-page
    *ngIf="error === whiteboardGenerationErrorValue && whiteboardGenerationError !== whiteboardGenerationErrorValue"
    [backButtonCaption]="'Back'"
    [errorMessage]="whiteboardGenerationError"
    (back)="stopOKRGeneration()"
    (retry)="generateOKRs()"></error-page>
  <error-page
    *ngIf="error === whiteboardGenerationErrorValue && whiteboardGenerationError === whiteboardGenerationErrorValue"
    [backButtonCaption]="'Back'"
    [errorMessage]="strategicMapLoadError"
    (back)="stopOKRGeneration()"
    (retry)="generateOKRs()"></error-page>
  <error-page *ngIf="error === contextLoadError" [backButtonCaption]="'Back'" [errorMessage]="filesLoadError" (back)="closePage()" (retry)="loadContext()"></error-page>
</ui-loading-indicator>

<ng-template #strategicMapLoadError>
  <p class="deletion-error sp-mb-0">
    Unable to prepare whiteboard and suggest Objectives. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>

<ng-template #filesLoadError>
  <p class="deletion-error sp-mb-0">
    Unable to load Objective generation. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
<ng-template #deletionError>
  <p class="deletion-error sp-mb-0">
    Unable to delete Objective generation. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
