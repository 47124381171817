// eslint-disable-next-line @typescript-eslint/naming-convention
type IntercomFn = (action: IntercomAction, opts?: { hide_default_launcher?: boolean; horizontal_padding?: number }) => void;
type IntercomSendMessageFn = (action: IntercomAction, message: string) => void;

type IntercomAction = "update" | "show" | "showNewMessage";

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Intercom(action: IntercomAction, opts?: { hide_default_launcher?: boolean; horizontal_padding?: number }): void {
  const realIntercom: IntercomFn = window["Intercom"];
  if (realIntercom) {
    realIntercom(action, opts);
  }
}

export function sendIntercomMessage(message: string): void {
  const realIntercom: IntercomSendMessageFn = window["Intercom"];
  if (realIntercom) {
    realIntercom("showNewMessage", message);
  }
}
