<ui-alert
  [errorDetails]="errorDetails"
  [uiCloseable]="uiCloseable"
  [uiCloseText]="uiCloseText"
  [uiDescription]="uiDescription"
  [uiIconType]="uiIconType"
  [uiMessage]="uiMessage"
  [uiShowIcon]="uiShowIcon"
  [uiType]="uiType"
  (uiOnClose)="uiOnClose.emit($event)"
  uiBanner></ui-alert>
