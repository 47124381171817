import { StateService, UIRouterGlobals } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { DUMMY_GOAL_SUGGESTION_ID } from "@webapp/goal-suggestions/components/goal-suggestions/goal-suggestions.component";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { WhiteboardPermissionsService } from "@webapp/whiteboards/services/whiteboard-permissions.service";
import { CreateOkrFormModalService } from "../components/create-okr-form/create-okr-form-modal.service";

@Injectable()
export class CreateOkrDropdownButtonService {
  constructor(
    private routerGlobals: UIRouterGlobals,
    private createOkrFormModalService: CreateOkrFormModalService,
    private piFeatureVisibilityFacade: PiFeatureVisibilityFacade,
    private editionFeatureService: EditionFeatureService,
    private whiteboardPermissionsService: WhiteboardPermissionsService,
    private state: StateService
  ) {}

  public menuItems({ hideCreateOkr }: { hideCreateOkr?: boolean } = {}): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const suggestOKRs = menuItemBuilder
      .setKey("suggest_okrs")
      .setDescriptionKey("create_okrs_with_personalized_ai_recommendations")
      .setAction({
        handler: () => this.openQuantiveSuggestionsWizard(),
      })
      .setUiType({ uiType: "uiIcon", iconType: "artificial-intelligence", iconTheme: "outline" })
      .setClasses(["text-color-gray-1"])
      .setToBeEndOfSection()
      .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
      .setIsVisibleCondition(
        this.piFeatureVisibilityFacade.isGuidedOKRsCreationVisible$().pipe(map((isGuidedOKRsCreationVisible) => isGuidedOKRsCreationVisible && !hideCreateOkr))
      )
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "suggest_okrs" })
      .build();

    const createOkr = menuItemBuilder
      .setKey("create_okr")
      .setDescriptionKey("directly_add_your_okrs")
      .setAction({
        handler: () => this.openCreateGoalForm(),
      })
      .setUiType({ uiType: "uiIcon", iconType: "goal", iconTheme: "outline" })
      .setToBeEndOfSection()
      .setIsVisibleCondition(of(!hideCreateOkr))
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "create_okr" })
      .build();

    const chooseFromTemplate = menuItemBuilder
      .setKey("choose_from_template")
      .setDescriptionKey("get_inspiration_marketplace")
      .setAction({
        href: "/marketplace/okrs-examples",
      })
      .setUiType({ uiType: "uiIcon", iconType: "add-from-marketplace", iconTheme: "outline" })
      .setToBeEndOfSection()
      .setExternalLink()
      .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
      .setIsVisibleCondition(this.editionFeatureService.hasFeature$("marketplace"))
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "choose_from_template" })
      .build();

    const brainstormAndIdeate = menuItemBuilder
      .setKey("brainstorm_and_ideate")
      .setDescriptionKey("use_a_whiteboard_to_draft_okrs")
      .setAction({
        state: "gtmhub.whiteboards",
      })
      .setUiType({ uiType: "uiIcon", iconType: "whiteboards", iconTheme: "outline" })
      .setToBeEndOfSection()
      .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
      .setIsVisibleCondition(this.whiteboardPermissionsService.hasPermissionToCreate$())
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "brainstorm_and_ideate" })
      .build();

    return [suggestOKRs, createOkr, chooseFromTemplate, brainstormAndIdeate];
  }

  private openCreateGoalForm(): void {
    this.createOkrFormModalService.openFormModal({
      sessionId: this.routerGlobals.params["planningSessionId"],
      flowName: PiTrackingFlowNameEnum.CreateObjectiveFormTopNav,
    });
  }

  private openQuantiveSuggestionsWizard(): void {
    this.state.go("gtmhub.goalSuggestion", { goalSuggestionId: DUMMY_GOAL_SUGGESTION_ID });
  }
}
