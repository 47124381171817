import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { RestLayerRequest } from "@webapp/core/abstracts/models/rest-layer-request.models";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { ICollection } from "@webapp/core/core.models";
import { AsyncTaskDTOv2 } from "@webapp/strategy/models/async-tasks/async-tasks.dto-v2-models";
import { AsyncTaskVM } from "@webapp/strategy/models/async-tasks/async-tasks.vm-models";
import { DocumentDTOv2 } from "@webapp/strategy/models/documents/documents.dto-v2-models";
import { DocumentVM } from "@webapp/strategy/models/documents/documents.vm-models";
import { asyncTaskDTOv22VM } from "../../../async-tasks/api-utils/v2/async-tasks-mapper-v2.utils";
import { IDocumentsApi } from "../documents-api.interface";
import { documentDTOv22VM } from "./documents-mapper-v2.utils";
import { DocumentsUrlBuilderV2Service } from "./documents-url-builder-v2.service";

@Injectable()
export class DocumentsApiV2Service extends PlainBaseApiService implements IDocumentsApi {
  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService,
    private documentsUrlBuilderV2Service: DocumentsUrlBuilderV2Service
  ) {
    super(httpClient, apiEndpointService);
  }

  public uploadFile$(file: File, contextId: string): Observable<{ documentIds: string[] }> {
    const uploadFileUrl = this.documentsUrlBuilderV2Service.getDocumentsUrl();
    const formData = new FormData();
    formData.append("files", file, file.name);
    formData.append("contextId", contextId);
    return this.httpClient.post<Array<string>>(uploadFileUrl, formData).pipe(map((documentIds) => ({ documentIds })));
  }

  public getDocumentById$(documentId: string): Observable<DocumentVM> {
    const url = this.documentsUrlBuilderV2Service.getDocumentUrl(documentId);
    return this.get$<DocumentDTOv2>(url).pipe(map((document: DocumentDTOv2) => documentDTOv22VM(document)));
  }

  public getDocuments$(request: RestLayerRequest<DocumentVM>): Observable<DocumentVM[]> {
    const url = this.documentsUrlBuilderV2Service.getDocumentsUrl();
    return this.getAll$<ICollection<DocumentDTOv2>>(url, request).pipe(
      map((collection: ICollection<DocumentDTOv2>) => collection.items.map((document) => documentDTOv22VM(document)))
    );
  }

  public deleteDocument$(documentId: string): Observable<void> {
    const url = this.documentsUrlBuilderV2Service.getDocumentUrl(documentId);
    return this.delete$(url);
  }

  public retryFileAnalysis$(documentId: string): Observable<AsyncTaskVM> {
    const url = this.documentsUrlBuilderV2Service.getDocumentUrl(documentId) + "/summarize";
    return this.post$<AsyncTaskDTOv2>(url, {}).pipe(map((task: AsyncTaskDTOv2) => asyncTaskDTOv22VM(task)));
  }
}
