<section class="chat-input" [ngClass]="{ disabled: conversation?.currentlyAnsweringQuestion }" (focusin)="onFocusIn()" (focusout)="onFocusOut($event)">
  <section class="suggested-prompts" *ngIf="conversation?.chat.length > 0">
    <button class="suggested-prompt" #btn *ngFor="let prompt of prompts" (click)="executePredefinedPrompt(prompt.question, prompt.action, btn)">
      {{ prompt.label }}
    </button>
    @if (isDecisionsV2Enabled) {
      <button class="suggested-prompt" [uiDropdownMenu]="menu" ui-dropdown uiTrigger="click">
        <i ui-icon uiType="menu-horizontal"></i>
      </button>
      <ui-dropdown-menu #menu="uiDropdownMenu">
        <ul class="prompts-menu" ui-menu>
          <li #li *ngFor="let prompt of promptsMenu" (click)="executePredefinedPrompt(prompt.question, prompt.action, li)" ui-menu-item>
            {{ prompt.label }}
          </li>
        </ul>
      </ui-dropdown-menu>
    }
  </section>
  <form (ngSubmit)="submitQuestion()">
    <input
      #questionInput
      [(ngModel)]="questionText"
      [attr.placeholder]="conversation?.currentlyAnsweringQuestion ? 'Generating...' : 'Ask a question'"
      [ngClass]="{ 'right-button-visible': generateButtonVisible }"
      name="question"
      type="text" />
    <button
      class="generate-button"
      #generateButton
      *ngIf="generateButtonVisible && !conversation?.currentlyAnsweringQuestion"
      (focusout)="onFocusOut($event)"
      type="submit"
      ui-button
      uiShape="round"
      uiSize="default"
      uiType="primary">
      <span>Ask</span>
    </button>
  </form>
  <section class="chat-additional-data">
    <div class="chat-customization-options" *ngIf="(isPerplexityEnabled$ | async) === true">
      <span class="customization-option">Enable External Data</span>
      <ui-toggle [value]="usePerplexity$ | async" (toggled)="togglePerplexityUsage({ usePerplexity: $event.value })"></ui-toggle>
    </div>
    <span class="disclaimer"> AI systems are susceptible to errors. Verify your analysis. </span>
  </section>
</section>
