<ui-loading-indicator
  class="loading-container"
  [uiSpinning]="loadingBet || generatingStrategicMap || checkingForAsyncGeneration || onePagerGenerationInProgress"
  [uiTip]="generatingStrategicMap ? 'Generating decision map...' : onePagerGenerationInProgress ? 'Generating executive summary...' : 'Loading decision...'">
  <div class="invisible">
    <div #placeholder></div>
  </div>
  <article class="strategic-bet-evaluator" *ngIf="strategicBet && betContext && !error && !betNotAvaialbleForUser && !onePagerGenerationFailed">
    <evaluate-bet-header
      class="strategic-bet-header"
      [collaborators]="strategicBet.collaborators"
      [contextAsyncPreviewState]="contextAsyncPreviewState$ | async"
      [contextId]="betContext.id"
      [currentStepIndex]="currentStepIndex"
      [hideRightActionButtons]="redirectingToWhiteboard"
      [hideShareAndPDFButtons]="ckbFlowEnabled && strategicBet.progressSubStep === 0"
      [stepCaptionOverride]="redirectingToWhiteboard ? 'Generate Objectives' : null"
      [strategicBetName]="strategicBet?.name"
      [userIsOwner]="userIsOwner"
      (closePage)="closePage()"
      (deleteBet)="deleteBet()"
      (exportPDF)="exportPDF()"
      (leaveDecision)="leaveDecision()"
      (openContextDrawer)="openContextDrawer()"
      (showInviteCollaborators)="showInviteCollaborators()"></evaluate-bet-header>
    <ng-container *ngIf="currentStepIndex === 0 && !redirectingToWhiteboard">
      <evaluate-bet-context
        class="strategic-bet-context"
        [betContext]="betContext"
        [userSettings]="strategySettings"
        (generateStrategy)="generateStrategy($event)"
        (hideAlert)="hideAlert(0)"></evaluate-bet-context>
    </ng-container>
    <ng-container *ngIf="currentStepIndex > 0 && !redirectingToWhiteboard">
      <evaluate-bet-strategic-map
        class="strategic-bet-strategy-map"
        [betId]="strategicBet.id"
        [contextId]="betContext.id"
        [exportPdfAttempts]="exportPdfAttempts.asObservable()"
        [progressSubStep]="strategicBet.progressSubStep"
        [strategicMap]="strategicMap"
        [userIsOwner]="userIsOwner"
        [userSettings]="strategySettings"
        (finalizeGrooming)="finalizeGrooming($event)"
        (generateOKRs)="generateOKRs()"
        (generateOnePager)="triggerOnePagerGeneration()"
        (hideAlert)="hideAlert($event)"
        (updateHypothesisGroomStatus)="updateHypothesisGroomStatus($event)"></evaluate-bet-strategic-map>
    </ng-container>
    <whiteboard-generation
      class="whiteboard-generation-page"
      *ngIf="redirectingToWhiteboard"
      [generatedWhiteboardId]="generatedWhiteboardId"
      (navigateToWhiteboard)="navigateToWhiteboard()"
      (stopOKRGeneration)="stopOKRGeneration()"
      backButtonCaption="Back to decision map"></whiteboard-generation>
  </article>
  <article class="bet-not-available" *ngIf="betNotAvaialbleForUser && !onePagerGenerationFailed">
    <error-page [errorMessage]="noLongerCollaborator" (back)="closePage()" (retry)="closePage()" backButtonCaption="Close" errorButtonCaption="View all decisions" errorType="no_access"></error-page>
  </article>
  <article class="bet-not-available" *ngIf="onePagerGenerationFailed === true">
    <error-page
      [errorMessage]="generationFailed"
      [showXClose]="false"
      (back)="resetOnePagerGenerationState()"
      (retry)="generateOnePager()"
      backButtonCaption="Back to decision"
      errorButtonCaption="Retry"
      errorType="error"></error-page>
  </article>
  <evaluate-bet-errors
    *ngIf="error && !betNotAvaialbleForUser"
    [error]="error"
    [whiteboardGenerationError]="whiteboardGenerationError"
    (closePage)="closePage()"
    (generateOKRs)="generateOKRs()"
    (loadBet)="loadBetData()"
    (regenerateStrategy)="regenerateStrategy()"
    (stopOKRGeneration)="stopOKRGeneration()"
    (stopStrategyGeneration)="stopStrategyGeneration()"></evaluate-bet-errors>
</ui-loading-indicator>
<ng-template #decisionDeletionError>
  <p class="deletion-error sp-mb-0">
    Unable to delete decision. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
  <error-details *ngIf="uiError.transactionId" [transactionId]="uiError.transactionId"></error-details>
</ng-template>
<ng-template #decisionLeaveError>
  <p class="deletion-error sp-mb-0">
    Unable to leave decision. Please try again or
    <span class="delete-modal-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
  <error-details *ngIf="uiError.transactionId" [transactionId]="uiError.transactionId"></error-details>
</ng-template>
<ng-template #noLongerCollaborator>
  <h3>You are no longer a collaborator</h3>
  <p>You will not see this decision in your list. It might have been deleted, or the owner removed you from the collaborators list.</p>
</ng-template>

<ng-template #generationFailed>
  <h3>The summary couldn't be generated</h3>
  <p>
    Try again - this issue is most likely temporary. If the issue persists,
    <span class="contact-support-link" (click)="onContactSupportClick()" (keypress)="onContactSupportClick()" tabindex="0">contact support</span>.
  </p>
</ng-template>
