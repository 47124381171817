import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { IStrategyConversationApi } from "./strategy-conversation-api.interface";
import { StrategyConversationApiV2Service } from "./v2/strategy-conversation-api-v2.service";

@Injectable()
export class StrategyConversationApiFactory {
  constructor(private injector: Injector) {}

  public getApi(): Observable<IStrategyConversationApi> {
    return of(this.injector.get(StrategyConversationApiV2Service));
  }
}
