import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, catchError, combineLatest, filter, forkJoin, of } from "rxjs";
import { WhiteboardsShapeService } from "@gtmhub/whiteboards/services/whiteboards-shapes.service";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { AsyncTaskVM } from "@webapp/strategy/models/async-tasks/async-tasks.vm-models";
import { OrderedStrategyResultEntryVM, StrategyResultBlockTypeVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { StrategyChatQuestionVM, StrategyConversationChatEntryVM } from "@webapp/strategy/models/chat/strategy-conversation-chat.vm-models";
import { StrategyConversationVM } from "@webapp/strategy/models/strategy-conversation/strategy-conversation.vm-models";
import { REPORT_DUMMY_BLOCK_ID } from "@webapp/strategy/models/strategy.constants";
import { StrategyReportFollowupActionDefinition } from "@webapp/strategy/models/strategy.vm-models";
import { AsyncTasksService } from "@webapp/strategy/services/async-tasks/async-tasks.service";
import { StrategyConversationChatService } from "@webapp/strategy/services/chat/strategy-conversation-chat.service";
import { StrategyConversationViewContextService } from "@webapp/strategy/services/conversation/strategy-conversation-view-context.service";
import { StrategyConversationService } from "@webapp/strategy/services/conversation/strategy-conversation.service";
import { StrategyConversationReportService } from "@webapp/strategy/services/report/strategy-conversation-report.service";
import { chatEntryAiVM2VM, conversationVM2AiVM } from "@webapp/strategy/services/utility/strategy-conversation-factory.utils";
import { User } from "@webapp/users/models/users.models";
import { WhiteboardsShapeServiceProvider } from "@webapp/whiteboards/providers/whiteboards.provider";
import { UiAiChatModule } from "../evaluate-bet-page/components/ai-chat/ai-chat.module";
import { ChatQuestionVM, ConversationChatEntryVM, ConversationVM } from "../evaluate-bet-page/components/ai-chat/models/chat.vm-models";
import { ResultsSectionComponent } from "../results-section/results-section.component";
import { handleNoteContent } from "./utils";

@UntilDestroy()
@Component({
  selector: "decision-view",
  templateUrl: "./decision-view.component.html",
  styleUrls: ["./decision-view.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ResultsSectionComponent, UiAiChatModule, AsyncPipe],
  providers: [StrategyConversationViewContextService, WhiteboardsShapeServiceProvider],
})
export class DecisionViewComponent implements OnInit {
  @Input() public conversationId: string;
  @Input() public usersMap: Record<string, User>;

  // public aiConversation: ConversationVM;
  public conversation: StrategyConversationVM;

  private conversationObject: StrategyConversationVM;

  public reportConversation$: Observable<StrategyConversationVM>;
  public aiChatConversation$: Observable<ConversationVM>;

  constructor(
    private cdr: ChangeDetectorRef,
    private reportService: StrategyConversationReportService,
    private chatService: StrategyConversationChatService,
    private conversationService: StrategyConversationService,
    private asyncTasks: AsyncTasksService,
    private viewContextService: StrategyConversationViewContextService,
    private whiteboardsShapeService: WhiteboardsShapeService
  ) {}

  public ngOnInit(): void {
    this.loadConversation();
  }

  public deleteResultBlock(resultBlock: OrderedStrategyResultEntryVM): void {
    this.reportService
      .deleteResultBlock(this.conversation, resultBlock)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => this.cdr.markForCheck());
  }

  public editResultSectionContent(resultBlock: OrderedStrategyResultEntryVM): void {
    if (resultBlock.id !== REPORT_DUMMY_BLOCK_ID) {
      this.reportService.editResultSectionContent(this.conversation, resultBlock).pipe(takeOneUntilDestroyed(this)).subscribe();
      this.updateWhiteboardNote(resultBlock);
    } else {
      resultBlock.shapeId = this.createWhiteboardNote({ blockType: resultBlock.blockType, blockContent: resultBlock.blockContent } as unknown as ConversationChatEntryVM);

      this.reportService.addResultFromADummyBlock(this.conversation, resultBlock).pipe(takeOneUntilDestroyed(this)).subscribe();
    }
  }

  public executeFollowupAction(followupAction: StrategyReportFollowupActionDefinition): void {
    this.reportService.executeFollowupAction(this.conversation, followupAction);
  }

  public insertTextBlockAtIndex(index: number): void {
    this.reportService.insertEmptyTextBlockAtIndex(this.conversation, index).pipe(takeOneUntilDestroyed(this)).subscribe();
  }

  public moveResultBlockUp(resultBlock: OrderedStrategyResultEntryVM): void {
    this.reportService.moveResultBlockUp(this.conversation, resultBlock).pipe(takeOneUntilDestroyed(this)).subscribe();
  }

  public moveResultBlockDown(resultBlock: OrderedStrategyResultEntryVM): void {
    this.reportService.moveResultBlockDown(this.conversation, resultBlock).pipe(takeOneUntilDestroyed(this)).subscribe();
  }

  public addContent(chatEntry: ConversationChatEntryVM): void {
    this.chatService.updateChatEntryStatus(this.conversationId, chatEntry, {
      inserting: true,
      error: false,
    });

    // this.strategiesTrackingService.trackFreeTextInserted(this.conversationId, chatEntry.followupActionDetails.type, {
    //   partial: (chatEntry.blockContent as ChatTextResultVM).partial,
    // });

    const shapeId = this.createWhiteboardNote(chatEntry);

    this.cdr.markForCheck();
    this.reportService
      .addResultFromChat(this.conversation, chatEntryAiVM2VM({ ...chatEntry, shapeId: shapeId }))
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: (response: OrderedStrategyResultEntryVM[] | null) => {
          if (response === null) {
            this.chatService.updateChatEntryStatus(this.conversationId, chatEntry, {
              error: true,
            });
          }
        },
        complete: () => {
          this.chatService.updateChatEntryStatus(this.conversationId, chatEntry, {
            inserting: false,
          });
          this.cdr.markForCheck();
        },
      });
  }

  public askQuestion(question: ChatQuestionVM): void {
    // this.conversationMediatorService
    //   .setConversationToLoading$(this.betId, this.area.id, {
    //     id: this.hypothesisId,
    //     conversationId: this.hypothesis.conversationId,
    //   })
    //   .pipe(takeOneUntilDestroyed(this))
    //   .subscribe(() => {
    //     this.onHypothesisIsSeenChange();
    this.chatService.askQuestion(this.conversationId, question as unknown as StrategyChatQuestionVM, null);
    // });
  }

  public provideFeedbackForMessage(params: { message: ConversationChatEntryVM; type: "positive" | "negative" }): void {
    this.chatService.provideFeedbackForMessage(this.conversationId, params.message as unknown as StrategyConversationChatEntryVM, params.type);
  }

  public regenerateLastChatEntry(): void {
    this.chatService.regenerateLastChatEntry(this.conversationId);
  }

  public retryErroredMessage(): void {
    this.chatService.retryErroredMessage(this.conversationId);
  }

  private loadConversation(): void {
    // this.apmService.startDataLoadSpan("load conversation async tasks");

    // this.initialLoadingIsOngoing = true;

    const conversationObservable = this.conversationObject
      ? of(this.conversationObject)
      : this.conversationService.getConversation$(this.conversationId).pipe(filter((conversation) => conversation !== undefined));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    conversationObservable.pipe(untilDestroyed(this)).subscribe((conversation) => {
      // this.verified = conversation?.results.length > 0;
    });

    combineLatest([conversationObservable, this.asyncTasks.getTasksForAccountAndItemId$(this.conversationId, ["ask_question", "regenerate_chat_answer"])])
      .pipe(
        catchError(() => {
          // this.initialLoadingIsOngoing = false;
          return of([0, null]);
        })
      )
      .pipe(takeOneUntilDestroyed(this))
      .subscribe((response) => {
        const conversation = response[0] as StrategyConversationVM;
        const asyncTasks = response[1] as AsyncTaskVM[];

        if (!conversation) {
          // this.isInErrorState = true;
          // this.initialLoadingIsOngoing = false;
          this.cdr.markForCheck();
          return;
        }

        if (this.conversationObject) {
          this.reportConversation$ = of(this.conversationObject);
          this.aiChatConversation$ = of(conversationVM2AiVM(this.conversationObject));
        } else {
          this.reportConversation$ = this.reportService.getConversationForReport$(conversation.id);
          this.aiChatConversation$ = this.chatService.getConversationForAiChat$(conversation.id);
          this.updateAiConversationBasedOnTasks(asyncTasks, conversation.id);
        }

        this.aiChatConversation$.pipe(untilDestroyed(this)).subscribe((conversation) => {
          if (conversation.currentlyAnsweringQuestion) {
            this.viewContextService.converstionStartedAnsweringQuestion();
          } else {
            this.viewContextService.converstionStoppedAnsweringQuestion();
          }
        });

        // this.apmService.endDataLoadSpan("load conversation async tasks");
        // this.apmService.startDataLoadSpan("load conversation");

        forkJoin([
          this.reportConversation$.pipe(
            filter((reportConversation) => reportConversation !== null),
            takeOneUntilDestroyed(this)
          ),
          this.aiChatConversation$.pipe(
            filter((chatConversation) => chatConversation !== null),
            takeOneUntilDestroyed(this)
          ),
        ])
          .pipe(takeOneUntilDestroyed(this))
          .subscribe(([reportConversation]) => {
            this.conversation = reportConversation;
            // conversation.contextId = this.contextId;
            // if (conversation !== null) {
            //   this.initialLoadingIsOngoing = false;
            // }
            // this.apmService.endDataLoadSpan("load conversation");
            this.cdr.markForCheck();
          });

        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  private updateAiConversationBasedOnTasks(tasks: AsyncTaskVM[], conversationId: string): void {
    this.aiChatConversation$.pipe(takeOneUntilDestroyed(this)).subscribe(() => {
      const inProgressAskQuestionTasks = tasks.filter(
        (task) => task.name === "ask_question" && task.itemId === conversationId && ["PENDING", "STARTED"].includes(task.status)
      );
      const inProgressRegenerateChatAnswerTasks = tasks.filter((task) => task.name === "regenerate_chat_answer" && ["PENDING", "STARTED"].includes(task.status));

      if (inProgressAskQuestionTasks.length > 0) {
        const currentTask = tasks.find((task) => task.name === "ask_question" && task.itemId === conversationId && ["PENDING", "STARTED"].includes(task.status));
        const { additionalData } = currentTask;
        const type = (additionalData.followup_action_details as Record<string, unknown>)?.type as StrategyResultBlockTypeVM;
        const chatRequest = additionalData?.chatRequest as { question: string };

        this.chatService.addLoadingChatEntry(
          conversationId,
          {
            question: chatRequest?.question,
            type: type,
          },
          undefined,
          "",
          currentTask?.startedAt
        );
      } else if (inProgressRegenerateChatAnswerTasks.length > 0) {
        this.chatService.regenerateLastChatEntryVisuals(conversationId);
      }
    });
  }

  private createWhiteboardNote(chatEntry: ConversationChatEntryVM): string {
    const noteShape = this.whiteboardsShapeService.createNote({ content: handleNoteContent(chatEntry.blockType, chatEntry.blockContent) });
    return noteShape.id;
  }

  private updateWhiteboardNote(resultBlock: OrderedStrategyResultEntryVM): void {
    this.whiteboardsShapeService.updateNote({
      shapeId: resultBlock.shapeId,
      content: handleNoteContent(resultBlock.blockType, resultBlock.blockContent),
    });
  }
}
