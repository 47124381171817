import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { PiTrackingEventsEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";

@Component({
  selector: "key-result[flowId][flowName]",
  templateUrl: "./key-result.component.html",
  styleUrls: ["./key-result.component.less"],
})
export class QuantiveKeyResultComponent {
  @Input() public flowId: string;
  @Input() public flowName: string;
  @Input() public isEditing: boolean;
  @Input() public keyResult;
  @Input() public objective;
  @Input() public formGroup: FormGroup;
  @Input() public showKeyResultActions: boolean;
  @Output() public readonly removeKeyResult = new EventEmitter<number>();

  constructor(private analyticsService: AnalyticsService) {}

  public get keyResults(): FormArray {
    return this.objective.controls.keyResults;
  }

  public confirm(kr: AbstractControl): void {
    kr.patchValue({ title: kr.value.title, description: kr.value.description, isManual: false, isEdit: false });

    if (kr.value.id) {
      this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionEdited, {
        flowId: this.flowId,
        flowName: this.flowName,
        userId: getCurrentUserId(),
        suggestionId: kr.value.id,
      });
    }
  }

  public cancel(kr: AbstractControl, metricIndex: number): void {
    if (kr.value.isManual) {
      this.onRemoveKeyResult(metricIndex);
      return;
    }

    kr.patchValue({
      title: kr.value.previousSavedTitle,
      isEdit: false,
    });
  }

  public openEditMode(kr: AbstractControl): void {
    kr.patchValue({
      ...kr,
      isEdit: true,
      previousSavedTitle: kr.value.title,
    });
  }

  public onRemoveKeyResult(metricIndex: number): void {
    this.removeKeyResult.emit(metricIndex);
  }
}
