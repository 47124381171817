import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ChatTextResultVM, ConversationChatEntryVM, UserDTO } from "@webapp/strategy/components/evaluate-bet-page/components/ai-chat/models/chat.vm-models";

@Component({
  selector: "ui-chat-block-human",
  templateUrl: "./chat-block-human.component.html",
  styleUrls: ["./chat-block-human.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBlockHumanComponent implements OnInit {
  @Input() public message: ConversationChatEntryVM | undefined;
  @Input() public user: UserDTO | undefined;
  public textContent: ChatTextResultVM | undefined;

  public ngOnInit(): void {
    this.textContent = this.message && (this.message.blockContent as ChatTextResultVM);
  }
}
