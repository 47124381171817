import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PlainBaseApiService } from "@webapp/core/abstracts/services/plain-base-api.service";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { ICollection } from "@webapp/core/core.models";
import { AsyncTaskDTOv2 } from "@webapp/strategy/models/async-tasks/async-tasks.dto-v2-models";
import { AsyncTaskVM } from "@webapp/strategy/models/async-tasks/async-tasks.vm-models";
import { IAsyncTasksApi } from "../async-tasks-api.interface";
import { asyncTaskDTOv22VM, asyncTaskFilterV2Mapper } from "./async-tasks-mapper-v2.utils";
import { AsyncTasksUrlBuilderV2Service } from "./async-tasks-url-builder-v2.service";

@Injectable()
export class AsyncTasksApiV2Service extends PlainBaseApiService implements IAsyncTasksApi {
  constructor(
    protected httpClient: HttpClient,
    apiEndpointService: ApiEndpointService,
    private asyncTasksUrlBuilderV2Service: AsyncTasksUrlBuilderV2Service
  ) {
    super(httpClient, apiEndpointService);
  }

  public getAsyncTasks$(filter: Record<string, unknown>): Observable<AsyncTaskVM[]> {
    const asyncTaskUrl = this.asyncTasksUrlBuilderV2Service.getAsyncTasksUrl();

    return this.getAll$<ICollection<AsyncTaskDTOv2>>(asyncTaskUrl, {
      filter: asyncTaskFilterV2Mapper(filter),
      limit: 0,
    }).pipe(map((collection: ICollection<AsyncTaskDTOv2>) => collection.items.map((asyncTask) => asyncTaskDTOv22VM(asyncTask))));
  }
}
