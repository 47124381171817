import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef, booleanAttribute } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";

@Component({
  selector: "message-box",
  templateUrl: "./message-box.component.html",
  styleUrls: ["./message-box.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule, UiButtonModule, CommonModule],
  host: {
    "[class.borderless]": `borderless`,
  },
})
export class MessageBoxComponent {
  @Input({ required: true })
  set description(value: string | TemplateRef<object>) {
    if (value instanceof TemplateRef) {
      this.descriptionTemplateRef = value;
      this.descriptionString = null;
    } else {
      this.descriptionTemplateRef = null;
      this.descriptionString = value;
    }
  }

  @Input() public errorType: "error" | "no_access" = "error";
  @Input() public buttonLabel: string;
  @Input() public buttonIcon: string;
  @Input() public icon: string;
  @Input() public size: "medium" | "large" = "large";
  @Input() public showButtonIcon: boolean = true;
  @Input({ transform: booleanAttribute }) public borderless: boolean;
  @Output() public readonly buttonAction = new EventEmitter<void>();

  public descriptionString: string | null = null;
  public descriptionTemplateRef: TemplateRef<object> | null = null;

  @HostBinding("class")
  get hostClasses(): string {
    return `size-${this.size}`;
  }

  public onButtonClick(): void {
    this.buttonAction.emit();
  }
}
