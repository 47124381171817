import { Injectable } from "@angular/core";
import html2pdf from "html2pdf.js";
import { StrategiesTrackingService } from "./strategies-tracking.service";

@Injectable()
export class StrategyConversationExportService {
  constructor(private strategiesTrackingService: StrategiesTrackingService) {}

  public exportReport(element: HTMLElement, contextId: string, completionCallback?: () => void): void {
    const start = new Date();
    const options = {
      margin: [30, 15, 30, 15], // top, left, buttom, right,
      filename: `Report-${new Date().toLocaleString().replace(",", "")}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      pagebreak: { mode: "avoid-all", before: ".export-page-break" },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };
    let pdfReportPageCount = 0;
    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf: { internal: { getNumberOfPages(): number } }) => {
        pdfReportPageCount = pdf.internal.getNumberOfPages();
        const exportEndDate = new Date();
        this.strategiesTrackingService.trackSummaryReportExported(contextId, pdfReportPageCount, exportEndDate.getTime() - start.getTime());
        if (completionCallback) {
          completionCallback();
        }
      })
      .save();
  }
}
