import { BaseElementBuilder } from "@webapp/shared/builders/base-element-builder";
import { Tab } from "@webapp/shared/models/tabset-builder.models";

export class TabBuilder extends BaseElementBuilder<Tab> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  public setAriaLabel(ariaLabel: string): this {
    this.elem.ariaLabel = ariaLabel;
    return this;
  }

  public setIconType(iconType: string): this {
    this.elem.iconType = iconType;
    return this;
  }

  public setTrack(trackName: string): this {
    this.elem.track = trackName;
    return this;
  }

  public setTrackMetaData(metaData: { [key: string]: string }): this {
    this.elem.trackMetaData = metaData;
    return this;
  }

  public setDataTestId(dataTestId: string): this {
    this.elem.dataTestId = dataTestId;
    return this;
  }

  public setSelected(): this {
    this.elem.selected = true;
    return this;
  }

  public override reset(): this {
    this.elem = {} as Tab;
    return this;
  }

  public setSubTitle(subTitle: string): this {
    this.elem.subTitle = subTitle;
    return this;
  }
}
