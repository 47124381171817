import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import less from "less";
import { Observable, map } from "rxjs";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { DocumentExporterService } from "@webapp/strategy/services/document-exporter/services/document-exporter.service";
import resultBlockPdfStyles from "./result-block-styles.less?ngResource";

@UntilDestroy()
@Injectable()
export class ConversationExporterService {
  constructor(private documentExporterService: DocumentExporterService) {}

  public exportConversationAsPDF$({ htmlForExport }: { htmlForExport: string }): Observable<void> {
    return this.documentExporterService
      .exportAsPDF$({
        sourceType: "strategyChat",
        payload: htmlForExport,
        css: this.buildConversationsCSS(),
        preferences: {
          scale: 0.8,
          isLandscape: false,
          paperSize: "A4",
          margins: [0.7, 0.7, 0.5, 0.5],
        },
      })
      .pipe(
        takeOneUntilDestroyed(this),
        map((resourceUrl) => {
          this.documentExporterService.triggerResourceDownload({
            from: resourceUrl,
            withName: this.generatePDFName(),
          });
        })
      );
  }

  private buildConversationsCSS(): string {
    let compiledCSS: string;
    const trimmedResultBlockPdfStyles = resultBlockPdfStyles.replace(/\/\*[^*]*\*+(?:[^/*][^*]*\*+)*\//g, ""); // removing all multiline script comments

    less.render(trimmedResultBlockPdfStyles, (error, output) => {
      if (error) {
        console.error(error);
      }
      compiledCSS = output.css;
    });

    return compiledCSS;
  }

  private generatePDFName(): string {
    return `Report-${new Date().toLocaleString().replace(",", "")}`;
  }
}
