import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { IDocumentsApi } from "./documents-api.interface";
import { DocumentsApiV2Service } from "./v2/documents-api-v2.service";

@Injectable()
export class DocumentsApiFactory {
  constructor(private injector: Injector) {}

  public getApi(): Observable<IDocumentsApi> {
    return of(this.injector.get(DocumentsApiV2Service));
  }
}
