<div class="button-container" *ngIf="useShareLinkSplitButtonForTheNewGrid && !showShareLinkOnly">
  <button
    class="selected-access-type"
    #triggerButton
    [attr.aria-description]="triggerButtonAriaDescription"
    [attr.aria-expanded]="showPermissions ? true : null"
    [attr.aria-haspopup]="true"
    [attr.aria-label]="triggerButtonAriaLabel"
    [uiDropdownMenu]="accessDropdown"
    [uiVisible]="showPermissions"
    (uiVisibleChange)="changePermissionsVisibility()"
    data-test-id="simple-permissions-button"
    ui-dropdown
    uiPlacement="bottomRight"
    uiShape="round"
    uiTrigger="click"
    uiType="text">
    <i [uiType]="selectedAccessOption.icon" ui-icon uiSize="sm" uiTheme="outline"></i> {{ !compactMode ? (selectedAccessOption?.labelKey | localize) : "" }}
  </button>
  <button class="share-button" (click)="handleShareableLink()" data-test-id="grid-share-link-icon" ui-button uiShape="round" uiSize="default" uiType="text"><i ui-icon uiType="link"></i></button>
</div>

<button
  class="selected-access-type"
  #triggerButton
  *ngIf="!useShareLinkSplitButtonForTheNewGrid && !showShareLinkOnly"
  [attr.aria-description]="triggerButtonAriaDescription"
  [attr.aria-expanded]="showPermissions ? true : null"
  [attr.aria-haspopup]="true"
  [attr.aria-label]="triggerButtonAriaLabel"
  [uiDropdownMenu]="accessDropdown"
  [uiVisible]="showPermissions"
  (uiVisibleChange)="changePermissionsVisibility()"
  data-test-id="simple-permissions-button"
  ui-dropdown
  uiPlacement="bottomRight"
  uiTrigger="click">
  <i [uiType]="selectedAccessOption.icon" ui-icon uiSize="sm" uiTheme="outline"></i> {{ !compactMode ? (selectedAccessOption?.labelKey | localize) : "" }}
  <i *ngIf="!compactMode" ui-icon uiSize="sm" uiType="chevron-down-small"></i>
</button>

<div class="button-container" *ngIf="showShareLinkOnly">
  <button class="share-link-button" (click)="handleShareableLink()" ui-button uiShape="round" uiSize="default" uiType="text"><i ui-icon uiType="link"></i>{{ "share_link" | localize }}</button>
</div>

<ui-dropdown-menu #accessDropdown="uiDropdownMenu" tabindex="0">
  <ul class="access-options-menu" #accessDropdownContainer data-test-id="simple-permissions-dropdown" ui-menu>
    <ui-radio-group #radioGroup [(ngModel)]="selectedAccessOption" (ngModelChange)="changeAccessType()" aria-label="Select who has access" role="radiogroup">
      <span class="menu-title">{{ "who_has_access" | localize }}</span>
      <li class="access-option" *ngFor="let option of accessOptions" ui-menu-item>
        <span [uiValue]="option" data-test-id="simple-permission-label" ui-radio><i [uiType]="option.icon" ui-icon uiSize="sm" uiTheme="outline"></i>{{ option.labelKey | localize }}</span>
      </li>
    </ui-radio-group>
    <div class="restricted-access-section" *ngIf="selectedAccessOption && selectedAccessOption.value === accessTypes.restricted">
      <div class="principals" *ngIf="principalsMap && access && access.permissions && access.permissions.length" aria-label="List of invitees" role="group">
        <div class="principal" #permissionRow *ngFor="let permission of access.permissions; trackBy: trackByPrincipalId" [class.principal-owner-row]="ownerId === permission?.principalId">
          <div class="image">
            <ui-assignee-avatar [fromAssigneeId]="permission?.principalId" [uiShowTooltip]="true" uiSize="xs" uiTooltipPlacement="bottom"></ui-assignee-avatar>
          </div>
          <div
            class="name"
            [id]="permission?.principalId"
            [uiTooltipTitle]="principalsMap[permission?.principalId]?.name"
            [uiTooltipVisible]="checkOverflow(permission?.principalId)"
            ui-tooltip
            uiTooltipPlacement="bottom">
            <span *ngIf="permission.principalKind === 'role'">{{ "everyone_in_named_role2" | localize: { name: principalsMap[permission?.principalId]?.name } }}</span>
            <ui-assignee-name class="principal-assignee-name" *ngIf="permission.principalKind !== 'role'" [fromAssigneeId]="permission?.principalId"></ui-assignee-name>
          </div>
          <span class="grant item-owner-label" *ngIf="ownerId === permission?.principalId" [attr.aria-label]="getItemOwnerAriaLabel(permission)" tabindex="0">{{ "owner" | localize }}</span>
          <gh-dropdown
            class="permission-dropdown-menu-button-container"
            *ngIf="ownerId !== permission?.principalId"
            [hasCustomButtonContent]="true"
            [menuItems]="inviteesAccessDropdownMenuItems"
            (selectedItem)="dropdownSelectedChange($event, permission, permissionRow)"
            placement="bottomRight">
            <button
              [attr.aria-label]="getAccessInviteeAriaLabel(permission)"
              aria-haspopup="menu"
              gh-dropdown-custom-button-content
              tabindex="0"
              ui-button
              uiPlacement="bottomRight"
              uiSize="small"
              uiTrigger="click"
              uiType="text">
              {{ (permission.grant.general.includes("update") ? "can_edit" : "can_view") | localize }}
            </button>
          </gh-dropdown>
        </div>
      </div>
      <div class="invite-user-section">
        <span class="invite-user-title">{{ "invite_someone" | localize }}</span>
        <div class="search-holder">
          <simple-search
            #simpleSearch
            [searchCollections]="searchCollections"
            [searchPlaceholder]="'search_for_users_teams_roles' | localize"
            (itemClicked)="addPrincipal($event)"
            activeTeamsOnly
            activeUsersOnly></simple-search>
        </div>
      </div>
    </div>
    <okr-views-copy-link-form class="copy-link-form" *ngIf="shareableLink?.shouldDisplay" [url]="shareableLink.url" (copyLink)="shareableLink.copyLinkHandler()" />
  </ul>
</ui-dropdown-menu>
