import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class ContributorRemovedResponseStrategy implements QuantiveResultsSocketStrategy<"contributorRemoved"> {
  public messageType: QuantiveResultsSocketStrategy<"contributorRemoved">["messageType"] = "contributorRemoved";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"contributorRemoved">> {
    return pipe(filter(({ data }) => data.type === "contributor_removed"));
  }
}
