import {
  IAttributes,
  IAugmentedJQuery,
  ICompileProvider,
  ICompileService,
  IDocumentService,
  IExceptionHandlerService,
  INgModelController,
  IPromise,
  IQService,
  IRootScopeService,
  IScope,
  auto,
  jwt,
  module,
  noop,
  ui,
} from "angular";
import ngSanitize from "angular-sanitize";
import { IModalStackService } from "angular-ui-bootstrap";
import { Ng1StateDeclaration, RawParams, StateService, Transition, TransitionService, ViewConfig } from "@uirouter/angularjs";
import { downgradeComponent } from "@angular/upgrade/static";
import { environment } from "@environments/environment";
import jwtDecode from "jwt-decode";
import userProfile from "@gtmhub/user-profile/user-profile.module";
import { AccountResolverService } from "@webapp/accounts";
import { AppComponent } from "@webapp/app.component";
import { NavigationLifeCycleEvents } from "@webapp/navigation/components/navigation/navigation.events";
import { LastUrlService } from "@webapp/shared/services/last-url-service";
import { removeMainNavSkeleton, removeSubNavSkeleton } from "@webapp/shared/skeleton/skeleton.utils";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";
import analytics from "./analytics/analytics.module";
import appCore from "./app-core.module";
import assignees from "./assignees/module";
import { AuthenticationResolverService, getToken } from "./auth";
import http from "./bootstrap/http/http.module";
import { patchOverlay } from "./bootstrap/overlay";
import { trackStateChanges } from "./bootstrap/track-state-changes";
import clickMagick from "./clickMagick/click-magick.module";
import logging from "./core/logging/module";
import routing from "./core/routing/module";
import tracing from "./core/tracing/module";
import delighted from "./delighted/module";
import editionPlanChange from "./edition-plan-change/edition-plan-change.module";
import home from "./home/home.module";
import login from "./login/login.module";
import { IGtmhubRootScopeService } from "./models";
import multiAccount from "./multi-account/module";
import navigation from "./navigation/navigation.module";
import notifications from "./notifications/notifications.module";
import onboarding from "./onboarding/onboarding.module";
import sharedComponents from "./shared/components/shared-components.module";
import sharedFilters from "./shared/filters/filters.module";
import { RootLayoutCtrl } from "./shared/layout/controllers/root-layout.ctrl";
import states from "./states";
import uiBootstrap from "./ui-bootstrap.module";
import { persistQueryStringParametersInStorage } from "./util";

const mod = module("app", [
  appCore,
  assignees,
  analytics,
  ngSanitize,
  login,
  delighted,
  navigation,
  multiAccount,
  clickMagick,
  notifications,
  home,
  sharedFilters,
  sharedComponents,
  editionPlanChange,
  onboarding,
  states,
  uiBootstrap,
  http,
  userProfile,
  routing,
  tracing,
  logging,
  // strategy,
]);

trackStateChanges(mod);

mod.directive("appBootstrap", downgradeComponent({ component: AppComponent, propagateDigest: false }));
mod.controller("RootLayoutCtrl", RootLayoutCtrl);

mod.config([
  "$provide",
  function ($provide: auto.IProvideService) {
    $provide.decorator("$exceptionHandler", [
      "$delegate",
      function ($delegate: IExceptionHandlerService) {
        return function (exception, cause) {
          const exceptionsToIgnore = [
            "Possibly unhandled rejection: backdrop click",
            "Possibly unhandled rejection: cancel",
            "Possibly unhandled rejection: escape key press",
          ];

          if (exceptionsToIgnore.includes(exception)) {
            return;
          }

          $delegate(exception, cause);
        };
      },
    ]);
  },
]);

mod.directive("contenteditable", function () {
  return {
    require: "ngModel",
    scope: {
      trustHtml: "@",
    },
    link: function (scope: { trustHtml: string } & IScope, elm: IAugmentedJQuery, attrs: IAttributes, ctrl: INgModelController) {
      // view -> model
      elm.on("keyup", function () {
        ctrl.$setViewValue(elm.text());
      });

      elm.on("blur", function () {
        if (elm.html().length && !elm.text().trim().length) {
          elm.empty();
        }
      });

      // model -> view
      ctrl.$render = function () {
        if (!scope.trustHtml || scope.trustHtml === "true") {
          elm.html(ctrl.$viewValue);
        } else {
          elm.text(ctrl.$viewValue);
        }
      };

      // load init value from DOM
      const eval2 = eval;
      ctrl.$setViewValue(eval2(elm.text()));
    },
  };
});

mod.directive("onOutsideElementClick", [
  "$document",
  function ($document: IDocumentService) {
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        element.on("click", function (e) {
          e.stopPropagation();
        });

        const onClick = function (event: Event) {
          scope.$apply(function () {
            scope.$eval(attrs.onOutsideElementClick, { event });
          });
        };

        $document.on("click", onClick);

        scope.$on("$destroy", function () {
          $document.off("click", onClick);
        });
      },
    };
  },
]);

mod.run([
  "$rootScope",
  "$transitions",
  "$state",
  "$q",
  "AuthenticationResolverService",
  "AccountResolverService",
  "CurrentUserRepository",
  "UserProfileService",
  "LastUrlService",
  "jwtHelper",
  (
    $rootScope: IRootScopeService,
    $transitions: TransitionService,
    $state: StateService,
    $q: IQService,
    authenticationResolverService: AuthenticationResolverService,
    accountResolverService: AccountResolverService,
    currentUserRepository: CurrentUserRepository,
    profileService: UserProfileService,
    lastUrlService: LastUrlService,
    jwtHelper: jwt.IJwtHelper
  ) => {
    const generateToPathURL = (toState: Ng1StateDeclaration, toStateParams: RawParams): string => {
      // Remove "#"
      const toPath = $state.href(toState.name, toStateParams).slice(1);

      // Remove search from URL. Search is saved in lastUrlService.storeCurrentLocation with $location.search()
      return toPath.split("?")[0];
    };

    const logoutAndNavigateToLogin = (trans: Transition): IPromise<boolean> => {
      const toState = trans.to();

      const storedLocationSearch = lastUrlService.getStoredLocationSearch();
      const shouldKeepStoredLocation = storedLocationSearch && storedLocationSearch[lastUrlService.keepLocationKey];
      if (!shouldKeepStoredLocation) {
        lastUrlService.storeCurrentLocation(generateToPathURL(toState, trans.params()));
      }

      // deleting all account specific information
      accountResolverService.clear();
      currentUserRepository.clear();
      authenticationResolverService.clear();

      return authenticationResolverService.navigateToLogin(profileService.getProfile(), { isResolved: false }).then(() => false);
    };

    const refreshTokenIfExpired = (accessToken: string, trans: Transition): IPromise<boolean> => {
      const decodedToken = jwtDecode<{ iat: number; exp: number }>(accessToken);

      // If token is expired, wait for a new one
      if (jwtHelper.isTokenExpired(accessToken)) {
        return authenticationResolverService
          .refreshToken({ blocking: true })
          .then(() => $q.when(true))
          .catch(() => logoutAndNavigateToLogin(trans));
      }

      // If token is at half its duration, refresh in the background
      const tokenHalfTimeExpiration = (decodedToken.exp - decodedToken.iat) / 2;
      if (jwtHelper.isTokenExpired(accessToken, tokenHalfTimeExpiration)) {
        authenticationResolverService.refreshToken({ blocking: false }).catch(() => null);
      }

      return $q.when(true);
    };

    $rootScope.$on(
      "$destroy",
      $transitions.onStart({}, (trans) => {
        const toState = trans.to();

        const handleDemandsNotMet = (): IPromise<boolean> => {
          const accessToken = getToken();

          if (!accessToken) {
            return logoutAndNavigateToLogin(trans);
          }

          return refreshTokenIfExpired(accessToken, trans);
        };

        return authenticationResolverService.demandsAreMet(toState).then((demandsAreMet) => {
          if (!demandsAreMet) {
            return handleDemandsNotMet().then((res) => res);
          }

          if (!accountResolverService.demandsAreMet(toState) || !currentUserRepository.demandsAreMet(toState) || !profileService.demandsAreMet(toState)) {
            lastUrlService.storeCurrentLocation(generateToPathURL(toState, trans.params()));
            return accountResolverService.prepareForResolveAccount();
          }
        });
      })
    );
  },
]);

mod.run([
  "$rootScope",
  "UserProfileService",
  "AccountResolverService",
  function ($rootScope: IRootScopeService, profileService: UserProfileService, accountResolverService: AccountResolverService) {
    $rootScope.$on("userSignedOut", () => {
      profileService.clear();
      accountResolverService.clear();
    });
  },
]);

mod.run(() => {
  persistQueryStringParametersInStorage("chosenEdition", "feature");
});

mod.config([
  "tagsInputConfigProvider",
  (tagsInputConfigProvider: {
    setActiveInterpolation(field: string, setting: Record<string, unknown>): void;
    setDefaults(field: string, setting: Record<string, unknown>): void;
    setTextAutosizeThreshold(number): void;
  }) => {
    // Needed to be set so that the placeholder can be dynamically changed
    // e.g. in the parent-okr-selector.component.ts
    tagsInputConfigProvider.setActiveInterpolation("tagsInput", { placeholder: true });
  },
]);

mod.run([
  "$rootScope",
  ($rootScope: IGtmhubRootScopeService) => {
    $rootScope.$on(NavigationLifeCycleEvents.NAV_LOADED, () => removeMainNavSkeleton());
    $rootScope.$on(NavigationLifeCycleEvents.SUB_NAV_LOADED, () => removeSubNavSkeleton());
  },
]);

mod.config([
  "$compileProvider",
  "$provide",
  ($compile: ICompileProvider, $provide: auto.IProvideService) => {
    // Disable directives that use CSS or comments for binding
    // (we either use attribute or element directives)
    $compile.cssClassDirectivesEnabled(false);
    $compile.commentDirectivesEnabled(false);

    if (environment.production) {
      // We would use `$compile.debugInfoEnabled(false)`, but it disables .scope() / .isolateScope()
      // methods on angular.element(...) calls, which will break many insights. The following code is
      // taken from angular.js source code and disables everything but the scope methods.
      $provide.decorator("$compile", [
        "$delegate",
        ($delegate: ICompileService) => {
          $delegate["$$addBindingInfo"] = noop;
          $delegate["$$addBindingClass"] = noop;
          $delegate["$$addScopeClass"] = noop;
          $delegate["$$createComment"] = () => window.document.createComment("");

          return $delegate;
        },
      ]);
    }
  },
]);

mod.run([
  "$rootScope",
  "$transitions",
  "$state",
  "$uibModalStack",
  function ($rootScope: IGtmhubRootScopeService, $transitions: TransitionService, $state: StateService, $uibModalStack: IModalStackService) {
    $rootScope.$on(
      "$destroy",
      $transitions.onSuccess({}, () => {
        const state = $state.current.name.split(".")[1];
        $rootScope.isMsTeamsState = state === "msteams" || state === "msTeamsHome";
      })
    );

    const isStatelessModal = (modal: ui.bootstrap.IModalStackedMapKeyValuePair): boolean => {
      return !modal.value.modalDomEl.hasClass("state-initiated");
    };
    $rootScope.$on(
      "$destroy",
      $transitions.onStart({}, () => {
        let modal = $uibModalStack.getTop();

        while (modal && isStatelessModal(modal)) {
          modal.key.close();
          modal = $uibModalStack.getTop();
        }
      })
    );
  },
]);

mod.run([
  "$transitions",
  ($transitions: TransitionService) => {
    $transitions.onStart({}, (transition) => {
      try {
        // this is a workaround for a non-deterministic bug in ui-router
        // since there's an unsafe cast to any, I'm surrounding this with a try catch block
        // to make sure that it won't break anything
        const toList = transition.treeChanges().to.map((t) => {
          return t.state.name;
        });

        // There's a bug where an invalid ViewConfig is added to the _viewConfigs array when rapidly changing states
        // so we have to deactivate all view configs that do not belong to the current state

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const configsToBeDeactivated: ViewConfig[] = (transition.router.viewService as any)._viewConfigs.filter((vc) => {
          return !toList.includes(vc.viewDecl.$context.name);
        });
        configsToBeDeactivated.forEach((vc) => {
          transition.router.viewService.deactivateViewConfig(vc);
        });
      } catch (e) {
        console.error(e);
      }
    });
  },
]);

patchOverlay(mod);

export default mod.name;
