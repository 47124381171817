import { Injectable } from "@angular/core";
import { StrategyConversationUrlBuilderV2Service } from "../../../conversation/api-utils/v2/strategy-conversation-url-builder-v2.service";

@Injectable()
export class StrategyConversationChatUrlBuilderV2Service extends StrategyConversationUrlBuilderV2Service {
  private chatBaseUrl = "chat";

  public getChatUrl(conversationId: string): string {
    const conversationsUrl = this.getConversationUrl(conversationId);
    return `${conversationsUrl}/${this.chatBaseUrl}`;
  }
}
