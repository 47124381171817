import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class GenerateGoalsForBetResponseStrategy implements QuantiveResultsSocketStrategy<"generateGoalsForBetResponse"> {
  public messageType: QuantiveResultsSocketStrategy<"generateGoalsForBetResponse">["messageType"] = "generateGoalsForBetResponse";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"generateGoalsForBetResponse">> {
    return pipe(
      filter(({ data }) => {
        return data.item === "strategic_bet" && data.taskName === "generate_goals_for_bet" && data.userId === userId.get();
      })
    );
  }
}
