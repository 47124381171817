import { MonoTypeOperatorFunction, filter, pipe } from "rxjs";
import { userId } from "@webapp/core/storage/services/cache/user-id";
import { QuantiveResultsSocketStrategy, QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";

export class GenerateGoalsForContextResponseStrategy implements QuantiveResultsSocketStrategy<"generateGoalsForContextResponse"> {
  public messageType: QuantiveResultsSocketStrategy<"generateGoalsForContextResponse">["messageType"] = "generateGoalsForContextResponse";

  public onMessage(): MonoTypeOperatorFunction<QuantiveResultsSocketVM<"generateGoalsForContextResponse">> {
    return pipe(
      filter(({ data }) => {
        return data.item === "context" && data.taskName === "generate_goals_for_context" && data.userId === userId.get();
      })
    );
  }
}
