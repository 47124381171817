import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { ErrorDetails, UiAlertModule, UiAlertType } from "@quantive/ui-kit/alert";
import { InputBoolean } from "ng-zorro-antd/core/util";

@Component({
  selector: "ui-banner",
  exportAs: "uiBanner",
  templateUrl: "./banner.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiAlertModule],
})
export class UiBannerComponent {
  @Input("uiIconType") public uiIconType: string | null = null;
  @Input("uiMessage") public uiMessage: string | TemplateRef<void> | null = null;
  @Input("uiDescription") public uiDescription: string | TemplateRef<void> | null = null;
  @Input("uiType") public uiType: UiAlertType = "info";
  @Input("uiShowIcon") @InputBoolean() public uiShowIcon = true;
  @Input("uiCloseText") public uiCloseText: string | TemplateRef<void> | null = null;
  @Input("uiCloseable") @InputBoolean() public uiCloseable = true;
  @Input() public errorDetails?: ErrorDetails;

  @Output("uiOnClose") public readonly uiOnClose = new EventEmitter<boolean>();
}
