<section class="area-content">
  @if (area.id && !area.editing && !area.saving) {
    <section class="area-header">
      <h5>{{ area.title }}</h5>
      <section class="area-actions">
        <gh-dropdown
          *ngIf="!areaBeingModifiedId || area.id === areaBeingModifiedId"
          [attr.id]="area.id"
          [dropdownMenuClass]="'context-menu'"
          [hasCustomButtonContent]="true"
          [menuItems]="areaMenuItems">
          <button class="area-actions-button" gh-dropdown-custom-button-content type="button" ui-button>
            <i class="fs-12px" ui-icon uiTheme="outline" uiType="menu-horizontal"></i>
          </button>
        </gh-dropdown>
      </section>
    </section>
    <ng-content></ng-content>
  } @else if (area.id && area.editing && !area.saving) {
    <!-- Edit Area Form -->
    <section class="area-header">
      <h5>Edit area</h5>
    </section>
    <area-form [area]="area" (areaCancel)="onCancelEditArea()" (areaSubmit)="onEditAreaSubmit()" submitButtonText="Save area"></area-form>
  } @else if (!area.id && !area.saving) {
    <!-- Create Area Form -->
    <section class="area-header">
      <h5>Add area</h5>
    </section>
    <area-form [area]="area" (areaCancel)="onCancelSaveArea()" (areaSubmit)="onSaveAreaSubmit($event)" submitButtonText="Add area"></area-form>
  } @else if (area.saving) {
    <!-- Loading -->
    <ui-skeleton-line [style]="{ height: '20px', width: '100%' }" [uiActive]="true" />
    <ul class="hypothesis-list">
      <li class="hypothesis no-hover" *ngFor="let hypothesis of [1, 2]" tabindex="1">
        <section class="hypothesis-status">
          @if (grooming) {
            <ui-checkbox [uiDisabled]="true" />
          }
        </section>
        <section class="hypothesis-content">
          <ui-skeleton-line [style]="{ height: '20px', width: '100%' }" [uiActive]="true" />
          @if (!grooming) {
            <ui-skeleton-line
              [style]="{
                height: '16px',
                width: '40%',
              }"
              [uiActive]="true" />
          }
        </section>
      </li>
    </ul>
  }
</section>
