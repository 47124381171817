import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonComponent } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { EMPTY, Observable, catchError, firstValueFrom, map, switchMap } from "rxjs";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { Intercom } from "@gtmhub/shared/intercom";
import { StrategySettings } from "@gtmhub/users/models";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { QuantiveResultsSocketVM } from "@webapp/strategy/services/web-sockets/models/strategy/socket-strategy.vm-models";
import { StrategySocketsService } from "@webapp/strategy/services/web-sockets/services/strategy-sockets.service";
import { UiLoadingIndicatorComponent } from "@webapp/ui/loading-indicator/loading-indicator.component";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { CurrentUserRepository } from "@webapp/users";
import { ContextVM } from "../../models/context/context.vm-models";
import { GenerateGoalsForContextMediator } from "../../services/context/generate-goals-for-context/generate-goals-for-context.mediator";
import { StrategyConversationContextService } from "../../services/context/strategy-conversation-context.service";
import { DocumentsMediatorService } from "../../services/documents/documents-mediator.service";
import { StrategiesTrackingService } from "../../services/utility/strategies-tracking.service";
import { ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData } from "../confirm-delete-strategy-item/confirm-delete-strategy-item.component";
import { ContextFormComponent } from "../context-form/context-form.component";
import { DropdownMenuWithTriggerComponent } from "../dropdown-menu-with-trigger/dropdown-menu-with-trigger.component";
import { ErrorPageComponent } from "../error-page/error-page.component";
import { AlertMessageComponent } from "../evaluate-bet-page/components/alert-message/alert-message.component";
import { WhiteboardGenerationComponent } from "../evaluate-bet-page/components/whiteboard-generation/whiteboard-generation.component";

@UntilDestroy()
@Component({
  selector: "strategy-into-action-page",
  templateUrl: "./strategy-into-action-page.component.html",
  styleUrls: ["./strategy-into-action-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiLoadingIndicatorComponent,
    NgIf,
    UiTooltipModule,
    DropdownMenuWithTriggerComponent,
    UiIconModule,
    UiButtonComponent,
    AlertMessageComponent,
    ContextFormComponent,
    WhiteboardGenerationComponent,
    ErrorPageComponent,
  ],
  providers: [GenerateGoalsForContextMediator],
})
export class StrategyIntoActionPageComponent implements OnInit, OnDestroy {
  @Input() public contextId: string;
  @ViewChild("deletionError") private deletionError: TemplateRef<object>;

  public context: ContextVM | null = null;
  public contextForm: FormGroup<{ notes: FormControl<string> }>;
  public redirectingToWhiteboard = false;
  public loadingContext = false;
  public checkingForAsyncGeneration = false;
  public documentsAreBeingProcessed = false;
  public error: string | null = null;
  public contextLoadError = "CONTEXT_LOAD_ERROR";
  public whiteboardGenerationErrorValue = "WHITEBOARD_GENERATION_ERROR";
  public strategySettings: StrategySettings;
  public whiteboardGenerationError: string | null = null;
  public showContextFormError = false;
  public generatedWhiteboardId: string | null = null;
  public contextSummarized = false;

  private documentsWithSummariesCount = 0;

  constructor(
    private strategyConversationContextService: StrategyConversationContextService,
    private formBuilder: FormBuilder,
    private modalService: UiModalService,
    private currentUserRepository: CurrentUserRepository,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apmService: ApmService,
    private socketsService: StrategySocketsService,
    public documentsMediator: DocumentsMediatorService,
    public trackingService: StrategiesTrackingService,
    private generateGoalsForContextMediator: GenerateGoalsForContextMediator
  ) {}

  public ngOnDestroy(): void {
    this.socketsService.setCurrentWatchers([]);
  }

  public ngOnInit(): void {
    this.socketsService.setCurrentWatchers([{ id: this.contextId, type: "context" }]);

    this.loadContext();
    this.loadDocuments();

    this.socketsService
      .onMessage$("updateContextDocumentsSummary")
      .pipe(untilDestroyed(this))
      .subscribe((message: QuantiveResultsSocketVM<"updateContextDocumentsSummary">) => {
        if (message.data.itemId === this.context.id && message.data.status === "SUCCESS") {
          this.contextSummarized = true;

          if (this.redirectingToWhiteboard) {
            this.generateOKRs();
          }

          this.cdr.markForCheck();
        }
      });
  }

  public loadContext(): void {
    this.checkingForAsyncGeneration = true;

    this.initializeContextData$()
      .pipe(
        takeOneUntilDestroyed(this),
        switchMap((context) =>
          this.generateGoalsForContextMediator.listenForGoalGenerationChangesForContext$({
            id: context.id,
            progressStep: context.progressStep,
          })
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: ({ status, whiteboardId, errorMessage }) => {
          if (status === "IDLE") {
            this.generatedWhiteboardId = null;
            this.redirectingToWhiteboard = false;
            this.resetError();
          } else if (status === "ERROR") {
            this.generatedWhiteboardId = null;
            this.redirectingToWhiteboard = false;
            this.error = errorMessage;
            this.whiteboardGenerationError = errorMessage;
          } else if (status === "LOADING") {
            this.generatedWhiteboardId = null;
            this.redirectingToWhiteboard = true;
            this.resetError();
          } else if (status === "SUCCESS") {
            this.generatedWhiteboardId = whiteboardId;
            this.redirectingToWhiteboard = true;
            this.resetError();
          }

          this.checkingForAsyncGeneration = false;
          this.cdr.markForCheck();
        },
        error: () => {
          this.checkingForAsyncGeneration = false;
          this.cdr.markForCheck();
        },
      });
  }

  private initializeContextData$(): Observable<ContextVM> {
    this.resetError();
    this.loadingContext = true;
    this.cdr.markForCheck();

    this.apmService.startDataLoadSpan("load context");
    return this.strategyConversationContextService.getBetContext$(this.contextId).pipe(
      takeOneUntilDestroyed(this),
      map((context) => {
        this.context = context;
        this.contextSummarized = !!context.documentSummary;
        this.contextForm = this.formBuilder.group({
          notes: new FormControl<string>(this.context.notes || ""),
        });
        this.strategySettings = this.currentUserRepository.getUserSetting("strategySettings");
        this.cdr.markForCheck();
        this.loadingContext = false;
        this.apmService.endDataLoadSpan("load context");

        return context;
      }),
      catchError(() => {
        this.error = this.contextLoadError;
        this.loadingContext = false;
        this.cdr.markForCheck();

        return EMPTY;
      })
    );
  }

  public stopOKRGeneration(): void {
    this.decrementProgressStep();
    this.generatedWhiteboardId = null;
    this.redirectingToWhiteboard = false;
    this.resetError();
    this.cdr.markForCheck();
  }

  public hideAlert(): void {
    this.strategySettings = {
      ...this.strategySettings,
      contextHintSeen: true,
    };
    this.currentUserRepository.setUserSetting({ strategySettings: this.strategySettings });

    this.cdr.markForCheck();
  }

  public exportOKRsForContext(): void {
    this.updateContextMetaData();
    if (this.contextForm.invalid) {
      this.showContextFormError = true;
      this.cdr.markForCheck();
      return;
    }
    if (this.documentsWithSummariesCount === 0) {
      this.showContextFormError = true;
      this.cdr.markForCheck();
      return;
    }
    this.resetError();
    this.updateContextAndGenerateOKRs();
    this.trackingService.trackStrategyRedirectionAction(this.contextId, "Generate OKRs", "continue");
  }

  public notesValueChanged(newNotesValue: string): void {
    const newName = this.getContextName(newNotesValue);
    this.strategyConversationContextService
      .updateBetContext$(this.contextId, newNotesValue, "Generate OKRs", newName)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: () => {
          this.context.notes = newNotesValue;
          this.context.name = newName;
          this.cdr.markForCheck();
        },
      });
  }

  public navigateToWhiteboard(): void {
    this.trackingService.trackGoToWhiteboard(this.generatedWhiteboardId, "Generate OKRs");
    this.router.navigate(["/whiteboard", this.generatedWhiteboardId]);
  }

  public updateContextAndGenerateOKRs(): void {
    const notes = this.contextForm.get("notes").value;
    const newName = this.getContextName(notes);
    this.redirectingToWhiteboard = true;
    this.strategyConversationContextService
      .updateBetContext$(this.contextId, notes, "Generate OKRs", newName)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe({
        next: () => {
          this.context.name = newName;
          if (this.contextSummarized) {
            this.generateOKRs();
          }
        },
        error: () => {
          this.error = this.whiteboardGenerationErrorValue;
          this.whiteboardGenerationError = this.whiteboardGenerationErrorValue;
          this.redirectingToWhiteboard = false;
          this.cdr.markForCheck();
        },
      });
    this.cdr.markForCheck();
  }

  public generateOKRs(): void {
    this.generateGoalsForContextMediator.triggerGoalGenerationForContext$(this.contextId);
  }

  public closePage(): void {
    this.router.navigate(["/strategies/generated-objectives"]);
  }

  public deleteContext(contextName: string): void {
    this.modalService.confirm<ConfirmDeleteStrategyItemComponent, ConfirmDeleteStrategyItemModalData>(
      {
        uiTitle: "Delete Objective generation?",
        uiContent: ConfirmDeleteStrategyItemComponent,
        uiData: {
          strategyItemName: contextName,
          strategyItemType: "objectiveGeneration",
        },
        uiOkText: "Delete Objective generation",
        uiOkLoadingText: "Deleting Objective generation...",
        uiOkDanger: true,
        uiIconType: null,
        uiOnOk: () => {
          return firstValueFrom(this.strategyConversationContextService.deleteContext$(this.contextId))
            .then(() => {
              this.router.navigate(["/strategies/generated-objectives"]);
            })
            .catch(() => {
              throw this.deletionError;
            });
        },
        uiCancelText: "Cancel",
      },
      "error"
    );
  }

  public onContactSupportClick(): void {
    Intercom("show");
  }

  private loadDocuments(): void {
    this.apmService.startDataLoadSpan("load documents");
    this.documentsMediator
      .getDocumentsForContext$(this.contextId)
      .pipe(untilDestroyed(this))
      .subscribe((documents) => {
        this.documentsWithSummariesCount = documents.filter((d) => d.state === "SUMMARIZED").length;
        this.updateContextMetaData();
        this.apmService.endDataLoadSpan("load documents");
      });
  }

  private getContextName(notes: string): string {
    const documentCountString = `${this.documentsWithSummariesCount} file${this.documentsWithSummariesCount === 1 ? "" : "s"}`;
    return notes.trim().length > 0 ? `${documentCountString} - ${notes.split(/\s+/).slice(0, 10).join(" ")}` : documentCountString;
  }

  private updateContextMetaData(): void {
    if (this.showContextFormError) {
      this.showContextFormError = this.documentsWithSummariesCount === 0;
      this.cdr.markForCheck();
    }
    const newName = this.getContextName(this.context?.notes || "");
    this.strategyConversationContextService
      .updateBetContext$(this.contextId, this.context.notes, "Generate OKRs", newName)
      .pipe(takeOneUntilDestroyed(this))
      .subscribe(() => {
        this.context.name = newName;
        this.cdr.markForCheck();
      });
  }

  private resetError(): void {
    this.error = null;
    this.showContextFormError = false;
    this.cdr.markForCheck();
  }

  private decrementProgressStep(): void {
    this.generateGoalsForContextMediator.invalidateGoalGenerationStateForContext(this.contextId);
  }
}
