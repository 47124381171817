import { localize } from "@gtmhub/localization";
import { ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { CustomFieldFilterName, ExtendedOrFilterGroups, FilterFieldName } from "@webapp/okrs/okrs-grid-page/models";
import {
  generateAccessTypeFilterFromKey,
  generateAssigneeFilterFromKey,
  generateDatePickerFilterFromKey,
  generateLastUpdatedFilterFromKey,
  generateMultiSelectFilterFromKey,
  generateNumericFromFilterKey,
  generateOkrDesignScoreFilterFromKey,
  generateOkrStateFilterFromKey,
  generateOwnersFilterFromKey,
  generateProgressScoreFilterFromKey,
  generateSelectFilterFromKey,
  generateSessionFilterFromKey,
  generateTagsFilterFromKey,
  generateTextInputFilterFromKey,
  generateWorkflowStatusFilterFromKey,
} from "./filter-factories";

export const generateFilterFromFilterKey = (filterKey: FilterFieldName): ExtendedOrFilterGroups => {
  if (filterKey === "sessionId") {
    return generateSessionFilterFromKey(filterKey);
  }

  if (filterKey === "ownerIds") {
    return generateOwnersFilterFromKey(filterKey);
  }

  if (filterKey === "ownerId") {
    return generateOwnersFilterFromKey(filterKey);
  }

  if (filterKey === "description" || filterKey === "name" || filterKey === "metrics.description" || filterKey === "metrics.name") {
    return generateTextInputFilterFromKey(filterKey);
  }

  if (filterKey === "metrics.ownerIds") {
    return generateOwnersFilterFromKey(filterKey);
  }

  if (filterKey === "watchers") {
    return generateOwnersFilterFromKey(filterKey);
  }

  if (filterKey === "closedStatus.status") {
    return generateOkrStateFilterFromKey(filterKey);
  }

  if (filterKey === "workflow.status") {
    return generateWorkflowStatusFilterFromKey(filterKey);
  }

  if (filterKey === "designScore.totalPoints") {
    return generateOkrDesignScoreFilterFromKey(filterKey);
  }

  if (filterKey === "attainment") {
    return generateProgressScoreFilterFromKey(filterKey);
  }

  if (filterKey === "tags.title") {
    return generateTagsFilterFromKey(filterKey);
  }

  if (filterKey === "metrics.latestSnapshotAt") {
    return generateLastUpdatedFilterFromKey(filterKey);
  }

  if (filterKey === "accessType") {
    return generateAccessTypeFilterFromKey(filterKey);
  }
};

export const generateFilterFromCustomField = (filterKey: FilterFieldName, customField: ICustomField): ExtendedOrFilterGroups => {
  if (customField.visualizationKind === "gtmhub.text" || customField.visualizationKind === "gtmhub.textarea") {
    return { ...generateTextInputFilterFromKey(filterKey), label: localize(customField.label), visualizationKind: customField.visualizationKind };
  }

  if (customField.visualizationKind === "gtmhub.select") {
    return { ...generateSelectFilterFromKey(filterKey), label: localize(customField.label), visualizationKind: customField.visualizationKind };
  }

  if (customField.visualizationKind === "gtmhub.multiselect") {
    return { ...generateMultiSelectFilterFromKey(filterKey), label: localize(customField.label), visualizationKind: customField.visualizationKind };
  }

  if (customField.visualizationKind === "gtmhub.datepicker") {
    return { ...generateDatePickerFilterFromKey(filterKey), label: localize(customField.label), visualizationKind: customField.visualizationKind };
  }

  if (customField.visualizationKind === "gtmhub.selectassignee") {
    return { ...generateAssigneeFilterFromKey(filterKey), label: localize(customField.label), visualizationKind: customField.visualizationKind };
  }

  if (customField.visualizationKind === "gtmhub.numeric") {
    return { ...generateNumericFromFilterKey(filterKey), label: localize(customField.label), visualizationKind: customField.visualizationKind };
  }
};

const getCustomFieldType = (cf: ICustomField): CustomFieldFilterName => {
  if (cf.targetTypes.includes("user") || cf.targetTypes.includes("team")) {
    return `assignees.customFields.${cf.name}`;
  }

  if (cf.targetTypes.includes("metric")) {
    return `metrics.customFields.${cf.name}`;
  }

  return `customFields.${cf.name}`;
};

export const enrichCustomFieldName = (customField: ICustomField): ICustomField => {
  return { ...customField, name: getCustomFieldType(customField) };
};
export const enrichCustomFieldNames = (customFields: ICustomField[]): ICustomField[] => {
  return customFields.map((cf) => enrichCustomFieldName(cf));
};

export function substituteSessionIdWithTitle(sort: string[]): string[] {
  return sort.map((field) => {
    if (field === "sessionId") {
      return "sessionTitle";
    } else if (field === "-sessionId") {
      return "-sessionTitle";
    }
    return field;
  });
}
