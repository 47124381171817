import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GoalsDataVM, StructuredDataAspectVM } from "@webapp/strategy/models/bets/strategic-bets.vm-models";
import { FollowupActionDefinition } from "@webapp/strategy/models/strategy.vm-models";
import { GenericResultsBoxComponent } from "../generic-results-box/generic-results-box.component";

@Component({
  selector: "goals-result-block",
  templateUrl: "./goals-result-block.component.html",
  styleUrls: ["./goals-result-block.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GenericResultsBoxComponent],
})
export class GoalsResultBlockComponent implements OnInit {
  @Input() public goalsBlockContent: GoalsDataVM;

  @Output() public readonly contentChanged = new EventEmitter<{
    content: StructuredDataAspectVM[];
    section: number;
  }>();
  @Output()
  public readonly executeAction: EventEmitter<FollowupActionDefinition> = new EventEmitter<FollowupActionDefinition>();
  @Output()
  public readonly focusOutWithChanges: EventEmitter<void> = new EventEmitter<void>();

  public goalsBlockData: { title: string; data: StructuredDataAspectVM[] }[];

  public ngOnInit(): void {
    this.goalsBlockData = this.goalsBlockContent.goals.map((goal) => {
      const data = goal.keyResults.map((keyResult, index) => ({
        id: index,
        text: keyResult,
        references: [],
      }));

      return {
        title: goal.goal,
        data: [
          ...data,
          {
            id: data.length,
            text: goal.reasoning,
            references: goal.references || [],
          },
        ],
      };
    });
  }

  public changeContent($event: StructuredDataAspectVM[], $index: number): void {
    this.contentChanged.emit({
      content: $event,
      section: $index,
    });
  }
}
