import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzMessageContainerComponent } from "ng-zorro-antd/message";
import { UiToastComponent } from "../../toast.component";
import { UiToastConfig, UiToastData } from "../../toast.models";

@Component({
  selector: "ui-toast-container",
  exportAs: "uiToastContainer",
  templateUrl: "toast-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiToastComponent, NgFor],
})
export class UiToastContainerComponent extends NzMessageContainerComponent {
  public instances: Array<Required<UiToastData>>;
  public left?: string;

  protected updateConfig(): void {
    super.updateConfig();
    this.updateLeft();
    this.cdr.markForCheck();
  }

  private updateLeft(): void {
    const left = (this.config as UiToastConfig).nzLeft;
    if (typeof left === "string") {
      this.left = left;
      return;
    }

    this.left = `${left}px`;
  }
}
