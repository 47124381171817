import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable, map, switchMap, tap } from "rxjs";
import { PatchModel } from "@webapp/core/http/models/http.models";
import { AsyncResponseVM, GenerateOKRsResponseVM } from "../../models/bets/strategic-bets.vm-models";
import { ContextVM } from "../../models/context/context.vm-models";
import { StrategyContextDocument } from "../../models/strategy.vm-models";
import { StrategiesTrackingService } from "../utility/strategies-tracking.service";
import { StrategyConversationContextApiFactory } from "./api-utils/strategy-conversation-context-api-factory.service";

@UntilDestroy()
@Injectable()
export class StrategyConversationContextService {
  constructor(
    private strategyConversationContextApiFactory: StrategyConversationContextApiFactory,
    private trackingService: StrategiesTrackingService
  ) {}

  public updateBetContext$(contextId: string, notes: string, source: "Decision Map" | "Generate OKRs", name?: string): Observable<ContextVM> {
    return this.strategyConversationContextApiFactory.getApi().pipe(
      switchMap((api) => api.updateBetContext$(contextId, notes, name)),
      tap(() => {
        this.trackingService.trackStrategyContextInput(contextId, source);
      })
    );
  }

  public patchBetContext$(partialContext: PatchModel<ContextVM>): Observable<ContextVM> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.patchBetContext$(partialContext)));
  }

  public updateDocumentsForContext$(contextId: string, documents: StrategyContextDocument[]): Observable<ContextVM> {
    return this.strategyConversationContextApiFactory.getApi().pipe(
      switchMap((api) =>
        api.updateBetContextDocuments$(
          contextId,
          documents.map((document) => document.uid)
        )
      )
    );
  }

  public getBetContext$(contextId: string): Observable<ContextVM> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.getBetContext$(contextId)));
  }

  public createContext$(contextName?: string): Observable<ContextVM> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.createContext$(contextName)));
  }

  public getNonBetContexts$(): Observable<ContextVM[]> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.getContexts$({ isStrategicBet: false })));
  }

  public getContexts$(): Observable<ContextVM[]> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.getContexts$({ isStrategicBet: true })));
  }

  public deleteContext$(contextId: string): Observable<void> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.deleteContext$(contextId)));
  }

  public generateGoals$(contextId: string): Observable<string> {
    return this.strategyConversationContextApiFactory.getApi().pipe(
      switchMap((api) => api.generateGoals$(contextId)),
      map((generateOKRsResponse: GenerateOKRsResponseVM) => generateOKRsResponse.whiteboardId)
    );
  }

  public generateGoalsAsync$(contextId: string): Observable<AsyncResponseVM> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.generateGoalsAsync$(contextId)));
  }

  public markContextAsSeen$(contextId: string): Observable<void> {
    return this.strategyConversationContextApiFactory.getApi().pipe(switchMap((api) => api.markContextAsSeen$(contextId)));
  }
}
