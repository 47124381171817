import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { CustomDaysAgoPipe } from "@webapp/shared/pipes/custom-days-ago.pipe";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";

@Component({
  selector: "strategy-ai-card",
  templateUrl: "./strategy-ai-card.component.html",
  styleUrls: ["./strategy-ai-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiTooltipModule, UiIconModule, UiButtonModule, CustomDaysAgoPipe, UiAssigneeModule, AssigneesModule],
})
export class StrategyAiCardComponent {
  @Input({ required: true }) public name: string | TemplateRef<void>;
  @Input({ required: true }) public created: string;
  @Input({ required: true }) public ownerIds: string[];
  @Input() public icon: string | TemplateRef<unknown>;
  @Input() public dropdown: TemplateRef<void>;
  @Input() public route: string | string[];

  @Output() public readonly handleClick = new EventEmitter<void>();

  public get isTemplateRef(): boolean {
    return this.name instanceof TemplateRef;
  }

  public get templateRef(): TemplateRef<void> {
    return this.name as TemplateRef<void>;
  }

  public get isIconTemplateRef(): boolean {
    return this.icon instanceof TemplateRef;
  }

  public get iconTemplateRef(): TemplateRef<unknown> {
    return this.icon as TemplateRef<unknown>;
  }

  public get iconAsString(): string {
    return this.icon as string;
  }
}
