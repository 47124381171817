<header>
  <section class="strategic-bet-info">
    <h4 #betName [uiTooltipTitle]="strategicBetName" tooltipIfOverflow="hover" ui-tooltip>
      {{ strategicBetName }}
    </h4>

    <h3>
      <span class="step-caption">{{ stepCaptionOverride || stepsLabelsArray[currentStepIndex] }}</span>
    </h3>
  </section>
  @if (!hideRightActionButtons && (currentStepIndex === 1 || currentStepIndex === 2)) {
    <section class="collaborators">
      <ui-assignee-avatar-group class="sp-m-4" [uiMax]="3" uiSize="sm">
        @for (collaborator of collaborators; track $index) {
          <ui-assignee-avatar [fromAssigneeId]="collaborator.userId" uiSize="md"></ui-assignee-avatar>
        }
      </ui-assignee-avatar-group>

      @if (userIsOwner && !hideShareAndPDFButtons) {
        <button (click)="showInviteCollaborators.emit()" ui-button ui-tooltip uiShape="round" uiTooltipTitle="Invite people to collaborate" uiType="link">
          <i class="fs-16px" ui-icon uiTheme="outline" uiType="share"></i>
        </button>
      }
    </section>
    <section class="action-button">
      <button class="header-button" [uiTooltipTitle]="contextIconTooltipText" (click)="openContextDrawer.emit()" ui-button ui-tooltip uiShape="round">
        <i class="fs-16px" ui-icon uiType="answers"></i>
      </button>
      <section class="async-status-container">
        <async-status [asyncPreviewState]="contextAsyncPreviewState" [imageOnly]="true" size="12px"></async-status>
      </section>
    </section>
  }

  <section class="action-button" *ngIf="!hideRightActionButtons">
    <gh-dropdown
      [dropdownMenuClass]="'context-menu'"
      [menuItems]="currentStepIndex === 0 ? contextMenuItemsForContextPage : contextMenuItems"
      (click)="$event.stopPropagation()"
      iconName="menu-vertical"
      ui-tooltip
      uiTooltipTitle="More">
    </gh-dropdown>
  </section>
  <section class="action-button" *ngIf="!hideRightActionButtons">
    <button (click)="closePage.emit()" ui-button ui-tooltip uiShape="round" uiTooltipTitle="Close" uiType="default">
      <i ui-icon uiTheme="outline" uiType="close-big"></i>
    </button>
  </section>
</header>
