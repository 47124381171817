import { Injectable } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { encodeBase64SQL } from "../../shared/utils";
import { DocumentExportPostVM } from "../models/document-exporter.vm-models";
import { DocumentExporterV3ApiService } from "./document-exporter-api-v3.service";

@Injectable()
export class DocumentExporterService {
  constructor(private documentExporterV3ApiService: DocumentExporterV3ApiService) {}

  public exportAsPDF$(exportData: DocumentExportPostVM): Observable<SafeResourceUrl> {
    const base64EncodedPayload: DocumentExportPostVM["payload"] = encodeBase64SQL(
      this.appendStylesToHTML({
        payload: exportData.payload,
        css: exportData.css,
      })
    );
    // for some reason CSS is not respected by the BE
    // instead we append the styles to the HTML using a <style> tag
    const base64EncodedCSS: DocumentExportPostVM["css"] = encodeBase64SQL("");

    return this.documentExporterV3ApiService.exportAsPDF$({
      ...exportData,
      payload: base64EncodedPayload,
      css: base64EncodedCSS,
    });
  }

  public triggerResourceDownload({ from, withName }: { from: SafeResourceUrl; withName: string }): void {
    const downloadableEl: HTMLAnchorElement = document.createElement("a");
    downloadableEl.href = <string>from;
    downloadableEl.download = `${withName}.pdf`;
    downloadableEl.click();
  }

  private appendStylesToHTML({ payload, css }: Pick<DocumentExportPostVM, "payload" | "css">): string {
    return `<style>${css}</style>` + payload;
  }
}
